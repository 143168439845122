<template>
  <div
      v-loading="loading"
      :element-loading-text="text_load"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div>
      <el-row :gutter="24">
        <el-col :span="16">
          <el-select
              v-if="requireLstHocKy"
              style="width: 120px"
              class="mb-2"
              v-model="hocKy"
              size="small"
              placeholder="Học kỳ"
          >
            <el-option value="" label="--Học kỳ--"></el-option>
            <el-option
                v-for="(item, index) in lstHocKy"
                :value="item.key"
                :label="item.label"
                :key="item.type"
            ></el-option>
          </el-select>
          <ESelect
              v-if="requireKhoiHoc"
              class=""
              size="small"
              style="width: 120px"
              collapseTags
              v-model="khoiHoc"
              @change="khoiHocChange"
              placeholder="Chọn"
              filterable
              :data="optionGrade"
              :fields="['label', 'value']"
          />
          <ESelect
              v-if="!hideClass"
              class="ml-2"
              size="small"
              style="width: 120px"
              collapseTags
              v-model="lopHoc"
              placeholder="Chọn"
              filterable
              :data="danh_sach_lop_hoc"
              :fields="['tenLop', 'maLop']"
          />
        </el-col>
        <el-col :span="8">
          <div class="d-flex">

            <el-tooltip content="Tải file mẫu" placement="top">
              <el-link class="mb-2 ml-auto" size="small" @click.prevent="taiFileMau">
                <i>{{ textDownload ? textDownload : "Tải file mẫu" }}</i></el-link
              >
            </el-tooltip>
            <!--              <el-link class="mb-2 ml-auto" v-if="!disabledDownload" size="small" @click.prevent="dowloadFile"><i>Tải-->
            <!--                file-->
            <!--                mẫu </i></el-link>-->
            <!--              <el-link v-if="fileHasData&&!disabledDownload" class="mb-2" style="margin-left:12px" size="small"-->
            <!--                       @click.prevent="dowloadFileCoDuLieu">-->
            <!--                <i>Tải file mẫu có dữ liệu</i></el-link>-->
          </div>
        </el-col>
      </el-row>

      <!--        <div class="ml-auto" v-if="!disabledDownload">-->
      <!--          <el-link class="mb-2 mt-2" size="small" @click.prevent="dowloadFile"><i>Tải về file mẫu</i>-->
      <!--          </el-link>-->
      <!--        </div>-->

      <div class="d-block d-md-flex align-items-md-center">
        <div>
          <el-upload
              :on-change="uploadFile"
              :on-progress="onProgress"
              :on-preview="onPreview"
              :on-error="onError"
              :on-exceed="onExeed"
              :before-upload="beforUpload"
              :before-remove="beforRemove"
              :file-list="fileList"
              accept=".xls,.xlsx"
              :on-remove="removeFile"
              class="upload-excell"
              drag
              :limit="2"
              ref="uploadExcel"
              action="/"
              :auto-upload="false"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text"><em>Chọn file upload</em></div>
          </el-upload>
        </div>
        <div class="mt-3 mt-md-0 ml-md-2">
          <el-button
              :disabled="!base64 || base64 == '' || base64 == null"
              size="small"
              type="primary"
              @click="submitUpload"
          >
            Nhập dữ liệu
          </el-button>
        </div>
      </div>
    </div>
    <div>
      <el-dialog
          v-loading="loading"
          :element-loading-text="text_load"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          :close-on-press-escape="false"
          :close-on-click-modal="false"
          title="Dữ liệu nhập"
          top="5vh"
          class="dialog__alert"
          :destroy-on-close="true"
          :before-close="closeDialog"
          width="90%"
          center
          :visible.sync="show_info_validate"
          append-to-body
      >
        <div>
          <div v-if="dataValidateImport && dataValidateImport.length">
            <el-tabs type="border-card">
              <el-tab-pane
                  v-for="(item, index) in dataValidateImport"
                  :key="index"
                  type="border-card"
                  :label="item.sheetName"
              >
                <div class="thongBaoLoiImport">
                  <el-tabs type="border-card">
                    <el-tab-pane type="border-card" label="Dữ liệu lỗi">
                      <table>
                        <thead>
                        <tr v-for="(thead, j) in item.data.header" :key="j">
                          <th class="text-center">
                            <div class="noiDungHeader">Vị trí trong file (hàng)</div>
                          </th>
                          <th
                              class="text-center"
                              v-for="(th, k) in thead.cells"
                              :key="k"
                          >
                            <div class="noiDungHeader">
                              {{ getContentHeader(th.content) }}
                            </div>
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(tr, h) in item.data.fail" :key="tr.rowIndex">
                          <td class="text-center">
                            <p>{{ tr.rowIndex }}</p>
                          </td>
                          <template v-for="(nd, l) in tr.cells">
                            <td :class="{ loiThongTin: nd.cellType == 1 }">
                              {{ nd.content.trim() }}
                              <br v-if="nd.content.trim()"/><span>{{
                                nd.comment
                              }}</span>
                            </td>
                          </template>
                        </tr>
                        </tbody>
                      </table>
                    </el-tab-pane>
                    <el-tab-pane type="border-card" label="Dữ liệu hợp lệ">
                      <table>
                        <tr v-for="(thead, j) in item.data.header" :key="j">
                          <th class="text-center " v-for="(th, k) in thead.cells" :key="k">
                            <div class="noiDungHeader">
                              {{ getContentHeader(th.content) }}
                            </div>
                          </th>
                        </tr>
                        <tr v-for="(tr, h) in item.data.success" :key="tr.rowIndex">
                          <template v-for="(nd, l) in tr.cells">
                            <td :class="{ loiThongTin: nd.cellType == 1 }">
                              {{ nd.content.trim() }}
                              <br v-if="nd.content.trim()"/><span>{{ nd.comment }}</span>
                            </td>
                          </template>
                        </tr>
                      </table>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button size="mini" type="default" @click="closeDialog()">Đóng</el-button>
          <el-button
              :disabled="hasSuccess"
              size="mini"
              type="primary"
              @click="nhapDuLieuHopLe()"
          >Nhập lên
          </el-button>
          <el-button
              @click="handleExportExcelError"
              size="mini"
              type="primary"
              :disabled="!dataValidateImport||!hasFail"
          >
            Tải file lỗi
          </el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {mapActions, mapState} from "vuex";
import ESelect from "../Ui/ESelect";
import XLSX from "xlsx";
import LZString from "lz-string";
import api from "../../_helpers/api";
import constant from "../../_helpers/constant_api";
import ExcelJS from "exceljs";
import saveAs from "file-saver";
import th from "element-ui/src/locale/lang/th";

export default {
  name: "ImportNhanXet",
  components: {
    ESelect,
  },
  props: [
    "isGdcd",
    "year",
    "capHoc",
    "path",
    "source",
    "uriDowload",
    "nameFile",
    "disabledDownload",
    "requireKhoiHoc",
    "pathSampleFile",
    "requireImportType",
    "linkFileHasData",
    "fileHasData",
    "requireLstHocKy",
    "hideClass",
    "fileNameError",
    "importType",
    "dataType",
    "textDownload",
  ],
  data() {
    return {
      infoRole: {
        isGVCN: false,
      },
      optionGrade: [],
      khoiCap1: [
        {
          value: 1,
          label: "Khối 1",
        },
        {
          value: 2,
          label: "Khối 2",
        },
        {
          value: 3,
          label: "Khối 3",
        },
        {
          value: 4,
          label: "Khối 4",
        },
        {
          value: 5,
          label: "Khối 5",
        },
      ],
      khoiCap2: [
        {
          value: 6,
          label: "Khối 6",
        },
        {
          value: 7,
          label: "Khối 7",
        },
        {
          value: 8,
          label: "Khối 8",
        },
        {
          value: 9,
          label: "Khối 9",
        },
      ],
      khoiCap3: [
        {
          value: 10,
          label: "Khối 10",
        },
        {
          value: 11,
          label: "Khối 11",
        },
        {
          value: 12,
          label: "Khối 12",
        },
      ],
      lstHocKy: [
        {key: 1, label: "Học kỳ I"},
        {key: 2, label: "Học kỳ II"},
        {key: 3, label: "Cả năm"},
      ],
      danh_sach_lop_hoc: [],
      hocKy: "",
      khoiHoc: "",
      lopHoc: "",
      text_load: "Quá trình đang tải dữ liệu, vui lòng đợi trong ít phút...",
      row_header: 0,
      type: "",
      loiDocFile: false,
      loading: false,
      fullScreenLoading: false,
      lastFile: null,
      dataValidateImport: [],
      show_info_validate: false,
      hasSuccess: true,
      hasFail: false,
      base64: "",
      fileList: [],
      listRows: [],
      workbook: "",
      worksheet: "",
      listRowsAll: [],

      dataError: "",
    };
  },
  computed: {
    ...mapState("account", ["list_nam_hoc", "user"]),
  },
  watch: {
    year(val) {
      this.onChangeNamHoc();
    },
    capHoc(val) {
      this.khoiHoc = "";
      this.lopHoc = "";
      if (val) {
        this.getKhoiHoc();
      }
    },
  },
  methods: {
    getContentHeader(str) {
      let result = str ? str.replace(/{[^}]*}|#/g, '') : '';
      return result;
    },
    removeBracesContent(str) {
      // console.log('removeBracesContent: ' + str)
      return str.replace(/{[^}]*}/g, '');
    },
    taiFileMau() {
      console.log("taiFileMau");
      this.$confirm("Xác nhận tải xuống file biểu mẫu?", "Thông báo", {
        confirmButtonText: "Tải xuống",
        cancelButtonText: "Hủy",
        closeOnClickModal: false,
      })
          .then((_) => {
            let params = {
              qlnt: this.user.qlnt,
              namHoc: this.year,
              capHoc: this.capHoc,
              dataType: this.dataType,
              maLop: this.lopHoc,
              khoiHoc: this.khoiHoc,
              hocKy: this.hocKy,
            };
            console.log(params);
            api
                .post("/hocbadientu-sync-service/api/v2/export/data/export", params)
                .then((data) => {
                  console.log('response')
                  console.log(data)
                  if (data.data.code == 200) {
                    this.handleExportExcelFileMau(data.data.data)
                  } else {
                    this.$message({
                      customClass: "dts-noty-error",
                      showClose: true,
                      message: data.data.msg,
                      type: "error",
                      duration: 3000,
                    });
                  }
                })
                .catch((e) => {
                  console.log(e);
                  this.$message({
                    customClass: "dts-noty-error",
                    showClose: true,
                    message: "Lỗi kết nối",
                    type: "error",
                    duration: 3000,
                  });
                });
          })
          .catch((_) => {
          });
    },
    nhapDuLieuHopLe() {
      console.log("nhapDuLieuHopLe");
      this.loading = true;
      console.log(this.dataValidateImport);
      let dataImport = [];
      for (let i = 0; i < this.dataValidateImport.length; i++) {
        if (this.dataValidateImport[i]) {
          let obj = {
            sheetName: this.dataValidateImport[i].sheetName,
            header: this.dataValidateImport[i].data.header,
            success: this.dataValidateImport[i].data.success,
          };
          dataImport.push(obj);
        }
      }
      console.log("Dữ liệu sẽ import:");
      console.log(dataImport);

      try {
        let params = {
          namHoc: this.year,
          type: this.type,
          data: this.base64,
          maLop: this.lopHoc,
          qlnt: this.user.qlnt,
          importType: this.importType,
          importData: dataImport,
          dataType: this.dataType,
        };
        if (!this.hideClass) {
          params.maLop = this.lopHoc;
        }
        if (this.requireKhoiHoc) {
          params.khoiHoc = this.khoiHoc;
        }
        if (this.requireLstHocKy) {
          params.hocKy = this.hocKy;
        }
        let url = "/hocbadientu-sync-service/api/v2/import/data/import";
        api
            .post(url, params)
            .then((response) => {
              console.log("Import bước cuối trả về:");
              console.log(response);
              console.log(response.code);
              console.log(response.msg);
              if (response.data.code == 200) {
                console.log("case 1");
                this.thongBao("success", response.data.msg);
              } else {
                console.log("case 2");
                this.thongBao("error", response.data.msg);
              }
              console.log("Sau khi import:");
              this.dataValidateImport = [];
              this.loading = false;
              this.fullScreenLoading = false;
              this.show_info_validate = false;
              this.base64 = null;
              this.$refs.uploadExcel.clearFiles();
            })
            .catch((e) => {
              this.loading = false;
              // this.thongBao('success', 'Hệ thống đang cập nhật dữ liệu. Vui lòng chờ trong ít phút.');
            });
      } catch (e) {
        this.loading = true;
        this.thongBao("error", "Vui lòng thử lại sau ít phút.");
      }
    },
    closeDialog() {
      this.clearValidateDialog();
      console.log('closeDialog')
      this.show_info_validate = false;
      this.dataValidateImport = [];
      this.base64 = '';
      this.loading = false;
      this.show_info_validate = false;
      this.base64 = null;
      this.$refs.uploadExcel.clearFiles();
    },
    clearValidateDialog() {
      console.log('clearValidateDialog')
      this.show_info_validate = false;
      this.dataValidateImport = [];
      this.base64 = '';
      this.loading = false;
      this.show_info_validate = false;
      this.base64 = null;
      this.$refs.uploadExcel.clearFiles();
    },
    onChangeNamHoc() {
      this.lopHoc = "";
      this.khoiHoc = "";
      this.danh_sach_lop_hoc = [];
    },
    getKhoiHoc() {
      this.optionGrade = [];
      if (this.capHoc == 1) {
        this.optionGrade = this.khoiCap1;
      } else if (this.capHoc == 2) {
        this.optionGrade = this.khoiCap2;
      } else if (this.capHoc == 3) {
        this.optionGrade = this.khoiCap3;
      } else if (this.capHoc == 12) {
        this.optionGrade = this.khoiCap1.concat(this.khoiCap2);
      } else if (this.capHoc == 23) {
        this.optionGrade = this.khoiCap3.concat(this.khoiCap3);
      } else {
        this.optionGrade = this.khoiCap1.concat(this.khoiCap2, this.khoiCap3);
      }
    },
    khoiHocChange() {
      this.lopHoc = "";
      this.danh_sach_lop_hoc = [];
      this.getlistLopHoc();
      // if (this.khoiHoc) {
      //   this.getRole();
      // }
    },
    getRole() {
      console.log("get Role");
      let url = constant.giaovien.getRole;
      let params = {
        namHoc: this.year,
      };
      this.infoRole = {
        isGVCN: false,
      };
      api
          .get(url, params)
          .then((data) => {
            console.log("get role");
            console.log(data);
            if (data.data.code == 200) {
              this.infoRole = data.data.data;
            }
            this.getlistLopHoc();
          })
          .catch((e) => {
          });
    },
    getlistLopHoc() {
      try {
        this.loading = true;
        let params = {
          start: 0,
          limit: 99,
          khoiHoc: this.khoiHoc,
          namHoc: this.year,
        };
        let url = "";
        if (this.isGdcd) {
          url = constant.giaovien.listLopNxGdcd;
          params.isGVCN = false;
        } else {
          url = constant.giaovien.listLop;
        }
        api
            .get(url, params)
            .then((response) => {
              if (response.data.code == 200) {
                this.danh_sach_lop_hoc = response.data.data.list;
              } else {
              }
              this.loading = false;
            })
            .catch((e) => {
              this.loading = false;
              // this.thongBao('success', 'Hệ thống đang cập nhật dữ liệu. Vui lòng chờ trong ít phút.');
            });
      } catch (e) {
        this.thongBao("error", "Vui lòng thử lại sau ít phút.");
      }
    },
    getRowHeader() {
      if (this.type) {
        let source = this.source.find((item) => {
          return item.type === this.type;
        });
        if (source) {
          this.row_header = source.rowHeader;
        } else {
          this.row_header = 1;
        }
      }
    },

    handleExportExcelFileMau(data) {
      console.log('handleExportExcelFileMau')
      this.loading = true;
      var that = this;
      // let errors = this.dataValidateImport;
      let dt = JSON.parse(LZString.decompressFromBase64(data));
      // console.log('dt')
      // console.log(dt)
      var fileName = dt.fileName;
      let errors = dt.data;
      console.log(errors)
      let wb = new ExcelJS.Workbook();
      errors.forEach((ws) => {
        let sheet = wb.addWorksheet(ws.sheetName, {
          pageSetup: {
            paperSize: 9,
            fitToPage: true,
            fitToHeight: 0,
            fitToWidth: 1,
          },
        });
        let arrHeader = [];
        let maxRowSpan = 1;
        var headerRequired = [];
        var headerOriginal = [];
        // console.log('ws')
        // console.log(ws)
        if (ws.header) {
          ws.header.forEach((item) => {
            console.log('Header xuất excel. # : Đỏ; {nội dung chú thích} ')
            console.table(item)
            headerOriginal = item;
            let arrRows = item.map((c) => c.replace(/\#/g, ''));
            arrRows = arrRows.map(that.removeBracesContent);
            // console.log('arrRows')
            // console.log(arrRows)
            arrHeader.push(arrRows);
            headerRequired = item.map((str, index) => str.includes('#') ? index : -1)
                .filter(index => index !== -1);
            console.log('headerRequired (Cột bắt buộc)')
            console.log(headerRequired)
            // Track the maximum rowspan
            // item.forEach((c) => {
            //   if (c.rowspan && c.rowspan > maxRowSpan) {
            //     maxRowSpan = c.rowspan;
            //   }
            // });
          });
        }
        // Add header rows to the sheet
        sheet.addRows(arrHeader);
        // Set the height of the header rows
        for (let i = 1; i <= maxRowSpan; i++) {
          sheet.getRow(i).height = 50; // Adjust this value to set the desired height
        }
        // Handle merging cells for colspan and rowspan
        ws.header.forEach((item, rowIndex) => {
          // sheet.getColumn(rowIndex).width = 100;
          item.forEach((c, colIndex) => {
            let startRow = rowIndex + 1;
            let startCol = colIndex + 1;
            let endRow = startRow + (c.rowspan ? c.rowspan - 1 : 0);
            let endCol = startCol + (c.colspan ? c.colspan - 1 : 0);

            if (startRow !== endRow || startCol !== endCol) {
              sheet.mergeCells(startRow, startCol, endRow, endCol);
            }
            let cell = sheet.getCell(startRow, startCol);
            // console.log('header là:')
            // console.log(c)
            if (c) {
              let hasNote = c.includes('{') && c.includes('}');
              if (hasNote) {
                let noteContent = c.match(/{([^}]*)}/s);
                let noteTmp = noteContent ? noteContent[1] : null;
                cell.note = noteTmp;
              }
            }
            if (c.includes('#')) {
              // console.log('có # <=> thông tin bắt buộc')
              // console.log('Ban đầu')
              // console.log(c)
              // let note = c.match(/{([^}]*)}/s);
              // console.log('Nội dung ghi chú là:')
              // console.error(note)
              // let n = note ? note[1] : null;
              // console.log('n')
              // console.error(n)
              // if (n) {
              //   cell.note = n;
              // }
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: {argb: "FFDDDDDD"},
              };
              cell.font = {
                name: "Times New Roman", size: 13,
                color: {argb: 'FF0000'},
                bold: true
              }
            } else {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: {argb: "FFDDDDDD"},
              };
              cell.font = {name: "Times New Roman", size: 13, bold: true};
            }
            cell.alignment = {vertical: "middle", horizontal: "center"};
            cell.border = {
              top: {style: "thin"},
              left: {style: "thin"},
              bottom: {style: "thin"},
              right: {style: "thin"},
            };
          });
        });
        // Add data rows to the sheet
        let dataStartRow = maxRowSpan + 1; // Determine where the data rows start
        if (ws.success) {
          console.log('có bản ghi đáp ứng yêu cầu import')
          console.table(ws.header.concat(ws.success));
          ws.success.forEach((item) => {
            // console.log('item')
            // console.log(item)
            let arrRow = item.map((c) => c);
            sheet.addRow(arrRow);
          });
        }
        // Set the height for each data row and adjust column widths based on the maximum length of content in each column
        sheet.eachRow({includeEmpty: true}, (row, rowNumber) => {
          if (rowNumber >= dataStartRow || true) {
            // Skip the header rows
            row.height = 30; // Adjust this value to set the desired height for data rows
            row.eachCell({includeEmpty: true}, (cell) => {
              if (cell.value) {
                var cellLength = cell.value.toString().length ?? 50;
                var currentCol = sheet.getColumn(cell.col);
                // Set minimum column width for columns except the first column
                var minWidth = cell.col === 1 ? undefined : 30;
                // console.log('minWidth: ' + minWidth)

                // Adjust column width based on content
                if (minWidth !== undefined) {
                  // console.log('minWidth != undefined')
                  // console.log(currentCol.width)
                  if (!currentCol.width || currentCol.width < minWidth) {
                    currentCol.width = minWidth;
                  }
                }
                if (cellLength > currentCol.width) {
                  currentCol.width = cellLength;
                }
              } else {
              }
            });
          }
        });
        // Format all rows (including header and data rows)
        // console.log('headerRequired')
        // console.log(headerRequired)
        sheet.eachRow((row, indexRow) => {
          row.eachCell((cell, index) => {
            // console.log('Hàng:' + indexRow)
            // console.log('Cột:' + index)
            if (headerRequired.includes(index - 1)) {
              // console.log('headerOriginal:')
              let note = headerOriginal[index - 1].match(/{([^}]*)}/s)
              // console.log('note')
              // console.log(note)
              if (note && note[1]) {
                // cell.note = note[1];
              }
              if (indexRow == 1) {
                // Xử lý cho hàng 1 - header
                cell.font = {
                  name: "Times New Roman", size: 13,
                  color: {argb: 'FF0000'}
                }
              } else {
                // Xử lý cho data
                cell.font = {
                  name: "Times New Roman", size: 13,
                  // color: {argb: 'FF0000'}
                }
              }
            } else {
              cell.font = {name: "Times New Roman", size: 13};
              // console.log('Không chứa ' + index)
            }
            cell.alignment = {vertical: "middle", horizontal: "center"};
            // cell.border = {
            //   top: {style: "thin"},
            //   left: {style: "thin"},
            //   bottom: {style: "thin"},
            //   right: {style: "thin"},
            // };
          });
        });
      });

      this.$refs.uploadExcel.clearFiles();
      // Save the workbook
      wb.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        this.loading = false;
        // anchor.download = this.tenFileLoi() + ".xlsx";
        anchor.download = fileName + ".xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    },
    handleExportExcelError() {
      console.log('handleExportExcelError')
      var that = this;
      let errors = this.dataValidateImport;
      console.log(errors)
      let wb = new ExcelJS.Workbook();
      errors.forEach((ws) => {
        let sheet = wb.addWorksheet(ws.sheetName, {
          pageSetup: {
            paperSize: 9,
            fitToPage: true,
            fitToHeight: 0,
            fitToWidth: 1,
          },
        });

        let arrHeader = [];
        let headerRequired = [];
        let headerOriginal = [];
        let maxRowSpan = 1;
        ws.data.header.forEach((item) => {
          console.log('item')
          console.log(item)
          let arrRowsBf = item.cells.map((c) => c.content);
          headerOriginal = arrRowsBf;
          let arrRows = arrRowsBf.map((c) => c.replace(/\#/g, ''));
          arrRows = arrRows.map(that.removeBracesContent);
          console.log('arrRows')
          console.log(arrRows)
          headerRequired = arrRowsBf.map((str, index) => str.includes('#') ? index : -1)
              .filter(index => index !== -1);
          console.log('headerRequired')
          console.log(headerRequired)
          arrHeader.push(arrRows);

          // Track the maximum rowspan
          item.cells.forEach((c) => {
            if (c.rowspan && c.rowspan > maxRowSpan) {
              maxRowSpan = c.rowspan;
            }
          });
        });

        // Add header rows to the sheet
        sheet.addRows(arrHeader);

        // Set the height of the header rows
        for (let i = 1; i <= maxRowSpan; i++) {
          sheet.getRow(i).height = 50; // Adjust this value to set the desired height
        }

        // Handle merging cells for colspan and rowspan
        ws.data.header.forEach((item, rowIndex) => {
          item.cells.forEach((c, colIndex) => {
            let startRow = rowIndex + 1;
            let startCol = colIndex + 1;
            let endRow = startRow + (c.rowspan ? c.rowspan - 1 : 0);
            let endCol = startCol + (c.colspan ? c.colspan - 1 : 0);
            if (startRow !== endRow || startCol !== endCol) {
              sheet.mergeCells(startRow, startCol, endRow, endCol);
            }
            let cell = sheet.getCell(startRow, startCol);
            cell.alignment = {vertical: "middle", horizontal: "center"};
            cell.border = {
              top: {style: "thin"},
              left: {style: "thin"},
              bottom: {style: "thin"},
              right: {style: "thin"},
            };
            console.log('Cột: ' + colIndex)
            console.log('Dữ liệu: ')
            console.log(c)
            console.log(c.content)
            if (c.content) {
              let hasNote = c.content.includes('{') && c.content.includes('}');
              if (hasNote) {
                let noteContent = c.content.match(/{([^}]*)}/s);
                let noteTmp = noteContent ? noteContent[1] : null;
                cell.note = noteTmp;
              }
            }
            if (headerRequired.includes(colIndex)) {
              console.log('Bắt buộc')
              // let note = headerOriginal[colIndex].match(/{([^}]*)}/s)
              // console.log('note')
              // console.log(note)
              // console.log('Nội dung ghi chú là:')
              // let n = note ? note[1] : null;
              // console.log(n)
              // if (n) {
              //   cell.note = n;
              // }
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: {argb: "FFDDDDDD"},
              };
              cell.font = {
                name: "Times New Roman", size: 13,
                color: {argb: 'FF0000'},
                bold: true
              }
            } else {
              console.log('Không bắt buộc:')
              cell.font = {
                name: "Times New Roman", size: 13,
                color: {argb: '0000'},
                bold: true
              }
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: {argb: "FFDDDDDD"},
              };
            }
          });
        });

        // Add data rows to the sheet
        let dataStartRow = maxRowSpan + 1; // Determine where the data rows start
        console.log('Các bản ghi lỗi')
        // Buid data
        ws.data.fail.forEach((item, indexRow) => {
          let arrRow = item.cells.map((c) => c.content);
          console.log('arrRow')
          console.log(arrRow)
          sheet.addRow(arrRow);
        });
        // Build lỗi
        ws.data.fail.forEach((item, indexRow) => {
          console.log(item)
          item.cells.forEach((c, colIndex) => {
            console.log('Qua mỗi phần tử:')
            console.log(c)
            if (c.comment) {
              let cell = sheet.getCell(indexRow + 2, colIndex + 1);
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: {argb: "FFD700"},
              };
              if (c.comment) {
                cell.note = c.comment;
              }
              cell.font = {
                name: "Times New Roman", size: 13,
                color: {argb: 'FF0000'},
                bold: true
              }
            }
          });
        });

        // Set the height for each data row and adjust column widths based on the maximum length of content in each column
        sheet.eachRow({includeEmpty: true}, (row, rowNumber) => {
          if (rowNumber >= dataStartRow) {
            // Skip the header rows
            row.height = 30; // Adjust this value to set the desired height for data rows
            row.eachCell({includeEmpty: true}, (cell) => {
              if (cell.value) {
                const cellLength = cell.value.toString().length;
                const currentCol = sheet.getColumn(cell.col);
                // Set minimum column width for columns except the first column
                const minWidth = cell.col === 1 ? undefined : 30;

                // Adjust column width based on content
                if (minWidth !== undefined) {
                  if (!currentCol.width || currentCol.width < minWidth) {
                    currentCol.width = minWidth;
                  }
                }
                if (cellLength > currentCol.width) {
                  currentCol.width = cellLength;
                }
              }
            });
          }
        });
        // Format all rows (including header and data rows)
        sheet.eachRow((row, indexRow) => {
          row.eachCell((cell) => {
            // console.error('item từng bản ghi')
            if (indexRow != 1) {
              cell.font = {name: "Times New Roman", size: 13};
            }
            cell.alignment = {vertical: "middle", horizontal: "center"};
            cell.border = {
              top: {style: "thin"},
              left: {style: "thin"},
              bottom: {style: "thin"},
              right: {style: "thin"},
            };
          });
        });
      });

      this.$refs.uploadExcel.clearFiles();

      // Save the workbook
      wb.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = this.tenFileLoi() + ".xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    },

    createBaoLoi(data) {
      let errors = JSON.parse(LZString.decompressFromBase64(data));
      console.log("createBaoLoi");
      console.log(errors);
      console.error("row_header");
      console.error(this.row_header);
      let wb = new ExcelJS.Workbook();
      let sheet = wb.addWorksheet(this.workbook.SheetNames[0]);
      let r;
      console.error("listRows:");
      console.log(this.listRows);
      this.listRows.forEach((row, index) => {
        if (index < this.row_header) {
          r = sheet.addRow(row);
          r.font = {
            name: "Times New Roman",
            size: 12,
            bold: true,
          };
        } else {
          errors.forEach((rowError) => {
            if (rowError) {
              if (index + this.row_header + 1 == rowError.row + this.row_header + 1) {
                r = sheet.addRow(row);
                r.font = {
                  name: "Times New Roman",
                  size: 12,
                  bold: false,
                };
                rowError.cells.forEach((cellError) => {
                  try {
                    var rowCell = r.getCell(cellError.cell);
                    rowCell.note = cellError.cmt || "";
                    rowCell.fill = {
                      type: "pattern",
                      pattern: "solid",
                      fgColor: {
                        argb: "FFFFFF00",
                      },
                      bgColor: {
                        argb: "FF0000FF",
                      },
                    };
                  } catch (error) {
                    console.log(error);
                    //error
                  }
                });
              }
            }
          });
        }
        if (this.worksheet["!rows"] && this.worksheet["!rows"][index])
          r.height = this.worksheet["!rows"][index]
              ? this.worksheet["!rows"][index].hpx
              : 6;
        r.alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        };
        r.eachCell((cell) => {
          if (index < 1) {
            cell.fill = {
              bgColor: {
                indexed: 64,
              },
              fgColor: {
                theme: 0,
                tint: -0.0499893185216834,
              },
              pattern: "solid",
              type: "pattern",
            };
          }
          cell.border = {
            top: {
              style: "thin",
            },
            left: {
              style: "thin",
            },
            bottom: {
              style: "thin",
            },
            right: {
              style: "thin",
            },
          };
        });
      });
      let merges = [];
      if (this.worksheet["!merges"])
        this.worksheet["!merges"].forEach((range) => {
          var cell_range =
              XLSX.utils.encode_cell({
                c: range.s.c,
                r: range.s.r,
              }) +
              ":" +
              XLSX.utils.encode_cell({
                c: range.e.c,
                r: range.e.r,
              });
          sheet.mergeCells(cell_range);
          merges.push(cell_range);
        });
      sheet.columns.forEach((col, index) => {
        if (this.worksheet["!cols"] && this.worksheet["!cols"][index])
          col.width = this.worksheet["!cols"][index].width;
      });
      var that = this;
      this.$refs.uploadExcel.clearFiles();
      wb.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = that.tenFileLoi() + ".xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    },

    tenFileLoi() {
      console.log("tenFileLoi:");
      let result = "";
      let tenFile = "file_loi_import";
      if (this.fileNameError) {
        tenFile = this.fileNameError;
      }
      tenFile = tenFile + "_ma_truong_" + this.user.maTruong;
      if (this.hocKy) {
        tenFile = tenFile + "_hoc_ky_" + this.hocKy;
      }
      if (this.lopHoc) {
        tenFile = tenFile + "_lop_hoc_" + this.lopHoc;
      }
      result = tenFile + "_" + this.getFullDate();
      return result;
    },
    getFullDate() {
      let today = new Date();
      let yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();
      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;
      let formattedToday = dd + "-" + mm + "-" + yyyy;
      return formattedToday;
    },
    removeFile() {
      console.log("removeFile");
      this.dataValidateImport = [];
      this.base64 = "";
    },
    onProgress() {
      console.log('onProgress')
    },
    onPreview() {
      console.log('onPreview')
    },
    onError() {
      console.log('onError')
    },
    onExeed(files, file) {
      console.log('onExeed')
      console.log(files)
      console.log('file')
      console.log(file)
      this.clearValidateDialog();
      this.loiDocFile = true;
      console.log(this.fileList)
      console.log(this.lastFile)
      this.fileList = []
      console.log('********')
      this.fileList = [files[files.length - 1]];
      console.log(this.fileList)
      this.listRows = [];
      this.listRowsAll = [];
      this.workbook = "";
      this.worksheet = "";
      console.log('listRows')
      console.log(this.listRows)
      console.log(this.workbook)
      console.log(this.worksheet)
      this.$refs.uploadExcel.clearFiles();
      // this.fileList = [];
      setTimeout(() => {
        this.uploadFile(this.fileList);
      }, 500)
    },
    beforUpload() {
      console.log('beforUpload')
    },
    beforRemove() {
      console.log('beforRemove')
      this.$refs.uploadExcel.clearFiles();
    },
    uploadFile(file, fileList) {
      // Nếu fileList có 1 tệp, ghi đè tệp đó
      // if (fileList.length > 1) {
      //   this.fileList = [fileList[fileList.length - 1]];
      // } else {
      //   this.fileList = [file];
      // }
      this.loading = true;
      console.log('uploadFile')
      console.log('files:')
      console.log(file);
      console.log('list File')
      console.log(fileList);
      this.fileList = [fileList[fileList.length - 1]];
      this.dataValidateImport = [];
      this.listRows = [];
      this.listRowsAll = [];
      this.workbook = "";
      this.worksheet = "";
      this.base64 = '';
      try {
        let allowedExtensions = /(\.xlsx|\.xls)$/i;
        if (!allowedExtensions.exec(file.name)) {
          this.$alert("Vui lòng chỉ tải lên file định dạng excel", "Thông báo", {
            confirmButtonText: "Đóng",
            type: "error",
            callback: (action) => {
              this.base64 = '';
              this.$refs.uploadExcel.clearFiles();
            },
          });

          this.loading = false;
          return false;
        }
        // this.loading = true;
        console.log("1");
        let reader = new FileReader();
        console.log("2");
        this.base64 = '';
        if (file.raw) {
          this.loading = true;
          reader.readAsArrayBuffer(file.raw);
          console.log("3");
          reader.onloadstart = (e) => {
          };
          reader.onload = (e) => {
            console.log("4");
            let data = new Uint8Array(e.target.result);
            console.log("Data build");
            console.log(data);
            this.builData(data);
          };
        } else {
          console.log('không có file.raw')
          this.thongBao('error', 'Vui lòng chọn lại file')
          this.fileList = [];
          this.loading = false;
          this.$refs.uploadExcel.clearFiles();
          this.base64 = "";
          this.loiDocFile = true;
        }
      } catch (e) {
        console.log(e);
        this.thongBao("error", "Vui lòng thử lại sau ít phút");
      }
    },
    builData(data) {
      this.loading = true;
      console.log("builData");
      console.log(data);
      this.listRowsAll = [];
      this.base64 = '';
      this.workbook = XLSX.read(data, {
        type: "array",
        cellDates: true,
        cellStyles: true,
      });
      let params = [];
      console.log('workbook ở đây')
      console.log(this.workbook)
      let sheetErr = []
      this.workbook.SheetNames.forEach((sheet, index) => {
        setTimeout(() => {

          let paramSheet = [];
          this.worksheet = this.workbook.Sheets[sheet];
          console.log("worksheet");
          console.log(this.worksheet);
          console.log(sheet);
          this.loiDocFile = false;
          this.listRows = [];
          console.log('list rows')
          console.log(this.listRows)
          console.log(this.worksheet)
          console.log(this.workbook)
          try {
            console.log('try')
            this.listRows = JSON.parse(JSON.stringify(this.sheet2Arr(this.worksheet, this.workbook)));
            console.log('listRows')
            console.log(this.listRows)
          } catch (e) {
            // let msg = "Vui lòng kiểm tra lại dữ liệu file tải lên ở sheet: " + sheet;
            sheetErr.push(sheet)
            // this.thongBao("error", msg);
            // this.loading = false;
            // this.$refs.uploadExcel.clearFiles();
            // this.base64 = "";
            // this.loiDocFile = true;
            // return;
          }
          console.log("listRows");
          console.log(this.listRows);
          this.listRows.forEach((row, index) => {
            let p = {};
            row.forEach((value, index) => {
              p[index + 1] = value.toString();
            });
            paramSheet.push(p);
          });
          params.push({
            sheetName: sheet,
            data: paramSheet,
          });
          this.listRowsAll.push({
            sheetName: sheet,
            data: this.listRows,
          });
          this.loading = false;
        }, 300)
      });
      setTimeout(() => {
        if(sheetErr&&sheetErr.length){
          console.log('có sheetErr')
          console.log(sheetErr)
          let serr = sheetErr.join(" & ");
          this.thongBao('error','Vui lòng kiểm tra lại dữ liệu tại sheet: '+ serr)
          this.loading = false;
          this.$refs.uploadExcel.clearFiles();
          this.base64 = "";
          this.loiDocFile = true;
          return;
        }
        console.log('Bước cuối')
        console.log("listRowsAll");
        console.log(this.listRowsAll);
        console.error("data");
        console.error(params);
        if (!this.loiDocFile) {
          this.base64 = LZString.compressToBase64(JSON.stringify(params));
        } else {
          this.base64 = "";
        }
        this.loading = false;
      }, 1500)
    },
    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t;
      }
      if (type == "success") {
        cl = "dts-noty-success";
      }
      if (type == "warning") {
        cl = "dts-noty-warning";
      }
      if (type == "error") {
        cl = "dts-noty-error";
      }
      if (type == "info") {
        cl = "dts-noty-info";
      }
      document.querySelector('.overlay').style.display = 'block';
      this.$message({
        onClose: () => {
          document.querySelector('.overlay').style.display = 'none';
        },
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 5000,
      });
    },
    sheet2Arr(sheet, workbook) {
      console.log('sheet2Arr')
      let result = [];
      let row;
      let rowNum;
      let colNum;
      let range = XLSX.utils.decode_range(sheet["!ref"])
          ? XLSX.utils.decode_range(sheet["!ref"])
          : 0;
      for (rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
        row = [];
        for (colNum = range.s.c; colNum <= range.e.c; colNum++) {
          var nextCell =
              sheet[
                  XLSX.utils.encode_cell({
                    r: rowNum,
                    c: colNum,
                  })
                  ];
          if (typeof nextCell === "undefined") {
            row.push("");
          } else {
            if (nextCell.v instanceof Date) {
              let dateMode = workbook.Workbook.WBProps.date1904;
              let result = XLSX.SSF.format("DD/MM/YYYY", nextCell.v, {
                date1904: dateMode,
              });
              row.push(result);
            } else {
              if (typeof nextCell.v === "undefined") {
                row.push("");
              } else {
                row.push(nextCell.v);
              }
            }
          }
        }
        result.push(row);
      }
      return result;
    },
    submitUpload() {
      console.error("requireKhoiHoc");
      console.error(this.requireKhoiHoc);
      // if (!this.type) {
      //   this.$alert("Vui lòng chọn nguồn", "Thông báo", {
      //     confirmButtonText: "Đóng",
      //     type: "warning",
      //     callback: (action) => {},
      //   });
      //   return;
      // }
      if (!this.year) {
        this.$alert("Vui lòng chọn năm học", "Thông báo", {
          confirmButtonText: "Đóng",
          type: "warning",
          callback: (action) => {
          },
        });
        return;
      }
      // if (!this.importType && this.requireImportType) {
      //   this.$alert('Vui lòng chọn phương thức nhập lên', 'Thông báo', {
      //     confirmButtonText: 'Đóng',
      //     type: 'warning',
      //     callback: action => {
      //     }
      //   });
      //   return;
      // }

      if (this.requireLstHocKy) {
        if (!this.hocKy || this.hocKy.length == 0 || this.hocKy == "") {
          this.thongBao("error", "Vui lòng bổ sung học kỳ");
          return;
        }
      }
      // if (this.requireKhoiHoc && !this.khoiHoc) {
      //   this.thongBao('error', 'Vui lòng chọn khối học')
      //   return;
      // }
      // if (!this.hideClass && !this.lopHoc) {
      //   this.thongBao('error','Vui lòng chọn lớp học.')
      //   return;
      // }
      if (!this.base64) {
        this.thongBao("error", "Vui lòng bổ sung file");
        return;
      }
      this.loading = true;
      let params = {
        namHoc: this.year,
        type: this.type,
        data: this.base64,
        maLop: this.lopHoc,
        capHoc: this.capHoc,
        qlnt: this.user.qlnt,
        hocKy: this.hocKy,
        dataType: this.dataType,
      };
      if (!this.hideClass) {
        params.maLop = this.lopHoc;
      }
      if (this.requireKhoiHoc) {
        params.khoiHoc = this.khoiHoc;
      }
      if (this.requireLstHocKy) {
        if (!this.hocKy || this.hocKy.length == 0 || this.hocKy == "") {
          this.thongBao("error", "Vui lòng bổ sung học kỳ");
          return;
        }
        params.hocKy = this.hocKy;
      }
      console.log("validate");
      console.log(params);
      this.dataValidateImport = [];
      api
          .post("/hocbadientu-sync-service/api/v2/import/data/validate", params)
          .then((data) => {
            console.log("phản hồi validate:");
            console.log(data);
            this.hasSuccess = true;
            this.hasFail = false;
            if (data.data.code == 200) {
              console.log('case 200')
              let vld = JSON.parse(LZString.decompressFromBase64(data.data.data));
              this.dataValidateImport = vld.data;
              console.log('vld:')
              console.log(vld)
              console.log(this.dataValidateImport);
              if (this.dataValidateImport) {
                for (let i = 0; i < this.dataValidateImport.length; i++) {
                  console.log('sheet: ' + (i + 1) + ' ' + this.dataValidateImport[i].sheetName)
                  let dataSheet = this.dataValidateImport[i].data.header;
                  console.log('dataSheet')
                  console.log(dataSheet)
                  if (dataSheet) {
                    let headerSheet = dataSheet[0].cells.map((c) => c.content);
                    console.log('headerSheet')
                    console.table(headerSheet)
                  }
                }
              }
              this.show_info_validate = true;
              this.hasSuccess = !vld.hasSuccess;
              this.hasFail = vld.hasFail;
              console.log('hasSuccess:' + this.hasSuccess)
              console.log('hasFail:' + this.hasFail)
            } else if (data.data.code == 313) {
              console.log('case 313')
              this.$confirm(data.data.msg, "Lỗi nhập dữ liệu", {
                confirmButtonText: "Tải về báo lỗi",
                cancelButtonText: "Hủy",
                closeOnClickModal: false,
                center: true,
                type: "warning",
              })
                  .then(() => {
                    this.createBaoLoi(data.data.data);
                  })
                  .catch(() => {
                    this.$refs.uploadExcel.clearFiles();
                  });
            } else {
              console.log('case other')
              console.log(data)
              this.thongBao('error', data.data.msg)
              this.$refs.uploadExcel.clearFiles();
              this.clearValidateDialog();
              // this.$alert(data.data.msg, "Thông báo", {
              //   confirmButtonText: "Đóng",
              //   center: true,
              //   type: "error",
              //   callback: (action) => {
              //   },
              // });
            }
            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
            // this.thongBao('success', 'Hệ thống đang cập nhật dữ liệu. Vui lòng chờ trong ít phút.');
          });
    },
    dowloadFileCoDuLieu() {
      console.log("dowloadFileCoDuLieu");
      if (!this.fileHasData) {
        this.thongBao("error", "Chưa có file mẫu.");
      } else {
        this.$confirm("Xác nhận tải xuống file biểu mẫu?", "Thông báo", {
          confirmButtonText: "Đồng ý",
          cancelButtonText: "Hủy",
          closeOnClickModal: false,
        })
            .then((_) => {
              let params = {
                qlnt: this.user.qlnt,
                namHoc: this.year,
                capHoc: this.capHoc,
              };
              api
                  .post(this.linkFileHasData, params)
                  .then((data) => {
                    if (data.data.code == 200) {
                      window.open(data.data.data, "_blank");
                    } else {
                      this.$message({
                        customClass: "dts-noty-error",
                        showClose: true,
                        message: data.data.msg,
                        type: "error",
                        duration: 3000,
                      });
                    }
                  })
                  .catch((e) => {
                    console.log(e);
                    this.$message({
                      customClass: "dts-noty-error",
                      showClose: true,
                      message: "Lỗi kết nối",
                      type: "error",
                      duration: 3000,
                    });
                  });
            })
            .catch((_) => {
            });
      }
    },
    dowloadFile() {
      if (!this.year) {
        this.$alert("Vui lòng chọn năm học", "Thông báo", {
          confirmButtonText: "Đóng",
          type: "warning",
          callback: (action) => {
          },
        });
        return;
      }
      let params = {
        hocKy: this.hocKy,
        namHoc: this.year,
        khoiHoc: this.khoiHoc,
        type: this.type,
        maLop: this.lopHoc,
        qlnt: this.type,
      };
      let uri = this.uriDowload ? this.uriDowload : this.linkFileHasData;
      api
          .post(uri, params)
          .then((data) => {
            console.log("Trả về:");
            console.log(data);
            if (data.data.code == 200) {
              window.location.href = data.data.data;
            } else {
              this.$message({
                customClass: "dts-noty-error",
                showClose: true,
                message: data.data.msg,
                type: "error",
                duration: 3000,
              });
            }
          })
          .catch((e) => {
            console.log(e);
            this.$message({
              customClass: "dts-noty-error",
              showClose: true,
              message: "Lỗi kết nối",
              type: "error",
              duration: 3000,
            });
          });
    },
    getTenLop(malop) {
      let lop = this.danh_sach_lop_hoc.find((item) => {
        return item.maLop == malop;
      });
      if (lop) {
        return lop.tenLop;
      }
      return "";
    },
  },
  mounted() {
    this.getKhoiHoc();
    if (this.user) {
      this.type = this.user.qlnt;
      this.getRowHeader();
    }
  },
};
</script>
<style scoped>
.el-link {
  font-weight: 400;
  font-size: 13px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 4px;
}

.loiThongTin {
  background: red;
}

.table {
  overflow: auto;
  height: 50vh;
}

.table thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

.table tbody th {
  position: sticky;
  left: 0;
}

.el-tabs__content {
  height: 70vh !important;
  overflow: scroll !important;
}

.noiDungHeader {
  width: max-content !important;
  margin: 0 auto !important;
}

.el-upload-list__item .el-icon-close {
  display: block !important;
}
</style>
