<template>
<div>
    
    <input id="parameters" type="hidden" />
    <input id="pdfBase64" type="hidden" />
</div>

</template>

<script>
  import $ from 'jquery';
$(function() {
      // use jquery
    //   $('body').css('background-color', 'orange');

      // use selectize, s jquery plugin
  });


    export default {
        props: [],
        watch: {
        },
        data() {
            return {
            }
        },
        mounted: function () {
            console.log("mounted !!!!!");
        },
        methods: {

        }

    }
</script>


<style scoped>
</style>
