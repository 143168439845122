import api from "../api";
import {sprintf} from "sprintf-js";
import restApi from "../_helpers/rest_api";

export const schoolService = {
    getListPGD,
    getListShool,
    getInfo,
    updateInfo,
    dongBo,
    getHistorySync
};

function getListPGD(data){
    let uri = api.school.listPgd;
    return restApi.get(uri,data);
}

function dongBo(data){
    let uri = api.school.dongBo;
    return restApi.post(uri,data,300000);
}

function getListShool (data){
    let uri = api.school.listSchool;
    return restApi.get(uri,data)
}

function getInfo(data){
    let uri = api.school.infoSchool;
    return restApi.get(uri,data)
}

function updateInfo(data){
    let uri = api.school.updateInfoTruong;
    console.log("updateInfo")
    console.log(data)
    if(data.type == 1){
         uri = api.school.updateInfoChuKy;
    }
    else{
        uri = api.school.updateInfoTruong;
    }
    return restApi.post(uri,data);
}

function getHistorySync (data){
    let uri = api.school.historySync;
    return restApi.get(uri,data);
}