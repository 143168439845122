<template>
  <div>

    <div class="el-loading-mask is-fullscreen" style="z-index: 2000;">
      <div class="el-loading-spinner">
        <svg viewBox="25 25 50 50" class="circular">
          <circle cx="50" cy="50" r="20" fill="none" class="path"></circle>
        </svg>
        <!---->
      </div>
    </div>
    <el-dialog
        :close-on-press-escape="false"
        :close-on-click-modal="false" :title="title" width="425px" class="dialog__alert" :destroy-on-close="true"
        :before-close="closeDialog" center :visible.sync="showDialog" append-to-body>
      <div class="text-center">{{ message }}</div>
      <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="closeDialog()">Đóng</el-button>
            </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  mapState,
  mapActions,
  mapMutations
} from 'vuex';
import util from "../util";

export default {
  name: "CheckCode",
  components: {},
  data() {
    return {
      code: "",
      showDialog: false,
      title: 'Thông báo',
    }
  },
  computed: {
    ...mapState('alert', [
      "type",
      "message",
    ]),
    ...mapState('account', [
      'user'
    ]),
  },
  mounted() {
    console.log("mount check code")
    this.code = this.$route.query.code;
    if (this.code) {
      console.log("Có code đăng nhập")
      window.localStorage.removeItem('messInfoOtpCode');
      window.localStorage.removeItem('OtpCode');
      window.localStorage.removeItem('user');
      console.log("check code smas")
      this.loginWithK12(this.code)
    } else {
      console.log("Không có code đăng nhập")
      this.logout();
      this.$router.push({
        name: 'Login'
      });
    }
  },
  methods: {
    ...mapActions('sso', ['loginWithK12']),
    ...mapMutations('account', [
      "logout",
    ]),
    ...mapActions({
      clearAlert: 'alert/clear'
    }),
    closeDialog() {
      this.clearAlert();
      this.$router.push({
        name: 'Login'
      });
    }
  },
  watch: {
    message(val) {
      console.log('alert:', val)
      if (val) {
        this.showDialog = true;
      }
    }
  }
}
</script>
<style scoped>
</style>