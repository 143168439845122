import {pupilService} from "../_services";
import {router} from "../_router";

const user = JSON.parse(localStorage.getItem('user'));
const state = {
    listHocBa: [],
    requestHocSinhSuccess: false,
    requestMessage: '',
    listRequest: [],
    listHs: null,
    lstHocBa: null,
    listHocSinh : null,
    infoHocSinh : null,
};

const actions = {
    getInfoHocSinh({commit}, data = null){
        pupilService.getInfo(data = null).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('setDataHocSinh', response.data.data);
                } else {
                    commit('setDataHocSinh', null);
                }
            }
        ).catch(function (e) {
            commit('setDataHocSinh', null);
        });
    },


    getListHocBa({commit}, data = null) {
        
        pupilService.getListHocBa(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('getListHocBa', response.data.data);
                } else {
                    commit('getListHocBa', null);
                }
            }
        ).catch(function (e) {
            commit('getListHocBa', null);
        });
    },

    getListHocSinh({commit}, data = null) {
        pupilService.getListHocSinh(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('getListHocSinh', response.data.data);
                } else {
                    commit('getListHocSinh', []);
                }
            }
        ).catch(function (e) {
            console.log(e);
        });
    },


    requestHocSinh({commit}, maTruong = null) {
        pupilService.requestHocBa(maTruong).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('request', "Yêu cầu của bạn đã được gửi thành công");
                } else {
                    commit('request', response.data.msg);
                }
            }
        ).catch(function (e) {
            console.log(e);
        });
    },
    clearRequest({commit}) {
        commit('clearRequest');
    },
    getListRequest({commit}, data) {
        pupilService.getListRequest(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit("getListRequest", response.data.data);
                } else {
                    commit("getListRequest", []);
                }
            }
        ).catch(function (e) {
            console.log(e);
            commit("getListRequest", []);
        });
    },
    getListHs({commit}, data) {
        pupilService.getListHS(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit("getListHs", response.data.data);
                } else {
                    commit('getListHs', null)
                }
            }
        ).catch(function (e) {
            commit('getListHs', null)
        })
    },
    getLstHocBa({commit}, data) {
        commit('getHocBa', null);
        pupilService.listHocBa(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit("getHocBa", response.data.data);
                } else {
                    commit('getHocBa', null);
                }
            }
        ).catch(function (e) {
            commit('getHocBa', null);
        })
    }


};
const mutations = {
    getListHocBa(state, data) {
        state.listHocBa = data;
    },
    getListHocSinh(state, data) {
        state.listHocSinh = data;
    },
    request(state, data) {
        state.requestHocSinhSuccess = true;
        state.requestMessage = data;

    },
    clearRequest(state) {
        state.requestHocSinhSuccess = false;
        state.requestMessage = '';
    },
    getListRequest(state, data) {
        state.listRequest = data;
    },
    getListHs(state, data) {
        state.listHs = data
    },
    getHocBa(state, data) {
        state.lstHocBa = data;
    },
    setDataHocSinh(state, data){
        state.infoHocSinh = data;
    }
};
export const hocsinh = {
    namespaced: true,
    state,
    actions,
    mutations
};
