import {userService} from "./user.service";
// import {userSystemService} from "./userSystem.service";
// import {roleSystemService} from "./roleSystem.service";
import {pupilService} from "./hocsinh.service";
import {teacherService} from "./giaovien.service";
import {hocbaService} from "./hocba.service";
import {schoolService} from "./school.service";
import {LoginSsoService} from "./loginsso.service";
import {testService} from "./test.service";
import {configKy} from "./configKy.service";
import {reportService} from "./report.service";


export {
    userService,
    // userSystemService,
    // roleSystemService,
    hocbaService,
    pupilService,
    teacherService,
    LoginSsoService,
    schoolService,
    testService,
    configKy,
    reportService,
};
