var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading.fullscreen.lock",
            value: _vm.loadingScreen,
            expression: "loadingScreen",
            modifiers: { fullscreen: true, lock: true },
          },
        ],
        staticClass:
          "page__title d-flex align-items-center justify-content-between",
      },
      [_c("h1", { staticClass: "title" }, [_vm._v("Báo cáo dữ liệu học bạ")])]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "box-upload-file page__header" },
      [
        _c(
          "el-row",
          { staticClass: "margin-bottom-report", attrs: { gutter: 24 } },
          [
            _c(
              "el-col",
              { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 } },
              [
                _c("label", [_vm._v("Năm học")]),
                _vm._v(" "),
                _c("eselect", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    collapseTags: "",
                    placeholder: "Chọn",
                    filterable: "",
                    data: _vm.list_nam_hoc,
                    fields: ["name", "value"],
                  },
                  model: {
                    value: _vm.dataSearch.namHoc,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "namHoc", $$v)
                    },
                    expression: "dataSearch.namHoc",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.user.role != 4
              ? _c(
                  "el-col",
                  { attrs: { xs: 12, sm: 8, md: 6, lg: 6, xl: 6 } },
                  [
                    _c("label", [_vm._v("Đơn vị")]),
                    _vm._v(" "),
                    _c("eselect", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        disabled: _vm.user.role != 7,
                        collapseTags: "",
                        placeholder: "Chọn",
                        filterable: "",
                        data: _vm.list_don_vi,
                        fields: ["name", "value"],
                      },
                      on: {
                        change: function ($event) {
                          return _vm.chonDonVi()
                        },
                      },
                      model: {
                        value: _vm.dataSearch.donVi,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataSearch, "donVi", $$v)
                        },
                        expression: "dataSearch.donVi",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-col",
              { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 } },
              [
                _c("label", [_vm._v("Cấp học")]),
                _vm._v(" "),
                _c("eselect", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    disabled: _vm.list_cap_hoc.length == 1,
                    collapseTags: "",
                    placeholder: "Chọn",
                    filterable: "",
                    data: _vm.list_cap_hoc,
                    fields: ["name", "value"],
                  },
                  on: {
                    change: function ($event) {
                      return _vm.chonDonVi()
                    },
                  },
                  model: {
                    value: _vm.dataSearch.capHoc,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "capHoc", $$v)
                    },
                    expression: "dataSearch.capHoc",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.user.role != 4
              ? _c(
                  "el-col",
                  { attrs: { xs: 12, sm: 8, md: 6, lg: 6, xl: 6 } },
                  [
                    _c("label", [_vm._v("Trường học")]),
                    _vm._v(" "),
                    _c("eselect", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        collapseTags: "",
                        placeholder: "Chọn",
                        filterable: "",
                        data: _vm.list_truong_hoc,
                        fields: ["name", "value"],
                      },
                      model: {
                        value: _vm.dataSearch.truongHoc,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataSearch, "truongHoc", $$v)
                        },
                        expression: "dataSearch.truongHoc",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-col",
              { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 } },
              [
                _c("label", [_vm._v("Khối học")]),
                _vm._v(" "),
                _c("eselect", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    collapseTags: "",
                    placeholder: "Chọn",
                    filterable: "",
                    data: _vm.danh_sach_khoi_hoc,
                    fields: ["label", "value"],
                  },
                  on: {
                    change: function ($event) {
                      return _vm.chonKhoiHoc()
                    },
                  },
                  model: {
                    value: _vm.dataSearch.khoiHoc,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "khoiHoc", $$v)
                    },
                    expression: "dataSearch.khoiHoc",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 } },
              [
                _c("label", [_vm._v("Lớp học")]),
                _vm._v(" "),
                _c("eselect", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    collapseTags: "",
                    placeholder: "Chọn",
                    filterable: "",
                    data: _vm.danh_sach_lop_hoc,
                    fields: ["tenLop", "maLop"],
                  },
                  model: {
                    value: _vm.dataSearch.lopHoc,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "lopHoc", $$v)
                    },
                    expression: "dataSearch.lopHoc",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              {
                staticClass: " lg",
                attrs: { xs: 12, sm: 8, md: 8, lg: 8, xl: 8 },
              },
              [
                _c("div", { staticStyle: { color: "transparent" } }, [
                  _vm._v("Xem báo cáo"),
                ]),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-right": "10px" },
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.viewBaoCao()
                      },
                    },
                  },
                  [_vm._v("Xem báo cáo")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.getDataReport()
                      },
                    },
                  },
                  [_vm._v("Tải xuống")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("p", { staticClass: "red", staticStyle: { "margin-top": "0px" } }, [
      _vm._v(
        "Lưu ý: Để báo cáo đúng với dữ liệu thực tế, nhà trường vui lòng truy cập menu Tổng quan để tổng hợp báo cáo."
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "page__header" }, [
      _c(
        "div",
        { staticClass: "listNew mb-5" },
        [
          _c("PreviewBaoCao", {
            attrs: {
              "data-view": _vm.dataView,
              name: "Báo cáo dữ liệu học bạ",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }