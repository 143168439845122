import api from "../api";
import { sprintf } from "sprintf-js";
import restApi from "../_helpers/rest_api";
import { fn } from "jquery";

export const pupilService = {
    getListHocBa,
    requestHocBa,
    getListRequest,
    getListHS,
    listHocBa,
    getListHocSinh,
    getInfo
};

function getInfo(data){
    let uri = api.hocsinh.info;
    let params  = {};
    return restApi.get(uri, params);
}

function getListHocBa(data) {
    let uri = api.hocsinh.getHocBa;
    let params = {
        start: 0,
        limit: 100,
    };
    if (data){
        params  = data
    }
    return restApi.get(uri, params);
}

function getListHocSinh(data) {
    let uri = api.hocsinh.getListHocSinh;
    let params = {
        start: 0,
        limit: 100,
    };
    if (data){
        params  = data
    }
    return restApi.get(uri, params);
}

function requestHocBa(maTruong) {
    let uri = api.hocsinh.request;
    let params = {
        maTruong: maTruong ? maTruong : '',
    };
    return restApi.post(uri, params);
}

function getListRequest(data) {
    let uri = api.hocsinh.listRequest;
    return restApi.get(uri, data);
}


function getListHS(data){
    let uri = api.hocsinh.getListHS;
    return restApi.get(uri,data);
}

function listHocBa(data){
    let uri = api.hocsinh.listHocBa;
    return restApi.get(uri,data)
}
