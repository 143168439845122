<template>
    <div>
        <div class="bieudo" ref="bieudokhongxepchong"></div>
    </div>
</template>
<style>
</style>
<script>
import ApexCharts from 'apexcharts'

export default {
    props: ['dulieu', 'label'],
    data() {
        return {}
    },
    mounted() {
        if (this.dulieu) {
            this.bindData(this.dulieu)
        }
    },
    components: {
        apexchart: ApexCharts,
    },
    watch: {
        dulieu: function (newVal) {
            if (newVal) {
                let data_demo = newVal;
                console.log("Dữ liệu được làm mới trong biểu đồ tổng quan")
                console.log(data_demo)
                this.bindData(data_demo)
            }
        },
    },
    methods: {
        bindData(e) {
            if (e.length > 0) {
                let series = e;
                let categories = this.label;
                let element = this.$refs.bieudokhongxepchong;
                console.log("series, categories, element")
                console.log(series, categories, element)
                this.generateChart(series, categories, element)
            }
        },
        generateChart(series, categories, element) {
            let chart;
            const options = {
                series,
                colors: ['#00a65a', '#FFA200', '#60BFFF', '#d14b6a', '#f51a1a', '#FB4444'],
                labels: this.label,
                chart: {
                    type: "donut",
                    width: '350',
                    toolbar: {
                        show: false
                    },
                    events: {
                        mounted: (chartContext, config) => {
                            console.log("mounted", chartContext, config, config.globals.yRange);
                        },
                        updated: (chartContext, config) => {
                        }
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val, opts) {
                        return val.toFixed(2) + '%';
                    },
                    style: {
                        fontSize: '12px',
                        fontWeight: 'bold',
                    },
                },
                noData: {
                    text: "Biểu đồ chưa có dữ liệu",
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '14px',
                        fontFamily: undefined
                    }
                },
                plotOptions: {
                    pie: {
                        startAngle: 0,
                        endAngle: 360,
                        expandOnClick: true,
                        offsetX: 0,
                        offsetY: 0,
                        customScale: 1,
                        dataLabels: {
                            offset: 0,
                            minAngleToShowLabel: 10
                        },
                        donut: {
                            size: '45%',
                            background: 'transparent',
                            labels: {
                                show: true,
                                name: {
                                    show: true,
                                    fontSize: '12px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 100,
                                    color: undefined,
                                    offsetY: 0,
                                    formatter: function (val) {
                                        return val
                                    }
                                },
                                value: {
                                    show: true,
                                    fontSize: '12px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 100,
                                    color: undefined,
                                    offsetY: 6,
                                    formatter: function (val) {
                                        return val
                                    }
                                },
                                total: {
                                    show: true,
                                    showAlways: true,
                                    label: 'Tổng số',
                                    fontSize: '12px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 100,
                                    color: '#373d3f',
                                    formatter: function (w) {
                                        let tongso = w.globals.seriesTotals.reduce((a, b) => {
                                            return a + b
                                        }, 0)
                                        return tongso.toLocaleString()
                                    }
                                }
                            }
                        },
                    }
                },
                fill: {
                    opacity: 1
                },
                legend: {
                    position: "top",
                    horizontalAlign: "center"
                },
                grid: {
                    padding: {
                        left: 13.5,
                        right: 0
                    },
                    xaxis: {
                        lines: {
                            show: false
                        }
                    },
                }
            };
            chart = new ApexCharts(element, options);
            chart.render();
            chart.updateOptions([options])
        }
    },
}
</script>
<style scoped="scoped">
.apexcharts-canvas {
    margin: 0 auto !important;
}
</style>