<template>
    <div id="listConfig" class="page page-file file detailHocBa" v-loading.fullscreen.lock="fullscreenLoading">
        <div class="box-upload-file">
            <div class="d-flex flex-wrap">
                <div class="d-inline-block pl-3 pr-3 pb-3">
                    <h5 class="font-weight-bold m-0">Thống kê ký học bạ</h5>
                </div>

            </div>
            <div class="pl-3">
                <el-form :rules="rulesSearch" ref="searchForm" :model="searchForm" :hide-required-asterisk="true">
                    <el-form-item label="Năm học" style="width: 250px" class="d-inline-block" prop="namHoc">
                        <!--                        <el-date-picker style="width: 150px;" v-model="searchForm.namHoc" type="year"-->
                        <!--                                        placeholder="Năm học" value-format="yyyy">-->
                        <!--                        </el-date-picker>-->
                        <SelectNamHoc v-model="searchForm.namHoc"/>
                    </el-form-item>
                    <el-form-item label="Đối tượng" style="width: 300px" class="d-inline-block" prop="namHoc">
                        <el-select v-model="searchForm.doiTuong" placeholder="Chọn đối tượng">
                            <el-option
                                v-for="item in [{value : 3,label: 'Hiệu trưởng'},{value : 2,label: 'GVCN'}]"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item class="d-inline-block">
                        <el-button type="primary" @click="submitSearch('searchForm')">Tìm kiếm</el-button>
                    </el-form-item>
                    <el-form-item class="d-inline-block">
                        <el-button icon="el-icon-document" type="primary" @click.prevent="dowloadFile">Xuất báo cáo</el-button>
                    </el-form-item>
                </el-form>
            </div>

        </div>
        <div class="page__content">
            <div class="row">
                <div class="col-md-3 col-6">
                    <PieChart title="Tổng quan" :data-pie="this.thongKe.caTinh"></PieChart>
                    <table class="table table-bordered mt-4">
                        <tbody>
                        <tr>
                            <th>Tổng</th>
                            <td>{{ thongKe.caTinh[0] + thongKe.caTinh[1] }}</td>
                        </tr>
                        <tr>
                            <th>Chưa ký</th>
                            <td>{{ thongKe.caTinh[0] }}</td>
                        </tr>
                        <tr>
                            <th>Đã ký</th>
                            <td>{{ thongKe.caTinh[1] }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-3 col-6">
                    <PieChart title="Cấp 1" :data-pie="this.thongKe.cap1"></PieChart>
                    <table class="table table-bordered mt-4">
                        <tbody>
                        <tr>
                            <th>Tổng</th>
                            <td>{{ thongKe.cap1[0] + thongKe.cap1[1] }}</td>
                        </tr>
                        <tr>
                            <th>Chưa ký</th>
                            <td>{{ thongKe.cap1[0] }}</td>
                        </tr>
                        <tr>
                            <th>Đã ký</th>
                            <td>{{ thongKe.cap1[1] }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-3 col-6">
                    <PieChart title="Cấp 2" :data-pie="this.thongKe.cap2"></PieChart>
                    <table class="table table-bordered mt-4">
                        <tbody>
                        <tr>
                            <th>Tổng</th>
                            <td>{{ thongKe.cap2[0] + thongKe.cap2[1] }}</td>
                        </tr>
                        <tr>
                            <th>Chưa ký</th>
                            <td>{{ thongKe.cap2[0] }}</td>
                        </tr>
                        <tr>
                            <th>Đã ký</th>
                            <td>{{ thongKe.cap2[1] }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-3 col-6">
                    <PieChart title="Cấp 3" :data-pie="this.thongKe.cap3"></PieChart>
                    <table class="table table-bordered mt-4">
                        <tbody>
                        <tr>
                            <th>Tổng</th>
                            <td>{{ thongKe.cap3[0] + thongKe.cap3[1] }}</td>
                        </tr>
                        <tr>
                            <th>Chưa ký</th>
                            <td>{{ thongKe.cap3[0] }}</td>
                        </tr>
                        <tr>
                            <th>Đã ký</th>
                            <td>{{ thongKe.cap3[1] }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="mt-4">
                <h4>Thống kê theo phòng</h4>
                <el-table
                    :row-key="getRowKeys"
                    :expand-row-keys="expandArr"
                    class="configSo--phong"
                    border
                    height="600"
                    stripe
                    @expand-change="expandChange"
                    :data="detailReport.baoCaoKyTheoPhongs">
                    <el-table-column type="expand">
                        <template slot-scope="props">
                            <el-table
                                v-loading="props.row.loading"
                                element-loading-text="Đang lấy dữ liệu..."
                                element-loading-spinner="el-icon-loading"
                                element-loading-background="rgba(255, 255, 255, 0.8)"
                                class="configSo--truong"
                                border
                                fit
                                height="300"
                                :data="props.row.baoCaoKyTheoTruongs">
                                <el-table-column label="STT" style="width: 60px" width="60" type="index"
                                                 class-name="text-center"
                                                 :index="indexMethod">
                                </el-table-column>
                                <el-table-column
                                    label="Tên Phòng GD"
                                    property="tenPhong"
                                    width="400"
                                ></el-table-column>
                                <el-table-column
                                    label="Mã trường học"
                                    width="150"
                                    property="maTruongHoc"
                                >
                                </el-table-column>
                                <el-table-column
                                    label="Tên trường học"
                                    width="400"
                                    property="tenTruongHoc"
                                >
                                </el-table-column>
                                <el-table-column
                                    label="Tổng số"
                                    property="tongSoHocBa"

                                >
                                </el-table-column>
                                <el-table-column
                                    label="Chưa ký"
                                    property="tongHocBaChuaKy"
                                >
                                </el-table-column>
                                <el-table-column
                                    label="Đã ký"
                                    property="tongHocBaDaKy"
                                >
                                </el-table-column>
                                <el-table-column
                                    label="Tỷ lệ chưa ký"
                                    property="phanTramChuaKy"
                                >
                                </el-table-column>
                                <el-table-column
                                    label="Tỷ lệ đã ký"
                                    property="phanTramDaKy"
                                >
                                </el-table-column>

                            </el-table>

                        </template>
                    </el-table-column>
                    <el-table-column label="STT" style="width: 60px" width="60" type="index" class-name="text-center"
                                     :index="indexMethod">
                    </el-table-column>
                    <el-table-column
                        label="Tên Phòng GD"
                        property="tenPhong"
                        width="400"
                    ></el-table-column>
                    <el-table-column
                        label="Mã trường học"
                        width="150"

                    >
                    </el-table-column>
                    <el-table-column
                        label="Tên trường học"
                        width="400"

                    >
                    </el-table-column>
                    <el-table-column
                        label="Tổng số"
                        property="tongSoHocBa"

                    >
                    </el-table-column>
                    <el-table-column
                        label="Chưa ký"
                        property="tongHocBaChuaKy"
                    >
                    </el-table-column>
                    <el-table-column
                        label="Đã ký"
                        property="tongHocBaDaKy"
                    >
                    </el-table-column>
                    <el-table-column
                        label="Tỷ lệ chưa ký"
                        property="phanTramChuaKy"
                    >
                    </el-table-column>
                    <el-table-column
                        label="Tỷ lệ đã ký"
                        property="phanTramDaKy"
                    >
                    </el-table-column>
                </el-table>
            </div>
        </div>

    </div>
</template>

<script>
    import SelectNamHoc from "../../components/SelectNamHoc";
    import {mapState, mapActions} from 'vuex';
    import PieChart from "./PieChart";


    export default {
        name: "ThongKeKy",
        components: {
            PieChart,
            SelectNamHoc
        },

        computed: {
            ...mapState("reportKy", [
                "detailReport"
            ]),
            ...mapState("account", [
                "user"
            ])
        },

        data() {

            return {
                expandArr: [],
                thongKe: {
                    caTinh: [0, 0],
                    cap1: [0, 0],
                    cap2: [0, 0],
                    cap3: [0, 0],
                },

                loading: true,
                polar: {
                    title: {
                        text: 'Cả tỉnh',
                        left: 'center'
                    },
                    legend: {
                        orient: 'vertical',
                        left: 'left',
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                    series: [
                        {
                            name: 'Cả tỉnh',
                            type: 'pie',
                            radius: '50%',
                            data: [
                                {value: 1048, name: 'Đã ký'},
                                {value: 735, name: 'Chưa ký'},

                            ],
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        }
                    ],
                    animationDuration: 2000
                },

                fullscreenLoading: true,
                rulesSearch: {
                    namHoc: [{
                        required: true,
                        message: 'Chưa chọn năm học',
                        trigger: 'change'
                    }],
                },
                searchForm: {
                    namHoc: '',
                    doiTuong: 3,
                }
            }
        },
        mounted() {

            this.searchForm.namHoc = this.getFullYear();

            let maPhong = '';
            if (this.user.role == 6) {
                maPhong = this.user.maPhong;
            }

            this.getReport({
                namHoc: this.searchForm.namHoc,
                type: this.searchForm.doiTuong,
                maSo: this.user.maSo,
                maPhong: maPhong
            })
        },
        methods: {
            ...mapActions('reportKy', [
                "getReport",
                "getReportPhong"
            ]),

            dowloadFile(){
                const link = document.createElement('a')
                link.href = '/files/bao_cao_ky.xlsx'
                link.download = 'Bao_cao_ky'
                link.click()
                URL.revokeObjectURL(link.href)
            },

            getRowKeys(row) {
                return row.maPhong;
            },

            expandChange(row, expanded) {
                if (this.expandArr.length && this.expandArr[0] == row.maPhong) {
                    this.expandArr = [];
                } else {
                    this.expandArr = [row.maPhong];
                }
                if (row && !row.baoCaoKyTheoTruongs.length && row.loading) {
                    this.getReportPhong({
                        namHoc: this.searchForm.namHoc,
                        type: this.searchForm.doiTuong,
                        maSo: this.user.maSo,
                        maPhong: row.maPhong,
                    })
                }
            },
            indexMethod(index) {
                return (index) + 1;
            },
            getFullYear() {
                let d = new Date();
                return d.getFullYear();
            },
            submitSearch(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let maPhong = '';
                        if (this.user.role == 6) {
                            maPhong = this.user.maPhong;
                        }
                        this.fullscreenLoading = true;
                        this.getReport({
                            namHoc: this.searchForm.namHoc,
                            type: this.searchForm.doiTuong,
                            maSo: this.user.maSo,
                            maPhong: maPhong
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }
        },
        watch: {
            detailReport() {
                this.detailReport.baoCaoKyTheoCaps.map(item => {
                    if (item.capHoc == 1) {
                        this.thongKe.cap1 = [item.tongHocBaChuaKy, item.tongHocBaDaKy];
                    }
                    if (item.capHoc == 2) {
                        this.thongKe.cap2 = [item.tongHocBaChuaKy, item.tongHocBaDaKy];
                    }
                    if (item.capHoc == 3) {
                        this.thongKe.cap3 = [item.tongHocBaChuaKy, item.tongHocBaDaKy];
                    }
                    return item;
                })
                this.thongKe.caTinh = [this.detailReport.tongHocBaChuaKy, this.detailReport.tongHocBaDaKy];

                this.fullscreenLoading = false;
            },

        }
    }
</script>

<style scoped>

</style>
