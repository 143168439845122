var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading.fullScreen,
          expression: "loading.fullScreen",
        },
      ],
      staticClass: "page page-user-info",
      attrs: {
        "element-loading-text": "Loading...",
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(255, 255, 255, 0.8)",
      },
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "dts-content", attrs: { gutter: 10 } },
        [
          _vm.show_import_bigdata
            ? _c(
                "el-col",
                { staticClass: "text-center", attrs: { span: 24 } },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "uploadBia",
                      staticClass: "upload-demo text-center",
                      attrs: {
                        drag: "",
                        action: "/",
                        "file-list": _vm.fileList,
                        "on-change": _vm.uploadFile,
                        "on-remove": _vm.uploadFile,
                        accept: ".pdf,.xlsx,.xls,.zip,.zar",
                        "auto-upload": false,
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-upload" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "el-upload__text" }, [
                        _vm._v("Kéo thả hoặc "),
                        _c("em", [_vm._v("click ở đây")]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.phanTramUpLoad && _vm.phanTramUpLoad != 0
                    ? _c(
                        "div",
                        [
                          _c("el-progress", {
                            staticStyle: { width: "50%", margin: "0 auto" },
                            attrs: { percentage: _vm.phanTramUpLoad },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "text-center mt-3" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "primary",
                            disabled:
                              _vm.phanTramUpLoad != 0 &&
                              _vm.phanTramUpLoad < 100
                                ? true
                                : false,
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.uploadMutilFile($event)
                            },
                          },
                        },
                        [_vm._v("Tải lên dữ liệu\n          ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("el-col", { attrs: { lg: 3, md: 3, sm: 3 } }),
          _vm._v(" "),
          _c(
            "el-col",
            {
              staticClass: "bg-smoke text-center  card-info",
              attrs: { lg: 18, md: 18, sm: 18, xs: 24 },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c("el-col", { staticClass: "text-center mt-4" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "img d-flex align-items-center justify-content-center",
                      },
                      [
                        _c("div", { staticClass: "icon" }, [
                          _c("i", { staticClass: "el-icon-school" }),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("h3", { staticClass: "mt-2" }, [
                      _vm._v(_vm._s(_vm.infoSchool.tenTruongHoc)),
                    ]),
                    _vm._v(" "),
                    _c(
                      "p",
                      { staticClass: "mt-2" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: "Cập nhật thông tin",
                              placement: "top",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.showUpdate($event)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-edit-outline",
                                  staticStyle: { "font-size": "14px" },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: "Đổi mật khẩu",
                              placement: "top",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.showUpdatePass($event)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-key",
                                  staticStyle: { "font-size": "14px" },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "mt-2", attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { staticClass: "text-left", attrs: { sm: 12, xs: 24 } },
                    [
                      _c("el-card", { attrs: { shadow: "always" } }, [
                        _c("div", { staticClass: "iconInfo" }, [
                          _c("i", { staticClass: "el-icon-user" }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "textInfo" }, [
                          _c("span", [_vm._v("Mã trường")]),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(_vm.infoSchool.maTruongHoc))]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "text-left", attrs: { sm: 12, xs: 24 } },
                    [
                      _c("el-card", { attrs: { shadow: "always" } }, [
                        _c("div", { staticClass: "iconInfo" }, [
                          _c("i", { staticClass: "el-icon-coordinate" }),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "textInfo",
                            staticStyle: { display: "flex" },
                          },
                          [
                            _c(
                              "p",
                              { staticStyle: { "padding-right": "10px" } },
                              [_c("span", [_vm._v("Ảnh dấu")])]
                            ),
                            _vm._v(" "),
                            _vm.infoSchool.pathDau
                              ? _c(
                                  "div",
                                  { staticStyle: { "padding-right": "15px" } },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "50px",
                                        height: "50px",
                                      },
                                      attrs: {
                                        src: _vm.infoSchool.pathDau,
                                        alt: "Ảnh dấu",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.hien_thi_anh_dau = true
                                        },
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "absolute",
                                  right: "18px",
                                  bottom: "30px",
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Tải ảnh dấu",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "mini",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.beforeUpload($event)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-upload",
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Xem trước",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "mini",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.xemAnhKy($event)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-coordinate",
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { staticClass: "text-left", attrs: { sm: 12, xs: 24 } },
                    [
                      _c("el-card", { attrs: { shadow: "always" } }, [
                        _c("div", { staticClass: "iconInfo" }, [
                          _c("i", { staticClass: "el-icon-phone-outline" }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "textInfo" }, [
                          _c("span", [_vm._v("Số điện thoại")]),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(_vm.infoSchool.sdtLienHe))]),
                          _vm._v(" "),
                          !_vm.infoSchool.sdtLienHe
                            ? _c("p", [_vm._v("Chưa cập nhật")])
                            : _vm._e(),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "text-left", attrs: { sm: 12, xs: 24 } },
                    [
                      _c("el-card", { attrs: { shadow: "always" } }, [
                        _c("div", { staticClass: "iconInfo" }, [
                          _c("i", { staticClass: "el-icon-message" }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "textInfo" }, [
                          _c("span", [_vm._v("Email")]),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(_vm.infoSchool.emailLienHe))]),
                          _vm._v(" "),
                          !_vm.infoSchool.emailLienHe
                            ? _c("p", [_vm._v("Chưa cập nhật")])
                            : _vm._e(),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { staticClass: "text-left", attrs: { sm: 12, xs: 24 } },
                    [
                      _c("el-card", { attrs: { shadow: "always" } }, [
                        _c("div", { staticClass: "iconInfo" }, [
                          _c("i", { staticClass: "el-icon-map-location" }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "textInfo" }, [
                          _c("span", [_vm._v("Tỉnh/Thành Phố")]),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(_vm.infoSchool.tenTinh))]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "text-left", attrs: { sm: 12, xs: 24 } },
                    [
                      _c("el-card", { attrs: { shadow: "always" } }, [
                        _c("div", { staticClass: "iconInfo" }, [
                          _c("i", { staticClass: "el-icon-cloudy" }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "textInfo" }, [
                          _c(
                            "div",
                            [
                              _c(
                                "span",
                                [
                                  _vm._v("Serial Number\n                    "),
                                  _vm.infoSchool.moTaTrangThaiNopCTS ||
                                  _vm.infoSchool.trangThaiNopCTS
                                    ? _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content:
                                              _vm.infoSchool
                                                .moTaTrangThaiNopCTS,
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticStyle: {
                                                "font-size": "18px",
                                              },
                                            },
                                            [
                                              _c(
                                                "small",
                                                {
                                                  staticStyle: {
                                                    color: "blue",
                                                    cursor: "pointer",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "(" +
                                                      _vm._s(
                                                        _vm.getTrangThaiNopCTS(
                                                          _vm.infoSchool
                                                            .trangThaiNopCTS
                                                        )
                                                      ) +
                                                      ")"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-row",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                  },
                                  attrs: { gutter: 24 },
                                },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticStyle: { margin: "0" },
                                      attrs: { span: 14 },
                                    },
                                    [
                                      _vm.infoSchool.serialNumber
                                        ? _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                content:
                                                  _vm.infoSchool.serialNumber,
                                                placement: "bottom",
                                              },
                                            },
                                            [
                                              _vm.infoSchool.serialNumber &&
                                              _vm.infoSchool.serialNumber !=
                                                " " &&
                                              _vm.infoSchool.serialNumber != ""
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticStyle: {
                                                        "white-space": "nowrap",
                                                        overflow: "hidden",
                                                        "text-overflow":
                                                          "ellipsis",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            _vm.infoSchool
                                                              .serialNumber
                                                          ) +
                                                          " - [" +
                                                          _vm._s(
                                                            _vm.infoSchool
                                                              .moTaTrangThaiNopCTS
                                                          ) +
                                                          "]\n                        "
                                                      ),
                                                    ]
                                                  )
                                                : _c("p", [
                                                    _vm._v(
                                                      "Chưa cập nhật - [" +
                                                        _vm._s(
                                                          _vm.infoSchool
                                                            .moTaTrangThaiNopCTS
                                                        ) +
                                                        "]"
                                                    ),
                                                  ]),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "mt-0 text-right",
                                      attrs: { span: 10 },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "padding-right": "0px",
                                            "margin-top": "-30px",
                                          },
                                        },
                                        [
                                          _vm.infoSchool.nopCTSlenSo && false
                                            ? _c(
                                                "el-tooltip",
                                                {
                                                  staticClass: "item",
                                                  attrs: {
                                                    effect: "dark",
                                                    content:
                                                      "Nộp thông tin CTS lên Sở",
                                                    placement: "top",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-upload float-right cursor-pointer",
                                                    staticStyle: {
                                                      "padding-right": "10px",
                                                      color: "blue",
                                                      "font-size": "22px",
                                                    },
                                                    on: { click: _vm.NopLenSo },
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.infoSchool.serialNumber != ""
                                            ? _c(
                                                "el-tooltip",
                                                {
                                                  staticClass: "item",
                                                  attrs: {
                                                    effect: "dark",
                                                    content:
                                                      "Cập nhật thông tin chứng thư số",
                                                    placement: "top",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    [
                                                      _c("el-button", {
                                                        attrs: {
                                                          type: "primary",
                                                          icon: "el-icon-edit-outline",
                                                          size: "mini",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.capNhatThongTinCTS,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "el-tooltip",
                                                {
                                                  staticClass: "item",
                                                  attrs: {
                                                    effect: "dark",
                                                    content:
                                                      "Thêm thông tin tài khoản",
                                                    placement: "top",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    [
                                                      _c("el-button", {
                                                        attrs: {
                                                          type: "primary",
                                                          icon: "el-icon-circle-plus-outline",
                                                          size: "mini",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.capNhatThongTinCTS,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                          _vm._v(" "),
                                          !_vm.checkMenuUrlExists()
                                            ? _c(
                                                "el-tooltip",
                                                {
                                                  staticClass: "item",
                                                  attrs: {
                                                    effect: "dark",
                                                    content:
                                                      "Cập nhật trạng thái",
                                                    placement: "top",
                                                  },
                                                },
                                                [
                                                  _c("el-button", {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      type: "primary",
                                                      disabled:
                                                        _vm.checkMenuUrlExists(),
                                                      icon: "el-icon-refresh",
                                                      size: "mini",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.capNhatTrangThai,
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { staticClass: "text-left", attrs: { sm: 12, xs: 24 } },
                    [
                      _c("el-card", { attrs: { shadow: "always" } }, [
                        _c("div", { staticClass: "iconInfo" }, [
                          _c("i", {
                            staticClass: "el-icon-location-information",
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "textInfo" }, [
                          _c("span", [_vm._v("Quận/Huyện")]),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(_vm.infoSchool.tenHuyen))]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "text-left", attrs: { sm: 12, xs: 24 } },
                    [
                      _c("el-card", { attrs: { shadow: "always" } }, [
                        _c("div", { staticClass: "iconInfo" }, [
                          _c("img", {
                            staticStyle: { width: "45px" },
                            attrs: { src: "/images/icon_mysign.png", alt: "" },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "textInfo" }, [
                          _c("span", [_vm._v("Tài khoản SmartCA Viettel")]),
                          _vm._v(" "),
                          _vm.infoSchool.usernameCAVT != ""
                            ? _c("p", [
                                _vm._v(_vm._s(_vm.infoSchool.usernameCAVT)),
                              ])
                            : _c("p", [_vm._v(" Chưa cập nhật")]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  false
                    ? _c(
                        "el-col",
                        { staticClass: "text-left", attrs: { sm: 12, xs: 24 } },
                        [
                          _c("el-card", { attrs: { shadow: "always" } }, [
                            _c("div", { staticClass: "iconInfo" }, [
                              _c("img", {
                                staticStyle: { width: "45px" },
                                attrs: {
                                  src: "https://demo-ca.hocba.edu.vn/images/icon_usb_token.png",
                                  alt: "",
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "textInfo" }, [
                              _c("span", [_vm._v("Số Serial Chứng Thư Số")]),
                              _vm._v(" "),
                              _vm.infoSchool.serialNumberUsbToken != " "
                                ? _c("p", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.infoSchool.serialNumberUsbToken
                                        ) +
                                        "\n                "
                                    ),
                                  ])
                                : _c("p", [_vm._v(" Chưa cập nhật")]),
                            ]),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { staticClass: "text-left", attrs: { sm: 12, xs: 24 } },
                    [
                      _c("el-card", { attrs: { shadow: "always" } }, [
                        _c("div", { staticClass: "iconInfo" }, [
                          _c("i", { staticClass: "el-icon-location-outline" }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "textInfo" }, [
                          _c("span", [_vm._v("Phường/Xã")]),
                          _vm._v(" "),
                          _vm.infoSchool.tenXa
                            ? _c("p", [_vm._v(_vm._s(_vm.infoSchool.tenXa))])
                            : _c("p", [_vm._v(" ")]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "text-left", attrs: { sm: 12, xs: 24 } },
                    [
                      _c("el-card", { attrs: { shadow: "always" } }, [
                        _c("div", { staticClass: "iconInfo" }, [
                          _c("img", {
                            staticStyle: { width: "45px" },
                            attrs: { src: "/images/icon_vnpt_ca.png", alt: "" },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "textInfo" }, [
                          _c("span", [_vm._v("Tài khoản SmartCA VNPT")]),
                          _vm._v(" "),
                          _vm.infoSchool.usernameCAVNPT != "" &&
                          _vm.infoSchool.usernameCAVNPT != " "
                            ? _c("p", [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.infoSchool.usernameCAVNPT) +
                                    "\n                "
                                ),
                              ])
                            : _c("p", [_vm._v(" Chưa cập nhật")]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dialog",
                    {
                      staticClass: "uploadSignature",
                      attrs: {
                        title: "Cập nhật thông tin chứng thư số",
                        width: "65%",
                        center: "",
                        visible: _vm.hien_thi_cap_nhat_cts,
                        "custom-class": "thongTinKyTheoMonHoc",
                        "close-on-press-escape": false,
                        "close-on-click-modal": false,
                        "before-close": _vm.closeDialogUpload,
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.hien_thi_cap_nhat_cts = $event
                        },
                      },
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 24 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-table",
                                {
                                  attrs: {
                                    fit: "",
                                    border: "",
                                    data: _vm.danh_sach_chung_thu_so,
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      "header-align": "center",
                                      label: "Số serial",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: true,
                                                type: "text",
                                              },
                                              model: {
                                                value: scope.row.serialNumber,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "serialNumber",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.serialNumber",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      property: "validFrom",
                                      "header-align": "center",
                                      align: "center",
                                      label: "Ngày hiệu lực",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: true,
                                                type: "text",
                                              },
                                              model: {
                                                value: scope.row.validFrom,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "validFrom",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.validFrom",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      property: "validTo",
                                      "header-align": "center",
                                      align: "center",
                                      label: "Ngày hết hiệu lực",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: true,
                                                type: "text",
                                              },
                                              model: {
                                                value: scope.row.validTo,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "validTo",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.validTo",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      property: "nhaPhatHanh",
                                      "header-align": "center",
                                      align: "center",
                                      label: "Nhà phát hành",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  disabled: true,
                                                  filterable: "",
                                                  placeholder:
                                                    "Chọn nhà phát hành",
                                                },
                                                model: {
                                                  value: scope.row.nhaPhatHanh,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "nhaPhatHanh",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.nhaPhatHanh",
                                                },
                                              },
                                              _vm._l(
                                                _vm.list_nha_phat_hanh,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.value,
                                                    attrs: {
                                                      label: item.name,
                                                      value: item.value,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      width: "200",
                                      "header-align": "center",
                                      label: "Hành động",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      size: "mini",
                                                      type: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.suDungCTS(
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Cập nhật\n                        "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "dialog-footer",
                          attrs: { slot: "footer" },
                          slot: "footer",
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini" },
                              on: {
                                click: function ($event) {
                                  _vm.hien_thi_cap_nhat_cts = false
                                },
                              },
                            },
                            [_vm._v("Hủy")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("iframe", {
        staticStyle: { display: "contents" },
        attrs: { height: "1", width: "1", src: _vm.infoSchool.pathDau },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "uploadSignature",
          attrs: {
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            title: "Tải lên dấu",
            center: "",
            visible: _vm.showPopUp,
            "custom-class": "thongTinKyTheoMonHoc",
            "before-close": _vm.closeDialogUpload,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showPopUp = $event
            },
          },
        },
        [
          _vm.showPopUp
            ? _c("crop-image", {
                attrs: {
                  "style-box": "width: 250px; height: 250px;",
                  option: _vm.optionCrope,
                  showCrop: _vm.showPopUp,
                },
                on: {
                  goStep: _vm.goStep,
                  uploadLuon: _vm.uploadLuon,
                  getBase64: _vm.getBase64,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.showPopUp = false
                      _vm.step = 1
                    },
                  },
                },
                [_vm._v("Hủy")]
              ),
              _vm._v(" "),
              _vm.step == 3
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        disabled: !_vm.imageDau,
                        type: "primary",
                      },
                      on: { click: _vm.uploadDau },
                    },
                    [_vm._v("Xác nhận")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "updateInfoTruong",
          attrs: {
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            title: "Cập nhật thông tin trường",
            center: "",
            width: "60%",
            top: "5vh",
            visible: _vm.showPopUpInfo,
          },
          on: {
            close: function ($event) {
              return _vm.dongCapNhat(
                "ruleForm",
                "showPopUpInfo",
                "confirm_ruleForm"
              )
            },
          },
        },
        [
          _vm.infoSchool
            ? _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: { model: _vm.ruleForm, rules: _vm.rulesUpdateForm },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Tên trường",
                                prop: "tenTruongHoc",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { clearable: "", placeholder: "Nhập" },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleInputChange(
                                      "ruleForm",
                                      "tenTruongHoc",
                                      "confirm_ruleForm"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.ruleForm.tenTruongHoc,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "tenTruongHoc", $$v)
                                  },
                                  expression: "ruleForm.tenTruongHoc",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Email", prop: "emailLienHe" } },
                            [
                              _c("el-input", {
                                attrs: { clearable: "", placeholder: "Nhập " },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleInputChange(
                                      "ruleForm",
                                      "emailLienHe",
                                      "confirm_ruleForm"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.ruleForm.emailLienHe,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "emailLienHe", $$v)
                                  },
                                  expression: "ruleForm.emailLienHe",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _vm.user.qlnt !== 0 || true
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Tỉnh/Thành Phố",
                                    prop: "tenTinh",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "Nhập",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleInputChange(
                                          "ruleForm",
                                          "tenTinh",
                                          "confirm_ruleForm"
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.ruleForm.tenTinh,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm, "tenTinh", $$v)
                                      },
                                      expression: "ruleForm.tenTinh",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Số điện thoại",
                                prop: "sdtLienHe",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { clearable: "", placeholder: "Nhập" },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleInputChange(
                                      "ruleForm",
                                      "sdtLienHe",
                                      "confirm_ruleForm"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.ruleForm.sdtLienHe,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "sdtLienHe", $$v)
                                  },
                                  expression: "ruleForm.sdtLienHe",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _vm.user.qlnt !== 0 || true
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Quận/Huyện",
                                    prop: "tenHuyen",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "Nhập",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleInputChange(
                                          "ruleForm",
                                          "tenHuyen",
                                          "confirm_ruleForm"
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.ruleForm.tenHuyen,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm, "tenHuyen", $$v)
                                      },
                                      expression: "ruleForm.tenHuyen",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Tài khoản SmartCA Viettel",
                                prop: "usernameCAVT",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { clearable: "", placeholder: "Nhập" },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleInputChange(
                                      "ruleForm",
                                      "usernameCAVT",
                                      "confirm_ruleForm"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.ruleForm.usernameCAVT,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "usernameCAVT", $$v)
                                  },
                                  expression: "ruleForm.usernameCAVT",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _vm.user.qlnt !== 0 || true
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: { label: "Phường/Xã", prop: "tenXa" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "Nhập",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleInputChange(
                                          "ruleForm",
                                          "tenXa",
                                          "confirm_ruleForm"
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.ruleForm.tenXa,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm, "tenXa", $$v)
                                      },
                                      expression: "ruleForm.tenXa",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Tài khoản SmartCA VNPT",
                                prop: "usernameCAVNPT",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { clearable: "", placeholder: "Nhập" },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleInputChange(
                                      "ruleForm",
                                      "usernameCAVNPT",
                                      "confirm_ruleForm"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.ruleForm.usernameCAVNPT,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "usernameCAVNPT",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.usernameCAVNPT",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Ngày thành lập" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: "",
                                  clearable: "",
                                  placeholder: "Nhập",
                                },
                                model: {
                                  value: _vm.ruleForm.ngayThanhLap,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "ngayThanhLap", $$v)
                                  },
                                  expression: "ruleForm.ngayThanhLap",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.dongCapNhat(
                        "ruleForm",
                        "showPopUpInfo",
                        "confirm_ruleForm"
                      )
                    },
                  },
                },
                [_vm._v("\n          Hủy\n        ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: !_vm.confirm_ruleForm,
                    size: "mini",
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.updateInfoSubmit("ruleForm")
                    },
                  },
                },
                [_vm._v("\n          Lưu\n        ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "updateInfoTruong",
          attrs: {
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            title: "Đổi mật khẩu",
            center: "",
            width: "50%",
            visible: _vm.showPopUpPass,
          },
          on: {
            close: function ($event) {
              return _vm.dongCapNhat(
                "matKhau",
                "showPopUpPass",
                "confirm_matKhau"
              )
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "matKhau",
              attrs: { model: _vm.matKhau, rules: _vm.rulesChangePassword },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "moi" } },
                        [
                          _c("label", [
                            _vm._v("Nhập mật khẩu mớim"),
                            _c("span", { staticClass: "error" }, [_vm._v("*")]),
                          ]),
                          _vm._v(" "),
                          _c("InputPassword", {
                            ref: "moi",
                            attrs: { placeholder: "Nhập" },
                            on: {
                              input: function ($event) {
                                _vm.confirm_matKhau = true
                              },
                            },
                            model: {
                              value: _vm.matKhau.moi,
                              callback: function ($$v) {
                                _vm.$set(_vm.matKhau, "moi", $$v)
                              },
                              expression: "matKhau.moi",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "nhapLai" } },
                        [
                          _c("label", [
                            _vm._v("Xác nhận mật khẩu mới "),
                            _c("span", { staticClass: "error" }, [_vm._v("*")]),
                          ]),
                          _vm._v(" "),
                          _c("InputPassword", {
                            ref: "nhapLai",
                            attrs: { placeholder: "Nhập" },
                            on: {
                              input: function ($event) {
                                _vm.confirm_matKhau = true
                              },
                            },
                            model: {
                              value: _vm.matKhau.nhapLai,
                              callback: function ($$v) {
                                _vm.$set(_vm.matKhau, "nhapLai", $$v)
                              },
                              expression: "matKhau.nhapLai",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.dongCapNhat(
                        "matKhau",
                        "showPopUpPass",
                        "confirm_matKhau"
                      )
                    },
                  },
                },
                [_vm._v("\n          Hủy\n        ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: !_vm.confirm_matKhau,
                    size: "mini",
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.xacNhanThayDoiMatKhau("matKhau")
                    },
                  },
                },
                [_vm._v("\n          Lưu\n        ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Ảnh dấu của trường",
            width: "40%",
            center: "",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "custom-class": "thongTinKyTheoMonHoc",
            visible: _vm.hien_thi_anh_dau,
          },
          on: {
            "update:visible": function ($event) {
              _vm.hien_thi_anh_dau = $event
            },
          },
        },
        [
          _c("div", { staticClass: "text-center" }, [
            _c("img", {
              staticStyle: { width: "50%", height: "auto" },
              attrs: {
                src: _vm.infoSchool.pathDau + "?t=" + _vm.getTime(),
                alt: "Ảnh dấu",
              },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Xem trước ảnh con dấu khi đóng dấu",
            width: "60%",
            center: "",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "custom-class": "thongTinKyTheoMonHoc",
            visible: _vm.hien_thi_anh_ky,
          },
          on: {
            "update:visible": function ($event) {
              _vm.hien_thi_anh_ky = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "text-center", staticStyle: { height: "60vh" } },
            [
              _c(
                "p",
                { staticStyle: { "text-align": "left", color: "blue" } },
                [
                  _vm._v(
                    "\n          Hình ảnh con dấu tải lên dưới đây sẽ tương quan so với con dấu ở học bạ khi đóng\n          dấu. Quý thầy cô vui lòng cân nhắc tải lại lên nếu con dấu quá nhỏ hoặc chưa đạt\n          tiêu chuẩn!\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("iframe", {
                staticStyle: { width: "100%", height: "90%" },
                attrs: { src: _vm.linkAnh },
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "50%",
            top: "5vh",
            "custom-class": "thongTinKyTheoMonHoc",
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            center: "",
            title: "Hướng dẫn",
            visible: _vm.show_huong_dan_upload,
          },
          on: {
            "update:visible": function ($event) {
              _vm.show_huong_dan_upload = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _vm.show_huong_dan_upload
                  ? _c("video", { attrs: { width: "100%", controls: "" } }, [
                      _c("source", {
                        attrs: {
                          src: "https://cdnhocbadientu.csdl.edu.vn/media/huong_dan_upload_anh_dau.mp4",
                          type: "video/mp4",
                        },
                      }),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.showUpLoad },
                },
                [_vm._v("Đã hiểu")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Cập nhật thông tin Chứng thư số",
            center: "",
            visible: _vm.show_cap_nhat_cts,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            width: "40%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.show_cap_nhat_cts = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("label", [_vm._v("Chọn loại chứng thư số ")]),
                  _vm._v(" "),
                  _c("eselect", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      collapseTags: "",
                      size: "mini",
                      placeholder: "Chọn",
                      filterable: "",
                      data: _vm.danh_sach_loai_chung_thu_so,
                      fields: ["name", "value"],
                    },
                    model: {
                      value: _vm.loaiChungThuSo,
                      callback: function ($$v) {
                        _vm.loaiChungThuSo = $$v
                      },
                      expression: "loaiChungThuSo",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "label",
                    { staticStyle: { color: "transparent", display: "block" } },
                    [_vm._v("Cập nhật ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { disabled: !_vm.loaiChungThuSo, type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.checkLoaiCTS()
                        },
                      },
                    },
                    [_vm._v("Lưu\n          ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              false
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("p", [_vm._v("Chọn loại Chứng thư số:")]),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { padding: "5px", margin: "8px 3px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.layChungThuSo()
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticStyle: { width: "30px", height: "20px" },
                            attrs: {
                              src: _vm.getPathImg("/icon_mysign.png"),
                              alt: "",
                            },
                          }),
                          _vm._v("\n            CTS MySign\n          "),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { padding: "5px", margin: "8px 3px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.layCTSUSB("VIETTEL")
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticStyle: { width: "30px", height: "20px" },
                            attrs: {
                              src: _vm.getPathImg("/icon_usb_token.png"),
                              alt: "",
                            },
                          }),
                          _vm._v(
                            "\n            CTS UsbToken VIETTEL\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { padding: "5px", margin: "8px 3px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.layCTSUSB("BAN_CO_YEU")
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticStyle: { width: "30px", height: "20px" },
                            attrs: {
                              src: _vm.getPathImg("/icon_usb_token.png"),
                              alt: "",
                            },
                          }),
                          _vm._v(
                            "\n            CTS UsbToken Ban Cơ Yếu\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { padding: "5px", margin: "8px 3px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.layCTSUSB("BKAV")
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticStyle: { width: "30px", height: "20px" },
                            attrs: {
                              src: _vm.getPathImg("/icon_usb_token.png"),
                              alt: "",
                            },
                          }),
                          _vm._v("\n            CTS UsbToken BKAV\n          "),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { padding: "5px", margin: "8px 3px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.layCTSUSB("VNPT")
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticStyle: { width: "30px", height: "20px" },
                            attrs: {
                              src: _vm.getPathImg("/icon_usb_token.png"),
                              alt: "",
                            },
                          }),
                          _vm._v("\n            CTS UsbToken VNPT\n          "),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.show_cap_nhat_cts = false
                    },
                  },
                },
                [_vm._v("Hủy")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.layCTS
            ? _c("layThongTinCTS", {
                attrs: { nph: _vm.nhaPhatHanh },
                on: {
                  done: function ($event) {
                    return _vm.layLaiThongTinCTS()
                  },
                  close: function ($event) {
                    _vm.layCTS = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "d-flex align-items-center justify-content-between" },
      [
        _c(
          "div",
          {
            staticClass:
              "page__title d-flex align-items-center justify-content-between",
          },
          [_c("h1", { staticClass: "title" }, [_vm._v("Thông tin nhà trường")])]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }