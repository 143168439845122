<template>
  <el-dialog
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :title="title"
      :class="'dialog__confirm '+dialogClass"
      :destroy-on-close="true"
      center
      :before-close="closeOtpModal"
      :visible.sync="showConfirm"
      append-to-body
  >
    <div class="text-center">{{ message }}</div>
    <span slot="footer" class="dialog-footer">
            <el-button @click="closeOtpModal">Hủy</el-button>
            <el-button type="primary" @click="confirmClick">Xác nhận</el-button>
        </span>
  </el-dialog>
</template>

<script>
export default {
  name: "FormConfirm",
  props: [
    'dialogClass',
    'title',
    'message',
    'showConfirm'
  ],
  data() {
    return {}
  },
  methods: {
    closeOtpModal() {
      this.$emit('close')
    },
    confirmClick() {
      this.$emit('confirm')
    }
  }
}
</script>

<style scoped>

</style>
