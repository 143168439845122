import { render, staticRenderFns } from "./Clear.vue?vue&type=template&id=56249014&scoped=true&"
import script from "./Clear.vue?vue&type=script&lang=js&"
export * from "./Clear.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56249014",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\xampp\\htdocs\\sProfiles\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('56249014')) {
      api.createRecord('56249014', component.options)
    } else {
      api.reload('56249014', component.options)
    }
    module.hot.accept("./Clear.vue?vue&type=template&id=56249014&scoped=true&", function () {
      api.rerender('56249014', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/pages/Clear.vue"
export default component.exports