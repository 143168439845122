<template>
    <div class="page__content" v-loading.fullscreen.lock="fullscreenLoading">
        <el-alert
            title="Hướng dẫn"
            :closable="false"
            show-icon=""
            type="info"
            class="mb-2"
        >
            <div>
                Bước 1: Chọn lớp muốn thêm học bạ<br>
                Bước 2: Chọn Hình thức thêm, mặc định hệ thống hiển thị là “Upload file”. Nếu muốn nhập tay thì lựa chọn hình thức là “Nhập thông tin”<br>
                Bước 3: Nếu hình thức là upload file thì chọn File upload, người dùng scan kết quả học tập của năm học đó rồi upload file lên hệ thống, Trang bìa không phải scan. Nếu hình thức là Nhập thông tin thì điền các thông tin của học bạ.<br>
                Bước 4: Nhấn tạo học bạ<br>
                Bước 5: Lặp lại từ bước 1 nếu muốn tạo tiếp học bạ hoặc nhấn button Đóng để kết thúc việc thêm mới.
            </div>
        </el-alert>
        <el-alert
            v-if="listLopThieu.length"
            title="Thiếu học bạ"
            type="warning"
            :closable="false"
            show-icon>
            <ul>
                <li v-for="item in listLopThieu" :key="item.maLop">
                    <div> Năm học: {{item.namHoc}}. Lớp : {{ item.tenLop }}</div>
                </li>
            </ul>
        </el-alert>
        <div>
            <h5>A. Thông tin học sinh</h5>
            <el-form>
                <div>
                    <el-form-item style="width: 220px" class="d-inline-block" prop="maHS">
                        <div>Mã học sinh:</div>
                        <el-input :disabled="checkDisable()" v-model="formHocBa.maHS = getValue('maHS')" placeholder="Nhập"></el-input>
                    </el-form-item>
                    <el-form-item style="width: 220px" class="d-inline-block" prop="hoten">
                        <div>Họ tên học sinh:</div>
                        <el-input :disabled="checkDisable()" v-model="formHocBa.hoten = getValue('tenHS')" placeholder="Nhập"></el-input>
                    </el-form-item>
                    <el-form-item style="width: 120px" class="d-inline-block">
                        <div>Giới tính:</div>
                        <el-select :disabled="checkDisable()" v-model="formHocBa.gioiTinh = getValue('gioiTinh')" placeholder="Giới tính">
                            <el-option v-for="item in gioiTinh" :label="item.label" :key="item.value"
                                       :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item class="d-inline-block" prop="year">
                        <div>Ngày sinh:</div>
                        <el-date-picker :disabled="checkDisable()" style="width: 150px" v-model="formHocBa.ngaySinh = getValue('ngaySinh')" format="dd/MM/yyyy" type="date"
                                        placeholder="Ngày sinh" value-format="dd/MM/yyyy">
                        </el-date-picker>
                    </el-form-item>
                </div>
                <div>
                    <el-form-item class="d-inline-block" prop="namHoc">
                        <div>Năm học:</div>
                        <el-date-picker disabled prop="namHoc" style="width: 150px" v-model="formHocBa.namHoc" type="year"
                                        placeholder="Năm học" value-format="yyyy">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item style="width: 130px" class="d-inline-block" prop="maLop">
                        <div>Lớp:</div>
                        <el-select @change="lopChange" v-model="formHocBa.maLop" placeholder="Chọn lớp">
                            <el-option v-for="item in listLopThieu" :label="item.tenLop" :key="item.maLop"
                                       :value="item.maLop"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </el-form>
        </div>
        <h4>
            <el-radio-group v-model="showManual">
                <el-radio :label="1">Upload file</el-radio>
                <el-radio :label="2">Nhập thông tin</el-radio>
            </el-radio-group>

        </h4>

        <el-form :rules="rulesHocBa" ref="formHocBa" :model="formHocBa" :hide-required-asterisk="true">

            <template v-if="showManual==1">
                <h5>B. Tải lên file học bạ</h5>
                <div>
                    <UploadImageBase64
                        style="width: 100px; cursor: pointer;"
                        :default-preview="urlFile"
                        class="hocba__upload mb-1"
                        image-class="v1-image"
                        input-class="v1-image"
                        :max-size="customImageMaxSize"
                        @size-exceeded="onSizeExceeded"
                        placeholder="Tải lên học bạ"
                        @load="onLoad">
                        <template v-slot:showButton>
                            <div class="hocba__uploadfile"><i class="el-icon-upload"></i> Tải lên file học bạ</div>
                        </template>
                    </UploadImageBase64>
                </div>
            </template>
            <template v-else>
                <div>
                    <h5>B. Các môn học và hoạt động giáo dục</h5>
                    <div class="row">
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3" v-for="(item,index) in monHoc">
                            <div><strong>{{index+1}}. {{item.monHoc}}</strong></div>
                            <div class="d-flex">
                                <el-form-item style="width: 160px" class="mr-1">
                                    <label>Mức đạt:
                                        <el-select v-model="item.mucDat"  placeholder="Mức đạt">
                                            <el-option v-for="item in mucDatList" :label="item.label" :key="item.value" :value="item.value"></el-option>
                                        </el-select>
                                    </label>
                                </el-form-item>
                                <el-form-item style="width: 160px"  class="d-inline-block mr-1">
                                    <label>Điểm kiểm tra định kỳ:
                                        <el-input v-model="item.diemKTDK" placeholder="Điểm KTĐK">
                                        </el-input>
                                    </label>
                                </el-form-item>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <label>Nhận xét các môn học và hoạt động giáo dục:
                                <el-input rows="3" v-model="formHocBa.danhGiaMonHoc" type="textarea"></el-input>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="mt-3">
                    <h5>C. Những phẩm chất chủ yếu</h5>
                    <div class="row">
                        <div v-for="item in phamChat" class="col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-2">
                            <div>
                                <strong style="width: 110px;display: inline-block">{{item.ten}} : </strong>
                                <el-select size="small" v-model="item.mucDat"  placeholder="Mức đạt">
                                    <el-option v-for="item in mucDatList" :label="item.label" :key="item.value" :value="item.value"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <label>Nhận xét các phẩm chất:
                                <el-input rows="3" v-model="formHocBa.danhGiaPhamChat" type="textarea"></el-input>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="mt-3">
                    <h5>D. Năng lực cốt lõi</h5>
                    <div class="row">
                        <div class="col-md-6">
                            <h6> 1.Những năng lực chung </h6>
                            <div class="row">
                                <div v-for="item in nangLucChung" class="col-sm-12 mb-3">
                                    <div>
                                        <strong style="width: 250px;display: inline-block">{{item.ten}} : </strong>
                                        <el-select size="small" v-model="item.mucDat"  placeholder="Mức đạt">
                                            <el-option v-for="item in mucDatList" :label="item.label" :key="item.value" :value="item.value"></el-option>
                                        </el-select>
                                    </div>
                                </div>
                                <div class="col-md-12 mb-4">
                                    <label>Nhận xét về năng lực chung:
                                        <el-input rows="3" v-model="formHocBa.danhGiaNangLucChung" type="textarea"></el-input>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <h6> 2.Những năng lực đặc thù </h6>
                            <div class="row">
                                <div v-for="item in nangLucDacThu" class="col-sm-12 mb-3">
                                    <div>
                                        <strong style="width: 110px;display: inline-block">{{item.ten}} : </strong>
                                        <el-select size="small" v-model="item.mucDat"  placeholder="Mức đạt">
                                            <el-option v-for="item in mucDatList" :label="item.label" :key="item.value" :value="item.value"></el-option>
                                        </el-select>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <label>Nhận xét về năng lực đặc thù:
                                        <el-input rows="3" v-model="formHocBa.danhGiaNangLucDacThu" type="textarea"></el-input>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-3">
                    <h5>E. Đánh giá chung</h5>
                    <div>
                        <label>Đánh giá kết quả giảo dục:
                            <el-input rows="3" v-model="formHocBa.danhGiaKqGd" type="textarea"></el-input>
                        </label>
                    </div>
                    <div>
                        <label>Khen Thưởng:
                            <el-input rows="3" v-model="formHocBa.khenThuong" type="textarea"></el-input>
                        </label>
                    </div>
                    <div>
                        <label>Hoàn thành chương trình:
                            <el-input rows="3" v-model="formHocBa.hoanThanhChuongTrinh" type="textarea"></el-input>
                        </label>
                    </div>
                </div>
            </template>
            <div class="button mb-5 mt-4">
                <el-button type="primary" @click="taoHocBa('formHocBa')">Tạo học bạ</el-button>
                <el-button type="info" @click="closeClick">Đóng</el-button>
            </div>
        </el-form>

        <dialog-alert title="Thông báo" :message="messageAlert" :show-dialog="showAlert" @closeDialog="showAlert = false"></dialog-alert>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import dialog from "../../dialog";
import UploadImageBase64 from "../../components/UploadImageBase64";
import DialogAlert from "../../components/DialogAlert";

export default {
    name: "TieuHoc",
    components : {
        DialogAlert,
        UploadImageBase64
    },
    computed: {
        ...mapState('teacher', [
            "listLop",
            "urlFile"

        ]),
        ...mapState('account', [
            'user'
        ]),
        ...mapState('hocba', [
            "createStatus",
            "detailHocBa",
            "detailHs"
        ])
    },
    data : ()=>{
        return{
            customImageMaxSize: 10,
            messageAlert : '',
            showAlert : false,
            showManual : 1,
            listLopThieu : [],
            mucDatList : [
                {value : '',label : 'Chọn xếp loại'},
                {value :'T',label : 'Tốt'},
                {value :'Đ',label : 'Đạt'},
                {value :'C',label : 'Cần cố gắng'},
            ],
            gioiTinh : [
                {value :-1,label : 'Giới tính'},
                {value :0,label : 'Nữ'},
                {value :1,label : 'Nam'}
            ],
            fullscreenLoading : false,
            formHocBa : {
                capHoc : '',
                khoiHoc: '',
                namHoc : '',
                maLop : '',
                maHS : '',
                hoTenMe : '',
                ngheNghiepCha: "",
                ngheNghiepMe: "",
                ngheNghiepNguoiGiamHo: "",
                hoTenCha : '',
                gioiTinh : '',
                hoten : '',
                ngaySinh : "",
                noiOHienNay : "",
                noiSinh : "",
                khenThuong : '',
                nangLucs : [],
                nangLucDacThus : [],
                phamChatChuYeus : [],
                hoanThanhChuongTrinh : '',
                danhGiaKqGd : '',
                danhGiaKetQua : '',
                danhGiaMonHoc : '',
                danhGiaPhamChat : '',
                danhGiaNangLucChung : '',
                danhGiaNangLucDacThu : '',
                quaTrinhHocTaps : [],
                ketQuaHocTaps: [],

            },
            monHoc : [
                {
                    mucDat : '',
                    nhanXet : '',
                    maMonHoc: "TIENGVIET",
                    monHoc: "Tiếng Việt",
                    diemKTDK : ''
                },
                {
                    mucDat : '',
                    nhanXet : '',
                    maMonHoc: "TOAN",
                    monHoc: "Toán",
                    diemKTDK : ''
                },
                {
                    mucDat : '',
                    nhanXet : '',
                    maMonHoc: "NGOAINGU",
                    monHoc: "Ngoại ngữ",
                    diemKTDK : ''
                },
                {
                    mucDat : '',
                    nhanXet : '',
                    maMonHoc: "LSDL",
                    monHoc: "Lịch sử & Địa lý",
                    diemKTDK : ''
                },
                {
                    mucDat : '',
                    nhanXet : '',
                    maMonHoc: "KHOAHOC",
                    monHoc: "Khoa học",
                    diemKTDK : ''
                },
                {
                    mucDat : '',
                    nhanXet : '',
                    maMonHoc: "THCN",
                    monHoc: "Tin học và Công nghệ",
                    diemKTDK : ''
                },
                {
                    mucDat : '',
                    nhanXet : '',
                    maMonHoc: "DAODUC",
                    monHoc: "Đạo Đức",
                    diemKTDK : ''
                },
                {
                    mucDat : '',
                    nhanXet : '',
                    maMonHoc: "TNXH",
                    monHoc: "Tự nhiên và Xã hội",
                    diemKTDK : ''
                },
                {
                    mucDat : '',
                    nhanXet : '',
                    maMonHoc: "GDTC",
                    monHoc: "Giáo dục thể chất",
                    diemKTDK : ''
                },
                {
                    mucDat : '',
                    nhanXet : '',
                    maMonHoc: "AMNHAC",
                    monHoc: "Âm nhạc",
                    diemKTDK : ''
                },
                {
                    mucDat : '',
                    nhanXet : '',
                    maMonHoc: "MYTHUAT",
                    monHoc: 'Mỹ thuật',
                    diemKTDK : ''
                },
                {
                    mucDat : '',
                    nhanXet : '',
                    maMonHoc: "HDTN",
                    monHoc: 'Hoạt động trải nghiệm',
                    diemKTDK : ''
                },
                {
                    mucDat : '',
                    nhanXet : '',
                    maMonHoc: "TIENGDT",
                    monHoc: 'Tiếng dân tộc',
                    diemKTDK : ''
                }
            ],

            phamChat : {
                yeuNuoc : {
                    mucDat : '',
                    ten : "Yêu nước",
                },
                nhanAi : {
                    mucDat : '',
                    ten : "Nhân ái",
                },
                chamChi : {
                    mucDat : '',
                    ten : "Chăm chỉ",
                },
                trungThuc : {
                    mucDat : '',
                    ten : "Trung thực",
                },
                trachNhiem : {
                    mucDat : '',
                    ten : "Trách nhiệm",
                }
            },
            nangLucChung : {
                tuChu : {
                    mucDat : '',
                    ten : "Tự chủ và tự học",
                },
                giaoTiep : {
                    mucDat : '',
                    ten : "Giao tiếp và hợp tác",
                },
                sangTao : {
                    mucDat : '',
                    ten : "Giải quyết vấn đề và sáng tạo",
                },
            },
            nangLucDacThu : {
                ngonNgu : {
                    mucDat : '',
                    ten : "Ngôn ngữ",
                },
                tinhToan : {
                    mucDat : '',
                    ten : "Tính toán",
                },
                congNghe : {
                    mucDat : '',
                    ten : "Công nghệ",
                },
                tinHoc : {
                    mucDat : '',
                    ten : "tinHoc",
                },
                thamMi : {
                    mucDat : '',
                    ten : "Thẩm mĩ",
                },
                theChat : {
                    mucDat : '',
                    ten : "Thể chất",
                }
            },
            rulesHocBa : {
                khoiHoc: [{
                    required: true,
                    message: 'Chưa chọn khối học',
                    trigger: 'change'
                }],
                maLop: [{
                    required: true,
                    message: 'Chưa chọn lớp học',
                    trigger: 'change'
                }],
                hoten: [{
                    required: true,
                    message: 'Chưa thêm tên học sinh',
                    trigger: 'change'
                }],
                namHoc : [{
                    required: true,
                    message: 'Chưa chọn năm học',
                    trigger: 'change'
                }]
            },
            optionEducation: [
                {
                    value: 1,
                    label: 'Cấp 1'
                },
                {
                    value: 2,
                    label: 'Cấp 2'
                },
                {
                    value: 3,
                    label: 'Cấp 3'
                },
            ],
            optionGrade: [],
            khoiCap1: [{
                value: 1,
                label: 'Khối 1'
            },
                {
                    value: 2,
                    label: 'Khối 2'
                },
                {
                    value: 3,
                    label: 'Khối 3'
                },
                {
                    value: 4,
                    label: 'Khối 4'
                },
                {
                    value: 5,
                    label: 'Khối 5'
                },
            ],
            khoiCap2: [{
                value: 6,
                label: 'Khối 6'
            },
                {
                    value: 7,
                    label: 'Khối 7'
                },
                {
                    value: 8,
                    label: 'Khối 8'
                },
                {
                    value: 9,
                    label: 'Khối 9'
                },
            ],
            khoiCap3: [{
                value: 10,
                label: 'Khối 10'
            },
                {
                    value: 11,
                    label: 'Khối 11'
                },
                {
                    value: 12,
                    label: 'Khối 12'
                },

            ],
        }
    },
    methods : {
        ...mapActions('teacher', [
            "getListLop",
            "clear",
            "uploadFile",
            "removeUploadFile"
        ]),
        ...mapActions('hocba', [
            "createHocBaTieuHoc",
            "createHocBaTrungHoc",
        ]),
        clearDiem(){

        },
        getKhoiHoc() {
            if (this.user.capHoc == 1) {
                this.optionGrade = this.khoiCap1;
            } else if (this.user.capHoc == 2) {
                this.optionGrade = this.khoiCap2;
            } else if (this.user.capHoc == 3) {
                this.optionGrade = this.khoiCap3;
            } else {
                this.optionGrade = this.khoiCap1.concat(this.khoiCap2, this.khoiCap3)
            }
        },
        khoiHocChange() {
            this.formHocBa.lop = '';
            this.getlistLopHoc();
        },
        getlistLopHoc() {
            this.getListLop({
                start: 0,
                limit: 999,
                khoiHoc: this.formHocBa.khoiHoc,
                // namHoc : this.searchForm.year,
                // lopHoc : this.searchForm.class
            })
        },
        taoHocBa(formName){
            this.fullscreenLoading = true;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let param = this.formHocBa;
                    let namHoc = this.formHocBa.namHoc;
                    param.namHoc = namHoc;
                    param.nangLucDacThus = Object.values(this.nangLucDacThu);
                    param.nangLucs = Object.values(this.nangLucChung);
                    param.phamChatChuYeus = Object.values(this.phamChat);
                    param.ketQuaHocTaps = Object.values(this.monHoc);
                    param.pathImage = this.urlFile;
                    this.createHocBaTieuHoc(param);
                } else {
                    this.fullscreenLoading = false;
                    dialog.showDialog('Thông báo','Vui lòng kiểm tra lại các trường thông tin');
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        getValueLop(key){
            let item = this.listLopThieu[0];
            this.formHocBa.maLop = item.maLop;
            this.getNamHoc();
        },
        getNamHoc(){
            let item = this.detailHs.lops;
            let namHoc = '';

            item.map((o)=> {
                if (o.maLop == this.formHocBa.maLop){
                    namHoc = o.namHoc;
                }
                return o;
            });
            this.formHocBa.namHoc = namHoc.toString();
        },
        getValue(key){
            let item = this.detailHs;
            if (item == null){
                return '';
            }
            return item[key];
        },
        checkDisable(){
            let item = this.detailHs;
            if (item == null){
                return false;
            }else{
                return true
            }
        },
        lopChange(){
            this.getNamHoc();
        },
        removeLop(){
            let listLop = this.listLopThieu;
            _.remove(listLop,(item) => {
                return item.maLop == this.formHocBa.maLop;
            })
            if (listLop.length){
                this.listLopThieu = listLop;
                this.getValueLop('maLop');
                $('.add-hocba').animate({ scrollTop: 0 });
            }else{
                this.$refs['formHocBa'].resetFields();
                this.clearDiem();
                this.$emit('close');
            }

        },
        closeClick(){
            this.$emit('close');
        },

        onSizeExceeded(size) {
            const h = this.$createElement;
            this.$notify.error({
                title: 'Upload thumbnail',
                message: `Hình ảnh vượt quá giới hạn ${this.customImageMaxSize}Mb!`
            });
        },

        onLoad(dataUri) {
            this.uploadFile({
                data : dataUri,
                id : 1,
            });
        },
    },
    mounted() {
        this.$refs['formHocBa'].resetFields();
        this.clearDiem();
        this.listLopThieu = this.detailHs.lops;
        this.getValueLop('maLop');
    },
    watch : {
        createStatus(val){
            if (val) {
                this.fullscreenLoading = false;
                this.showAlert = true;
                this.messageAlert = val.message;
                if (val.status=='success'){
                    this.removeLop();
                }
                this.removeUploadFile()
                this.$refs['formHocBa'].resetFields();
                this.clearDiem();
            }
        }
    }
}
</script>

<style scoped>

</style>
