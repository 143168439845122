var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("table", { attrs: { border: "1" } }, [
      _c(
        "thead",
        _vm._l(_vm.headerData, function (row, rowIndex) {
          return _c(
            "tr",
            { key: "header-" + rowIndex },
            _vm._l(
              Array.from({ length: _vm.dataLength }, function (_, index) {
                return index
              }),
              function (colIndex) {
                return !_vm.isMergedCell(rowIndex, colIndex) &&
                  !_vm.isMergedRow(rowIndex, colIndex)
                  ? _c(
                      "th",
                      {
                        key: "header-cell-" + rowIndex + "-" + colIndex,
                        attrs: {
                          "data-info":
                            "header-cell-" + rowIndex + "-" + colIndex,
                          "data-rowspan": _vm.getRowSpan(rowIndex, colIndex),
                          colspan: _vm.getColSpan(rowIndex, colIndex),
                          rowspan: _vm.getRowSpan(rowIndex, colIndex),
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(row[colIndex] || "") +
                            "\n                "
                        ),
                      ]
                    )
                  : _vm._e()
              }
            ),
            0
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.tableData, function (row, rowIndex) {
          return _c(
            "tr",
            { key: "row-" + rowIndex },
            _vm._l(
              Array.from({ length: _vm.dataLength }, function (_, index) {
                return index
              }),
              function (colIndex) {
                return !_vm.isMergedCell(rowIndex + _vm.headerRows, colIndex) &&
                  !_vm.isMergedRow(rowIndex + _vm.headerRows, colIndex)
                  ? _c(
                      "td",
                      {
                        key: "cell-" + rowIndex + "-" + colIndex,
                        attrs: {
                          colspan: _vm.getColSpan(
                            rowIndex + _vm.headerRows,
                            colIndex
                          ),
                          rowspan: _vm.getRowSpan(
                            rowIndex + _vm.headerRows,
                            colIndex
                          ),
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(row[colIndex] || "") +
                            "\n                "
                        ),
                      ]
                    )
                  : _vm._e()
              }
            ),
            0
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }