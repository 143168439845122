import api from "../api";
import {sprintf} from "sprintf-js";
import restApi from "../_helpers/api";

export const teacherService = {
    getListHocBa,
    requestNop,
    requestRut,
    pheDuyetRut,
    pheDuyetNop,
    kyHocBa,
    getListLop,
    updateHocBa,
    getOptCode,
    phanLop,
    uploadHocBa,
    uploadBiaHocBa,
    kySoHocBa,
    getReport,
    getHash,
    kyUsbHocBa,
    getListGiaoVien,
    uploadFile,
    updateFileSignature,
    getInfoGv,
    duyetChuKyCacGiaoVienDaChon,
    getDsBiaHocBa
};

function getDsBiaHocBa(data){
    let uri = api.giaovien.dsBiaHocBa;
    return restApi.get(uri,data);
}

function duyetChuKyCacGiaoVienDaChon(data){
    let uri = api.giaovien.duyetChuKy;
    return restApi.post(uri,data);
}
function getInfoGv(){
    let uri = api.giaovien.info;
    return restApi.get(uri,{});
}

function getListGiaoVien(data){
    let uri = api.giaovien.listGiaoVien;
    return restApi.get(uri,data);
}

function kyUsbHocBa(data){
    let uri = api.giaovien.kyUsbHocBa;
    return restApi.post(uri,data);
}

function getHash(data){
    let uri = api.giaovien.getHashUsb;
    return restApi.post(uri,data);
}

function getListHocBa(data){
    let uri = api.giaovien.listHocba;
    return restApi.get(uri,data);
}

function requestNop(data){
    let uri = api.giaovien.listRequestNop;
    return restApi.get(uri,data);
}

function requestRut(data){
    let uri = api.giaovien.listRequestRut;
    return restApi.get(uri,data);
}

function pheDuyetNop(data){
    let uri = api.giaovien.pheDuyetNop;
    return restApi.post(uri,data);
}

function pheDuyetRut(data){
    let uri = api.giaovien.pheDuyetRut;
    return restApi.post(uri,data);
}

function kyHocBa (data){
    let uri = api.giaovien.kyHocBa;
    return restApi.post(uri,data);
}

function kySoHocBa (data){
    let uri = api.giaovien.kySoHocBa;
    return restApi.post(uri,data,5*60*1000);
}

function getListLop(data){
    let uri = api.giaovien.listLop;
    return restApi.get(uri,data);
}

function updateHocBa(data){
    let uri = api.giaovien.updateHocBa;
    return restApi.post(uri,data);
}

function getOptCode(){
    let uri = api.giaovien.getOtp;
    let params = {};
    return restApi.post(uri,params);
}

function phanLop(data){
    let uri = api.giaovien.phanLop;
    return restApi.post(uri,data);
}

function uploadHocBa(data){
    let uri = api.giaovien.uploadHocBa;
    return restApi.post(uri,data);
}

function uploadBiaHocBa(data){
    let uri = api.giaovien.uploadBiaHocBa;
    return restApi.post(uri,data);
}

function getReport(data){
    let uri = api.giaovien.xemBaoCao;
    return restApi.get(uri,data);
}

function uploadFile(data){
    let uri = api.giaovien.uploadfile;
    return restApi.post(uri,data);
}

function updateFileSignature(data){
    let uri = api.giaovien.uploadfile;
    return restApi.post(uri,data);
}
