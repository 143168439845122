import { render, staticRenderFns } from "./QuanLyKyThay.vue?vue&type=template&id=1b4986a1&scoped=true&"
import script from "./QuanLyKyThay.vue?vue&type=script&lang=js&"
export * from "./QuanLyKyThay.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b4986a1",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\xampp\\htdocs\\sProfiles\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1b4986a1')) {
      api.createRecord('1b4986a1', component.options)
    } else {
      api.reload('1b4986a1', component.options)
    }
    module.hot.accept("./QuanLyKyThay.vue?vue&type=template&id=1b4986a1&scoped=true&", function () {
      api.rerender('1b4986a1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/pages/QuanLyKyThay.vue"
export default component.exports