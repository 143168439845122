import BootstrapDialog from "bootstrap4-dialog";
export default {

    confirmDialog(content, btnOkTitle, fn) {
        BootstrapDialog.show({
            title: "Thông báo",
            type: BootstrapDialog.TYPE_DEFAULT,
            size: BootstrapDialog.SIZE_SMALL,
            message: content,
            buttons: [
                {
                    label: 'Đóng',
                    action: (dialog) => {
                        dialog.close();
                    }
                },
                {
                    label: btnOkTitle,
                    cssClass: 'btn-primary',
                    action: (dialog) => {
                        dialog.close();
                        if (typeof fn === "function") {
                            fn();
                        }
                    }
                }
            ]
        });
    },

    confirmDialogCustom(content, btnOkTitle, okFn, cancelFn, btnCancelTitle = 'Đóng', okBtn = false, size) {
        BootstrapDialog.show({
            title: "Thông báo",
            type: BootstrapDialog.TYPE_DEFAULT,
            size: size || BootstrapDialog.SIZE_SMALL,
            message: content,
            buttons: [
                {
                    label: btnCancelTitle,
                    action: (dialog) => {
                        dialog.close();
                        if (typeof cancelFn === "function") {
                            cancelFn();
                        }
                    }
                },
                {
                    label: btnOkTitle,
                    cssClass: 'btn-primary',
                    action: (dialog) => {
                        okBtn = true;
                        dialog.close();
                        if (typeof okFn === "function") {
                            okFn();
                        }
                    }
                }
            ],
            onhide: (dialogRef) => {
                if(!okBtn){
                    if (typeof cancelFn === "function") {
                        cancelFn();
                    }
                }
            }
        });
    },

    showDialog(title, content, fnOnHide, size) {
        if (size === undefined || size === null) {
            size = BootstrapDialog.SIZE_SMALL;
        }
        let dialog = BootstrapDialog.show({
            title: title,
            type: BootstrapDialog.TYPE_DEFAULT,
            size: size,
            message: content,
            buttons: [
                {
                    label: 'Đóng',
                    action: (dialog) => {
                        if (typeof fnOnHide === "function") {
                            fnOnHide();
                        }
                        dialog.close();
                    }
                },
            ],
            onhide: (dialogRef) => {
                if (typeof fnOnHide === "function") {
                    fnOnHide();
                }
            }
        });
    },

}
