<template>
  <div class="hocbaDetail">
    <div v-if="trangThaiDiemTongKetMon!=0">
      <div
          v-loading="trangThaiDiemTongKetMon==2"
          element-loading-text="Loading..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(255, 255, 255, 0.8)">

        <div  class="page__content dts-custom-table">
          <div>
            <el-table
            :max-height="maxHeight"
                :data="kqhtHocSinhs"
                boder
                style="width: 100%"
                >
              <el-table-column
                  fixed  align="center"
                  type="index"
                  label="STT"
                  width="50">
              </el-table-column>
              <el-table-column
                  align="left"
                  header-align="center"
                  fixed
                  prop="hoTen"
                  label="Họ tên"
                  width="180">
              </el-table-column>
              <el-table-column
                  align="center"
                  header-align="center"
                  fixed
                  prop="tenLop"
                  label="Tên lớp"
                  width="70">
              </el-table-column>
              <el-table-column
                  align="center"
                  header-align="center"
                  fixed
                  prop="namHoc"
                  label="Năm học"
                  width="110">
              </el-table-column>
              <template v-for="(item,index) in tenMonHocs">
                <el-table-column  align="center" :label="item">
                  <el-table-column
                      label="H Kỳ I" align="center"
                      width="55">
                    <template slot-scope="scope">
                      <div>
                        {{scope.row.ketQuaHocTaps[index].diemHocKyI }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                      label="H Kỳ II" align="center"
                      width="55">
                    <template slot-scope="scope">
                      <div>
                        {{scope.row.ketQuaHocTaps[index].diemHocKyII }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                      label="CN" align="center"
                      width="50">
                    <template slot-scope="scope">
                      <div>
                        {{scope.row.ketQuaHocTaps[index].diemCaNam }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column align="center"
                                   label="Điểm hoặc xếp lại sau KT lại (nếu có)"
                                   width="80">

                    <template slot-scope="scope">
                      <div>
                        {{scope.row.ketQuaHocTaps[index].diemThiLai }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column align="center"
                                   label="Giáo viên"
                                   width="170">
                    <template slot-scope="scope">
                      <div style="text-align:left">
                        {{scope.row.ketQuaHocTaps[index].tenGiaoVien}}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column align="center"
                                   label="Trạng thái"
                                   width="90">
                    <template slot-scope="scope">
                      <div>
                        {{getTrangThaiXacNhan(scope.row.ketQuaHocTaps[index].trangThaiXacNhan) }}
                      </div>
                    </template>
                  </el-table-column>
                </el-table-column>
              </template>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="text-center">
        <p>
          Không có dữ liệu</p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
export default {
  props: [
    'id'
  ],
  name: "diemTongKetMon",

  components: {
  },

  computed: {
    ...mapState('hocba', [
      "diemTongKetMon",
      "trangThaiDiemTongKetMon"
    ]),
  },

  data() {
    return {
      showPopUp: false,
      kqhtHocSinhs:[],
      kqhtHocSinhs:[], 
      maxHeight: window.innerHeight,
      tenMonHocs:[],
      tieuDe:"",
    }
  },
  methods: {
    getTrangThaiXacNhan(e){
      let tmp = "";
      if(e==1){
        tmp = "Đã ký";
      }
      else{
        tmp= "Chưa ký";
      }
      return tmp;
    },
    closePopup() {
      this.showPopUp = false;
    },
  },
  created() {

  },
  beforeMount() {
  },
  mounted() {
    console.log("Mount trong thông tin điểm")

  },
  watch: {
    diemTongKetMon(val) {
      if (val) {
        this.kqhtHocSinhs=val.kqhtHocSinhs;

        this.tenMonHocs=val.tenMonHocs;
        this.tieuDe=val.tieuDe;
      }
      else {
        this.kqhtHocSinhs=[];
        this.tenMonHocs=[];
        this.tieuDe="";
      }
    },
  }
}
</script>

<style scoped>
</style>
