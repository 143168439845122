<template>
  <div id="danhSachLop" class="page page-file file detailHocBa" v-loading.fullscreen.lock="fullscreenLoading">
    <div class="page__title d-flex align-items-center justify-content-between">
      <h1 class="title">Kết chuyển lên lớp</h1>
    </div>
    <div class="box-upload-file">
      <el-row :gutter="24">
        <el-col :xs="24" :sm="12" :md="8" :lg="4">
          <label>Năm học</label>
          <eselect style="width:100%" collapseTags v-model="namHoc" placeholder="Chọn" filterable
                   :data="list_nam_hoc" :fields="['name','value']"/>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="4">
          <label>Cấp học</label>
          <eselect style="width:100%" :disabled="list_cap_hoc.length==1" @change="capHocChange"
                   v-model="capHoc" placeholder="Chọn" filterable
                   :data="list_cap_hoc"
                   :fields="['name','value']"/>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="4">
          <label>Khối học</label>
          <eselect style="width:100%" collapseTags v-model="khoiHoc" placeholder="Chọn" filterable
                   :data="danh_sach_khoi_hoc" :fields="['label','value']"/>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="6">
          <div style="color: transparent">Tìm kiếm</div>
          <el-button class="m-0" @click.prevent="layDanhSachLopHoc" type="primary">Tìm kiếm
          </el-button>
        </el-col>
      </el-row>
    </div>

    <div class="page__content">
      <div class="listNew mb-5">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-table :max-height="maxHeightTable" fit lazy :data="danh_sach_lop" border
                      style="width: 100%;">
              <el-table-column fixed align="center" type="index" label="STT" width="50">
              </el-table-column>
              <el-table-column align="center" fixed prop="tenLop" label="Tên lớp cũ">
              </el-table-column>
              <el-table-column align="center" fixed prop="siSoLopHocCu" label="Sĩ số">
              </el-table-column>
              <el-table-column align="center" fixed prop="dienLenLop" label="Thuộc diện lên lớp">
              </el-table-column>
              <!--                            <el-table-column align="center" fixed prop="lopKetChuyen" width="200"   label="Tên lớp kết chuyển">-->
              <!--                            </el-table-column>-->
              <!--                            <el-table-column align="center" fixed prop="siSoLopHocMoi" label="Số học sinh kết chuyển">-->
              <!--                            </el-table-column>-->
              <!--                            <el-table-column align="center" fixed label="Chọn lớp kết chuyển">-->
              <!--                                <template slot-scope="scope">-->
              <!--                                    <eselect style="width:100%" @change="chonLopChuyenDen(scope.row)" size="mini"-->
              <!--                                             collapseTags v-model="scope.row.lopChuyenDen"-->
              <!--                                             :placeholder="'Chọn'" filterable :data="scope.row.lopHocMois"-->
              <!--                                             :fields="['tenLop','maLop']"/>-->
              <!--                                </template>-->
              <!--                            </el-table-column>-->
              <el-table-column align="center" label="Hành động">
                <template slot-scope="scope">
                  <div style="display: flex">
                    <el-button :disabled="!scope.row.lopHocMois||scope.row.siSoLopHocCu==0" size="mini" type="success"
                               @click.prevent="showKetChuyenHocSinh(scope.row)">
                      Kết chuyển
                    </el-button>
                    <!--                                        <el-button size="mini" type="warning" @click.prevent="huyKetChuyen(scope.row)">-->
                    <!--                                            Huỷ-->
                    <!--                                            kết chuyển-->
                    <!--                                        </el-button>-->
                    <el-button size="mini" type="primary" @click.prevent="showLichSu(scope.row)">
                      Lịch sử kết chuyển
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :span="24">
            <div>
              <el-dialog
                  :close-on-press-escape="false"
                  :close-on-click-modal="false" align="center" :title="'Kết chuyển học sinh lớp ' + titleTenLop" top="5"
                  width="50%" class="hisory-huychot"
                  :visible.sync="show_danh_sach_hoc_sinh_ket_chuyen">
                <div>
                  <div class="text-left">
                    <el-row :gutter="24">
                      <el-col :xs="18" :sm="12" :md="12" :lg="12">
                        <label>Lớp học kết chuyển:</label>
                        <eselect style="width:100%" collapseTags v-model="dataChuyenLop.lopMoi"
                                 placeholder="Chọn" filterable
                                 :data="danh_sach_lop_hoc_chuyen_den" :fields="['tenLop','maLop']"/>
                      </el-col>
                      <el-col :span="6">
                        <div style="color: transparent">Tìm kiếm</div>
                        <el-button :disabled="!listHocSinhCheckHuyChot||listHocSinhCheckHuyChot.length==0"
                                   class="mb-1" @click.prevent="ketChuyenDanhSachHocSinh()"
                                   type="success"> Kết chuyển
                        </el-button>
                      </el-col>
                    </el-row>

                  </div>
                  <el-table border fit :max-height="maxHeightHuyChot" :lazy="true" :data="danh_sach_hoc_sinh"
                            width="100%">
                    <el-table-column
                        style="width: 50px"
                        width="50"
                        align="center" header-align="center">
                      <template slot="header" slot-scope="scope">
                        <el-checkbox @change="ChonToanBoHocSinh" v-model="CheckAllStudent"></el-checkbox>
                      </template>
                      <template slot-scope="scope">
                        <el-checkbox @change="chonMoiHocBaHuyChot(scope.row)"
                                     v-model="scope.row.check"></el-checkbox>
                      </template>
                    </el-table-column>
                    <el-table-column align="center" label="STT" type="index" width="50">
                    </el-table-column>
                    <el-table-column align="center" label="Mã học sinh" property="maHS"></el-table-column>
                    <el-table-column header-align="center" label="Tên học sinh" property="tenHS">
                    </el-table-column>
                  </el-table>
                </div>

              </el-dialog>
              <el-dialog
                  :close-on-press-escape="false"
                  :close-on-click-modal="false" align="center" :title="'Lịch sử kết chuyển học sinh lớp ' + titleTenLop"
                  top="5vh" width="80%"
                  :visible.sync="show_his">
                <div>
                  <el-table border fit :max-height="maxHeightHuyChot" :lazy="true" :data="list_his"
                            width="100%">
                    <el-table-column align="center" label="STT" type="index" width="50">
                    </el-table-column>
                    <el-table-column align="center" label="Lớp cũ" property="tenLopCu"></el-table-column>
                    <el-table-column align="center" label="Lớp mới" property="tenLopMoi"></el-table-column>
                    <el-table-column align="center" label="Sĩ số lớp cũ" property="siSoLopHocCu"></el-table-column>
                    <el-table-column align="center" label="Sĩ số lớp mới" property="siSoLopHocMoi"></el-table-column>
                    <el-table-column align="center" label="Số học sinh kết chuyển"
                                     property="dienLenLop"></el-table-column>
                    <el-table-column align="center" label="Thời gian" property="createDateString"></el-table-column>
                    <el-table-column align="center" label="Danh sách kết chuyển">
                      <template slot-scope="scope">
                        <el-button size="mini" type="primary" @click.prevent="showDanhSachKetChuyen(scope.row)">
                          Xem danh sách
                        </el-button>
                      </template>
                    </el-table-column>

                  </el-table>
                </div>

              </el-dialog>
              <el-dialog
                  :close-on-press-escape="false"
                  :close-on-click-modal="false" align="center"
                  :title="'Danh sách học sinh kết chuyển lớp ' + titleTenLop" top="5vh" width="40%"
                  :visible.sync="show_danh_sach_lich_sư">
                <div>
                  <el-table border fit :max-height="maxHeightHuyChot" :lazy="true"
                            :data="danh_sach_hoc_sinh_da_ket_chuyen"
                            width="100%">
                    <el-table-column
                        style="width: 100px"
                        width="50"
                        align="center" header-align="center">
                      <template slot="header" slot-scope="scope">
                        <el-checkbox @change="ChonToanBoHocSinhHuyKetChuyen"
                                     v-model="CheckAllStudentHuyChot"></el-checkbox>
                      </template>
                      <template slot-scope="scope">
                        <el-checkbox @change="chonMoiHocSinhHuyChot(scope.row)"
                                     v-model="scope.row.check"></el-checkbox>
                      </template>
                    </el-table-column>
                    <el-table-column align="center" label="STT" type="index" width="50">
                    </el-table-column>
                    <el-table-column align="left" header-align="center" label="Tên học sinh"
                                     property="tenHS"></el-table-column>
                    <el-table-column align="center" label="Mã học sinh" property="maHS"></el-table-column>
                  </el-table>
                  <div class="text-center mt-4">
                    <el-button size="mini" type="danger" @click.prevent="huyKetChuyenHocSinhDaChon()"
                               :disabled="listHocSinhCheckHuyKetChuyen.length==0">Huỷ kết chuyển
                    </el-button>
                  </div>
                </div>

              </el-dialog>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import {
  mapActions,
  mapState
} from 'vuex';
import ESelectVue from "./../Ui/ESelect";
import constant from "../../_helpers/constant_api";
import api from "../../_helpers/api";
import constant_api from "../../_helpers/constant_api";

export default {
  name: "KetChuyenLenLop",
  metaInfo: {
    title: 'Kết chuyển lên lớp',
    titleTemplate: '',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: ''
    }]
  },
  components: {
    'eselect': ESelectVue,
  },
  computed: {
    ...mapState('account', [
      'user',
      'list_nam_hoc',
      'list_cap_hoc',
      'nam_hoc_hien_tai',
    ]),
    ...mapState('until', [
      'window',
    ])
  },
  mounted() {
    console.log("Mounted DanhSachLop...")
    this.maxHeight = this.getHeightTable()
    this.maxHeight -= 200;
    this.maxHeightHuyChot -= 250;
    this.namHoc = this.nam_hoc_hien_tai;
    this.capHoc = this.list_cap_hoc[0].value
    this.mapDanhSachKhoi()

  },
  data() {
    return {
      show_his: false,
      titleTenLop: '',
      list_his: [],
      maxHeightTable: window.innerHeight,
      fullscreenLoading: false,
      maxHeight: window.innerHeight,
      maxHeightHuyChot: window.innerHeight,
      namHoc: '',
      capHoc: '',
      khoiHoc: '',
      danh_sach_lop: [],
      danh_sach_khoi_hoc: [],
      khoiCap1: [{
        value: 1,
        label: 'Khối 1'
      },
        {
          value: 2,
          label: 'Khối 2'
        },
        {
          value: 3,
          label: 'Khối 3'
        },
        {
          value: 4,
          label: 'Khối 4'
        },
        {
          value: 5,
          label: 'Khối 5'
        },
      ],
      khoiCap2: [{
        value: 6,
        label: 'Khối 6'
      },
        {
          value: 7,
          label: 'Khối 7'
        },
        {
          value: 8,
          label: 'Khối 8'
        },
        {
          value: 9,
          label: 'Khối 9'
        },
      ],
      khoiCap3: [{
        value: 10,
        label: 'Khối 10'
      },
        {
          value: 11,
          label: 'Khối 11'
        },
        {
          value: 12,
          label: 'Khối 12'
        },

      ],
      danh_sach_ky_hoc: [
        {name: 'Học kỳ I', value: 1},
        {name: 'Học kỳ II', value: 2},
        {name: 'Cả năm', value: 3},
      ],

      dataChuyenLop: {
        danhSachHs: [],
        lopCu: '',
        lopMoi: ''
      },
      dataHuyKetChuyen: {
        danhSachHs: [],
        lopCu: '',
        lopMoi: ''
      },
      listHocSinhCheckHuyChot: [],
      listHocSinhCheckHuyKetChuyen: [],
      danh_sach_hoc_sinh: [],
      danh_sach_lop_hoc_chuyen_den: [],
      danh_sach_hoc_sinh_da_ket_chuyen: [],
      listHuyChot: [],
      CheckAllStudent: false,
      show_danh_sach_hoc_sinh_ket_chuyen: false,
      show_danh_sach_lich_sư: false,
      CheckAllStudentHuyChot: false,
    }
  },
  methods: {
    huyKetChuyenHocSinhDaChon() {
      console.log('Huỷ kết chuyển học sinh đã chọn')
      console.log(this.listHocSinhCheckHuyKetChuyen)
      console.log(this.dataHuyKetChuyen)

      this.$confirm('Xác nhận huỷ kết chuyển dữ liệu ' + this.listHocSinhCheckHuyKetChuyen.length + ' học sinh đã chọn?', 'Thông báo', {
        confirmButtonText: 'Huỷ kết chuyển',
        cancelButtonText: 'Hủy',
        confirmButtonClass: 'el-button--danger',
      })
          .then(_ => {
            this.huyKetChuyenDuLieuLop()
          })
          .catch(_ => {
          });
    },
    showDanhSachKetChuyen(item) {
      this.listHocSinhCheckHuyKetChuyen = [];
      this.CheckAllStudentHuyChot = false;
      this.dataHuyKetChuyen.lopCu = item.maLopCu;
      this.dataHuyKetChuyen.lopMoi = item.maLopMoi;
      console.log('showDanhSachKetChuyen')
      console.log(item)
      console.log(item.hocSinhInfos)
      if (item && item.hocSinhInfos) {
        this.show_danh_sach_lich_sư = true;
        let list_data = JSON.parse(JSON.stringify(item.hocSinhInfos))
        for (let i = 0; i < list_data.length; i++) {
          list_data[i].check = false;
        }
        this.danh_sach_hoc_sinh_da_ket_chuyen = list_data
        console.log(this.danh_sach_hoc_sinh_da_ket_chuyen)
      } else {
        this.thongBao('error', 'Không có bản ghi')
      }
    },
    ketChuyenDanhSachHocSinh() {
      if (!this.dataChuyenLop.lopMoi || this.dataChuyenLop.lopMoi == '') {
        this.thongBao('error', 'Vui lòng chọn lớp học chuyển đến');
        return
      }
      console.log('Kết chuyển danh sách học sinh')
      console.log(this.dataChuyenLop)
      let uri = constant.truong.ketChuyenDulieuLop;
      let params = {
        maHSs: this.dataChuyenLop.danhSachHs,
        maLopCu: this.dataChuyenLop.lopCu,
        maLopMoi: this.dataChuyenLop.lopMoi
      }
      this.fullscreenLoading = true;
      api.post(uri, params).then(
          response => {
            console.log("kết chuyển trả về:")
            console.log(response)
            if (response.data.code == 200) {
              this.thongBao('success', 'Kết chuyển thành công.')
              this.layDanhSachLopHoc();
            } else {
              this.thongBao('error', response.data.msg)
            }
            this.fullscreenLoading = false;
          }
      );
    },
    ChonToanBoHocSinh() {
      if (this.CheckAllStudent) {
        console.log('Chọn toàn bộ học sinh')
        for (let i = 0; i < this.danh_sach_hoc_sinh.length; i++) {
          this.danh_sach_hoc_sinh[i].check = true;
        }
      } else {
        console.log('Bỏ chọn toàn bộ')
        for (let i = 0; i < this.danh_sach_hoc_sinh.length; i++) {
          this.danh_sach_hoc_sinh[i].check = false;
        }
      }
      this.maplistHocSinhCheckHuyChot();
    },
    ChonToanBoHocSinhHuyKetChuyen() {
      if (this.CheckAllStudentHuyChot) {
        console.log('Chọn toàn bộ học sinh huỷ kết chuyển')
        for (let i = 0; i < this.danh_sach_hoc_sinh_da_ket_chuyen.length; i++) {
          this.danh_sach_hoc_sinh_da_ket_chuyen[i].check = true;
        }
      } else {
        console.log('Bỏ chọn toàn bộ học sinh đã kết chuyển')
        for (let i = 0; i < this.danh_sach_hoc_sinh_da_ket_chuyen.length; i++) {
          this.danh_sach_hoc_sinh_da_ket_chuyen[i].check = false;
        }
      }
      console.log(this.danh_sach_hoc_sinh_da_ket_chuyen)
      this.maplistHocSinhCheckHuyKetChuyen();
    },
    maplistHocSinhCheckHuyChot() {
      console.log('maplistHocSinhCheckHuyChot')
      console.log(this.danh_sach_hoc_sinh)
      let data = [];
      this.dataChuyenLop.danhSachHs = [];
      for (let i = 0; i < this.danh_sach_hoc_sinh.length; i++) {
        if (this.danh_sach_hoc_sinh[i].check == true) {
          data.push(this.danh_sach_hoc_sinh[i]);
          this.dataChuyenLop.danhSachHs.push(this.danh_sach_hoc_sinh[i].maHS)
        }
      }
      this.listHocSinhCheckHuyChot = data;
      console.log("Danh sách học bạ huỷ chốt check:")
      console.log(this.listHocSinhCheckHuyChot)
      if (this.listHocSinhCheckHuyChot.length == this.danh_sach_hoc_sinh.length) {
        this.CheckAllStudent = true;
      } else {
        this.CheckAllStudent = false;
      }
    },
    maplistHocSinhCheckHuyKetChuyen() {
      console.log('maplistHocSinhCheckHuyKetChuyen')
      console.log(this.danh_sach_hoc_sinh_da_ket_chuyen)
      let data = [];
      this.dataHuyKetChuyen.danhSachHs = [];
      for (let i = 0; i < this.danh_sach_hoc_sinh_da_ket_chuyen.length; i++) {
        if (this.danh_sach_hoc_sinh_da_ket_chuyen[i].check == true) {
          data.push(this.danh_sach_hoc_sinh_da_ket_chuyen[i]);
          this.dataHuyKetChuyen.danhSachHs.push(this.danh_sach_hoc_sinh_da_ket_chuyen[i].maHS)
        }
      }
      this.listHocSinhCheckHuyKetChuyen = data;
      console.log("Danh sách học sinh huỷ kết chuyển:")
      console.log(this.listHocSinhCheckHuyKetChuyen)
      if (this.listHocSinhCheckHuyKetChuyen.length == this.danh_sach_hoc_sinh_da_ket_chuyen.length) {
        this.CheckAllStudentHuyChot = true;
      } else {
        this.CheckAllStudentHuyChot = false;
      }
    },
    chonMoiHocSinhHuyChot(e) {
      console.log('Chọn mỗi học sinh huỷ chốt')
      console.log("chonMoiHocSinhHuyChot")
      console.log(e.check)
      let listChotHocBa = JSON.parse(JSON.stringify(this.danh_sach_hoc_sinh_da_ket_chuyen))
      let newCheck = !e.check;
      for (let i = 0; i < listChotHocBa.length; i++) {
        if (listChotHocBa[i].id == e.id) {
          console.error(newCheck + '<==')
          this.danh_sach_hoc_sinh_da_ket_chuyen[i].check = newCheck;
        }
      }
      console.error('listChotHocBa')
      console.error(listChotHocBa)
      this.danh_sach_hoc_sinh_da_ket_chuyen = JSON.parse(JSON.stringify(listChotHocBa))
      this.maplistHocSinhCheckHuyKetChuyen();
    },
    chonMoiHocBaHuyChot(e) {
      console.log('Chọn mỗi học sinh')
      console.log("chonMoiHocBaChot")
      console.log(e.check)
      let listChotHocBa = JSON.parse(JSON.stringify(this.danh_sach_hoc_sinh_da_ket_chuyen))
      let newCheck = !e.check;
      for (let i = 0; i < listChotHocBa.length; i++) {
        if (listChotHocBa[i].id == e.id) {
          console.error(newCheck + '<==')
          this.danh_sach_hoc_sinh_da_ket_chuyen[i].check = newCheck;
        }
      }
      console.error('listChotHocBa')
      console.error(listChotHocBa)
      this.danh_sach_hoc_sinh_da_ket_chuyen = JSON.parse(JSON.stringify(listChotHocBa))
      this.maplistHocSinhCheckHuyChot();
    },
    showKetChuyenHocSinh(item) {
      this.titleTenLop = item.tenLop
      this.dataChuyenLop.lopMoi = [];
      this.listHocSinhCheckHuyChot = [];
      this.danh_sach_lop_hoc_chuyen_den = item.lopHocMois
      this.dataChuyenLop.lopCu = item.maLop;
      this.CheckAllStudent = false;
      console.log('showKetChuyenHocSinh')
      if (!item.maLop) {

      } else {
        this.getDanhSachHocSinhChuyenLop(item.maLop);
      }
    },
    getDanhSachHocSinhChuyenLop(maLop) {
      console.log('getDanhSachHocSinhChuyenLop')
      console.log(maLop)
      let params = {
        start: 0,
        limit: 900,
        maLop: maLop
      }
      try {
        let uri = constant_api.hocsinh.getListHSKetChuyen;
        this.fullscreenLoading = true;
        this.danh_sach_hoc_sinh = [];
        api.get(uri, params).then(
            response => {
              if (response.data.code == 200) {
                let list_student = response.data.data;
                for (let i = 0; i < list_student.length; i++) {
                  list_student[i].check = false;

                }
                this.danh_sach_hoc_sinh = list_student;
                if (this.danh_sach_hoc_sinh && this.danh_sach_hoc_sinh.length) {
                  console.log('danh sách học sinh')
                  console.log(this.danh_sach_hoc_sinh)
                  this.show_danh_sach_hoc_sinh_ket_chuyen = true;
                }
              } else {
                this.thongBao('error', response.data.msg)
              }
              this.fullscreenLoading = false;
            }
        ).catch((e) => {
          this.fullscreenLoading = false;
        })

      } catch (e) {
        this.thongBao('error', 'Vui lòng thao tác lại.')
      }
    },
    showLichSu(his) {
      var list = his.lichSuKetChuyens
      console.log('showLichSu')
      console.log(list)
      if (list && list.length) {
        this.list_his = list;
        this.show_his = true;
      } else {
        this.thongBao('error', 'Không có lịch sử kết chuyển')
      }
      this.titleTenLop = his.tenLop
    },
    huyKetChuyen(item) {
      console.error('huyKetChuyen')
      console.error(item)
      let params = {
        "maLopCu": item.maLop,
        "maLopMoi": item.maLopMoi
      }

      this.$confirm('Xác nhận huỷ kết chuyển dữ liệu lớp học?', 'Thông báo', {
        confirmButtonText: 'Huỷ kết chuyển',
        cancelButtonText: 'Hủy',
        confirmButtonClass: 'el-button--warning',
      })
          .then(_ => {
            this.huyKetChuyenDuLieuLop(params)
          })
          .catch(_ => {
          });
    },
    chonLopChuyenDen(item) {
      if (!item.lopChuyenDen || item.lopChuyenDen == '') {
        this.thongBao('error', 'Vui lòng chọn lớp kết chuyển tới.')
        return
      } else {
        console.log('chonLopChuyenDen')
        console.log(item)
        let params = {
          "maLopCu": item.maLop,
          "maLopMoi": item.lopChuyenDen
        }

        this.$confirm('Xác nhận kết chuyển dữ liệu học?', 'Thông báo', {
          confirmButtonText: 'Xác nhận',
          cancelButtonText: 'Hủy',
          confirmButtonClass: 'el-button--primary',
        })
            .then(_ => {
              this.ketChuyenDuLieuLop(params)
            })
            .catch(_ => {
            });
      }
    },
    ketChuyenDuLieuLop(data) {
      let uri = constant.truong.ketChuyenDulieuLop;
      let params = [data]
      this.fullscreenLoading = true;
      api.post(uri, params).then(
          response => {
            console.log("kết chuyển trả về:")
            console.log(response)
            this.fullscreenLoading = false;
            if (response.data.code == 200) {
              this.thongBao('success', 'Kết chuyển dữ liệu lớp học thành công.')
              this.layDanhSachLopHoc();
            } else {
              this.thongBao('error', response.data.msg)
            }
          }
      );
    },
    huyKetChuyenDuLieuLop() {
      let uri = constant.truong.huyKetChuyenDulieuLop;
      let params = {
        maLopCu: this.dataHuyKetChuyen.lopCu,
        maLopMoi: this.dataHuyKetChuyen.lopMoi,
        maHSs: this.dataHuyKetChuyen.danhSachHs,
      }
      this.fullscreenLoading = true;
      api.post(uri, params).then(
          response => {
            console.log("kết chuyển trả về:")
            console.log(response)
            this.fullscreenLoading = false;
            if (response.data.code == 200) {
              this.thongBao('success', 'Huỷ kết chuyển thành công.')
              this.layDanhSachLopHoc();
            } else {
              this.thongBao('error', response.data.msg)
            }
          }
      );
    },
    getHeightTable() {
      if (this.window.height && this.window.height > 950) {
        return this.window.height - 100
      }
      if (this.window.height && this.window.height > 850) {
        return 800
      }
      if (this.window.height && this.window.height > 750) {
        return 700
      }
      return 600
    },
    capHocChange() {
      this.khoiHoc = '';
      if (!this.capHoc) {
        this.mapDanhSachKhoi();
        return;
      } else {
        if (this.capHoc == 1) {
          this.danh_sach_khoi_hoc = this.khoiCap1;
        } else if (this.capHoc == 2) {
          this.danh_sach_khoi_hoc = this.khoiCap2;
        } else {
          this.danh_sach_khoi_hoc = this.khoiCap3;
        }
      }
    },
    mapDanhSachKhoi() {
      let arr = [];
      this.danh_sach_khoi_hoc = [];
      for (let i = 0; i < this.list_cap_hoc.length; i++) {
        if (this.list_cap_hoc[i].value == 1) {
          arr = arr.concat(this.khoiCap1)
        }
        if (this.list_cap_hoc[i].value == 2) {
          arr = arr.concat(this.khoiCap2)
        }
        if (this.list_cap_hoc[i].value == 3) {
          arr = arr.concat(this.khoiCap3)
        }
      }
      this.danh_sach_khoi_hoc = arr;
    },
    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t
      }
      if (type == "success") {
        cl = "dts-noty-success"
      }
      if (type == "warning") {
        cl = "dts-noty-warning"
      }
      if (type == "error") {
        cl = "dts-noty-error"
      }
      if (type == "info") {
        cl = "dts-noty-info"
      }
      document.querySelector('.overlay').style.display = 'block';
      this.$message({
        onClose: () => {
          document.querySelector('.overlay').style.display = 'none';
        },
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000
      });
    },
    layDanhSachLopHoc() {
      this.show_danh_sach_hoc_sinh_ket_chuyen = false;
      this.show_danh_sach_lich_sư = false;
      console.log("Lấy danh sách lớp")
      if (!this.namHoc || this.namHoc == '') {
        this.thongBao('error', 'Thiếu thông tin năm học.');
        return;
      }
      ;
      this.danh_sach_lop = [];
      let uri = constant.truong.getListLopKetChuyen;
      let params = {
        namHoc: this.namHoc,
        khoiHoc: this.khoiHoc,
        capHoc: this.capHoc,
      }
      this.fullscreenLoading = true;
      api.get(uri, params).then(
          response => {
            console.log("Danh sách lớp trả về:")
            console.log(response)
            if (response.data.code == 200) {

              let mang = response.data.data;
              for (let i = 0; i < mang.length; i++) {
                mang[i].lopChuyenDen = '';
              }
              this.danh_sach_lop = mang;
              this.fullscreenLoading = false;
            } else {
              console.log("error")
              console.log(response)
              this.thongBao('error', response.data.msg)
              this.fullscreenLoading = false;
            }
          }
      );
    },
  }
}
</script>
<style scoped>
.el-icon-close {
  color: #da1d1d;
  font-weight: 900;
}

.el-icon-check {
  color: blue;
  font-weight: 900;
}

.page .el-table table thead tr th {
  background: #6884bf;
}

input[type=checkbox] {
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.2);
  /* IE */
  -moz-transform: scale(1.2);
  /* FF */
  -webkit-transform: scale(1.2);
  /* Safari and Chrome */
  -o-transform: scale(1.2);
  /* Opera */
  transform: scale(1.2);
  padding: 10px;
}

.dts_checkbox {

  /* Double-sized Checkboxes */
  -ms-transform: scale(1.2);
  /* IE */
  -moz-transform: scale(1.2);
  /* FF */
  -webkit-transform: scale(1.2);
  /* Safari and Chrome */
  -o-transform: scale(1.2);
  /* Opera */
  transform: scale(1.2);
  padding: 10px;
}

.pd-b-5 {
  padding-bottom: 5px;
}
</style>