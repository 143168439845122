var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page page-404" }, [
    _c(
      "div",
      {
        staticClass: "vh-100 d-flex align-items-center justify-content-center",
        attrs: { id: "notfound" },
      },
      [
        _c(
          "div",
          { staticClass: "notfound text-center" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("h2", [_vm._v("Không tìm thấy nội dung này!")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Xin lỗi nhưng trang bạn đang tìm kiếm không tồn tại, đã bị xóa. tên đã thay đổi hoặc tạm thời không có"
              ),
            ]),
            _vm._v(" "),
            _c(
              "router-link",
              { attrs: { to: { name: "CallbackSso" } } },
              [
                _c("el-button", { attrs: { size: "mini", type: "primary" } }, [
                  _vm._v("Quay lại trang chủ "),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { size: "mini", type: "danger" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.checkLogOut()
                  },
                },
              },
              [_vm._v("Đăng nhập lại")]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "notfound-404" }, [
      _c("h1", [_vm._v("4"), _c("span", [_vm._v("0")]), _vm._v("4")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }