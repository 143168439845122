import {
    configKy
} from "../_services";

const state = {
    danhSachCauHinhTheoSo: null,
    danhSachCauHinhSo: null,
    xoaDuLieuCauHinhSo: null,
    xoaCauHinhSo: null,
    themCauHinhSo: null,
    danhSachSo: null,
    resChinhSuaKieuKy: null,
    listConfigKy: null,
    updateStatus: null,
    openID: '',
    createStatus: null,
    hienThiTaiTrang: null
};

const actions = {
    getDanhSachSo({
        commit
    }, data) {

        commit('setHienThiTaiTrang', 2)
        configKy.getDanhSachSo(data).then(
            response => {

                commit('setHienThiTaiTrang', 1)
                commit('setDanhSachSo', response.data.data)
            }
        ).catch(function (e) {

            commit('setHienThiTaiTrang', 1)
            commit('setDanhSachSo', null)
        })
    },
    getConfigKy({
        commit
    }, data) {
        configKy.getListConfig(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('getConfigKy', response.data.data)
                } else {
                    commit('getConfigKy', null)
                }
            }
        ).catch(function (e) {
            commit('getConfigKy', null)
        })
    },
    chinhSuaKieuKy({
        commit
    }, data) {

        commit('setHienThiTaiTrang', 2)
        let dl = {
            stt: 2,
            data: null
        }
        var duLieuChinhSua = data;
        commit('setChinhSuaKieuKy', dl)
        console.log("Chỉnh sửa kiểu ký:")
        configKy.chinhSuaKieuKy(data).then(
            response => {
                commit('setHienThiTaiTrang', 1)
                if (response.data && response.data.code == 200) {
                    let dl = {
                        stt: 1,
                        data: response.data
                    }
                    commit('setChinhSuaKieuKy', dl);
                    commit('ganLaiDuLieuSauChinhSua', duLieuChinhSua);

                } else {
                    let dl = {
                        stt: 1,
                        data: response.data
                    }
                    commit('setChinhSuaKieuKy', dl)
                }
            }
        ).catch(function (e) {
            commit('setHienThiTaiTrang', 1)
            let dl = {
                stt: 2,
                data: null
            }
            commit('setChinhSuaKieuKy', dl)
        })
    },
    getDanhSachCauHinh({
        commit
    }, data) {

        commit('setHienThiTaiTrang', 2)
        configKy.getDanhSachCauHinh(data).then(
            response => {

                commit('setHienThiTaiTrang', 1)
                if (response.data && response.data.code == 200) {
                    commit('setDanhSachCauHinhSo', response.data.data)
                } else {
                    commit('setDanhSachCauHinhSo', null)
                }
            }
        ).catch(function (e) {

            commit('setHienThiTaiTrang', 1)
            commit('setDanhSachCauHinhSo', null)
        })
    },
    layDanhSachCauHinhTheoSo({
        commit
    }, data) {
        let ma_so = data.maSo;
        commit('setHienThiTaiTrang', 2)
        configKy.layDanhSachCauHinhTheoSo(data).then(
            response => {

                commit('setHienThiTaiTrang', 1)
                if (response.data && response.data.code == 200) {
                    let dulieu = {
                        maSo: ma_so,
                        data: response.data.data
                    }
                    commit('setDanhSachCauHinhTheoSo', dulieu)
                } else {
                    let dulieu = {
                        maSo: ma_so,
                        data: null
                    }
                    commit('setDanhSachCauHinhTheoSo', dulieu)
                }
            }
        ).catch(function (e) {

            commit('setHienThiTaiTrang', 1)
            let dulieu = {
                maSo: ma_so,
                data: null
            }
            commit('setDanhSachCauHinhTheoSo', dulieu)
        })
    },
    layDanhSachCauHinhTheoPhong({
        commit
    }, data) {
        let ma_so = data.maSo;
        let ma_phong = data.maPhong;

        commit('setHienThiTaiTrang', 2)
        configKy.layDanhSachCauHinhTheoPhong(data).then(
            response => {

                commit('setHienThiTaiTrang', 1)
                if (response.data && response.data.code == 200) {
                    let dulieu = {
                        maSo: ma_so,
                        maPhong: ma_phong,
                        data: response.data.data
                    }
                    commit('setDanhSachCauHinhTheoPhong', dulieu)
                } else {
                    let dulieu = {
                        maSo: ma_so,
                        maPhong: ma_phong,
                        data: null
                    }
                    commit('setDanhSachCauHinhTheoPhong', dulieu)
                }
            }
        ).catch(function (e) {

            commit('setHienThiTaiTrang', 1)
            let dulieu = {
                maSo: ma_so,
                maPhong: ma_phong,
                data: null
            }
            commit('setDanhSachCauHinhTheoPhong', dulieu)
        })
    },
    themMoiCauHinhSo({
        commit
    }, data) {
        var maPhong = null;
        var maSo = null;
        var doiTuong = null;
        var maTruong = null;
        doiTuong = data.objType; //1: sở; 2:Phòng; 3:Trường
        if (doiTuong != 1) {
            maPhong = data.maPhong;
            maSo = data.maSo;
            maTruong = data.maTruong;
        }
        commit('setHienThiTaiTrang', 2)
        configKy.themMoiCauHinhSo(data).then(
            response => {
                console.log("Res thêm mới cấu hình cho: " + doiTuong);
                console.log(response)
                if (response.data && response.data.code == 200) {
                    if (doiTuong == 3) {
                        console.log("Thêm xong mà đối tượng là trường")
                        let dl = {};
                        dl.objType = doiTuong;
                        dl.trangThai = 1;
                        dl.maPhong = maPhong;
                        dl.maSo = maSo;
                        dl.maTruong = maTruong;
                        dl.data = response.data;
                        commit('setThemMoiCauHinhSo', dl)
                        commit('themMoiCauHinhSo', dl)
                    }
                    if (doiTuong == 2) {
                        let dl = {};
                        dl.objType = doiTuong;
                        dl.trangThai = 1;
                        dl.maPhong = maPhong;
                        dl.maSo = maSo;
                        dl.data = response.data;
                        commit('setThemMoiCauHinhSo', dl)
                        commit('themMoiCauHinhSo', dl)
                    }
                    if (doiTuong == 1) {
                        console.log("Thêm xong mà đối tượng là sở");
                        let dl = {};
                        dl.objType = doiTuong;
                        dl.data = response.data;
                        commit('themMoiCauHinhSo', dl)
                    }
                    commit('setHienThiTaiTrang', 1)
                } else {
                    let dl = {};
                    dl.objType = 4;
                    dl.data = response.data;
                    commit('themMoiCauHinhSo', dl)
                    commit('setHienThiTaiTrang', 1)
                }
            }
        ).catch(function (e) {
            let dl = {};
            dl.objType = 4;
            dl.data = response.data;
            commit('themMoiCauHinhSo', dl)
        })
    },
    xoaCauHinhSo({
        commit
    }, data) {
        var maPhong = null;
        var maSo = null;
        var doiTuong = null;
        var maTruong = null;
        doiTuong = data.objType; //1: sở; 2:Phòng; 3:Trường
        if (doiTuong != 1) {
            maPhong = data.maPhong;
            maSo = data.maSo;
            maTruong = data.maTruong;
        }
        commit('setHienThiTaiTrang', 2)
        configKy.xoaCauHinhSo(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    if (doiTuong == 1) {
                        console.log("Xóa xong là sở");
                        response.data.objType = 1;
                        commit('xoaCauHinhSo', response.data);

                    }
                    if (doiTuong == 2) {
                        console.log("Xóa xong là phòng");
                        let dl = {};
                        dl.objType = doiTuong;
                        dl.trangThai = 1;
                        dl.maPhong = maPhong;
                        dl.maSo = maSo;
                        response.data.objType = 2;
                        commit('xoaCauHinhSo', response.data);
                        commit('setXoaCauHinhSo', dl)
                    }
                    if (doiTuong == 3) {
                        console.log("Xóa xong là trường");
                        let dl = {};
                        dl.objType = doiTuong;
                        dl.trangThai = 1;
                        dl.maPhong = maPhong;
                        dl.maSo = maSo;
                        dl.maTruong = maTruong;
                        response.data.objType = 3;
                        commit('xoaCauHinhSo', response.data);
                        commit('setXoaCauHinhSo', dl)
                    }
                    commit('setHienThiTaiTrang', 1)
                } else {
                    let dulieu = response.data;
                    dulieu.objType = 1;
                    commit('xoaCauHinhSo', dulieu);
                    commit('setHienThiTaiTrang', 1)
                }
            }
        ).catch(function (e) {
            commit('xoaCauHinhSo', null)
            commit('setHienThiTaiTrang', 1)
        })
    },
    updateConfig({
        commit
    }, data) {
        configKy.updateConfig(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('update', {
                        code: 200,
                        msg: 'Chốt ngày thành công'
                    })
                } else {
                    commit('update', {
                        code: response.data.code,
                        msg: response.data.msg
                    })
                }
            }
        ).catch(function (e) {
            commit('update', {
                code: 404,
                msg: 'Lỗi kết nối. Vui lòng thử lại sau'
            })
        })
    },

    createConfig({
        commit
    }, data) {
        configKy.create(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('create', {
                        code: 200,
                        msg: 'Tạo thành công'
                    })
                } else {
                    commit('create', {
                        code: response.data.code,
                        msg: response.data.msg
                    })
                }
            }
        ).catch(function (e) {
            commit('create', {
                code: 404,
                msg: 'Lỗi kết nối. Vui lòng thử lại sau'
            })
        })
    },
    updateConfig2({
        commit
    }, data) {
        configKy.updateConfig2(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('update', {
                        code: 200,
                        msg: 'Chốt ngày thành công'
                    })
                } else {
                    commit('update', {
                        code: response.data.code,
                        msg: response.data.msg
                    })
                }
            }
        ).catch(function (e) {
            commit('update', {
                code: 404,
                msg: 'Lỗi kết nối. Vui lòng thử lại sau'
            })
        })
    }
};

const mutations = {
    setDanhSachCauHinhTheoPhong(state, data) {
        for (let i = 0; i < state.danhSachCauHinhSo.length; i++) {
            if (state.danhSachCauHinhSo[i].maSo == data.maSo) {
                for (let j = 0; j < state.danhSachCauHinhSo[i].list_phong.length; j++) {
                    if (state.danhSachCauHinhSo[i].list_phong[j].maPhong == data.maPhong) {
                        state.danhSachCauHinhSo[i].list_phong[j].listTruong = data.data
                    }
                }
            }
        }
    },
    setDanhSachCauHinhTheoSo(state, data) {
        for (let i = 0; i < state.danhSachCauHinhSo.length; i++) {
            if (state.danhSachCauHinhSo[i].maSo == data.maSo) {
                state.danhSachCauHinhSo[i].list_phong = data.data;
            }
        }
    },
    setHienThiTaiTrang(state, data) {
        //2:loading ; !=2 tải xong
        state.hienThiTaiTrang = data;
    },
    ganLaiDuLieuSauChinhSua(state, data) {
        console.log("ganLaiDuLieuSauChinhSua");
        console.log(data)
        for (let i = 0; i < state.danhSachCauHinhSo.length; i++) {
            if (state.danhSachCauHinhSo[i].maSo == data.maSo) {
                if (data.objType == 1) {
                    state.danhSachCauHinhSo[i].kieuKyGV = data.kieuKyGV;
                    state.danhSachCauHinhSo[i].kieuKyHieuTruong = data.kieuKyHieuTruong;
                    if(state.danhSachCauHinhSo[i].list_phong.length>0){
                        console.log("gán cho phòng của sở chỉnh sửa")
                        for(let j=0;j<state.danhSachCauHinhSo[i].list_phong.length;j++){
                            state.danhSachCauHinhSo[i].list_phong[j].kieuKyGV = data.kieuKyGV;
                            state.danhSachCauHinhSo[i].list_phong[j].kieuKyHieuTruong = data.kieuKyHieuTruong;
                            if(state.danhSachCauHinhSo[i].list_phong[j].listTruong.length>0){
                                for(let k=0;k<state.danhSachCauHinhSo[i].list_phong[j].listTruong.length;k++){
                                    state.danhSachCauHinhSo[i].list_phong[j].listTruong[k].kieuKyHieuTruong =data.kieuKyHieuTruong;
                                    state.danhSachCauHinhSo[i].list_phong[j].listTruong[k].kieuKyGV =data.kieuKyGV;
                                }
                            }
                        }
                    }
                } else {
                    for (let j = 0; j < state.danhSachCauHinhSo[i].list_phong.length; j++) {
                        if (state.danhSachCauHinhSo[i].list_phong[j].maPhong == data.maPhong) {
                            if (data.objType == 2) {
                                state.danhSachCauHinhSo[i].list_phong[j].kieuKyGV = data.kieuKyGV;
                                state.danhSachCauHinhSo[i].list_phong[j].kieuKyHieuTruong = data.kieuKyHieuTruong;
                                if (state.danhSachCauHinhSo[i].list_phong[j].listTruong.length > 0) {
                                    for (let k = 0; k < state.danhSachCauHinhSo[i].list_phong[j].listTruong.length; k++) {
                                        state.danhSachCauHinhSo[i].list_phong[j].listTruong[k].kieuKyHieuTruong =data.kieuKyHieuTruong;
                                        state.danhSachCauHinhSo[i].list_phong[j].listTruong[k].kieuKyGV =data.kieuKyGV;
                                    }
                                }
                            }
                            if (data.objType == 3) {
                                for (let k = 0; k < state.danhSachCauHinhSo[i].list_phong[j].listTruong.length; k++) {
                                    if (state.danhSachCauHinhSo[i].list_phong[j].listTruong[k].maTruong == data.maTruong) {
                                        console.log("Thay đổi trạng thái cho" + data.maTruong)
                                        state.danhSachCauHinhSo[i].list_phong[j].listTruong[k].kieuKyHieuTruong =data.kieuKyHieuTruong;
                                        state.danhSachCauHinhSo[i].list_phong[j].listTruong[k].kieuKyGV =data.kieuKyGV;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    },
    setChinhSuaKieuKy(state, data) {
        state.resChinhSuaKieuKy = data;
    },
    setDanhSachCauHinhSo(state, data) {
        let du_lieu = null;

        if (data) {
            du_lieu = data.map(item => {
                item.list_phong = [];
                return item;
            })
        }
        state.danhSachCauHinhSo = du_lieu;
    },
    setThemMoiCauHinhSo(state, data) {
        for (let i = 0; i < state.danhSachCauHinhSo.length; i++) {
            if (state.danhSachCauHinhSo[i].maSo == data.maSo) {
                for (let j = 0; j < state.danhSachCauHinhSo[i].list_phong.length; j++) {
                    if (state.danhSachCauHinhSo[i].list_phong[j].maPhong == data.maPhong) {
                        if (data.objType == 2) {
                            state.danhSachCauHinhSo[i].list_phong[j].trangThai = 1;
                            state.danhSachCauHinhSo[i].list_phong[j].tenSo = "Đang sử dụng";
                            if (state.danhSachCauHinhSo[i].list_phong[j].listTruong.length > 0) {
                                for (let k = 0; k < state.danhSachCauHinhSo[i].list_phong[j].listTruong.length; k++) {
                                    state.danhSachCauHinhSo[i].list_phong[j].listTruong[k].tenSo = "Đang sử dụng";
                                    state.danhSachCauHinhSo[i].list_phong[j].listTruong[k].trangThai = 1;
                                }
                            }
                        }
                        if (data.objType == 3) {
                            for (let k = 0; k < state.danhSachCauHinhSo[i].list_phong[j].listTruong.length; k++) {
                                if (state.danhSachCauHinhSo[i].list_phong[j].listTruong[k].maTruong == data.maTruong) {
                                    console.log("Thay đổi trạng thái cho" + data.maTruong)
                                    state.danhSachCauHinhSo[i].list_phong[j].listTruong[k].tenSo = "Đang sử dụng";
                                    state.danhSachCauHinhSo[i].list_phong[j].listTruong[k].trangThai = 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    },
    setXoaCauHinhSo(state, data) {
        for (let i = 0; i < state.danhSachCauHinhSo.length; i++) {
            if (state.danhSachCauHinhSo[i].maSo == data.maSo) {
                for (let j = 0; j < state.danhSachCauHinhSo[i].list_phong.length; j++) {
                    if (state.danhSachCauHinhSo[i].list_phong[j].maPhong == data.maPhong) {
                        if (data.objType == 2) {
                            state.danhSachCauHinhSo[i].list_phong[j].trangThai = 0;
                            state.danhSachCauHinhSo[i].list_phong[j].tenSo = "Chưa được sử dụng";
                            if (state.danhSachCauHinhSo[i].list_phong[j].listTruong.length > 0) {
                                for (let k = 0; k < state.danhSachCauHinhSo[i].list_phong[j].listTruong.length; k++) {
                                    state.danhSachCauHinhSo[i].list_phong[j].listTruong[k].tenSo = "Chưa được sử dụng";
                                    state.danhSachCauHinhSo[i].list_phong[j].listTruong[k].trangThai = 0;
                                }
                            }
                        }
                        if (data.objType == 3) {
                            for (let k = 0; k < state.danhSachCauHinhSo[i].list_phong[j].listTruong.length; k++) {
                                if (state.danhSachCauHinhSo[i].list_phong[j].listTruong[k].maTruong == data.maTruong) {
                                    state.danhSachCauHinhSo[i].list_phong[j].listTruong[k].tenSo = "Chưa được sử dụng";
                                    state.danhSachCauHinhSo[i].list_phong[j].listTruong[k].trangThai = 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    },
    themMoiCauHinhSo(state, data) {
        state.themCauHinhSo = data;
    },
    xoaCauHinhSo(state, data) {
        console.log("xoaCauHinhSo" + data)
        state.xoaDuLieuCauHinhSo = data;
    },
    getConfigKy(state, data) {
        state.listConfigKy = data;
    },
    setDanhSachSo(state, data) {
        state.danhSachSo = data;
    },
    update(state, data) {
        state.updateStatus = data;
    },
    setOpenID(state, data) {
        state.openID = data;
    },
    create(state, data) {
        state.createStatus = data;
    },
};

export const configKyModule = {
    namespaced: true,
    state,
    actions,
    mutations
};