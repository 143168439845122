export default {
    getListPath(menu){
        let lstPath = [];
        if (menu.length){
            menu.map(item => {
                lstPath.push(item.menuUrl);
                if (item.children && item.children.length){
                    let arr = this.getListPath(item.children);
                    lstPath = lstPath.concat(arr);

                }
                return item;
            })
        }

       lstPath.push("thong-tin-tai-khoan");

        return lstPath;
    },

    setCookie(name,value,minute) {
        var expires = "";
        if (minute) {
            var date = new Date();
            date.setTime(date.getTime() + (minute*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    },
    deleteCookie(name) {
        document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },
    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    },

    generateStatus(status) {
        let listStatus = [
            {val: 1, text: 'Hoạt động'},
            {val: 2, text: 'Vô hiệu hóa'},
        ];
        let result;
        listStatus.map((r) => {
            if (r.val == status) {
                return result = r.text;
            }
        });

        return result;
    },
    regexChar(char,data){
        let str = data.toString();
        return str.includes(char);
    },
    generateRole(role) {
        let listRole = [
            {val: 1, text: 'Admin'},
            {val: 2, text: 'Khu vực'},
            {val: 3, text: 'Đơn vị đo kiểm'},
            {val: 4, text: 'Toàn quốc'},
        ];
        let result;
        listRole.map((r) => {
            if (r.val == role) {
                return result = r.text;
            }
        });

        return result;
    },
    generateArea(area) {
        let listArea = [
            {val: 1, text: 'Miền Bắc'},
            {val: 2, text: 'Miền Trung'},
            {val: 3, text: 'Miền Nam'},
        ];
        let result;
        listArea.map((r) => {
            if (r.val == area) {
                return result = r.text;
            }
        });

        return result;
    },
    generateNetwork(network) {
        let listNetwork = [
            {val: 1, text: 'Viettel'},
            {val: 2, text: 'Vinaphone'},
            {val: 3, text: 'MobiFone'},
            {val: 4, text: 'Vietnamobile'},
            {val: 5, text: 'GMobile'},
            {val: 6, text: 'Đài phát thanh, đài truyền hình'},
        ];
        let result;
        listNetwork.map((r) => {
            if (r.val == network) {
                return result = r.text;
            }
        });

        return result;
    },

    hideNumberPhone(phone) {
        let str = '';
        str = phone.split('');
        str[4] = '*';
        str[5] = '*';
        str[6] = '*';
        str = str.join('');
        return str;
    },
    validatePassword (value, field, confirmPassword)  {
        const errorMessages = {
          required: {
            moi: "Vui lòng nhập mật khẩu mới",
            nhapLai: "Vui lòng xác nhận mật khẩu mới",
          },
          length: "Mật khẩu phải từ 8 đến 20 kí tự",
          upperCase: "Mật khẩu phải có ít nhất một ký tự hoa",
          specialChar: "Mật khẩu phải có ít nhất một ký tự đặc biệt như ! @ # & ( )",
          number: "Mật khẩu phải có ít nhất một ký tự chữ số [0-9]",
          mismatch: "Mật khẩu xác nhận không khớp",
        };
      
        if (!value) {
          return errorMessages.required[field];
        }
      
        if (value.length < 8 || value.length > 20) {
          return errorMessages.length;
        }
      
        if (!/[A-Z]/.test(value)) {
          return errorMessages.upperCase;
        }
      
        if (!/[!@#&()]/.test(value)) {
          return errorMessages.specialChar;
        }
      
        if (!/[0-9]/.test(value)) {
          return errorMessages.number;
        }
      
        if (field === "nhapLai" && value !== confirmPassword) {
          return errorMessages.mismatch;
        }
      
        return null;
      },

      hasChangesToConfirmBeforeClose(newValue, originalValue) {
        // Hàm so sánh mảng
        const arraysEqual = (arr1, arr2) => {
          if (arr1.length !== arr2.length) return false;
          for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) return false;
          }
          return true;
        };
    
        // So sánh hai mảng
        if (Array.isArray(newValue) && Array.isArray(originalValue)) {
          return !arraysEqual(newValue, originalValue);
        }
    
        // So sánh các giá trị không phải là mảng
        return JSON.stringify(newValue) !== JSON.stringify(originalValue);
      }
}
