var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "page page-file file",
    },
    [_c(_vm.view, { tag: "component", on: { close: _vm.closeDialog } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }