var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "page",
      attrs: { "element-loading-text": "Hệ thống đang xử lý yêu cầu" },
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page__header" },
        [
          _c(
            "el-form",
            {
              ref: "searchForm",
              attrs: {
                rules: _vm.rulesSearch,
                "label-position": "top",
                model: _vm.searchForm,
                "hide-required-asterisk": true,
              },
            },
            [
              _c(
                "el-row",
                {
                  attrs: { gutter: 20 },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.submitSearch("searchForm")
                    },
                  },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 12, sm: 12, md: 6, lg: 4 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Năm học" } },
                        [
                          _c("ESelect", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              collapseTags: "",
                              placeholder: "Chọn",
                              filterable: "",
                              data: _vm.list_nam_hoc,
                              fields: ["name", "value"],
                            },
                            model: {
                              value: _vm.searchForm.year,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "year", $$v)
                              },
                              expression: "searchForm.year",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 12, sm: 12, md: 6, lg: 4 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Cấp học" } },
                        [
                          _c("ESelect", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              disabled: _vm.list_cap_hoc.length == 1,
                              collapseTags: "",
                              placeholder: "Chọn",
                              filterable: "",
                              data: _vm.list_cap_hoc,
                              fields: ["name", "value"],
                            },
                            on: { change: _vm.getKhoiHoc },
                            model: {
                              value: _vm.searchForm.capHoc,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "capHoc", $$v)
                              },
                              expression: "searchForm.capHoc",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 12, sm: 12, md: 6, lg: 4 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Khối học" } },
                        [
                          _c("ESelect", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              collapseTags: "",
                              placeholder: "Chọn",
                              filterable: "",
                              data: _vm.optionGrade,
                              fields: ["label", "value"],
                            },
                            on: {
                              change: function ($event) {
                                return _vm.layDanhSachLop()
                              },
                            },
                            model: {
                              value: _vm.searchForm.khoiHoc,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "khoiHoc", $$v)
                              },
                              expression: "searchForm.khoiHoc",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 12, sm: 12, md: 6, lg: 4 } },
                    [
                      _c("div", { staticStyle: { color: "transparent" } }, [
                        _vm._v("Tìm kiếm"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "m-0",
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitSearch("searchForm")
                            },
                          },
                        },
                        [_vm._v("Tìm kiếm\n            ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticStyle: { display: "none" } }, [
                _c(
                  "div",
                  { staticStyle: { display: "inline-block" } },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "d-inline-block",
                        attrs: { prop: "year" },
                      },
                      [
                        _c("ESelect", {
                          staticStyle: { width: "150px" },
                          attrs: {
                            collapseTags: "",
                            placeholder: "Chọn",
                            filterable: "",
                            data: _vm.list_nam_hoc,
                            fields: ["name", "value"],
                          },
                          model: {
                            value: _vm.searchForm.year,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "year", $$v)
                            },
                            expression: "searchForm.year",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { display: "inline-block" } },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "d-inline-block" },
                      [
                        _c("ESelect", {
                          staticStyle: { width: "120px" },
                          attrs: {
                            collapseTags: "",
                            placeholder: "Chọn",
                            filterable: "",
                            data: _vm.list_cap_hoc,
                            fields: ["name", "value"],
                          },
                          on: { change: _vm.getKhoiHoc },
                          model: {
                            value: _vm.searchForm.capHoc,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "capHoc", $$v)
                            },
                            expression: "searchForm.capHoc",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { display: "inline-block" } },
                  [
                    _c(
                      "el-form-item",
                      { staticClass: "d-inline-block" },
                      [
                        _c("ESelect", {
                          staticStyle: { width: "120px" },
                          attrs: {
                            collapseTags: "",
                            placeholder: "Chọn",
                            filterable: "",
                            data: _vm.optionGrade,
                            fields: ["label", "value"],
                          },
                          model: {
                            value: _vm.searchForm.khoiHoc,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "khoiHoc", $$v)
                            },
                            expression: "searchForm.khoiHoc",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "text-center",
                    staticStyle: { display: "inline-block" },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.submitSearch("searchForm")
                          },
                        },
                      },
                      [_vm._v("Tìm kiếm")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", {}, [
        _c(
          "div",
          {
            staticClass: "table-responsive tableFixHead",
            style: "max-height: " + _vm.maxHeightTable + "px",
          },
          [
            _c(
              "table",
              {
                staticClass: "table table-bordered table-custom text-center",
                staticStyle: { "min-width": "850px", overflow: "scroll" },
              },
              [
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "tbody",
                  [
                    !_vm.dataTable.length
                      ? [_vm._m(2)]
                      : [
                          _vm._l(_vm.dataTable, function (item, i) {
                            return _vm._l(item.lopHocs, function (lop, index) {
                              return _c("tr", [
                                index === 0
                                  ? _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "vertical-align": "middle",
                                        },
                                        attrs: { rowspan: item.lopHocs.length },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(i + 1) +
                                            "\n                "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                index === 0
                                  ? _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "vertical-align": "middle",
                                        },
                                        attrs: { rowspan: item.lopHocs.length },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(item.name) +
                                            "\n                "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(lop.tenLop) +
                                      "\n                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c(
                                    "div",
                                    [
                                      [
                                        item.namHoc - lop.khoiHoc < 2019 ||
                                        lop.khoiHoc >= 6 ||
                                        item.namHoc <= 2022 ||
                                        _vm.user.maSo == "08"
                                          ? _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  background: "#18afa8",
                                                },
                                                attrs: {
                                                  size: "small",
                                                  type: "primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.chotBia(
                                                      lop,
                                                      "qtht"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        Chốt QTHT\n                      "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        item.namHoc - lop.khoiHoc < 2019 ||
                                        lop.khoiHoc >= 6 ||
                                        item.namHoc <= 2022 ||
                                        _vm.user.maSo == "08"
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  size: "small",
                                                  type: "primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.chotBia(
                                                      lop,
                                                      "bia"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "Chốt\n                        bìa\n                      "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.chotHocBa(lop, "hocba")
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                item.namHoc - lop.khoiHoc <
                                                  2019 ||
                                                  lop.khoiHoc >= 6 ||
                                                  item.namHoc <= 2022
                                                  ? "Chốt KQHT"
                                                  : "Chốt học bạ"
                                              ) +
                                              "\n                      "
                                          ),
                                        ]
                                      ),
                                    ],
                                    2
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c(
                                    "div",
                                    [
                                      item.namHoc - lop.khoiHoc < 2019 ||
                                      lop.khoiHoc >= 6 ||
                                      item.namHoc <= 2022 ||
                                      _vm.user.maSo == "08"
                                        ? _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                background: "#ff8233",
                                              },
                                              attrs: {
                                                size: "small",
                                                type: "danger",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.showHuyChotHocBa(
                                                    lop,
                                                    "qtht"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "Huỷ chốt QTHT\n                      "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      item.namHoc - lop.khoiHoc < 2019 ||
                                      lop.khoiHoc >= 6 ||
                                      item.namHoc <= 2022 ||
                                      _vm.user.maSo == "08"
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "small",
                                                type: "danger",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.showHuyChotBiaHocBa(
                                                    lop
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "Huỷ chốt bìa\n                      "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "ml-0",
                                          attrs: {
                                            size: "small",
                                            type: "danger",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.showHuyChotHocBa(
                                                lop,
                                                "hocba"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(
                                                item.namHoc - lop.khoiHoc <
                                                  2019 ||
                                                  lop.khoiHoc >= 6 ||
                                                  item.namHoc <= 2022
                                                  ? "Hủy chốt KQHT"
                                                  : "Hủy chốt học bạ"
                                              ) +
                                              "\n                      "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ])
                            })
                          }),
                        ],
                  ],
                  2
                ),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "hisory-xuly",
          attrs: {
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            align: "center",
            title: _vm.titleClass,
            top: "5",
            visible: _vm.showHistory,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showHistory = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "file--title text-left" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "mb-1 mr-auto",
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.ChotLaiDuLieu(1)
                        },
                      },
                    },
                    [_vm._v(" Chốt lại bìa\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "mb-1 mr-auto",
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.ChotLaiDuLieu(2)
                        },
                      },
                    },
                    [_vm._v(" Chốt lại học bạ\n          ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  attrs: {
                    border: "",
                    fit: "",
                    "max-height": _vm.maxHeight,
                    lazy: true,
                    data: _vm.listHistory,
                    width: "100%",
                  },
                },
                [
                  _c("el-table-column", {
                    staticStyle: { width: "50px" },
                    attrs: {
                      width: "50",
                      align: "center",
                      "header-align": "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _c("el-checkbox", {
                              on: { change: _vm.ChonToanBoHocBaChotLai },
                              model: {
                                value: _vm.CheckAll,
                                callback: function ($$v) {
                                  _vm.CheckAll = $$v
                                },
                                expression: "CheckAll",
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-checkbox", {
                              on: {
                                change: function ($event) {
                                  return _vm.chonMoiHocBaChotLai(scope.row)
                                },
                              },
                              model: {
                                value: scope.row.check,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "check", $$v)
                                },
                                expression: "scope.row.check",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "STT",
                      type: "index",
                      width: "50",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "Mã học sinh",
                      property: "maHS",
                      width: "150",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "header-align": "center",
                      label: "Tên học sinh",
                      property: "tenHS",
                      width: "200",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "header-align": "center",
                      label: "Lỗi tạo bìa",
                      "min-width": 280,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", {
                              staticStyle: {
                                "white-space": "pre",
                                "max-height": "200px",
                                "overflow-x": "hidden",
                                "overflow-y": "auto",
                              },
                              domProps: {
                                innerHTML: _vm._s(scope.row.moTaLoiBiaHocBa),
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "Thời gian bìa",
                      "min-width": 100,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.thoiGianCapNhatBia
                              ? _c("p", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm._f("moment")(
                                          new Date(
                                            scope.row.thoiGianCapNhatBia
                                          ),
                                          "H:mm:ss DD/MM/YYYY"
                                        )
                                      )
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "header-align": "center",
                      label: "Lỗi tạo học bạ",
                      "min-width": 520,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", {
                              staticStyle: {
                                "white-space": "pre",
                                "max-height": "200px",
                                "overflow-x": "hidden",
                                "overflow-y": "auto",
                              },
                              domProps: {
                                innerHTML: _vm._s(scope.row.moTaLoiHocBa),
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "header-align": "center",
                      label: "Thời gian học bạ",
                      "min-width": 100,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.thoiGianCapNhatHocBa
                              ? _c("p", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm._f("moment")(
                                          new Date(
                                            scope.row.thoiGianCapNhatHocBa
                                          ),
                                          "H:mm:ss DD/MM/YYYY"
                                        )
                                      )
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": 120,
                      label: "Hành động",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.moTaLoiBiaHocBa
                              ? [
                                  scope.row.khoiHoc == 1 ||
                                  scope.row.khoiHoc == 6 ||
                                  scope.row.khoiHoc == 10
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "primary",
                                          },
                                          on: {
                                            "~click": function ($event) {
                                              $event.preventDefault()
                                              return _vm.chotBiaHS(scope.row)
                                            },
                                          },
                                        },
                                        [_vm._v("Chốt bìa\n                ")]
                                      )
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.moTaLoiHocBa
                              ? [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "small", type: "primary" },
                                      on: {
                                        "~click": function ($event) {
                                          $event.preventDefault()
                                          return _vm.choHocBaHS(scope.row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "Chốt\n                  học bạ\n                "
                                      ),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "hisory-huychot",
          attrs: {
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            align: "center",
            title: _vm.TextChotHocBa,
            top: "5",
            width: "50%",
            visible: _vm.showHuyChot,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showHuyChot = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "file--title text-left" },
                      [
                        _vm.typeHuyChotDuLieu == "hocba"
                          ? _c(
                              "el-badge",
                              {
                                staticClass: "item",
                                attrs: {
                                  hidden:
                                    _vm.listHocSinhCheckHuyChot.length == 0,
                                  value: _vm.listHocSinhCheckHuyChot.length,
                                  type: "primary",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "ml-0 mb-1 mr-auto",
                                    attrs: {
                                      disabled:
                                        _vm.listHocSinhCheckHuyChot.length == 0,
                                      size: "small",
                                      type: "danger",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.checkHuyChotDuLieu(2)
                                      },
                                    },
                                  },
                                  [_vm._v(" Huỷ chốt Học bạ\n                ")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.typeHuyChotDuLieu == "qtht"
                          ? _c(
                              "el-badge",
                              {
                                staticClass: "item",
                                attrs: {
                                  hidden:
                                    _vm.listHocSinhCheckHuyChot.length == 0,
                                  value: _vm.listHocSinhCheckHuyChot.length,
                                  type: "primary",
                                },
                              },
                              [
                                _vm.typeHuyChotDuLieu == "qtht"
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "mb-1 mr-auto",
                                        staticStyle: { background: "#ff8233" },
                                        attrs: {
                                          disabled:
                                            _vm.listHocSinhCheckHuyChot
                                              .length == 0,
                                          size: "small",
                                          type: "danger",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.checkHuyChotDuLieu(4)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " Huỷ chốt QTHT\n                "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 18 } }, [
                    _c("div", { staticClass: "text-right pt-2" }, [
                      _c("span", [
                        _c("span", { staticStyle: { color: "#DA1D1D" } }, [
                          _vm._v("Lưu ý:"),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticStyle: { color: "#DA1D1D" } }, [
                          _vm._v(
                            "Khi thực hiện thao tác huỷ, tất cả dữ liệu đã ký các thầy cô sẽ phải ký lại từ đầu."
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  attrs: {
                    border: "",
                    fit: "",
                    "max-height": _vm.maxHeightHuyChot,
                    lazy: true,
                    data: _vm.listHuyChot,
                    width: "100%",
                  },
                },
                [
                  _c("el-table-column", {
                    staticStyle: { width: "50px" },
                    attrs: {
                      width: "50",
                      align: "center",
                      "header-align": "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _c("el-checkbox", {
                              on: { change: _vm.ChonToanBoHocBaHuyChot },
                              model: {
                                value: _vm.CheckAll,
                                callback: function ($$v) {
                                  _vm.CheckAll = $$v
                                },
                                expression: "CheckAll",
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-checkbox", {
                              on: {
                                change: function ($event) {
                                  return _vm.chonMoiHocBaHuyChot(scope.row)
                                },
                              },
                              model: {
                                value: scope.row.check,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "check", $$v)
                                },
                                expression: "scope.row.check",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "STT",
                      type: "index",
                      width: "50",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "Mã học sinh",
                      property: "maHS",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "header-align": "center",
                      label: "Tên học sinh",
                      property: "tenHS",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "hisory-huychot",
          attrs: {
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            align: "center",
            title: _vm.TextChotHocBa,
            top: "5",
            width: "50%",
            visible: _vm.showHuyChotBia,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showHuyChotBia = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "file--title text-left" },
                      [
                        _c(
                          "el-badge",
                          {
                            staticClass: "item",
                            attrs: {
                              hidden: _vm.listHocSinhCheckHuyChot.length == 0,
                              value: _vm.listHocSinhCheckHuyChot.length,
                              type: "primary",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "mb-1 mr-auto",
                                attrs: {
                                  disabled:
                                    _vm.listHocSinhCheckHuyChot.length == 0,
                                  size: "small",
                                  type: "danger",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.checkHuyChotDuLieu(3)
                                  },
                                },
                              },
                              [_vm._v(" Huỷ chốt bìa\n                ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "padding-top": "10px" },
                      attrs: { span: 18 },
                    },
                    [
                      _c("div", { staticClass: "file--title text-right" }, [
                        _c("p", [
                          _c("b", { staticStyle: { color: "#DA1D1D" } }, [
                            _vm._v("Lưu ý:"),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticStyle: { color: "#DA1D1D" } }, [
                            _vm._v(
                              " Những học sinh đã ký bìa sẽ không được huỷ chốt."
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  attrs: {
                    border: "",
                    fit: "",
                    "max-height": _vm.maxHeightHuyChot,
                    lazy: true,
                    data: _vm.listHuyChot,
                    width: "100%",
                  },
                },
                [
                  _c("el-table-column", {
                    staticStyle: { width: "50px" },
                    attrs: {
                      width: "50",
                      align: "center",
                      "header-align": "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _c("el-checkbox", {
                              on: { change: _vm.ChonToanBoHocBaHuyChot },
                              model: {
                                value: _vm.CheckAll,
                                callback: function ($$v) {
                                  _vm.CheckAll = $$v
                                },
                                expression: "CheckAll",
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-checkbox", {
                              on: {
                                change: function ($event) {
                                  return _vm.chonMoiHocBaHuyChot(scope.row)
                                },
                              },
                              model: {
                                value: scope.row.check,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "check", $$v)
                                },
                                expression: "scope.row.check",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "STT",
                      type: "index",
                      width: "50",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "Mã học sinh",
                      property: "maHS",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "header-align": "center",
                      label: "Tên học sinh",
                      property: "tenHS",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "hisory-huychot",
          attrs: {
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            align: "center",
            title: _vm.TextChotHocBa,
            top: "5",
            width: "50%",
            visible: _vm.showLichSuHuyChot,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showLichSuHuyChot = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-table",
                {
                  attrs: {
                    border: "",
                    fit: "",
                    "max-height": _vm.maxHeightHuyChot,
                    lazy: true,
                    data: _vm.danh_sach_lich_su_huy_chot,
                    width: "100%",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "STT",
                      type: "index",
                      width: "50",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "Tài khoản huỷ chốt",
                      property: "createdBy",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "Dữ liệu huỷ chốt",
                      property: "tenLoaiHuyChot",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "Số lượng huỷ chốt",
                      property: "soLuongHuyChot",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "Thời gian huỷ chốt",
                      property: "ngayHuyChotString",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      width: "120",
                      align: "center",
                      "header-align": "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _vm._v("\n              Hành động\n            "),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.khoiPhucBanGhi(scope.row)
                                  },
                                },
                              },
                              [_vm._v("Khôi phục\n              ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.showChotHocBa
        ? _c(
            "div",
            [
              _c(
                "el-dialog",
                {
                  staticClass: "hisory-huychot",
                  attrs: {
                    "close-on-press-escape": false,
                    "close-on-click-modal": false,
                    align: "center",
                    title: _vm.TextChotHocBa,
                    top: "5vh",
                    width: "85%",
                    "before-close": _vm.closeModal(),
                    visible: _vm.showChotHocBa,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.showChotHocBa = $event
                    },
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c(
                              "div",
                              {
                                staticClass: "file--title text-left",
                                staticStyle: { margin: "-15px 0 0 -4px" },
                              },
                              [
                                _vm.showHocBa && _vm.typeChotDuLieu == "hocba"
                                  ? _c(
                                      "el-badge",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          hidden:
                                            _vm.listHocSinhCheckChot.length ==
                                            0,
                                          value:
                                            _vm.listHocSinhCheckChot.length,
                                          type: "primary",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "mb-1 mr-auto",
                                            attrs: {
                                              disabled:
                                                _vm.listHocSinhCheckChot
                                                  .length == 0,
                                              size: "small",
                                              type: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.ChotDuLieuHocBa(
                                                  false
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "Chốt Học bạ\n                  "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.typeChotDuLieu == "bia"
                                  ? _c(
                                      "el-badge",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          hidden:
                                            _vm.listHocSinhCheckChot.length ==
                                            0,
                                          value:
                                            _vm.listHocSinhCheckChot.length,
                                          type: "primary",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "mb-1 mr-auto",
                                            attrs: {
                                              disabled:
                                                _vm.listHocSinhCheckChot
                                                  .length == 0,
                                              size: "small",
                                              type: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.ChotDuLieuBiaHocBa()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "Chốt bìa\n                  "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.typeChotDuLieu == "qtht" && !_vm.showHocBa
                                  ? _c(
                                      "el-badge",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          hidden:
                                            _vm.listHocSinhCheckChot.length ==
                                            0,
                                          value:
                                            _vm.listHocSinhCheckChot.length,
                                          type: "primary",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "mb-1 mr-auto",
                                            staticStyle: {
                                              background: "#18afa8",
                                              "margin-right": "20px !important",
                                            },
                                            attrs: {
                                              disabled:
                                                _vm.listHocSinhCheckChot
                                                  .length == 0,
                                              size: "small",
                                              type: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.ChotDuLieuBiaHocBa()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    Chốt QTHT\n                  "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("el-switch", {
                                  model: {
                                    value: _vm.xemTruocKhiChot,
                                    callback: function ($$v) {
                                      _vm.xemTruocKhiChot = $$v
                                    },
                                    expression: "xemTruocKhiChot",
                                  },
                                }),
                                _vm._v(
                                  "\n                Xem học bạ trước khi chốt\n              "
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 24 } },
                        [
                          _vm.showHocBa
                            ? _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      attrs: {
                                        border: "",
                                        fit: "",
                                        "max-height": _vm.maxHeightHuyChot,
                                        lazy: true,
                                        data: _vm.listChotHocBa,
                                        width: "100%",
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        staticStyle: { width: "50px" },
                                        attrs: {
                                          width: "50",
                                          align: "center",
                                          "header-align": "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "header",
                                              fn: function (scope) {
                                                return [
                                                  _c("el-checkbox", {
                                                    attrs: {
                                                      disabled:
                                                        _vm.khongChoChonTatCa,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.ChonToanBoHocBaChot(
                                                          false
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.CheckAllChotHocBa,
                                                      callback: function ($$v) {
                                                        _vm.CheckAllChotHocBa =
                                                          $$v
                                                      },
                                                      expression:
                                                        "CheckAllChotHocBa",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("el-checkbox", {
                                                    attrs: {
                                                      disabled:
                                                        scope.row.thanhCong,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.chonMoiHocBaChot(
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: scope.row.check,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "check",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.check",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2715830024
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "STT",
                                          type: "index",
                                          width: "50",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          width: "130",
                                          align: "center",
                                          label: "Mã học sinh",
                                          property: "maHS",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          width: "250",
                                          "header-align": "center",
                                          label: "Tên học sinh",
                                          property: "tenHS",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          width: "120",
                                          "header-align": "center",
                                          label: "Trạng thái",
                                          property: "trangThai",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.showColGdcd
                                        ? _c(
                                            "el-table-column",
                                            {
                                              attrs: {
                                                align: "center",
                                                label:
                                                  "Hiển thị GDCD trong học bạ",
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  width: "120",
                                                  align: "center",
                                                  "header-align": "center",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "header",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "el-checkbox",
                                                            {
                                                              staticStyle: {
                                                                color: "white",
                                                              },
                                                              on: {
                                                                change:
                                                                  _vm.ChonToanBoAnhHienThiKyIHocBaChot,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.CheckAllHienThi1Ky,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.CheckAllHienThi1Ky =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "CheckAllHienThi1Ky",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "1 kỳ\n                      "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c("el-checkbox", {
                                                            attrs: {
                                                              disabled:
                                                                scope.row
                                                                  .thanhCong,
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.chonHienThi1Ky(
                                                                  scope.row
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                scope.row
                                                                  .hienThi1K,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    scope.row,
                                                                    "hienThi1K",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "scope.row.hienThi1K",
                                                            },
                                                          }),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  563115404
                                                ),
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: {
                                                  width: "120",
                                                  align: "center",
                                                  "header-align": "center",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "header",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "el-checkbox",
                                                            {
                                                              staticStyle: {
                                                                color: "white",
                                                              },
                                                              on: {
                                                                change:
                                                                  _vm.ChonToanBoAnhHienThiKyIIIHocBaChot,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.CheckAllHienThi3Ky,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.CheckAllHienThi3Ky =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "CheckAllHienThi3Ky",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Cả năm\n                      "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c("el-checkbox", {
                                                            attrs: {
                                                              disabled:
                                                                scope.row
                                                                  .thanhCong,
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.chonHienThi3Ky(
                                                                  scope.row
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                scope.row
                                                                  .hienThi3K,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    scope.row,
                                                                    "hienThi3K",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "scope.row.hienThi3K",
                                                            },
                                                          }),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  2991971783
                                                ),
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          width: "120",
                                          label: "Miễn giảm",
                                          align: "center",
                                          "header-align": "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("el-checkbox", {
                                                    attrs: {
                                                      disabled:
                                                        scope.row.thanhCong,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.ChonMienGiam(
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: scope.row.mienGiam,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "mienGiam",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.mienGiam",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3524335815
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "Nội dung",
                                          align: "left",
                                          "header-align": "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("p", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        scope.row.moTaLoiHocBa
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2848201285
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          width: "130",
                                          align: "center",
                                          label: "Thời gian",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  scope.row.thoiGian
                                                    ? _c("div", [
                                                        _vm._v(
                                                          _vm._s(
                                                            scope.row.thoiGian
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1495048942
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showBia
                            ? _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      attrs: {
                                        border: "",
                                        fit: "",
                                        "max-height": _vm.maxHeightHuyChot,
                                        lazy: true,
                                        data: _vm.listChotHocBa,
                                        width: "100%",
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        staticStyle: { width: "50px" },
                                        attrs: {
                                          width: "50",
                                          align: "center",
                                          "header-align": "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "header",
                                              fn: function (scope) {
                                                return [
                                                  _c("el-checkbox", {
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.ChonToanBoHocBaChot(
                                                          false
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.CheckAllChotHocBa,
                                                      callback: function ($$v) {
                                                        _vm.CheckAllChotHocBa =
                                                          $$v
                                                      },
                                                      expression:
                                                        "CheckAllChotHocBa",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("el-checkbox", {
                                                    attrs: {
                                                      disabled:
                                                        scope.row.thanhCong,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.chonMoiHocBaChot(
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: scope.row.check,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "check",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.check",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2640767322
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "STT",
                                          type: "index",
                                          width: "50",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "Mã học sinh",
                                          property: "maHS",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          "header-align": "center",
                                          label: "Tên học sinh",
                                          property: "tenHS",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          "header-align": "center",
                                          label: "Trạng thái",
                                          property: "trangThai",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.showColImg
                                        ? _c("el-table-column", {
                                            attrs: {
                                              width: "120",
                                              align: "center",
                                              "header-align": "center",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "header",
                                                  fn: function (scope) {
                                                    return [
                                                      _vm._v(
                                                        "\n                    Miễn giảm ảnh\n                    "
                                                      ),
                                                      _c("br"),
                                                      _vm._v(" "),
                                                      _c("el-checkbox", {
                                                        staticStyle: {
                                                          color: "white",
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.ChonToanBoAnhHocBaChot,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.CheckAllAnhChotHocBa,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.CheckAllAnhChotHocBa =
                                                              $$v
                                                          },
                                                          expression:
                                                            "CheckAllAnhChotHocBa",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("el-checkbox", {
                                                        attrs: {
                                                          disabled:
                                                            scope.row.thanhCong,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.ChonCoAnh(
                                                              scope.row
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row.anhHS,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "anhHS",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.anhHS",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2732696504
                                            ),
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.typeChotDuLieu != "qtht"
                                        ? _c("el-table-column", {
                                            attrs: {
                                              label: "Nội dung",
                                              align: "left",
                                              "header-align": "center",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("p", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            scope.row
                                                              .moTaLoiBiaHocBa
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              802871311
                                            ),
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "Thời gian",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  scope.row.thoiGian
                                                    ? _c("div", [
                                                        _vm._v(
                                                          _vm._s(
                                                            scope.row.thoiGian
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1495048942
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 24 } },
                        [
                          _c(
                            "el-col",
                            { staticClass: "text-left", attrs: { span: 12 } },
                            [
                              _c("p", [
                                _c("i", [
                                  _vm._v("Danh sách có "),
                                  _c("b", [
                                    _vm._v(_vm._s(_vm.listChotHocBa.length)),
                                  ]),
                                  _vm._v(" học sinh"),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dialog",
                    {
                      staticClass: "hisory-huychot",
                      attrs: {
                        "close-on-press-escape": false,
                        "close-on-click-modal": false,
                        align: "center",
                        title: _vm.TextChotHocBa,
                        fullscreen: "",
                        "append-to-body": "",
                        visible: _vm.showViewPdf,
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.showViewPdf = $event
                        },
                      },
                    },
                    [
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "viewHocBa",
                            staticStyle: {
                              position: "relative",
                              height: "90vh",
                              overflow: "scroll",
                              border: "1px solid black",
                            },
                          },
                          [
                            _c(
                              "marquee",
                              {
                                staticStyle: {
                                  position: "absolute",
                                  top: "-25px",
                                },
                                attrs: { behavior: "", direction: "" },
                              },
                              [
                                _c("b", { staticStyle: { color: "#DA1D1D" } }, [
                                  _vm._v("Lưu ý:"),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticStyle: { color: "blue" } }, [
                                  _vm._v(
                                    "Quý thầy cô vui lòng kiểm tra kỹ trước khi thực hiện chốt/huỷ chốt dữ liệu. Trân trọng cảm ơn."
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.mauMoi
                              ? _c(
                                  "div",
                                  [
                                    _vm.chiTietHocBa
                                      ? _c("viewC1", {
                                          attrs: {
                                            chuKy: true,
                                            fileName: _vm.searchForm.student
                                              ? _vm.searchForm.student
                                              : _vm.searchForm.maLop
                                              ? _vm.searchForm.maLop
                                              : "Học Bạ Học Sinh",
                                            khoiHoc: _vm.searchForm.khoiHoc,
                                            item: _vm.chiTietHocBa,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _c("div", [
                                  _c("iframe", {
                                    staticStyle: {
                                      width: "100%",
                                      height: "87vh",
                                    },
                                    attrs: { src: _vm.linkViewPdf },
                                  }),
                                ]),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 24 } },
                        [
                          _c(
                            "el-col",
                            { staticClass: "text-center", attrs: { span: 24 } },
                            [
                              _c("el-pagination", {
                                attrs: {
                                  "page-size": 1,
                                  "current-page":
                                    _vm.thongTinXemFilePdfHocBa
                                      .hoc_sinh_hien_tai,
                                  layout: "prev, pager, next",
                                  total:
                                    _vm.thongTinXemFilePdfHocBa
                                      .danh_sach_hoc_sinh.length,
                                },
                                on: {
                                  "current-change": _vm.chonTrangXemHocBa,
                                  "update:currentPage": function ($event) {
                                    return _vm.$set(
                                      _vm.thongTinXemFilePdfHocBa,
                                      "hoc_sinh_hien_tai",
                                      $event
                                    )
                                  },
                                  "update:current-page": function ($event) {
                                    return _vm.$set(
                                      _vm.thongTinXemFilePdfHocBa,
                                      "hoc_sinh_hien_tai",
                                      $event
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.showHocBa && _vm.showBia == false
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "primary" },
                                      on: { click: _vm.chotDuLieuHocBaStep2 },
                                    },
                                    [_vm._v("Chốt Học bạ\n              ")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showBia &&
                              _vm.showHocBa == false &&
                              _vm.typeChotDuLieu == "bia"
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "primary" },
                                      on: {
                                        click: _vm.chotDuLieuHocBaStep2Bia,
                                      },
                                    },
                                    [_vm._v("Chốt bìa\n              ")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showBia &&
                              _vm.showHocBa == false &&
                              _vm.typeChotDuLieu == "qtht"
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { background: "#18afa8" },
                                      attrs: { size: "mini", type: "primary" },
                                      on: {
                                        click: _vm.chotDuLieuHocBaStep2Bia,
                                      },
                                    },
                                    [_vm._v("Chốt QTHT\n              ")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.show_huy_chot
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "danger" },
                                      on: {
                                        click: _vm.huyChotDuLieuHocBaStep2,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                Huỷ chốt\n                dữ liệu\n              "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.show_huy_chot
        ? _c(
            "div",
            [
              _c(
                "el-dialog",
                {
                  staticClass: "hisory-huychot",
                  attrs: {
                    "close-on-press-escape": false,
                    "close-on-click-modal": false,
                    align: "center",
                    title: _vm.TextChotHocBa,
                    top: "5vh",
                    width: "70%",
                    "before-close": _vm.closeModal(),
                    visible: _vm.show_huy_chot,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.show_huy_chot = $event
                    },
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c("el-col", { attrs: { span: 6 } }, [
                            _c(
                              "div",
                              { staticClass: "file--title text-left" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "mb-1 mr-auto",
                                    attrs: {
                                      disabled:
                                        _vm.listHocSinhCheckChot.length == 0,
                                      size: "small",
                                      type: "danger",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.ChotDuLieuHocBa(true)
                                      },
                                    },
                                  },
                                  [_vm._v("Huỷ chốt dữ liệu\n                ")]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 18 } }, [
                            _c(
                              "div",
                              { staticClass: "file--title text-left mt-3" },
                              [
                                _c("span", [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#DA1D1D" } },
                                    [_vm._v("Lưu ý:")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#DA1D1D" } },
                                    [
                                      _vm._v(
                                        "Khi thực hiện thao tác huỷ, tất cả dữ liệu đã ký các thầy cô sẽ phải ký lại từ đầu."
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 24 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-table",
                                {
                                  attrs: {
                                    border: "",
                                    fit: "",
                                    "max-height": _vm.maxHeightHuyChot,
                                    lazy: true,
                                    data: _vm.listChotHocBa,
                                    width: "100%",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    staticStyle: { width: "50px" },
                                    attrs: {
                                      width: "50",
                                      align: "center",
                                      "header-align": "center",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "header",
                                          fn: function (scope) {
                                            return [
                                              _c("el-checkbox", {
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.ChonToanBoHocBaChot(
                                                      true
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: _vm.CheckAllChotHocBa,
                                                  callback: function ($$v) {
                                                    _vm.CheckAllChotHocBa = $$v
                                                  },
                                                  expression:
                                                    "CheckAllChotHocBa",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("el-checkbox", {
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.chonMoiHocBaChot(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: scope.row.check,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "check",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "scope.row.check",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      332862229
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "STT",
                                      type: "index",
                                      width: "50",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      width: "130",
                                      align: "center",
                                      label: "Mã học sinh",
                                      property: "maHS",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      width: "250",
                                      "header-align": "center",
                                      label: "Tên học sinh",
                                      property: "tenHS",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      width: "120",
                                      "header-align": "center",
                                      label: "Trạng thái",
                                      property: "trangThai",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      width: "120",
                                      label: "Miễn giảm",
                                      align: "center",
                                      "header-align": "center",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("el-checkbox", {
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.ChonMienGiam(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: scope.row.mienGiam,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "mienGiam",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.mienGiam",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2122797091
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "Nội dung",
                                      align: "left",
                                      "header-align": "center",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("p", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    scope.row.moTaLoiHocBa
                                                  ),
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2848201285
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      width: "130",
                                      align: "center",
                                      label: "Thời gian",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              scope.row.thoiGian
                                                ? _c("div", [
                                                    _vm._v(
                                                      _vm._s(scope.row.thoiGian)
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1495048942
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 24 } },
                        [
                          _c(
                            "el-col",
                            { staticClass: "text-left", attrs: { span: 12 } },
                            [
                              _c("p", [
                                _c("i", [
                                  _vm._v("Danh sách có "),
                                  _c("b", [
                                    _vm._v(_vm._s(_vm.listChotHocBa.length)),
                                  ]),
                                  _vm._v(" học sinh"),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dialog",
                    {
                      staticClass: "hisory-huychot",
                      attrs: {
                        "close-on-press-escape": false,
                        "close-on-click-modal": false,
                        align: "center",
                        title: "Chốt học bạ",
                        fullscreen: "",
                        "append-to-body": "",
                        visible: _vm.showViewPdf,
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.showViewPdf = $event
                        },
                      },
                    },
                    [
                      _c("div", [
                        _c("div", { staticClass: "viewHocBa" }, [
                          _c("iframe", {
                            staticStyle: { width: "100%" },
                            attrs: { src: _vm.linkViewPdf, height: "100%" },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 24 } },
                        [
                          _c(
                            "el-col",
                            { staticClass: "text-center", attrs: { span: 24 } },
                            [
                              _vm.showHocBa && _vm.showBia == false
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "primary" },
                                      on: { click: _vm.chotDuLieuHocBaStep2 },
                                    },
                                    [_vm._v("Chốt dữ liệu\n              ")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showBia && _vm.showHocBa == false
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "primary" },
                                      on: {
                                        click: _vm.chotDuLieuHocBaStep2Bia,
                                      },
                                    },
                                    [_vm._v("Chốt dữ liệu\n              ")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.show_huy_chot
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "danger" },
                                      on: {
                                        click: _vm.huyChotDuLieuHocBaStep2,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                Huỷ chốt\n                dữ liệu\n              "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "page__title d-flex align-items-center justify-content-between",
      },
      [_c("h1", { staticClass: "title" }, [_vm._v("Chốt học bạ")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("STT")]),
        _vm._v(" "),
        _c("th", [_vm._v("Khối học")]),
        _vm._v(" "),
        _c("th", [_vm._v("Lớp")]),
        _vm._v(" "),
        _c("th", { staticStyle: { "min-width": "275px" } }, [
          _vm._v("Chốt dữ liệu"),
        ]),
        _vm._v(" "),
        _c("th", { staticStyle: { "min-width": "275px" } }, [
          _vm._v("Huỷ chốt dữ liệu"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { attrs: { colspan: "10" } }, [
        _c("div", { staticClass: "text-center" }, [_vm._v("Chưa có dữ liệu")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }