

const state = {
};

const actions = {

};
const  getters = {
    auth : (state) => {
        let token = localStorage.getItem('token');
        if (token) {
            return true;
        } else {
            return false;
        }
    },
    checkRole : (state) => {
        let user = localStorage.getItem('user');
        if (user) {
            let data = JSON.parse(user);
            if (data.role){
                return data.role
            }else{
                return false;
            }
        } else {
            return false;
        }
    }
};
const mutations = {
   
};

export const setting = {
    state,
    actions,
    getters,
    mutations
};
