<template>
    <div>
        <div class="page__title d-flex align-items-center justify-content-between">
            <h1 class="title">Ký file pdf</h1>

        </div>
        <el-row :gutter="24">
            <el-col :span="24">
                <div>

                    <iframe ref="pdfIframe" src="/files/pdf/demo.pdf" style="height:500px;width: 100% "></iframe>
<!--                    <div v-for="(element, index) in elements"-->
<!--                         :key="index"-->
<!--                         v-draggable-->
<!--                         @end="onDrop"-->
<!--                         class="draggable-element">-->
<!--                        {{ element.name }}-->
<!--                    </div>-->
                    <draggable v-model="elements">
                        <div v-for="(element, index) in elements" :key="index" class="draggable-element">
                            {{ element.name }}
                        </div>
                    </draggable>
                </div>
            </el-col>
        </el-row>
    </div>

</template>
<script>
import draggable from 'vuedraggable';
export default {
    name: "kyFilePdf",
    metaInfo: {
        title: 'Ký file pdf',
        titleTemplate: '',
        meta: [{
            vmid: 'description',
            name: 'description',
            content: ''
        }]
    },
    computed: {},
    components: {
        draggable
    },

    data() {

        return {
            elements: [
                { name: 'Element 1' },
                { name: 'Element 2' },
                { name: 'Element 3' },
            ],
        }
    },

    methods: {
        onDrop(event) {
            // Lấy element được kéo thả
            const element = event.item;
            // Lấy tọa độ của element trong iframe
            const { left, top } = event.offset;
            // Lấy iframe
            console.error('Vị trí:')
            console.error(left +' và '+ top)
            const iframe = this.$refs.pdfIframe;
            // Lấy context của iframe
            const context = iframe.contentWindow || iframe.contentDocument.defaultView;
            // Tạo một div chứa element
            const div = document.createElement('div');
            div.innerText = element.name;
            div.style.left = `${left}px`;
            div.style.top = `${top}px`;
            // Thêm div vào iframe
            context.document.body.appendChild(div);
        },
    },

    beforeMount() {
    },
    mounted() {
    },
    watch: {}

}
</script>

<style scoped>
</style>