export default {
    type: {
        reConnect: -2,//Kết nội lại
        connect: -1,//Kết nối
        //OTP
        sendOtp: 0,//Gửi OTP ký học bạ
        signOtp: 1,//Ký học bạ bằng OTP
        sendOtpBiaHocBa: 2,//Gửi OTP ký bìa học bạ
        signOtpBiaHocBa: 3,//Ký bìa học bạ bằng OTP
        // //Sim CA
        // signSimCAHocBa: 10,//Ký học bạ bằng sim CA
        // signSimCABiaHocBa: 11,//Ký bìa học bạ bằng sim CA
        // signSimCADauBiaHocBa: 12,//Ký dấu bìa học bạ bằng sim CA
        // signSimCAHieuTruongKyDauBiaHocBa: 13,//Hiệu trưởng ký dấu bìa học bạ bằng sim CA
        // thucHienDongDauBiaAnhSimCa: 14,//Đóng dấu ảnh bìa  bằng sim CA
        // signSimCADongDauKqht: 15,//Đóng dấu bìa kết quả học tập bằng sim CA
        // signSimCAKyQTHT: 15,//Đóng quá trình học tập bằng sim CA
        // signSimCADauQTHT: 16,//Đóng dấu quá trình học tập bằng sim CA

        //Sim CA
        signSimCAHocBa: 10,//Ký học bạ bằng sim CA
        signSimCADongDauKqht: 11,// đóng dấu kqht
        signSimCADauBiaHocBa: 12,//đóng dấu bìa học bạ bằng sim CA
        signSimCAHieuTruongKyDauBiaHocBa: 13,//Hiệu trưởng ký bìa học bạ bằng sim CA
        thucHienDongDauBiaAnhSimCa: 14,//Đóng dấu ảnh bìa  bằng sim CA
        signSimCAKyQTHT: 15,//ký quá trình học tập bằng sim CA
        signSimCADauQTHT: 16,//Đóng dấu quá trình học tập bằng sim CA
        signSimCAScanHocBa: 17,//Ký file scan bằng sim CA

        //USB token
        getHashHocBa: 20,//Lấy hash học bạ
        signUsbHocBa: 21,//Ký học bạ bằng USB
        getHashDongDauBiaHocBa: 22,//Lấy hash đóng dấu bìa
        dongDauBiaHocBa: 23,//đóng dấu bìa học bạ
        // getHashDauBiahHocBa: 24,//Lấy hash ký dấu bìa học bạ bằng usb token
        getHashDauBiahHocBa: 101,//Lấy hash ký dấu bìa học bạ bằng usb token
        kyDauBiahHocBa: 25,//ký dấu bìa học bạ bằng usb token
        getHashHieuTruongKyDauBiahHocBa: 26,//Hiệu trưởng ký dấu bìa học bạ bằng usb token
        hieuTruongKyDauBiahHocBa: 27,//hiệu trưởng ký dấu bìa học bạ bằng usb token
        getHashDongDauBiaAnh: 28,//Lấy hash đóng dấu ảnh bìa kết quả học tập bằng usb token
        // thucHienDongDauBiaAnh: 29,//Thực hiện đóng dấu ảnh bìa kết quả học tập bằng usb token
        thucHienDongDauBiaAnh: 37,//Thực hiện đóng dấu ảnh bìa kết quả học tập bằng usb token
        //Cloud VT
        signCloudCAViettelHocBa: 30,//Ký học bạ bằng cloud Viettel
        signCloudCAViettelBiaHocBa: 31,//Ký dấu học bạ bằng cloud Viettel
        // signCloudCADauBia: 32,//Ký  bìa học bạ bằng cloud Viettel
        signCloudCADauBia: 32,//Ký  bìa học bạ bằng cloud Viettel
        signCloudCAHieuTruongKyBia: 33,//Hiệu trưởng ký dấu bìa học bạ bằng cloud Viettel
        signCloudCADauAnhBia: 34,//Đóng dấu ảnh bìa bằng mysign
        signCloudCAQTHT: 35,//Ký QTHT bằng mysign
        signCloudCAScanHocBa: 38,//Ký file scan bằng mysign
        chotDauBiaKqhtCloudViettel: 36,//Chốt dấu kqht bằng cloud viettel
        kyBiaQTHT: 35,//Ký bìa QTHT mysign
        //Cloud VNPT
        signCloudCAVNPTHocBa: 40,//Ký học bạ bằng cloud vinaphone
        signCloudCAVNPTDongDauKqht: 41,//đóng dấu bìa (kqht) bằng cloud vinaphone
        signCloudCAVNPTKyBia: 42,//ký bìa học bạ bằng cloud vinaphone
        chotDauBiaKqhtCloudVnpt: 45,//Chốt dấu kqht bằng cloud vinaphone
        // dongDauHocBa: 11,//đóng dấu học bạ
        // getHashDongDauBiaKqht: 50,//Lấy hash đóng dấu bìa kết quả học tập bằng usb token
        layHashKySoDiem: 39,//Lấy hash ký sổ điểm
        layHashDongDauSoDiem: 65,//Lấy hash đóng dấu sổ điểm
        signDauSoDiemUSBTOKEN: 66,//Đóng dấu ổ diểm bằng usb
        signKySoDiemUSBTOKEN: 64,//Ký sổ điểm bằng usb
        getHashKyQTHTUSBTOKEN: 50,//Lấy hash đóng dấu bìa kết quả học tập bằng usb token
        getHashKyScanUSBTOKEN: 48,//Lấy hash ký file scan bằng usb token
        signScanUSBTOKEN: 49,// ký file scan bằng usb token
        // thucHienDongDauBiaKqht: 51,//Thực hiện đóng dấu bìa kết quả học tập bằng usb token
        signQTHTUSBTOKEN: 51,//Thực hiện đóng dấu bìa kết quả học tập bằng usb token
        getHashDauQTHTUSBTOKEN:52,//ấy hash đóng dấu quá trình học tập
        signDauQTHTUSBTOKEN:53,//Đóng dấu kqht
        kyBiaHocBaVgca:73,//Ký bìa học bạ
        kyKetQuaHocTapVgca:70,//Kết quả học tập VGCA
        kyQuaTrinhHocTapVgca:75,//Ký quá trình học tập VGCA
        dongDauKqHocTapVgca:71,//đóng dấu kết quả học tập VGCA
        dongDauBiaVgca:72,//đóng dấu bìa VGCA
        dongDauAnhBiaVgca:74,//đóng dấu ảnh bìa VGCA
        dongDauQTHTVgca:76,//đóng dấu quá trình học tập VGCA
        kySoDiemVNPT:69,//Ký sổ điểm VNPT
        kySoDiemMySign:67,//Ký sổ điểm SIMCA
        dongDauSoDiemMySign:68,//Ký sổ điểm SIMCA
        kySoDiemSimCA:18,//Ký sổ điểm SIMCA
        kyHoSoNopLenBoMySign:83,//Ký hồ sơ nộp lên bộ
        getHashNopHoSoBangUSBToken:86,//Laays hash ký hồ sơ ộp len bộ
        signHoSoBangUSBToken:87,//Ký hồ sơ nộp lên bộ
        kySoPhanCong:88,//Ký sổ phân công
        dongDauSoPhanCong:94,//Đóng dấu sổ phân công
        layHashKySoPhanCong:92,//Lấy hash ký sổ phân công
        kySoPhanCongUsb:93,//Ký sổ phân công USB
        layHashDongDauSoPhanCong:98,//Lấy hash đóng dấu sổ phân công
        dongDauSoPhanCongUSB:99,//Đóng dấu sổ phân công USB
        nopHoSoHocBaVgca:100,
    },
};
