<template>
    <div>
<!--        <div  class="bieudo" ref="bieudo"></div>-->
        <apexchart type="bar" height="430" :options="options" :series="series"></apexchart>
    </div>
</template>
<style>
</style>
<script>
import ApexCharts from 'vue-apexcharts'
    // import ApexCharts from 'apexcharts'
    export default {
        props: ['dulieu','dulieucaclop','type'],
        data() {
            return {
                chart: null,
                series : [],
                options: {
                    chart: {
                        type: 'bar',
                        height: '300',
                        stacked: true
                    },
                    fill: {
                        opacity: 1

                    },
                    colors: ['#00a65a', '#FFA200'],
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            barHeight: '90%',
                        },
                    },
                    stroke: {
                        width: 1,
                        colors: ["#fff"]
                    },
                    grid: {
                        xaxis: {
                            lines: {
                                show: true
                            }
                        }
                    },
                    yaxis: {
                        // min: -5,
                        // max: 5,
                        title: {
                            // text: 'Age',
                        },
                    },

                    dataLabels: {
                        enabled: true,
                        formatter: function (val) {
                            return Math.abs(val)
                        },
                    },
                    legend: {

                        show: true,
                        position: 'top',
                    },
                    tooltip: {
                        shared: false,
                        x: {
                            formatter: function (val) {
                                return val
                            }
                        },
                        y: {
                            formatter: function (val) {
                                return Math.abs(val)
                            }
                        }
                    },

                    // title: {
                    //     text: 'Biểu đồ trạng thái học bạ theo môn học'
                    // },

                    xaxis: {
                        categories: [],
                        // title: {
                        //     text: 'Số lượng'
                        // },
                        labels: {
                            formatter: function (val) {
                                return Math.abs(Math.round(val))
                            }
                        }
                    },
                                    }
            }
        },
        mounted() {
            if(this.dulieu){
                this.bindData(this.dulieu)
            }
            if (this.dulieucaclop){
                this.bindData(this.dulieucaclop)

            }

        },
        components: {
            apexchart: ApexCharts,
        },
        watch: {
            dulieu: function (newVal, olVal) {
                if (newVal) {
                    let data = newVal;
                    console.log("watch dữ liệu biểu đồ trạng thái theo môn:")
                    console.log(data)
                    this.bindData(data)
                }
            }
        },
        methods: {
            bindData(e) {
                this.series = e.datas;
                // let element = this.$refs.bieudo;
                // this.chart = new ApexCharts(element, this.options);
                // this.chart.render();
                // // let element = this.$refs.bieudo;
                // // var chart = new ApexCharts(element, this.options);

                if (e) {
                    // this.chart.updateOptions({
                    //     xaxis: {
                    //         categories: e.listMon
                    //     },
                    // });
                    // this.chart.updateSeries(this.options.series)
                    this.options = {...this.options, ...{
                            xaxis: {
                                categories: e.listMon
                            }
                        }}
                }
            }
        },
    }
</script>
<style scoped="scoped">
    .apexcharts-canvas {
        margin: 0 auto !important;
    }
</style>