<template>
    <div>
        <el-select style="width:130px" disabled v-model="searchForm.education" placeholder="Cấp học">
            <el-option v-for="item in optionEducation" :label="item.label" :key="item.value" :value="item.value">
            </el-option>
        </el-select>
        <el-select style="width:130px" v-model="searchForm.grade" placeholder="Chọn khối (*)"
            @change="onChange" @input="onInput" @visible-change="onVisible" @focus="onFocus" @blur="onBlur">
            <el-option v-for="item in optionGrade" :label="item.label" :key="item.value" :value="item.value">
            </el-option>
        </el-select>
    </div>
</template>

<script>
    import {
        mapActions,
        mapState
    } from 'vuex';
    export default {
        name: "CapHocKhoiHoc",

        props: {
            value: {
                required: true
            },
        },

        components: {},

        computed: {
            ...mapState('account', [
                'user'
            ])
        },
        data() {
            return {
                Content: this.value,
                searchForm: {
                    education: 12
                },
                optionEducation: [{
                        value: 1,
                        label: 'Cấp 1'
                    },
                    {
                        value: 2,
                        label: 'Cấp 2'
                    },
                    {
                        value: 3,
                        label: 'Cấp 3'
                    },
          {value: 15,label: 'Liên cấp'},
          {value: 145,label: 'Liên cấp'},
          {value: 14,label: 'Liên cấp'},
          {value: 13,label: 'Liên cấp'},
          {value: 451,label: 'Liên cấp'},
          {value: 23,label: 'Liên cấp'},
          {value: 236,label: 'Liên cấp'},
          {value: 36,label: 'Liên cấp'},
          {value: 452,label: 'Liên cấp'},
          {value: 1245,label: 'Liên cấp'},
          {value: 12,label: 'Liên cấp'},
          {value: 123,label: 'Liên cấp'},
          {value: 12345,label: 'Liên cấp'},
          {value: 1235,label: 'Liên cấp'},
                ],
                optionGrade: [],
                khoiCap1: [{
                        value: 1,
                        label: 'Khối 1'
                    },
                    {
                        value: 2,
                        label: 'Khối 2'
                    },
                    {
                        value: 3,
                        label: 'Khối 3'
                    },
                    {
                        value: 4,
                        label: 'Khối 4'
                    },
                    {
                        value: 5,
                        label: 'Khối 5'
                    },
                ],
                khoiCap2: [{
                        value: 6,
                        label: 'Khối 6'
                    },
                    {
                        value: 7,
                        label: 'Khối 7'
                    },
                    {
                        value: 8,
                        label: 'Khối 8'
                    },
                    {
                        value: 9,
                        label: 'Khối 9'
                    },
                ],
                khoiCap3: [{
                        value: 10,
                        label: 'Khối 10'
                    },
                    {
                        value: 11,
                        label: 'Khối 11'
                    },
                    {
                        value: 12,
                        label: 'Khối 12'
                    },

                ],
            }
        },
        mounted() {
            this.searchForm.education = this.user.capHoc;
        },
        beforeMount() {
            this.getKhoiHoc();
        },

        watch: {

            value() {
                console.log("Value thay đổi:" + this.value)
                this.Content = this.value;
            },
        },

        methods: {


            focus() {
                this.$refs.el_select.focus();
            },
            onChange(e) {
                this.$emit('change', e);
            },
            onInput(e) {
                this.$emit('input', e);
            },

            onVisible(e) {
                this.$emit('visible', e);
            },

            onFocus(e) {
                this.$emit('focus', e);
            },

            onBlur(e) {
                this.$emit('blur', e);
            },

            capHocChange() {},
            khoiHocChange() {},
            getKhoiHoc() {
                if (this.user.capHoc == 1) {
                    this.optionGrade = this.khoiCap1;
                } else if (this.user.capHoc == 2) {
                    this.optionGrade = this.khoiCap2;
                } else if (this.user.capHoc == 3) {
                    this.optionGrade = this.khoiCap3;
                } else if (this.user.capHoc == 12) {
                    this.optionGrade = this.khoiCap1.concat(this.khoiCap2)
                } else if (this.user.capHoc == 13) {
                    this.optionGrade = this.khoiCap1.concat(this.khoiCap3)
                } else if (this.user.capHoc == 23) {
                    this.optionGrade = this.khoiCap2.concat(this.khoiCap3)
                } else {
                    this.optionGrade = this.khoiCap1.concat(this.khoiCap2, this.khoiCap3)
                }
            },
        }
    }

</script>

<style scoped>

</style>
