var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading.fullscreen.lock",
            value: _vm.loadingScreen,
            expression: "loadingScreen",
            modifiers: { fullscreen: true, lock: true },
          },
        ],
        staticClass:
          "page__title d-flex align-items-center justify-content-between",
      },
      [
        _vm.typeAction == "ky"
          ? _c("h1", { staticClass: "title" }, [_vm._v("Ký sổ phân công")])
          : _vm.typeAction == "dongDau"
          ? _c("h1", { staticClass: "title" }, [
              _vm._v("Đóng dấu sổ phân công"),
            ])
          : _c("h1", { staticClass: "title" }, [
              _vm._v("Danh sách sổ phân công"),
            ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "box-upload-file" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 24 } },
          [
            _c(
              "el-col",
              { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 } },
              [
                _c("label", [_vm._v("Năm học")]),
                _vm._v(" "),
                _c("eselect", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    collapseTags: "",
                    placeholder: "Chọn",
                    filterable: "",
                    data: _vm.list_nam_hoc,
                    fields: ["name", "value"],
                  },
                  model: {
                    value: _vm.dataSearch.namHoc,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "namHoc", $$v)
                    },
                    expression: "dataSearch.namHoc",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              { attrs: { xs: 12, sm: 8, md: 6, lg: 3, xl: 3 } },
              [
                _c("label", [_vm._v("Cấp học")]),
                _vm._v(" "),
                _c("eselect", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    disabled: _vm.list_cap_hoc.length == 1,
                    collapseTags: "",
                    placeholder: "Chọn",
                    filterable: "",
                    data: _vm.list_cap_hoc,
                    fields: ["name", "value"],
                  },
                  model: {
                    value: _vm.dataSearch.capHoc,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "capHoc", $$v)
                    },
                    expression: "dataSearch.capHoc",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              {
                staticClass: " lg",
                attrs: { xs: 12, sm: 8, md: 6, lg: 3, xl: 3 },
              },
              [
                _c("div", { staticStyle: { color: "transparent" } }, [
                  _vm._v("Tìm kiếm"),
                ]),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "m-0",
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.getlistSoPhanCong()
                      },
                    },
                  },
                  [_vm._v("Xem")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.dataFileSoDiem.url
      ? _c(
          "div",
          {
            staticClass: "page__container ",
            staticStyle: { "margin-top": "25px" },
          },
          [
            _c("div", { staticClass: "page__content" }, [
              _c(
                "div",
                { staticClass: "listNew mb-5" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c("p", { staticStyle: { color: "#0b2e13" } }, [
                          _vm._v("Trạng thái: "),
                          _c("b", { staticStyle: { color: "blue" } }, [
                            _vm._v(
                              _vm._s(_vm.getTrangThai(_vm.dataFileSoDiem))
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { staticClass: "text-right", attrs: { span: 12 } },
                        [
                          _vm.typeAction == "ky"
                            ? _c(
                                "div",
                                { staticStyle: { display: "contents" } },
                                [
                                  !_vm.dataFileSoDiem.daKy &&
                                  _vm.user.role == 3 &&
                                  _vm.dataFileSoDiem.chot
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.kySoPhanCong(1)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "Ký sổ phân\n                công\n              "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm.typeAction == "dongDau"
                            ? _c(
                                "div",
                                { staticStyle: { display: "contents" } },
                                [
                                  !_vm.dataFileSoDiem.daDongDau &&
                                  _vm.user.role == 1 &&
                                  _vm.dataFileSoDiem.daKy &&
                                  _vm.dataFileSoDiem.chot
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.kySoPhanCong(2)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "Đóng dấu\n                sổ phân công\n              "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                { staticStyle: { display: "contents" } },
                                [
                                  !_vm.dataFileSoDiem.chot
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.chotDuLieuSoDiem(1)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " Chốt\n                dữ\n                liệu sổ phân công\n              "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.dataFileSoDiem.chot
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.chotDuLieuSoDiem(2)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " Hủy\n                chốt\n                dữ liệu sổ phân công\n              "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                          _vm._v(" "),
                          _vm.kieuKyGiaoVien
                            ? _c(
                                "div",
                                {
                                  staticClass: "cursor-pointer",
                                  staticStyle: { display: "contents" },
                                },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: "Thay đổi kiểu ký",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            padding: "7px 5px 5px 5px",
                                            margin: "0",
                                            "margin-left": "8px",
                                          },
                                          attrs: {
                                            type: "primary",
                                            disabled: _vm.countTypeSign == 1,
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              _vm.showChonKieuKy = true
                                            },
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              width: "30px",
                                              height: "17px",
                                            },
                                            attrs: {
                                              src: _vm.getPathImgByType(
                                                _vm.kieuKyGiaoVien
                                              ),
                                              alt: "",
                                            },
                                          }),
                                          _vm._v(
                                            "\n                  Kiểu ký\n                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "box-upload-file",
                            staticStyle: { overflow: "scroll" },
                          },
                          [
                            _c("iframe", {
                              staticStyle: { width: "100%", height: "80vh" },
                              attrs: { src: _vm.dataFileSoDiem.url },
                            }),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "el-dialog",
          {
            attrs: {
              "close-on-press-escape": false,
              "close-on-click-modal": false,
              top: "5vh",
              title: "Xác nhận ký tài liệu",
              width: "50%",
              "append-to-body": "",
              "show-close": false,
              center: "",
              "custom-class": "hienThiThoiGianCho",
              visible: _vm.hien_thi_thoi_gian_doi_ky,
            },
            on: {
              "update:visible": function ($event) {
                _vm.hien_thi_thoi_gian_doi_ky = $event
              },
            },
          },
          [
            _c(
              "el-row",
              { attrs: { gutter: 24 } },
              [
                _c("el-col", { attrs: { span: 24 } }, [
                  _c("p", [
                    _vm._v("Yêu cầu ký số đã được gửi về thiết bị di động."),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticStyle: { "white-space": "nowrap" } }, [
                    _vm._v("Mở\n            "),
                    _vm.kieuKyGiaoVien != 2
                      ? _c("span", [_vm._v("ứng dụng")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.kieuKyGiaoVien == 2
                      ? _c("span", [
                          _vm._v("thiết bị di động "),
                          _c("b", [_vm._v("nhập mã PIN")]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.kieuKyGiaoVien == 5
                      ? _c("span", [_vm._v("MySign")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.kieuKyGiaoVien == 6
                      ? _c("span", [_vm._v("VNPT SmartCA")])
                      : _vm._e(),
                    _vm._v("\n            và nhấn "),
                    _c("b", [_vm._v("Xác nhận")]),
                    _vm._v(" để ký tài liệu."),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-col", { attrs: { xs: 24, sm: 12 } }, [
                  _c("div", { staticClass: "bg-purple-light pt-3" }, [
                    _c("p", [
                      _c("b", { staticClass: "pd-r-14" }, [
                        _vm._v("Ứng dụng: "),
                      ]),
                      _vm._v(" "),
                      _vm.kieuKyGiaoVien == 2
                        ? _c("span", [_vm._v("Viettel CA")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.kieuKyGiaoVien == 5
                        ? _c("span", [_vm._v("MySign")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.kieuKyGiaoVien == 6
                        ? _c("span", [_vm._v("VNPT SmartCA")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.kieuKyGiaoVien == 7
                        ? _c("span", [_vm._v("VGCA")])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticStyle: { "white-space": "nowrap" } }, [
                      _c("b", { staticClass: "pd-r-10" }, [
                        _vm._v("Trạng thái: "),
                      ]),
                      _vm._v("Đang thực hiện ký"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.kieuKyGiaoVien != 2
                    ? _c("div", { staticClass: "text-center pt-3" }, [
                        _c("p", [_vm._v("Thời gian còn lại")]),
                        _vm._v(" "),
                        _c("h4", { staticStyle: { color: "#00A65A" } }, [
                          _vm._v(_vm._s(_vm.getMinutes(_vm.timeLeft))),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", [
                    _c("hr"),
                    _vm._v(" "),
                    _vm.kieuKyGiaoVien == 5
                      ? _c("p", [
                          _c("span", [
                            _c("b", { staticStyle: { color: "blue" } }, [
                              _vm._v("Lưu ý:"),
                            ]),
                          ]),
                          _vm._v(
                            '\n              Nếu sau 10s không thấy có thông báo kí được gửi tới ứng dụng hãy bấm vào "Tải lại yêu cầu" trên ứng\n              dụng.\n              Nếu sau 2 phút vẫn không có yêu cầu kí hãy gọi tới số '
                          ),
                          _c("b", { staticStyle: { color: "#DA1D1D" } }, [
                            _vm._v("18008000"),
                          ]),
                          _vm._v(" bấm nhánh\n              "),
                          _c("b", { staticStyle: { color: "#DA1D1D" } }, [
                            _vm._v("1"),
                          ]),
                          _vm._v(" để được trợ\n              giúp."),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.kieuKyGiaoVien == 2
                      ? _c("p", [
                          _c("b", { staticStyle: { color: "blue" } }, [
                            _vm._v("Lưu ý:"),
                          ]),
                          _vm._v(
                            "\n              Có bất kì lỗi gì về kí hãy gọi số "
                          ),
                          _c("b", { staticStyle: { color: "#DA1D1D" } }, [
                            _vm._v("18008000"),
                          ]),
                          _vm._v(" bấm nhánh "),
                          _c("b", { staticStyle: { color: "#DA1D1D" } }, [
                            _vm._v("1"),
                          ]),
                          _vm._v("\n              để được trợ.\n            "),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("hr"),
                  ]),
                  _vm._v(" "),
                  _vm.kieuKyGiaoVien != 2
                    ? _c("div", [_c("p", [_vm._v("Cài đặt ứng dụng tại")])])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.kieuKyGiaoVien != 2
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 24 } },
                            [
                              _c("el-col", { attrs: { span: 12 } }, [
                                _c("img", {
                                  attrs: {
                                    src: "/images/ch_android.png",
                                    alt: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.getAppAndroid()
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("img", {
                                  attrs: {
                                    src: "/images/appstore_ios.png",
                                    alt: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.getAppIos()
                                    },
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("el-col", { attrs: { span: 12 } }, [
                                _vm.kieuKyGiaoVien == 5
                                  ? _c("img", {
                                      staticStyle: { width: "80% !important" },
                                      attrs: {
                                        src: "/images/qr_code_viettel.png",
                                        alt: "Ảnh qr",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.kieuKyGiaoVien == 6
                                  ? _c("img", {
                                      staticStyle: { width: "80% !important" },
                                      attrs: {
                                        src: "/images/qr_code_vnpt.png",
                                        alt: "Ảnh qr",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.kieuKyGiaoVien == 7
                                  ? _c("img", {
                                      staticStyle: { width: "80% !important" },
                                      attrs: {
                                        src: "/images/qr_code_vnpt.png",
                                        alt: "Ảnh qr",
                                      },
                                    })
                                  : _vm._e(),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c("div", { staticClass: "text-center" }, [
                        _c("img", {
                          attrs: { src: "/images/loading1.gif", alt: "" },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "text-left" }, [
                          _c("p", [
                            _c("b", [
                              _vm._v(
                                "Hệ thống Viettel CA đang thực hiện thao tác ký, Quý Thầy cô vui lòng chờ trong giây\n                lát đến khi hệ thống hoàn thành việc ký. Trân trọng cảm ơn!"
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-col",
                  {
                    staticClass: "hidden-xs-only hide-in-mobile",
                    attrs: { sm: 12 },
                  },
                  [
                    _c("img", {
                      attrs: { src: "/images/mobile_ca.png", alt: "ảnh" },
                    }),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              "close-on-press-escape": false,
              "close-on-click-modal": false,
              title: "Chọn kiểu ký",
              width: "40%",
              center: "",
              "custom-class": "thongTinKyTheoMonHoc",
              "append-to-body": "",
              "show-close": false,
              visible: _vm.showChonKieuKy,
            },
            on: {
              "update:visible": function ($event) {
                _vm.showChonKieuKy = $event
              },
            },
          },
          [
            _c(
              "el-row",
              { attrs: { gutter: 24 } },
              [
                _c(
                  "el-col",
                  { staticClass: "text-center", attrs: { span: 24 } },
                  _vm._l(_vm.listKieuKy, function (type, i) {
                    return _c(
                      "el-radio",
                      {
                        key: i,
                        staticClass: "checkTypeSign",
                        attrs: { label: type.value },
                        on: {
                          change: function ($event) {
                            return _vm.chonKieuKy()
                          },
                        },
                        model: {
                          value: _vm.kieuKyGiaoVien,
                          callback: function ($$v) {
                            _vm.kieuKyGiaoVien = $$v
                          },
                          expression: "kieuKyGiaoVien",
                        },
                      },
                      [
                        _c("img", {
                          staticStyle: { width: "90px", height: "70px" },
                          attrs: { src: _vm.getPathImg(type), alt: "Ảnh" },
                        }),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              "close-on-press-escape": false,
              "close-on-click-modal": false,
              title: "Chọn chứng thư số",
              width: "50%",
              center: "",
              "custom-class": "thongTinKyTheoMonHoc",
              "append-to-body": "",
              "show-close": false,
              "show-close": "",
              visible: _vm.hien_thi_chon_chung_thu_so,
            },
            on: {
              "update:visible": function ($event) {
                _vm.hien_thi_chon_chung_thu_so = $event
              },
            },
          },
          [
            _c(
              "el-row",
              { attrs: { gutter: 24 } },
              [
                _c(
                  "el-table",
                  { attrs: { border: "", data: _vm.danh_sach_chung_thu_so } },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: "STT",
                        width: "50",
                        align: "center",
                        type: "index",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { "header-align": "center", label: "Số serial" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(scope.row.serialNumberDecimal) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        property: "status",
                        label: "Trạng thái",
                        align: "center",
                        width: "150",
                        "header-align": "center",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        property: "validTo",
                        "header-align": "center",
                        align: "center",
                        label: "Ngày hết hiệu lực",
                        width: "220",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        "header-align": "center",
                        label: "Hành động",
                        width: "150",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.setChungThuSo(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("Sử dụng")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.show_ky_usb
          ? _c("KyHocBaUsbAllV2", {
              key: "kySoPhanCong",
              attrs: {
                caphoc: _vm.dataSearch.capHoc,
                namhoc: _vm.dataSearch.namHoc,
                thuchienky: _vm.thuc_hien_ky_usb_edoc,
                typeKy: _vm.typeKy,
                dulieu: _vm.duLieuKyDongDau,
              },
              on: {
                done: function ($event) {
                  return _vm.getlistSoPhanCong()
                },
                close: function ($event) {
                  return _vm.kyHocBaUsbXong()
                },
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }