<template>
    <div class="page__content" v-loading.fullscreen.lock="fullscreenLoading">
        <el-alert
            title="Hướng dẫn"
            :closable="false"
            show-icon=""
            type="info"
            class="mb-2"
        >
            <div>
                Bước 1: Chọn lớp muốn thêm học bạ<br>
                Bước 2: Chọn Hình thức thêm, mặc định hệ thống hiển thị là “Upload file”. Nếu muốn nhập tay thì lựa chọn hình thức là “Nhập thông tin”<br>
                Bước 3: Nếu hình thức là upload file thì chọn File upload, người dùng scan kết quả học tập của năm học đó rồi upload file lên hệ thống, Trang bìa không phải scan. Nếu hình thức là Nhập thông tin thì điền các thông tin của học bạ.<br>
                Bước 4: Nhấn tạo học bạ<br>
                Bước 5: Lặp lại từ bước 1 nếu muốn tạo tiếp học bạ hoặc nhấn button Đóng để kết thúc việc thêm mới.
            </div>
        </el-alert>
        <el-alert
            v-if="listLopThieu.length"
            title="Thiếu học bạ"
            type="warning"
            :closable="false"
            show-icon>
            <ul>
                <li v-for="item in listLopThieu" :key="item.maLop">
                    <div> Năm học: {{item.namHoc}}. Lớp : {{ item.tenLop }}</div>
                </li>
            </ul>
        </el-alert>
        <h5>A. Thông tin học sinh</h5>
        <el-form>
            <div>
                <el-form-item style="width: 220px" class="d-inline-block" prop="maHS">
                    <div>Mã học sinh:</div>
                    <el-input :disabled="checkDisable()" v-model="formHocBa.maHS = getValue('maHS')" placeholder="Nhập"></el-input>
                </el-form-item>
                <el-form-item style="width: 220px" class="d-inline-block" prop="hoten">
                    <div>Họ tên học sinh:</div>
                    <el-input :disabled="checkDisable()" v-model="formHocBa.hoten = getValue('tenHS')" placeholder="Nhập"></el-input>
                </el-form-item>
                <el-form-item style="width: 120px" class="d-inline-block">
                    <div>Giới tính:</div>
                    <el-select :disabled="checkDisable()" v-model="formHocBa.gioiTinh = getValue('gioiTinh')" placeholder="Giới tính">
                        <el-option v-for="item in gioiTinh" :label="item.label" :key="item.value"
                                   :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="d-inline-block" prop="year">
                    <div>Ngày sinh:</div>
                    <el-date-picker style="width: 150px" v-model="formHocBa.ngaySinh = getValue('ngaySinh')" format="dd/MM/yyyy" type="date"
                                    placeholder="Ngày sinh" value-format="dd/MM/yyyy">
                    </el-date-picker>
                </el-form-item>
            </div>
            <div>

                <el-form-item class="d-inline-block" prop="namHoc">
                    <div>Năm học:</div>
                    <el-date-picker disabled prop="namHoc" style="width: 150px" v-model="formHocBa.namHoc" type="year"
                                    placeholder="Năm học" value-format="yyyy">
                    </el-date-picker>
                </el-form-item>
                <el-form-item style="width: 130px" class="d-inline-block" prop="maLop">
                    <div>Lớp:</div>
                    <el-select @change="lopChange" v-model="formHocBa.maLop" placeholder="Chọn lớp">
                        <el-option v-for="item in listLopThieu" :label="item.tenLop" :key="item.maLop"
                                   :value="item.maLop"></el-option>
                    </el-select>
                </el-form-item>
            </div>
        </el-form>
        <h4>
            <el-radio-group v-model="showManual">
                <el-radio :label="1">Upload file</el-radio>
                <el-radio :label="2">Nhập thông tin</el-radio>
            </el-radio-group>

        </h4>
        <el-form :rules="rulesHocBa" ref="formHocBa" :model="formHocBa" :hide-required-asterisk="true">


            <div v-if="!checkDisable()">
                <el-form-item style="width: 400px" class="d-inline-block">
                    <div>Nơi sinh:</div>
                    <el-input type="textarea" v-model="formHocBa.noiSinh = getValue('noiSinh')" placeholder="Nơi sinh"></el-input>
                </el-form-item>
                <el-form-item style="width: 400px" class="d-inline-block">
                    <div>Nơi ở hiện tại:</div>
                    <el-input type="textarea" v-model="formHocBa.noiOHienNay = getValue('noiOHienNay')" placeholder="Nơi ở hiện tại"></el-input>
                </el-form-item>
            </div>
            <div v-if="!checkDisable()">
                <el-form-item style="width: 250px" class="d-inline-block">
                    <div>Họ tên bố:</div>
                    <el-input v-model="formHocBa.hoTenCha = getValue('hoTenCha')" placeholder="Họ tên bố"></el-input>
                </el-form-item>
                <el-form-item style="width: 250px" class="d-inline-block">
                    <div>Nghề nghiệp bố:</div>
                    <el-input v-model="formHocBa.ngheNghiepCha = getValue('ngheNghiepCha')" placeholder="Nghề nghiệp bố"></el-input>
                </el-form-item>
            </div>
            <div v-if="!checkDisable()">
                <el-form-item style="width: 250px" class="d-inline-block">
                    <div>Họ tên mẹ:</div>
                    <el-input v-model="formHocBa.hoTenMe = getValue('hoTenMe')" placeholder="Họ tên mẹ"></el-input>
                </el-form-item>
                <el-form-item style="width: 250px" class="d-inline-block">
                    <div>Nghề nghiệp Mẹ:</div>
                    <el-input v-model="formHocBa.ngheNghiepMe = getValue('ngheNghiepMe')" placeholder="Nghề nghiệp mẹ"></el-input>
                </el-form-item>
            </div>


            <template v-if="showManual==1">
                <h5>B. Tải lên file học bạ</h5>
                <div>
                    <UploadImageBase64
                        style="width: 100px; cursor: pointer;"
                        :default-preview="urlFile"
                        class="hocba__upload mb-1"
                        image-class="v1-image"
                        input-class="v1-image"
                        :max-size="customImageMaxSize"
                        @size-exceeded="onSizeExceeded"
                        placeholder="Tải lên học bạ"
                        @load="onLoad">
                        <template v-slot:showButton>
                           <div class="hocba__uploadfile"><i class="el-icon-upload"></i> Tải lên file học bạ</div>
                        </template>
                    </UploadImageBase64>
                </div>
            </template>
            <template v-else>
                <h5>B. Kết quả học tập</h5>
                <div v-for="(item,index) in monHoc">
                    <div><strong>{{index+1}}. {{item.monHoc}}</strong></div>
                    <div>
                        <el-form-item style="width: 120px" class="d-inline-block">
                            <label>Điểm TB HK I:
                                <el-input v-model="item.diemHocKyI" placeholder="Điểm HK I"></el-input>
                            </label>
                        </el-form-item>
                        <el-form-item style="width: 120px" class="d-inline-block">
                            <label>Điểm TB HK II:
                                <el-input v-model="item.diemHocKyII" placeholder="Điểm HK II"></el-input>
                            </label>
                        </el-form-item>
                        <el-form-item style="width: 120px" class="d-inline-block">
                            <label>Điểm TB cả năm:
                                <el-input v-model="item.diemCaNam" placeholder="Điểm cả năm"></el-input>
                            </label>
                        </el-form-item>
                        <el-form-item class="d-inline-block">
                            <label>Điểm KT lại hoặc xếp loại sau KT lại:
                                <el-input v-model="item.diemThiLai" placeholder="Điểm KT lại"></el-input>
                            </label>
                        </el-form-item>
                    </div>
                </div>

                <div>
                    <h5>C. Tổng kết cả năm</h5>
                    <div>
                        <el-form-item style="width: 120px" class="d-inline-block">
                            <label>Điểm TB HK I:
                                <el-input v-model="formHocBa.diemTBHKI" placeholder="Điểm TB HK I"></el-input>
                            </label>
                        </el-form-item>
                        <el-form-item style="width: 120px" class="d-inline-block">
                            <label>Điểm TB HK II:
                                <el-input v-model="formHocBa.diemTBHKII" placeholder="Điểm TB HK II"></el-input>
                            </label>
                        </el-form-item>
                        <el-form-item style="width: 120px" class="d-inline-block">
                            <label>Điểm TB cả năm:
                                <el-input v-model="formHocBa.diemTBCaNam" placeholder="Điểm TB cả năm"></el-input>
                            </label>
                        </el-form-item>
                        <el-form-item class="d-inline-block">
                            <label>Điểm trung bình sau KT lại:
                                <el-input v-model="formHocBa.diemThiLai" placeholder="Điểm TB sau thi lại"></el-input>
                            </label>
                        </el-form-item>
                    </div>
                    <div>
                        <el-form-item style="width: 120px" class="d-inline-block">
                            <label>Học lực HK I:
                                <el-input v-model="formHocBa.hocLucHKI" placeholder="Học lực HK I"></el-input>
                            </label>
                        </el-form-item>
                        <el-form-item style="width: 120px" class="d-inline-block">
                            <label>Học lực HK II:
                                <el-input v-model="formHocBa.hocLucHKII" placeholder="Học lực HK II"></el-input>
                            </label>
                        </el-form-item>
                        <el-form-item style="width: 120px" class="d-inline-block">
                            <label>Học lực cả năm:
                                <el-input v-model="formHocBa.hocLucCN" placeholder="Học lưc cả năm"></el-input>
                            </label>
                        </el-form-item>
                        <el-form-item class="d-inline-block">
                            <label>Học lực sau KT lại:
                                <el-input v-model="formHocBa.hocLucThiLai" placeholder="Học lực sau thi lại"></el-input>
                            </label>
                        </el-form-item>
                    </div>
                    <div>
                        <el-form-item style="width: 120px" class="d-inline-block">
                            <label>Hạnh kiểm HK I:
                                <el-input v-model="formHocBa.hanhKiemHKI" placeholder="Hạnh kiểm"></el-input>
                            </label>
                        </el-form-item>
                        <el-form-item style="width: 120px" class="d-inline-block">
                            <label>Hạnh kiểm HK II:
                                <el-input v-model="formHocBa.hanhKiemHKII" placeholder="Hạnh kiểm HK II"></el-input>
                            </label>
                        </el-form-item>
                        <el-form-item style="width: 120px" class="d-inline-block">
                            <label>Hạnh kiểm cả năm:
                                <el-input v-model="formHocBa.hanhKiemCaNam" placeholder="Hạnh kiểm cả năm"></el-input>
                            </label>
                        </el-form-item>
                        <el-form-item class="d-inline-block">
                            <label>Hạnh kiểm sau KT lại:
                                <el-input v-model="formHocBa.hanhKiemThiLai" placeholder="Hạnh kiểm sau thi lại"></el-input>
                            </label>
                        </el-form-item>
                    </div>
                </div>
            </template>

            <div class="button mb-5">
                <el-button type="primary" @click="taoHocBa('formHocBa')">Tạo học bạ</el-button>
                <el-button type="info" @click="closeClick">Đóng</el-button>
            </div>
        </el-form>
        <dialog-alert title="Thông báo" :message="messageAlert" :show-dialog="showAlert" @closeDialog="showAlert = false"></dialog-alert>

    </div>
</template>

<script>
import {mapActions, mapState,mapMutations} from "vuex";
import dialog from "../../dialog";
import DialogAlert from "../../components/DialogAlert";
import UploadImageBase64 from "../../components/UploadImageBase64";

export default {
    name: "TrungHoc",
    components : {
        DialogAlert,
        UploadImageBase64
    },
    computed: {
        ...mapState('teacher', [
            "listLop",
            "urlFile"

        ]),
        ...mapState('account', [
            'user'
        ]),
        ...mapState('hocba', [
            "createStatus",
            "detailHocBa",
            "detailHs"
        ])
    },
    data: () => {
        return {
            showManual : 1,
            listLopThieu : [],
            messageAlert : '',
            showAlert : false,
            gioiTinh: [
                {value: '', label: 'Giới tính'},
                {value: 0, label: 'Nữ'},
                {value: 1, label: 'Nam'}
            ],
            fullscreenLoading: false,
            formHocBa: {
                capHoc: '',
                khoiHoc: '',
                namHoc: '',
                maLop: '',
                maHS: '',
                hoTenMe: '',
                ngheNghiepCha: "",
                ngheNghiepMe: "",
                ngheNghiepNguoiGiamHo: "",
                hoTenCha: '',
                gioiTinh: '',
                hoten: '',
                hanhKiemCaNam: "",
                hanhKiemHKI: "",
                hanhKiemHKII: "",
                hanhKiemThiLai: "",
                hocLucCN: "",
                hocLucHKI: "",
                hocLucHKII: "",
                hocLucThiLai: "",
                diemTBHKI: "",
                diemTBHKII: "",
                diemTBCaNam: "",
                ngaySinh: "",
                noiOHienNay: "",
                noiSinh: "",
                ketQuaHocTaps: [],
                diemThiLai : "",
                pathImage : ""
            },
            monHoc: [
                {
                    diemCaNam: "",
                    diemHocKyI: "",
                    diemHocKyII: "",
                    diemThiLai: "",
                    maMonHoc: "TOAN",
                    monHoc: "Toán"
                },
                {
                    diemCaNam: "",
                    diemHocKyI: "",
                    diemHocKyII: "",
                    diemThiLai: "",
                    maMonHoc: "VATLY",
                    monHoc: "Vật lý"
                },
                {
                    maMonHoc: "TINHOC",
                    monHoc: "Tin học",
                    diemCaNam: "",
                    diemHocKyI: "",
                    diemHocKyII: "",
                    diemThiLai: "",
                },
                {
                    maMonHoc: "NGUVAN",
                    monHoc: "Ngữ văn",
                    diemCaNam: "",
                    diemHocKyI: "",
                    diemHocKyII: "",
                    diemThiLai: "",
                },
                {
                    maMonHoc: "SINHHOC",
                    monHoc: "Sinh học",
                    diemCaNam: "",
                    diemHocKyI: "",
                    diemHocKyII: "",
                    diemThiLai: "",
                },
                {
                    maMonHoc: "LICHSU",
                    monHoc: "Lịch sử",
                    diemCaNam: "",
                    diemHocKyI: "",
                    diemHocKyII: "",
                    diemThiLai: "",
                },
                {
                    maMonHoc: "DIALY",
                    monHoc: "Địa lý",
                    diemCaNam: "",
                    diemHocKyI: "",
                    diemHocKyII: "",
                    diemThiLai: "",
                },
                {
                    maMonHoc: "NGOAINGU",
                    monHoc: "Ngoại ngữ",
                    diemCaNam: "",
                    diemHocKyI: "",
                    diemHocKyII: "",
                    diemThiLai: "",
                },

                {
                    maMonHoc: "GDCD",
                    monHoc: "GDCD",
                    diemCaNam: "",
                    diemHocKyI: "",
                    diemHocKyII: "",
                    diemThiLai: "",
                },
                {
                    maMonHoc: "CONGNGHE",
                    monHoc: "Công nghệ",
                    diemCaNam: "",
                    diemHocKyI: "",
                    diemHocKyII: "",
                    diemThiLai: "",
                },
                {
                    maMonHoc: "THEDUC",
                    monHoc: "Thể dục",
                    diemCaNam: "",
                    diemHocKyI: "",
                    diemHocKyII: "",
                    diemThiLai: "",
                },
                {
                    maMonHoc: "AMNHAC",
                    monHoc: "Âm nhạc",
                    diemCaNam: "",
                    diemHocKyI: "",
                    diemHocKyII: "",
                    diemThiLai: "",
                },
                {
                    maMonHoc: "MYTHUAT",
                    monHoc: "Mỹ thuật",
                    diemCaNam: "",
                    diemHocKyI: "",
                    diemHocKyII: "",
                    diemThiLai: "",
                },
            ],

            rulesHocBa: {
                khoiHoc: [{
                    required: true,
                    message: 'Chưa chọn khối học',
                    trigger: 'change'
                }],
                maHS: [{
                    required: true,
                    message: 'Chưa nhập mã học sinh',
                    trigger: 'change'
                }],
                maLop: [{
                    required: true,
                    message: 'Chưa chọn lớp học',
                    trigger: 'change'
                }],
                hoten: [{
                    required: true,
                    message: 'Chưa thêm tên học sinh',
                    trigger: 'change'
                }],
                namHoc: [{
                    required: true,
                    message: 'Chưa chọn năm học',
                    trigger: 'change'
                }]
            },
            optionEducation: [
                {
                    value: 1,
                    label: 'Cấp 1'
                },
                {
                    value: 2,
                    label: 'Cấp 2'
                },
                {
                    value: 3,
                    label: 'Cấp 3'
                },
            ],
            optionGrade: [],
            khoiCap1: [{
                value: 1,
                label: 'Khối 1'
            },
                {
                    value: 2,
                    label: 'Khối 2'
                },
                {
                    value: 3,
                    label: 'Khối 3'
                },
                {
                    value: 4,
                    label: 'Khối 4'
                },
                {
                    value: 5,
                    label: 'Khối 5'
                },
            ],
            khoiCap2: [{
                value: 6,
                label: 'Khối 6'
            },
                {
                    value: 7,
                    label: 'Khối 7'
                },
                {
                    value: 8,
                    label: 'Khối 8'
                },
                {
                    value: 9,
                    label: 'Khối 9'
                },
            ],
            khoiCap3: [
                {
                value: 10,
                label: 'Khối 10'
                },
                {
                    value: 11,
                    label: 'Khối 11'
                },
                {
                    value: 12,
                    label: 'Khối 12'
                },

            ],
            customImageMaxSize: 10,
            fileUpload : '',
        }
    },
    methods: {
        ...mapActions('teacher', [
            "getListLop",
            "clear",
            "uploadFile",
            "removeUploadFile"
        ]),
        ...mapActions('hocba', [
            "createHocBaTieuHoc",
            "createHocBaTrungHoc",
        ]),


        closeClick(){
            this.$emit('close');
        },

        onSizeExceeded(size) {
            const h = this.$createElement;
            this.$notify.error({
                title: 'Upload thumbnail',
                message: `Hình ảnh vượt quá giới hạn ${this.customImageMaxSize}Mb!`
            });
        },

        onLoad(dataUri) {
            this.uploadFile({
                data : dataUri,
                id : 1,
            });
        },

        getKhoiHoc() {
            if (this.user.capHoc == 1) {
                this.optionGrade = this.khoiCap1;
            } else if (this.user.capHoc == 2) {
                this.optionGrade = this.khoiCap2;
            } else if (this.user.capHoc == 3) {
                this.optionGrade = this.khoiCap3;
            } else {
                this.optionGrade = this.khoiCap1.concat(this.khoiCap2, this.khoiCap3)
            }
        },
        khoiHocChange() {
            this.formHocBa.lop = '';
            this.getlistLopHoc();
        },
        getlistLopHoc() {
            this.getListLop({
                start: 0,
                limit: 999,
                khoiHoc: this.formHocBa.khoiHoc,
                // namHoc : this.searchForm.year,
                // lopHoc : this.searchForm.class
            })
        },
        clearDiem(){
            this.formHocBa.diemTBCaNam = '';
            this.formHocBa.diemTBHKI = '';
            this.formHocBa.diemTBHKII = '';
            this.formHocBa.diemThiLai = '';
            this.formHocBa.hocLucCN = '';
            this.formHocBa.hocLucHKI = '';
            this.formHocBa.hocLucHKII = '';
            this.formHocBa.hocLucThiLai = '';
            this.formHocBa.hanhKiemHKI = '';
            this.formHocBa.hanhKiemHKII = '';
            this.formHocBa.hanhKiemThiLai = '';
            this.formHocBa.hanhKiemCaNam = '';

            this.monHoc.map((item) => {
                item.diemCaNam = "";
                item.diemHocKyI = "";
                item.diemHocKyII = "";
                item.diemThiLai = "";
                return item
            })
        },
        taoHocBa(formName) {

            this.fullscreenLoading = true;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let param = this.formHocBa;
                    let namHoc = this.formHocBa.namHoc ;
                    param.namHoc = namHoc;
                    param.ketQuaHocTaps = Object.values(this.monHoc);
                    param.pathImage = this.urlFile;

                    if (this.formHocBa.capHoc == 1) {
                        this.createHocBaTieuHoc(param);
                    } else {
                        this.createHocBaTrungHoc(param);
                    }
                } else {
                    this.fullscreenLoading = false;
                    this.messageAlert = 'Vui lòng kiểm tra lại các trường thông tin.'
                    this.showAlert = true
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        getValueLop(key){
            let item = this.listLopThieu[0];
            this.formHocBa.maLop = item.maLop;
            this.getNamHoc();
        },
        getNamHoc(){
            let item = this.detailHs.lops;
            let namHoc = '';

            item.map((o)=> {
                if (o.maLop == this.formHocBa.maLop){
                   namHoc = o.namHoc;
                }
                return o;
            });
            this.formHocBa.namHoc = namHoc.toString();
        },
        getValue(key){
            let item = this.detailHs;
            if (item == null){
                return '';
            }
            return item[key];
        },
        checkDisable(){
            let item = this.detailHs;
            if (item == null){
                return false;
            }else{
                return true
            }
        },
        lopChange(){
            this.getNamHoc();
        },
        removeLop(){
            let listLop = this.listLopThieu;
            _.remove(listLop,(item) => {
                return item.maLop == this.formHocBa.maLop;
            })
            if (listLop.length){
                this.listLopThieu = listLop;
                this.getValueLop('maLop');
                $('.add-hocba').animate({ scrollTop: 0 });
            }else{
                this.$refs['formHocBa'].resetFields();
                this.clearDiem();
                this.$emit('close');
            }

        }
    },
    mounted() {
        this.$refs['formHocBa'].resetFields();
        this.clearDiem();
        this.listLopThieu = this.detailHs.lops;
        this.getValueLop('maLop');

    },
    watch: {
        createStatus(val) {
            if (val) {
                this.fullscreenLoading = false;
                this.showAlert = true;
                this.messageAlert = val.message;
                if (val.status=='success'){
                    this.removeLop();
                }
                this.removeUploadFile()
                this.$refs['formHocBa'].resetFields();
                this.clearDiem();
            }
        },
    }
}
</script>

<style scoped>

</style>
