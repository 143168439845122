<template>
    <div>
        <el-row :gutter="24" v-loading="loadChiTiet" element-loading-text="Loading..."
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(255, 255, 255, 0.8)">
            <el-col :sm="12" :xs="24" :md="12" :lg="12" v-for="(item,i) in du_lieu_bieu_do" :key="i">
                <div class="grid-content">
                    <div class="dts-moibieudo grid-content bg-purple-light">
                        <div class="dts-card-header">
                            <span class="dts-card-title">{{ item.tenLop }}</span>
                        </div>
                        <div class="dts-card-body">
                            <div>
                                <BieuDoTrangThaiTheoMon :type="2" :dulieu="item"></BieuDoTrangThaiTheoMon>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>

        </el-row>
    </div>
</template>
<script>
import BieuDoTrangThaiTheoMon from "./BieuDoTrangThaiTheoMon";

export default {
    props: ['du_lieu_bieu_do', 'loadChiTiet'],
    data() {
        return {}
    },
    mounted() {

    },
    components: {
        BieuDoTrangThaiTheoMon: BieuDoTrangThaiTheoMon
    },
    computed: {},
    watch: {
        // du_lieu_bieu_do: function (newVal, olVal) {
        //     this.du_lieu_bieu_do = newVal;
        // }
    },
    methods: {},
}
</script>
<style scoped="scoped">
.dts-moibieudo {
    display: contents;
    border: 1px solid blue;
}

.dts-card-header {
    background: #f2f3f8;
    height: 25px;
    color: black;
    padding: 5px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.dts-card-title {
    color: #070707;
    padding-left: 10px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
}

.dts-card-body {
    padding: 5px;
    background: white;
    height: 300px;
    position: relative;
    border-bottom-right-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    border: #f2f3f8 2px solid;
}

.dts-card-body-ct {
    padding: 5px;
    background: white;
    height: 350px;
    position: relative;
    border-bottom-right-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    border: #f2f3f8 2px solid;
}

.dts-right {
    float: right;
    padding: 0 5px;
}

.dts-right a {
    color: rgb(83, 60, 212);
}

p {
    padding: 0;
    margin: 0
}
</style>