<template>
    <div id="layout-none">
        <slot />
    </div>
</template>

<script>
    export default {
        name : "LayoutNone",

    }
</script>

<style scoped>

</style>
