<template>
  <el-select
      style="width: 120px"
      v-model="Content"
      placeholder="Khối học"
      @change="onChange"
      @input="onInput"
      @visible-change="onVisible"
      @focus="onFocus"
      @blur="onBlur">
    <el-option v-for="(item, index) in lstKhoiHoc" :key="index" :label="item.label" :value="item.value">
      {{item.label}}
    </el-option>
  </el-select>
</template>

<script>


import {
  mapActions,
  mapState
} from 'vuex';

export default {
  name: "SelectKhoiHoc",

  props: {
    value: {
      required: true
    },
  },

  components: {},

  computed: {
    ...mapState('account', [
      'user'
    ]),
  },

  data() {
    return {
      lstKhoiHoc: [],
      Content: this.value,
      khoiCap1: [{
        value: 1,
        label: 'Khối 1'
      },
        {
          value: 2,
          label: 'Khối 2'
        },
        {
          value: 3,
          label: 'Khối 3'
        },
        {
          value: 4,
          label: 'Khối 4'
        },
        {
          value: 5,
          label: 'Khối 5'
        },
      ],
      khoiCap2: [{
        value: 6,
        label: 'Khối 6'
      },
        {
          value: 7,
          label: 'Khối 7'
        },
        {
          value: 8,
          label: 'Khối 8'
        },
        {
          value: 9,
          label: 'Khối 9'
        },
      ],
      khoiCap3: [{
        value: 10,
        label: 'Khối 10'
      },
        {
          value: 11,
          label: 'Khối 11'
        },
        {
          value: 12,
          label: 'Khối 12'
        },

      ],
    }
  },
  mounted() {
    console.log("Mount chọn khối học");
    let list_cap_hoc = this.user.capHoc;
    list_cap_hoc = list_cap_hoc.toString();
    list_cap_hoc = list_cap_hoc.split("");
    let danh_sach_khoi = [];
    for(let i = 0;i<list_cap_hoc.length;i++){
      if(list_cap_hoc[i]==1){danh_sach_khoi = danh_sach_khoi.concat(this.khoiCap1)}
      if(list_cap_hoc[i]==2){danh_sach_khoi = danh_sach_khoi.concat(this.khoiCap2)}
      if(list_cap_hoc[i]==3){danh_sach_khoi = danh_sach_khoi.concat(this.khoiCap3)}
    }
    this.lstKhoiHoc = danh_sach_khoi;
    console.log("hihi")
    console.log(this.lstKhoiHoc)
  },
  beforeMount() {
    console.log("Thông tin user");
    console.log(this.user);

  },

  watch: {
    value() {
      this.Content = this.value;
    },
  },

  methods: {
    focus() {
      this.$refs.el_select.focus();
    },
    onChange(e) {

      this.$emit('change', e);
    },
    onInput(e) {
      this.$emit('input', e);
    },

    onVisible(e) {
      this.$emit('visible', e);
    },

    onFocus(e) {
      this.$emit('focus', e);
    },

    onBlur(e) {
      this.$emit('blur', e);
    },

  }
}
</script>

<style scoped>

</style>