<template>
  <el-menu :default-active="activeIndex" :default-openeds="list_menu_open" :collapse="!open" class="sidebar-menu">
    <template v-for="(menu,index) in this.list_menu">
      <el-submenu v-if="menu.children.length" :key="menu.id" :index="menu.id">
        <template slot="title">
          <template v-if="!menu.menu">
            <router-link :to="{path: menu.menuUrl}" class="sub-menu">
              <div class="menu-parent">
                <i v-if="menu.menuIcon" :class="menu.menuIcon"></i>
                <i v-else class="el-icon-menu"></i>
                <span>{{ menu.menuName }}</span>
              </div>

            </router-link>
          </template>
          <template v-else>
            <div class="menu-parent">
              <i v-if="menu.menuIcon" :class="menu.menuIcon"></i>
              <i v-else class="el-icon-menu"></i>
              <span>{{ menu.menuName }}</span>
            </div>

          </template>

        </template>
        <el-menu-item v-for="subMenu in menu.children" :key="subMenu.id" :index="subMenu.id"
                      @click="openPage(subMenu.menuUrl,subMenu.id)">
          <span>{{ subMenu.menuName }}</span>
        </el-menu-item>
      </el-submenu>
      <el-menu-item v-else :index="menu.id" @click="openPage(menu.menuUrl,menu.id)">
        <template v-if="!menu.menu">
          <div class="menu-parent">
            <i v-if="menu.menuIcon" :class="menu.menuIcon"></i>
            <i v-else class="el-icon-menu"></i>
            <span>{{ menu.menuName }}</span>
          </div>
          <span v-show="!open" slot="title">{{ menu.menuName }}</span>
        </template>
        <template v-else>
          <div class="menu-parent">
            <i v-if="menu.menuIcon" :class="menu.menuIcon"></i>
            <i v-else class="el-icon-menu"></i>
            <span>{{ menu.menuName }}</span>
          </div>
          <span v-show="!open" slot="title">{{ menu.menuName }}</span>
        </template>
      </el-menu-item>
    </template>


  </el-menu>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "Desktop",
  props: ['open'],
  data() {
    return {
      collapse: false,
      list_menu: [],
      list_menu_open: [],
      activeIndex: '',
    }
  },
  computed: {
    ...mapState('until', [
      "menuOpen",
      "currentPath"
    ])
  },
  beforeMount() {
    console.log('beforeMount Desktop')
    this.thongtin = JSON.parse(localStorage.getItem("user"));
    this.list_menu = this.thongtin ? this.thongtin.danhSachMenu : [];
    this.list_menu_open = [];
    let list_menu = this.thongtin ? this.thongtin.danhSachMenu : [];
    console.log(this.thongtin)
    console.log(this.list_menu)
    if (list_menu.length > 0) {
      list_menu.map(menu => {
        console.log(JSON.stringify(menu))
        if (menu.opened) {
          this.list_menu_open.push(menu.id)
        }
        if (this.replaceAll(menu.menuUrl, /["/"]/g, "") === this.replaceAll(this.$route.path, /["/"]/g, "")) {
          this.activeIndex = menu.id
        }
        if (menu.children) {
          menu.children.map(item => {
            if (this.replaceAll(item.menuUrl, /["/"]/g, "") === this.replaceAll(this.$route.path, /["/"]/g, "")) {
              this.activeIndex = item.id
            }
            return item;
          })
        }
        return menu;
      })
    }

  },
  methods: {
    ...mapActions('account', [
      "logout",
      "checkToken"
    ]),
    ...mapActions('until', [
      "menuTogether",
      "setCurentPath"
    ]),
    openPage(url, id) {
      if (url) {

        if (this.replaceAll(this.$route.path, /["/"]/g, "") !== this.replaceAll(url, /["/"]/g, "")) {
          this.setCurentPath(id)
          this.$emit('loadPage');
          this.$router.push(url)
        }

      }
    },

    replaceAll(sentence, regx, replaceBy) {
      return sentence.replace(regx, replaceBy);
    }

  },
  watch: {
    currentPath(val) {
      if (val) {

        this.activeIndex = val;
      } else {

        this.activeIndex = '';
      }
    }
  }
}
</script>

<style scoped>

</style>