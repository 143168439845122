import util from "../../util";

export default function guest ({ next, store }){
    if(store.getters.auth){
        let user = localStorage.getItem('user');
        let token = util.getCookie('token');
        if (token && user){
            if((JSON.parse(user)).role == 5 || JSON.parse(user).role == 6 || JSON.parse(user).role == 7){
                console.log('role 5 6 7')
                return next({
                    name: 'DashBoard'
                })
            }else if((JSON.parse(user)).role == 4){
                console.log('role 4')
                return next({
                    name: 'DashBoard'
                })
            }
            else if((JSON.parse(user)).role == 8){
                console.log('role 8')
                return next({
                    name: 'CauHinhNguoiDung'
                })
            }
            else{
                return next({
                    name: 'DanhSachHocSinh'
                });
            }
        }
    }
    return next()
}
