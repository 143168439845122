var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "hocbaDetail" }, [
    _vm.trangThaiDiemTongKetMon != 0
      ? _c("div", [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.trangThaiDiemTongKetMon == 2,
                  expression: "trangThaiDiemTongKetMon==2",
                },
              ],
              attrs: {
                "element-loading-text": "Loading...",
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "rgba(255, 255, 255, 0.8)",
              },
            },
            [
              _c("div", { staticClass: "page__content dts-custom-table" }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          "max-height": _vm.maxHeight,
                          data: _vm.kqhtHocSinhs,
                          boder: "",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            fixed: "",
                            align: "center",
                            type: "index",
                            label: "STT",
                            width: "50",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "left",
                            "header-align": "center",
                            fixed: "",
                            prop: "hoTen",
                            label: "Họ tên",
                            width: "180",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            "header-align": "center",
                            fixed: "",
                            prop: "tenLop",
                            label: "Tên lớp",
                            width: "70",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            "header-align": "center",
                            fixed: "",
                            prop: "namHoc",
                            label: "Năm học",
                            width: "110",
                          },
                        }),
                        _vm._v(" "),
                        _vm._l(_vm.tenMonHocs, function (item, index) {
                          return [
                            _c(
                              "el-table-column",
                              { attrs: { align: "center", label: item } },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "H Kỳ I",
                                    align: "center",
                                    width: "55",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("div", [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    scope.row.ketQuaHocTaps[
                                                      index
                                                    ].diemHocKyI
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "H Kỳ II",
                                    align: "center",
                                    width: "55",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("div", [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    scope.row.ketQuaHocTaps[
                                                      index
                                                    ].diemHocKyII
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "CN",
                                    align: "center",
                                    width: "50",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("div", [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    scope.row.ketQuaHocTaps[
                                                      index
                                                    ].diemCaNam
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    align: "center",
                                    label:
                                      "Điểm hoặc xếp lại sau KT lại (nếu có)",
                                    width: "80",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("div", [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    scope.row.ketQuaHocTaps[
                                                      index
                                                    ].diemThiLai
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    align: "center",
                                    label: "Giáo viên",
                                    width: "170",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "text-align": "left",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      scope.row.ketQuaHocTaps[
                                                        index
                                                      ].tenGiaoVien
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    align: "center",
                                    label: "Trạng thái",
                                    width: "90",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("div", [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.getTrangThaiXacNhan(
                                                      scope.row.ketQuaHocTaps[
                                                        index
                                                      ].trangThaiXacNhan
                                                    )
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ])
      : _c("div", [_vm._m(0)]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center" }, [
      _c("p", [_vm._v("\n        Không có dữ liệu")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }