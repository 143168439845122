var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading.fullScreen,
          expression: "loading.fullScreen",
        },
      ],
      attrs: {
        "element-loading-text": "Loading...",
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(255, 255, 255, 0.8)",
      },
    },
    [
      _c(
        "el-row",
        { staticStyle: { "padding-top": "70px" }, attrs: { gutter: 24 } },
        [
          _c("el-col", { attrs: { sm: 12, xs: 24 } }, [
            _c(
              "div",
              { staticClass: "grid-content bg-purple" },
              [
                _c("v-jstree", {
                  attrs: { data: _vm.listReport },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (_) {
                        return [
                          _c("div", { staticStyle: { display: "inherit" } }, [
                            _.model.menu
                              ? _c("i", { staticClass: "el-icon-folder" })
                              : _c("i", { staticClass: "el-icon-menu" }),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "padding-right": "30px" } },
                              [_vm._v(_vm._s(_.model.menuName))]
                            ),
                            _vm._v(" "),
                            _.model.menu
                              ? _c(
                                  "a",
                                  {
                                    staticStyle: { margin: "5px" },
                                    attrs: { href: "#", title: "Thêm nhóm" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.themNhomMenuCon(_.model)
                                      },
                                    },
                                  },
                                  [
                                    _c("b", [
                                      _c("i", { staticClass: "el-icon-plus" }),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _.model.menu
                              ? _c(
                                  "a",
                                  {
                                    staticStyle: { margin: "5px" },
                                    attrs: { href: "#", title: "Thêm" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.themMenuCon(_.model)
                                      },
                                    },
                                  },
                                  [
                                    _c("b", [
                                      _c("i", { staticClass: "el-icon-plus" }),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticStyle: { margin: "5px" },
                                attrs: { href: "#", title: "Sửa" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.chinhSuaMenuCon(_.model)
                                  },
                                },
                              },
                              [
                                _c("b", [
                                  _c("i", { staticClass: "el-icon-edit" }),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticStyle: { margin: "5px" },
                                attrs: { href: "#", title: "Xóa" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.xoaMenuCon(_.model)
                                  },
                                },
                              },
                              [
                                _c("b", [
                                  _c("i", { staticClass: "el-icon-delete" }),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 12, xs: 24 } }, [
            _c(
              "div",
              { staticClass: "grid-content bg-purple" },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { float: "right", "margin-bottom": "5px" },
                    attrs: { size: "mini", type: "primary" },
                    on: { click: _vm.themMoiVaiTro },
                  },
                  [_vm._v("\n          Thêm mới\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { border: "", data: _vm.listVaiTro, stripe: "" },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { align: "center", type: "index", width: "50" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        align: "left",
                        "header-align": "center",
                        prop: "roleName",
                        label: "Tên",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "role",
                        align: "center",
                        label: "Mã",
                        width: "50",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { align: "center", label: "Thao tác" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "warning" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.phanQuyenVaiTro(scope.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "Phân\n                quyền\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "danger" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.XoaVaiTro(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("Xóa")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            [
              _c(
                "el-dialog",
                {
                  attrs: {
                    "close-on-press-escape": false,
                    "close-on-click-modal": false,
                    title: "Thêm mới vai trò",
                    center: "",
                    visible: _vm.hienThi.themMoiVaiTro,
                  },
                  on: {
                    "update:visible": function ($event) {
                      return _vm.$set(_vm.hienThi, "themMoiVaiTro", $event)
                    },
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 12 } },
                        [
                          _c("label", [_vm._v("Tên vai trò")]),
                          _vm._v(" "),
                          _c("el-input", {
                            attrs: { size: "mini", placeholder: "Nhập" },
                            model: {
                              value: _vm.vaiTro.roleName,
                              callback: function ($$v) {
                                _vm.$set(_vm.vaiTro, "roleName", $$v)
                              },
                              expression: "vaiTro.roleName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 12 } },
                        [
                          _c("label", [_vm._v("Mã")]),
                          _vm._v(" "),
                          _c("el-input", {
                            attrs: { size: "mini", placeholder: "Nhập" },
                            model: {
                              value: _vm.vaiTro.role,
                              callback: function ($$v) {
                                _vm.$set(_vm.vaiTro, "role", $$v)
                              },
                              expression: "vaiTro.role",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              _vm.hienThi.themMoiVaiTro = false
                            },
                          },
                        },
                        [_vm._v("Đóng")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.confirmThemVaiTro($event)
                            },
                          },
                        },
                        [_vm._v("Thêm")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: {
                    "close-on-press-escape": false,
                    "close-on-click-modal": false,
                    title: "Thêm thông tin",
                    center: "",
                    visible: _vm.hienThi.themNhomMenuCon,
                  },
                  on: {
                    "update:visible": function ($event) {
                      return _vm.$set(_vm.hienThi, "themNhomMenuCon", $event)
                    },
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 12 } },
                        [
                          _c("label", [_vm._v("Tên menu")]),
                          _vm._v(" "),
                          _c("el-input", {
                            attrs: { size: "mini", placeholder: "Nhập" },
                            model: {
                              value: _vm.thongTinThemNhom.menuName,
                              callback: function ($$v) {
                                _vm.$set(_vm.thongTinThemNhom, "menuName", $$v)
                              },
                              expression: "thongTinThemNhom.menuName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 12 } },
                        [
                          _c("label", [_vm._v("Đường dẫn")]),
                          _vm._v(" "),
                          _c("el-input", {
                            attrs: { size: "mini", placeholder: "Nhập" },
                            model: {
                              value: _vm.thongTinThemNhom.menuUrl,
                              callback: function ($$v) {
                                _vm.$set(_vm.thongTinThemNhom, "menuUrl", $$v)
                              },
                              expression: "thongTinThemNhom.menuUrl",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 12 } },
                        [
                          _c("label", [_vm._v("Vị trí sắp xếp")]),
                          _vm._v(" "),
                          _c("el-input", {
                            attrs: { size: "mini", placeholder: "Nhập" },
                            model: {
                              value: _vm.thongTinThemNhom.sort,
                              callback: function ($$v) {
                                _vm.$set(_vm.thongTinThemNhom, "sort", $$v)
                              },
                              expression: "thongTinThemNhom.sort",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 12 } },
                        [
                          _c("label", [_vm._v("Icon")]),
                          _vm._v(" "),
                          _c("el-input", {
                            attrs: { size: "mini", placeholder: "Nhập" },
                            model: {
                              value: _vm.thongTinThemNhom.menuIcon,
                              callback: function ($$v) {
                                _vm.$set(_vm.thongTinThemNhom, "menuIcon", $$v)
                              },
                              expression: "thongTinThemNhom.menuIcon",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              _vm.hienThi.themNhomMenuCon = false
                            },
                          },
                        },
                        [_vm._v("Đóng")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.confirmThemNhomMenuCon($event)
                            },
                          },
                        },
                        [_vm._v("Thêm")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: {
                    "close-on-press-escape": false,
                    "close-on-click-modal": false,
                    title: "Thêm thông tin",
                    center: "",
                    visible: _vm.hienThi.themMenuCon,
                  },
                  on: {
                    "update:visible": function ($event) {
                      return _vm.$set(_vm.hienThi, "themMenuCon", $event)
                    },
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 12 } },
                        [
                          _c("label", [_vm._v("Tên menu")]),
                          _vm._v(" "),
                          _c("el-input", {
                            attrs: { size: "mini", placeholder: "Nhập" },
                            model: {
                              value: _vm.thongTinThem.menuName,
                              callback: function ($$v) {
                                _vm.$set(_vm.thongTinThem, "menuName", $$v)
                              },
                              expression: "thongTinThem.menuName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 12 } },
                        [
                          _c("label", [_vm._v("Đường dẫn")]),
                          _vm._v(" "),
                          _c("el-input", {
                            attrs: { size: "mini", placeholder: "Nhập" },
                            model: {
                              value: _vm.thongTinThem.menuUrl,
                              callback: function ($$v) {
                                _vm.$set(_vm.thongTinThem, "menuUrl", $$v)
                              },
                              expression: "thongTinThem.menuUrl",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 12 } },
                        [
                          _c("label", [_vm._v("Vị trí sắp xếp")]),
                          _vm._v(" "),
                          _c("el-input", {
                            attrs: { size: "mini", placeholder: "Nhập" },
                            model: {
                              value: _vm.thongTinThem.sort,
                              callback: function ($$v) {
                                _vm.$set(_vm.thongTinThem, "sort", $$v)
                              },
                              expression: "thongTinThem.sort",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 12 } },
                        [
                          _c("label", [_vm._v("Icon")]),
                          _vm._v(" "),
                          _c("el-input", {
                            attrs: { size: "mini", placeholder: "Nhập" },
                            model: {
                              value: _vm.thongTinThem.menuIcon,
                              callback: function ($$v) {
                                _vm.$set(_vm.thongTinThem, "menuIcon", $$v)
                              },
                              expression: "thongTinThem.menuIcon",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              _vm.hienThi.themMenuCon = false
                            },
                          },
                        },
                        [_vm._v("Đóng")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.confirmThemMenuCon($event)
                            },
                          },
                        },
                        [_vm._v("Thêm")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: {
                    "close-on-press-escape": false,
                    "close-on-click-modal": false,
                    title: "Chỉnh sửa thông tin",
                    center: "",
                    visible: _vm.hienThi.chinhSuaMenuCon,
                  },
                  on: {
                    "update:visible": function ($event) {
                      return _vm.$set(_vm.hienThi, "chinhSuaMenuCon", $event)
                    },
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 12 } },
                        [
                          _c("label", [_vm._v("Tên menu")]),
                          _vm._v(" "),
                          _c("el-input", {
                            attrs: { size: "mini", placeholder: "Nhập" },
                            model: {
                              value: _vm.thongTinChinhSua.menuName,
                              callback: function ($$v) {
                                _vm.$set(_vm.thongTinChinhSua, "menuName", $$v)
                              },
                              expression: "thongTinChinhSua.menuName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 12 } },
                        [
                          _c("label", [_vm._v("Đường dẫn")]),
                          _vm._v(" "),
                          _c("el-input", {
                            attrs: { size: "mini", placeholder: "Nhập" },
                            model: {
                              value: _vm.thongTinChinhSua.menuUrl,
                              callback: function ($$v) {
                                _vm.$set(_vm.thongTinChinhSua, "menuUrl", $$v)
                              },
                              expression: "thongTinChinhSua.menuUrl",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 12 } },
                        [
                          _c("label", [_vm._v("Vị trí sắp xếp")]),
                          _vm._v(" "),
                          _c("el-input", {
                            attrs: { size: "mini", placeholder: "Nhập" },
                            model: {
                              value: _vm.thongTinChinhSua.sort,
                              callback: function ($$v) {
                                _vm.$set(_vm.thongTinChinhSua, "sort", $$v)
                              },
                              expression: "thongTinChinhSua.sort",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 12 } },
                        [
                          _c("label", [_vm._v("Icon")]),
                          _vm._v(" "),
                          _c("el-input", {
                            attrs: { size: "mini", placeholder: "Nhập" },
                            model: {
                              value: _vm.thongTinChinhSua.menuIcon,
                              callback: function ($$v) {
                                _vm.$set(_vm.thongTinChinhSua, "menuIcon", $$v)
                              },
                              expression: "thongTinChinhSua.menuIcon",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              _vm.hienThi.themMenuCon = false
                            },
                          },
                        },
                        [_vm._v("Đóng")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.confirmChinhSuaMenuCon($event)
                            },
                          },
                        },
                        [_vm._v("Thêm")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: {
                    "close-on-press-escape": false,
                    "close-on-click-modal": false,
                    title: "Phân quyền vai trò",
                    width: "70%",
                    center: "",
                    visible: _vm.hienThi.phanQuyenVaiTro,
                  },
                  on: {
                    "update:visible": function ($event) {
                      return _vm.$set(_vm.hienThi, "phanQuyenVaiTro", $event)
                    },
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 18 } },
                        [
                          _c("eselect", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              collapseTags: "",
                              placeholder: "Chọn Sở Giáo dục và Đào tạo",
                              filterable: "",
                              data: _vm.list_so,
                              fields: ["name", "value"],
                            },
                            model: {
                              value: _vm.maDonVi,
                              callback: function ($$v) {
                                _vm.maDonVi = $$v
                              },
                              expression: "maDonVi",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.findMenuRoleByMaSo()
                                },
                              },
                            },
                            [_vm._v("Tìm kiếm")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _vm.listVaiTroPhanQuyen
                          ? _c(
                              "div",
                              { staticClass: "bd" },
                              [
                                _c("v-jstree", {
                                  attrs: {
                                    data: _vm.listVaiTroPhanQuyen,
                                    "show-checkbox": "show-checkbox",
                                    multiple: "multiple",
                                    "allow-batch": "allow-batch",
                                    "whole-row": "whole-row",
                                  },
                                  on: { "item-click": _vm.itemClick2 },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (_) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "inherit",
                                                },
                                              },
                                              [
                                                _.model.menu
                                                  ? _c("i", {
                                                      staticClass:
                                                        "el-icon-folder",
                                                    })
                                                  : _c("i", {
                                                      staticClass:
                                                        "el-icon-menu",
                                                    }),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "padding-right": "30px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_.model.menuName)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1315561561
                                  ),
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              _vm.hienThi.themMenuCon = false
                            },
                          },
                        },
                        [_vm._v("Đóng")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.confirmPhanQuyenVaiTro($event)
                            },
                          },
                        },
                        [_vm._v("Phân quyền")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }