import api from "../api";
import {sprintf} from "sprintf-js";
import restApi from "../_helpers/api";

export const userService = {
    login,
    logout,
    getOtp,
    confirmOtp,
    checkTokenHS,
    checkTokenGV,
    getCapCha,
    getProfile,
};

function login(email,passWord,capCha) {
    let uri = api.login.login;
    let params = {
        email: email,
        password: passWord,
        captcha:capCha
    };
    return restApi.post(uri,params);
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    // return next({ path: '/' });
}

// Get profile
function getProfile() {
    let uri = api.getProfile;
    let params = {};
    return restApi.get(uri,params);
}

function getOtp(maHocSinh){
    let uri = api.login.loginOtp;
    let params = {
        id: maHocSinh
    };
    return restApi.post(uri,params);
}

function confirmOtp(maHocSinh,otpCode,capCha){
    let uri = api.login.confirmOtp;
    let params = {
        id: maHocSinh,
        captcha:capCha,
        code : otpCode
    };
    return restApi.post(uri,params);
}

function checkTokenHS(){
    let uri = api.login.checkTokenHS;
    let params = {};
    return restApi.post(uri,params);
}

function checkTokenGV(){
    let uri = api.login.checkTokenGV;
    let params = {};
    return restApi.post(uri,params);
}
function getCapCha(e){
    let uri = api.login.getCapcha;
    let params = {
        code : e
    };
    return restApi.get(uri,params);
}
