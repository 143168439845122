<template>
  <div class="page">
    <div class="page__title d-flex align-items-center justify-content-between">
      <h1 class="title">Nhập nhận xét-khen thưởng</h1>
      <el-tooltip class="item" effect="dark" content="Hướng dẫn nhập dữ liệu" placement="top">
        <a class="link" href="/files/huong_dan_nhap_du_lieu.docx"> Hướng dẫn nhập dữ liệu</a>
      </el-tooltip>
    </div>
    <div class="page__header">
      <el-row :gutter="20">
        <el-col :xs="12" :sm="6" :md="4" :lg="4">
            <label>Năm học</label>
          <eselect style="width:100%" collapseTags v-model="namHoc" placeholder="Chọn" filterable
                   :data="list_nam_hoc" :fields="['name','value']" />
        </el-col>
        <el-col :xs="12" :sm="6" :md="4" :lg="4">
            <label>Cấp học</label>
          <eselect style="width:100%" collapseTags v-model="capHoc" placeholder="Chọn" filterable
                   :data="list_cap_hoc" :fields="['name','value']" />
        </el-col>
      </el-row>
    </div>
    <div class="page__header" >
      <h5 class="title">Khen thưởng</h5>
      <small><i> (Truy cập Học bạ số -> Nhập nhận xét - khen thưởng -> Tải file khen thưởng)</i></small>
<!--      <p><i style="font-size:12px">(Truy cập Học bạ số -> Nhập dữ liệu -> Nhập nhận xét khen thưởng -> Khen-->
<!--        thưởng -> Tải dữ liệu học sinh)</i></p>-->
<!--        <el-row :gutter="20">-->
<!--        <el-col :xs="12" :sm="6" :md="4" :lg="4">-->
<!--            <label>Phương thức nhập </label>-->
<!--          <eselect class="mb-2" style="width:111.5%" :disabled="typeKhenThuong>=2" collapseTags v-model="typeKhenThuong" filterable-->
<!--                   :data="listPhuongThucNhap" :fields="['name','value']" />-->
<!--        </el-col>-->
<!--      </el-row>-->
      <ImportGeneral :require-cap-hoc="true" :require-nam-hoc="true" :requireImportType="true" :year="namHoc" :cap-hoc="capHoc"
                     :fileNameError="'danh_sach_loi_nhap_khen_thuong'"
                     :data-type="capHoc==1?10:14"
                     :text-download="'Tải file nhập Khen thưởng'"
                     :requireKhoiHoc="true"
                     :hideClass="false"
                     :uri-dowload="dowloadKhenThuong" :source="source" :path="typeKhenThuong === 1? pathKhenThuong : pathKhenThuongChung"
                     :importType="typeKhenThuong" name-file="khen-thuong">
      </ImportGeneral>
    </div>

    <div v-if="capHoc!=''" class="page__header">
      <h5 class="title">Nhận xét của giáo viên chủ nhiệm</h5>
      <small><i> (Truy cập Học bạ số -> Nhập nhận xét - khen thưởng -> Tải file Nhận xét của Giáo viên chủ nhiệm</i></small>
<!--      <p><i style="font-size:12px">(Truy cập Học bạ số -> Nhập dữ liệu -> Nhận xét giáo viên chủ nhiệm -> Tải dữ liệu học sinh)</i></p>-->
<!--      <el-row :gutter="20">-->
<!--        <el-col :xs="12" :sm="6" :md="4" :lg="4">-->
<!--            <label>Phương thức nhập</label>-->
<!--          <eselect class="mb-2" style="width:111.5%" :disabled="typeNXGvcn>=2" collapseTags v-model="typeNXGvcn" filterable-->
<!--                   :data="listPhuongThucNhap" :fields="['name','value']" />-->
<!--        </el-col>-->
<!--      </el-row>-->
      <ImportGeneral :require-cap-hoc="true" :require-nam-hoc="true" :requireImportType="true" :year="namHoc" :cap-hoc="capHoc"
                     :fileNameError="'danh_sach_loi_nhap_nhan_xet_gvcn'"
                     :requireKhoiHoc="true"
                     :hideClass="false"
                     :text-download="'Tải file nhập Nhận xét của GVCN'"
                     :data-type="15"
                     :uri-dowload="dowloadGVCN" :source="source" :path="typeNXGvcn === 1? pathNhanXetGVCN : pathNhanXetGVCNChung" 
                     :importType="typeNXGvcn" name-file="nx-gvcn"></ImportGeneral>
    </div>
    <div class="page__header"  v-if= "capHoc!=1&&capHoc!='' &&(user.isHieuTruong || user.isCanBo)">
      <h5 class="title">Nhận xét của hiệu trưởng</h5>
      <small><i> (Truy cập Học bạ số -> Nhập nhận xét - khen thưởng -> Tải file Nhận xét của Hiệu trưởng</i></small>
<!--      <p><i style="font-size:12px">(Truy cập Học bạ số -> Nhập dữ liệu -> Nhận xét hiệu trưởng -> Tải dữ liệu học sinh)</i></p>-->
<!--      <el-row :gutter="20">-->
<!--        <el-col :xs="12" :sm="6" :md="4" :lg="4">-->
<!--            <label>Phương thức nhập</label>-->
<!--          <eselect class="mb-2" style="width:111.5%" :disabled="typeNXHieuTruong>=2" collapseTags v-model="typeNXHieuTruong" filterable-->
<!--                   :data="listPhuongThucNhap" :fields="['name','value']" />-->
<!--        </el-col>-->
<!--      </el-row>-->
      <ImportGeneral :require-cap-hoc="true" :require-nam-hoc="true" :requireImportType="false" :year="namHoc" :cap-hoc="capHoc"
                     :fileNameError="'danh_sach_loi_nhap_nhan_xet_hieu_truong'"
                     :data-type="16"
                     :requireKhoiHoc="true"
                     :hideClass="false"
                     :text-download="'Tải file nhập Nhận xét của Hiệu trưởng'"
                     :uri-dowload="dowloadHieuTruong" :source="source" :path="typeNXHieuTruong === 1? pathNhanXetHieuTruong : pathNhanXetHieuTruongChung" 
                     :importType="typeNXHieuTruong"
                     name-file="nx-hieu-truong">
      </ImportGeneral>
    </div>

  </div>

</template>

<script>
// import ImportGeneral from "./ImportForNhanXet";
import ImportGeneral from "./ImportChung.vue";
import constant_api from "../../_helpers/constant_api";
import {
  mapState
} from "vuex";
import ESelect from "../Ui/ESelect";
import ImportForNhanXet from "./ImportChung.vue";

export default {
  name: "ImportNhanXetKhenThuong",
  metaInfo: {
    title: 'Nhập nhận xét khen thưởng',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: ''
    }],
  },
  components: {
    ImportForNhanXet,
    ImportGeneral,
    'eselect': ESelect,
  },
  data() {
    return {
      namHoc: '',
      capHoc: '',
      dowloadHieuTruong: constant_api.download.nhanXetHieuTruong,
      dowloadGVCN: constant_api.download.nhanXetGVCN,
      dowloadKhenThuong: constant_api.download.khenThuong,
      source: [{
        type: 1,
        label: 'VNEDU',
        rowHeader: 1
      }, {
        type: 2,
        label: 'MOET',
        rowHeader: 1
      }],
      pathNhanXetGVCN: constant_api.import.nhanXetGVCN,
      pathNhanXetGVCNChung: constant_api.import.nhanXetGVCNChung,
      pathNhanXetHieuTruong: constant_api.import.nhanXetHieuTruong,
      pathNhanXetHieuTruongChung: constant_api.import.nhanXetHieuTruongChung,
      pathKhenThuong: constant_api.import.nhanXetKhenThuong,
      pathKhenThuongChung: constant_api.import.nhanXetKhenThuongChung,
      typeKhenThuong: '',
      typeNXGvcn: '',
      typeNXHieuTruong: '',
      listPhuongThucNhap : [
                    {
                      'name': 'Mẫu riêng',
                      'value': 1
                    }, 
                    {
                      'name': 'Mẫu Học bạ số',
                      'value': 2
                    }
      ]
    }
  },
  computed: {
    ...mapState('account',
        [
          "user",
          'list_nam_hoc',
          'list_cap_hoc',
          'nam_hoc_hien_tai',
        ]),
  },
  methods: {
    getListCapHoc() {
      if (this.user && this.user.capHoc) {
        let ch = this.user.capHoc.toString();
        let cap_hoc = ch.split('');
        this.capHoc = parseInt(cap_hoc[0]);
      }
    }
  },
  beforeMount() {
    this.getListCapHoc();
    this.namHoc = this.nam_hoc_hien_tai;
    if (this.user) {
      this.type = this.user.qlnt;
    }
    this.typeKhenThuong = 2
    this.typeNXGvcn = 2
    this.typeNXHieuTruong = 2
  },
}
</script>

<style scoped>

</style>