<template>
    <el-select
        clearable
        style="width: 130px"
            v-model="Content"
            placeholder="Năm học"
            :disabled="disabled"
            @change="onChange"
            @input="onInput"
            @visible-change="onVisible"
            @focus="onFocus"
            @blur="onBlur">
        <el-option v-for="(item, index) in list_nam_hoc" :key="index" :label="item.name" :value="item.value">
            {{item.name}}
        </el-option>
    </el-select>
</template>

<script>


import {mapActions, mapState} from 'vuex';
export default {
    name: "SelectNamHoc",

    props : {
        disabled:{},
        value: {
            required: true
        },
    },

    components: {

    },

    data(){

        return{
            lstNamHoc : [],
            Content : this.value
        }
    },
    mounted(){
        console.log("Mount chọn năm học");
        let currentMonth = this.getCurrentMonth();
        console.log("currentMonth:" + currentMonth)
    },
    beforeMount() {

        let namHoc = [];
        let currentYear = this.getFullYear();
        currentYear +=1;
        for (let i = 0;i < 5;i++){
            namHoc.push({
                label : (currentYear-(i+1)) +' - '+ (currentYear-i),
                value: currentYear-(i+1)
            })

        }
        this.lstNamHoc = namHoc;
    },

  computed: {
    ...mapState('account', [
      'list_nam_hoc',
    ])
  },
    watch: {
        value(){
            console.log("Value thay đổi:" + this.value)
            this.Content = this.value;
        },
    },

    methods : {
        getFullYear() {
            let d = new Date();
            return d.getFullYear();
        },
        getCurrentMonth() {
            let d = new Date();
            return d.getMonth();
        },

        focus(){
            this.$refs.el_select.focus();
        },
        onChange(e){

            this.$emit('change', e);
        },
        onInput(e){
            this.$emit('input', e);
        },

        onVisible(e){
            this.$emit('visible', e);
        },

        onFocus(e){
            this.$emit('focus', e);
        },

        onBlur(e){
            this.$emit('blur', e);
        },

    }
}
</script>

<style scoped>

</style>