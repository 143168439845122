<template>
  <div>
    <div ref="pdfViewer">
      <iframe
          style="width: 100%; height: 100vh; min-height: 600px"
          :src="urlPdf"
      ></iframe>
    </div>
  </div>
</template>
<script>
import jsPDF from "jspdf";
import {times} from "./../../../../../fonts/times-normal";
import {timesB} from "./../../../../../fonts/times-bold";
import {timesItalic} from "./../../../../../fonts/times-italic";
import "jspdf-autotable";
export default {
  props: ["item", "fileName", 'cauHinh'],
  data() {
    return {
      imgPassportWidth: 50,
      imgPassportHeight: 50,
      imgStampWidth: 40,
      imgStampHeight: 40,
      imgSignatureWidth: 40,
      imgSignatureHeight: 20,
      thongTinKqht: [],
      dataForm: {
        schoolName: "",
      },
      urlPdf: "",
    };
  },
  mounted() {
    if (!this.cauHinh || this.cauHinh == null) {
      this.cauHinh = {}
    }
    this.generatePDF();
  },
  methods: {
    convertNullsToEmptyStrings(obj) {
      let result = {};
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          result[key] = obj[key] === null ? "" : obj[key];
        }
      }
      return result;
    },
    handleNull(str) {
      return str !== null && str !== undefined && str !== "" ? str : "";
    },
    getTextGender(e) {
      return e == 1 ? "Nam" : e == 0 ? "Nữ" : "";
    },
    removeTabs(str) {
      if (typeof str !== "string") {
        return "";
      }
      return str.replace(/\t/g, "");
    },
    countCharacters(text, textDefault, n) {
      const maxLength = n ? n : 90;
      const combinedText = (textDefault || "") + (text || "");
      if (!combinedText || typeof combinedText !== "string") {
        return "";
      }
      const lineBreaks = Math.floor(combinedText.length / maxLength);
      let newText = combinedText;
      for (let i = 1; i <= lineBreaks; i++) {
        const breakIndex = newText.lastIndexOf(" ", i * maxLength + (i - 1));
        if (breakIndex !== -1) {
          newText = newText.slice(0, breakIndex) + "\n" + newText.slice(breakIndex + 1);
        } else {
          newText =
              newText.slice(0, i * maxLength + (i - 1)) +
              "\n" +
              newText.slice(i * maxLength + (i - 1));
        }
      }
      return newText;
    },
    adjustTextSpacing(textLines, doc) {
      if (
          !Array.isArray(textLines) ||
          textLines.length === 0 ||
          !doc ||
          typeof doc.getTextWidth !== "function"
      ) {
        return textLines;
      }
      const increaseSpace = (text, widthDifference) => {
        const words = text.split(" ");
        const targetSpace = 2;
        let splitIndex;
        const conditionMap = {
          "1-3": Math.floor(words.length / 5),
          "3-5": Math.floor((words.length * 1.2) / 3),
          "5-6": Math.floor(words.length / 2),
          "6-8": Math.floor((words.length * 2) / 3),
          "8-9": Math.floor((words.length * 6) / 7),
          "9-10": Math.floor(words.length),
        };
        const conditionKeys = Object.keys(conditionMap);
        const matchedKey = conditionKeys.find((key) => {
          const [min, max] = key.split("-").map(Number);
          return widthDifference > min && widthDifference < max;
        });
        splitIndex = matchedKey ? conditionMap[matchedKey] : splitIndex;
        console.log(`${text} ${widthDifference}`);
        for (let i = 0; i < splitIndex; i++) {
          words[i] += " ".repeat(targetSpace - 1);
        }
        return words.join(" ");
      };
      const convertLines = textLines.map((line, index) => {
        const lineWidth = doc.getTextWidth(line);
        return {line, lineWidth, index};
      });
      const maxWidth = Math.max(...convertLines.map((line) => line.lineWidth));
      let newLinesArray = [];
      convertLines.forEach(({line, lineWidth}) => {
        if (lineWidth > 0) {
          const widthDifference = maxWidth - lineWidth;
          line = increaseSpace(line, widthDifference);
        }
        newLinesArray.push(line);
      });
      return newLinesArray;
    },
    generatePDF() {
      console.log("generatePDF");
      const doc = new jsPDF("p", "mm", "a4");
      doc.addFileToVFS("Times-normal.ttf", times);
      doc.addFileToVFS("Times-bold.ttf", timesB);
      doc.addFileToVFS("Times-italic.ttf", timesItalic);
      doc.addFont("Times-normal.ttf", "VNTimes", "normal");
      doc.addFont("Times-bold.ttf", "TimesBold", "normal");
      doc.addFont("Times-italic.ttf", "VNTimesItalic", "normal");
      doc.setFontSize(12);
      doc.setFont("VNTimes");
      for (let j = 0; j < this.item.length; j++) {
        let moiHocBa = JSON.parse(JSON.stringify(this.item[j]));
        let dulieuRender = moiHocBa.KQHT27 ? moiHocBa.KQHT27 : moiHocBa.KQHT;
        let bia = moiHocBa.BIA27 ? moiHocBa.BIA27 : moiHocBa.BIA;
        let totalPages = 3;
        console.log('dulieuRender (KQHT):')
        console.log(JSON.stringify(dulieuRender))
        totalPages += 2 * (dulieuRender.length);
        console.log("BIA27", bia);
        for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
          if (pageNumber == 1 && (!this.cauHinh.isBia)) {
            this.addContentToPage(doc, pageNumber, dulieuRender, bia);
            if (pageNumber <= totalPages) {
              doc.addPage();
            }
          }
          if (pageNumber == 2 && (!this.cauHinh.isBia)) {
            this.addContentToPage(doc, pageNumber, dulieuRender, bia);
            if (pageNumber <= totalPages) {
              doc.addPage();
            }
          }
          if (pageNumber == 3 && (!this.cauHinh.isInRiengThongTinHocSinh)) {
            this.addContentToPage(doc, pageNumber, dulieuRender, bia);
            if (pageNumber <= totalPages) {
              doc.addPage();
            }
          }
          if (pageNumber == 4 && (!this.cauHinh.isKQHT)) {
            let dataRender = dulieuRender[0];
            this.addContentToPage(doc, pageNumber, dataRender, bia);
            if (pageNumber <= totalPages) {
              doc.addPage();
            }
          }
          if (pageNumber == 5 && (!this.cauHinh.isKQHT)) {
            let dataRender = dulieuRender[0];
            this.addContentToPage(doc, pageNumber, dataRender, bia);
            this.addContentToPage(doc, pageNumber, dulieuRender, bia);
            if (pageNumber <= totalPages) {
              doc.addPage();
            }
          }
          if (pageNumber == 6 && (!this.cauHinh.isKQHT)) {
            let dataRender = dulieuRender[1];
            this.addContentToPage(doc, pageNumber, dataRender, bia);
            if (pageNumber <= totalPages) {
              doc.addPage();
            }
          }
          if (pageNumber == 7 && (!this.cauHinh.isKQHT)) {
            let dataRender = dulieuRender[1];
            this.addContentToPage(doc, pageNumber, dataRender, bia);
            if (pageNumber <= totalPages) {
              doc.addPage();
            }
          }
          if (pageNumber == 8 && (!this.cauHinh.isKQHT)) {
            let dataRender = dulieuRender[2];
            this.addContentToPage(doc, pageNumber, dataRender, bia);
            if (pageNumber <= totalPages) {
              doc.addPage();
            }
          }
          if (pageNumber == 9 && (!this.cauHinh.isKQHT)) {
            let dataRender = dulieuRender[2];
            this.addContentToPage(doc, pageNumber, dataRender, bia);
            if (pageNumber <= totalPages) {
              doc.addPage();
            }
          }
          if (pageNumber == 10 && (!this.cauHinh.isKQHT)) {
            let dataRender = dulieuRender[3];
            this.addContentToPage(doc, pageNumber, dataRender, bia);
            if (pageNumber <= totalPages) {
              doc.addPage();
            }
          }
          if (pageNumber == 11 && (!this.cauHinh.isKQHT)) {
            let dataRender = dulieuRender[3];
            this.addContentToPage(doc, pageNumber, dataRender, bia);
            if (pageNumber <= totalPages) {
              doc.addPage();
            }
          }
          if (pageNumber == 12 && (!this.cauHinh.isKQHT)) {
            let dataRender = dulieuRender[4];
            this.addContentToPage(doc, pageNumber, dataRender, bia);
            if (pageNumber <= totalPages) {
              doc.addPage();
            }
          }
          if (pageNumber == 13 && (!this.cauHinh.isKQHT)) {
            let dataRender = dulieuRender[4];
            this.addContentToPage(doc, pageNumber, dataRender, bia);
            if (pageNumber <= totalPages) {
              doc.addPage();
            }
          }
        }
      }
      const totalPages = doc.internal.getNumberOfPages();
      console.log("Tổng trang:" + totalPages);
      doc.deletePage(totalPages);
      doc.setDocumentProperties({
        title: this.fileName ? this.fileName : "Học Bạ Học Sinh",
      });
      const pdfDataUrl = doc.output("blob", {filename: "tenHocSinh"});
      // this.displayPDF(pdfDataUrl);
      this.xemPdf(pdfDataUrl);
    },
    addContentToPage(doc, pageNumber, duLieuRender, bia) {
      let duLieu = null;
      if (Array.isArray(duLieuRender)) {
        duLieu = this.convertNullsToEmptyStrings(duLieuRender[0]);
      } else {
        duLieu = this.convertNullsToEmptyStrings(duLieuRender);
      }
      let isThongTu27 = duLieu.maThongTu == "27-2020" || duLieu.maThongTu == "";
      console.log("maThongTu", isThongTu27);
      console.log("dulieuRender", duLieu);
      let pageWidth = doc.internal.pageSize.getWidth();
      if (pageNumber == 1) {
        doc.setFont("TimesBold");
        const marginTop = 10;
        const marginLeft = 15;
        const marginRight = doc.internal.pageSize.getWidth() - 10;
        const marginBottom = doc.internal.pageSize.getHeight() - 10;
        doc.setLineWidth(0.3);
        doc.line(marginLeft, marginTop, marginRight, marginTop);
        doc.line(marginLeft, marginTop, marginLeft, marginBottom);
        doc.line(marginRight, marginTop, marginRight, marginBottom);
        doc.line(marginLeft, marginBottom, marginRight, marginBottom);
        doc.setLineWidth(0.3);
        doc.line(marginLeft + 2, marginTop + 2, marginRight - 2, marginTop + 2);
        doc.line(marginLeft + 2, marginTop + 2, marginLeft + 2, marginBottom - 2);
        doc.line(marginRight - 2, marginTop + 2, marginRight - 2, marginBottom - 2);
        doc.line(marginLeft + 2, marginBottom - 2, marginRight - 2, marginBottom - 2);
        let textHocBa = "BỘ GIÁO DỤC VÀ ĐÀO TẠO";
        let fontSize = 16;
        let textWidth =
            (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
        doc.setFontSize(fontSize);
        doc.text(textHocBa, (pageWidth - textWidth) / 2, 25);
        fontSize = 40;
        doc.setFontSize(fontSize);
        textHocBa = "HỌC BẠ";
        textWidth =
            (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
        doc.text(textHocBa, (pageWidth - textWidth) / 2, 90);
        fontSize = 25;
        doc.setFontSize(fontSize);
        textHocBa = "TIỂU HỌC";
        textWidth =
            (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
        doc.text(textHocBa, (pageWidth - textWidth) / 2, 110);
        fontSize = 13;
        doc.setFontSize(fontSize);
        const bgColor = [255, 255, 255];
        const textX = 33;
        const valueX = 105;
        const rectX = 27;
        const rectY = 185;
        const rectWidth = 160;
        const rectHeight = 60;
        const borderRadius = 4;
        const fillColor = bgColor;
        doc.setFillColor(fillColor[0], fillColor[1], fillColor[2]);
        doc.roundedRect(
            rectX,
            rectY,
            rectWidth,
            rectHeight,
            borderRadius,
            borderRadius,
            "F"
        );
        doc.setDrawColor(0);
        doc.setLineWidth(0.1);
        doc.roundedRect(
            rectX,
            rectY,
            rectWidth,
            rectHeight,
            borderRadius,
            borderRadius,
            "S"
        );
        doc.setFont("TimesBold");
        doc.text("Họ và tên học sinh:", textX, 195);
        doc.setFont("TimesBold");
        const hoten = this.handleNull(duLieu.hoten);
        doc.text(hoten, valueX, 195);
        doc.setFont("TimesBold");
        doc.text("Trường:", textX, 205);
        doc.setFont("TimesBold");
        const tenTruong = this.countCharacters(duLieu.tenTruong, "", 45);
        doc.text(tenTruong, valueX, tenTruong.length > 45 ? 203 : 205);
        doc.setFont("TimesBold");
        doc.text("Xã (Phường, Thị trấn):", textX, 215);
        doc.setFont("TimesBold");
        const tenXa = this.handleNull(duLieu.tenXa);
        doc.text(tenXa, valueX, 215);
        doc.setFont("TimesBold");
        doc.text("Huyện (Thành phố, Quận, Thị xã):", textX, 225);
        doc.setFont("TimesBold");
        const tenHuyen = this.handleNull(duLieu.tenHuyen);
        doc.text(tenHuyen, valueX, 225);
        doc.setFont("TimesBold");
        doc.text("Tỉnh (Thành phố):", textX, 235);
        doc.setFont("TimesBold");
        const tenTinh = this.handleNull(duLieu.tenTinh);
        doc.text(tenTinh, valueX, 235);

      }
      else if (pageNumber == 2) {
        doc.setFont("TimesBold");
        let textHocBa = "HƯỚNG DẪN GHI HỌC BẠ";
        let fontSize = 20;
        let textWidth =
            (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
        doc.setFontSize(fontSize);
        doc.text(textHocBa, (pageWidth - textWidth) / 2, 17);
        let lines;
        let contentWidth = 190;
        if (isThongTu27) {
          doc.setFontSize(13);
          doc.setFont("VNTimes");
          lines = doc.splitTextToSize(
              "    Học bạ dùng để ghi kết quả tổng hợp đánh giá cuối năm học của học sinh. Khi ghi Học bạ, giáo viên cần nghiên cứu kĩ Thông tư số 27/2020/TT-BGDĐT ngày 04 tháng 09 năm 2020 của Bộ trưởng Bộ Giáo dục và Đào tạo về việc ban hành quy định đánh giá học sinh tiểu học.",
              contentWidth
          );
          doc.text(lines, 10, 27);
          doc.setFont("TimesBold");
          doc.text("1. Trang 1, thông tin ghi theo giấy khai sinh của học sinh", 10, 44);
          doc.setFont("TimesBold");
          doc.text(`2. Mục "1. Các môn học và hoạt động giáo dục"`, 10, 51);
          doc.setFont("VNTimes");
          lines = doc.splitTextToSize(
              `- Trong cột "Mức đạt được": Ghi kí hiệu T nếu học sinh đạt mức "Hoàn thành tốt"; H nếu học sinh đạt mức "Hoàn thành" hoặc C nếu học sinh ở mức "Chưa hoàn thành".`,
              contentWidth
          );
          doc.text(lines, 10, 58);
          lines = doc.splitTextToSize(
              `- Trong cột "Điểm KTĐK" đối với các môn học có Bài kiểm tra định kì: ghi điểm số của bài kiểm tra cuối năm học; đối với học sinh được kiểm tra lại, ghi điểm số của bài kiểm tra lần cuối.`,
              contentWidth
          );
          doc.text(lines, 10, 69);
          lines = doc.splitTextToSize(
              `- Trong cột "Nhận xét": Ghi những điểm nổi bật về sự tiến bộ, năng khiếu, hứng thú học tập đối với các môn học, hoạt động giáo dục của học sinh; nội dung, kĩ năng chưa hoàn thành trong từng môn học, hoạt động giáo dục cần được khắc phục, giúp đỡ (nếu có).`,
              contentWidth
          );
          doc.text(lines, 10, 80);
          doc.setFont("TimesBold");
          doc.text(
              `3. Mục "2. Những phẩm chất chủ yếu" và mục "3. Những năng lực cốt lõi"`,
              10,
              98
          );
          doc.setFont("VNTimes");
          lines = doc.splitTextToSize(
              `- Trong cột "Mức đạt được" tương ứng với từng nội dung đánh giá về phẩm chất, năng lực: ghi kí hiệu T nếu học sinh đạt mức “Tốt”, Đ nếu học sinh đạt mức “Đạt” hoặc C nếu học sinh ở mức “Cần cố gắng”.`,
              contentWidth
          );
          doc.text(lines, 10, 106);
          doc.setFont("VNTimes");
          lines = doc.splitTextToSize(
              ` - Trong cột "Nhận xét" tương ứng với nội dung đánh giá về phẩm chất: ghi các biểu hiện, sự tiến bộ, ưu điểm, hạn chế hoặc khuyến nghị (nếu có) về sự hình thành và phát triển một số phẩm chất chủ yếu của học sinh. Ví dụ: Đi học đầy đủ, đúng giờ, mạnh dạn trình bày ý kiến cá nhân; biết giữ lời hứa; tôn trọng và biết giúp đỡ mọi người;..`,
              contentWidth
          );
          doc.text(lines, 10, 123);
          doc.setFont("VNTimes");
          lines = doc.splitTextToSize(
              `  - Trong cột "Nhận xét" tương ứng với nội dung đánh giá về năng lực: ghi các biểu hiện, sự tiến bộ, ưu điểm, hạn chế hoặc khuyến nghị (nếu có) về sự hình thành và phát triển một số năng lực chung, năng lực đặc thù của học sinh. `,
              contentWidth
          );
          doc.text(lines, 10, 145);
          lines = doc.splitTextToSize(
              ` Ví dụ: Biết vệ sinh thân thể, ăn mặc gọn gàng; chủ động, phối hợp trong học tập; có khả năng tự học; ...; sử dụng ngôn ngữ lưu loát trong cuộc sống và học tập, biết tư duy, lập luận và giải quyết được một số vấn đề toán học quen thuộc;... `,
              contentWidth
          );
          doc.text(lines, 10, 162);
          doc.setFont("TimesBold");
          doc.text(`4. Mục "4. Đánh giá kết quả giáo dục"`, 10, 179);
          doc.setFont("VNTimes");
          lines = doc.splitTextToSize(
              `Ghi một trong bốn mức: “Hoàn thành xuất sắc”, “Hoàn thành tốt”, “Hoàn thành hoặc “Chưa hoàn thành”.`,
              contentWidth
          );
          doc.text(lines, 10, 186);
          doc.setFont("TimesBold");
          doc.text(`5. Mục "5. Khen thưởng"`, 10, 198);
          doc.setFont("VNTimes");
          lines = doc.splitTextToSize(
              `Ghi những thành tích mà học sinh được khen thưởng trong năm học. Ví dụ: Đạt danh hiệu Học sinh Xuất sắc; Đạt danh hiệu Học sinh Tiêu biểu hoàn thành tốt trong học tập và rèn luyện; Đạt giải Nhì hội giao lưu An toàn giao thông cấp huyện;`,
              contentWidth
          );
          doc.text(lines, 10, 205);
          doc.setFont("TimesBold");
          doc.text(
              `6. Mục "6. Hoàn thành chương trình lớp học/chương trình tiểu học"`,
              10,
              222
          );
          doc.setFont("VNTimes");
          lines = doc.splitTextToSize(
              `Ghi Hoàn thành chương trình lớp....../chương trình tiểu học hoặc Chưa hoàn thành chương trình \nlớp...../chương trình tiểu học; Được lên lớp hoặc Chưa được lên lớp.\nVí dụ:\n- Hoàn thành chương trình lớp 2; Được lên lớp 3; Hoàn thành chương trình tiểu học.`,
              contentWidth
          );
          doc.text(lines, 10, 230);
          doc.setFontSize(11);
          doc.setFont("VNTimesItalic");
          doc.text(
              "   Học bạ được nhà trường bảo quản và trả lại cho học sinh khi học sinh chuyển trường, học xong chương trình tiểu học.",
              10,
              260
          );
        } else {
          doc.setFontSize(12);
          const YGocBia = 25;
          const marginLeftBia = 10;
          doc.setFont("VNTimes");
          lines = doc.splitTextToSize(
              "      Học bạ dùng để ghi kết quả tổng hợp đánh giá cuối năm học của học sinh. Khi ghi Học bạ, giáo viên cần nghiên cứu kĩ Thông tư số 22/2016/TT-BGDĐT ngày 22/9/2016 của Bộ trưởng Bộ GDĐT sửa đổi, bổ sung một số điều quy định đánh giá học sinh tiểu học ban hành kèm theo Thông tư số 30/2014/TT-BGDĐT ngày 28/8/2014 của Bộ trưởng Bộ GDĐT.",
              contentWidth
          );
          doc.text(lines, marginLeftBia, YGocBia);
          doc.setFontSize(13);
          doc.setFont("TimesBold");
          doc.text(
              "1. Trang 1, thông tin ghi theo giấy khai sinh của học sinh",
              marginLeftBia,
              YGocBia + 20
          );
          doc.setFont("TimesBold");
          doc.text(
              `2. Mục "1. Các môn học và hoạt động giáo dục"`,
              marginLeftBia,
              YGocBia + 27
          );
          doc.setFontSize(12);
          doc.setFont("VNTimes");
          lines = doc.splitTextToSize(
              `- Trong cột "Nhận xét": Ghi những điểm nổi bật về sự tiến bộ, năng khiếu, hứng thú học tập đối với môn học, hoạt động giáo dục của học sinh; nội dung, kĩ năng chưa hoàn thành trong môn học, hoạt động giáo dục cần được khắc phục, giúp đỡ. `,
              contentWidth
          );
          doc.text(lines, marginLeftBia, YGocBia + 33);
          lines = doc.splitTextToSize(
              `- Trong cột "Mức đạt được": học sinh đạt mức "Hoàn thành tốt" ghi T; mức "Hoàn thành" ghi H; mức "Chưa hoàn thành" ghi C. `,
              contentWidth
          );
          doc.text(lines, marginLeftBia, YGocBia + 48);
          lines = doc.splitTextToSize(
              `- Trong cột "Điểm KTĐK" đối với các môn có Bài kiểm tra định kì: ghi điểm số của bài kiểm tra; đối với học sinh được kiểm tra lại, ghi điểm số của bài kiểm tra cuối cùng và lưu ý đặc biệt nếu có. `,
              contentWidth
          );
          doc.text(lines, marginLeftBia, YGocBia + 58);
          doc.setFontSize(13);
          doc.setFont("TimesBold");
          doc.text(`3. Mục "2. Các năng lực, phẩm chất`, marginLeftBia, YGocBia + 69);
          doc.setFontSize(12);
          doc.setFont("VNTimes");
          lines = doc.splitTextToSize(
              `- Trong cột "Nhận xét" tương ứng với cột "Năng lực": ghi các biểu hiện, sự tiến bộ, ưu điểm, hạn chế, góp ý và khuyến nghị (nếu có) về sự hình thành và phát triển một số năng lực của học sinh. Một số biểu hiện đối với từng năng lực, có thể là: `,
              contentWidth
          );
          doc.text(lines, marginLeftBia, YGocBia + 76);
          lines = doc.splitTextToSize(
              `+ Tự phục vụ, tự quản: thực hiện được một số việc phục vụ cho bản thân (vệ sinh thân thể, ăn, mặc,…); một số việc phục vụ cho học tập (chuẩn bị đồ dùng học tập ở lớp, ở nhà,…). `,
              contentWidth
          );
          doc.text(lines, marginLeftBia, YGocBia + 91);
          lines = doc.splitTextToSize(
              `+ Hợp tác: mạnh dạn giao tiếp trong hợp tác; trình bày rõ ràng, ngắn gọn; nói đúng nội dung cần trao đổi; ngôn ngữ phù hợp với hoàn cảnh và đối tượng. `,
              contentWidth
          );
          doc.text(lines, marginLeftBia, YGocBia + 101);
          lines = doc.splitTextToSize(
              `+ Tự học và giải quyết vấn đề: khả năng tự thực hiện nhiệm vụ học cá nhân trên lớp, làm việc trong nhóm, tổ, lớp; khả năng tự học có sự giúp đỡ hoặc không cần giúp đỡ`,
              contentWidth
          );
          doc.text(lines, marginLeftBia, YGocBia + 111);
          lines = doc.splitTextToSize(
              `Ví dụ cách ghi vào cột “Nhận xét”: Biết vệ sinh thân thể, ăn mặc gọn gàng; mạnh dạn giao tiếp trong hợp tác; có khả năng tự học;`,
              contentWidth
          );
          doc.text(lines, marginLeftBia, YGocBia + 121);
          lines = doc.splitTextToSize(
              `- Trong cột "Nhận xét" tương ứng với cột "Phẩm chất": ghi các biểu hiện, sự tiến bộ, ưu điểm, hạn chế, góp ý và khuyến nghị (nếu có) về sự hình thành và phát triển một số phẩm chất của học sinh. Một số biểu hiện đối với từng phẩm chất, có thể là:`,
              contentWidth
          );
          doc.text(lines, marginLeftBia, YGocBia + 131);
          lines = doc.splitTextToSize(
              `+ Chăm học, chăm làm: đi học đầy đủ, đúng giờ; thường xuyên trao đổi bài với bạn, thầy cô và người lớn.`,
              contentWidth
          );
          doc.text(lines, marginLeftBia, YGocBia + 146);
          lines = doc.splitTextToSize(
              `+ Tự tin, trách nhiệm: mạnh dạn khi thực hiện nhiệm vụ học tập, trình bày ý kiến cá nhân; tự chịu trách nhiệm về các việc làm, không đổ lỗi cho người khác khi mình làm chưa đúng.`,
              contentWidth
          );
          doc.text(lines, marginLeftBia, YGocBia + 151);
          lines = doc.splitTextToSize(
              `+ Trung thực, kỉ luật: không nói dối; không nói sai về bạn; tôn trọng lời hứa, giữ lời hứa.`,
              contentWidth
          );
          doc.text(lines, marginLeftBia, YGocBia + 161);
          lines = doc.splitTextToSize(
              `+ Đoàn kết, yêu thương: giúp đỡ, tôn trọng mọi người; nhường nhịn bạn; quan tâm chăm sóc ông bà, cha mẹ, anh em, bạn bè; kính trọng người lớn, biết ơn thầy giáo, cô giáo.`,
              contentWidth
          );
          doc.text(lines, marginLeftBia, YGocBia + 166);
          lines = doc.splitTextToSize(
              `Ví dụ cách ghi vào cột “Nhận xét”: đi học đầy đủ; mạnh dạn khi thực hiện nhiệm vụ học tập; biết giữ lời hứa; biết giúp đỡ, tôn trọng mọi người.`,
              contentWidth
          );
          doc.text(lines, marginLeftBia, YGocBia + 176);
          lines = doc.splitTextToSize(
              `- Trong cột "Mức đạt được" tương ứng với từng nội dung của cột "Năng lực" và cột "Phẩm chất": ghi kí hiệu T nếu học sinh đạt mức “Tốt”, Đ nếu học sinh đạt mức “Đạt” hoặc C nếu học sinh ở mức “Cần cố gắng”.`,
              contentWidth
          );
          doc.text(lines, marginLeftBia, YGocBia + 186);
          doc.setFontSize(13);
          doc.setFont("TimesBold");
          doc.text(`4. Mục "Khen thưởng"`, marginLeftBia, YGocBia + 198);
          doc.setFontSize(12);
          doc.setFont("VNTimes");
          lines = doc.splitTextToSize(
              `- Ghi lại những thành tích mà học sinh đạt được. Ví dụ: Hoàn thành xuất sắc nhiệm vụ học tập và rèn luyện; Có thành tích trong học tập môn Tiếng Việt; Có tiến bộ vượt bậc về tự phục vụ và tự quản; Đạt giải Nhì hội giao lưu An toàn giao thông cấp huyện/tỉnh/quốc gia. `,
              contentWidth
          );
          doc.text(lines, marginLeftBia, YGocBia + 205);
          doc.setFontSize(13);
          doc.setFont("TimesBold");
          doc.text(
              `5. Mục "Hoàn thành chương trình lớp học/chương trình tiểu học`,
              marginLeftBia,
              YGocBia + 220
          );
          doc.setFontSize(12);
          doc.setFont("VNTimes");
          lines = doc.splitTextToSize(
              `Ghi Hoàn thành chương trình lớp….../chương trình tiểu học hoặc Chưa hoàn thành chương trình lớp….../chương trình tiểu học; Được lên lớp hoặc Chưa được lên lớp. Ví dụ:`,
              contentWidth
          );
          doc.text(lines, marginLeftBia, YGocBia + 225);
          lines = doc.splitTextToSize(
              `- Hoàn thành chương trình lớp 2; Được lên lớp 3.`,
              contentWidth
          );
          doc.text(lines, marginLeftBia, YGocBia + 235);
          lines = doc.splitTextToSize(
              `- Chưa hoàn thành chương trình lớp 3; Được lên lớp 4.`,
              contentWidth
          );
          doc.text(lines, marginLeftBia, YGocBia + 240);
          lines = doc.splitTextToSize(
              `- Chưa hoàn thành chương trình lớp 4; Ở lại lớp 4.`,
              contentWidth
          );
          doc.text(lines, marginLeftBia, YGocBia + 245);
          lines = doc.splitTextToSize(
              `- Hoàn thành chương trình tiểu học.`,
              contentWidth
          );
          doc.text(lines, marginLeftBia, YGocBia + 250);
          doc.setFontSize(11);
          doc.setFont("VNTimesItalic");
          lines = doc.splitTextToSize(
              `Học bạ được nhà trường bảo quản và trả lại cho học sinh khi học sinh chuyển trường, học xong chương trình tiểu học.`,
              contentWidth
          );
          doc.text(lines, marginLeftBia, YGocBia + 260);
        }
      } else if (pageNumber == 3) {
        let textHocBa = "HỌC BẠ";
        let fontSize = 30;
        doc.setFont("TimesBold");
        let textWidth =
            (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
        doc.setFontSize(fontSize);
        doc.text(textHocBa, (pageWidth - textWidth) / 2, 20);
        doc.setFont("VNTimes");
        doc.setFontSize(13);
        doc.text("Họ và tên học sinh:", 14, 35);
        doc.setFont("TimesBold");
        const hoten = this.handleNull(bia.tenHS);
        doc.text(hoten ? hoten.toUpperCase() : '', 62, 35);
        doc.setFont("VNTimes");
        doc.text("Giới tính:", 155, 35);
        const gioiTinh = this.getTextGender(bia.gioiTinh);
        // doc.setFont("TimesBold");
        doc.text(gioiTinh, 175, 35);
        doc.setFont("VNTimes");
        doc.text("Ngày, tháng, năm sinh:", 14, 45);
        const ngaySinh = this.handleNull(bia.ngaySinh);
        // doc.setFont("TimesBold");
        doc.text(ngaySinh, 62, 45);
        doc.setFont("VNTimes");
        doc.text("Dân tộc:", 105, 45);
        const tenDanToc = this.handleNull(bia.tenDanToc);
        // doc.setFont("TimesBold");
        doc.text(tenDanToc, 125, 45);
        doc.setFont("VNTimes");
        doc.text("Quốc tịch:", 155, 45);
        const tenQuocTich = this.handleNull(bia.tenQuocTich);
        // doc.setFont("TimesBold");
        doc.text(tenQuocTich || "Việt Nam", 175, 45);
        doc.setFont("VNTimes");
        doc.text("Nơi sinh:", 14, 55);
        const noiSinh = this.countCharacters(bia.noiSinh, "", 70);
        // doc.setFont("TimesBold");
        doc.text(noiSinh, 62, noiSinh.length > 70 ? 52 : 55);
        doc.setFont("VNTimes");
        doc.text("Quê quán:", 14, 65);
        const queQuan = this.handleNull(bia.queQuan);
        // doc.setFont("TimesBold");
        doc.text(queQuan, 62, 65);
        doc.setFont("VNTimes");
        doc.text("Nơi ở hiện nay:", 14, 75);
        const noiOHienNay = this.countCharacters(bia.noiOHienNay, "", 70);
        // doc.setFont("TimesBold");
        doc.text(noiOHienNay, 62, noiOHienNay.length > 70 ? 72.5 : 75);
        doc.setFont("VNTimes");
        doc.text("Họ và tên cha:", 14, 85);
        const hoTenCha = this.handleNull(bia.hoTenCha);
        // doc.setFont("TimesBold");
        doc.text(hoTenCha, 62, 85);
        doc.setFont("VNTimes");
        doc.text("Họ và tên mẹ:", 14, 95);
        const hoTenMe = this.handleNull(bia.hoTenMe);
        // doc.setFont("TimesBold");
        doc.text(hoTenMe, 62, 95);
        doc.setFont("VNTimes");
        doc.text("Người giám hộ (nếu có):", 14, 105);
        const nguoiGiamHo = this.handleNull(bia.nguoiGiamHo);
        // doc.setFont("TimesBold");
        doc.text(nguoiGiamHo, 62, 105);
        doc.setFont("VNTimes");
        const imgX = 130;
        const imgY = 123;
        const imgWidth = this.imgStampWidth;
        const imgHeight = this.imgStampHeight;
        if(!this.cauHinh.isDau){
          doc.addImage(
              this.handleNull(bia.pathDau),
              "JPEG",
              imgX,
              imgY,
              imgWidth,
              imgHeight
          );
        }
        const imgWidth2 = this.imgStampWidth + 10;
        const imgHeight2 = this.imgStampHeight / 2;
        const imgX2 = imgX + imgWidth / 2 + 3;
        const imgY2 = imgY + (imgHeight - imgHeight2) / 2;
        if (!this.cauHinh.isAnAnhHieuTruong) {
          doc.addImage(
              this.handleNull(bia.pathChuKyHieuTruong),
              "JPEG",
              imgX2,
              imgY2,
              imgWidth2,
              imgHeight2
          );
        }
        const textKyVaGhiRoHoTen = "(Ký, ghi rõ họ tên và đóng dấu)";
        const tieuDeKyThay = this.handleNull(bia.tieuDeKyThay);
        console.log('tieuDeKyThay')
        console.log(tieuDeKyThay)
        const xGoc = 130;
        const yGoc = 130;
        const textKyVaGhiRoHoTenWidth = doc.getTextWidth(textKyVaGhiRoHoTen);
        const centerXGoc = xGoc + textKyVaGhiRoHoTenWidth / 2;
        doc.setFont("VNTimesItalic");
        doc.text(textKyVaGhiRoHoTen, xGoc, tieuDeKyThay.includes("\n") ? xGoc : (xGoc - 5));
        const hasNewline = tieuDeKyThay.includes("\n");
        const tieuDeKyThayWidth = doc.getTextWidth(tieuDeKyThay);
        const xTieuDeKyThay = hasNewline
            ? centerXGoc - tieuDeKyThayWidth / 2 + 20
            : centerXGoc - tieuDeKyThayWidth / 2;
        doc.setFont("TimesBold");
        doc.text(tieuDeKyThay, xTieuDeKyThay, yGoc - 10);
        const textNgayKyBia = this.handleNull(bia.ngayKyBia);
        const textGocWidth = doc.getTextWidth(textNgayKyBia);
        const xTextGoc = (centerXGoc - textGocWidth / 2);
        doc.setFont("VNTimes");
        // doc.text('text Ngay Ky Bia', xTextGoc, yGoc - 20);
        const textLines = doc.splitTextToSize(textNgayKyBia, pageWidth);
        const textWidths = doc.getStringUnitWidth(textNgayKyBia) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        const x = pageWidth - textWidths - 15; // 10 là khoảng cách từ lề phải
        // Thêm văn bản với căn lề phải
        for (let i = 0; i < textLines.length; i++) {
          doc.text(textLines[i], x, yGoc - 17);
        }
        const textTenHieuTruong = this.handleNull(bia.tenHieuTruong);
        const textTenHieuTruongWidth = doc.getTextWidth(textTenHieuTruong);
        const xTenHieuTruong = centerXGoc - textTenHieuTruongWidth / 2;
        doc.setFont("TimesBold");
        doc.text(textTenHieuTruong, xTenHieuTruong, yGoc + 35);
        fontSize = 13;
        doc.setFont("TimesBold");
        textHocBa = "QUÁ TRÌNH HỌC TẬP";
        textWidth =
            (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
        doc.text(textHocBa, (pageWidth - textWidth) / 2, 190);
        var quaTrinhHocTaps = bia.quaTrinhHocTaps;
        if (this.cauHinh.isQTHT) {
          //   Ẩn qtht
          quaTrinhHocTaps.forEach(obj => {
            for (let key in obj) {
              if (obj.hasOwnProperty(key)) {
                obj[key] = null;  // Đưa giá trị về null
              }
            }
          });
        }
        let header = [
          [
            {content: "Năm học", colSpan: 1},
            {content: "Lớp", colSpan: 1},
            {
              content: "Tên trường",
              colSpan: 1,
            },
            {
              content: "Sổ đăng bộ",
              colSpan: 1,
            },
            {
              content: "Ngày nhập học/\nchuyển đến",
              colSpan: 1,
            },
          ],
        ];
        let body = [];
        if (Array.isArray(quaTrinhHocTaps) && quaTrinhHocTaps.length > 0) {
          body = quaTrinhHocTaps.map((item) => {
            return [
              {content: this.handleNull(item.namHoc), colSpan: 1},
              {content: this.handleNull(item.tenLop), colSpan: 1},
              {
                content: this.handleNull(item.tenTruong),
                colSpan: 1,
              },
              {
                content: this.handleNull(item.soDangBo),
                colSpan: 1,
              },
              {
                content: this.handleNull(item.ngayTrangThai),
                colSpan: 1,
              },
            ];
          });
        } else {
          body = [
            [
              {
                content: "",
                colSpan: 1,
              },
              {
                content: "",
                colSpan: 1,
              },
              {content: "", colSpan: 1},
              {content: "", colSpan: 1},
            ],
            [
              {content: "", colSpan: 1},
              {
                content: "",
                colSpan: 1,
              },
              {content: "", colSpan: 1},
            ],
            [
              {content: "", colSpan: 1},
              {
                content: "",
                colSpan: 1,
              },
              {content: "", colSpan: 1},
            ],
            [
              {content: "", colSpan: 1},
              {
                content: "",
                colSpan: 1,
              },
              {content: "", colSpan: 1},
            ],
            [
              {content: "", colSpan: 1},
              {
                content: "",
                colSpan: 1,
              },
              {content: "", colSpan: 1},
            ],
          ];
        }
        body = body;
        let columnStyles = {
          0: {
            halign: "center",
            valign: "middle",
            fontSize: 12,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
          },
          1: {
            halign: "center",
            fontSize: 12,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
          },
          2: {
            halign: "left",
            fontSize: 12,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 10},
            valign: "middle",
            cellWidth: 80,
          },
          3: {
            halign: "center",
            fontSize: 12,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
          },
          4: {
            halign: "center",
            fontSize: 12,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
          },
        };
        doc.autoTable({
          tableLineColor: [70, 76, 70],
          columnStyles: columnStyles,
          head: header,
          body: body,
          startY: 195,
          theme: "grid",
          headStyles: {
            fillColor: [255, 255, 255],
            textColor: [45, 45, 45],
            font: "TimesBold",
            fontStyle: "normal",
            lineWidth: 0.1,
            halign: "center",
            valign: "middle",
            fontSize: 12,
            cellPadding: 1,
          },
          styles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: "TimesBold",
            fontStyle: "normal",
          },
          bodyStyles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: "VNTimes",
            fontStyle: "normal",
            cellPadding: 1,
            fontSize: 12,
            minCellHeight: 10,
          },
        });
      } else if (pageNumber == 4)
      {
        if (this.cauHinh.isAnSoTrang != -1)
        {
          doc.setFont("VNTimes");
          let textHocBa1 = String(this.cauHinh.isAnSoTrang + pageNumber - 4);
          console.log('textHocBa1')
          console.log(textHocBa1)
          let fontSize1 = 13;
          let textWidth1 =
              (doc.getStringUnitWidth(textHocBa1) * fontSize1) / doc.internal.scaleFactor;
          doc.setFontSize(fontSize1);
          doc.text(textHocBa1, (pageWidth - textWidth1) / 2, 295);
        }
        doc.setLineWidth(0.3);
        doc.text("Họ và tên học sinh:", 14, 15);
        doc.setFont("TimesBold");
        const hoten = this.handleNull(duLieu.hoten);
        doc.text(hoten, 53, 15);
        doc.setFont("TimesBold");
        doc.text("Lớp:", 150, 15);
        doc.setFont("TimesBold");
        const tenLop = this.handleNull(duLieu.tenLop);
        doc.text(tenLop, 170, 15);
        doc.setFont("VNTimes");
        doc.text("Chiều cao:", 14, 25);
        doc.text(duLieu.chieuCao ? `${duLieu.chieuCao} cm ` : "", 34, 25);
        doc.setFont("VNTimes");
        doc.text("Cân nặng:", 120, 25);
        doc.setFont("VNTimes");
        doc.text(duLieu.canNang ? `${duLieu.canNang} kg` : "", 140, 25);
        doc.setFont("VNTimes");
        doc.text("Số ngày nghỉ có phép:", 14, 35);
        const soNgayNghiCoPhep = duLieu.soNgayNghiCoPhep
            ? duLieu.soNgayNghiCoPhep.toString()
            : "0";
        doc.text(soNgayNghiCoPhep, 56, 35);
        doc.setFont("VNTimes");
        doc.text("Số ngày nghỉ không phép:", 120, 35);
        doc.setFont("VNTimes");
        const soNgayNghiKhongPhep = duLieu.soNgayNghiKhongPhep
            ? duLieu.soNgayNghiKhongPhep.toString()
            : "0";
        doc.text(soNgayNghiKhongPhep, 170, 35);
        doc.setFont("TimesBold");
        doc.setFontSize(13);
        doc.text("1. Các môn học và hoạt động giáo dục", 14, 45);
        let header = [
          [
            {content: "Môn học và hoạt động giáo dục", colSpan: 1},
            {
              content: "Mức đạt \nđược",
              colSpan: 1,
            },
            {textAlign: "center", content: "Điểm \nKTĐK", colSpan: 1},
            {content: "Nhận xét", colSpan: 1},
          ],
        ];
        let body = [];
        isThongTu27
            ? (body = [
              [
                {content: "Tiếng Việt", colSpan: 1},
                {
                  content: this.handleNull(duLieu.tvDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.tvDiem), colSpan: 1},
                {
                  content: this.handleNull(this.cauHinh.gopNhanXet ? duLieu.nhanXet : duLieu.tvNx),
                  rowSpan: this.cauHinh.gopNhanXet ? 14 : 1,
                },
              ],
              [
                {content: "Toán", colSpan: 1},
                {
                  content: this.handleNull(duLieu.toDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.toDiem), colSpan: 1},
                {content: this.handleNull(duLieu.toNx), colSpan: 1},
              ],
              [
                {content: "Ngoại ngữ", colSpan: 1},
                {
                  content: this.handleNull(duLieu.nnDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.nnDiem), colSpan: 1},
                {content: this.handleNull(duLieu.nnNx), colSpan: 1},
              ],
              [
                {content: "Lịch sử và Địa lý", colSpan: 1},
                {
                  content: this.handleNull(duLieu.ldDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.ldDiem), colSpan: 1},
                {content: this.handleNull(duLieu.ldNx), colSpan: 1},
              ],
              [
                {content: "Khoa học", colSpan: 1},
                {
                  content: this.handleNull(duLieu.khDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.khDiem), colSpan: 1},
                {content: this.handleNull(duLieu.khNx), colSpan: 1},
              ],
              [
                {content: "Tin học và Công nghệ (Công nghệ)", colSpan: 1},
                {
                  content: this.handleNull(duLieu.cnDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.cnDiem), colSpan: 1},
                {content: this.handleNull(duLieu.cnNx), colSpan: 1},
              ],
              [
                {
                  content: "Tin học và Công nghệ (Tin học)",
                  colSpan: 1,
                },
                {
                  content: this.handleNull(duLieu.thDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.thDiem), colSpan: 1},
                {content: this.handleNull(duLieu.thNx), colSpan: 1},
              ],
              [
                {content: "Đạo đức", colSpan: 1},
                {content: this.handleNull(duLieu.ddDgtx), colSpan: 1},
                {
                  content: "",
                  rowSpan: 7,
                },
                {content: this.handleNull(duLieu.ddNx), colSpan: 1},
              ],
              [
                {content: "Tự nhiên và Xã hội", colSpan: 1},
                {content: this.handleNull(duLieu.txDgtx), colSpan: 1},
                {content: this.handleNull(duLieu.txNx), colSpan: 1,},
              ],
              [
                {content: "Giáo dục thể chất", colSpan: 1},
                {content: this.handleNull(duLieu.tdDgtx), colSpan: 1},
                {content: this.handleNull(duLieu.tdNx), colSpan: 1,},
              ],
              [
                {content: "Nghệ thuật (Âm nhạc)", colSpan: 1},
                {content: this.handleNull(duLieu.anDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.anNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Nghệ thuật (Mĩ thuật)", colSpan: 1},
                {content: this.handleNull(duLieu.mtDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.mtNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Hoạt động trải nghiệm", colSpan: 1},
                {
                  content: this.handleNull(duLieu.hdtnDgtx),
                  colSpan: 1,
                },
                {
                  content: this.handleNull(duLieu.hdtnNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Tiếng dân tộc", colSpan: 1},
                {content: this.handleNull(duLieu.dtDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.dtNx),
                  colSpan: 1,
                },
              ],
            ])
            : (body = [
              [
                {content: "Tiếng Việt", colSpan: 1},
                {
                  content: this.handleNull(duLieu.tvDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.tvDiem), colSpan: 1},
                {
                  content: this.handleNull(duLieu.tvNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Toán", colSpan: 1},
                {
                  content: this.handleNull(duLieu.toDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.toDiem), colSpan: 1},
                {
                  content: this.handleNull(duLieu.toNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Khoa học", colSpan: 1},
                {
                  content: this.handleNull(duLieu.khDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.khDiem), colSpan: 1},
                {
                  content: this.handleNull(duLieu.khNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Lịch sử và Địa lý", colSpan: 1},
                {
                  content: this.handleNull(duLieu.ldDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.ldDiem), colSpan: 1},
                {
                  content: this.handleNull(duLieu.ldNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Ngoại ngữ", colSpan: 1},
                {
                  content: this.handleNull(duLieu.nnDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.nnDiem), colSpan: 1},
                {
                  content: this.handleNull(duLieu.nnNx),
                  colSpan: 1,
                },
              ],
              [
                {
                  content: "Tin học",
                  colSpan: 1,
                },
                {
                  content: this.handleNull(duLieu.thDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.thDiem), colSpan: 1},
                {
                  content: this.handleNull(duLieu.thNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Tiếng dân tộc", colSpan: 1},
                {content: this.handleNull(duLieu.dtDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.dtNx),
                  colSpan: 1,
                },
                {
                  content: "",
                  colSpan: 1,
                },
              ],
              [
                {content: "Đạo đức", colSpan: 1},
                {content: this.handleNull(duLieu.ddDgtx), colSpan: 1},
                {
                  content: "",
                  rowSpan: 7,
                },
                {
                  content: this.handleNull(duLieu.ddNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Âm nhạc", colSpan: 1},
                {content: this.handleNull(duLieu.anDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.anNx),
                  colSpan: 1,
                },
                {
                  content: "",
                  colSpan: 1,
                },
              ],
              [
                {content: "Mĩ thuật", colSpan: 1},
                {content: this.handleNull(duLieu.mtDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.mtNx),
                  colSpan: 1,
                },
                {
                  content: "",
                  colSpan: 1,
                },
              ],
              [
                {content: "Kỹ thuật", colSpan: 1},
                {content: this.handleNull(duLieu.ktDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.ktNx),
                  colSpan: 1,
                },
                {
                  content: "",
                  colSpan: 1,
                },
              ],
              [
                {content: "Thể dục", colSpan: 1},
                {content: this.handleNull(duLieu.tdDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.tdNx),
                  colSpan: 1,
                },
                {
                  content: "",
                  colSpan: 1,
                },
              ],
            ]);
        body = body;
        let columnStyles = {
          0: {
            halign: "center",
            valign: "middle",
            fontSize: 12,
            cellWidth: 50,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 2},
          },
          1: {
            halign: "center",
            textAlign: "center",
            fontSize: 12,
            cellPadding: {top: 4, right: 4, bottom: 4, left: 4},
            valign: "middle",
            font: "TimesBold",
            cellWidth: 21.5,
          },
          2: {
            textAlign: "center",
            halign: "center",
            fontSize: 12,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            font: "TimesBold",
            cellWidth: 21.5,
          },
          3: {
            textAlign: "center",
            halign: "left",
            fontSize: 12,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 4},
            valign: "top",
            cellWidth: 91.5,
          },
        };
        doc.autoTable({
          tableLineColor: [70, 76, 70],
          columnStyles: columnStyles,
          head: header,
          body: body,
          startY: 50,
          didDrawCell: function (data) {
            if (data.row.index === 7 && data.column.index === 2) {
              const {doc, cell} = data;
              const startX = cell.x + cell.width;
              const startY = cell.y;
              const endX = cell.x;
              const endY = cell.y + cell.height;
              doc.setLineWidth(0.3);
              doc.line(startX, startY, endX, endY);
            }
          },
          willDrawCell: (data) => {
            if (data.section === "body") {
              const textLines = data.cell.text;
              const increaseSpace = (text, widthDifference) => {
                const words = text.split(" ");
                const targetSpace = 2;
                let splitIndex;
                const conditionMap = {
                  "1-3": Math.floor(words.length / 4.65),
                  "3-8": Math.floor(words.length / 2.45),
                  "8-16": Math.floor((words.length * 4) / 5),
                };
                const conditionKeys = Object.keys(conditionMap);
                const matchedKey = conditionKeys.find((key) => {
                  const [min, max] = key.split("-").map(Number);
                  return widthDifference > min && widthDifference < max;
                });
                splitIndex = matchedKey ? conditionMap[matchedKey] : splitIndex;
                for (let i = 0; i < splitIndex; i++) {
                  words[i] += " ".repeat(targetSpace - 1);
                }
                return words.join(" ");
              };
              const doc = data.doc;
              const convertLines = textLines.map((line, index) => {
                const lineWidth = doc.getTextWidth(line);
                return {line, lineWidth, index};
              });
              const maxWidth = Math.max(...convertLines.map((line) => line.lineWidth));
              let newLinesArray = [];
              convertLines.forEach(({line, lineWidth}) => {
                if (lineWidth > 0) {
                  const widthDifference = maxWidth - lineWidth;
                  line = increaseSpace(line, widthDifference);
                }
                newLinesArray.push(line);
              });
              data.cell.text = newLinesArray;
            }
          },
          includeHiddenHtml: true,
          theme: "grid",
          headStyles: {
            fillColor: [255, 255, 255],
            textColor: [45, 45, 45],
            font: "TimesBold",
            fontStyle: "normal",
            lineWidth: 0.1,
            halign: "center",
            valign: "middle",
            fontSize: 12,
            cellPadding: 1,
          },
          styles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: "TimesBold",
            fontStyle: "normal",
            lineHeight: 1.5,
          },
          bodyStyles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: "VNTimes",
            fontStyle: "normal",
            cellPadding: 2.5,
            minCellHeight: 15,
          },
        });
      }
      else if (pageNumber == 5) {
        if (this.cauHinh.isAnSoTrang != -1) {
          doc.setFont("VNTimes");
          let textHocBa1 = String(this.cauHinh.isAnSoTrang + pageNumber - 4);
          console.log('textHocBa1')
          console.log(textHocBa1)
          let fontSize1 = 13;
          let textWidth1 =
              (doc.getStringUnitWidth(textHocBa1) * fontSize1) / doc.internal.scaleFactor;
          doc.setFontSize(fontSize1);
          doc.text(textHocBa1, (pageWidth - textWidth1) / 2, 295);
        }
        if (isThongTu27) {
          doc.setLineWidth(0.3);
          doc.setFont("TimesBold");
          doc.text("Trường:", 15, 10);
          let tenTruong = this.countCharacters(duLieu.tenTruong, "", 50);
          const hoten = this.handleNull(duLieu.hoten);
          doc.text(tenTruong, 35, 10);
          doc.text("Năm học:", 158, 10);
          const tenLop = this.handleNull(duLieu.tenLop);
          let namHoc = duLieu.namHoc ? duLieu.namHoc.toString() : "";
          let convertNamHoc = `${namHoc}-${(parseInt(namHoc) + 1)?.toString()}`;
          doc.text(convertNamHoc, 178, 10);
          doc.setFont("TimesBold");
          doc.setFontSize(13);
          doc.text("2. Những phẩm chất chủ yếu", 15, 20);
          let header = [
            [
              {content: "Phẩm chất", colSpan: 1},
              {content: "Mức đạt được", colSpan: 1},
              {
                content: "Nhận xét",
                colSpan: 1,
              },
            ],
          ];
          let body = [
            [
              {content: "Yêu nước", colSpan: 1},
              {
                content: this.handleNull(duLieu.pcYeuNuoc),
                colSpan: 1,
              },
              {
                content: this.removeTabs(duLieu.nxPhamChatChuYeu),
                rowSpan: 5,
              },
            ],
            [
              {content: "Nhân ái", colSpan: 1},
              {content: this.handleNull(duLieu.pcNhanAi), colSpan: 1},
            ],
            [
              {content: "Chăm chỉ", colSpan: 1},
              {content: this.handleNull(duLieu.pcChamChi), colSpan: 1},
            ],
            [
              {content: "Trung thực", colSpan: 1},
              {content: this.handleNull(duLieu.pcTrungThuc), colSpan: 1},
            ],
            [
              {content: "Trách nhiệm", colSpan: 1},
              {content: this.handleNull(duLieu.pcTrachNhiem), colSpan: 1},
            ],
          ];
          let columnStyles = {
            0: {
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellWidth: 60,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 2},
            },
            1: {
              textAlign: "center",
              halign: "center",
              fontSize: 12,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
              valign: "middle",
              font: "TimesBold",
              cellWidth: 30,
            },
            2: {
              halign: "left",
              fontSize: 12,
              cellPadding: {top: 4, right: 4, bottom: 4, left: 4},
              valign: "middle",
              cellWidth: 95,
            },
          };
          const startY_1 = 23;
          doc.autoTable({
            tableLineColor: [70, 76, 70],
            columnStyles: columnStyles,
            head: header,
            body: body,
            startY: startY_1,
            theme: "grid",
            headStyles: {
              fillColor: [255, 255, 255],
              textColor: [45, 45, 45],
              font: "TimesBold",
              fontStyle: "normal",
              lineWidth: 0.1,
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellPadding: 1,
              minCellHeight: 8,
            },
            styles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "TimesBold",
              fontStyle: "normal",
            },
            bodyStyles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "VNTimes",
              fontStyle: "normal",
              cellPadding: 2,
              fontSize: 12,
              minCellHeight: 8,
            },
            willDrawCell: (data) => {
              if (data.section === "body" && data.cell.rowSpan === 5) {
                data.cell.text = this.adjustTextSpacing(data.cell.text, data.doc);
              }
            },
          });
          const endY1 = doc.autoTable.previous.finalY;
          const tableHeight_1 = endY1 - startY_1;
          doc.setFont("TimesBold");
          doc.setFontSize(13);
          doc.text(
              "3. Những năng lực cốt lõi",
              15,
              doc.autoTable.previous.finalY + 6
          );
          doc.setFont("VNTimesItalic");
          doc.text("3.1. Những năng lực chung", 15, doc.autoTable.previous.finalY + 13);
          header = [
            [
              {content: "Năng lực", colSpan: 1},
              {content: "Mức đạt được", colSpan: 1},
              {
                content: "Nhận xét",
                colSpan: 1,
              },
            ],
          ];
          body = [
            [
              {content: "Tự chủ và tự học", colSpan: 1},
              {
                content: this.handleNull(duLieu.nlTuChuTuHoc),
                colSpan: 1,
              },
              {
                content: this.removeTabs(duLieu.nxNangLucChung),
                rowSpan: 3,
              },
            ],
            [
              {
                content: "Giao tiếp và hợp tác",
                colSpan: 1,
              },
              {
                content: this.handleNull(duLieu.nlGiaoTiepHopTac),
                colSpan: 1,
              },
            ],
            [
              {
                content: "Giải quyết vấn đề và sáng tạo",
                colSpan: 1,
              },
              {
                content: this.handleNull(duLieu.nlGquyetVdeSangTao),
                colSpan: 1,
              },
            ],
          ];
          columnStyles = {
            0: {
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellWidth: 60,
              cellPadding: {top: 3, right: 3, bottom: 3, left: 3},
            },
            1: {
              halign: "center",
              fontSize: 12,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
              valign: "middle",
              cellWidth: 30,
              font: "TimesBold",
            },
            2: {
              halign: "left",
              fontSize: 12,
              cellPadding: {top: 2, right: 2, bottom: 2, left: 2},
              valign: "middle",
              cellWidth: 95,
            },
          };
          const startY_2 = doc.autoTable.previous.finalY + 17;
          doc.autoTable({
            tableLineColor: [70, 76, 70],
            columnStyles: columnStyles,
            head: header,
            body: body,
            startY: startY_2,
            theme: "grid",
            headStyles: {
              fillColor: [255, 255, 255],
              textColor: [45, 45, 45],
              font: "TimesBold",
              fontStyle: "normal",
              lineWidth: 0.1,
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellPadding: 1,
            },
            styles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "TimesBold",
              fontStyle: "normal",
            },
            bodyStyles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "VNTimes",
              fontStyle: "normal",
              cellPadding: 2,
              fontSize: 12,
            },
            willDrawCell: (data) => {
              if (data.section === "body" && data.cell.rowSpan === 3) {
                data.cell.text = this.adjustTextSpacing(data.cell.text, data.doc);
              }
            },
          });
          const endY_2 = doc.autoTable.previous.finalY;
          const tableHeight_2 = endY_2 - startY_2;
          doc.setFont("VNTimesItalic");
          doc.text("3.2. Những năng lực đặc thù", 15, doc.autoTable.previous.finalY + 6);
          header = [
            [
              {content: "Năng lực", colSpan: 1},
              {content: "Mức đạt được", colSpan: 1},
              {
                content: "Nhận xét",
                colSpan: 1,
              },
            ],
          ];
          body = [
            [
              {content: "Ngôn ngữ", colSpan: 1},
              {
                content: this.handleNull(duLieu.nlNgonNgu),
                colSpan: 1,
              },
              {
                content: this.removeTabs(duLieu.nxNangLucDacThu ? duLieu.nxNangLucDacThu.replace(/\r\n\r\n/g, '\r\n') : ''),
                rowSpan: 7
              },
            ],
            [
              {content: "Tính toán", colSpan: 1},
              {content: this.handleNull(duLieu.nlTinhToan), colSpan: 1},
            ],
            [
              {content: "Khoa học", colSpan: 1},
              {content: this.handleNull(duLieu.nlKhoaHoc), colSpan: 1},
            ],
            [
              {content: "Công nghệ", colSpan: 1},
              {content: this.handleNull(duLieu.nlCongNghe), colSpan: 1},
            ],
            [
              {content: "Tin học", colSpan: 1},
              {content: this.handleNull(duLieu.nlTinHoc), colSpan: 1},
            ],
            [
              {content: "Thẩm mĩ", colSpan: 1},
              {content: this.handleNull(duLieu.nlThamMi), colSpan: 1},
            ],
            [
              {content: "Thể chất", colSpan: 1},
              {content: this.handleNull(duLieu.nlTheChat), colSpan: 1},
            ],
          ];
          columnStyles = {
            0: {
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellWidth: 60,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 2},
            },
            1: {
              halign: "center",
              fontSize: 12,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
              valign: "middle",
              font: "TimesBold",
              cellWidth: 30,
            },
            2: {
              halign: "left",
              fontSize: 12,
              cellPadding: {top: 4, right: 4, bottom: 4, left: 4},
              valign: "middle",
              cellWidth: 95,
            },
          };
          let startY_3 = doc.autoTable.previous.finalY + 9;
          const createTable = (startY) => {
            doc.autoTable({
              tableLineColor: [70, 76, 70],
              columnStyles: columnStyles,
              head: header,
              body: body,
              startY: startY,
              theme: "grid",
              headStyles: {
                fillColor: [255, 255, 255],
                textColor: [45, 45, 45],
                font: "TimesBold",
                fontStyle: "normal",
                lineWidth: 0.1,
                halign: "center",
                valign: "middle",
                fontSize: 12,
                cellPadding: 1,
                minCellHeight: 8,
              },
              styles: {
                textColor: [45, 45, 45],
                lineColor: [70, 76, 70],
                font: "TimesBold",
                fontStyle: "normal",
              },
              bodyStyles: {
                textColor: [45, 45, 45],
                lineColor: [70, 76, 70],
                font: "VNTimes",
                fontStyle: "normal",
                cellPadding: 2,
                fontSize: 12,
                minCellHeight: 8,
              },
              willDrawCell: (data) => {
                if (data.section === "body" && data.cell.rowSpan === 7) {
                  data.cell.text = this.adjustTextSpacing(data.cell.text, data.doc);
                }
              },
            });
          };
          createTable(startY_3);
          const endY_3 = doc.autoTable.previous.finalY;
          const tableHeight_3 = endY_3 - startY_3;
          const totalTableHeight = tableHeight_1 + tableHeight_2 + tableHeight_3;
          if (totalTableHeight > 155) {

            header = [];
            body = [];
            startY_3 = 10;
            doc.addPage();
            createTable(startY_3);
          } else {
            startY_3 = doc.autoTable.previous.finalY + 9;
          }
        } else {
          doc.setFont("TimesBold");
          let tenTruong = this.countCharacters(duLieu.tenTruong, "", 50);
          doc.text("Trường:", 15, tenTruong.length > 50 ? 12.5 : 15);
          doc.text(tenTruong, 35, tenTruong.length > 50 ? 10 : 15);
          doc.text("Năm học:", 150, tenTruong.length > 50 ? 12.5 : 15);
          let namHoc = duLieu.namHoc ? duLieu.namHoc.toString() : "";
          let convertNamHoc = `${namHoc}-${(parseInt(namHoc) + 1)?.toString()}`;
          doc.text(convertNamHoc, 170, tenTruong.length > 50 ? 12.5 : 15);
          doc.setFont("TimesBold");
          doc.setFontSize(13);
          doc.text("2. Các năng lực", 15, 20);
          let header = [
            [
              {content: "Năng lực", colSpan: 1},
              {content: "Mức đạt được", colSpan: 1},
              {
                content: "Nhận xét",
                colSpan: 1,
              },
            ],
          ];
          let body = [
            [
              {content: "Tự phục vụ, tự quản", colSpan: 1},
              {
                content: this.handleNull(duLieu.nlTpvtq),
                colSpan: 1,
              },
              {
                content: this.removeTabs(duLieu.nangLuc),
                rowSpan: 3,
              },
            ],
            [
              {content: "Hợp tác", colSpan: 1},
              {content: this.handleNull(duLieu.nlHt), colSpan: 1},
            ],
            [
              {content: "Tự học, giải quyết \n vấn đề", colSpan: 1},
              {content: this.handleNull(duLieu.nlThgqvd), colSpan: 1},
            ],
          ];
          let columnStyles = {
            0: {
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellWidth: 60,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 2},
            },
            1: {
              textAlign: "center",
              halign: "center",
              fontSize: 12,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
              valign: "middle",
              font: "TimesBold",
              cellWidth: 30,
            },
            2: {
              halign: "left",
              fontSize: 12,
              cellPadding: {top: 4, right: 4, bottom: 4, left: 4},
              valign: "middle",
              cellWidth: 95,
            },
          };
          const startY_1 = 23;
          doc.autoTable({
            tableLineColor: [70, 76, 70],
            columnStyles: columnStyles,
            head: header,
            body: body,
            startY: startY_1,
            theme: "grid",
            headStyles: {
              fillColor: [255, 255, 255],
              textColor: [45, 45, 45],
              font: "TimesBold",
              fontStyle: "normal",
              lineWidth: 0.1,
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellPadding: 1,
              minCellHeight: 8,
            },
            styles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "TimesBold",
              fontStyle: "normal",
            },
            bodyStyles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "VNTimes",
              fontStyle: "normal",
              cellPadding: 2,
              fontSize: 12,
              minCellHeight: 8,
            },
          });
          const endY1 = doc.autoTable.previous.finalY;
          const tableHeight_1 = endY1 - startY_1;
          doc.text("3. Các phẩm chất", 15, doc.autoTable.previous.finalY + 6);
          header = [
            [
              {content: "Phẩm chất", colSpan: 1},
              {content: "Mức đạt được", colSpan: 1},
              {
                content: "Nhận xét",
                colSpan: 1,
              },
            ],
          ];
          body = [
            [
              {content: "Chăm học, chăm làm", colSpan: 1},
              {
                content: this.handleNull(duLieu.pcChcl),
                colSpan: 1,
              },
              {
                content: this.removeTabs(duLieu.nxPhamChatChuYeu),
                rowSpan: 4,
              },
            ],
            [
              {content: "Tự tin trách nhiệm", colSpan: 1},
              {content: this.handleNull(duLieu.pcTttn), colSpan: 1},
            ],
            [
              {content: "Trung thực, kỷ luật", colSpan: 1},
              {content: this.handleNull(duLieu.pcTtkl), colSpan: 1},
            ],
            [
              {content: "Đoàn kết, yêu thương", colSpan: 1},
              {content: this.handleNull(duLieu.pcDkyt), colSpan: 1},
            ],
          ];
          columnStyles = {
            0: {
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellWidth: 60,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 2},
            },
            1: {
              halign: "center",
              fontSize: 12,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
              valign: "middle",
              font: "TimesBold",
              cellWidth: 30,
            },
            2: {
              halign: "left",
              fontSize: 12,
              cellPadding: {top: 4, right: 4, bottom: 4, left: 4},
              valign: "middle",
              cellWidth: 95,
            },
          };
          let startY_3 = doc.autoTable.previous.finalY + 9;
          const createTable = (startY) => {
            console.log("startY", startY);
            doc.autoTable({
              tableLineColor: [70, 76, 70],
              columnStyles: columnStyles,
              head: header,
              body: body,
              startY: startY,
              theme: "grid",
              headStyles: {
                fillColor: [255, 255, 255],
                textColor: [45, 45, 45],
                font: "TimesBold",
                fontStyle: "normal",
                lineWidth: 0.1,
                halign: "center",
                valign: "middle",
                fontSize: 12,
                cellPadding: 1,
                minCellHeight: 17,
              },
              styles: {
                textColor: [45, 45, 45],
                lineColor: [70, 76, 70],
                font: "TimesBold",
                fontStyle: "normal",
              },
              bodyStyles: {
                textColor: [45, 45, 45],
                lineColor: [70, 76, 70],
                font: "VNTimes",
                fontStyle: "normal",
                cellPadding: 2,
                fontSize: 12,
                minCellHeight: 17,
              },
            });
          };
          createTable(startY_3);
          const endY_3 = doc.autoTable.previous.finalY;
          const tableHeight_3 = endY_3 - startY_3;
          if (tableHeight_1 > 70 || tableHeight_3 > 115) {
            header = [];
            body = [];
            startY_3 = 10;
            doc.addPage();
            createTable(startY_3);
          } else {
            startY_3 = doc.autoTable.previous.finalY + 9;
          }
        }
        doc.setFontSize(13);
        doc.setFont("TimesBold");
        doc.text("4. Đánh giá kết quả giáo dục:", 15, doc.autoTable.previous.finalY + 6);
        doc.setFont("VNTimes");
        const danhGiaKetQuaGiaoDuc = this.handleNull(duLieu.danhGiaKetQuaGiaoDuc);
        doc.text(danhGiaKetQuaGiaoDuc, 75, doc.autoTable.previous.finalY + 6);
        doc.setFont("TimesBold");
        const khenThuong = this.handleNull(duLieu.noiDungKhenThuong);
        doc.text("5. Khen thưởng: ", 15, doc.autoTable.previous.finalY + 13);
        doc.setFont("VNTimes");
        doc.text(khenThuong, 15, doc.autoTable.previous.finalY + 18);
        doc.setFont("TimesBold");
        const titleContent = this.handleNull(duLieu.tieuDeKyThay);
        const newlineCount = titleContent.split("\n").length - 1;
        doc.text(
            "6. Hoàn thành chương trình lớp học/chương trình tiểu học:",
            15,
            newlineCount === 2
                ? doc.autoTable.previous.finalY + 30
                : doc.autoTable.previous.finalY + 35
        );
        doc.setFont("VNTimes");
        const hoanThanhChuongTrinh = this.countCharacters(
            duLieu.hoanThanhChuongTrinh ?? ""
        );
        doc.text(
            hoanThanhChuongTrinh,
            15,
            newlineCount === 2
                ? doc.autoTable.previous.finalY + 35
                : doc.autoTable.previous.finalY + 40
        );
        const kygiaContent = "(Ký và ghi rõ họ tên)";
        const kygiaWidth = doc.getTextWidth(kygiaContent);
        const kygiaX = 140;
        const kygiaY = doc.autoTable.previous.finalY + 55;
        doc.setFont("VNTimesItalic");
        doc.text(kygiaContent, kygiaX, kygiaY);
        const ngayHieuTruongKy = this.handleNull(duLieu.ngayGVCNKy);
        const ngayHieuTruongWidth = doc.getTextWidth(ngayHieuTruongKy);
        const ngayHieuTruongX = kygiaX + (kygiaWidth - ngayHieuTruongWidth) / 2;
        const ngayHieuTruongY = kygiaY - 10;
        doc.setFont("VNTimes");
        doc.text(ngayHieuTruongKy, ngayHieuTruongX, ngayHieuTruongY);
        const gvChuNhiemContent = "Giáo viên chủ nhiệm";
        const gvChuNhiemWidth = doc.getTextWidth(gvChuNhiemContent);
        const gvChuNhiemX = kygiaX + (kygiaWidth - gvChuNhiemWidth) / 2;
        const gvChuNhiemY = kygiaY - 5;
        doc.setFont("TimesBold");
        doc.text(gvChuNhiemContent, gvChuNhiemX, gvChuNhiemY);
        const tenGVCNContent = this.handleNull(duLieu?.tenGVCN);
        const tenGVCNWidth = doc.getTextWidth(tenGVCNContent);
        const tenGVCNX = kygiaX + (kygiaWidth - tenGVCNWidth) / 2;
        const tenGVCNY = gvChuNhiemY + 37;
        doc.setFont("TimesBold");
        doc.text(tenGVCNContent, tenGVCNX, tenGVCNY);
        const imageX = kygiaX + (kygiaWidth - this.imgSignatureWidth) / 2;
        const imageY = kygiaY + 3;
        console.error('Chữ ký gvcn')
        console.log(this.cauHinh)
        if (!this.cauHinh.isAnAnhGVCN) {
          console.log('Thêm ảnh chữ gvcn')
          doc.addImage(
              this.handleNull(duLieu.pathChuKyGVCN),
              "JPEG",
              imageX,
              imageY,
              this.imgSignatureWidth,
              this.imgSignatureHeight
          );
        }else{
          console.log('Không in chữ ký gvcn')
        }
        const imgX = 12;
        const imgY = doc.autoTable.previous.finalY + 45;
        const imgWidth = this.imgStampWidth;
        const imgHeight = this.imgStampHeight;
        if (!this.cauHinh.isDau) {
          doc.addImage(
              this.handleNull(duLieu.pathDau),
              "JPEG",
              imgX,
              imgY,
              imgWidth,
              imgHeight
          );
        }
        const imgWidth2 = 30;
        const imgHeight2 = 20;
        const imgX2 = imgX + imgWidth / 2 + 10;
        const imgY2 = imgY + (imgHeight - imgHeight2) / 2 + 5;
        if (!this.cauHinh.isAnAnhHieuTruong) {
          doc.addImage(
              this.handleNull(duLieu.pathChuKyHieuTruong),
              "JPEG",
              imgX2,
              imgY2,
              imgWidth2,
              imgHeight2
          );
        }

        const signatureContent = "(Ký ghi rõ họ tên và đóng dấu)";
        const signatureWidth = doc.getTextWidth(signatureContent);
        const signatureX = 25;
        const signatureY = doc.autoTable.previous.finalY + 55;
        doc.setFont("VNTimesItalic");
        doc.text(signatureContent, signatureX, signatureY);
        const titleWidth = doc.getTextWidth(titleContent);
        const adjustments = {
          0: {x: 0, y: -5},
          1: {x: 18, y: -10},
          2: {x: 45, y: -15},
        };
        const adjustment = adjustments[newlineCount] || adjustments[0];
        const titleX = signatureX + (signatureWidth - titleWidth) / 2 + adjustment.x;
        const titleY = signatureY + adjustment.y;
        doc.setFont("TimesBold");
        doc.text(titleContent, titleX, titleY);
        const headmasterNameContent = this.handleNull(duLieu.tenHieuTruong);
        const headmasterNameWidth = doc.getTextWidth(headmasterNameContent);
        const headmasterNameX = signatureX + (signatureWidth - headmasterNameWidth) / 2;
        const headmasterNameY = signatureY + 32;
        doc.setFont("TimesBold");
        doc.text(headmasterNameContent, headmasterNameX, headmasterNameY);
      }
      else if (pageNumber == 6) {
        if (this.cauHinh.isAnSoTrang != -1) {
          doc.setFont("VNTimes");
          let textHocBa1 = String(this.cauHinh.isAnSoTrang + pageNumber - 4);
          console.log('textHocBa1')
          console.log(textHocBa1)
          let fontSize1 = 13;
          let textWidth1 =
              (doc.getStringUnitWidth(textHocBa1) * fontSize1) / doc.internal.scaleFactor;
          doc.setFontSize(fontSize1);
          doc.text(textHocBa1, (pageWidth - textWidth1) / 2, 295);
        }
        doc.setLineWidth(0.3);
        doc.text("Họ và tên học sinh:", 14, 15);
        doc.setFont("TimesBold");
        const hoten = this.handleNull(duLieu.hoten);
        doc.text(hoten, 53, 15);
        doc.setFont("TimesBold");
        doc.text("Lớp:", 150, 15);
        doc.setFont("TimesBold");
        const tenLop = this.handleNull(duLieu.tenLop);
        doc.text(tenLop, 170, 15);
        doc.setFont("VNTimes");
        doc.text("Chiều cao:", 14, 25);
        doc.text(duLieu.chieuCao ? `${duLieu.chieuCao} cm ` : "", 34, 25);
        doc.setFont("VNTimes");
        doc.text("Cân nặng:", 120, 25);
        doc.setFont("VNTimes");
        doc.text(duLieu.canNang ? `${duLieu.canNang} kg` : "", 140, 25);
        doc.setFont("VNTimes");
        doc.text("Số ngày nghỉ có phép:", 14, 35);
        const soNgayNghiCoPhep = duLieu.soNgayNghiCoPhep
            ? duLieu.soNgayNghiCoPhep.toString()
            : "0";
        doc.text(soNgayNghiCoPhep, 56, 35);
        doc.setFont("VNTimes");
        doc.text("Số ngày nghỉ không phép:", 120, 35);
        doc.setFont("VNTimes");
        const soNgayNghiKhongPhep = duLieu.soNgayNghiKhongPhep
            ? duLieu.soNgayNghiKhongPhep.toString()
            : "0";
        doc.text(soNgayNghiKhongPhep, 170, 35);
        doc.setFont("TimesBold");
        doc.setFontSize(13);
        doc.text("1. Các môn học và hoạt động giáo dục", 14, 45);
        let header = [
          [
            {content: "Môn học và hoạt động giáo dục", colSpan: 1},
            {
              content: "Mức đạt \nđược",
              colSpan: 1,
            },
            {textAlign: "center", content: "Điểm \nKTĐK", colSpan: 1},
            {content: "Nhận xét", colSpan: 1},
          ],
        ];
        let body = [];
        isThongTu27
            ? (body = [
              [
                {content: "Tiếng Việt", colSpan: 1},
                {
                  content: this.handleNull(duLieu.tvDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.tvDiem), colSpan: 1},
                {
                  content: this.handleNull(this.cauHinh.gopNhanXet ? duLieu.nhanXet : duLieu.tvNx),
                  rowSpan: this.cauHinh.gopNhanXet ? 14 : 1,
                },
              ],
              [
                {content: "Toán", colSpan: 1},
                {
                  content: this.handleNull(duLieu.toDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.toDiem), colSpan: 1},
                {content: this.handleNull(duLieu.toNx), colSpan: 1},
              ],
              [
                {content: "Ngoại ngữ", colSpan: 1},
                {
                  content: this.handleNull(duLieu.nnDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.nnDiem), colSpan: 1},
                {content: this.handleNull(duLieu.nnNx), colSpan: 1},
              ],
              [
                {content: "Lịch sử và Địa lý", colSpan: 1},
                {
                  content: this.handleNull(duLieu.ldDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.ldDiem), colSpan: 1},
                {content: this.handleNull(duLieu.ldNx), colSpan: 1},
              ],
              [
                {content: "Khoa học", colSpan: 1},
                {
                  content: this.handleNull(duLieu.khDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.khDiem), colSpan: 1},
                {content: this.handleNull(duLieu.khNx), colSpan: 1},
              ],
              [
                {content: "Tin học và Công nghệ (Công nghệ)", colSpan: 1},
                {
                  content: this.handleNull(duLieu.cnDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.cnDiem), colSpan: 1},
                {content: this.handleNull(duLieu.cnNx), colSpan: 1},
              ],
              [
                {
                  content: "Tin học và Công nghệ (Tin học)",
                  colSpan: 1,
                },
                {
                  content: this.handleNull(duLieu.thDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.thDiem), colSpan: 1},
                {content: this.handleNull(duLieu.thNx), colSpan: 1},
              ],
              [
                {content: "Đạo đức", colSpan: 1},
                {content: this.handleNull(duLieu.ddDgtx), colSpan: 1},
                {
                  content: "",
                  rowSpan: 7,
                },
                {content: this.handleNull(duLieu.ddNx), colSpan: 1},
              ],
              [
                {content: "Tự nhiên và Xã hội", colSpan: 1},
                {content: this.handleNull(duLieu.txDgtx), colSpan: 1},
                {content: this.handleNull(duLieu.txNx), colSpan: 1,},
              ],
              [
                {content: "Giáo dục thể chất", colSpan: 1},
                {content: this.handleNull(duLieu.tdDgtx), colSpan: 1},
                {content: this.handleNull(duLieu.tdNx), colSpan: 1,},
              ],
              [
                {content: "Nghệ thuật (Âm nhạc)", colSpan: 1},
                {content: this.handleNull(duLieu.anDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.anNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Nghệ thuật (Mĩ thuật)", colSpan: 1},
                {content: this.handleNull(duLieu.mtDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.mtNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Hoạt động trải nghiệm", colSpan: 1},
                {
                  content: this.handleNull(duLieu.hdtnDgtx),
                  colSpan: 1,
                },
                {
                  content: this.handleNull(duLieu.hdtnNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Tiếng dân tộc", colSpan: 1},
                {content: this.handleNull(duLieu.dtDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.dtNx),
                  colSpan: 1,
                },
              ],
            ])
            : (body = [
              [
                {content: "Tiếng Việt", colSpan: 1},
                {
                  content: this.handleNull(duLieu.tvDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.tvDiem), colSpan: 1},
                {
                  content: this.handleNull(duLieu.tvNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Toán", colSpan: 1},
                {
                  content: this.handleNull(duLieu.toDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.toDiem), colSpan: 1},
                {
                  content: this.handleNull(duLieu.toNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Khoa học", colSpan: 1},
                {
                  content: this.handleNull(duLieu.khDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.khDiem), colSpan: 1},
                {
                  content: this.handleNull(duLieu.khNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Lịch sử và Địa lý", colSpan: 1},
                {
                  content: this.handleNull(duLieu.ldDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.ldDiem), colSpan: 1},
                {
                  content: this.handleNull(duLieu.ldNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Ngoại ngữ", colSpan: 1},
                {
                  content: this.handleNull(duLieu.nnDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.nnDiem), colSpan: 1},
                {
                  content: this.handleNull(duLieu.nnNx),
                  colSpan: 1,
                },
              ],
              [
                {
                  content: "Tin học",
                  colSpan: 1,
                },
                {
                  content: this.handleNull(duLieu.thDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.thDiem), colSpan: 1},
                {
                  content: this.handleNull(duLieu.thNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Tiếng dân tộc", colSpan: 1},
                {content: this.handleNull(duLieu.dtDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.dtNx),
                  colSpan: 1,
                },
                {
                  content: "",
                  colSpan: 1,
                },
              ],
              [
                {content: "Đạo đức", colSpan: 1},
                {content: this.handleNull(duLieu.ddDgtx), colSpan: 1},
                {
                  content: "",
                  rowSpan: 7,
                },
                {
                  content: this.handleNull(duLieu.ddNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Âm nhạc", colSpan: 1},
                {content: this.handleNull(duLieu.anDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.anNx),
                  colSpan: 1,
                },
                {
                  content: "",
                  colSpan: 1,
                },
              ],
              [
                {content: "Mĩ thuật", colSpan: 1},
                {content: this.handleNull(duLieu.mtDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.mtNx),
                  colSpan: 1,
                },
                {
                  content: "",
                  colSpan: 1,
                },
              ],
              [
                {content: "Kỹ thuật", colSpan: 1},
                {content: this.handleNull(duLieu.ktDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.ktNx),
                  colSpan: 1,
                },
                {
                  content: "",
                  colSpan: 1,
                },
              ],
              [
                {content: "Thể dục", colSpan: 1},
                {content: this.handleNull(duLieu.tdDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.tdNx),
                  colSpan: 1,
                },
                {
                  content: "",
                  colSpan: 1,
                },
              ],
            ]);
        body = body;
        let columnStyles = {
          0: {
            halign: "center",
            valign: "middle",
            fontSize: 12,
            cellWidth: 50,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 2},
          },
          1: {
            halign: "center",
            textAlign: "center",
            fontSize: 12,
            cellPadding: {top: 4, right: 4, bottom: 4, left: 4},
            valign: "middle",
            font: "TimesBold",
            cellWidth: 21.5,
          },
          2: {
            textAlign: "center",
            halign: "center",
            fontSize: 12,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            font: "TimesBold",
            cellWidth: 21.5,
          },
          3: {
            textAlign: "center",
            halign: "left",
            fontSize: 12,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 4},
            valign: "top",
            cellWidth: 91.5,
          },
        };
        doc.autoTable({
          tableLineColor: [70, 76, 70],
          columnStyles: columnStyles,
          head: header,
          body: body,
          startY: 50,
          didDrawCell: function (data) {
            if (data.row.index === 7 && data.column.index === 2) {
              const {doc, cell} = data;
              const startX = cell.x + cell.width;
              const startY = cell.y;
              const endX = cell.x;
              const endY = cell.y + cell.height;
              doc.setLineWidth(0.3);
              doc.line(startX, startY, endX, endY);
            }
          },
          willDrawCell: (data) => {
            if (data.section === "body") {
              const textLines = data.cell.text;
              const increaseSpace = (text, widthDifference) => {
                const words = text.split(" ");
                const targetSpace = 2;
                let splitIndex;
                const conditionMap = {
                  "1-3": Math.floor(words.length / 4.65),
                  "3-8": Math.floor(words.length / 2.45),
                  "8-16": Math.floor((words.length * 4) / 5),
                };
                const conditionKeys = Object.keys(conditionMap);
                const matchedKey = conditionKeys.find((key) => {
                  const [min, max] = key.split("-").map(Number);
                  return widthDifference > min && widthDifference < max;
                });
                splitIndex = matchedKey ? conditionMap[matchedKey] : splitIndex;
                for (let i = 0; i < splitIndex; i++) {
                  words[i] += " ".repeat(targetSpace - 1);
                }
                return words.join(" ");
              };
              const doc = data.doc;
              const convertLines = textLines.map((line, index) => {
                const lineWidth = doc.getTextWidth(line);
                return {line, lineWidth, index};
              });
              const maxWidth = Math.max(...convertLines.map((line) => line.lineWidth));
              let newLinesArray = [];
              convertLines.forEach(({line, lineWidth}) => {
                if (lineWidth > 0) {
                  const widthDifference = maxWidth - lineWidth;
                  line = increaseSpace(line, widthDifference);
                }
                newLinesArray.push(line);
              });
              data.cell.text = newLinesArray;
            }
          },
          includeHiddenHtml: true,
          theme: "grid",
          headStyles: {
            fillColor: [255, 255, 255],
            textColor: [45, 45, 45],
            font: "TimesBold",
            fontStyle: "normal",
            lineWidth: 0.1,
            halign: "center",
            valign: "middle",
            fontSize: 12,
            cellPadding: 1,
          },
          styles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: "TimesBold",
            fontStyle: "normal",
            lineHeight: 1.5,
          },
          bodyStyles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: "VNTimes",
            fontStyle: "normal",
            cellPadding: 2.5,
            minCellHeight: 15,
          },
        });
      } else if (pageNumber == 7) {
        if (this.cauHinh.isAnSoTrang != -1) {
          doc.setFont("VNTimes");
          let textHocBa1 = String(this.cauHinh.isAnSoTrang + pageNumber - 4);
          console.log('textHocBa1')
          console.log(textHocBa1)
          let fontSize1 = 13;
          let textWidth1 =
              (doc.getStringUnitWidth(textHocBa1) * fontSize1) / doc.internal.scaleFactor;
          doc.setFontSize(fontSize1);
          doc.text(textHocBa1, (pageWidth - textWidth1) / 2, 295);
        }
        if (isThongTu27) {
          doc.setLineWidth(0.3);
          doc.setFont("TimesBold");
          doc.text("Trường:", 15, 10);
          let tenTruong = this.countCharacters(duLieu.tenTruong, "", 50);
          const hoten = this.handleNull(duLieu.hoten);
          doc.text(tenTruong, 35, 10);
          doc.text("Năm học:", 158, 10);
          const tenLop = this.handleNull(duLieu.tenLop);
          let namHoc = duLieu.namHoc ? duLieu.namHoc.toString() : "";
          let convertNamHoc = `${namHoc}-${(parseInt(namHoc) + 1)?.toString()}`;
          doc.text(convertNamHoc, 178, 10);
          doc.setFont("TimesBold");
          doc.setFontSize(13);
          doc.text("2. Những phẩm chất chủ yếu", 15, 20);
          let header = [
            [
              {content: "Phẩm chất", colSpan: 1},
              {content: "Mức đạt được", colSpan: 1},
              {
                content: "Nhận xét",
                colSpan: 1,
              },
            ],
          ];
          let body = [
            [
              {content: "Yêu nước", colSpan: 1},
              {
                content: this.handleNull(duLieu.pcYeuNuoc),
                colSpan: 1,
              },
              {
                content: this.removeTabs(duLieu.nxPhamChatChuYeu ? duLieu.nxPhamChatChuYeu.replace(/\r\n\r\n/g, '\r\n') : ''),
                rowSpan: 5,
              },
            ],
            [
              {content: "Nhân ái", colSpan: 1},
              {content: this.handleNull(duLieu.pcNhanAi), colSpan: 1},
            ],
            [
              {content: "Chăm chỉ", colSpan: 1},
              {content: this.handleNull(duLieu.pcChamChi), colSpan: 1},
            ],
            [
              {content: "Trung thực", colSpan: 1},
              {content: this.handleNull(duLieu.pcTrungThuc), colSpan: 1},
            ],
            [
              {content: "Trách nhiệm", colSpan: 1},
              {content: this.handleNull(duLieu.pcTrachNhiem), colSpan: 1},
            ],
          ];
          let columnStyles = {
            0: {
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellWidth: 60,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 2},
            },
            1: {
              textAlign: "center",
              halign: "center",
              fontSize: 12,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
              valign: "middle",
              font: "TimesBold",
              cellWidth: 30,
            },
            2: {
              halign: "left",
              fontSize: 12,
              cellPadding: {top: 4, right: 4, bottom: 4, left: 4},
              valign: "middle",
              cellWidth: 95,
            },
          };
          const startY_1 = 23;
          doc.autoTable({
            tableLineColor: [70, 76, 70],
            columnStyles: columnStyles,
            head: header,
            body: body,
            startY: startY_1,
            theme: "grid",
            headStyles: {
              fillColor: [255, 255, 255],
              textColor: [45, 45, 45],
              font: "TimesBold",
              fontStyle: "normal",
              lineWidth: 0.1,
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellPadding: 1,
              minCellHeight: 8,
            },
            styles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "TimesBold",
              fontStyle: "normal",
            },
            bodyStyles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "VNTimes",
              fontStyle: "normal",
              cellPadding: 2,
              fontSize: 12,
              minCellHeight: 8,
            },
            willDrawCell: (data) => {
              if (data.section === "body" && data.cell.rowSpan === 5) {
                data.cell.text = this.adjustTextSpacing(data.cell.text, data.doc);
              }
            },
          });
          const endY1 = doc.autoTable.previous.finalY;
          const tableHeight_1 = endY1 - startY_1;
          doc.setFont("TimesBold");
          doc.setFontSize(13);
          doc.text(
              "3. Những năng lực cốt lõi",
              15,
              doc.autoTable.previous.finalY + 6
          );
          doc.setFont("VNTimesItalic");
          doc.text("3.1. Những năng lực chung", 15, doc.autoTable.previous.finalY + 13);
          header = [
            [
              {content: "Năng lực", colSpan: 1},
              {content: "Mức đạt được", colSpan: 1},
              {
                content: "Nhận xét",
                colSpan: 1,
              },
            ],
          ];
          body = [
            [
              {content: "Tự chủ và tự học", colSpan: 1},
              {
                content: this.handleNull(duLieu.nlTuChuTuHoc),
                colSpan: 1,
              },
              {
                content: this.removeTabs(duLieu.nxNangLucChung ? duLieu.nxNangLucChung.replace(/\r\n\r\n/g, '\r\n') : ''),
                rowSpan: 3,
              },
            ],
            [
              {
                content: "Giao tiếp và hợp tác",
                colSpan: 1,
              },
              {
                content: this.handleNull(duLieu.nlGiaoTiepHopTac),
                colSpan: 1,
              },
            ],
            [
              {
                content: "Giải quyết vấn đề và sáng tạo",
                colSpan: 1,
              },
              {
                content: this.handleNull(duLieu.nlGquyetVdeSangTao),
                colSpan: 1,
              },
            ],
          ];
          columnStyles = {
            0: {
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellWidth: 60,
              cellPadding: {top: 3, right: 3, bottom: 3, left: 3},
            },
            1: {
              halign: "center",
              fontSize: 12,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
              valign: "middle",
              cellWidth: 30,
              font: "TimesBold",
            },
            2: {
              halign: "left",
              fontSize: 12,
              cellPadding: {top: 4, right: 4, bottom: 4, left: 4},
              valign: "middle",
              cellWidth: 95,
            },
          };
          const startY_2 = doc.autoTable.previous.finalY + 17;
          doc.autoTable({
            tableLineColor: [70, 76, 70],
            columnStyles: columnStyles,
            head: header,
            body: body,
            startY: startY_2,
            theme: "grid",
            headStyles: {
              fillColor: [255, 255, 255],
              textColor: [45, 45, 45],
              font: "TimesBold",
              fontStyle: "normal",
              lineWidth: 0.1,
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellPadding: 1,
            },
            styles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "TimesBold",
              fontStyle: "normal",
            },
            bodyStyles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "VNTimes",
              fontStyle: "normal",
              cellPadding: 2,
              fontSize: 12,
            },
            willDrawCell: (data) => {
              if (data.section === "body" && data.cell.rowSpan === 3) {
                data.cell.text = this.adjustTextSpacing(data.cell.text, data.doc);
              }
            },
          });
          const endY_2 = doc.autoTable.previous.finalY;
          const tableHeight_2 = endY_2 - startY_2;
          doc.setFont("VNTimesItalic");
          doc.text("3.2. Những năng lực đặc thù", 15, doc.autoTable.previous.finalY + 6);
          header = [
            [
              {content: "Năng lực", colSpan: 1},
              {content: "Mức đạt được", colSpan: 1},
              {
                content: "Nhận xét",
                colSpan: 1,
              },
            ],
          ];
          body = [
            [
              {content: "Ngôn ngữ", colSpan: 1},
              {
                content: this.handleNull(duLieu.nlNgonNgu),
                colSpan: 1,
              },
              {content: this.removeTabs(duLieu.nxNangLucDacThu), rowSpan: 7},
            ],
            [
              {content: "Tính toán", colSpan: 1},
              {content: this.handleNull(duLieu.nlTinhToan), colSpan: 1},
            ],
            [
              {content: "Khoa học", colSpan: 1},
              {content: this.handleNull(duLieu.nlKhoaHoc), colSpan: 1},
            ],
            [
              {content: "Công nghệ", colSpan: 1},
              {content: this.handleNull(duLieu.nlCongNghe), colSpan: 1},
            ],
            [
              {content: "Tin học", colSpan: 1},
              {content: this.handleNull(duLieu.nlTinHoc), colSpan: 1},
            ],
            [
              {content: "Thẩm mĩ", colSpan: 1},
              {content: this.handleNull(duLieu.nlThamMi), colSpan: 1},
            ],
            [
              {content: "Thể chất", colSpan: 1},
              {content: this.handleNull(duLieu.nlTheChat), colSpan: 1},
            ],
          ];
          columnStyles = {
            0: {
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellWidth: 60,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 2},
            },
            1: {
              halign: "center",
              fontSize: 12,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
              valign: "middle",
              font: "TimesBold",
              cellWidth: 30,
            },
            2: {
              halign: "left",
              fontSize: 12,
              cellPadding: {top: 4, right: 4, bottom: 4, left: 4},
              valign: "middle",
              cellWidth: 95,
            },
          };
          let startY_3 = doc.autoTable.previous.finalY + 9;
          const createTable = (startY) => {
            doc.autoTable({
              tableLineColor: [70, 76, 70],
              columnStyles: columnStyles,
              head: header,
              body: body,
              startY: startY,
              theme: "grid",
              headStyles: {
                fillColor: [255, 255, 255],
                textColor: [45, 45, 45],
                font: "TimesBold",
                fontStyle: "normal",
                lineWidth: 0.1,
                halign: "center",
                valign: "middle",
                fontSize: 12,
                cellPadding: 1,
                minCellHeight: 8,
              },
              styles: {
                textColor: [45, 45, 45],
                lineColor: [70, 76, 70],
                font: "TimesBold",
                fontStyle: "normal",
              },
              bodyStyles: {
                textColor: [45, 45, 45],
                lineColor: [70, 76, 70],
                font: "VNTimes",
                fontStyle: "normal",
                cellPadding: 2,
                fontSize: 12,
                minCellHeight: 8,
              },
              willDrawCell: (data) => {
                if (data.section === "body" && data.cell.rowSpan === 7) {
                  data.cell.text = this.adjustTextSpacing(data.cell.text, data.doc);
                }
              },
            });
          };
          createTable(startY_3);
          const endY_3 = doc.autoTable.previous.finalY;
          const tableHeight_3 = endY_3 - startY_3;
          const totalTableHeight = tableHeight_1 + tableHeight_2 + tableHeight_3;
          if (totalTableHeight > 155) {

            header = [];
            body = [];
            startY_3 = 10;
            doc.addPage();
            createTable(startY_3);
          } else {
            startY_3 = doc.autoTable.previous.finalY + 9;
          }
        } else {
          doc.setFont("TimesBold");
          let tenTruong = this.countCharacters(duLieu.tenTruong, "", 50);
          doc.text("Trường:", 15, tenTruong.length > 50 ? 12.5 : 15);
          doc.text(tenTruong, 35, tenTruong.length > 50 ? 10 : 15);
          doc.text("Năm học:", 150, tenTruong.length > 50 ? 12.5 : 15);
          let namHoc = duLieu.namHoc ? duLieu.namHoc.toString() : "";
          let convertNamHoc = `${namHoc}-${(parseInt(namHoc) + 1)?.toString()}`;
          doc.text(convertNamHoc, 170, tenTruong.length > 50 ? 12.5 : 15);
          doc.setFont("TimesBold");
          doc.setFontSize(13);
          doc.text("2. Các năng lực", 15, 20);
          let header = [
            [
              {content: "Năng lực", colSpan: 1},
              {content: "Mức đạt được", colSpan: 1},
              {
                content: "Nhận xét",
                colSpan: 1,
              },
            ],
          ];
          let body = [
            [
              {content: "Tự phục vụ, tự quản", colSpan: 1},
              {
                content: this.handleNull(duLieu.nlTpvtq),
                colSpan: 1,
              },
              {
                content: this.removeTabs(duLieu.nangLuc),
                rowSpan: 3,
              },
            ],
            [
              {content: "Hợp tác", colSpan: 1},
              {content: this.handleNull(duLieu.nlHt), colSpan: 1},
            ],
            [
              {content: "Tự học, giải quyết \n vấn đề", colSpan: 1},
              {content: this.handleNull(duLieu.nlThgqvd), colSpan: 1},
            ],
          ];
          let columnStyles = {
            0: {
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellWidth: 60,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 2},
            },
            1: {
              textAlign: "center",
              halign: "center",
              fontSize: 12,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
              valign: "middle",
              font: "TimesBold",
              cellWidth: 30,
            },
            2: {
              halign: "left",
              fontSize: 12,
              cellPadding: {top: 4, right: 4, bottom: 4, left: 4},
              valign: "middle",
              cellWidth: 95,
            },
          };
          const startY_1 = 23;
          doc.autoTable({
            tableLineColor: [70, 76, 70],
            columnStyles: columnStyles,
            head: header,
            body: body,
            startY: startY_1,
            theme: "grid",
            headStyles: {
              fillColor: [255, 255, 255],
              textColor: [45, 45, 45],
              font: "TimesBold",
              fontStyle: "normal",
              lineWidth: 0.1,
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellPadding: 1,
              minCellHeight: 8,
            },
            styles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "TimesBold",
              fontStyle: "normal",
            },
            bodyStyles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "VNTimes",
              fontStyle: "normal",
              cellPadding: 2,
              fontSize: 12,
              minCellHeight: 8,
            },
          });
          const endY1 = doc.autoTable.previous.finalY;
          const tableHeight_1 = endY1 - startY_1;
          doc.text("3. Các phẩm chất", 15, doc.autoTable.previous.finalY + 6);
          header = [
            [
              {content: "Phẩm chất", colSpan: 1},
              {content: "Mức đạt được", colSpan: 1},
              {
                content: "Nhận xét",
                colSpan: 1,
              },
            ],
          ];
          body = [
            [
              {content: "Chăm học, chăm làm", colSpan: 1},
              {
                content: this.handleNull(duLieu.pcChcl),
                colSpan: 1,
              },
              {
                content: this.removeTabs(duLieu.nxPhamChatChuYeu),
                rowSpan: 4,
              },
            ],
            [
              {content: "Tự tin trách nhiệm", colSpan: 1},
              {content: this.handleNull(duLieu.pcTttn), colSpan: 1},
            ],
            [
              {content: "Trung thực, kỷ luật", colSpan: 1},
              {content: this.handleNull(duLieu.pcTtkl), colSpan: 1},
            ],
            [
              {content: "Đoàn kết, yêu thương", colSpan: 1},
              {content: this.handleNull(duLieu.pcDkyt), colSpan: 1},
            ],
          ];
          columnStyles = {
            0: {
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellWidth: 60,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 2},
            },
            1: {
              halign: "center",
              fontSize: 12,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
              valign: "middle",
              font: "TimesBold",
              cellWidth: 30,
            },
            2: {
              halign: "left",
              fontSize: 12,
              cellPadding: {top: 4, right: 4, bottom: 4, left: 4},
              valign: "middle",
              cellWidth: 95,
            },
          };
          let startY_3 = doc.autoTable.previous.finalY + 9;
          const createTable = (startY) => {
            console.log("startY", startY);
            doc.autoTable({
              tableLineColor: [70, 76, 70],
              columnStyles: columnStyles,
              head: header,
              body: body,
              startY: startY,
              theme: "grid",
              headStyles: {
                fillColor: [255, 255, 255],
                textColor: [45, 45, 45],
                font: "TimesBold",
                fontStyle: "normal",
                lineWidth: 0.1,
                halign: "center",
                valign: "middle",
                fontSize: 12,
                cellPadding: 1,
                minCellHeight: 17,
              },
              styles: {
                textColor: [45, 45, 45],
                lineColor: [70, 76, 70],
                font: "TimesBold",
                fontStyle: "normal",
              },
              bodyStyles: {
                textColor: [45, 45, 45],
                lineColor: [70, 76, 70],
                font: "VNTimes",
                fontStyle: "normal",
                cellPadding: 2,
                fontSize: 12,
                minCellHeight: 17,
              },
            });
          };
          createTable(startY_3);
          const endY_3 = doc.autoTable.previous.finalY;
          const tableHeight_3 = endY_3 - startY_3;
          if (tableHeight_1 > 70 || tableHeight_3 > 115) {
            header = [];
            body = [];
            startY_3 = 10;
            doc.addPage();
            createTable(startY_3);
          } else {
            startY_3 = doc.autoTable.previous.finalY + 9;
          }
        }
        doc.setFontSize(13);
        doc.setFont("TimesBold");
        doc.text("4. Đánh giá kết quả giáo dục:", 15, doc.autoTable.previous.finalY + 6);
        doc.setFont("VNTimes");
        const danhGiaKetQuaGiaoDuc = this.handleNull(duLieu.danhGiaKetQuaGiaoDuc);
        doc.text(danhGiaKetQuaGiaoDuc, 75, doc.autoTable.previous.finalY + 6);
        doc.setFont("TimesBold");
        const khenThuong = this.handleNull(duLieu.noiDungKhenThuong);
        doc.text("5. Khen thưởng: ", 15, doc.autoTable.previous.finalY + 13);
        doc.setFont("VNTimes");
        doc.text(khenThuong, 15, doc.autoTable.previous.finalY + 18);
        doc.setFont("TimesBold");
        const titleContent = this.handleNull(duLieu.tieuDeKyThay);
        const newlineCount = titleContent.split("\n").length - 1;
        doc.text(
            "6. Hoàn thành chương trình lớp học/chương trình tiểu học:",
            15,
            newlineCount === 2
                ? doc.autoTable.previous.finalY + 30
                : doc.autoTable.previous.finalY + 35
        );
        doc.setFont("VNTimes");
        const hoanThanhChuongTrinh = this.countCharacters(
            duLieu.hoanThanhChuongTrinh ?? ""
        );
        doc.text(
            hoanThanhChuongTrinh,
            15,
            newlineCount === 2
                ? doc.autoTable.previous.finalY + 35
                : doc.autoTable.previous.finalY + 40
        );
        const kygiaContent = "(Ký và ghi rõ họ tên)";
        const kygiaWidth = doc.getTextWidth(kygiaContent);
        const kygiaX = 140;
        const kygiaY = doc.autoTable.previous.finalY + 55;
        doc.setFont("VNTimesItalic");
        doc.text(kygiaContent, kygiaX, kygiaY);
        const ngayHieuTruongKy = this.handleNull(duLieu.ngayGVCNKy);
        const ngayHieuTruongWidth = doc.getTextWidth(ngayHieuTruongKy);
        const ngayHieuTruongX = kygiaX + (kygiaWidth - ngayHieuTruongWidth) / 2;
        const ngayHieuTruongY = kygiaY - 10;
        doc.setFont("VNTimes");
        doc.text(ngayHieuTruongKy, ngayHieuTruongX, ngayHieuTruongY);
        const gvChuNhiemContent = "Giáo viên chủ nhiệm";
        const gvChuNhiemWidth = doc.getTextWidth(gvChuNhiemContent);
        const gvChuNhiemX = kygiaX + (kygiaWidth - gvChuNhiemWidth) / 2;
        const gvChuNhiemY = kygiaY - 5;
        doc.setFont("TimesBold");
        doc.text(gvChuNhiemContent, gvChuNhiemX, gvChuNhiemY);
        const tenGVCNContent = this.handleNull(duLieu?.tenGVCN);
        const tenGVCNWidth = doc.getTextWidth(tenGVCNContent);
        const tenGVCNX = kygiaX + (kygiaWidth - tenGVCNWidth) / 2;
        const tenGVCNY = gvChuNhiemY + 37;
        doc.setFont("TimesBold");
        doc.text(tenGVCNContent, tenGVCNX, tenGVCNY);
        const imageX = kygiaX + (kygiaWidth - this.imgSignatureWidth) / 2;
        const imageY = kygiaY + 3;
        console.error('Chữ ký gvcn')
        console.log(this.cauHinh)
        if (!this.cauHinh.isAnAnhGVCN) {
          console.log('Thêm ảnh chữ gvcn')
          doc.addImage(
              this.handleNull(duLieu.pathChuKyGVCN),
              "JPEG",
              imageX,
              imageY,
              this.imgSignatureWidth,
              this.imgSignatureHeight
          );
        }else{
          console.log('Không in chữ ký gvcn')
        }
        const imgX = 12;
        const imgY = doc.autoTable.previous.finalY + 45;
        const imgWidth = this.imgStampWidth;
        const imgHeight = this.imgStampHeight;
        if (!this.cauHinh.isDau) {
          doc.addImage(
              this.handleNull(duLieu.pathDau),
              "JPEG",
              imgX,
              imgY,
              imgWidth,
              imgHeight
          );
        }
        const imgWidth2 = 30;
        const imgHeight2 = 20;
        const imgX2 = imgX + imgWidth / 2 + 10;
        const imgY2 = imgY + (imgHeight - imgHeight2) / 2 + 5;
        if (!this.cauHinh.isAnAnhHieuTruong) {
          doc.addImage(
              this.handleNull(duLieu.pathChuKyHieuTruong),
              "JPEG",
              imgX2,
              imgY2,
              imgWidth2,
              imgHeight2
          );
        }

        const signatureContent = "(Ký ghi rõ họ tên và đóng dấu)";
        const signatureWidth = doc.getTextWidth(signatureContent);
        const signatureX = 25;
        const signatureY = doc.autoTable.previous.finalY + 55;
        doc.setFont("VNTimesItalic");
        doc.text(signatureContent, signatureX, signatureY);
        const titleWidth = doc.getTextWidth(titleContent);
        const adjustments = {
          0: {x: 0, y: -5},
          1: {x: 18, y: -10},
          2: {x: 45, y: -15},
        };
        const adjustment = adjustments[newlineCount] || adjustments[0];
        const titleX = signatureX + (signatureWidth - titleWidth) / 2 + adjustment.x;
        const titleY = signatureY + adjustment.y;
        doc.setFont("TimesBold");
        doc.text(titleContent, titleX, titleY);
        const headmasterNameContent = this.handleNull(duLieu.tenHieuTruong);
        const headmasterNameWidth = doc.getTextWidth(headmasterNameContent);
        const headmasterNameX = signatureX + (signatureWidth - headmasterNameWidth) / 2;
        const headmasterNameY = signatureY + 32;
        doc.setFont("TimesBold");
        doc.text(headmasterNameContent, headmasterNameX, headmasterNameY);
      } else if (pageNumber == 8) {
        if (this.cauHinh.isAnSoTrang != -1) {
          doc.setFont("VNTimes");
          let textHocBa1 = String(this.cauHinh.isAnSoTrang + pageNumber - 4);
          console.log('textHocBa1')
          console.log(textHocBa1)
          let fontSize1 = 13;
          let textWidth1 =
              (doc.getStringUnitWidth(textHocBa1) * fontSize1) / doc.internal.scaleFactor;
          doc.setFontSize(fontSize1);
          doc.text(textHocBa1, (pageWidth - textWidth1) / 2, 295);
        }
        doc.setLineWidth(0.3);
        doc.text("Họ và tên học sinh:", 14, 15);
        doc.setFont("TimesBold");
        const hoten = this.handleNull(duLieu.hoten);
        doc.text(hoten, 53, 15);
        doc.setFont("TimesBold");
        doc.text("Lớp:", 150, 15);
        doc.setFont("TimesBold");
        const tenLop = this.handleNull(duLieu.tenLop);
        doc.text(tenLop, 170, 15);
        doc.setFont("VNTimes");
        doc.text("Chiều cao:", 14, 25);
        doc.text(duLieu.chieuCao ? `${duLieu.chieuCao} cm ` : "", 34, 25);
        doc.setFont("VNTimes");
        doc.text("Cân nặng:", 120, 25);
        doc.setFont("VNTimes");
        doc.text(duLieu.canNang ? `${duLieu.canNang} kg` : "", 140, 25);
        doc.setFont("VNTimes");
        doc.text("Số ngày nghỉ có phép:", 14, 35);
        const soNgayNghiCoPhep = duLieu.soNgayNghiCoPhep
            ? duLieu.soNgayNghiCoPhep.toString()
            : "0";
        doc.text(soNgayNghiCoPhep, 56, 35);
        doc.setFont("VNTimes");
        doc.text("Số ngày nghỉ không phép:", 120, 35);
        doc.setFont("VNTimes");
        const soNgayNghiKhongPhep = duLieu.soNgayNghiKhongPhep
            ? duLieu.soNgayNghiKhongPhep.toString()
            : "0";
        doc.text(soNgayNghiKhongPhep, 170, 35);
        doc.setFont("TimesBold");
        doc.setFontSize(13);
        doc.text("1. Các môn học và hoạt động giáo dục", 14, 45);
        let header = [
          [
            {content: "Môn học và hoạt động giáo dục", colSpan: 1},
            {
              content: "Mức đạt \nđược",
              colSpan: 1,
            },
            {textAlign: "center", content: "Điểm \nKTĐK", colSpan: 1},
            {content: "Nhận xét", colSpan: 1},
          ],
        ];
        let body = [];
        isThongTu27
            ? (body = [
              [
                {content: "Tiếng Việt", colSpan: 1},
                {
                  content: this.handleNull(duLieu.tvDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.tvDiem), colSpan: 1},
                {
                  content: this.handleNull(this.cauHinh.gopNhanXet ? duLieu.nhanXet : duLieu.tvNx),
                  rowSpan: this.cauHinh.gopNhanXet ? 14 : 1,
                },
              ],
              [
                {content: "Toán", colSpan: 1},
                {
                  content: this.handleNull(duLieu.toDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.toDiem), colSpan: 1},
                {content: this.handleNull(duLieu.toNx), colSpan: 1},
              ],
              [
                {content: "Ngoại ngữ", colSpan: 1},
                {
                  content: this.handleNull(duLieu.nnDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.nnDiem), colSpan: 1},
                {content: this.handleNull(duLieu.nnNx), colSpan: 1},
              ],
              [
                {content: "Lịch sử và Địa lý", colSpan: 1},
                {
                  content: this.handleNull(duLieu.ldDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.ldDiem), colSpan: 1},
                {content: this.handleNull(duLieu.ldNx), colSpan: 1},
              ],
              [
                {content: "Khoa học", colSpan: 1},
                {
                  content: this.handleNull(duLieu.khDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.khDiem), colSpan: 1},
                {content: this.handleNull(duLieu.khNx), colSpan: 1},
              ],
              [
                {content: "Tin học và Công nghệ (Công nghệ)", colSpan: 1},
                {
                  content: this.handleNull(duLieu.cnDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.cnDiem), colSpan: 1},
                {content: this.handleNull(duLieu.cnNx), colSpan: 1},
              ],
              [
                {
                  content: "Tin học và Công nghệ (Tin học)",
                  colSpan: 1,
                },
                {
                  content: this.handleNull(duLieu.thDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.thDiem), colSpan: 1},
                {content: this.handleNull(duLieu.thNx), colSpan: 1},
              ],
              [
                {content: "Đạo đức", colSpan: 1},
                {content: this.handleNull(duLieu.ddDgtx), colSpan: 1},
                {
                  content: "",
                  rowSpan: 7,
                },
                {content: this.handleNull(duLieu.ddNx), colSpan: 1},
              ],
              [
                {content: "Tự nhiên và Xã hội", colSpan: 1},
                {content: this.handleNull(duLieu.txDgtx), colSpan: 1},
                {content: this.handleNull(duLieu.txNx), colSpan: 1,},
              ],
              [
                {content: "Giáo dục thể chất", colSpan: 1},
                {content: this.handleNull(duLieu.tdDgtx), colSpan: 1},
                {content: this.handleNull(duLieu.tdNx), colSpan: 1,},
              ],
              [
                {content: "Nghệ thuật (Âm nhạc)", colSpan: 1},
                {content: this.handleNull(duLieu.anDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.anNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Nghệ thuật (Mĩ thuật)", colSpan: 1},
                {content: this.handleNull(duLieu.mtDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.mtNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Hoạt động trải nghiệm", colSpan: 1},
                {
                  content: this.handleNull(duLieu.hdtnDgtx),
                  colSpan: 1,
                },
                {
                  content: this.handleNull(duLieu.hdtnNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Tiếng dân tộc", colSpan: 1},
                {content: this.handleNull(duLieu.dtDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.dtNx),
                  colSpan: 1,
                },
              ],
            ])
            : (body = [
              [
                {content: "Tiếng Việt", colSpan: 1},
                {
                  content: this.handleNull(duLieu.tvDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.tvDiem), colSpan: 1},
                {
                  content: this.handleNull(duLieu.tvNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Toán", colSpan: 1},
                {
                  content: this.handleNull(duLieu.toDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.toDiem), colSpan: 1},
                {
                  content: this.handleNull(duLieu.toNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Khoa học", colSpan: 1},
                {
                  content: this.handleNull(duLieu.khDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.khDiem), colSpan: 1},
                {
                  content: this.handleNull(duLieu.khNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Lịch sử và Địa lý", colSpan: 1},
                {
                  content: this.handleNull(duLieu.ldDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.ldDiem), colSpan: 1},
                {
                  content: this.handleNull(duLieu.ldNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Ngoại ngữ", colSpan: 1},
                {
                  content: this.handleNull(duLieu.nnDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.nnDiem), colSpan: 1},
                {
                  content: this.handleNull(duLieu.nnNx),
                  colSpan: 1,
                },
              ],
              [
                {
                  content: "Tin học",
                  colSpan: 1,
                },
                {
                  content: this.handleNull(duLieu.thDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.thDiem), colSpan: 1},
                {
                  content: this.handleNull(duLieu.thNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Tiếng dân tộc", colSpan: 1},
                {content: this.handleNull(duLieu.dtDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.dtNx),
                  colSpan: 1,
                },
                {
                  content: "",
                  colSpan: 1,
                },
              ],
              [
                {content: "Đạo đức", colSpan: 1},
                {content: this.handleNull(duLieu.ddDgtx), colSpan: 1},
                {
                  content: "",
                  rowSpan: 7,
                },
                {
                  content: this.handleNull(duLieu.ddNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Âm nhạc", colSpan: 1},
                {content: this.handleNull(duLieu.anDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.anNx),
                  colSpan: 1,
                },
                {
                  content: "",
                  colSpan: 1,
                },
              ],
              [
                {content: "Mĩ thuật", colSpan: 1},
                {content: this.handleNull(duLieu.mtDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.mtNx),
                  colSpan: 1,
                },
                {
                  content: "",
                  colSpan: 1,
                },
              ],
              [
                {content: "Kỹ thuật", colSpan: 1},
                {content: this.handleNull(duLieu.ktDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.ktNx),
                  colSpan: 1,
                },
                {
                  content: "",
                  colSpan: 1,
                },
              ],
              [
                {content: "Thể dục", colSpan: 1},
                {content: this.handleNull(duLieu.tdDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.tdNx),
                  colSpan: 1,
                },
                {
                  content: "",
                  colSpan: 1,
                },
              ],
            ]);
        body = body;
        let columnStyles = {
          0: {
            halign: "center",
            valign: "middle",
            fontSize: 12,
            cellWidth: 50,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 2},
          },
          1: {
            halign: "center",
            textAlign: "center",
            fontSize: 12,
            cellPadding: {top: 4, right: 4, bottom: 4, left: 4},
            valign: "middle",
            font: "TimesBold",
            cellWidth: 21.5,
          },
          2: {
            textAlign: "center",
            halign: "center",
            fontSize: 12,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            font: "TimesBold",
            cellWidth: 21.5,
          },
          3: {
            textAlign: "center",
            halign: "left",
            fontSize: 12,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 4},
            valign: "top",
            cellWidth: 91.5,
          },
        };
        doc.autoTable({
          tableLineColor: [70, 76, 70],
          columnStyles: columnStyles,
          head: header,
          body: body,
          startY: 50,
          didDrawCell: function (data) {
            if (data.row.index === 7 && data.column.index === 2) {
              const {doc, cell} = data;
              const startX = cell.x + cell.width;
              const startY = cell.y;
              const endX = cell.x;
              const endY = cell.y + cell.height;
              doc.setLineWidth(0.3);
              doc.line(startX, startY, endX, endY);
            }
          },
          willDrawCell: (data) => {
            if (data.section === "body") {
              const textLines = data.cell.text;
              const increaseSpace = (text, widthDifference) => {
                const words = text.split(" ");
                const targetSpace = 2;
                let splitIndex;
                const conditionMap = {
                  "1-3": Math.floor(words.length / 4.65),
                  "3-8": Math.floor(words.length / 2.45),
                  "8-16": Math.floor((words.length * 4) / 5),
                };
                const conditionKeys = Object.keys(conditionMap);
                const matchedKey = conditionKeys.find((key) => {
                  const [min, max] = key.split("-").map(Number);
                  return widthDifference > min && widthDifference < max;
                });
                splitIndex = matchedKey ? conditionMap[matchedKey] : splitIndex;
                for (let i = 0; i < splitIndex; i++) {
                  words[i] += " ".repeat(targetSpace - 1);
                }
                return words.join(" ");
              };
              const doc = data.doc;
              const convertLines = textLines.map((line, index) => {
                const lineWidth = doc.getTextWidth(line);
                return {line, lineWidth, index};
              });
              const maxWidth = Math.max(...convertLines.map((line) => line.lineWidth));
              let newLinesArray = [];
              convertLines.forEach(({line, lineWidth}) => {
                if (lineWidth > 0) {
                  const widthDifference = maxWidth - lineWidth;
                  line = increaseSpace(line, widthDifference);
                }
                newLinesArray.push(line);
              });
              data.cell.text = newLinesArray;
            }
          },
          includeHiddenHtml: true,
          theme: "grid",
          headStyles: {
            fillColor: [255, 255, 255],
            textColor: [45, 45, 45],
            font: "TimesBold",
            fontStyle: "normal",
            lineWidth: 0.1,
            halign: "center",
            valign: "middle",
            fontSize: 12,
            cellPadding: 1,
          },
          styles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: "TimesBold",
            fontStyle: "normal",
            lineHeight: 1.5,
          },
          bodyStyles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: "VNTimes",
            fontStyle: "normal",
            cellPadding: 2.5,
            minCellHeight: 15,
          },
        });
      } else if (pageNumber == 9) {
        if (this.cauHinh.isAnSoTrang != -1) {
          doc.setFont("VNTimes");
          let textHocBa1 = String(this.cauHinh.isAnSoTrang + pageNumber - 4);
          console.log('textHocBa1')
          console.log(textHocBa1)
          let fontSize1 = 13;
          let textWidth1 =
              (doc.getStringUnitWidth(textHocBa1) * fontSize1) / doc.internal.scaleFactor;
          doc.setFontSize(fontSize1);
          doc.text(textHocBa1, (pageWidth - textWidth1) / 2, 295);
        }
        if (isThongTu27) {
          doc.setLineWidth(0.3);
          doc.setFont("TimesBold");
          doc.text("Trường:", 15, 10);
          let tenTruong = this.countCharacters(duLieu.tenTruong, "", 50);
          const hoten = this.handleNull(duLieu.hoten);
          doc.text(tenTruong, 35, 10);
          doc.text("Năm học:", 158, 10);
          const tenLop = this.handleNull(duLieu.tenLop);
          let namHoc = duLieu.namHoc ? duLieu.namHoc.toString() : "";
          let convertNamHoc = `${namHoc}-${(parseInt(namHoc) + 1)?.toString()}`;
          doc.text(convertNamHoc, 178, 10);
          doc.setFont("TimesBold");
          doc.setFontSize(13);
          doc.text("2. Những phẩm chất chủ yếu", 15, 20);
          let header = [
            [
              {content: "Phẩm chất", colSpan: 1},
              {content: "Mức đạt được", colSpan: 1},
              {
                content: "Nhận xét",
                colSpan: 1,
              },
            ],
          ];
          let body = [
            [
              {content: "Yêu nước", colSpan: 1},
              {
                content: this.handleNull(duLieu.pcYeuNuoc),
                colSpan: 1,
              },
              {
                content: this.removeTabs(duLieu.nxPhamChatChuYeu),
                rowSpan: 5,
              },
            ],
            [
              {content: "Nhân ái", colSpan: 1},
              {content: this.handleNull(duLieu.pcNhanAi), colSpan: 1},
            ],
            [
              {content: "Chăm chỉ", colSpan: 1},
              {content: this.handleNull(duLieu.pcChamChi), colSpan: 1},
            ],
            [
              {content: "Trung thực", colSpan: 1},
              {content: this.handleNull(duLieu.pcTrungThuc), colSpan: 1},
            ],
            [
              {content: "Trách nhiệm", colSpan: 1},
              {content: this.handleNull(duLieu.pcTrachNhiem), colSpan: 1},
            ],
          ];
          let columnStyles = {
            0: {
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellWidth: 60,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 2},
            },
            1: {
              textAlign: "center",
              halign: "center",
              fontSize: 12,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
              valign: "middle",
              font: "TimesBold",
              cellWidth: 30,
            },
            2: {
              halign: "left",
              fontSize: 12,
              cellPadding: {top: 4, right: 4, bottom: 4, left: 4},
              valign: "middle",
              cellWidth: 95,
            },
          };
          const startY_1 = 23;
          doc.autoTable({
            tableLineColor: [70, 76, 70],
            columnStyles: columnStyles,
            head: header,
            body: body,
            startY: startY_1,
            theme: "grid",
            headStyles: {
              fillColor: [255, 255, 255],
              textColor: [45, 45, 45],
              font: "TimesBold",
              fontStyle: "normal",
              lineWidth: 0.1,
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellPadding: 1,
              minCellHeight: 8,
            },
            styles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "TimesBold",
              fontStyle: "normal",
            },
            bodyStyles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "VNTimes",
              fontStyle: "normal",
              cellPadding: 2,
              fontSize: 12,
              minCellHeight: 8,
            },
            willDrawCell: (data) => {
              if (data.section === "body" && data.cell.rowSpan === 5) {
                data.cell.text = this.adjustTextSpacing(data.cell.text, data.doc);
              }
            },
          });
          const endY1 = doc.autoTable.previous.finalY;
          const tableHeight_1 = endY1 - startY_1;
          doc.setFont("TimesBold");
          doc.setFontSize(13);
          doc.text(
              "3. Những năng lực cốt lõi",
              15,
              doc.autoTable.previous.finalY + 6
          );
          doc.setFont("VNTimesItalic");
          doc.text("3.1. Những năng lực chung", 15, doc.autoTable.previous.finalY + 13);
          header = [
            [
              {content: "Năng lực", colSpan: 1},
              {content: "Mức đạt được", colSpan: 1},
              {
                content: "Nhận xét",
                colSpan: 1,
              },
            ],
          ];
          body = [
            [
              {content: "Tự chủ và tự học", colSpan: 1},
              {
                content: this.handleNull(duLieu.nlTuChuTuHoc),
                colSpan: 1,
              },
              {
                content: this.removeTabs(duLieu.nxNangLucChung),
                rowSpan: 3,
              },
            ],
            [
              {
                content: "Giao tiếp và hợp tác",
                colSpan: 1,
              },
              {
                content: this.handleNull(duLieu.nlGiaoTiepHopTac),
                colSpan: 1,
              },
            ],
            [
              {
                content: "Giải quyết vấn đề và sáng tạo",
                colSpan: 1,
              },
              {
                content: this.handleNull(duLieu.nlGquyetVdeSangTao),
                colSpan: 1,
              },
            ],
          ];
          columnStyles = {
            0: {
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellWidth: 60,
              cellPadding: {top: 3, right: 3, bottom: 3, left: 3},
            },
            1: {
              halign: "center",
              fontSize: 12,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
              valign: "middle",
              cellWidth: 30,
              font: "TimesBold",
            },
            2: {
              halign: "left",
              fontSize: 12,
              cellPadding: {top: 2, right: 2, bottom: 2, left: 2},
              valign: "middle",
              cellWidth: 95,
            },
          };
          const startY_2 = doc.autoTable.previous.finalY + 17;
          doc.autoTable({
            tableLineColor: [70, 76, 70],
            columnStyles: columnStyles,
            head: header,
            body: body,
            startY: startY_2,
            theme: "grid",
            headStyles: {
              fillColor: [255, 255, 255],
              textColor: [45, 45, 45],
              font: "TimesBold",
              fontStyle: "normal",
              lineWidth: 0.1,
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellPadding: 1,
            },
            styles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "TimesBold",
              fontStyle: "normal",
            },
            bodyStyles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "VNTimes",
              fontStyle: "normal",
              cellPadding: 2,
              fontSize: 12,
            },
            willDrawCell: (data) => {
              if (data.section === "body" && data.cell.rowSpan === 3) {
                data.cell.text = this.adjustTextSpacing(data.cell.text, data.doc);
              }
            },
          });
          const endY_2 = doc.autoTable.previous.finalY;
          const tableHeight_2 = endY_2 - startY_2;
          doc.setFont("VNTimesItalic");
          doc.text("3.2. Những năng lực đặc thù", 15, doc.autoTable.previous.finalY + 6);
          header = [
            [
              {content: "Năng lực", colSpan: 1},
              {content: "Mức đạt được", colSpan: 1},
              {
                content: "Nhận xét",
                colSpan: 1,
              },
            ],
          ];
          body = [
            [
              {content: "Ngôn ngữ", colSpan: 1},
              {
                content: this.handleNull(duLieu.nlNgonNgu),
                colSpan: 1,
              },
              {content: this.removeTabs(duLieu.nxNangLucDacThu), rowSpan: 7},
            ],
            [
              {content: "Tính toán", colSpan: 1},
              {content: this.handleNull(duLieu.nlTinhToan), colSpan: 1},
            ],
            [
              {content: "Khoa học", colSpan: 1},
              {content: this.handleNull(duLieu.nlKhoaHoc), colSpan: 1},
            ],
            [
              {content: "Công nghệ", colSpan: 1},
              {content: this.handleNull(duLieu.nlCongNghe), colSpan: 1},
            ],
            [
              {content: "Tin học", colSpan: 1},
              {content: this.handleNull(duLieu.nlTinHoc), colSpan: 1},
            ],
            [
              {content: "Thẩm mĩ", colSpan: 1},
              {content: this.handleNull(duLieu.nlThamMi), colSpan: 1},
            ],
            [
              {content: "Thể chất", colSpan: 1},
              {content: this.handleNull(duLieu.nlTheChat), colSpan: 1},
            ],
          ];
          columnStyles = {
            0: {
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellWidth: 60,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 2},
            },
            1: {
              halign: "center",
              fontSize: 12,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
              valign: "middle",
              font: "TimesBold",
              cellWidth: 30,
            },
            2: {
              halign: "left",
              fontSize: 12,
              cellPadding: {top: 4, right: 4, bottom: 4, left: 4},
              valign: "middle",
              cellWidth: 95,
            },
          };
          let startY_3 = doc.autoTable.previous.finalY + 9;
          const createTable = (startY) => {
            doc.autoTable({
              tableLineColor: [70, 76, 70],
              columnStyles: columnStyles,
              head: header,
              body: body,
              startY: startY,
              theme: "grid",
              headStyles: {
                fillColor: [255, 255, 255],
                textColor: [45, 45, 45],
                font: "TimesBold",
                fontStyle: "normal",
                lineWidth: 0.1,
                halign: "center",
                valign: "middle",
                fontSize: 12,
                cellPadding: 1,
                minCellHeight: 8,
              },
              styles: {
                textColor: [45, 45, 45],
                lineColor: [70, 76, 70],
                font: "TimesBold",
                fontStyle: "normal",
              },
              bodyStyles: {
                textColor: [45, 45, 45],
                lineColor: [70, 76, 70],
                font: "VNTimes",
                fontStyle: "normal",
                cellPadding: 2,
                fontSize: 12,
                minCellHeight: 8,
              },
              willDrawCell: (data) => {
                if (data.section === "body" && data.cell.rowSpan === 7) {
                  data.cell.text = this.adjustTextSpacing(data.cell.text, data.doc);
                }
              },
            });
          };
          createTable(startY_3);
          const endY_3 = doc.autoTable.previous.finalY;
          const tableHeight_3 = endY_3 - startY_3;
          const totalTableHeight = tableHeight_1 + tableHeight_2 + tableHeight_3;
          if (totalTableHeight > 155) {

            header = [];
            body = [];
            startY_3 = 10;
            doc.addPage();
            createTable(startY_3);
          } else {
            startY_3 = doc.autoTable.previous.finalY + 9;
          }
        } else {
          doc.setFont("TimesBold");
          let tenTruong = this.countCharacters(duLieu.tenTruong, "", 50);
          doc.text("Trường:", 15, tenTruong.length > 50 ? 12.5 : 15);
          doc.text(tenTruong, 35, tenTruong.length > 50 ? 10 : 15);
          doc.text("Năm học:", 150, tenTruong.length > 50 ? 12.5 : 15);
          let namHoc = duLieu.namHoc ? duLieu.namHoc.toString() : "";
          let convertNamHoc = `${namHoc}-${(parseInt(namHoc) + 1)?.toString()}`;
          doc.text(convertNamHoc, 170, tenTruong.length > 50 ? 12.5 : 15);
          doc.setFont("TimesBold");
          doc.setFontSize(13);
          doc.text("2. Các năng lực", 15, 20);
          let header = [
            [
              {content: "Năng lực", colSpan: 1},
              {content: "Mức đạt được", colSpan: 1},
              {
                content: "Nhận xét",
                colSpan: 1,
              },
            ],
          ];
          let body = [
            [
              {content: "Tự phục vụ, tự quản", colSpan: 1},
              {
                content: this.handleNull(duLieu.nlTpvtq),
                colSpan: 1,
              },
              {
                content: this.removeTabs(duLieu.nangLuc),
                rowSpan: 3,
              },
            ],
            [
              {content: "Hợp tác", colSpan: 1},
              {content: this.handleNull(duLieu.nlHt), colSpan: 1},
            ],
            [
              {content: "Tự học, giải quyết \n vấn đề", colSpan: 1},
              {content: this.handleNull(duLieu.nlThgqvd), colSpan: 1},
            ],
          ];
          let columnStyles = {
            0: {
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellWidth: 60,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 2},
            },
            1: {
              textAlign: "center",
              halign: "center",
              fontSize: 12,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
              valign: "middle",
              font: "TimesBold",
              cellWidth: 30,
            },
            2: {
              halign: "left",
              fontSize: 12,
              cellPadding: {top: 4, right: 4, bottom: 4, left: 4},
              valign: "middle",
              cellWidth: 95,
            },
          };
          const startY_1 = 23;
          doc.autoTable({
            tableLineColor: [70, 76, 70],
            columnStyles: columnStyles,
            head: header,
            body: body,
            startY: startY_1,
            theme: "grid",
            headStyles: {
              fillColor: [255, 255, 255],
              textColor: [45, 45, 45],
              font: "TimesBold",
              fontStyle: "normal",
              lineWidth: 0.1,
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellPadding: 1,
              minCellHeight: 8,
            },
            styles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "TimesBold",
              fontStyle: "normal",
            },
            bodyStyles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "VNTimes",
              fontStyle: "normal",
              cellPadding: 2,
              fontSize: 12,
              minCellHeight: 8,
            },
          });
          const endY1 = doc.autoTable.previous.finalY;
          const tableHeight_1 = endY1 - startY_1;
          doc.text("3. Các phẩm chất", 15, doc.autoTable.previous.finalY + 6);
          header = [
            [
              {content: "Phẩm chất", colSpan: 1},
              {content: "Mức đạt được", colSpan: 1},
              {
                content: "Nhận xét",
                colSpan: 1,
              },
            ],
          ];
          body = [
            [
              {content: "Chăm học, chăm làm", colSpan: 1},
              {
                content: this.handleNull(duLieu.pcChcl),
                colSpan: 1,
              },
              {
                content: this.removeTabs(duLieu.nxPhamChatChuYeu),
                rowSpan: 4,
              },
            ],
            [
              {content: "Tự tin trách nhiệm", colSpan: 1},
              {content: this.handleNull(duLieu.pcTttn), colSpan: 1},
            ],
            [
              {content: "Trung thực, kỷ luật", colSpan: 1},
              {content: this.handleNull(duLieu.pcTtkl), colSpan: 1},
            ],
            [
              {content: "Đoàn kết, yêu thương", colSpan: 1},
              {content: this.handleNull(duLieu.pcDkyt), colSpan: 1},
            ],
          ];
          columnStyles = {
            0: {
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellWidth: 60,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 2},
            },
            1: {
              halign: "center",
              fontSize: 12,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
              valign: "middle",
              font: "TimesBold",
              cellWidth: 30,
            },
            2: {
              halign: "left",
              fontSize: 12,
              cellPadding: {top: 4, right: 4, bottom: 4, left: 4},
              valign: "middle",
              cellWidth: 95,
            },
          };
          let startY_3 = doc.autoTable.previous.finalY + 9;
          const createTable = (startY) => {
            console.log("startY", startY);
            doc.autoTable({
              tableLineColor: [70, 76, 70],
              columnStyles: columnStyles,
              head: header,
              body: body,
              startY: startY,
              theme: "grid",
              headStyles: {
                fillColor: [255, 255, 255],
                textColor: [45, 45, 45],
                font: "TimesBold",
                fontStyle: "normal",
                lineWidth: 0.1,
                halign: "center",
                valign: "middle",
                fontSize: 12,
                cellPadding: 1,
                minCellHeight: 17,
              },
              styles: {
                textColor: [45, 45, 45],
                lineColor: [70, 76, 70],
                font: "TimesBold",
                fontStyle: "normal",
              },
              bodyStyles: {
                textColor: [45, 45, 45],
                lineColor: [70, 76, 70],
                font: "VNTimes",
                fontStyle: "normal",
                cellPadding: 2,
                fontSize: 12,
                minCellHeight: 17,
              },
            });
          };
          createTable(startY_3);
          const endY_3 = doc.autoTable.previous.finalY;
          const tableHeight_3 = endY_3 - startY_3;
          if (tableHeight_1 > 70 || tableHeight_3 > 115) {
            header = [];
            body = [];
            startY_3 = 10;
            doc.addPage();
            createTable(startY_3);
          } else {
            startY_3 = doc.autoTable.previous.finalY + 9;
          }
        }
        doc.setFontSize(13);
        doc.setFont("TimesBold");
        doc.text("4. Đánh giá kết quả giáo dục:", 15, doc.autoTable.previous.finalY + 6);
        doc.setFont("VNTimes");
        const danhGiaKetQuaGiaoDuc = this.handleNull(duLieu.danhGiaKetQuaGiaoDuc);
        doc.text(danhGiaKetQuaGiaoDuc, 75, doc.autoTable.previous.finalY + 6);
        doc.setFont("TimesBold");
        const khenThuong = this.handleNull(duLieu.noiDungKhenThuong);
        doc.text("5. Khen thưởng: ", 15, doc.autoTable.previous.finalY + 13);
        doc.setFont("VNTimes");
        doc.text(khenThuong, 15, doc.autoTable.previous.finalY + 18);
        doc.setFont("TimesBold");
        const titleContent = this.handleNull(duLieu.tieuDeKyThay);
        const newlineCount = titleContent.split("\n").length - 1;
        doc.text(
            "6. Hoàn thành chương trình lớp học/chương trình tiểu học:",
            15,
            newlineCount === 2
                ? doc.autoTable.previous.finalY + 30
                : doc.autoTable.previous.finalY + 35
        );
        doc.setFont("VNTimes");
        const hoanThanhChuongTrinh = this.countCharacters(
            duLieu.hoanThanhChuongTrinh ?? ""
        );
        doc.text(
            hoanThanhChuongTrinh,
            15,
            newlineCount === 2
                ? doc.autoTable.previous.finalY + 35
                : doc.autoTable.previous.finalY + 40
        );
        const kygiaContent = "(Ký và ghi rõ họ tên)";
        const kygiaWidth = doc.getTextWidth(kygiaContent);
        const kygiaX = 140;
        const kygiaY = doc.autoTable.previous.finalY + 55;
        doc.setFont("VNTimesItalic");
        doc.text(kygiaContent, kygiaX, kygiaY);
        const ngayHieuTruongKy = this.handleNull(duLieu.ngayGVCNKy);
        const ngayHieuTruongWidth = doc.getTextWidth(ngayHieuTruongKy);
        const ngayHieuTruongX = kygiaX + (kygiaWidth - ngayHieuTruongWidth) / 2;
        const ngayHieuTruongY = kygiaY - 10;
        doc.setFont("VNTimes");
        doc.text(ngayHieuTruongKy, ngayHieuTruongX, ngayHieuTruongY);
        const gvChuNhiemContent = "Giáo viên chủ nhiệm";
        const gvChuNhiemWidth = doc.getTextWidth(gvChuNhiemContent);
        const gvChuNhiemX = kygiaX + (kygiaWidth - gvChuNhiemWidth) / 2;
        const gvChuNhiemY = kygiaY - 5;
        doc.setFont("TimesBold");
        doc.text(gvChuNhiemContent, gvChuNhiemX, gvChuNhiemY);
        const tenGVCNContent = this.handleNull(duLieu?.tenGVCN);
        const tenGVCNWidth = doc.getTextWidth(tenGVCNContent);
        const tenGVCNX = kygiaX + (kygiaWidth - tenGVCNWidth) / 2;
        const tenGVCNY = gvChuNhiemY + 37;
        doc.setFont("TimesBold");
        doc.text(tenGVCNContent, tenGVCNX, tenGVCNY);
        const imageX = kygiaX + (kygiaWidth - this.imgSignatureWidth) / 2;
        const imageY = kygiaY + 3;
        console.error('Chữ ký gvcn')
        console.log(this.cauHinh)
        if (!this.cauHinh.isAnAnhGVCN) {
          doc.addImage(
              this.handleNull(duLieu.pathChuKyGVCN),
              "JPEG",
              imageX,
              imageY,
              this.imgSignatureWidth,
              this.imgSignatureHeight
          );
        }
        const imgX = 12;
        const imgY = doc.autoTable.previous.finalY + 45;
        const imgWidth = this.imgStampWidth;
        const imgHeight = this.imgStampHeight;
        if (!this.cauHinh.isDau) {
          doc.addImage(
              this.handleNull(duLieu.pathDau),
              "JPEG",
              imgX,
              imgY,
              imgWidth,
              imgHeight
          );
        }
        const imgWidth2 = 30;
        const imgHeight2 = 20;
        const imgX2 = imgX + imgWidth / 2 + 10;
        const imgY2 = imgY + (imgHeight - imgHeight2) / 2 + 5;
        if (!this.cauHinh.isAnAnhHieuTruong) {
          doc.addImage(
              this.handleNull(duLieu.pathChuKyHieuTruong),
              "JPEG",
              imgX2,
              imgY2,
              imgWidth2,
              imgHeight2
          );
        }

        const signatureContent = "(Ký ghi rõ họ tên và đóng dấu)";
        const signatureWidth = doc.getTextWidth(signatureContent);
        const signatureX = 25;
        const signatureY = doc.autoTable.previous.finalY + 55;
        doc.setFont("VNTimesItalic");
        doc.text(signatureContent, signatureX, signatureY);
        const titleWidth = doc.getTextWidth(titleContent);
        const adjustments = {
          0: {x: 0, y: -5},
          1: {x: 18, y: -10},
          2: {x: 45, y: -15},
        };
        const adjustment = adjustments[newlineCount] || adjustments[0];
        const titleX = signatureX + (signatureWidth - titleWidth) / 2 + adjustment.x;
        const titleY = signatureY + adjustment.y;
        doc.setFont("TimesBold");
        doc.text(titleContent, titleX, titleY);
        const headmasterNameContent = this.handleNull(duLieu.tenHieuTruong);
        const headmasterNameWidth = doc.getTextWidth(headmasterNameContent);
        const headmasterNameX = signatureX + (signatureWidth - headmasterNameWidth) / 2;
        const headmasterNameY = signatureY + 32;
        doc.setFont("TimesBold");
        doc.text(headmasterNameContent, headmasterNameX, headmasterNameY);
      } else if (pageNumber == 10) {
        if (this.cauHinh.isAnSoTrang != -1) {
          doc.setFont("VNTimes");
          let textHocBa1 = String(this.cauHinh.isAnSoTrang + pageNumber - 4);
          console.log('textHocBa1')
          console.log(textHocBa1)
          let fontSize1 = 13;
          let textWidth1 =
              (doc.getStringUnitWidth(textHocBa1) * fontSize1) / doc.internal.scaleFactor;
          doc.setFontSize(fontSize1);
          doc.text(textHocBa1, (pageWidth - textWidth1) / 2, 295);
        }
        doc.setLineWidth(0.3);
        doc.text("Họ và tên học sinh:", 14, 15);
        doc.setFont("TimesBold");
        const hoten = this.handleNull(duLieu.hoten);
        doc.text(hoten, 53, 15);
        doc.setFont("TimesBold");
        doc.text("Lớp:", 150, 15);
        doc.setFont("TimesBold");
        const tenLop = this.handleNull(duLieu.tenLop);
        doc.text(tenLop, 170, 15);
        doc.setFont("VNTimes");
        doc.text("Chiều cao:", 14, 25);
        doc.text(duLieu.chieuCao ? `${duLieu.chieuCao} cm ` : "", 34, 25);
        doc.setFont("VNTimes");
        doc.text("Cân nặng:", 120, 25);
        doc.setFont("VNTimes");
        doc.text(duLieu.canNang ? `${duLieu.canNang} kg` : "", 140, 25);
        doc.setFont("VNTimes");
        doc.text("Số ngày nghỉ có phép:", 14, 35);
        const soNgayNghiCoPhep = duLieu.soNgayNghiCoPhep
            ? duLieu.soNgayNghiCoPhep.toString()
            : "0";
        doc.text(soNgayNghiCoPhep, 56, 35);
        doc.setFont("VNTimes");
        doc.text("Số ngày nghỉ không phép:", 120, 35);
        doc.setFont("VNTimes");
        const soNgayNghiKhongPhep = duLieu.soNgayNghiKhongPhep
            ? duLieu.soNgayNghiKhongPhep.toString()
            : "0";
        doc.text(soNgayNghiKhongPhep, 170, 35);
        doc.setFont("TimesBold");
        doc.setFontSize(13);
        doc.text("1. Các môn học và hoạt động giáo dục", 14, 45);
        let header = [
          [
            {content: "Môn học và hoạt động giáo dục", colSpan: 1},
            {
              content: "Mức đạt \nđược",
              colSpan: 1,
            },
            {textAlign: "center", content: "Điểm \nKTĐK", colSpan: 1},
            {content: "Nhận xét", colSpan: 1},
          ],
        ];
        let body = [];
        isThongTu27
            ? (body = [
              [
                {content: "Tiếng Việt", colSpan: 1},
                {
                  content: this.handleNull(duLieu.tvDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.tvDiem), colSpan: 1},
                {
                  content: this.handleNull(this.cauHinh.gopNhanXet ? duLieu.nhanXet : duLieu.tvNx),
                  rowSpan: this.cauHinh.gopNhanXet ? 14 : 1,
                },
              ],
              [
                {content: "Toán", colSpan: 1},
                {
                  content: this.handleNull(duLieu.toDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.toDiem), colSpan: 1},
                {content: this.handleNull(duLieu.toNx), colSpan: 1},
              ],
              [
                {content: "Ngoại ngữ", colSpan: 1},
                {
                  content: this.handleNull(duLieu.nnDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.nnDiem), colSpan: 1},
                {content: this.handleNull(duLieu.nnNx), colSpan: 1},
              ],
              [
                {content: "Lịch sử và Địa lý", colSpan: 1},
                {
                  content: this.handleNull(duLieu.ldDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.ldDiem), colSpan: 1},
                {content: this.handleNull(duLieu.ldNx), colSpan: 1},
              ],
              [
                {content: "Khoa học", colSpan: 1},
                {
                  content: this.handleNull(duLieu.khDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.khDiem), colSpan: 1},
                {content: this.handleNull(duLieu.khNx), colSpan: 1},
              ],
              [
                {content: "Tin học và Công nghệ (Công nghệ)", colSpan: 1},
                {
                  content: this.handleNull(duLieu.cnDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.cnDiem), colSpan: 1},
                {content: this.handleNull(duLieu.cnNx), colSpan: 1},
              ],
              [
                {
                  content: "Tin học và Công nghệ (Tin học)",
                  colSpan: 1,
                },
                {
                  content: this.handleNull(duLieu.thDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.thDiem), colSpan: 1},
                {content: this.handleNull(duLieu.thNx), colSpan: 1},
              ],
              [
                {content: "Đạo đức", colSpan: 1},
                {content: this.handleNull(duLieu.ddDgtx), colSpan: 1},
                {
                  content: "",
                  rowSpan: 7,
                },
                {content: this.handleNull(duLieu.ddNx), colSpan: 1},
              ],
              [
                {content: "Tự nhiên và Xã hội", colSpan: 1},
                {content: this.handleNull(duLieu.txDgtx), colSpan: 1},
                {content: this.handleNull(duLieu.txNx), colSpan: 1,},
              ],
              [
                {content: "Giáo dục thể chất", colSpan: 1},
                {content: this.handleNull(duLieu.tdDgtx), colSpan: 1},
                {content: this.handleNull(duLieu.tdNx), colSpan: 1,},
              ],
              [
                {content: "Nghệ thuật (Âm nhạc)", colSpan: 1},
                {content: this.handleNull(duLieu.anDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.anNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Nghệ thuật (Mĩ thuật)", colSpan: 1},
                {content: this.handleNull(duLieu.mtDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.mtNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Hoạt động trải nghiệm", colSpan: 1},
                {
                  content: this.handleNull(duLieu.hdtnDgtx),
                  colSpan: 1,
                },
                {
                  content: this.handleNull(duLieu.hdtnNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Tiếng dân tộc", colSpan: 1},
                {content: this.handleNull(duLieu.dtDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.dtNx),
                  colSpan: 1,
                },
              ],
            ])
            : (body = [
              [
                {content: "Tiếng Việt", colSpan: 1},
                {
                  content: this.handleNull(duLieu.tvDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.tvDiem), colSpan: 1},
                {
                  content: this.handleNull(duLieu.tvNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Toán", colSpan: 1},
                {
                  content: this.handleNull(duLieu.toDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.toDiem), colSpan: 1},
                {
                  content: this.handleNull(duLieu.toNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Khoa học", colSpan: 1},
                {
                  content: this.handleNull(duLieu.khDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.khDiem), colSpan: 1},
                {
                  content: this.handleNull(duLieu.khNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Lịch sử và Địa lý", colSpan: 1},
                {
                  content: this.handleNull(duLieu.ldDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.ldDiem), colSpan: 1},
                {
                  content: this.handleNull(duLieu.ldNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Ngoại ngữ", colSpan: 1},
                {
                  content: this.handleNull(duLieu.nnDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.nnDiem), colSpan: 1},
                {
                  content: this.handleNull(duLieu.nnNx),
                  colSpan: 1,
                },
              ],
              [
                {
                  content: "Tin học",
                  colSpan: 1,
                },
                {
                  content: this.handleNull(duLieu.thDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.thDiem), colSpan: 1},
                {
                  content: this.handleNull(duLieu.thNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Tiếng dân tộc", colSpan: 1},
                {content: this.handleNull(duLieu.dtDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.dtNx),
                  colSpan: 1,
                },
                {
                  content: "",
                  colSpan: 1,
                },
              ],
              [
                {content: "Đạo đức", colSpan: 1},
                {content: this.handleNull(duLieu.ddDgtx), colSpan: 1},
                {
                  content: "",
                  rowSpan: 7,
                },
                {
                  content: this.handleNull(duLieu.ddNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Âm nhạc", colSpan: 1},
                {content: this.handleNull(duLieu.anDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.anNx),
                  colSpan: 1,
                },
                {
                  content: "",
                  colSpan: 1,
                },
              ],
              [
                {content: "Mĩ thuật", colSpan: 1},
                {content: this.handleNull(duLieu.mtDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.mtNx),
                  colSpan: 1,
                },
                {
                  content: "",
                  colSpan: 1,
                },
              ],
              [
                {content: "Kỹ thuật", colSpan: 1},
                {content: this.handleNull(duLieu.ktDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.ktNx),
                  colSpan: 1,
                },
                {
                  content: "",
                  colSpan: 1,
                },
              ],
              [
                {content: "Thể dục", colSpan: 1},
                {content: this.handleNull(duLieu.tdDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.tdNx),
                  colSpan: 1,
                },
                {
                  content: "",
                  colSpan: 1,
                },
              ],
            ]);
        body = body;
        let columnStyles = {
          0: {
            halign: "center",
            valign: "middle",
            fontSize: 12,
            cellWidth: 50,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 2},
          },
          1: {
            halign: "center",
            textAlign: "center",
            fontSize: 12,
            cellPadding: {top: 4, right: 4, bottom: 4, left: 4},
            valign: "middle",
            font: "TimesBold",
            cellWidth: 21.5,
          },
          2: {
            textAlign: "center",
            halign: "center",
            fontSize: 12,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            font: "TimesBold",
            cellWidth: 21.5,
          },
          3: {
            textAlign: "center",
            halign: "left",
            fontSize: 12,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 4},
            valign: "top",
            cellWidth: 91.5,
          },
        };
        doc.autoTable({
          tableLineColor: [70, 76, 70],
          columnStyles: columnStyles,
          head: header,
          body: body,
          startY: 50,
          didDrawCell: function (data) {
            if (data.row.index === 7 && data.column.index === 2) {
              const {doc, cell} = data;
              const startX = cell.x + cell.width;
              const startY = cell.y;
              const endX = cell.x;
              const endY = cell.y + cell.height;
              doc.setLineWidth(0.3);
              doc.line(startX, startY, endX, endY);
            }
          },
          willDrawCell: (data) => {
            if (data.section === "body") {
              const textLines = data.cell.text;
              const increaseSpace = (text, widthDifference) => {
                const words = text.split(" ");
                const targetSpace = 2;
                let splitIndex;
                const conditionMap = {
                  "1-3": Math.floor(words.length / 4.65),
                  "3-8": Math.floor(words.length / 2.45),
                  "8-16": Math.floor((words.length * 4) / 5),
                };
                const conditionKeys = Object.keys(conditionMap);
                const matchedKey = conditionKeys.find((key) => {
                  const [min, max] = key.split("-").map(Number);
                  return widthDifference > min && widthDifference < max;
                });
                splitIndex = matchedKey ? conditionMap[matchedKey] : splitIndex;
                for (let i = 0; i < splitIndex; i++) {
                  words[i] += " ".repeat(targetSpace - 1);
                }
                return words.join(" ");
              };
              const doc = data.doc;
              const convertLines = textLines.map((line, index) => {
                const lineWidth = doc.getTextWidth(line);
                return {line, lineWidth, index};
              });
              const maxWidth = Math.max(...convertLines.map((line) => line.lineWidth));
              let newLinesArray = [];
              convertLines.forEach(({line, lineWidth}) => {
                if (lineWidth > 0) {
                  const widthDifference = maxWidth - lineWidth;
                  line = increaseSpace(line, widthDifference);
                }
                newLinesArray.push(line);
              });
              data.cell.text = newLinesArray;
            }
          },
          includeHiddenHtml: true,
          theme: "grid",
          headStyles: {
            fillColor: [255, 255, 255],
            textColor: [45, 45, 45],
            font: "TimesBold",
            fontStyle: "normal",
            lineWidth: 0.1,
            halign: "center",
            valign: "middle",
            fontSize: 12,
            cellPadding: 1,
          },
          styles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: "TimesBold",
            fontStyle: "normal",
            lineHeight: 1.5,
          },
          bodyStyles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: "VNTimes",
            fontStyle: "normal",
            cellPadding: 2.5,
            minCellHeight: 15,
          },
        });
      } else if (pageNumber == 11) {
        if (this.cauHinh.isAnSoTrang != -1) {
          doc.setFont("VNTimes");
          let textHocBa1 = String(this.cauHinh.isAnSoTrang + pageNumber - 4);
          console.log('textHocBa1')
          console.log(textHocBa1)
          let fontSize1 = 13;
          let textWidth1 =
              (doc.getStringUnitWidth(textHocBa1) * fontSize1) / doc.internal.scaleFactor;
          doc.setFontSize(fontSize1);
          doc.text(textHocBa1, (pageWidth - textWidth1) / 2, 295);
        }
        if (isThongTu27) {
          doc.setLineWidth(0.3);
          doc.setFont("TimesBold");
          doc.text("Trường:", 15, 10);
          let tenTruong = this.countCharacters(duLieu.tenTruong, "", 50);
          const hoten = this.handleNull(duLieu.hoten);
          doc.text(tenTruong, 35, 10);
          doc.text("Năm học:", 158, 10);
          const tenLop = this.handleNull(duLieu.tenLop);
          let namHoc = duLieu.namHoc ? duLieu.namHoc.toString() : "";
          let convertNamHoc = `${namHoc}-${(parseInt(namHoc) + 1)?.toString()}`;
          doc.text(convertNamHoc, 178, 10);
          doc.setFont("TimesBold");
          doc.setFontSize(13);
          doc.text("2. Những phẩm chất chủ yếu", 15, 20);
          let header = [
            [
              {content: "Phẩm chất", colSpan: 1},
              {content: "Mức đạt được", colSpan: 1},
              {
                content: "Nhận xét",
                colSpan: 1,
              },
            ],
          ];
          let body = [
            [
              {content: "Yêu nước", colSpan: 1},
              {
                content: this.handleNull(duLieu.pcYeuNuoc),
                colSpan: 1,
              },
              {
                content: this.removeTabs(duLieu.nxPhamChatChuYeu),
                rowSpan: 5,
              },
            ],
            [
              {content: "Nhân ái", colSpan: 1},
              {content: this.handleNull(duLieu.pcNhanAi), colSpan: 1},
            ],
            [
              {content: "Chăm chỉ", colSpan: 1},
              {content: this.handleNull(duLieu.pcChamChi), colSpan: 1},
            ],
            [
              {content: "Trung thực", colSpan: 1},
              {content: this.handleNull(duLieu.pcTrungThuc), colSpan: 1},
            ],
            [
              {content: "Trách nhiệm", colSpan: 1},
              {content: this.handleNull(duLieu.pcTrachNhiem), colSpan: 1},
            ],
          ];
          let columnStyles = {
            0: {
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellWidth: 60,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 2},
            },
            1: {
              textAlign: "center",
              halign: "center",
              fontSize: 12,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
              valign: "middle",
              font: "TimesBold",
              cellWidth: 30,
            },
            2: {
              halign: "left",
              fontSize: 12,
              cellPadding: {top: 4, right: 4, bottom: 4, left: 4},
              valign: "middle",
              cellWidth: 95,
            },
          };
          const startY_1 = 23;
          doc.autoTable({
            tableLineColor: [70, 76, 70],
            columnStyles: columnStyles,
            head: header,
            body: body,
            startY: startY_1,
            theme: "grid",
            headStyles: {
              fillColor: [255, 255, 255],
              textColor: [45, 45, 45],
              font: "TimesBold",
              fontStyle: "normal",
              lineWidth: 0.1,
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellPadding: 1,
              minCellHeight: 8,
            },
            styles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "TimesBold",
              fontStyle: "normal",
            },
            bodyStyles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "VNTimes",
              fontStyle: "normal",
              cellPadding: 2,
              fontSize: 12,
              minCellHeight: 8,
            },
            willDrawCell: (data) => {
              if (data.section === "body" && data.cell.rowSpan === 5) {
                data.cell.text = this.adjustTextSpacing(data.cell.text, data.doc);
              }
            },
          });
          const endY1 = doc.autoTable.previous.finalY;
          const tableHeight_1 = endY1 - startY_1;
          doc.setFont("TimesBold");
          doc.setFontSize(13);
          doc.text(
              "3. Những năng lực cốt lõi",
              15,
              doc.autoTable.previous.finalY + 6
          );
          doc.setFont("VNTimesItalic");
          doc.text("3.1. Những năng lực chung", 15, doc.autoTable.previous.finalY + 13);
          header = [
            [
              {content: "Năng lực", colSpan: 1},
              {content: "Mức đạt được", colSpan: 1},
              {
                content: "Nhận xét",
                colSpan: 1,
              },
            ],
          ];
          body = [
            [
              {content: "Tự chủ và tự học", colSpan: 1},
              {
                content: this.handleNull(duLieu.nlTuChuTuHoc),
                colSpan: 1,
              },
              {
                content: this.removeTabs(duLieu.nxNangLucChung),
                rowSpan: 3,
              },
            ],
            [
              {
                content: "Giao tiếp và hợp tác",
                colSpan: 1,
              },
              {
                content: this.handleNull(duLieu.nlGiaoTiepHopTac),
                colSpan: 1,
              },
            ],
            [
              {
                content: "Giải quyết vấn đề và sáng tạo",
                colSpan: 1,
              },
              {
                content: this.handleNull(duLieu.nlGquyetVdeSangTao),
                colSpan: 1,
              },
            ],
          ];
          columnStyles = {
            0: {
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellWidth: 60,
              cellPadding: {top: 3, right: 3, bottom: 3, left: 3},
            },
            1: {
              halign: "center",
              fontSize: 12,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
              valign: "middle",
              cellWidth: 30,
              font: "TimesBold",
            },
            2: {
              halign: "left",
              fontSize: 12,
              cellPadding: {top: 2, right: 2, bottom: 2, left: 2},
              valign: "middle",
              cellWidth: 95,
            },
          };
          const startY_2 = doc.autoTable.previous.finalY + 17;
          doc.autoTable({
            tableLineColor: [70, 76, 70],
            columnStyles: columnStyles,
            head: header,
            body: body,
            startY: startY_2,
            theme: "grid",
            headStyles: {
              fillColor: [255, 255, 255],
              textColor: [45, 45, 45],
              font: "TimesBold",
              fontStyle: "normal",
              lineWidth: 0.1,
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellPadding: 1,
            },
            styles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "TimesBold",
              fontStyle: "normal",
            },
            bodyStyles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "VNTimes",
              fontStyle: "normal",
              cellPadding: 2,
              fontSize: 12,
            },
            willDrawCell: (data) => {
              if (data.section === "body" && data.cell.rowSpan === 3) {
                data.cell.text = this.adjustTextSpacing(data.cell.text, data.doc);
              }
            },
          });
          const endY_2 = doc.autoTable.previous.finalY;
          const tableHeight_2 = endY_2 - startY_2;
          doc.setFont("VNTimesItalic");
          doc.text("3.2. Những năng lực đặc thù", 15, doc.autoTable.previous.finalY + 6);
          header = [
            [
              {content: "Năng lực", colSpan: 1},
              {content: "Mức đạt được", colSpan: 1},
              {
                content: "Nhận xét",
                colSpan: 1,
              },
            ],
          ];
          body = [
            [
              {content: "Ngôn ngữ", colSpan: 1},
              {
                content: this.handleNull(duLieu.nlNgonNgu),
                colSpan: 1,
              },
              {content: this.removeTabs(duLieu.nxNangLucDacThu), rowSpan: 7},
            ],
            [
              {content: "Tính toán", colSpan: 1},
              {content: this.handleNull(duLieu.nlTinhToan), colSpan: 1},
            ],
            [
              {content: "Khoa học", colSpan: 1},
              {content: this.handleNull(duLieu.nlKhoaHoc), colSpan: 1},
            ],
            [
              {content: "Công nghệ", colSpan: 1},
              {content: this.handleNull(duLieu.nlCongNghe), colSpan: 1},
            ],
            [
              {content: "Tin học", colSpan: 1},
              {content: this.handleNull(duLieu.nlTinHoc), colSpan: 1},
            ],
            [
              {content: "Thẩm mĩ", colSpan: 1},
              {content: this.handleNull(duLieu.nlThamMi), colSpan: 1},
            ],
            [
              {content: "Thể chất", colSpan: 1},
              {content: this.handleNull(duLieu.nlTheChat), colSpan: 1},
            ],
          ];
          columnStyles = {
            0: {
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellWidth: 60,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 2},
            },
            1: {
              halign: "center",
              fontSize: 12,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
              valign: "middle",
              font: "TimesBold",
              cellWidth: 30,
            },
            2: {
              halign: "left",
              fontSize: 12,
              cellPadding: {top: 4, right: 4, bottom: 4, left: 4},
              valign: "middle",
              cellWidth: 95,
            },
          };
          let startY_3 = doc.autoTable.previous.finalY + 9;
          const createTable = (startY) => {
            doc.autoTable({
              tableLineColor: [70, 76, 70],
              columnStyles: columnStyles,
              head: header,
              body: body,
              startY: startY,
              theme: "grid",
              headStyles: {
                fillColor: [255, 255, 255],
                textColor: [45, 45, 45],
                font: "TimesBold",
                fontStyle: "normal",
                lineWidth: 0.1,
                halign: "center",
                valign: "middle",
                fontSize: 12,
                cellPadding: 1,
                minCellHeight: 8,
              },
              styles: {
                textColor: [45, 45, 45],
                lineColor: [70, 76, 70],
                font: "TimesBold",
                fontStyle: "normal",
              },
              bodyStyles: {
                textColor: [45, 45, 45],
                lineColor: [70, 76, 70],
                font: "VNTimes",
                fontStyle: "normal",
                cellPadding: 2,
                fontSize: 12,
                minCellHeight: 8,
              },
              willDrawCell: (data) => {
                if (data.section === "body" && data.cell.rowSpan === 7) {
                  data.cell.text = this.adjustTextSpacing(data.cell.text, data.doc);
                }
              },
            });
          };
          createTable(startY_3);
          const endY_3 = doc.autoTable.previous.finalY;
          const tableHeight_3 = endY_3 - startY_3;
          const totalTableHeight = tableHeight_1 + tableHeight_2 + tableHeight_3;
          if (totalTableHeight > 155) {

            header = [];
            body = [];
            startY_3 = 10;
            doc.addPage();
            createTable(startY_3);
          } else {
            startY_3 = doc.autoTable.previous.finalY + 9;
          }
        } else {
          doc.setFont("TimesBold");
          let tenTruong = this.countCharacters(duLieu.tenTruong, "", 50);
          doc.text("Trường:", 15, tenTruong.length > 50 ? 12.5 : 15);
          doc.text(tenTruong, 35, tenTruong.length > 50 ? 10 : 15);
          doc.text("Năm học:", 150, tenTruong.length > 50 ? 12.5 : 15);
          let namHoc = duLieu.namHoc ? duLieu.namHoc.toString() : "";
          let convertNamHoc = `${namHoc}-${(parseInt(namHoc) + 1)?.toString()}`;
          doc.text(convertNamHoc, 170, tenTruong.length > 50 ? 12.5 : 15);
          doc.setFont("TimesBold");
          doc.setFontSize(13);
          doc.text("2. Các năng lực", 15, 20);
          let header = [
            [
              {content: "Năng lực", colSpan: 1},
              {content: "Mức đạt được", colSpan: 1},
              {
                content: "Nhận xét",
                colSpan: 1,
              },
            ],
          ];
          let body = [
            [
              {content: "Tự phục vụ, tự quản", colSpan: 1},
              {
                content: this.handleNull(duLieu.nlTpvtq),
                colSpan: 1,
              },
              {
                content: this.removeTabs(duLieu.nangLuc),
                rowSpan: 3,
              },
            ],
            [
              {content: "Hợp tác", colSpan: 1},
              {content: this.handleNull(duLieu.nlHt), colSpan: 1},
            ],
            [
              {content: "Tự học, giải quyết \n vấn đề", colSpan: 1},
              {content: this.handleNull(duLieu.nlThgqvd), colSpan: 1},
            ],
          ];
          let columnStyles = {
            0: {
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellWidth: 60,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 2},
            },
            1: {
              textAlign: "center",
              halign: "center",
              fontSize: 12,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
              valign: "middle",
              font: "TimesBold",
              cellWidth: 30,
            },
            2: {
              halign: "left",
              fontSize: 12,
              cellPadding: {top: 4, right: 4, bottom: 4, left: 4},
              valign: "middle",
              cellWidth: 95,
            },
          };
          const startY_1 = 23;
          doc.autoTable({
            tableLineColor: [70, 76, 70],
            columnStyles: columnStyles,
            head: header,
            body: body,
            startY: startY_1,
            theme: "grid",
            headStyles: {
              fillColor: [255, 255, 255],
              textColor: [45, 45, 45],
              font: "TimesBold",
              fontStyle: "normal",
              lineWidth: 0.1,
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellPadding: 1,
              minCellHeight: 8,
            },
            styles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "TimesBold",
              fontStyle: "normal",
            },
            bodyStyles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "VNTimes",
              fontStyle: "normal",
              cellPadding: 2,
              fontSize: 12,
              minCellHeight: 8,
            },
          });
          const endY1 = doc.autoTable.previous.finalY;
          const tableHeight_1 = endY1 - startY_1;
          doc.text("3. Các phẩm chất", 15, doc.autoTable.previous.finalY + 6);
          header = [
            [
              {content: "Phẩm chất", colSpan: 1},
              {content: "Mức đạt được", colSpan: 1},
              {
                content: "Nhận xét",
                colSpan: 1,
              },
            ],
          ];
          body = [
            [
              {content: "Chăm học, chăm làm", colSpan: 1},
              {
                content: this.handleNull(duLieu.pcChcl),
                colSpan: 1,
              },
              {
                content: this.removeTabs(duLieu.nxPhamChatChuYeu),
                rowSpan: 4,
              },
            ],
            [
              {content: "Tự tin trách nhiệm", colSpan: 1},
              {content: this.handleNull(duLieu.pcTttn), colSpan: 1},
            ],
            [
              {content: "Trung thực, kỷ luật", colSpan: 1},
              {content: this.handleNull(duLieu.pcTtkl), colSpan: 1},
            ],
            [
              {content: "Đoàn kết, yêu thương", colSpan: 1},
              {content: this.handleNull(duLieu.pcDkyt), colSpan: 1},
            ],
          ];
          columnStyles = {
            0: {
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellWidth: 60,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 2},
            },
            1: {
              halign: "center",
              fontSize: 12,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
              valign: "middle",
              font: "TimesBold",
              cellWidth: 30,
            },
            2: {
              halign: "left",
              fontSize: 12,
              cellPadding: {top: 4, right: 4, bottom: 4, left: 4},
              valign: "middle",
              cellWidth: 95,
            },
          };
          let startY_3 = doc.autoTable.previous.finalY + 9;
          const createTable = (startY) => {
            console.log("startY", startY);
            doc.autoTable({
              tableLineColor: [70, 76, 70],
              columnStyles: columnStyles,
              head: header,
              body: body,
              startY: startY,
              theme: "grid",
              headStyles: {
                fillColor: [255, 255, 255],
                textColor: [45, 45, 45],
                font: "TimesBold",
                fontStyle: "normal",
                lineWidth: 0.1,
                halign: "center",
                valign: "middle",
                fontSize: 12,
                cellPadding: 1,
                minCellHeight: 17,
              },
              styles: {
                textColor: [45, 45, 45],
                lineColor: [70, 76, 70],
                font: "TimesBold",
                fontStyle: "normal",
              },
              bodyStyles: {
                textColor: [45, 45, 45],
                lineColor: [70, 76, 70],
                font: "VNTimes",
                fontStyle: "normal",
                cellPadding: 2,
                fontSize: 12,
                minCellHeight: 17,
              },
            });
          };
          createTable(startY_3);
          const endY_3 = doc.autoTable.previous.finalY;
          const tableHeight_3 = endY_3 - startY_3;
          if (tableHeight_1 > 70 || tableHeight_3 > 115) {
            header = [];
            body = [];
            startY_3 = 10;
            doc.addPage();
            createTable(startY_3);
          } else {
            startY_3 = doc.autoTable.previous.finalY + 9;
          }
        }
        doc.setFontSize(13);
        doc.setFont("TimesBold");
        doc.text("4. Đánh giá kết quả giáo dục:", 15, doc.autoTable.previous.finalY + 6);
        doc.setFont("VNTimes");
        const danhGiaKetQuaGiaoDuc = this.handleNull(duLieu.danhGiaKetQuaGiaoDuc);
        doc.text(danhGiaKetQuaGiaoDuc, 75, doc.autoTable.previous.finalY + 6);
        doc.setFont("TimesBold");
        const khenThuong = this.handleNull(duLieu.noiDungKhenThuong);
        doc.text("5. Khen thưởng: ", 15, doc.autoTable.previous.finalY + 13);
        doc.setFont("VNTimes");
        doc.text(khenThuong, 15, doc.autoTable.previous.finalY + 18);
        doc.setFont("TimesBold");
        const titleContent = this.handleNull(duLieu.tieuDeKyThay);
        const newlineCount = titleContent.split("\n").length - 1;
        doc.text(
            "6. Hoàn thành chương trình lớp học/chương trình tiểu học:",
            15,
            newlineCount === 2
                ? doc.autoTable.previous.finalY + 30
                : doc.autoTable.previous.finalY + 35
        );
        doc.setFont("VNTimes");
        const hoanThanhChuongTrinh = this.countCharacters(
            duLieu.hoanThanhChuongTrinh ?? ""
        );
        doc.text(
            hoanThanhChuongTrinh,
            15,
            newlineCount === 2
                ? doc.autoTable.previous.finalY + 35
                : doc.autoTable.previous.finalY + 40
        );
        const kygiaContent = "(Ký và ghi rõ họ tên)";
        const kygiaWidth = doc.getTextWidth(kygiaContent);
        const kygiaX = 140;
        const kygiaY = doc.autoTable.previous.finalY + 55;
        doc.setFont("VNTimesItalic");
        doc.text(kygiaContent, kygiaX, kygiaY);
        const ngayHieuTruongKy = this.handleNull(duLieu.ngayGVCNKy);
        const ngayHieuTruongWidth = doc.getTextWidth(ngayHieuTruongKy);
        const ngayHieuTruongX = kygiaX + (kygiaWidth - ngayHieuTruongWidth) / 2;
        const ngayHieuTruongY = kygiaY - 10;
        doc.setFont("VNTimes");
        doc.text(ngayHieuTruongKy, ngayHieuTruongX, ngayHieuTruongY);
        const gvChuNhiemContent = "Giáo viên chủ nhiệm";
        const gvChuNhiemWidth = doc.getTextWidth(gvChuNhiemContent);
        const gvChuNhiemX = kygiaX + (kygiaWidth - gvChuNhiemWidth) / 2;
        const gvChuNhiemY = kygiaY - 5;
        doc.setFont("TimesBold");
        doc.text(gvChuNhiemContent, gvChuNhiemX, gvChuNhiemY);
        const tenGVCNContent = this.handleNull(duLieu?.tenGVCN);
        const tenGVCNWidth = doc.getTextWidth(tenGVCNContent);
        const tenGVCNX = kygiaX + (kygiaWidth - tenGVCNWidth) / 2;
        const tenGVCNY = gvChuNhiemY + 37;
        doc.setFont("TimesBold");
        doc.text(tenGVCNContent, tenGVCNX, tenGVCNY);
        const imageX = kygiaX + (kygiaWidth - this.imgSignatureWidth) / 2;
        const imageY = kygiaY + 3;
        console.error('Chữ ký gvcn')
        console.log(this.cauHinh)
        if (!this.cauHinh.isAnAnhGVCN) {
          doc.addImage(
              this.handleNull(duLieu.pathChuKyGVCN),
              "JPEG",
              imageX,
              imageY,
              this.imgSignatureWidth,
              this.imgSignatureHeight
          );
        }
        const imgX = 12;
        const imgY = doc.autoTable.previous.finalY + 45;
        const imgWidth = this.imgStampWidth;
        const imgHeight = this.imgStampHeight;
        if (!this.cauHinh.isDau) {
          doc.addImage(
              this.handleNull(duLieu.pathDau),
              "JPEG",
              imgX,
              imgY,
              imgWidth,
              imgHeight
          );
        }
        const imgWidth2 = 30;
        const imgHeight2 = 20;
        const imgX2 = imgX + imgWidth / 2 + 10;
        const imgY2 = imgY + (imgHeight - imgHeight2) / 2 + 5;
        if (!this.cauHinh.isAnAnhHieuTruong) {
          doc.addImage(
              this.handleNull(duLieu.pathChuKyHieuTruong),
              "JPEG",
              imgX2,
              imgY2,
              imgWidth2,
              imgHeight2
          );
        }

        const signatureContent = "(Ký ghi rõ họ tên và đóng dấu)";
        const signatureWidth = doc.getTextWidth(signatureContent);
        const signatureX = 25;
        const signatureY = doc.autoTable.previous.finalY + 55;
        doc.setFont("VNTimesItalic");
        doc.text(signatureContent, signatureX, signatureY);
        const titleWidth = doc.getTextWidth(titleContent);
        const adjustments = {
          0: {x: 0, y: -5},
          1: {x: 18, y: -10},
          2: {x: 45, y: -15},
        };
        const adjustment = adjustments[newlineCount] || adjustments[0];
        const titleX = signatureX + (signatureWidth - titleWidth) / 2 + adjustment.x;
        const titleY = signatureY + adjustment.y;
        doc.setFont("TimesBold");
        doc.text(titleContent, titleX, titleY);
        const headmasterNameContent = this.handleNull(duLieu.tenHieuTruong);
        const headmasterNameWidth = doc.getTextWidth(headmasterNameContent);
        const headmasterNameX = signatureX + (signatureWidth - headmasterNameWidth) / 2;
        const headmasterNameY = signatureY + 32;
        doc.setFont("TimesBold");
        doc.text(headmasterNameContent, headmasterNameX, headmasterNameY);
      } else if (pageNumber == 12) {
        if (this.cauHinh.isAnSoTrang != -1) {
          doc.setFont("VNTimes");
          let textHocBa1 = String(this.cauHinh.isAnSoTrang + pageNumber - 4);
          console.log('textHocBa1')
          console.log(textHocBa1)
          let fontSize1 = 13;
          let textWidth1 =
              (doc.getStringUnitWidth(textHocBa1) * fontSize1) / doc.internal.scaleFactor;
          doc.setFontSize(fontSize1);
          doc.text(textHocBa1, (pageWidth - textWidth1) / 2, 295);
        }
        doc.setLineWidth(0.3);
        doc.text("Họ và tên học sinh:", 14, 15);
        doc.setFont("TimesBold");
        const hoten = this.handleNull(duLieu.hoten);
        doc.text(hoten, 53, 15);
        doc.setFont("TimesBold");
        doc.text("Lớp:", 150, 15);
        doc.setFont("TimesBold");
        const tenLop = this.handleNull(duLieu.tenLop);
        doc.text(tenLop, 170, 15);
        doc.setFont("VNTimes");
        doc.text("Chiều cao:", 14, 25);
        doc.text(duLieu.chieuCao ? `${duLieu.chieuCao} cm ` : "", 34, 25);
        doc.setFont("VNTimes");
        doc.text("Cân nặng:", 120, 25);
        doc.setFont("VNTimes");
        doc.text(duLieu.canNang ? `${duLieu.canNang} kg` : "", 140, 25);
        doc.setFont("VNTimes");
        doc.text("Số ngày nghỉ có phép:", 14, 35);
        const soNgayNghiCoPhep = duLieu.soNgayNghiCoPhep
            ? duLieu.soNgayNghiCoPhep.toString()
            : "0";
        doc.text(soNgayNghiCoPhep, 56, 35);
        doc.setFont("VNTimes");
        doc.text("Số ngày nghỉ không phép:", 120, 35);
        doc.setFont("VNTimes");
        const soNgayNghiKhongPhep = duLieu.soNgayNghiKhongPhep
            ? duLieu.soNgayNghiKhongPhep.toString()
            : "0";
        doc.text(soNgayNghiKhongPhep, 170, 35);
        doc.setFont("TimesBold");
        doc.setFontSize(13);
        doc.text("1. Các môn học và hoạt động giáo dục", 14, 45);
        let header = [
          [
            {content: "Môn học và hoạt động giáo dục", colSpan: 1},
            {
              content: "Mức đạt \nđược",
              colSpan: 1,
            },
            {textAlign: "center", content: "Điểm \nKTĐK", colSpan: 1},
            {content: "Nhận xét", colSpan: 1},
          ],
        ];
        let body = [];
        isThongTu27
            ? (body = [
              [
                {content: "Tiếng Việt", colSpan: 1},
                {
                  content: this.handleNull(duLieu.tvDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.tvDiem), colSpan: 1},
                {
                  content: this.handleNull(this.cauHinh.gopNhanXet ? duLieu.nhanXet : duLieu.tvNx),
                  rowSpan: this.cauHinh.gopNhanXet ? 14 : 1,
                },
              ],
              [
                {content: "Toán", colSpan: 1},
                {
                  content: this.handleNull(duLieu.toDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.toDiem), colSpan: 1},
                {content: this.handleNull(duLieu.toNx), colSpan: 1},
              ],
              [
                {content: "Ngoại ngữ", colSpan: 1},
                {
                  content: this.handleNull(duLieu.nnDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.nnDiem), colSpan: 1},
                {content: this.handleNull(duLieu.nnNx), colSpan: 1},
              ],
              [
                {content: "Lịch sử và Địa lý", colSpan: 1},
                {
                  content: this.handleNull(duLieu.ldDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.ldDiem), colSpan: 1},
                {content: this.handleNull(duLieu.ldNx), colSpan: 1},
              ],
              [
                {content: "Khoa học", colSpan: 1},
                {
                  content: this.handleNull(duLieu.khDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.khDiem), colSpan: 1},
                {content: this.handleNull(duLieu.khNx), colSpan: 1},
              ],
              [
                {content: "Tin học và Công nghệ (Công nghệ)", colSpan: 1},
                {
                  content: this.handleNull(duLieu.cnDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.cnDiem), colSpan: 1},
                {content: this.handleNull(duLieu.cnNx), colSpan: 1},
              ],
              [
                {
                  content: "Tin học và Công nghệ (Tin học)",
                  colSpan: 1,
                },
                {
                  content: this.handleNull(duLieu.thDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.thDiem), colSpan: 1},
                {content: this.handleNull(duLieu.thNx), colSpan: 1},
              ],
              [
                {content: "Đạo đức", colSpan: 1},
                {content: this.handleNull(duLieu.ddDgtx), colSpan: 1},
                {
                  content: "",
                  rowSpan: 7,
                },
                {content: this.handleNull(duLieu.ddNx), colSpan: 1},
              ],
              [
                {content: "Tự nhiên và Xã hội", colSpan: 1},
                {content: this.handleNull(duLieu.txDgtx), colSpan: 1},
                {content: this.handleNull(duLieu.txNx), colSpan: 1,},
              ],
              [
                {content: "Giáo dục thể chất", colSpan: 1},
                {content: this.handleNull(duLieu.tdDgtx), colSpan: 1},
                {content: this.handleNull(duLieu.tdNx), colSpan: 1,},
              ],
              [
                {content: "Nghệ thuật (Âm nhạc)", colSpan: 1},
                {content: this.handleNull(duLieu.anDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.anNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Nghệ thuật (Mĩ thuật)", colSpan: 1},
                {content: this.handleNull(duLieu.mtDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.mtNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Hoạt động trải nghiệm", colSpan: 1},
                {
                  content: this.handleNull(duLieu.hdtnDgtx),
                  colSpan: 1,
                },
                {
                  content: this.handleNull(duLieu.hdtnNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Tiếng dân tộc", colSpan: 1},
                {content: this.handleNull(duLieu.dtDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.dtNx),
                  colSpan: 1,
                },
              ],
            ])
            : (body = [
              [
                {content: "Tiếng Việt", colSpan: 1},
                {
                  content: this.handleNull(duLieu.tvDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.tvDiem), colSpan: 1},
                {
                  content: this.handleNull(duLieu.tvNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Toán", colSpan: 1},
                {
                  content: this.handleNull(duLieu.toDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.toDiem), colSpan: 1},
                {
                  content: this.handleNull(duLieu.toNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Khoa học", colSpan: 1},
                {
                  content: this.handleNull(duLieu.khDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.khDiem), colSpan: 1},
                {
                  content: this.handleNull(duLieu.khNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Lịch sử và Địa lý", colSpan: 1},
                {
                  content: this.handleNull(duLieu.ldDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.ldDiem), colSpan: 1},
                {
                  content: this.handleNull(duLieu.ldNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Ngoại ngữ", colSpan: 1},
                {
                  content: this.handleNull(duLieu.nnDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.nnDiem), colSpan: 1},
                {
                  content: this.handleNull(duLieu.nnNx),
                  colSpan: 1,
                },
              ],
              [
                {
                  content: "Tin học",
                  colSpan: 1,
                },
                {
                  content: this.handleNull(duLieu.thDgtx),
                  colSpan: 1,
                },
                {content: this.handleNull(duLieu.thDiem), colSpan: 1},
                {
                  content: this.handleNull(duLieu.thNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Tiếng dân tộc", colSpan: 1},
                {content: this.handleNull(duLieu.dtDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.dtNx),
                  colSpan: 1,
                },
                {
                  content: "",
                  colSpan: 1,
                },
              ],
              [
                {content: "Đạo đức", colSpan: 1},
                {content: this.handleNull(duLieu.ddDgtx), colSpan: 1},
                {
                  content: "",
                  rowSpan: 7,
                },
                {
                  content: this.handleNull(duLieu.ddNx),
                  colSpan: 1,
                },
              ],
              [
                {content: "Âm nhạc", colSpan: 1},
                {content: this.handleNull(duLieu.anDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.anNx),
                  colSpan: 1,
                },
                {
                  content: "",
                  colSpan: 1,
                },
              ],
              [
                {content: "Mĩ thuật", colSpan: 1},
                {content: this.handleNull(duLieu.mtDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.mtNx),
                  colSpan: 1,
                },
                {
                  content: "",
                  colSpan: 1,
                },
              ],
              [
                {content: "Kỹ thuật", colSpan: 1},
                {content: this.handleNull(duLieu.ktDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.ktNx),
                  colSpan: 1,
                },
                {
                  content: "",
                  colSpan: 1,
                },
              ],
              [
                {content: "Thể dục", colSpan: 1},
                {content: this.handleNull(duLieu.tdDgtx), colSpan: 1},
                {
                  content: this.handleNull(duLieu.tdNx),
                  colSpan: 1,
                },
                {
                  content: "",
                  colSpan: 1,
                },
              ],
            ]);
        body = body;
        let columnStyles = {
          0: {
            halign: "center",
            valign: "middle",
            fontSize: 12,
            cellWidth: 50,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 2},
          },
          1: {
            halign: "center",
            textAlign: "center",
            fontSize: 12,
            cellPadding: {top: 4, right: 4, bottom: 4, left: 4},
            valign: "middle",
            font: "TimesBold",
            cellWidth: 21.5,
          },
          2: {
            textAlign: "center",
            halign: "center",
            fontSize: 12,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            font: "TimesBold",
            cellWidth: 21.5,
          },
          3: {
            textAlign: "center",
            halign: "left",
            fontSize: 12,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 4},
            valign: "top",
            cellWidth: 91.5,
          },
        };
        doc.autoTable({
          tableLineColor: [70, 76, 70],
          columnStyles: columnStyles,
          head: header,
          body: body,
          startY: 50,
          didDrawCell: function (data) {
            if (data.row.index === 7 && data.column.index === 2) {
              const {doc, cell} = data;
              const startX = cell.x + cell.width;
              const startY = cell.y;
              const endX = cell.x;
              const endY = cell.y + cell.height;
              doc.setLineWidth(0.3);
              doc.line(startX, startY, endX, endY);
            }
          },
          willDrawCell: (data) => {
            if (data.section === "body") {
              const textLines = data.cell.text;
              const increaseSpace = (text, widthDifference) => {
                const words = text.split(" ");
                const targetSpace = 2;
                let splitIndex;
                const conditionMap = {
                  "1-3": Math.floor(words.length / 4.65),
                  "3-8": Math.floor(words.length / 2.45),
                  "8-16": Math.floor((words.length * 4) / 5),
                };
                const conditionKeys = Object.keys(conditionMap);
                const matchedKey = conditionKeys.find((key) => {
                  const [min, max] = key.split("-").map(Number);
                  return widthDifference > min && widthDifference < max;
                });
                splitIndex = matchedKey ? conditionMap[matchedKey] : splitIndex;
                for (let i = 0; i < splitIndex; i++) {
                  words[i] += " ".repeat(targetSpace - 1);
                }
                return words.join(" ");
              };
              const doc = data.doc;
              const convertLines = textLines.map((line, index) => {
                const lineWidth = doc.getTextWidth(line);
                return {line, lineWidth, index};
              });
              const maxWidth = Math.max(...convertLines.map((line) => line.lineWidth));
              let newLinesArray = [];
              convertLines.forEach(({line, lineWidth}) => {
                if (lineWidth > 0) {
                  const widthDifference = maxWidth - lineWidth;
                  line = increaseSpace(line, widthDifference);
                }
                newLinesArray.push(line);
              });
              data.cell.text = newLinesArray;
            }
          },
          includeHiddenHtml: true,
          theme: "grid",
          headStyles: {
            fillColor: [255, 255, 255],
            textColor: [45, 45, 45],
            font: "TimesBold",
            fontStyle: "normal",
            lineWidth: 0.1,
            halign: "center",
            valign: "middle",
            fontSize: 12,
            cellPadding: 1,
          },
          styles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: "TimesBold",
            fontStyle: "normal",
            lineHeight: 1.5,
          },
          bodyStyles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: "VNTimes",
            fontStyle: "normal",
            cellPadding: 2.5,
            minCellHeight: 15,
          },
        });
      } else if (pageNumber == 13) {
        if (this.cauHinh.isAnSoTrang != -1) {
          doc.setFont("VNTimes");
          let textHocBa1 = String(this.cauHinh.isAnSoTrang + pageNumber - 4);
          console.log('textHocBa1')
          console.log(textHocBa1)
          let fontSize1 = 13;
          let textWidth1 =
              (doc.getStringUnitWidth(textHocBa1) * fontSize1) / doc.internal.scaleFactor;
          doc.setFontSize(fontSize1);
          doc.text(textHocBa1, (pageWidth - textWidth1) / 2, 295);
        }
        if (isThongTu27) {
          doc.setLineWidth(0.3);
          doc.setFont("TimesBold");
          doc.text("Trường:", 15, 10);
          let tenTruong = this.countCharacters(duLieu.tenTruong, "", 50);
          const hoten = this.handleNull(duLieu.hoten);
          doc.text(tenTruong, 35, 10);
          doc.text("Năm học:", 158, 10);
          const tenLop = this.handleNull(duLieu.tenLop);
          let namHoc = duLieu.namHoc ? duLieu.namHoc.toString() : "";
          let convertNamHoc = `${namHoc}-${(parseInt(namHoc) + 1)?.toString()}`;
          doc.text(convertNamHoc, 178, 10);
          doc.setFont("TimesBold");
          doc.setFontSize(13);
          doc.text("2. Những phẩm chất chủ yếu", 15, 20);
          let header = [
            [
              {content: "Phẩm chất", colSpan: 1},
              {content: "Mức đạt được", colSpan: 1},
              {
                content: "Nhận xét",
                colSpan: 1,
              },
            ],
          ];
          let body = [
            [
              {content: "Yêu nước", colSpan: 1},
              {
                content: this.handleNull(duLieu.pcYeuNuoc),
                colSpan: 1,
              },
              {
                content: this.removeTabs(duLieu.nxPhamChatChuYeu),
                rowSpan: 5,
              },
            ],
            [
              {content: "Nhân ái", colSpan: 1},
              {content: this.handleNull(duLieu.pcNhanAi), colSpan: 1},
            ],
            [
              {content: "Chăm chỉ", colSpan: 1},
              {content: this.handleNull(duLieu.pcChamChi), colSpan: 1},
            ],
            [
              {content: "Trung thực", colSpan: 1},
              {content: this.handleNull(duLieu.pcTrungThuc), colSpan: 1},
            ],
            [
              {content: "Trách nhiệm", colSpan: 1},
              {content: this.handleNull(duLieu.pcTrachNhiem), colSpan: 1},
            ],
          ];
          let columnStyles = {
            0: {
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellWidth: 60,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 2},
            },
            1: {
              textAlign: "center",
              halign: "center",
              fontSize: 12,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
              valign: "middle",
              font: "TimesBold",
              cellWidth: 30,
            },
            2: {
              halign: "left",
              fontSize: 12,
              cellPadding: {top: 4, right: 4, bottom: 4, left: 4},
              valign: "middle",
              cellWidth: 95,
            },
          };
          const startY_1 = 23;
          doc.autoTable({
            tableLineColor: [70, 76, 70],
            columnStyles: columnStyles,
            head: header,
            body: body,
            startY: startY_1,
            theme: "grid",
            headStyles: {
              fillColor: [255, 255, 255],
              textColor: [45, 45, 45],
              font: "TimesBold",
              fontStyle: "normal",
              lineWidth: 0.1,
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellPadding: 1,
              minCellHeight: 8,
            },
            styles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "TimesBold",
              fontStyle: "normal",
            },
            bodyStyles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "VNTimes",
              fontStyle: "normal",
              cellPadding: 2,
              fontSize: 12,
              minCellHeight: 8,
            },
            willDrawCell: (data) => {
              if (data.section === "body" && data.cell.rowSpan === 5) {
                data.cell.text = this.adjustTextSpacing(data.cell.text, data.doc);
              }
            },
          });
          const endY1 = doc.autoTable.previous.finalY;
          const tableHeight_1 = endY1 - startY_1;
          doc.setFont("TimesBold");
          doc.setFontSize(13);
          doc.text(
              "3. Những năng lực cốt lõi",
              15,
              doc.autoTable.previous.finalY + 6
          );
          doc.setFont("VNTimesItalic");
          doc.text("3.1. Những năng lực chung", 15, doc.autoTable.previous.finalY + 13);
          header = [
            [
              {content: "Năng lực", colSpan: 1},
              {content: "Mức đạt được", colSpan: 1},
              {
                content: "Nhận xét",
                colSpan: 1,
              },
            ],
          ];
          body = [
            [
              {content: "Tự chủ và tự học", colSpan: 1},
              {
                content: this.handleNull(duLieu.nlTuChuTuHoc),
                colSpan: 1,
              },
              {
                content: this.removeTabs(duLieu.nxNangLucChung),
                rowSpan: 3,
              },
            ],
            [
              {
                content: "Giao tiếp và hợp tác",
                colSpan: 1,
              },
              {
                content: this.handleNull(duLieu.nlGiaoTiepHopTac),
                colSpan: 1,
              },
            ],
            [
              {
                content: "Giải quyết vấn đề và sáng tạo",
                colSpan: 1,
              },
              {
                content: this.handleNull(duLieu.nlGquyetVdeSangTao),
                colSpan: 1,
              },
            ],
          ];
          columnStyles = {
            0: {
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellWidth: 60,
              cellPadding: {top: 3, right: 3, bottom: 3, left: 3},
            },
            1: {
              halign: "center",
              fontSize: 12,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
              valign: "middle",
              cellWidth: 30,
              font: "TimesBold",
            },
            2: {
              halign: "left",
              fontSize: 12,
              cellPadding: {top: 2, right: 2, bottom: 2, left: 2},
              valign: "middle",
              cellWidth: 95,
            },
          };
          const startY_2 = doc.autoTable.previous.finalY + 17;
          doc.autoTable({
            tableLineColor: [70, 76, 70],
            columnStyles: columnStyles,
            head: header,
            body: body,
            startY: startY_2,
            theme: "grid",
            headStyles: {
              fillColor: [255, 255, 255],
              textColor: [45, 45, 45],
              font: "TimesBold",
              fontStyle: "normal",
              lineWidth: 0.1,
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellPadding: 1,
            },
            styles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "TimesBold",
              fontStyle: "normal",
            },
            bodyStyles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "VNTimes",
              fontStyle: "normal",
              cellPadding: 2,
              fontSize: 12,
            },
            willDrawCell: (data) => {
              if (data.section === "body" && data.cell.rowSpan === 3) {
                data.cell.text = this.adjustTextSpacing(data.cell.text, data.doc);
              }
            },
          });
          const endY_2 = doc.autoTable.previous.finalY;
          const tableHeight_2 = endY_2 - startY_2;
          doc.setFont("VNTimesItalic");
          doc.text("3.2. Những năng lực đặc thù", 15, doc.autoTable.previous.finalY + 6);
          header = [
            [
              {content: "Năng lực", colSpan: 1},
              {content: "Mức đạt được", colSpan: 1},
              {
                content: "Nhận xét",
                colSpan: 1,
              },
            ],
          ];
          body = [
            [
              {content: "Ngôn ngữ", colSpan: 1},
              {
                content: this.handleNull(duLieu.nlNgonNgu),
                colSpan: 1,
              },
              {content: this.removeTabs(duLieu.nxNangLucDacThu), rowSpan: 7},
            ],
            [
              {content: "Tính toán", colSpan: 1},
              {content: this.handleNull(duLieu.nlTinhToan), colSpan: 1},
            ],
            [
              {content: "Khoa học", colSpan: 1},
              {content: this.handleNull(duLieu.nlKhoaHoc), colSpan: 1},
            ],
            [
              {content: "Công nghệ", colSpan: 1},
              {content: this.handleNull(duLieu.nlCongNghe), colSpan: 1},
            ],
            [
              {content: "Tin học", colSpan: 1},
              {content: this.handleNull(duLieu.nlTinHoc), colSpan: 1},
            ],
            [
              {content: "Thẩm mĩ", colSpan: 1},
              {content: this.handleNull(duLieu.nlThamMi), colSpan: 1},
            ],
            [
              {content: "Thể chất", colSpan: 1},
              {content: this.handleNull(duLieu.nlTheChat), colSpan: 1},
            ],
          ];
          columnStyles = {
            0: {
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellWidth: 60,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 2},
            },
            1: {
              halign: "center",
              fontSize: 12,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
              valign: "middle",
              font: "TimesBold",
              cellWidth: 30,
            },
            2: {
              halign: "left",
              fontSize: 12,
              cellPadding: {top: 4, right: 4, bottom: 4, left: 4},
              valign: "middle",
              cellWidth: 95,
            },
          };
          let startY_3 = doc.autoTable.previous.finalY + 9;
          const createTable = (startY) => {
            doc.autoTable({
              tableLineColor: [70, 76, 70],
              columnStyles: columnStyles,
              head: header,
              body: body,
              startY: startY,
              theme: "grid",
              headStyles: {
                fillColor: [255, 255, 255],
                textColor: [45, 45, 45],
                font: "TimesBold",
                fontStyle: "normal",
                lineWidth: 0.1,
                halign: "center",
                valign: "middle",
                fontSize: 12,
                cellPadding: 1,
                minCellHeight: 8,
              },
              styles: {
                textColor: [45, 45, 45],
                lineColor: [70, 76, 70],
                font: "TimesBold",
                fontStyle: "normal",
              },
              bodyStyles: {
                textColor: [45, 45, 45],
                lineColor: [70, 76, 70],
                font: "VNTimes",
                fontStyle: "normal",
                cellPadding: 2,
                fontSize: 12,
                minCellHeight: 8,
              },
              willDrawCell: (data) => {
                if (data.section === "body" && data.cell.rowSpan === 7) {
                  data.cell.text = this.adjustTextSpacing(data.cell.text, data.doc);
                }
              },
            });
          };
          createTable(startY_3);
          const endY_3 = doc.autoTable.previous.finalY;
          const tableHeight_3 = endY_3 - startY_3;
          const totalTableHeight = tableHeight_1 + tableHeight_2 + tableHeight_3;
          if (totalTableHeight > 155) {
            header = [];
            body = [];
            startY_3 = 10;
            doc.addPage();
            createTable(startY_3);
          } else {
            startY_3 = doc.autoTable.previous.finalY + 9;
          }
        } else {
          doc.setFont("TimesBold");
          let tenTruong = this.countCharacters(duLieu.tenTruong, "", 50);
          doc.text("Trường:", 15, tenTruong.length > 50 ? 12.5 : 15);
          doc.text(tenTruong, 35, tenTruong.length > 50 ? 10 : 15);
          doc.text("Năm học:", 150, tenTruong.length > 50 ? 12.5 : 15);
          let namHoc = duLieu.namHoc ? duLieu.namHoc.toString() : "";
          let convertNamHoc = `${namHoc}-${(parseInt(namHoc) + 1)?.toString()}`;
          doc.text(convertNamHoc, 170, tenTruong.length > 50 ? 12.5 : 15);
          doc.setFont("TimesBold");
          doc.setFontSize(13);
          doc.text("2. Các năng lực", 15, 20);
          let header = [
            [
              {content: "Năng lực", colSpan: 1},
              {content: "Mức đạt được", colSpan: 1},
              {
                content: "Nhận xét",
                colSpan: 1,
              },
            ],
          ];
          let body = [
            [
              {content: "Tự phục vụ, tự quản", colSpan: 1},
              {
                content: this.handleNull(duLieu.nlTpvtq),
                colSpan: 1,
              },
              {
                content: this.removeTabs(duLieu.nangLuc),
                rowSpan: 3,
              },
            ],
            [
              {content: "Hợp tác", colSpan: 1},
              {content: this.handleNull(duLieu.nlHt), colSpan: 1},
            ],
            [
              {content: "Tự học, giải quyết \n vấn đề", colSpan: 1},
              {content: this.handleNull(duLieu.nlThgqvd), colSpan: 1},
            ],
          ];
          let columnStyles = {
            0: {
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellWidth: 60,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 2},
            },
            1: {
              textAlign: "center",
              halign: "center",
              fontSize: 12,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
              valign: "middle",
              font: "TimesBold",
              cellWidth: 30,
            },
            2: {
              halign: "left",
              fontSize: 12,
              cellPadding: {top: 4, right: 4, bottom: 4, left: 4},
              valign: "middle",
              cellWidth: 95,
            },
          };
          const startY_1 = 23;
          doc.autoTable({
            tableLineColor: [70, 76, 70],
            columnStyles: columnStyles,
            head: header,
            body: body,
            startY: startY_1,
            theme: "grid",
            headStyles: {
              fillColor: [255, 255, 255],
              textColor: [45, 45, 45],
              font: "TimesBold",
              fontStyle: "normal",
              lineWidth: 0.1,
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellPadding: 1,
              minCellHeight: 8,
            },
            styles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "TimesBold",
              fontStyle: "normal",
            },
            bodyStyles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "VNTimes",
              fontStyle: "normal",
              cellPadding: 2,
              fontSize: 12,
              minCellHeight: 8,
            },
          });
          const endY1 = doc.autoTable.previous.finalY;
          const tableHeight_1 = endY1 - startY_1;
          doc.text("3. Các phẩm chất", 15, doc.autoTable.previous.finalY + 6);
          header = [
            [
              {content: "Phẩm chất", colSpan: 1},
              {content: "Mức đạt được", colSpan: 1},
              {
                content: "Nhận xét",
                colSpan: 1,
              },
            ],
          ];
          body = [
            [
              {content: "Chăm học, chăm làm", colSpan: 1},
              {
                content: this.handleNull(duLieu.pcChcl),
                colSpan: 1,
              },
              {
                content: this.removeTabs(duLieu.nxPhamChatChuYeu),
                rowSpan: 4,
              },
            ],
            [
              {content: "Tự tin trách nhiệm", colSpan: 1},
              {content: this.handleNull(duLieu.pcTttn), colSpan: 1},
            ],
            [
              {content: "Trung thực, kỷ luật", colSpan: 1},
              {content: this.handleNull(duLieu.pcTtkl), colSpan: 1},
            ],
            [
              {content: "Đoàn kết, yêu thương", colSpan: 1},
              {content: this.handleNull(duLieu.pcDkyt), colSpan: 1},
            ],
          ];
          columnStyles = {
            0: {
              halign: "center",
              valign: "middle",
              fontSize: 12,
              cellWidth: 60,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 2},
            },
            1: {
              halign: "center",
              fontSize: 12,
              cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
              valign: "middle",
              font: "TimesBold",
              cellWidth: 30,
            },
            2: {
              halign: "left",
              fontSize: 12,
              cellPadding: {top: 4, right: 4, bottom: 4, left: 4},
              valign: "middle",
              cellWidth: 95,
            },
          };
          let startY_3 = doc.autoTable.previous.finalY + 9;
          const createTable = (startY) => {
            console.log("startY", startY);
            doc.autoTable({
              tableLineColor: [70, 76, 70],
              columnStyles: columnStyles,
              head: header,
              body: body,
              startY: startY,
              theme: "grid",
              headStyles: {
                fillColor: [255, 255, 255],
                textColor: [45, 45, 45],
                font: "TimesBold",
                fontStyle: "normal",
                lineWidth: 0.1,
                halign: "center",
                valign: "middle",
                fontSize: 12,
                cellPadding: 1,
                minCellHeight: 17,
              },
              styles: {
                textColor: [45, 45, 45],
                lineColor: [70, 76, 70],
                font: "TimesBold",
                fontStyle: "normal",
              },
              bodyStyles: {
                textColor: [45, 45, 45],
                lineColor: [70, 76, 70],
                font: "VNTimes",
                fontStyle: "normal",
                cellPadding: 2,
                fontSize: 12,
                minCellHeight: 17,
              },
            });
          };
          createTable(startY_3);
          const endY_3 = doc.autoTable.previous.finalY;
          const tableHeight_3 = endY_3 - startY_3;
          if (tableHeight_1 > 70 || tableHeight_3 > 115) {
            header = [];
            body = [];
            startY_3 = 10;
            doc.addPage();
            createTable(startY_3);
          } else {
            startY_3 = doc.autoTable.previous.finalY + 9;
          }
        }
        doc.setFontSize(13);
        doc.setFont("TimesBold");
        doc.text("4. Đánh giá kết quả giáo dục:", 15, doc.autoTable.previous.finalY + 6);
        doc.setFont("VNTimes");
        const danhGiaKetQuaGiaoDuc = this.handleNull(duLieu.danhGiaKetQuaGiaoDuc);
        doc.text(danhGiaKetQuaGiaoDuc, 75, doc.autoTable.previous.finalY + 6);
        doc.setFont("TimesBold");
        const khenThuong = this.handleNull(duLieu.noiDungKhenThuong);
        doc.text("5. Khen thưởng: ", 15, doc.autoTable.previous.finalY + 13);
        doc.setFont("VNTimes");
        doc.text(khenThuong, 15, doc.autoTable.previous.finalY + 18);
        doc.setFont("TimesBold");
        const titleContent = this.handleNull(duLieu.tieuDeKyThay);
        const newlineCount = titleContent.split("\n").length - 1;
        doc.text(
            "6. Hoàn thành chương trình lớp học/chương trình tiểu học:",
            15,
            newlineCount === 2
                ? doc.autoTable.previous.finalY + 30
                : doc.autoTable.previous.finalY + 35
        );
        doc.setFont("VNTimes");
        const hoanThanhChuongTrinh = this.countCharacters(
            duLieu.hoanThanhChuongTrinh ?? ""
        );
        doc.text(
            hoanThanhChuongTrinh,
            15,
            newlineCount === 2
                ? doc.autoTable.previous.finalY + 35
                : doc.autoTable.previous.finalY + 40
        );
        const kygiaContent = "(Ký và ghi rõ họ tên)";
        const kygiaWidth = doc.getTextWidth(kygiaContent);
        const kygiaX = 140;
        const kygiaY = doc.autoTable.previous.finalY + 55;
        doc.setFont("VNTimesItalic");
        doc.text(kygiaContent, kygiaX, kygiaY);
        const ngayHieuTruongKy = this.handleNull(duLieu.ngayGVCNKy);
        const ngayHieuTruongWidth = doc.getTextWidth(ngayHieuTruongKy);
        const ngayHieuTruongX = kygiaX + (kygiaWidth - ngayHieuTruongWidth) / 2;
        const ngayHieuTruongY = kygiaY - 10;
        doc.setFont("VNTimes");
        doc.text(ngayHieuTruongKy, ngayHieuTruongX, ngayHieuTruongY);
        const gvChuNhiemContent = "Giáo viên chủ nhiệm";
        const gvChuNhiemWidth = doc.getTextWidth(gvChuNhiemContent);
        const gvChuNhiemX = kygiaX + (kygiaWidth - gvChuNhiemWidth) / 2;
        const gvChuNhiemY = kygiaY - 5;
        doc.setFont("TimesBold");
        doc.text(gvChuNhiemContent, gvChuNhiemX, gvChuNhiemY);
        const tenGVCNContent = this.handleNull(duLieu?.tenGVCN);
        const tenGVCNWidth = doc.getTextWidth(tenGVCNContent);
        const tenGVCNX = kygiaX + (kygiaWidth - tenGVCNWidth) / 2;
        const tenGVCNY = gvChuNhiemY + 37;
        doc.setFont("TimesBold");
        doc.text(tenGVCNContent, tenGVCNX, tenGVCNY);
        const imageX = kygiaX + (kygiaWidth - this.imgSignatureWidth) / 2;
        const imageY = kygiaY + 3;
        console.error('Chữ ký gvcn')
        console.log(this.cauHinh)
        if (!this.cauHinh.isAnAnhGVCN) {
          doc.addImage(
              this.handleNull(duLieu.pathChuKyGVCN),
              "JPEG",
              imageX,
              imageY,
              this.imgSignatureWidth,
              this.imgSignatureHeight
          );
        }
        const imgX = 12;
        const imgY = doc.autoTable.previous.finalY + 45;
        const imgWidth = this.imgStampWidth;
        const imgHeight = this.imgStampHeight;
        if (!this.cauHinh.isDau) {
          doc.addImage(
              this.handleNull(duLieu.pathDau),
              "JPEG",
              imgX,
              imgY,
              imgWidth,
              imgHeight
          );
        }
        const imgWidth2 = 30;
        const imgHeight2 = 20;
        const imgX2 = imgX + imgWidth / 2 + 10;
        const imgY2 = imgY + (imgHeight - imgHeight2) / 2 + 5;
        if (!this.cauHinh.isAnAnhHieuTruong) {
          doc.addImage(
              this.handleNull(duLieu.pathChuKyHieuTruong),
              "JPEG",
              imgX2,
              imgY2,
              imgWidth2,
              imgHeight2
          );
        }

        const signatureContent = "(Ký ghi rõ họ tên và đóng dấu)";
        const signatureWidth = doc.getTextWidth(signatureContent);
        const signatureX = 25;
        const signatureY = doc.autoTable.previous.finalY + 55;
        doc.setFont("VNTimesItalic");
        doc.text(signatureContent, signatureX, signatureY);
        const titleWidth = doc.getTextWidth(titleContent);
        const adjustments = {
          0: {x: 0, y: -5},
          1: {x: 18, y: -10},
          2: {x: 45, y: -15},
        };
        const adjustment = adjustments[newlineCount] || adjustments[0];
        const titleX = signatureX + (signatureWidth - titleWidth) / 2 + adjustment.x;
        const titleY = signatureY + adjustment.y;
        doc.setFont("TimesBold");
        doc.text(titleContent, titleX, titleY);
        const headmasterNameContent = this.handleNull(duLieu.tenHieuTruong);
        const headmasterNameWidth = doc.getTextWidth(headmasterNameContent);
        const headmasterNameX = signatureX + (signatureWidth - headmasterNameWidth) / 2;
        const headmasterNameY = signatureY + 32;
        doc.setFont("TimesBold");
        doc.text(headmasterNameContent, headmasterNameX, headmasterNameY);
      }
    },
    xemPdf(dataUri) {
      const url = URL.createObjectURL(dataUri);
      this.urlPdf = url;
    },
    displayPDF(pdfDataUrl) {
      this.urlPdf = pdfDataUrl;
      return;
    },
  },
};
</script>
<style></style>
