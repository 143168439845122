var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-button", { on: { click: _vm.print } }, [_vm._v("In thử")]),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "printContent",
          staticStyle: {
            "font-family": "'Times New Roman', Times, serif",
            "padding-top": "25px",
          },
        },
        [
          false
            ? _c(
                "div",
                {
                  staticStyle: {
                    "font-family": "'Times New Roman', Times, serif",
                    width: "210mm",
                    height: "297mm",
                    border: "1px solid black",
                    margin: "0 auto",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "bia",
                      staticStyle: {
                        "font-family": "'Times New Roman', Times, serif",
                        border: "4px double black",
                        width: "190mm",
                        height: "277mm",
                        margin: "10mm auto",
                        position: "relative",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "tenTruong",
                          staticStyle: {
                            "font-family": "'Times New Roman', Times, serif",
                            margin: "10mm 15mm",
                          },
                        },
                        [
                          _c(
                            "h6",
                            {
                              staticStyle: {
                                "font-family":
                                  "'Times New Roman', Times, serif",
                                "font-size": "18px",
                              },
                            },
                            [
                              _vm._v(
                                "TRƯỜNG:\n            " +
                                  _vm._s(_vm.thongTinHocBaBia.tenTruong)
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "h6",
                            {
                              staticStyle: {
                                "font-family":
                                  "'Times New Roman', Times, serif",
                                "font-size": "18px",
                              },
                            },
                            [
                              _vm._v(
                                "Huyện/Quận/Thị xã/Thành phố:\n            " +
                                  _vm._s(_vm.thongTinHocBaBia.tenHuyen)
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "h6",
                            {
                              staticStyle: {
                                "font-family":
                                  "'Times New Roman', Times, serif",
                                "font-size": "18px",
                              },
                            },
                            [
                              _vm._v(
                                "Tỉnh/Thành phố:\n            " +
                                  _vm._s(_vm.thongTinHocBaBia.tenTinh)
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._m(0),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "tenHocSinh",
                          staticStyle: {
                            "font-family": "'Times New Roman', Times, serif",
                            "text-align": "center",
                            "padding-top": "50mm",
                          },
                        },
                        [
                          _c(
                            "h6",
                            {
                              staticStyle: {
                                "font-family":
                                  "'Times New Roman', Times, serif",
                                "font-size": "18px",
                              },
                            },
                            [_vm._v("Họ và tên học sinh")]
                          ),
                          _vm._v(" "),
                          _c(
                            "h6",
                            {
                              staticStyle: {
                                "font-family":
                                  "'Times New Roman', Times, serif",
                                "font-size": "18px",
                              },
                            },
                            [_vm._v(_vm._s(_vm.thongTinHocBaBia.tenHS))]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "tenHocSinh",
                          staticStyle: {
                            "font-family": "'Times New Roman', Times, serif",
                            "text-align": "center",
                            position: "absolute",
                            bottom: "0",
                            width: "100%",
                          },
                        },
                        [
                          _c("h6", [
                            _vm._v(
                              "Sổ đăng bộ PCGD: " +
                                _vm._s(_vm.thongTinHocBaBia.soDangBo)
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          false
            ? _c(
                "div",
                {
                  staticStyle: {
                    "font-family": "'Times New Roman', Times, serif",
                    width: "210mm",
                    height: "297mm",
                    border: "1px solid black",
                    margin: "0 auto",
                  },
                },
                [_vm._m(1)]
              )
            : _vm._e(),
          _vm._v(" "),
          true
            ? _c(
                "div",
                {
                  staticStyle: {
                    "font-family": "'Times New Roman', Times, serif",
                    width: "210mm",
                    height: "297mm",
                    border: "1px solid black",
                    margin: "0 auto",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "biaHocBa",
                      staticStyle: {
                        "font-family": "'Times New Roman', Times, serif",
                        width: "190mm",
                        height: "277mm",
                        margin: "10mm auto",
                        position: "relative",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-family": "'Times New Roman', Times, serif",
                            position: "relative",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "anhHs",
                              staticStyle: {
                                "font-family":
                                  "'Times New Roman', Times, serif",
                                position: "absolute",
                                top: "40px",
                              },
                            },
                            [
                              _c("img", {
                                staticStyle: {
                                  "font-family":
                                    "'Times New Roman', Times, serif",
                                  width: "40mm",
                                  height: "60mm",
                                },
                                attrs: {
                                  src: _vm.thongTinHocBaBia.anhTuQLNT
                                    ? _vm.thongTinHocBaBia.anhTuQLNT
                                    : "",
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                staticStyle: {
                                  width: "111px",
                                  position: "absolute",
                                  left: "80px",
                                  top: "130px",
                                },
                                attrs: {
                                  src: "https://huviron-static.dtsgroup.com.vn//dau/7900001_1713932530683.png",
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-family":
                                  "'Times New Roman', Times, serif",
                                "text-align": "center",
                                position: "absolute",
                                width: "100%",
                                top: "0",
                              },
                            },
                            [
                              _vm._m(2),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-family":
                                      "'Times New Roman', Times, serif",
                                    "margin-top": "100px",
                                  },
                                },
                                [
                                  _vm._m(3),
                                  _vm._v(" "),
                                  _vm._m(4),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "font-family":
                                          "'Times New Roman', Times, serif",
                                        "padding-top": "40px",
                                      },
                                    },
                                    [
                                      _c(
                                        "table",
                                        {
                                          staticStyle: {
                                            "font-family":
                                              "'Times New Roman', Times, serif",
                                            width: "100%",
                                          },
                                        },
                                        [
                                          _c("tr", [
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-family":
                                                    "'Times New Roman', Times, serif",
                                                  "text-align": "left",
                                                },
                                              },
                                              [_vm._v("Họ và tên:")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-family":
                                                    "'Times New Roman', Times, serif",
                                                  "text-align": "left",
                                                },
                                                attrs: { colspan: "2" },
                                              },
                                              [
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.thongTinHocBaBia.tenHS
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-family":
                                                    "'Times New Roman', Times, serif",
                                                  "text-align": "left",
                                                },
                                              },
                                              [_vm._v("Giới tính:")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-family":
                                                    "'Times New Roman', Times, serif",
                                                  "text-align": "left",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.thongTinHocBaBia
                                                        .gioiTinh == 1
                                                        ? "Nam"
                                                        : "Nữ"
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("td", {
                                              staticStyle: {
                                                "font-family":
                                                  "'Times New Roman', Times, serif",
                                                "text-align": "left",
                                                width: "20%",
                                              },
                                            }),
                                          ]),
                                          _vm._v(" "),
                                          _c("tr", [
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-family":
                                                    "'Times New Roman', Times, serif",
                                                  "text-align": "left",
                                                },
                                              },
                                              [_vm._v("Ngày sinh:")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-family":
                                                    "'Times New Roman', Times, serif",
                                                  "text-align": "left",
                                                },
                                                attrs: { colspan: "2" },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.thongTinHocBaBia
                                                        .ngaySinh
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-family":
                                                    "'Times New Roman', Times, serif",
                                                  "text-align": "left",
                                                },
                                              },
                                              [_vm._v("Dân tộc:")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-family":
                                                    "'Times New Roman', Times, serif",
                                                  "text-align": "left",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.thongTinHocBaBia
                                                        .tenDanToc
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("td", {
                                              staticStyle: {
                                                "font-family":
                                                  "'Times New Roman', Times, serif",
                                                "text-align": "left",
                                              },
                                            }),
                                          ]),
                                          _vm._v(" "),
                                          _c("tr", [
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-family":
                                                    "'Times New Roman', Times, serif",
                                                  "text-align": "left",
                                                },
                                              },
                                              [_vm._v("Nơi sinh:")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-family":
                                                    "'Times New Roman', Times, serif",
                                                  "text-align": "left",
                                                },
                                                attrs: { colspan: "4" },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.thongTinHocBaBia
                                                        .noiSinh
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("tr", [
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-family":
                                                    "'Times New Roman', Times, serif",
                                                  "text-align": "left",
                                                },
                                                attrs: { colspan: "6" },
                                              },
                                              [
                                                _vm._v(
                                                  "Đối tượng\n                      (con liệt sĩ, con thương binh,...): " +
                                                    _vm._s(
                                                      _vm.thongTinHocBaBia
                                                        .tenDtuongCsach
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("tr", [
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-family":
                                                    "'Times New Roman', Times, serif",
                                                  "text-align": "left",
                                                },
                                                attrs: { colspan: "6" },
                                              },
                                              [
                                                _vm._v(
                                                  "Chỗ ở hiện\n                      tại: " +
                                                    _vm._s(
                                                      _vm.thongTinHocBaBia
                                                        .noiOHienNay
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("tr", [
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-family":
                                                    "'Times New Roman', Times, serif",
                                                  "text-align": "left",
                                                },
                                                attrs: { colspan: "3" },
                                              },
                                              [
                                                _vm._v(
                                                  "Họ và tên\n                      cha: " +
                                                    _vm._s(
                                                      _vm.thongTinHocBaBia
                                                        .hoTenCha
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-family":
                                                    "'Times New Roman', Times, serif",
                                                  "text-align": "left",
                                                },
                                                attrs: { colspan: "3" },
                                              },
                                              [
                                                _vm._v(
                                                  "Nghề\n                      nghiệp: " +
                                                    _vm._s(
                                                      _vm.thongTinHocBaBia
                                                        .ngheNghiepCha
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("tr", [
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-family":
                                                    "'Times New Roman', Times, serif",
                                                  "text-align": "left",
                                                },
                                                attrs: { colspan: "3" },
                                              },
                                              [
                                                _vm._v(
                                                  "Họ và tên\n                      mẹ: " +
                                                    _vm._s(
                                                      _vm.thongTinHocBaBia
                                                        .hoTenMe
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-family":
                                                    "'Times New Roman', Times, serif",
                                                  "text-align": "left",
                                                },
                                                attrs: { colspan: "3" },
                                              },
                                              [
                                                _vm._v(
                                                  "Nghề\n                      nghiệp: " +
                                                    _vm._s(
                                                      _vm.thongTinHocBaBia
                                                        .ngheNghiepMe
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("tr", [
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-family":
                                                    "'Times New Roman', Times, serif",
                                                  "text-align": "left",
                                                },
                                                attrs: { colspan: "3" },
                                              },
                                              [
                                                _vm._v(
                                                  "Họ và tên\n                      người giám hộ: " +
                                                    _vm._s(
                                                      _vm.thongTinHocBaBia
                                                        .hoTenCha
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-family":
                                                    "'Times New Roman', Times, serif",
                                                  "text-align": "left",
                                                },
                                                attrs: { colspan: "3" },
                                              },
                                              [
                                                _vm._v(
                                                  "Nghề\n                      nghiệp: " +
                                                    _vm._s(
                                                      _vm.thongTinHocBaBia
                                                        .hoTenCha
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "font-family":
                                            "'Times New Roman', Times, serif",
                                          height: "160px",
                                          width: "50%",
                                          "margin-left": "50%",
                                          position: "relative",
                                        },
                                      },
                                      [
                                        _c("i", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.thongTinHocBaBia.ngayKyBia
                                            )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.thongTinHocBaBia.tieuDeKyThay
                                            )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "font-family":
                                                "'Times New Roman', Times, serif",
                                              "padding-top": "110px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.thongTinHocBaBia
                                                    .tenHieuTruong
                                                ) +
                                                "\n                  "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("img", {
                                          staticStyle: {
                                            position: "absolute",
                                            top: "30px",
                                            width: "111px",
                                            left: "70px",
                                          },
                                          attrs: {
                                            src: _vm.thongTinHocBaBia.pathDau
                                              ? "https://huviron-static.dtsgroup.com.vn//dau/7900001_1713932530683.png"
                                              : "https://huviron-static.dtsgroup.com.vn//dau/7900001_1713932530683.png",
                                            alt: "Ảnh dấu",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("img", {
                                          staticStyle: {
                                            position: "absolute",
                                            top: "70px",
                                            width: "140px",
                                            left: "130px",
                                          },
                                          attrs: {
                                            src: "https://huviron-static.dtsgroup.com.vn//chuky/1/0/0/1/1/3/8/4/7/4/GV1001138474_7900001_1713932583300.png",
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "font-family":
                                          "'Times New Roman', Times, serif",
                                        "padding-top": "15px",
                                      },
                                    },
                                    [
                                      _c("h5", [_vm._v("QUÁ TRÌNH HỌC TẬP")]),
                                      _vm._v(" "),
                                      _c(
                                        "table",
                                        {
                                          staticStyle: {
                                            "font-family":
                                              "'Times New Roman', Times, serif",
                                            "border-collapse": "collapse",
                                            width: "100%",
                                          },
                                        },
                                        [
                                          _vm._m(5),
                                          _vm._v(" "),
                                          _c("tr", [
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-family":
                                                    "'Times New Roman', Times, serif",
                                                  border: "1px solid",
                                                  height: "34px",
                                                },
                                              },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.thongTinHocBaBia
                                                          .quaTrinhHocTaps[0]
                                                          .namHoc
                                                      )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-family":
                                                    "'Times New Roman', Times, serif",
                                                  border: "1px solid",
                                                  height: "34px",
                                                },
                                              },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.thongTinHocBaBia
                                                          .quaTrinhHocTaps[0]
                                                          .tenLop
                                                      )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-family":
                                                    "'Times New Roman', Times, serif",
                                                  border: "1px solid",
                                                  height: "34px",
                                                  "text-align": "left",
                                                },
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticStyle: {
                                                      "padding-left": "50px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.thongTinHocBaBia
                                                            .quaTrinhHocTaps[0]
                                                            .tenTruong
                                                        )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      position: "relative",
                                                    },
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticStyle: {
                                                        position: "absolute",
                                                        top: "-65px",
                                                        width: "111px",
                                                        right: "100px",
                                                      },
                                                      attrs: {
                                                        src: _vm
                                                          .thongTinHocBaBia
                                                          .pathDau
                                                          ? "https://huviron-static.dtsgroup.com.vn//dau/7900001_1713932530683.png"
                                                          : "https://huviron-static.dtsgroup.com.vn//dau/7900001_1713932530683.png",
                                                        alt: "Ảnh dấu",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("img", {
                                                      staticStyle: {
                                                        position: "absolute",
                                                        top: "-50px",
                                                        width: "140px",
                                                        right: "0px",
                                                      },
                                                      attrs: {
                                                        src: "https://huviron-static.dtsgroup.com.vn//chuky/1/0/0/1/1/3/8/4/7/4/GV1001138474_7900001_1713932583300.png",
                                                        alt: "",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("tr", [
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-family":
                                                    "'Times New Roman', Times, serif",
                                                  border: "1px solid",
                                                  height: "34px",
                                                },
                                              },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.thongTinHocBaBia
                                                          .quaTrinhHocTaps[1]
                                                          .namHoc
                                                      )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-family":
                                                    "'Times New Roman', Times, serif",
                                                  border: "1px solid",
                                                  height: "34px",
                                                },
                                              },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.thongTinHocBaBia
                                                          .quaTrinhHocTaps[1]
                                                          .tenLop
                                                      )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-family":
                                                    "'Times New Roman', Times, serif",
                                                  border: "1px solid",
                                                  height: "34px",
                                                },
                                              },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.thongTinHocBaBia
                                                          .quaTrinhHocTaps[1]
                                                          .tenTruong
                                                      )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("tr", [
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-family":
                                                    "'Times New Roman', Times, serif",
                                                  border: "1px solid",
                                                  height: "34px",
                                                },
                                              },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.thongTinHocBaBia
                                                          .quaTrinhHocTaps[2]
                                                          .namHoc
                                                      )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-family":
                                                    "'Times New Roman', Times, serif",
                                                  border: "1px solid",
                                                  height: "34px",
                                                },
                                              },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.thongTinHocBaBia
                                                          .quaTrinhHocTaps[2]
                                                          .tenLop
                                                      )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-family":
                                                    "'Times New Roman', Times, serif",
                                                  border: "1px solid",
                                                  height: "34px",
                                                },
                                              },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.thongTinHocBaBia
                                                          .quaTrinhHocTaps[2]
                                                          .tenTruong
                                                      )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("tr", [
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-family":
                                                    "'Times New Roman', Times, serif",
                                                  border: "1px solid",
                                                  height: "34px",
                                                },
                                              },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.thongTinHocBaBia
                                                          .quaTrinhHocTaps[3]
                                                          .namHoc
                                                      )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-family":
                                                    "'Times New Roman', Times, serif",
                                                  border: "1px solid",
                                                  height: "34px",
                                                },
                                              },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.thongTinHocBaBia
                                                          .quaTrinhHocTaps[3]
                                                          .tenLop
                                                      )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-family":
                                                    "'Times New Roman', Times, serif",
                                                  border: "1px solid",
                                                  height: "34px",
                                                },
                                              },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.thongTinHocBaBia
                                                          .quaTrinhHocTaps[3]
                                                          .tenTruong
                                                      )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("tr", [
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-family":
                                                    "'Times New Roman', Times, serif",
                                                  border: "1px solid",
                                                  height: "34px",
                                                },
                                              },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.thongTinHocBaBia
                                                          .quaTrinhHocTaps[4]
                                                          .namHoc
                                                      )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-family":
                                                    "'Times New Roman', Times, serif",
                                                  border: "1px solid",
                                                  height: "34px",
                                                },
                                              },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.thongTinHocBaBia
                                                          .quaTrinhHocTaps[4]
                                                          .tenLop
                                                      )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-family":
                                                    "'Times New Roman', Times, serif",
                                                  border: "1px solid",
                                                  height: "34px",
                                                },
                                              },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.thongTinHocBaBia
                                                          .quaTrinhHocTaps[4]
                                                          .tenTruong
                                                      )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.thongTinKqht, function (kqht, index) {
            return [
              _c(
                "div",
                {
                  staticStyle: {
                    "font-family": "'Times New Roman', Times, serif",
                    width: "210mm",
                    height: "297mm",
                    border: "1px solid black",
                    margin: "0 auto",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "biaHocBa",
                      staticStyle: {
                        "font-family": "'Times New Roman', Times, serif",
                        width: "190mm",
                        height: "277mm",
                        margin: "10mm auto",
                      },
                    },
                    [
                      _c("div", [
                        _c(
                          "table",
                          {
                            staticStyle: {
                              "font-family": "'Times New Roman', Times, serif",
                              width: "100%",
                            },
                          },
                          [
                            _c("tr", [
                              _c("td", [
                                _c("span", [_vm._v("Họ và tên:")]),
                                _c("b", [_vm._v(" " + _vm._s(kqht.hoten))]),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c("span", [_vm._v("Lớp:")]),
                                _c("b", [_vm._v(" " + _vm._s(kqht.tenLop))]),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c("span", [_vm._v("Năm học:")]),
                                _c("b", [_vm._v(" " + _vm._s(kqht.strNamHoc))]),
                              ]),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "table",
                          {
                            staticStyle: {
                              "font-family": "'Times New Roman', Times, serif",
                              "border-collapse": "collapse",
                              width: "100%",
                            },
                          },
                          [
                            _vm._m(6, true),
                            _vm._v(" "),
                            _vm._m(7, true),
                            _vm._v(" "),
                            _vm._l(kqht.ketQuaHocTaps, function (item) {
                              return _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "font-family":
                                        "'Times New Roman', Times, serif",
                                      "text-align": "center",
                                      border: "1px solid black",
                                    },
                                  },
                                  [
                                    _c(
                                      "p",
                                      { staticStyle: { padding: "7px" } },
                                      [_vm._v(_vm._s(item.tenMonHoc))]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "font-family":
                                        "'Times New Roman', Times, serif",
                                      "text-align": "center",
                                      border: "1px solid black",
                                    },
                                  },
                                  [_c("p", [_vm._v(_vm._s(item.diemHocKyI))])]
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "font-family":
                                        "'Times New Roman', Times, serif",
                                      "text-align": "center",
                                      border: "1px solid black",
                                    },
                                  },
                                  [_c("p", [_vm._v(_vm._s(item.diemHocKyII))])]
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "font-family":
                                        "'Times New Roman', Times, serif",
                                      "text-align": "center",
                                      border: "1px solid black",
                                    },
                                  },
                                  [_c("p", [_vm._v(_vm._s(item.diemCaNam))])]
                                ),
                                _vm._v(" "),
                                _vm._m(8, true),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "font-family":
                                        "'Times New Roman', Times, serif",
                                      "text-align": "left",
                                      border: "1px solid black",
                                    },
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticStyle: {
                                          "padding-left": "100px",
                                        },
                                      },
                                      [_vm._v(_vm._s(item.tenGiaoVien))]
                                    ),
                                    _vm._v(" "),
                                    _vm._m(9, true),
                                  ]
                                ),
                              ])
                            }),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _c("p", [
                            _vm._v(
                              "Trong bảng này có sửa chữa ở: Không chỗ, thuộc môn học, hoạt động giáo dục:\n              "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "table",
                            {
                              staticStyle: {
                                "font-family":
                                  "'Times New Roman', Times, serif",
                                width: "100%",
                                "margin-top": "60px",
                              },
                            },
                            [
                              _c("tr", [
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "font-family":
                                        "'Times New Roman', Times, serif",
                                      "text-align": "center",
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _c("b", [
                                        _vm._v(
                                          "Xác nhận của Giáo viên chủ nhiệm"
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _vm._m(10, true),
                                      _vm._v(" "),
                                      _vm._m(11, true),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        {
                                          staticStyle: {
                                            "font-family":
                                              "'Times New Roman', Times, serif",
                                            "padding-top": "100px",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.item.tenGiaoVien))]
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "font-family":
                                        "'Times New Roman', Times, serif",
                                      "text-align": "center",
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _c("b", [
                                        _vm._v("Xác nhận của Hiệu trưởng"),
                                      ]),
                                      _vm._v(" "),
                                      _vm._m(12, true),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: { position: "relative" },
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              position: "absolute",
                                              top: "0px",
                                              width: "111px",
                                              left: "45px",
                                            },
                                            attrs: {
                                              src: _vm.item.pathDau
                                                ? "https://huviron-static.dtsgroup.com.vn//dau/7900001_1713932530683.png"
                                                : "https://huviron-static.dtsgroup.com.vn//dau/7900001_1713932530683.png",
                                              alt: "Ảnh dấu",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("img", {
                                            staticStyle: {
                                              position: "absolute",
                                              top: "30px",
                                              width: "160px",
                                              left: "100px",
                                            },
                                            attrs: {
                                              src: "https://huviron-static.dtsgroup.com.vn//chuky/1/0/0/1/1/3/8/4/7/4/GV1001138474_7900001_1713932583300.png",
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        {
                                          staticStyle: {
                                            "font-family":
                                              "'Times New Roman', Times, serif",
                                            "padding-top": "100px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(
                                                _vm.thongTinHocBaBia
                                                  .tenHieuTruong
                                              )
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    "font-family": "'Times New Roman', Times, serif",
                    width: "210mm",
                    height: "297mm",
                    border: "1px solid black",
                    margin: "0 auto",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "biaHocBa",
                      staticStyle: {
                        "font-family": "'Times New Roman', Times, serif",
                        width: "190mm",
                        height: "277mm",
                        margin: "10mm auto",
                      },
                    },
                    [
                      _c("div", [
                        _c(
                          "table",
                          {
                            staticStyle: {
                              "font-family": "'Times New Roman', Times, serif",
                              width: "100%",
                            },
                          },
                          [
                            _c("tr", [
                              _c("td", [
                                _c("span", [_vm._v("Họ và tên:")]),
                                _c("b", [_vm._v(" " + _vm._s(kqht.hoten))]),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c("span", [_vm._v("Lớp:")]),
                                _c("b", [_vm._v(" " + _vm._s(kqht.tenLop))]),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c("span", [_vm._v("Năm học:")]),
                                _c("b", [_vm._v(" " + _vm._s(kqht.strNamHoc))]),
                              ]),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("table", [
                          _vm._m(13, true),
                          _vm._v(" "),
                          _vm._m(14, true),
                          _vm._v(" "),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "font-family":
                                    "'Times New Roman', Times, serif",
                                  "text-align": "center",
                                  border: "1px solid black",
                                  padding: "0",
                                  margin: "0",
                                  display: "block",
                                  "padding-top": "10px",
                                },
                              },
                              [
                                _vm._m(15, true),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      "font-family":
                                        "'Times New Roman', Times, serif",
                                      "text-align": "left",
                                      padding: "10px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "Ngoan, có ý\n                    thức tổ chức kỷ luật, có tinh thần trách nhiệm,\n                    hoàn thành tốt các công việc được\n                    giao."
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticStyle: { "padding-top": "30px" } },
                                  [
                                    _c(
                                      "table",
                                      {
                                        staticStyle: {
                                          "font-family":
                                            "'Times New Roman', Times, serif",
                                          width: "100%",
                                        },
                                      },
                                      [
                                        _c("tr", [
                                          _c("td", {
                                            staticStyle: {
                                              "font-family":
                                                "'Times New Roman', Times, serif",
                                              width: "70%",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "font-family":
                                                  "'Times New Roman', Times, serif",
                                                width: "30%",
                                                "text-align": "center",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    position: "relative",
                                                  },
                                                },
                                                [
                                                  _vm._m(16, true),
                                                  _vm._v(" "),
                                                  _c("img", {
                                                    staticStyle: {
                                                      position: "absolute",
                                                      top: "30px",
                                                      width: "160px",
                                                      left: "0px",
                                                    },
                                                    attrs: {
                                                      src: "https://huviron-static.dtsgroup.com.vn//chuky/1/0/0/1/1/3/8/4/7/4/GV1001138474_7900001_1713932583300.png",
                                                      alt: "",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticStyle: {
                                                        "font-family":
                                                          "'Times New Roman', Times, serif",
                                                        "padding-top": "70px",
                                                      },
                                                    },
                                                    [
                                                      _c("b", [
                                                        _vm._v(
                                                          _vm._s(kqht.tenGVCN)
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "font-family":
                                    "'Times New Roman', Times, serif",
                                  "text-align": "center",
                                  border: "1px solid black",
                                  padding: "0",
                                  margin: "0",
                                  height: "75mm",
                                  display: "block",
                                  "padding-top": "10px",
                                },
                              },
                              [
                                _vm._m(17, true),
                                _vm._v(" "),
                                _c("div", [
                                  _c(
                                    "table",
                                    {
                                      staticStyle: {
                                        "font-family":
                                          "'Times New Roman', Times, serif",
                                        width: "100%",
                                      },
                                    },
                                    [
                                      _c("tr", [
                                        _vm._m(18, true),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticStyle: {
                                              "font-family":
                                                "'Times New Roman', Times, serif",
                                              width: "40%",
                                              "text-align": "center",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  position: "relative",
                                                },
                                              },
                                              [
                                                _c("i", [
                                                  _vm._v(
                                                    _vm._s(
                                                      kqht.ngayHieuTruongKy
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _vm._m(19, true),
                                                _vm._v(" "),
                                                _vm._m(20, true),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  {
                                                    staticStyle: {
                                                      "font-family":
                                                        "'Times New Roman', Times, serif",
                                                      "padding-top": "110px",
                                                    },
                                                  },
                                                  [
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.thongTinHocBaBia
                                                            .tenHieuTruong
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("img", {
                                                  staticStyle: {
                                                    "font-family":
                                                      "'Times New Roman', Times, serif",
                                                    width: "140px",
                                                    height: "auto",
                                                    position: "absolute",
                                                    bottom: "30px",
                                                    left: "80px",
                                                  },
                                                  attrs: {
                                                    src: "https://huviron-static.dtsgroup.com.vn//chuky/1/0/0/1/1/3/8/4/7/4/GV1001138474_7900001_1713932583300.png",
                                                    alt: "Ảnh chữ ký",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("img", {
                                                  staticStyle: {
                                                    width: "111px",
                                                    position: "absolute",
                                                    left: "15px",
                                                    top: "60px",
                                                  },
                                                  attrs: {
                                                    src: "https://huviron-static.dtsgroup.com.vn//dau/7900001_1713932530683.png",
                                                    alt: "Ảnh dấu",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "logoBiaHocBa",
        staticStyle: {
          "font-family": "'Times New Roman', Times, serif",
          "text-align": "center",
          "padding-top": "50mm",
        },
      },
      [
        _c(
          "h1",
          {
            staticStyle: {
              "font-family": "'Times New Roman', Times, serif",
              "font-size": "90px",
            },
          },
          [_vm._v("HỌC BẠ")]
        ),
        _vm._v(" "),
        _c("h4", [_vm._v("TRUNG HỌC CƠ SỞ")]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "bia",
        staticStyle: {
          "font-family": "'Times New Roman', Times, serif",
          width: "190mm",
          height: "277mm",
          margin: "10mm auto",
          position: "relative",
        },
      },
      [
        _c("div", { staticClass: "text-center" }, [
          _c("h4", [_vm._v("HƯỚNG DẪN SỬ DỤNG HỌC BẠ")]),
        ]),
        _vm._v(" "),
        _c("div", [
          _c(
            "h6",
            {
              staticStyle: {
                "font-family": "'Times New Roman', Times, serif",
                "padding-top": "25px",
                "font-size": "16px",
              },
            },
            [_vm._v("1. Quy định\n            chung")]
          ),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "- Học bạ học sinh được nhà trường quản lý và bảo quản trong trường; đầu năm học, cuối học kỳ, cuối\n            năm học, được bàn giao cho giáo viên chủ nhiệm lớp để thực hiện việc ghi vào Học bạ và thu lại sau\n            khi đã hoàn thành."
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "- Nội dung trang 1 phải được ghi đầy đủ khi xác lập Học bạ; Hiệu trưởng ký, đóng dấu xác nhận quá\n            trình học tập từng năm học từ lớp 6 đến lớp 9."
            ),
          ]),
          _vm._v(" "),
          _c(
            "h6",
            {
              staticStyle: {
                "font-family": "'Times New Roman', Times, serif",
                "padding-top": "25px",
                "font-size": "16px",
              },
            },
            [_vm._v("2. Giáo viên\n            môn học")]
          ),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "- Ghi điểm trung bình môn học hoặc mức đánh giá kết quả học tập theo môn học từng học kì, cả năm\n            học; nhận xét sự tiến bộ, ưu điểm nổi bật, hạn chế chủ yếu (nếu có) của học sinh."
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "- Khi sửa chữa (nếu có), dùng bút mực đỏ gạch ngang nội dung cũ, ghi nội dung mới vào phía trên\n            bên phải vị trí ghi nội dung cũ, ký xác nhận về việc sửa chữa bên cạnh nội dung đã sửa."
            ),
          ]),
          _vm._v(" "),
          _c(
            "h6",
            {
              staticStyle: {
                "font-family": "'Times New Roman', Times, serif",
                "padding-top": "25px",
                "font-size": "16px",
              },
            },
            [_vm._v("3. Giáo viên chủ\n            nhiệm")]
          ),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "- Tiếp nhận và bàn giao lại Học bạ học sinh với văn phòng nhà trường."
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "- Đôn đốc việc ghi vào Học bạ điểm trung bình môn học hoặc mức đánh giá kết quả học tập của học\n            sinh của giáo viên môn học."
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "- Ghi đầy đủ các nội dung trên trang 1, nội dung ở phần đầu các trang tiếp theo, nhận xét kết quả rèn\n            luyện và học tập của học sinh theo từng năm học."
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "- Ghi kết quả tổng hợp đánh giá; mức đánh giá lại môn học hoặc rèn luyện thêm trong kì nghỉ hè\n            (nếu có); đánh giá mức độ hoàn thành nhiệm vụ đối với học sinh phải rèn luyện thêm trong kì nghỉ\n            hè (nếu có)."
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "- Ghi rõ được lên lớp hoặc không được lên lớp; hoàn thành hoặc chưa hoàn thành chương trình trung\n            học cơ sở; chứng chỉ (nếu có), kết quả tham gia các cuộc thi (nếu có), khen thưởng (nếu có)."
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "- Ghi nhận xét sự tiến bộ, ưu điểm nổi bật, hạn chế chủ yếu và những biểu hiện nổi bật của học sinh\n            trong quá trình rèn luyện và học tập; những vấn đề cần quan tâm giúp đỡ thêm trong quá trình rèn\n            luyện và học tập."
            ),
          ]),
          _vm._v(" "),
          _c(
            "h6",
            {
              staticStyle: {
                "font-family": "'Times New Roman', Times, serif",
                "padding-top": "25px",
                "font-size": "16px",
              },
            },
            [_vm._v("4. Hiệu\n            trưởng")]
          ),
          _vm._v(" "),
          _c("p", [
            _vm._v("- Phê duyệt Học bạ của học sinh khi kết thúc năm học."),
          ]),
          _vm._v(" "),
          _c("p", [_vm._v("- Kiểm tra việc quản lý, bảo quản, ghi Học bạ.")]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h5", [_vm._v("CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM")]),
      _vm._v(" "),
      _c("h6", [_c("u", [_vm._v("Độc lập - Tự do - Hạnh phúc")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h2",
      {
        staticStyle: {
          "font-family": "'Times New Roman', Times, serif",
          "font-size": "70px",
        },
      },
      [_c("b", [_vm._v("HỌC BẠ")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h3",
      {
        staticStyle: {
          "font-family": "'Times New Roman', Times, serif",
          "font-size": "28px",
        },
      },
      [_c("b", [_vm._v("TRUNG HỌC CƠ SỞ")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c(
        "td",
        {
          staticStyle: {
            "font-family": "'Times New Roman', Times, serif",
            border: "1px solid",
          },
        },
        [_c("p", [_c("b", [_vm._v("Năm học")])])]
      ),
      _vm._v(" "),
      _c(
        "td",
        {
          staticStyle: {
            "font-family": "'Times New Roman', Times, serif",
            border: "1px solid",
          },
        },
        [_c("p", [_c("b", [_vm._v("Lớp")])])]
      ),
      _vm._v(" "),
      _c(
        "td",
        {
          staticStyle: {
            "font-family": "'Times New Roman', Times, serif",
            border: "1px solid",
          },
        },
        [
          _c("p", [
            _c("b", [
              _vm._v("Tên trường,\n                      tỉnh/thành phố"),
            ]),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c(
        "td",
        {
          staticStyle: {
            "font-family": "'Times New Roman', Times, serif",
            "text-align": "center",
            border: "1px solid black",
            width: "4cm",
          },
          attrs: { rowspan: "2" },
        },
        [_vm._v("Môn học/hoạt động giáo dục\n                ")]
      ),
      _vm._v(" "),
      _c(
        "td",
        {
          staticStyle: {
            "font-family": "'Times New Roman', Times, serif",
            "text-align": "center",
            border: "1px solid black",
          },
          attrs: { colspan: "3" },
        },
        [
          _c("b", [
            _vm._v(
              "Điểm trung bình môn học hoặc mắc\n                  đánh giá"
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "td",
        {
          staticStyle: {
            "font-family": "'Times New Roman', Times, serif",
            "text-align": "center",
            border: "1px solid black",
          },
          attrs: { rowspan: "2" },
        },
        [
          _c("b", [
            _vm._v(
              "Điểm trung bình môn học hoặc mắc\n                  đánh giá"
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "td",
        {
          staticStyle: {
            "font-family": "'Times New Roman', Times, serif",
            "text-align": "center",
            border: "1px solid black",
          },
          attrs: { rowspan: "2" },
        },
        [
          _c("b", [
            _vm._v(
              "Nhận xét sự tiến bộ, ưu điểm nổi\n                  bật,\n                  hạn chế chủ yếu (nếu có) và chữ kí của giáo viên môn học"
            ),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c(
        "td",
        {
          staticStyle: {
            "font-family": "'Times New Roman', Times, serif",
            "text-align": "center",
            border: "1px solid black",
          },
        },
        [_c("p", [_vm._v("Học kỳ I")])]
      ),
      _vm._v(" "),
      _c(
        "td",
        {
          staticStyle: {
            "font-family": "'Times New Roman', Times, serif",
            "text-align": "center",
            border: "1px solid black",
          },
        },
        [_c("p", [_vm._v("Học kỳ II")])]
      ),
      _vm._v(" "),
      _c(
        "td",
        {
          staticStyle: {
            "font-family": "'Times New Roman', Times, serif",
            "text-align": "center",
            border: "1px solid black",
          },
        },
        [_c("p", [_vm._v("Cả năm")])]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "td",
      {
        staticStyle: {
          "font-family": "'Times New Roman', Times, serif",
          "text-align": "center",
          border: "1px solid black",
        },
      },
      [_c("p")]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { position: "relative" } }, [
      _c("img", {
        staticStyle: {
          position: "absolute",
          top: "-40px",
          width: "140px",
          left: "0px",
        },
        attrs: {
          src: "https://huviron-static.dtsgroup.com.vn//chuky/1/0/0/1/1/3/8/4/7/4/GV1001138474_7900001_1713932583300.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("i", [_vm._v("(Ký và ghi rõ họ tên)")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { position: "relative" } }, [
      _c("img", {
        staticStyle: {
          position: "absolute",
          top: "30px",
          width: "150px",
          left: "100px",
        },
        attrs: {
          src: "https://huviron-static.dtsgroup.com.vn//chuky/1/0/0/1/1/3/8/4/7/4/GV1001138474_7900001_1713932583300.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("i", [_vm._v("(Ký và ghi rõ họ tên)")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c(
        "td",
        {
          staticStyle: {
            "font-family": "'Times New Roman', Times, serif",
            "text-align": "left",
            border: "1px solid black",
            padding: "0",
            margin: "0",
          },
        },
        [
          _c(
            "table",
            {
              staticStyle: {
                "font-family": "'Times New Roman', Times, serif",
                "border-collapse": "collapse",
                width: "100%",
              },
            },
            [
              _c("tr", [
                _c(
                  "td",
                  {
                    staticStyle: {
                      "font-family": "'Times New Roman', Times, serif",
                      "text-align": "center",
                      border: "1px solid black",
                      width: "2cm",
                    },
                    attrs: { rowspan: "2" },
                  },
                  [_c("b", [_vm._v("Học kỳ")])]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticStyle: {
                      "font-family": "'Times New Roman', Times, serif",
                      "text-align": "center",
                      border: "1px solid black",
                    },
                    attrs: { colspan: "2" },
                  },
                  [_c("b", [_vm._v("Mức đánh giá")])]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticStyle: {
                      "font-family": "'Times New Roman', Times, serif",
                      "text-align": "center",
                      border: "1px solid black",
                    },
                    attrs: { rowspan: "2" },
                  },
                  [
                    _c("b", [
                      _vm._v(
                        "Tổng số buổi nghỉ học cả\n                        năm"
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticStyle: {
                      "font-family": "'Times New Roman', Times, serif",
                      "text-align": "center",
                      border: "1px solid black",
                    },
                    attrs: { colspan: "2" },
                  },
                  [
                    _c("b", [
                      _vm._v(
                        "Mức đánh giá sau đánh\n                        giá lại môn học hoặc rèn\n                        luyện trong kì nghỉ hè\n                        (nếu có)"
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticStyle: {
                      "font-family": "'Times New Roman', Times, serif",
                      "text-align": "left",
                      border: "1px solid black",
                      width: "4cm",
                    },
                    attrs: { rowspan: "5" },
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "font-family": "'Times New Roman', Times, serif",
                        },
                      },
                      [_vm._v("Được lên lớp:")]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "font-family": "'Times New Roman', Times, serif",
                        },
                      },
                      [_vm._v("Không được lên lớp:")]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticStyle: {
                      "font-family": "'Times New Roman', Times, serif",
                      "text-align": "center",
                      border: "1px solid black",
                      width: "2cm",
                    },
                  },
                  [_c("p", [_vm._v("Kết quả rèn luyện")])]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticStyle: {
                      "font-family": "'Times New Roman', Times, serif",
                      "text-align": "center",
                      border: "1px solid black",
                    },
                  },
                  [_c("p", [_vm._v("Kết quả học tập")])]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticStyle: {
                      "font-family": "'Times New Roman', Times, serif",
                      "text-align": "center",
                      border: "1px solid black",
                    },
                  },
                  [_c("p", [_vm._v("Kết quả rèn luyện")])]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticStyle: {
                      "font-family": "'Times New Roman', Times, serif",
                      "text-align": "center",
                      border: "1px solid black",
                    },
                  },
                  [_c("p", [_vm._v("Kết quả học tập")])]
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticStyle: {
                      "font-family": "'Times New Roman', Times, serif",
                      "text-align": "center",
                      border: "1px solid black",
                    },
                  },
                  [_c("p", [_vm._v("Học kỳ I")])]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticStyle: {
                      "font-family": "'Times New Roman', Times, serif",
                      "text-align": "center",
                      border: "1px solid black",
                    },
                  },
                  [_c("p", [_vm._v("Tốt")])]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticStyle: {
                      "font-family": "'Times New Roman', Times, serif",
                      "text-align": "center",
                      border: "1px solid black",
                      width: "2cm",
                    },
                  },
                  [_c("p", [_vm._v("Chưa đạt")])]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticStyle: {
                      "font-family": "'Times New Roman', Times, serif",
                      "text-align": "center",
                      border: "1px solid black",
                    },
                    attrs: { rowspan: "3" },
                  },
                  [_c("p", [_vm._v("Học kỳ I")])]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticStyle: {
                      "font-family": "'Times New Roman', Times, serif",
                      "text-align": "center",
                      border: "1px solid black",
                    },
                    attrs: { rowspan: "3" },
                  },
                  [_c("p", [_vm._v("Học kỳ I")])]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticStyle: {
                      "font-family": "'Times New Roman', Times, serif",
                      "text-align": "center",
                      border: "1px solid black",
                    },
                    attrs: { rowspan: "3" },
                  },
                  [_c("p", [_vm._v("Học kỳ I")])]
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticStyle: {
                      "font-family": "'Times New Roman', Times, serif",
                      "text-align": "center",
                      border: "1px solid black",
                    },
                  },
                  [_c("p", [_vm._v("Học kỳ II")])]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticStyle: {
                      "font-family": "'Times New Roman', Times, serif",
                      "text-align": "center",
                      border: "1px solid black",
                    },
                  },
                  [_c("p", [_vm._v("Tốt")])]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticStyle: {
                      "font-family": "'Times New Roman', Times, serif",
                      "text-align": "center",
                      border: "1px solid black",
                    },
                  },
                  [_c("p", [_vm._v("Đạt")])]
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c(
                  "td",
                  {
                    staticStyle: {
                      "font-family": "'Times New Roman', Times, serif",
                      "text-align": "center",
                      border: "1px solid black",
                    },
                  },
                  [_c("p", [_vm._v("Cả năm")])]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticStyle: {
                      "font-family": "'Times New Roman', Times, serif",
                      "text-align": "center",
                      border: "1px solid black",
                    },
                  },
                  [_c("p", [_vm._v("Tốt")])]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticStyle: {
                      "font-family": "'Times New Roman', Times, serif",
                      "text-align": "center",
                      border: "1px solid black",
                    },
                  },
                  [_c("p", [_vm._v("Đạt")])]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                "font-family": "'Times New Roman', Times, serif",
                "padding-left": "10px",
              },
            },
            [
              _c("p", [
                _c("i", [
                  _vm._v(
                    "- Nếu là lớp cuối cấp thì ghi: Hoàn thành hay không hoàn thành chương trình trung học cơ\n                      sở:"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "table",
                {
                  staticStyle: {
                    "font-family": "'Times New Roman', Times, serif",
                    width: "100%",
                    "margin-top": "25px",
                  },
                },
                [
                  _c("tr", [
                    _c(
                      "td",
                      {
                        staticStyle: {
                          "font-family": "'Times New Roman', Times, serif",
                          "text-align": "left",
                          width: "70%",
                        },
                      },
                      [_c("p", [_vm._v("- Chứng chỉ (nếu có): Không")])]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticStyle: {
                          "font-family": "'Times New Roman', Times, serif",
                          "text-align": "left",
                        },
                      },
                      [_c("p", [_vm._v("Loại:")])]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("p", [
                _c("i", [
                  _vm._v(
                    "- Nếu là lớp cuối cấp thì ghi: Hoàn thành hay không hoàn thành chương trình trung học cơ\n                      sở:"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("i", [
                  _vm._v("- Kết quả tham gia các cuộc thi (nếu có): Không"),
                ]),
              ]),
              _vm._v(" "),
              _c("p", [_c("i", [_vm._v("- Khen thưởng (nếu có): Không")])]),
            ]
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c(
        "td",
        {
          staticStyle: {
            "font-family": "'Times New Roman', Times, serif",
            "text-align": "center",
            border: "1px solid black",
            padding: "0",
            margin: "0",
            height: "3cm",
            display: "block",
            "padding-top": "10px",
          },
        },
        [
          _c("h5", [_c("b", [_vm._v("KẾT QUẢ RÈN LUYỆN TRONG KÌ NGHỈ HÈ")])]),
          _vm._v(" "),
          _c("i", [_vm._v("(Nếu có)")]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [_c("b", [_vm._v("NHẬN XÉT CỦA GIÁO VIÊN CHỦ NHIỆM")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("i", [_vm._v("(Ký và ghi rõ họ tên)")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [_c("b", [_vm._v("PHÊ DUYỆT CỦA HIỆU TRƯỞNG")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "td",
      {
        staticStyle: {
          "font-family": "'Times New Roman', Times, serif",
          width: "60%",
          "text-align": "left",
        },
      },
      [
        _c("p", { staticStyle: { "padding-left": "15px" } }, [
          _vm._v("Đồng ý với nhận xét của giáo viên chủ nhiệm"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_vm._v("HIỆU TRƯỞNG")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("i", [_vm._v("(Ký và ghi rõ họ tên)")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }