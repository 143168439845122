var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.noLiSurround
    ? _c(
        "ul",
        { class: "paginate " + _vm.containerClass },
        [
          _vm.firstLastButton
            ? _c(
                "li",
                {
                  class: [
                    _vm.pageClass,
                    _vm.firstPageSelected() ? _vm.disabledClass : "",
                  ],
                },
                [
                  _c("a", {
                    class: _vm.pageLinkClass,
                    attrs: { tabindex: _vm.firstPageSelected() ? -1 : 0 },
                    domProps: { innerHTML: _vm._s(_vm.firstButtonText) },
                    on: {
                      click: function ($event) {
                        return _vm.selectFirstPage()
                      },
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.selectFirstPage()
                      },
                    },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !(_vm.firstPageSelected() && _vm.hidePrevNext)
            ? _c(
                "li",
                {
                  class: [
                    _vm.prevClass,
                    _vm.firstPageSelected() ? _vm.disabledClass : "",
                  ],
                },
                [
                  _c("a", {
                    class: _vm.prevLinkClass,
                    attrs: { tabindex: _vm.firstPageSelected() ? -1 : 0 },
                    domProps: { innerHTML: _vm._s(_vm.prevText) },
                    on: {
                      click: function ($event) {
                        return _vm.prevPage()
                      },
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.prevPage()
                      },
                    },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.pages, function (page) {
            return _c(
              "li",
              {
                class: [
                  _vm.pageClass,
                  page.selected ? _vm.activeClass : "",
                  page.disabled ? _vm.disabledClass : "",
                  page.breakView ? _vm.breakViewClass : "",
                ],
              },
              [
                page.breakView
                  ? _c(
                      "a",
                      {
                        class: [_vm.pageLinkClass, _vm.breakViewLinkClass],
                        attrs: { tabindex: "0" },
                      },
                      [
                        _vm._t("breakViewContent", [
                          _vm._v(_vm._s(_vm.breakViewText)),
                        ]),
                      ],
                      2
                    )
                  : page.disabled
                  ? _c(
                      "a",
                      { class: _vm.pageLinkClass, attrs: { tabindex: "0" } },
                      [_vm._v(_vm._s(page.content))]
                    )
                  : _c(
                      "a",
                      {
                        class: _vm.pageLinkClass,
                        attrs: { tabindex: "0" },
                        on: {
                          click: function ($event) {
                            return _vm.handlePageSelected(page.index + 1)
                          },
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handlePageSelected(page.index + 1)
                          },
                        },
                      },
                      [_vm._v(_vm._s(page.content))]
                    ),
              ]
            )
          }),
          _vm._v(" "),
          !(_vm.lastPageSelected() && _vm.hidePrevNext)
            ? _c(
                "li",
                {
                  class: [
                    _vm.nextClass,
                    _vm.lastPageSelected() ? _vm.disabledClass : "",
                  ],
                },
                [
                  _c("a", {
                    class: _vm.nextLinkClass,
                    attrs: { tabindex: _vm.lastPageSelected() ? -1 : 0 },
                    domProps: { innerHTML: _vm._s(_vm.nextText) },
                    on: {
                      click: function ($event) {
                        return _vm.nextPage()
                      },
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.nextPage()
                      },
                    },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.firstLastButton
            ? _c(
                "li",
                {
                  class: [
                    _vm.pageClass,
                    _vm.lastPageSelected() ? _vm.disabledClass : "",
                  ],
                },
                [
                  _c("a", {
                    class: _vm.pageLinkClass,
                    attrs: { tabindex: _vm.lastPageSelected() ? -1 : 0 },
                    domProps: { innerHTML: _vm._s(_vm.lastButtonText) },
                    on: {
                      click: function ($event) {
                        return _vm.selectLastPage()
                      },
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.selectLastPage()
                      },
                    },
                  }),
                ]
              )
            : _vm._e(),
        ],
        2
      )
    : _c(
        "div",
        { class: _vm.containerClass },
        [
          _vm.firstLastButton
            ? _c("a", {
                class: [
                  _vm.pageLinkClass,
                  _vm.firstPageSelected() ? _vm.disabledClass : "",
                ],
                attrs: { tabindex: "0" },
                domProps: { innerHTML: _vm._s(_vm.firstButtonText) },
                on: {
                  click: function ($event) {
                    return _vm.selectFirstPage()
                  },
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.selectFirstPage()
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          !(_vm.firstPageSelected() && _vm.hidePrevNext)
            ? _c("a", {
                class: [
                  _vm.prevLinkClass,
                  _vm.firstPageSelected() ? _vm.disabledClass : "",
                ],
                attrs: { tabindex: "0" },
                domProps: { innerHTML: _vm._s(_vm.prevText) },
                on: {
                  click: function ($event) {
                    return _vm.prevPage()
                  },
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.prevPage()
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.pages, function (page) {
            return [
              page.breakView
                ? _c(
                    "a",
                    {
                      class: [
                        _vm.pageLinkClass,
                        _vm.breakViewLinkClass,
                        page.disabled ? _vm.disabledClass : "",
                      ],
                      attrs: { tabindex: "0" },
                    },
                    [
                      _vm._t("breakViewContent", [
                        _vm._v(_vm._s(_vm.breakViewText)),
                      ]),
                    ],
                    2
                  )
                : page.disabled
                ? _c(
                    "a",
                    {
                      class: [
                        _vm.pageLinkClass,
                        page.selected ? _vm.activeClass : "",
                        _vm.disabledClass,
                      ],
                      attrs: { tabindex: "0" },
                    },
                    [_vm._v(_vm._s(page.content))]
                  )
                : _c(
                    "a",
                    {
                      class: [
                        _vm.pageLinkClass,
                        page.selected ? _vm.activeClass : "",
                      ],
                      attrs: { tabindex: "0" },
                      on: {
                        click: function ($event) {
                          return _vm.handlePageSelected(page.index + 1)
                        },
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.handlePageSelected(page.index + 1)
                        },
                      },
                    },
                    [_vm._v(_vm._s(page.content))]
                  ),
            ]
          }),
          _vm._v(" "),
          !(_vm.lastPageSelected() && _vm.hidePrevNext)
            ? _c("a", {
                class: [
                  _vm.nextLinkClass,
                  _vm.lastPageSelected() ? _vm.disabledClass : "",
                ],
                attrs: { tabindex: "0" },
                domProps: { innerHTML: _vm._s(_vm.nextText) },
                on: {
                  click: function ($event) {
                    return _vm.nextPage()
                  },
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.nextPage()
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.firstLastButton
            ? _c("a", {
                class: [
                  _vm.pageLinkClass,
                  _vm.lastPageSelected() ? _vm.disabledClass : "",
                ],
                attrs: { tabindex: "0" },
                domProps: { innerHTML: _vm._s(_vm.lastButtonText) },
                on: {
                  click: function ($event) {
                    return _vm.selectLastPage()
                  },
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.selectLastPage()
                  },
                },
              })
            : _vm._e(),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }