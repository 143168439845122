<template>
  <div id="detailHocBa" class="page page-file file detailHocBa" v-loading.fullscreen.lock="fullscreenLoading">
    <div class="page__title d-flex align-items-center justify-content-between">
      <h1 class="title">Danh sách học bạ</h1>

    </div>
    <div class="box-upload-file">
      <el-form :rules="rulesSearch" ref="searchForm" :model="searchForm" :hide-required-asterisk="true">

        <el-row :gutter="24">
          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3" class="mt-2 mb-2">
            <el-form-item class="d-inline-block" prop="year">
              <eselect style="width:100%" collapseTags v-model="searchForm.year" placeholder="Chọn"
                       filterable @change="onYearChange" :data="list_nam_hoc" :fields="['name','value']"/>
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3" class="mt-2 mb-2">
            <el-form-item class="d-inline-block" prop="education">
              <eselect style="width:100%" :disabled="list_cap_hoc.length==1" @change="capHocChange"
                       v-model="searchForm.education" placeholder="Chọn" filterable
                       :data="list_cap_hoc"
                       :fields="['name','value']"/>
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3" class="mt-2 mb-2">
            <el-form-item class="d-inline-block" prop="grade">
              <eselect style="width:100%" collapseTags v-model="searchForm.grade" @change="khoiHocChange"
                       placeholder="Chọn" filterable :data="optionGrade"
                       :fields="['label','value']"/>
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3" class="mt-2 mb-2">
            <el-form-item class="d-inline-block" prop="class">
              <eselect style="width:100%" collapseTags v-model="searchForm.class" placeholder="Chọn"
                       filterable :data="danh_sach_lop_hoc" :fields="['tenLop','maLop']"/>
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3" class="mt-2 mb-2">
            <el-form-item class="d-inline-block">
              <el-input style="width:100%" v-model="searchForm.student" placeholder="Nhập">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3" class="mt-2 mb-2">
            <el-form-item class="d-inline-block">
              <el-input style="width:100%" v-model="searchForm.maHS" placeholder="Nhập"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="6" :lg="24" :xl="3" class="text-center mt-2 mb-2">
            <el-button type="primary" @click="submitSearch('searchForm')">Tìm kiếm</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div>
      <el-row :gutter="20">
        <el-col :span="12">
          <div style="align-items: center; color: #CC0000">
            <template v-if="listHocBa && listHocBa.thoiHanKy">
              <strong>Thời gian ký đến ngày: {{ listHocBa.thoiHanKy }}</strong>
            </template>
          </div>
          <el-button :disabled="!listHocBaCheck||listHocBaCheck.length==0" class="mb-1 mr-auto" size="small"
                     type="success" @click.prevent="showThongTinKy()"> Ký học bạ
          </el-button>
          <el-button v-if="listHocBaCheck.length && listHocBa.kieuKy==3" class="mb-1 mr-auto" size="small"
                     type="success" plain @click.prevent="kyHocBaListUsbAll"> Ký học bạ
          </el-button>
          <el-button v-if="listHocBaCheck.length && listHocBa.kieuKy==2" class="mb-1 mr-auto" size="small"
                     type="success" plain @click.prevent="kyHocBaListSimCA"> Ký học bạ
          </el-button>
          <div class="file-actions">
            <!--                v-if="listHocBaCheck.length && (listHocBa.kieuKy==3 || listHocBa.kieuKy==4)"-->

            <!--                    <el-button v-if="searchForm.grade>=6&&searchForm.class"-->
            <!--                               @click.prevent="getDiemTongKetMon(searchForm.class)" class="mb-1" size="small" type="primary" plain>-->
            <!--                        Điểm tổng kết-->
            <!--                    </el-button>-->
            <el-button v-if="listHocBaCheck.length && listHocBa.kieuKy==1"
                       @click="openFormOtp2(listHocBaCheck)"
                       class="mb-1" size="small" type="success" plain> Ký học bạ OTP
            </el-button>

          </div>
        </el-col>
        <el-col :span="12">
          <div class="file-actions text-right">
            <div class="cursor-pointer" v-if="kieuKyGiaoVien">

              <el-tooltip content="Đăng nhập VNPT SmartCA" placement="left">
                <el-button v-if="kieuKyGiaoVien==6&&!tokenSmartCa" size="mini" @click="loginSmartCA" type="primary">Đăng
                  nhập VNPT SmartCA {{ tokenSmartCa }}
                </el-button>
              </el-tooltip>
              <el-tooltip content="Thay đổi kiểu ký" placement="left">
                <el-button type="primary" :disabled="countTypeSign==1" style="padding: 5px;margin: 0"
                           @click.prevent="showChonKieuKy = true ">
                  <img style="width: 30px; height: 20px" :src="getPathImgByType(kieuKyGiaoVien)"
                       alt="">
                  Kiểu ký
                </el-button>
              </el-tooltip>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="page__content">
      <div class="listNew mb-5">
        <el-table border fit stripe :lazy="true" :data="listHocBa ? listHocBa.list : []" width="100%"
                  @selection-change="handleSelectionChange" ref="listFile" v-loading="loading">
          <el-table-column type="selection" align="center" width="40">
          </el-table-column>
          <el-table-column label="STT" style="width: 50px" width="50" type="index" class-name="text-center"
                           :index="indexMethod">
          </el-table-column>
          <el-table-column min-width="120" label="Mã học sinh" align="center" property="maHS">
          </el-table-column>
          <el-table-column label="Họ và tên" min-width="160" header-align="center" align="left"
                           property="hoten">
          </el-table-column>
          <el-table-column label="Lớp" property="tenLop" align="center" width="70" class="text-center"
                           style="text-align: center"></el-table-column>
          <el-table-column min-width="160" label="GVCN" property="tenGVCN" class-name="text-center"
                           align="left">
          </el-table-column>
          <el-table-column label="Trạng thái" min-width="90" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.trangThaiHocBa === 1">Đang học</div>
              <div v-if="scope.row.trangThaiHocBa === 3">Chuyển trường</div>
              <div v-if="scope.row.trangThaiHocBa === 4">Bỏ học</div>
              <div v-else></div>
            </template>
          </el-table-column>

          <el-table-column label="Trạng thái ký" align="center">
            <el-table-column v-if="searchForm.grade>5||searchForm.grade==''" width="90" align="center"
                             label="GVBM">
              <template slot-scope="scope">
                <p :class="getClass(scope.row.trangThaiGiaoVien)">{{ scope.row.moTaTrangThaiGiaoVien }}
                  <span v-if="user.role==3||(user.maGV==scope.row.maGVCN)">
<!--                                        <el-tooltip content="Xem chi tiết" placement="right">-->
                    <!--                                            <sup><i style="color:black" @click="xemThongTinKy(scope.row)"-->
                    <!--                                                    class="el-icon-info"></i></sup>-->
                    <!--                                        </el-tooltip>-->
                                    </span>
                </p>
              </template>
            </el-table-column>
            <el-table-column align="center" label="GVCN" width="90">
              <template slot-scope="scope">
                <p :class="getClass(scope.row.trangThaiGVCN)">{{ scope.row.moTaTrangThaiGVCN }}</p>
              </template>
            </el-table-column>
            <el-table-column align="center" label="Hiệu trưởng" width="90">
              <template slot-scope="scope">
                <p :class="getClass(scope.row.trangThaiHieuTruong)">{{
                    scope.row.moTaTrangThaiHieuTruong
                  }}</p>
              </template>
            </el-table-column>
          </el-table-column>

          <el-table-column align="center" width="80" label="Ký" class-name="" fixed="right">
            <template slot-scope="scope">
              <div v-if="scope.row.typeSign!=3">
                <el-tooltip v-if="!scope.row.sign" content="Ký" placement="top">

                  <el-button type="warning" size="mini"
                             @click.prevent="kyMotHocBa(scope.row)" icon="el-icon-edit">
                  </el-button>
                  <!--                                    <el-button v-if="scope.row.typeSign!=4" type="warning" size="mini"-->
                  <!--                                               @click.prevent="showThongTinKyBak(scope.row)" icon="el-icon-edit">-->
                  <!--                                    </el-button>-->
                  <!--                                    <el-button v-else type="warning" size="mini"-->
                  <!--                                               @click.prevent="KyHocBaUsbAllV2(scope.row)" icon="el-icon-edit">-->
                  <!--                                    </el-button>-->
                </el-tooltip>
                <el-tooltip v-else placement="top">
                  <div slot="content">Trường hợp hiển thị nút này <br/> 1. Học bạ đã ký <br/> 2.
                    Giáo viên bộ môn
                    hoặc Giáo viên chủ nhiệm chưa ký <br/> 3. Chưa đến thời gian ký học bạ hoặc đã
                    quá hạn ký <br> 4.Học bạ chưa được chốt dữ liệu. Quý Thầy-Cô báo lại Quản trị viên nhà trường truy
                    cập
                    Hệ thống -> Chốt dữ liệu -> Chốt dữ liệu học bạ.
                  </div>
                  <el-button type="info" size="mini" icon="el-icon-edit"></el-button>
                </el-tooltip>
              </div>
              <div v-else>
                <div v-if="!scope.row.sign">
                  <!-- <SignUsbAll :duLieu="scope.row"></SignUsbAll> -->
                  <el-tooltip content="Ký" placement="right">
                    <el-button data-option="" size="mini" @click="KyHocBaUsbAllV2(scope.row)"
                               type="warning" icon="el-icon-edit">
                    </el-button>
                  </el-tooltip>
                </div>
                <div v-else>
                  <el-tooltip content="Ký" placement="top">
                  </el-tooltip>
                  <el-tooltip placement="top">
                    <div slot="content">Trường hợp hiển thị nút này <br/> 1. Học bạ đã ký <br/>
                      2. Giáo viên bộ môn
                      hoặc Giáo viên chủ nhiệm chưa ký <br/> 3. Chưa đến thời gian ký học bạ hoặc
                      đã quá hạn ký <br> 4.Học bạ chưa được chốt dữ liệu. Quý Thầy-Cô báo lại Quản trị viên nhà trường
                      truy cập
                      Hệ thống -> Chốt dữ liệu -> Chốt dữ liệu học bạ.
                    </div>
                    <el-button type="info" size="mini" icon="el-icon-edit"></el-button>
                  </el-tooltip>
                </div>
              </div>

            </template>
          </el-table-column>
          <el-table-column align="center" min-width="130" label="Hành động" class-name="" fixed="right">
            <template slot-scope="scope">
              <div class="btn__table_action">
                <el-tooltip v-if="user.role==3||(user.maGV==scope.row.maGVCN)" content="Xem học bạ"
                            placement="top">
                  <el-button data-option="" size="mini" @click="xemHocBa(scope.row.id)" type="primary"
                             icon="el-icon-view">
                  </el-button>
                </el-tooltip>
                <el-tooltip v-if="user.capHoc!=1&&scope.row.loaiHocBa!=1" content="Xem điểm"
                            placement="top">
                  <el-button data-option="" size="mini" @click="openFancybox(scope.row.id)"
                             type="success" icon="el-icon-view">
                  </el-button>
                </el-tooltip>

              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="d-flex align-items-center justify-content-between" v-if="listHocBa && listHocBa.total">
          <template>
            <small>
              <i>Danh sách có {{ listHocBa.total }} học sinh </i>
            </small>
          </template>
          <div class="text-right mt-2">
            <pagination v-if="listHocBa" :page-count="getTotalPage(listHocBa.total)" v-model="paginate.page"
                        :initial-page="paginate.page" :click-handler="getHocBa" :prev-text="'Trước'"
                        :next-text="'Sau'" :container-class="'el-pager'" :pageClass="'number'">

            </pagination>
          </div>
        </div>

      </div>
    </div>

    <el-dialog
        :close-on-press-escape="false"
        :close-on-click-modal="false" class="popupSuaDiem" top="5vh" width="85%" title="Xem điểm"
        :visible.sync="showEditDiem" center>
      <div>
        <component :is="viewPopup" :id="idEdit"></component>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button @click="detailHocBaClose">Thoát</el-button>
            </span>
    </el-dialog>

    <el-dialog
        :close-on-press-escape="false"
        :close-on-click-modal="false" fullscreen title="Bảng điểm tổng hợp cả năm" :visible.sync="showDiemTongKet"
        center>
      <div>
        <component :is="viewPopupTongKetMon" :id="idDiemTongKet"></component>
      </div>
    </el-dialog>

    <form-otp @closeDialogOtp="closeDialogOtp()" @success="success()" :type="typeKy" :duLieu="duLieuHocBaCanKy"
              :list-hoc-ba="hocbaDatas" :show-dialog-otp="showDialogOtp">
    </form-otp>

    <dialog-alert v-if="kySoStatus" title="Thông báo" :message="kySoStatus.message" :show-dialog="showDialogAlert"
                  @closeDialog="closeDialogAlert()">

    </dialog-alert>

    <dialog-alert v-if="kyUsbStatus" title="Thông báo" :message="kyUsbStatus.message"
                  :show-dialog="showDialogAlertUsb" @closeDialog="closeDialogAlert()">

    </dialog-alert>

    <el-dialog
        :close-on-press-escape="false"
        :close-on-click-modal="false" title="Chi tiết" width="60%" center custom-class="thongTinKyTheoMonHoc"
        :visible.sync="hien_thi_thong_tin_ky">
      <el-table :max-height="maxHeight" :row-class-name="tableRowClassName" :data="list_trang_thai_ky_theo_mon">
        <el-table-column label="STT" width="50" align="center" type="index" class-name="text-center"
                         :index="indexMethod">
        </el-table-column>
        <el-table-column property="tenMonHoc" label="Môn học" width="110"></el-table-column>
        <el-table-column property="tenGiaoVien" label="Giáo viên"></el-table-column>
        <el-table-column label="GVCN">
          <template>
            <p>{{ tenGiaoVienChuNhiem }}</p>
          </template>
        </el-table-column>
        <el-table-column align="center" label="Trạng thái" width="100">
          <template slot-scope="scope">
            <div v-if="scope.row.trangThaiXacNhan==1">Đã ký</div>
            <div v-if="scope.row.trangThaiXacNhan==0">Chưa ký</div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
        :close-on-press-escape="false"
        :close-on-click-modal="false" title="Chọn kiểu ký" width="40%" center custom-class="thongTinKyTheoMonHoc"
        append-to-body :show-close="false"
        :visible.sync="showChonKieuKy">
      <el-row :gutter="24">
        <el-col :span="24" class="text-center">
          <el-radio class="checkTypeSign" v-for="(type,i) in listKieuKy" v-model="kieuKyGiaoVien" :key="i"
                    @change="chonKieuKy()" :label="type.value">
            <img style="width:90px; height: 70px" :src="getPathImg(type)" alt="Ảnh">
          </el-radio>
        </el-col>
      </el-row>
    </el-dialog>


    <el-dialog
        :close-on-press-escape="false"
        :close-on-click-modal="false" top="5vh" title="Xác nhận ký tài liệu" width="40%" append-to-body
        :show-close="false"
        center custom-class="hienThiThoiGianCho"
        :visible.sync="hien_thi_thoi_gian_doi_ky">
      <el-row :gutter="24">
        <el-col :span="24">
          <p>Yêu cầu ký số đã được gửi về thiết bị di động.</p>
          <p style="white-space: nowrap">Mở
            <span v-if="kieuKyGiaoVien!=2">ứng dụng</span>
            <span v-if="kieuKyGiaoVien==2">thiết bị di động <b>nhập mã PIN</b></span>
            <span v-if="kieuKyGiaoVien==5">MySign</span>
            <span v-if="kieuKyGiaoVien==6">VNPT SmartCA</span>
            và nhấn <b>Xác nhận</b> để ký tài liệu.</p>
        </el-col>
        <el-col :xs="24" :sm="12">
          <div class="bg-purple-light pt-3">
            <p><b class="pd-r-14">Ứng dụng: </b>
              <span v-if="kieuKyGiaoVien==2">Viettel CA</span>
              <span v-if="kieuKyGiaoVien==5">MySign</span>
              <span v-if="kieuKyGiaoVien==6">VNPT SmartCA</span>
            </p>
            <p style="white-space: nowrap"><b class="pd-r-10">Trạng thái: </b> Chờ xác nhận</p>
          </div>
          <div v-if="kieuKyGiaoVien!=2" class="text-center pt-3">
            <p>Thời gian còn lại</p>
            <h4 style="color: #00A65A">{{ getMinutes(timeLeft) }}</h4>
          </div>
          <div v-if="kieuKyGiaoVien!=2">
            <p>Cài đặt ứng dụng tại</p>
          </div>
          <div v-if="kieuKyGiaoVien!=2">
            <el-row :gutter="24">
              <el-col :span="12">
                <img @click.prevent="getAppAndroid()" src="/images/ch_android.png" alt="">
                <img @click.prevent="getAppIos()" src="/images/appstore_ios.png" alt="">
              </el-col>
              <el-col :span="12">
                <img v-if="kieuKyGiaoVien==5" style="width: 80% !important;"
                     src="/images/qr_code_viettel.png"
                     alt="Ảnh qr">
                <img v-if="kieuKyGiaoVien==6" style="width: 80% !important;"
                     src="/images/qr_code_vnpt.png"
                     alt="Ảnh qr">
              </el-col>
            </el-row>
          </div>
          <div v-else class="text-center">
            <img src="/images/loading1.gif" alt="">
          </div>
        </el-col>
        <el-col :sm="12" class="hidden-xs-only hide-in-mobile">
          <img src="/images/mobile_ca.png" alt="ảnh">
        </el-col>
      </el-row>
    </el-dialog>

    <KyHocBaListUsbAll v-if="kieuKy == 3" :duLieu="duLieuKyHocBaListUsbAll" @load="openLoading"
                       @close="kyHocBaUsbXong()" @error="closeLoading" :thuchienky="thuc_hien_ky_list_usb_all">
    </KyHocBaListUsbAll>
    <KyListHocBaUsbAllV2Edoc v-if="kieuKyGiaoVien == 4" :typeKy="'dongDauBia'" :dulieu="duLieuKyHocBaListUsbAll"
                             @load="openLoading" @close="kyHocBaUsbXong()" @error="closeLoading"
                             :thuchienky="thuc_hien_ky_list_usb_all_edoc">
    </KyListHocBaUsbAllV2Edoc>
    <KyHocBaUsbAllV2 v-if="kieuKy == 4" :dulieu="duLieuKyHocBaUsbAll" :typeKy="'hocba'"
                     :thuchienky="thuc_hien_ky_usb_v2" @done="submitSearch('searchForm')" @close="kyHocBaUsbXong()">
    </KyHocBaUsbAllV2>
    <ThongTinHocBa :show="ThongTinHocBa" :id_hoc_ba="idHocBa" @close="ThongTinHocBa = false"></ThongTinHocBa>
  </div>

</template>

<script>
import KyHocBaListUsbAll from "./KyHocBaListUsbAll";
import websocket from "../websocket";
import ChiTietHocBa from "./GiaoVien/ChiTietHocBa";
import diemTongKetMon from "./GiaoVien/diemTongKetMon";
import {
  mapActions,
  mapState
} from 'vuex';
import Pagination from "../components/Pagination";
import dialog from "../dialog";
import FormOtp from "../components/FormOtp";
import DialogAlert from "../components/DialogAlert";
import SignUsb from "./SignUsb";
import KyHocBaUsbAllV2 from "./KyHocBaUsbAllV2";
import KyListHocBaUsbAllV2Edoc from "./KyListHocBaUsbAllV2Edoc2V2.vue";
import KyHocBaUsb from "./KyHocBaUsb";
import ChonKhoiHoc from "../components/ChonKhoiHoc";
import CapHocKhoiHoc from "../components/CapHocKhoiHoc";
import ThongTinHocBa from "../pages/HocBa/ThongTinHocBa";
import ESelectVue from "./Ui/ESelect";
import api from '../_helpers/api';
import constant from '../_helpers/constant_api';
import msgType from '../_helpers/constant_msg_type';
import constant_api from "../api";


export default {
  name: "DanhSachHocSinh",
  metaInfo: {
    title: 'Danh sách học sinh',
    titleTemplate: '',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: ''
    }]
  },
  computed: {
    ...mapState('teacher', [
      "listLop",
      // "listHocBa",
      "updateMessage",
      "updateSuccess",
      "kySoStatus",
      "reportUrl",
      "reportStatus",
      "kyUsbStatus"
    ]),
    ...mapState('hocba', [
      'ketQuaHocTapTemp',
      "fileDetail",
      "fileStatus",
      "diemTongKetMon",

    ]),
    ...mapState('account', [
      'user',
      'list_nam_hoc',
      'nam_hoc_hien_tai',
      'list_cap_hoc',
    ])
  },
  components: {
    DialogAlert,
    Pagination,
    FormOtp,
    SignUsb,
    ChonKhoiHoc,
    CapHocKhoiHoc,
    KyHocBaUsbAllV2,
    KyListHocBaUsbAllV2Edoc,
    ThongTinHocBa,
    KyHocBaUsb,
    KyHocBaListUsbAll,
    'eselect': ESelectVue,
  },

  data() {

    return {
      tokenSmartCa: '',
      listHocBa: {},
      kieuKy: '',
      thuc_hien_ky_list_usb_all: false,
      thuc_hien_ky_list_usb_all_edoc: false,
      thuc_hien_ky_usb_v2: false,
      duLieuKyHocBaListUsbAll: [],
      duLieuKyHocBaUsbAll: {},
      tenGiaoVienChuNhiem: "",
      loading: false,
      showDialogAlertUsb: false,
      showDialogAlert: false,
      ThongTinHocBa: false,
      typeKy: 1,
      list_trang_thai_ky_theo_mon: [],
      maxHeight: window.innerHeight,
      hien_thi_thong_tin_ky: false,
      hocbaDatas: [],
      duLieuHocBaCanKy: {},
      showDialogOtp: false,
      hien_thi_thoi_gian_doi_ky: false,
      demNguoc: null,
      timeLeft: 90,
      listHocBaCheck: [],
      viewPopup: ChiTietHocBa,
      viewPopupTongKetMon: diemTongKetMon,
      idEdit: '',
      idDiemTongKet: {},
      idHocBa: '',
      showEditDiem: false,
      showDiemTongKet: false,
      paginate: {
        start: 0,
        limit: 100,
        page: 1,
      },
      data: [1, 2, 3, 4, 5],
      fullscreenLoading: false,
      showChonKieuKy: false,
      kieuKyGiaoVien: '',
      listKieuKy: [],
      typeOtpSms: {label: 'SIMCA', value: 1, icon: 'icon_otp_sms.png'},
      typeSimCa: {label: 'SIMCA', value: 2, icon: 'icon_sim_ca.png'},
      typeUsbToken: {label: 'USB TOKEN', value: 4, icon: 'icon_usb_token.png'},
      typeSmartCaVt: {label: 'SMART_CA_VIETTEL', value: 5, icon: 'icon_mysign.png'},
      typeSmartCaVnpt: {label: 'SMART_CA_VNPT', value: 6, icon: 'icon_vnpt_ca.png'},
      optionEducation: [{
        value: 1,
        label: 'Cấp 1'
      },
        {
          value: 2,
          label: 'Cấp 2'
        },
        {
          value: 3,
          label: 'Cấp 3'
        },
        {
          value: 15,
          label: 'Liên cấp'
        },
        {
          value: 145,
          label: 'Liên cấp'
        },
        {
          value: 14,
          label: 'Liên cấp'
        },
        {
          value: 13,
          label: 'Liên cấp'
        },
        {
          value: 451,
          label: 'Liên cấp'
        },
        {
          value: 23,
          label: 'Liên cấp'
        },
        {
          value: 236,
          label: 'Liên cấp'
        },
        {
          value: 36,
          label: 'Liên cấp'
        },
        {
          value: 452,
          label: 'Liên cấp'
        },
        {
          value: 1245,
          label: 'Liên cấp'
        },
        {
          value: 12,
          label: 'Liên cấp'
        },
        {
          value: 123,
          label: 'Liên cấp'
        },
        {
          value: 12345,
          label: 'Liên cấp'
        },
        {
          value: 1235,
          label: 'Liên cấp'
        },
        {
          value: 6,
          label: 'Trung tâm GDTX'
        },
      ],
      optionGrade: [],
      danh_sach_lop_hoc: [],
      khoiCap1: [{
        value: 1,
        label: 'Khối 1'
      },
        {
          value: 2,
          label: 'Khối 2'
        },
        {
          value: 3,
          label: 'Khối 3'
        },
        {
          value: 4,
          label: 'Khối 4'
        },
        {
          value: 5,
          label: 'Khối 5'
        },
      ],
      khoiCap2: [{
        value: 6,
        label: 'Khối 6'
      },
        {
          value: 7,
          label: 'Khối 7'
        },
        {
          value: 8,
          label: 'Khối 8'
        },
        {
          value: 9,
          label: 'Khối 9'
        },
      ],
      khoiCap3: [{
        value: 10,
        label: 'Khối 10'
      },
        {
          value: 11,
          label: 'Khối 11'
        },
        {
          value: 12,
          label: 'Khối 12'
        },

      ],
      optionStudent: [{
        value: '',
        label: 'Học sinh'
      },
        {
          value: 1,
          label: 'HS 1'
        },
        {
          value: 2,
          label: 'HS 2'
        },
        {
          value: 3,
          label: 'HS 3'
        },
      ],
      optionStatus: [{
        value: '',
        label: 'Trạng thái'
      },
        {
          value: 1,
          label: 'Đã ký'
        },
        {
          value: 2,
          label: 'Đã ký duyệt'
        },
        {
          value: 3,
          label: 'Chưa ký'
        },
      ],
      searchForm: {
        year: '',
        education: '',
        grade: '',
        class: '',
        student: '',
        status: '',
        maHS: ''
      },
      rulesSearch: {
        year: [{
          required: true,
          message: 'Bạn chưa chọn năm học',
          trigger: ['change', 'blur']
        }],
        // education : [
        //     {required : true, message : 'Bạn chưa chọn cấp học',trigger: 'change'}
        // ],
        grade: [{
          required: true,
          message: 'Chưa chọn khối học',
          trigger: ['change', 'blur']
        }],
        class: [{
          required: true,
          message: 'Chưa chọn lớp học',
          trigger: ['change', 'blur']
        }],
        // student : [
        //     {required : true, message : 'Bạn chưa chọn học sinh',trigger: 'change'}
        // ],
        // status : [
        //     {required : true, message : 'Bạn chưa chọn trạng thái',trigger: 'change'}
        // ]
      }
    }
  },

  methods: {
    loginSmartCA() {

      localStorage.setItem('routerLoginSmartCa', 'KyDauHocBa')
      console.log('loginSmartCa 12');
      let configParams = {
        typeLogin: 'hocBa',
        response_type: 'code',
        redirect_uri: 'https://hocba.edu.vn/smca-login',
        client_id: '4063-637907329184885701.apps.smartcaapi.com',
        scope: 'sign offline_access'
      }

      let query = this.objectToQueryString(configParams);
      var uriRedirect = 'https://gwsca.vnpt.vn/auth/authorize' + "?" + query;
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      setTimeout(() => {
        loading.close();
        window.location.href = uriRedirect
      }, 500)
    },
    kyMotHocBa(item) {
      console.error('Ký một học bạ:')
      console.error(item)
      this.listHocBaCheck = [item];
      this.showThongTinKy();
    },
    getPathImgByType(type) {
      let icon = ''
      if (type == 1) {
        icon = 'icon_otp_sms.png'
      }
      if (type == 2) {
        icon = 'icon_sim_ca.png'
      }
      if (type == 4) {
        icon = 'icon_usb_token.png'
      }
      if (type == 5) {
        icon = 'icon_mysign.png'
      }
      if (type == 6) {
        icon = 'icon_vnpt_ca.png'
      }
      let objIcon = {
        icon: icon
      }
      return this.getPathImg(objIcon)
    },
    chonKieuKy() {
      console.error('Chọn kiểu kỳ:')
      console.error(this.kieuKyGiaoVien)
      localStorage.setItem('kieuKyHocBaGiaoVien', this.kieuKyGiaoVien);
      this.showChonKieuKy = false;
    },
    getPathImg(item) {
      let result = window.location.protocol + '//' + window.location.hostname + '/images/' + item.icon;
      return result;
    },
    getKieuKy() {
      console.log('getKieuKy');
      this.fullscreenLoading = true;
      let uri = constant_api.giaovien.getKieuKy;
      let params = {}
      this.listKieuKy = [];
      api.get(uri, params).then(
          response => {
            console.error("Kiểu ký trả về:")
            console.error(response)
            var kieuKyHocBaGiaoVien = localStorage.getItem('kieuKyHocBaGiaoVien')
            if (response.data.code == 200) {
              this.countTypeSign = response.data.data.toString().split('').length;
              if (this.countTypeSign > 1) {
                let listKieuKy = response.data.data.toString().split('');
                for (let i = 0; i < listKieuKy.length; i++) {
                  if (listKieuKy[i] == 1) {
                    this.listKieuKy.push(this.typeOtpSms)
                  }
                  if (listKieuKy[i] == 2) {
                    this.listKieuKy.push(this.typeSimCa)
                  }
                  if (listKieuKy[i] == 4) {
                    this.listKieuKy.push(this.typeUsbToken)
                  }
                  if (listKieuKy[i] == 5) {
                    this.listKieuKy.push(this.typeSmartCaVt)
                  }
                  if (listKieuKy[i] == 6) {
                    this.listKieuKy.push(this.typeSmartCaVnpt)
                  }
                }
                console.error(this.listKieuKy)
                if (!kieuKyHocBaGiaoVien) {
                  this.showChonKieuKy = true;
                } else {
                  this.kieuKyGiaoVien = parseInt(kieuKyHocBaGiaoVien)
                }
              } else {
                this.kieuKyGiaoVien = response.data.data
                console.error('Kiểu ký giáo viên:')
                console.error(this.kieuKyGiaoVien)
              }
            }
            this.fullscreenLoading = false;
          }
      ).catch((e) => {
        // this.thongBao('success', 'Hệ thống đang cập nhật dữ liệu. Vui lòng chờ trong ít phút.');
        this.fullscreenLoading = false;
      })

    },
    getListLop() {
      this.fullscreenLoading = true;
      let url = constant.giaovien.listLop;
      let params = {
        namHoc: this.searchForm.year,
        start: 0,
        limit: 999,
        khoiHoc: this.searchForm.grade
      }
      this.danh_sach_lop_hoc = [];
      api.get(url, params).then(
          response => {
            console.log("Danh sách lớp học trả về:")
            console.log(response)
            if (response.data.code == 200) {
              this.danh_sach_lop_hoc = response.data.data.list
            } else {
              this.thongBao("error", response.data.msg)
            }
            this.fullscreenLoading = false;
          }
      )
    },
    layDanhSachHocBa(e) {
      this.fullscreenLoading = true;
      let url = constant.giaovien.listHocba;
      let params = {
        start: this.paginate.start,
        limit: this.paginate.limit,
        maLop: this.searchForm.class,
        namHoc: this.searchForm.year ? this.searchForm.year : '',
        capHoc: this.searchForm.education,
        khoiHoc: this.searchForm.grade,
        tenHS: this.searchForm.student,
        maHS: this.searchForm.maHS,
        trangThai: this.searchForm.status
      }
      api.get(url, params).then(
          response => {
            console.log("Danh sách học bạ trả về:")
            console.log(response)
            if (response.data.code == 200) {
              console.error('123')
              this.listHocBa = response.data.data;
              console.error(this.listHocBa)
            } else {
              this.thongBao("error", response.data.msg)
            }
            this.fullscreenLoading = false;
            this.loading = false;
          }
      )
    },
    openLoading() {
      console.log("open loading")
      this.fullscreenLoading = true;
    },
    closeLoading() {
      this.thuc_hien_ky_usb_v2 = false;
      this.thuc_hien_ky_list_usb_all = false;
      this.thuc_hien_ky_list_usb_all_edoc = false;
      this.fullscreenLoading = false;
    },
    KyHocBaUsbListAllV2() {
      console.log("Ký ds học bạ usb all")
      this.fullscreenLoading = true;
      console.log(this.listHocBaCheck)
      this.duLieuKyHocBaListUsbAll = this.listHocBaCheck;
      this.thuc_hien_ky_list_usb_all_edoc = true;
      // console.log(e)
    },
    KyHocBaUsbAllV2(e) {
      console.log("Ký học bạ usb all")
      // this.fullscreenLoading = true;
      this.duLieuKyHocBaUsbAll = e;
      this.thuc_hien_ky_usb_v2 = true;
      console.log(e)
    },

    kyHocBaListUsbAll() {
      console.log("Ký lisst học bạ usb: type 3")
      this.fullscreenLoading = true;
      this.thuc_hien_ky_list_usb_all = true;
      this.duLieuKyHocBaListUsbAll = this.listHocBaCheck;
    },

    kyHocBaUsbXong() {
      this.fullscreenLoading = false;
      this.thuc_hien_ky_usb_v2 = false;
      this.thuc_hien_ky_list_usb_all = false;
      this.thuc_hien_ky_list_usb_all_edoc = false;
    },
    onReceiveMessage(e) {
      let data = e.data;
      if (data.other == msgType.type.signSimCADongDauKqht || data.other == msgType.type.signCloudCAViettelBiaHocBa || data.other == msgType.type.signCloudCAVNPTDongDauKqht) {
        if (data.code == 200) {
          this.getHocBa(this.paginate.page);
          this.fullscreenLoading = false;
          this.hien_thi_thoi_gian_doi_ky = false;
        } else {
          this.fullscreenLoading = false;
          this.hien_thi_thoi_gian_doi_ky = false;
        }
      }


    },
    tableRowClassName({
                        row,
                        rowIndex
                      }) {
      console.log("Check trạng thái")
      console.log(row)
      if (row.trangThaiXacNhan == 1) {
        return 'success-row';
      } else {
        return 'warning-row';
      }
      return '';
    },
    xemThongTinKy(e) {
      console.log("Xem thông tin ký")
      console.log(e);
      this.tenGiaoVienChuNhiem = e.tenGVCN;
      this.list_trang_thai_ky_theo_mon = e.ketQuaHocTaps;
      this.hien_thi_thong_tin_ky = true;
    },
    getClass(e) {
      let result = "";
      if (e == 1) {
        result = "daKy"
      }
      if (e == -1) {
        result = "chuaKy"
      }
      if (e == 0) {
        result = "dangKy"
      }
      return result;
    },
    showThongTinKy() {
      console.error('show thông tin ký:')
      console.error(this.listHocBaCheck)
      console.error(this.kieuKyGiaoVien)
      if (this.kieuKyGiaoVien == 2) {
        this.dongDauSmartCaViettel()
      }
      if (this.kieuKyGiaoVien == 5) {
        this.dongDauViettelCloudCa()
      }
      if (this.kieuKyGiaoVien == 6) {
        this.dongDauVNPTCloudCa()
      }
      if (this.kieuKyGiaoVien == 4) {
        this.KyHocBaUsbListAllV2();
      }
      return;
      if (duLieu.typeSign == 1) {
        console.log("Ký OTP")
        this.openFormOtp(duLieu, 1);
      }
      if (duLieu.typeSign == 3) {
        console.log("Ký USB token")
      }

      if (duLieu.typeSign == 2) {
        console.log("Ký SIM CA")
        this.kySo(duLieu, 1);
      }
    },
    dongDauVNPTCloudCa() {
      console.log("dongDauVNPTCloudCa")
      let datas = [];
      let capHoc = '';
      let maGVCN = '';
      let maLop = '';
      this.listHocBaCheck.forEach(item => {
        datas.push({
          id: item.id,
          type: item.loaiHocBa,
          maLop: item.maLop,
          maGVCN: item.maGVCN
        })
        capHoc = item.loaiHocBa;
        maGVCN = item.maGVCN;
        maLop = item.maLop;
      })

      let data = {
        msgType: msgType.type.signCloudCAVNPTDongDauKqht,
        reqs: datas,
        type: capHoc,
        maGVCN: maGVCN,
        maLop: maLop,
        token: this.tokenSmartCa.data.access_token
      }

      this.$confirm('Xác nhận ký danh sách các học bạ đã chọn?', 'Thông báo', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
        closeOnClickModal: false,
      })
          .then(_ => {
            console.log("Gửi:")
            console.log(data)
            this.showDialogWait();
            websocket.sendMessage(data);
          })
          .catch(_ => {
          });
    },
    dongDauViettelCloudCa() {
      console.log("kyHocBaListCloudCA")
      let datas = [];
      let capHoc = '';
      let maGVCN = '';
      let maLop = '';
      this.listHocBaCheck.forEach(item => {
        datas.push({
          id: item.id,
          type: item.loaiHocBa,
          maLop: item.maLop,
          maGVCN: item.maGVCN
        })
        capHoc = item.loaiHocBa;
        maGVCN = item.maGVCN;
        maLop = item.maLop;
      })

      let data = {
        msgType: msgType.type.signCloudCAViettelBiaHocBa,
        reqs: datas,
        type: capHoc,
        maGVCN: maGVCN,
        maLop: maLop,
      }
      console.error('Dữ liệu đóng dấu:')
      console.error(data)
      this.$confirm('Xác nhận đóng dấu học bạ đã chọn?', 'Thông báo', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
        closeOnClickModal: false,
      })
          .then(_ => {
            console.log("Gửi:")
            console.log(data)
            websocket.sendMessage(data);
            this.showDialogWait();
          })
          .catch(_ => {
          });
    },
    dongDauSmartCaViettel() {
      console.log("kyHocBaListCloudCA")
      let datas = [];
      let capHoc = '';
      let maGVCN = '';
      let maLop = '';
      this.listHocBaCheck.forEach(item => {
        datas.push({
          id: item.id,
          type: item.loaiHocBa,
          maLop: item.maLop,
          maGVCN: item.maGVCN
        })
        capHoc = item.loaiHocBa;
        maGVCN = item.maGVCN;
        maLop = item.maLop;
      })

      let data = {
        msgType: msgType.type.signSimCADongDauKqht,
        reqs: datas,
        type: capHoc,
        maGVCN: maGVCN,
        maLop: maLop,
      }
      console.error('Dữ liệu đóng dấu:')
      console.error(data)
      this.$confirm('Xác nhận đóng dấu học bạ đã chọn?', 'Thông báo', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
        closeOnClickModal: false,
      })
          .then(_ => {
            console.log("Gửi:")
            console.log(data)
            websocket.sendMessage(data);
            this.showDialogWait();
          })
          .catch(_ => {
          });
    },
    showDialogWait() {
      console.error('showDialogWait')
      this.hien_thi_thoi_gian_doi_ky = true;
      this.clearTimer()
      this.getTimeDown();
      this.demNguocThoiGian(true);
    },
    getTimeDown() {
      this.timeLeft = 90;
      console.error('getTimeDown')
      if (this.kieuKyGiaoVien == 5) {
        this.timeLeft = 185;
      }
      if (this.kieuKyGiaoVien == 6) {
        this.timeLeft = 300;
      }
    },
    demNguocThoiGian(check) {
      if (this.timeLeft >= 0) {
        this.demNguoc = setInterval(() => {
          this.timeLeft--
        }, 1000)
      } else {
        clearInterval(this.demNguoc);
        this.demNguoc = null
      }
    },
    clearTimer() {
      console.error('clearTimer')
      if (this.demNguoc) {
        clearInterval(this.demNguoc);
        this.demNguoc = null
      }
    },
    getMinutes(second) {
      if (second <= 0) {
        return '00:00'
      } else {
        let sec = 60;
        let phut = (second - second % sec) / sec;
        let giay = second % sec;
        if (phut < 10) {
          phut = '0' + phut;
        }
        if (giay < 10) {
          giay = '0' + giay
        }
        return phut + ':' + giay;
      }
    },
    getDiemTongKetMon(e) {
      this.layDiemTongKetMon({
        maLop: e,
      });
      this.idDiemTongKet = this.diemTongKetMon;
      this.showDiemTongKet = true;
    },
    getCurrentMonth() {
      let d = new Date();
      return d.getMonth();
    },
    ...mapActions('teacher', [
      "clear",
      "updateHocBa",
      "kySoHocBa",
      "getReport",

    ]),
    ...mapActions("hocba", [
      "getDetail",
      "saveTempKq",
      "getFile",
      "layDiemTongKetMon",
    ]),

    kyVnpt() {

    },

    indexMethod(index) {
      return (this.paginate.start + index) + 1;
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.listFile.toggleRowSelection(row);
        });
      } else {
        this.$refs.listFile.clearSelection();
      }
    },
    getKhoiHoc() {
      let arr = [];
      this.optionGrade = [];
      for (let i = 0; i < this.list_cap_hoc.length; i++) {
        if (this.list_cap_hoc[i].value == 1) {
          arr = arr.concat(this.khoiCap1)
        }
        if (this.list_cap_hoc[i].value == 2) {
          arr = arr.concat(this.khoiCap2)
        }
        if (this.list_cap_hoc[i].value == 3) {
          arr = arr.concat(this.khoiCap3)
        }
      }
      this.optionGrade = arr;
    },
    closeDialogOtp() {
      this.toggleSelection();
      this.layDanhSachHocBa({
        start: this.paginate.start,
        limit: this.paginate.limit,
        maLop: this.searchForm.class,
        namHoc: this.searchForm.year ? this.searchForm.year : '',
        capHoc: this.searchForm.education,
        khoiHoc: this.searchForm.grade,
        tenHS: this.searchForm.student,
        maHS: this.searchForm.maHS,
        trangThai: this.searchForm.status
      });
      this.showDialogOtp = false;
    },

    handleSelectionChange(val) {
      this.listHocBaCheck = val;

    },

    xemBaoCao() {
      this.fullscreenLoading = true;
      this.getReport({
        maLop: this.searchForm.class,
        namHoc: this.searchForm.year ? this.searchForm.year : '',
      })
    },

    detailHocBaClose() {
      this.saveTempKq(null);
      this.showEditDiem = false;
    },
    dongDiemTongKetMon() {
      this.showDiemTongKet = false;
    },

    submitSearch(formName) {
      this.thuc_hien_ky_usb_v2 = false;
      this.thuc_hien_ky_list_usb_all_edoc = false;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.getHocBa(1)
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    getHocBa(select) {
      this.loading = true;
      this.showDialogOtp = false;
      this.paginate.start = (select - 1) * this.paginate.limit ? (select - 1) * this.paginate.limit : 0;
      this.paginate.page = select;
      this.layDanhSachHocBa();
    },
    success() {
      this.layDanhSachHocBa();
      this.showDialogOtp = false;
    },
    onYearChange() {

      this.searchForm.student = '';
      this.searchForm.maHS = '';
      this.searchForm.status = '';
      this.searchForm.grade = '';
      this.searchForm.class = '';
      this.danh_sach_lop_hoc = []
    },
    capHocChange() {
      this.searchForm.student = '';
      this.searchForm.maHS = '';
      this.searchForm.status = '';
      this.searchForm.grade = ''
      if (!this.searchForm.education) {
        this.getKhoiHoc();
        return;
      } else {
        if (this.searchForm.education == 1) {
          this.optionGrade = this.khoiCap1;
        } else if (this.searchForm.education == 2) {
          this.optionGrade = this.khoiCap2;
        } else {
          this.optionGrade = this.khoiCap3;
        }
      }
    },
    khoiHocChange() {
      this.getListLop();
      this.searchForm.class = '';

    },

    getTotalPage(number) {
      return Math.ceil(number / (this.paginate.limit));
    },

    viewFile(id) {
      this.fullscreenLoading = true;
      this.getFile({
        maHocBa: id
      })
    },
    xemHocBa2(e) {
      this.idHocBa = e;
      this.ThongTinHocBa = true;
    },
    xemHocBa(id) {
      this.fullscreenLoading = true;
      this.getFile({
        maHocBa: id
      })
    },

    openFancybox(id) {
      this.idEdit = id;
      this.getDetail({
        id: id
      })
      this.showEditDiem = true;
    },
    openFormOtp(e, type) {
      this.duLieuHocBaCanKy = e;
      let id = e.id
      this.hocbaDatas = [id];
      this.showDialogOtp = true;
    },
    kyUsb(id) {

    },
    kySo(e, type) {
      let duLieu = e;
      let id = e.id;
      console.log("Type ký" + type)
      this.$confirm('Xác nhận ký học bạ này?', 'Thông báo', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
        closeOnClickModal: false,
      })
          .then(_ => {
            this.fullscreenLoading = true;
            websocket.sendMessage({
              type: duLieu.loaiHocBa,
              maLop: duLieu.maLop,
              maGVCN: duLieu.maGVCN,
              id: id,
              msgType: 2 //ký SimCA
            });
          })
          .catch(_ => {
          });
    },

    kySo2(ids, type) {
      if (type) {
        this.typeKy = type;
      } else {
        this.typeKy = null;
      }
      let data = [];
      ids.map((val) => {
        data.push({
          id: val
        })
        return val;
      });
      dialog.confirmDialog('Xác nhận ký học bạ', 'Xác nhận', () => {
        this.fullscreenLoading = true;
        this.kySoHocBa({
          reqs: data,
          type: type == 1 ? '' : type
        })
      })
    },
    openFormOtp2(ids) {
      console.log(ids);
      let datas = [];
      let capHoc = null;
      let maGVCN = null;
      let maLop = null;
      for (let i = 0; i < ids.length; i++) {
        if (ids[i].maGVCN) {
          maGVCN = ids[i].maGVCN
        }
        capHoc = ids[i].loaiHocBa
        if (ids[i].maLop) {
          maLop = ids[i].maLop
        }
        ;
        if (ids[i].sign == false) {
          datas.push(ids[i].id)
        }
      }

      let duLieuHocBaCanKy = {
        loaiHocBa: capHoc,
        maLop: maLop,
        maGVCN: maGVCN,

      }
      this.duLieuHocBaCanKy = duLieuHocBaCanKy;
      this.hocbaDatas = datas;
      console.log("hocbaDatas")
      console.log(this.hocbaDatas)
      if (this.hocbaDatas.length > 0) {
        this.showDialogOtp = true;
      } else {

        this.thongBao('error', 'Danh sách học bạ đã ký')
      }
    },
    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t
      }
      if (type == "success") {
        cl = "dts-noty-success"
      }
      if (type == "warning") {
        cl = "dts-noty-warning"
      }
      if (type == "error") {
        cl = "dts-noty-error"
      }
      if (type == "info") {
        cl = "dts-noty-info"
      }
      document.querySelector('.overlay').style.display = 'block';
      this.$message({
        onClose: () => {
          document.querySelector('.overlay').style.display = 'none';
        },
        dangerouslyUseHTMLString: true,
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000
      });
    },
    openFancyBox(url) {
      $.fancybox.open({
        src: url,
        type: 'iframe',
      });
    },
    checkDuyet(item) {
      let role = this.user.role;
      if (role == 2 || item.role == 2) {
        if (!item.trangThaiXacNhanGVCN && item.trangThaiKyGVCN) {
          return true
        }
      } else if (role == 3) {
        if (item.trangThaiXacNhanGVCN && !item.trangThaiXacNhanHieuTruong) {
          return true;
        }
      }
      return false;
    },
    checkEdit(item) {
      let role = this.user.role;
      if (role == 1) {
        if (item.nguonDuLieu) {
          return true
        }
      } else if (role == 2) {
        if (item.nguonDuLieu) {
          return true;
        }
      }
      return false;
    },
    closeDialogAlert() {
      this.layDanhSachHocBa();
      this.showDialogAlert = false;
      this.showDialogAlertUsb = false;
    },
    getFullYear() {
      let d = new Date();
      return d.getFullYear();
    },

    checkPluginCallback() {
      console.log('1232331')
    },
    logData(e) {
      console.log(e);

    }
  },

  beforeMount() {
    window.addEventListener('message', this.onReceiveMessage, false);
    if (this.user.role == 1) {
      this.optionStatus = [{
        value: '',
        label: 'Trạng thái'
      },
        {
          value: 0,
          label: 'Chưa ký'
        },
        {
          value: 1,
          label: 'Đã ký'
        },
      ];
    }
    if (this.user.role == 2) {
      this.optionStatus = [{
        value: '',
        label: 'Trạng thái'
      },
        {
          value: 2,
          label: 'Giáo viên bộ môn đang ký'
        },
        {
          value: 3,
          label: 'Giáo viên bộ môn đã ký'
        },
        {
          value: 4,
          label: 'Giáo viên chủ nhiệm đã ký'
        },
      ];
    }
    if (this.user.role == 3) {
      this.optionStatus = [{
        value: '',
        label: 'Trạng thái'
      },
        {
          value: 4,
          label: 'GVCN đã ký'
        },
        {
          value: 5,
          label: 'GVCN chưa ký'
        },
        {
          value: 6,
          label: 'Hiệu trưởng đã ký'
        },
      ];
    }
  },
  mounted() {
    this.searchForm.year = this.nam_hoc_hien_tai
    this.layDanhSachHocBa();
    this.getKieuKy();

    if (localStorage.getItem('dataTokenVnptCa')) {
      this.tokenSmartCa = JSON.parse(localStorage.getItem('dataTokenVnptCa'))
      console.error('tokenSmartCa')
      console.error(this.tokenSmartCa)
    }
    // this.optionGrade = this.khoiCap1.concat(this.khoiCap2, this.khoiCap3);
    this.getKhoiHoc();
  },
  watch: {
    kySoStatus(val) {
      console.log(val);
      if (val) {
        this.fullscreenLoading = false;
        this.showDialogAlert = true;
        if (val == 'success') {
          console.log('success');
          this.layDanhSachHocBa();
        }
      }
    },
    kyHocBaStatus(val) {
      if (val == 'success') {
        this.layDanhSachHocBa();
      }
    },
    kyUsbStatus(val) {
      console.log(val);
      if (val) {
        this.fullscreenLoading = false;
        this.showDialogAlertUsb = true;

      }
    },
    fileDetail(val) {

      if (this.fileStatus == 2) {
        this.fullscreenLoading = false;
        let linkPfd = this.fileDetail + '#toolbar=0';
        this.openFancyBox(linkPfd)
      }
    },

    reportUrl(val) {
      if (this.reportStatus == 2) {
        this.fullscreenLoading = false;
        if (val) {
          this.openFancyBox(this.reportUrl)
        } else {
          dialog.showDialog('Thông báo', 'Không tìm thấy báo cáo')
        }
      }
      if (this.reportStatus == 1) {
        this.fullscreenLoading = false;
        dialog.showDialog('Thông báo', this.reportUrl)
      }
    },
    listHocBaCheck(newVal, oldVal) {
      if (newVal.length) {
        newVal.forEach(item => {
          if (item.sign) {
            this.$alert('Vui lòng chỉ chọn học bạ chưa ký', 'Thông báo', {
              confirmButtonText: 'Đóng',
              type: 'warning',
              callback: action => {
                this.$refs.listFile.clearSelection()
                this.$refs.listFile.toggleRowSelection(oldVal);
              }
            });
            return;
          }
        })
      }
    }
  },

}
</script>

<style scoped>
.btn__table_action button {
  padding: 8px 10px;
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.el-form-item {
  width: 100%
}

p {
  padding: 0;
  margin: 0;
}

.dangKy {
  color: #409EFF;
}

.chuaKy {
  color: #E6A23C;
}

.daKy {
  color: #479e24;
}

.el-dialog.thongTinKyTheoMonHoc {
  min-width: 375px;
}

.el-dialog.el-dialog--center.thongTinKyTheoMonHoc {
  min-width: 375px;
}

.bg-purple-light {
  background: #e5e9f2;
  border-radius: 5px;
  padding: 10px 15px;
}

.el-radio {
  color: #606266;
  cursor: pointer;
  padding: 15px 8px;
  background: whitesmoke;
  border-radius: 8px;
  margin: 10px 15px;
}
</style>