import util from "./util";
import msgType from "./_helpers/constant_msg_type";

export default {
    connection: null,
    disconect: false,
    timer1: null,
    connectWebSocket(uri) {
        let that = this;
        let url = BASE_URL_WEBSOCKET;
        console.log("websocket connect to: " + url)
        this.connection = new WebSocket(url);
        this.connection.binaryType = 'arraybuffer';
        this.connection.onmessage = (event) => {
            //Các action không phải là chat
            let data_arr = new Uint8Array(event.data);
            let string_data;
            string_data = data_arr.slice(4, data_arr.length);
            string_data = new TextDecoder("utf-8").decode(new Uint8Array(string_data));
            let data = JSON.parse(string_data);
            // console.error('response wsk:')
            // console.error(data)
            window.postMessage(data, "*");

        };
        this.connection.onopen = function (event) {
            clearInterval(that.timer1);
            that.timer1 = null;

            that.disconect = false;
            //console.log("Successfully connected to the echo websocket server...")
            var user = JSON.parse(localStorage.getItem("user"));
            // let token ='eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyTm8iOiI2MTUxZGFmNjdiNzNlZGVlMDk3YTM2YzAiLCJpc3MiOiJIT0NCQURJRU5UVSIsImlhdCI6MTY0NTU4OTAzNH0.Ejjnhag6SClSbtBf52zYGc7NhSfP30y3XNy67lTBEZQ';
            let token = util.getCookie('token');
            let data = {
                msgType: -1,
                token: token
            }
            if (token){
                that.sendMessage(data);
                that.timer1 = setInterval(() => {
                    if (that.connection && that.connection.readyState === 1) {
                        let data2 = {
                            msgType: msgType.type.reConnect,
                            token: token
                        }
                        that.sendMessage(data2);
                        // let token2 = util.getCookie('token');
                        util.setCookie('token',token,60)
                    }
                }, 5000);
            }else{
                this.connection.close();
            }


        };
        this.connection.onerror = function (e) {
            //console.log(e);
            //console.error('Socket encountered error: ', e.message, 'Closing socket');
            clearInterval(that.timer1);
            that.timer1 = null;
            switch (e.code) {
                // Try and reconnect
                case 'ECONNREFUSED':
                    if (that.disconect)
                        break;
                    that.reconnect(e);
                    break;
                // Otherwise run error
                default:
                    // that.onerror(e);
                    break;
            }
        };
        this.connection.onclose = function (e) {
            clearInterval(that.timer1);
            that.timer1 = null;
            //console.log("websocket close:");
            let trangThaiWebsocket = {
                code: 311,
                msg: "Websocket đã đóng"
            }

            window.postMessage(trangThaiWebsocket, "*");//action: 1 respon websocket;2:status websocket
            //console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
            switch (e.code) {
                // Normal closure
                case 1000:
                    if (that.debug) {
                        //console.log("[WS]: Closed");
                    }
                    break;
                // Abnormal closure
                default:
                    if (that.disconect)
                        break;
                    that.reconnect(e);
                    break;
            }
        };
    },
    reconnect: function (e) {
        console.log("reconnect websocket...");
        var that = this;
        if (that.debug) {
            //console.log(`[WS]: Reconnecting in 1 seconds.`);
        }
        setTimeout(function () {
            // Log reconnecting
            if (that.debug) {
                //console.log("[WS]: Reconnecting...");
            }
            that.reconnected = true;
            let trangThaiWebsocket = {
                code: 200,
                msg: "Đang kết nối lại với Websocket"
            }
            window.postMessage(trangThaiWebsocket, "*");//action: 1 respon websocket;2:status websocket
            var uri = BASE_URL_WEBSOCKET;
            that.connection = null;
            that.connectWebSocket(uri)
        }, 1000)
    },
    sendMessage(data) {
        // console.log('sendMessage')
        // console.log("Request send to websocket:")
        // console.log(data)
        let msgType = data.msgType;
        let dulieu = this.buildData(2, data, msgType);
        this.connection.send(dulieu);
    },
    buildData(roomId, str, type) {
        let dataString = JSON.stringify(str);
        let dataStr = this.getMesByteArray(dataString);
        let byteLength = dataStr.byteLength + 9;
        let arr = new ArrayBuffer(byteLength); // an Int32 takes 4 bytes
        let view = new DataView(arr);
        view.setInt32(0, parseInt(roomId)); // byteOffset = 0; litteEndian = false
        view.setInt8(4, type);
        view.setInt32(5, dataStr.byteLength);
        let data = new Uint8Array(arr);
        data.set(dataStr, 9);
        return data;
    },
    getMesByteArray(str) {
        //console.log("getMesByteArray");
        let textEncoder = new TextEncoder();
        let strByte = textEncoder.encode(str);
        return strByte;
    },
    close() {
        clearInterval(this.timer1);
        this.timer1 = null;
        if (this.connection)
            this.connection.close();
        this.connection = null;
        this.disconect = true;
    }
}