var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c(
      "div",
      {
        staticClass:
          "page__title d-flex align-items-center justify-content-between",
      },
      [
        _c("h1", { staticClass: "title" }, [
          _vm._v("Nhập nhận xét-khen thưởng"),
        ]),
        _vm._v(" "),
        _c(
          "el-tooltip",
          {
            staticClass: "item",
            attrs: {
              effect: "dark",
              content: "Hướng dẫn nhập dữ liệu",
              placement: "top",
            },
          },
          [
            _c(
              "a",
              {
                staticClass: "link",
                attrs: { href: "/files/huong_dan_nhap_du_lieu.docx" },
              },
              [_vm._v(" Hướng dẫn nhập dữ liệu")]
            ),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "page__header" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c(
              "el-col",
              { attrs: { xs: 12, sm: 6, md: 4, lg: 4 } },
              [
                _c("label", [_vm._v("Năm học")]),
                _vm._v(" "),
                _c("eselect", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    collapseTags: "",
                    placeholder: "Chọn",
                    filterable: "",
                    data: _vm.list_nam_hoc,
                    fields: ["name", "value"],
                  },
                  model: {
                    value: _vm.namHoc,
                    callback: function ($$v) {
                      _vm.namHoc = $$v
                    },
                    expression: "namHoc",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              { attrs: { xs: 12, sm: 6, md: 4, lg: 4 } },
              [
                _c("label", [_vm._v("Cấp học")]),
                _vm._v(" "),
                _c("eselect", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    collapseTags: "",
                    placeholder: "Chọn",
                    filterable: "",
                    data: _vm.list_cap_hoc,
                    fields: ["name", "value"],
                  },
                  model: {
                    value: _vm.capHoc,
                    callback: function ($$v) {
                      _vm.capHoc = $$v
                    },
                    expression: "capHoc",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "page__header" },
      [
        _c("h5", { staticClass: "title" }, [_vm._v("Khen thưởng")]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("ImportGeneral", {
          attrs: {
            "require-cap-hoc": true,
            "require-nam-hoc": true,
            requireImportType: true,
            year: _vm.namHoc,
            "cap-hoc": _vm.capHoc,
            fileNameError: "danh_sach_loi_nhap_khen_thuong",
            "data-type": _vm.capHoc == 1 ? 10 : 14,
            "text-download": "Tải file nhập Khen thưởng",
            requireKhoiHoc: true,
            hideClass: false,
            "uri-dowload": _vm.dowloadKhenThuong,
            source: _vm.source,
            path:
              _vm.typeKhenThuong === 1
                ? _vm.pathKhenThuong
                : _vm.pathKhenThuongChung,
            importType: _vm.typeKhenThuong,
            "name-file": "khen-thuong",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _vm.capHoc != ""
      ? _c(
          "div",
          { staticClass: "page__header" },
          [
            _c("h5", { staticClass: "title" }, [
              _vm._v("Nhận xét của giáo viên chủ nhiệm"),
            ]),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _c("ImportGeneral", {
              attrs: {
                "require-cap-hoc": true,
                "require-nam-hoc": true,
                requireImportType: true,
                year: _vm.namHoc,
                "cap-hoc": _vm.capHoc,
                fileNameError: "danh_sach_loi_nhap_nhan_xet_gvcn",
                requireKhoiHoc: true,
                hideClass: false,
                "text-download": "Tải file nhập Nhận xét của GVCN",
                "data-type": 15,
                "uri-dowload": _vm.dowloadGVCN,
                source: _vm.source,
                path:
                  _vm.typeNXGvcn === 1
                    ? _vm.pathNhanXetGVCN
                    : _vm.pathNhanXetGVCNChung,
                importType: _vm.typeNXGvcn,
                "name-file": "nx-gvcn",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.capHoc != 1 &&
    _vm.capHoc != "" &&
    (_vm.user.isHieuTruong || _vm.user.isCanBo)
      ? _c(
          "div",
          { staticClass: "page__header" },
          [
            _c("h5", { staticClass: "title" }, [
              _vm._v("Nhận xét của hiệu trưởng"),
            ]),
            _vm._v(" "),
            _vm._m(2),
            _vm._v(" "),
            _c("ImportGeneral", {
              attrs: {
                "require-cap-hoc": true,
                "require-nam-hoc": true,
                requireImportType: false,
                year: _vm.namHoc,
                "cap-hoc": _vm.capHoc,
                fileNameError: "danh_sach_loi_nhap_nhan_xet_hieu_truong",
                "data-type": 16,
                requireKhoiHoc: true,
                hideClass: false,
                "text-download": "Tải file nhập Nhận xét của Hiệu trưởng",
                "uri-dowload": _vm.dowloadHieuTruong,
                source: _vm.source,
                path:
                  _vm.typeNXHieuTruong === 1
                    ? _vm.pathNhanXetHieuTruong
                    : _vm.pathNhanXetHieuTruongChung,
                importType: _vm.typeNXHieuTruong,
                "name-file": "nx-hieu-truong",
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("small", [
      _c("i", [
        _vm._v(
          " (Truy cập Học bạ số -> Nhập nhận xét - khen thưởng -> Tải file khen thưởng)"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("small", [
      _c("i", [
        _vm._v(
          " (Truy cập Học bạ số -> Nhập nhận xét - khen thưởng -> Tải file Nhận xét của Giáo viên chủ nhiệm"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("small", [
      _c("i", [
        _vm._v(
          " (Truy cập Học bạ số -> Nhập nhận xét - khen thưởng -> Tải file Nhận xét của Hiệu trưởng"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }