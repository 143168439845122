var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "col-lg-12", staticStyle: { height: "0" } }, [
      _c("object", {
        ref: "plugin0",
        attrs: {
          type: "application/x-viettelcasigner",
          width: "0",
          height: "0",
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }