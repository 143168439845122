<template>
    <div id="detailHocBa" class="page page-file file detailHocBa" v-loading.fullscreen.lock="fullscreenLoading">
        <div class="box-upload-file">
            <el-form :rules="rulesSearch" ref="searchForm" :model="searchForm" :hide-required-asterisk="true">
                <div class="d-flex flex-wrap">
                    <div class="d-inline-block pl-3 pr-3 pb-3">
                        <h5 class="font-weight-bold m-0">Quản lý học bạ</h5>
                        <p class="m-0">Tìm kiếm học bạ</p>
                    </div>
                    <div>

                    </div>
                </div>
                <el-form-item style="width: 370px" class="d-inline-block" prop="pgd">
                    <e-select
                        v-if="user.role==6"
                        disabled
                        :data="listPGD"
                        filterable
                        @change="getListTruong"
                        custom-style="width: 370px"
                        v-model="searchForm.pgd"
                        placeholder="Chọn phòng giáo dục"
                        :fields="['tenDonVi','maDonVi']"
                    ></e-select>
                    <e-select
                        v-else
                        :data="listPGD"
                        filterable
                        @change="getListTruong"
                        custom-style="width: 370px"
                        v-model="searchForm.pgd"
                        placeholder="Chọn phòng giáo dục"
                        :fields="['tenDonVi','maDonVi']"
                    ></e-select>
                </el-form-item>
                <el-form-item style="width: 400px" class="d-inline-block">
                    <e-select
                        :data="listSchool"
                        style="width: 400px"
                        @input="selectedSchool"
                        filterable
                        custom-style="width: 350px"
                        v-model="searchForm.maTruong"
                        placeholder="Chọn trường"
                        :fields="['tenTruongHoc','maTruongHoc']"
                    ></e-select>
                </el-form-item>
                <el-form-item class="d-inline-block" prop="year">
                    <!--                    <el-date-picker style="width: 150px" v-model="searchForm.year" type="year" placeholder="Năm học"-->
                    <!--                                    value-format="yyyy">-->
                    <!--                    </el-date-picker>-->
                    <SelectNamHoc v-model="searchForm.year"/>
                </el-form-item>
                <el-form-item style="width: 120px" class="d-inline-block">
                    <e-select
                        :data="optionGrade"
                        @change="khoiHocChange"
                        v-model="searchForm.grade"
                        placeholder="Chọn khối"
                        :fields="['label','value']"
                    ></e-select>
                </el-form-item>
                <el-form-item style="width: 130px" class="d-inline-block">
                    <e-select
                        :data="listLop.list ? listLop.list : []"
                        v-model="searchForm.class"
                        placeholder="Chọn lớp"
                        :fields="['tenLop','maLop']"
                    ></e-select>
                </el-form-item>
                <el-form-item class="d-inline-block" prop="student">
                    <el-input v-model="searchForm.student" placeholder="Nhập"></el-input>
                </el-form-item>
                <!--            <el-form-item style="width: 130px" class="d-inline-block" prop="status">-->
                <!--                <el-select v-model="searchForm.status" placeholder="Trạng thái">-->
                <!--                    <el-option v-for="item in optionStatus" :label="item.label" :key="item.value" :value="item.value"></el-option>-->
                <!--                </el-select>-->
                <!--            </el-form-item>-->
                <el-form-item class="d-inline-block">
                    <el-button type="primary" @click="submitSearch('searchForm')">Tìm kiếm</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="file--title">
            <h5 class="title">Danh sách học sinh
                <template v-if="listHocBa && listHocBa.total">
                    <small>
                        <i>(Danh sách có {{listHocBa.total}} học sinh )</i>
                    </small>
                </template>
            </h5>
            <div class="file-actions">
                <!--                <el-button @click="viewAddHocBa" class="mb-1" plain size="small"> <i class="el-icon-s-grid"></i> Thêm học bạ</el-button>-->
                <el-button type="warning" @click.prevent="xemBaoCao" class="mb-1" size="small"><i
                    class="el-icon-document"></i> Xem báo cáo
                </el-button>
            </div>
        </div>
        <div class="page__content">
            <div class="listNew mb-5">

                <el-table border fit stripe :lazy="true" :data="listHocBa ? listHocBa.list : []" width="100%"
                          ref="listFile">
                    <el-table-column label="STT" style="width: 50px" width="70" type="index" class-name="text-center"
                                     :index="indexMethod">
                    </el-table-column>
                    <el-table-column label="Mã học sinh" property="maHS" width="200"></el-table-column>
                    <el-table-column label="Tên học sinh" property="hoten"></el-table-column>
                    <el-table-column label="Ngày sinh" property="ngaySinh" width="150"></el-table-column>
                    <el-table-column label="Trường" property="tenTruong"></el-table-column>
                    <el-table-column label="Lớp" property="tenLop" width="70"></el-table-column>
                    <el-table-column label="GVCN" property="tenGVCN"></el-table-column>
                    <el-table-column label="Năm học" property="strNamHoc" width="100"></el-table-column>

                    <el-table-column label="Hành động" class-name="">
                        <template slot-scope="scope">

                            <div class="btn__table_action">
                                <el-tooltip content="Xem học bạ" placement="top" class="ml-1 mb-1">
                                    <!--                                    <el-button icon="ion ion-md-eye" @click="" class="mb-1" type="danger"></el-button>-->
                                    <el-button size="small" @click="viewFile(scope.row.id)" class="mb-1" type="info"
                                               icon="ion ion-md-eye" circle></el-button>
                                </el-tooltip>

                            </div>

                        </template>
                    </el-table-column>
                </el-table>
                <div class="text-right mt-2">
                    <pagination v-if="listHocBa && listHocBa.total" :value="paginate.current"
                                :page-count="getTotalPage(listHocBa.total)" :click-handler="getHocBa"
                                :prev-text="'Trước'" :next-text="'Sau'" :container-class="'el-pager'"
                                :pageClass="'number'">

                    </pagination>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import ChiTietHocBa from "../GiaoVien/ChiTietHocBa";
    import {
        mapActions,
        mapState
    } from 'vuex';
    import Pagination from "../../components/Pagination";
    import dialog from "../../dialog";
    import FormOtp from "../../components/FormOtp";
    import UploadImageBase64 from "../../components/UploadImageBase64";
    import ThemHocBa from "../ThemHocBa";
    import SuaHocBa from "../SuaHocBa";
    import DialogAlert from "../../components/DialogAlert";
    import ESelect from "../../components/ESelect";
    import SelectNamHoc from "../../components/SelectNamHoc";

    export default {
        name: "QuanLyHocBa",
        metaInfo: {
            title: 'Quản lý học bạ',
            titleTemplate: '',
            meta: [{
                vmid: 'description',
                name: 'description',
                content: ''
            }]
        },
        computed: {
            ...mapState('teacher', [
                "listLop",
                "updateMessage",
                "updateSuccess",
                "uploadStatus",
                "reportUrl",
                "reportStatus"
            ]),
            ...mapState('hocba', [
                'ketQuaHocTapTemp',
                "fileDetail",
                "fileStatus",
                "listHocBa"

            ]),
            ...mapState('account', [
                'user'
            ]),
            ...mapState('hocsinh', [
                "listHs",
            ]),
            ...mapState('school', [
                "listPGD",
                "listSchool",
            ])
        },
        components: {
            DialogAlert,
            SuaHocBa,
            Pagination,
            FormOtp,
            UploadImageBase64,
            ThemHocBa,
            ESelect,
            SelectNamHoc
        },

        data() {
            return {
                showDialogAlert: false,
                messageAlert: '',
                showEditHocBa: false,
                showAddHocBa: false,
                showInfoHocba: false,
                dataSelect: '',
                customImageMaxSize: 2,
                typeKy: null,
                hocbaDatas: [],
                showDialogOtp: false,
                viewPopup: ChiTietHocBa,
                idEdit: '',
                showEditDiem: false,
                paginate: {
                    start: 0,
                    limit: 100,
                    current: 1,
                },
                data: [1, 2, 3, 4, 5],
                fullscreenLoading: false,
                optionEducation: [{
                    value: 1,
                    label: 'Cấp 1'
                },
                    {
                        value: 2,
                        label: 'Cấp 2'
                    },
                    {
                        value: 3,
                        label: 'Cấp 3'
                    },
                ],
                optionGrade: [],
                khoiCap1: [{
                    value: 1,
                    label: 'Khối 1'
                },
                    {
                        value: 2,
                        label: 'Khối 2'
                    },
                    {
                        value: 3,
                        label: 'Khối 3'
                    },
                    {
                        value: 4,
                        label: 'Khối 4'
                    },
                    {
                        value: 5,
                        label: 'Khối 5'
                    },
                ],
                khoiCap2: [{
                    value: 6,
                    label: 'Khối 6'
                },
                    {
                        value: 7,
                        label: 'Khối 7'
                    },
                    {
                        value: 8,
                        label: 'Khối 8'
                    },
                    {
                        value: 9,
                        label: 'Khối 9'
                    },
                ],
                khoiCap3: [{
                    value: 10,
                    label: 'Khối 10'
                },
                    {
                        value: 11,
                        label: 'Khối 11'
                    },
                    {
                        value: 12,
                        label: 'Khối 12'
                    },

                ],
                optionStudent: [{
                    value: '',
                    label: 'Học sinh'
                },
                    {
                        value: 1,
                        label: 'HS 1'
                    },
                    {
                        value: 2,
                        label: 'HS 2'
                    },
                    {
                        value: 3,
                        label: 'HS 3'
                    },
                ],
                optionStatus: [{
                    value: '',
                    label: 'Trạng thái'
                },
                    {
                        value: 1,
                        label: 'Đã ký'
                    },
                    {
                        value: 2,
                        label: 'Đã ký duyệt'
                    },
                    {
                        value: 3,
                        label: 'Chưa ký'
                    },
                ],
                searchForm: {
                    year: '',
                    education: '',
                    grade: '',
                    class: '',
                    student: '',
                    status: '',

                    pgd: '',
                    maTruong: '',
                },
                rulesSearch: {
                    // year : [
                    //     // {required : true, message : 'Bạn chưa chọn năm học',trigger:['change','blur']}
                    //     {required : true, message : 'Bạn chưa chọn năm học',trigger: 'change'}
                    // ],
                    // education : [
                    //     {required : true, message : 'Bạn chưa chọn cấp học',trigger: 'change'}
                    // ],
                    grade: [{
                        required: true,
                        message: 'Bạn chưa chọn khối học',
                        trigger: 'change'
                    }],
                    class: [{
                        required: true,
                        message: 'Bạn chưa chọn lớp học',
                        trigger: 'change'
                    }],
                    // student : [
                    //     {required : true, message : 'Bạn chưa chọn học sinh',trigger: 'change'}
                    // ],
                    // status : [
                    //     {required : true, message : 'Bạn chưa chọn trạng thái',trigger: 'change'}
                    // ]
                },
                itemEdit: '',
                indexSelect: null,
            }
        },

        beforeMount() {
            if (this.user.role == 7) {
                this.getListPGD({
                    maSo: this.user.maSo
                });
            }
            if (this.user.role == 6) {
                this.getListPGD({
                    maSo: this.user.maSo
                });
                this.searchForm.pgd = this.user.maPhong
            }
            // this.optionGrade = this.khoiCap1.concat(this.khoiCap2, this.khoiCap3);
            this.getListSchool({
                maTinh: this.user.maSo,
                maPhong: this.user.maPhong
            });

        },
        methods: {
            getCurrentMonth() {
                let d = new Date();
                return d.getMonth();
            },
            ...mapActions('teacher', [
                "getListLop",
                "clear",
                "updateHocBa",
                "uploadHocBa",
                "getReport"
            ]),
            ...mapActions("hocba", [
                "getDetail",
                "saveTempKq",
                "getFile",
                "setDataHocSinh",
                "getListHocBa"
            ]),
            ...mapActions('hocsinh', [
                "getListHs",
            ]),
            ...mapActions('school', [
                "getListPGD",
                "getListSchool"
            ]),

            xemBaoCao() {
                this.fullscreenLoading = true;
                this.getReport({
                    maLop: this.searchForm.class,
                    maPhong: this.searchForm.pgd,
                    maTruong: this.searchForm.maTruong,
                    namHoc: this.searchForm.year ? this.searchForm.year : '',
                })
            },

            submitSearch(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.getHocBa(1)
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            getHocBa(select) {
                this.showDialogOtp = false;
                let start = (select - 1) * this.paginate.limit ? (select - 1) * this.paginate.limit : 0;
                this.paginate.start = start;
                this.paginate.current = select;
                this.getListHocBa({
                    start: this.paginate.start,
                    limit: this.paginate.limit,
                    maLop: this.searchForm.class,
                    namHoc: this.searchForm.year ? this.searchForm.year : '',
                    capHoc: this.searchForm.education,
                    khoiHoc: this.searchForm.grade,
                    maHS: this.searchForm.student,
                    maPhong: this.searchForm.pgd,
                    maTruong: this.searchForm.maTruong,
                    maSo: this.user.maSo,
                });
            },

            getListTruong() {
                if (!this.searchForm.pgd) {
                    dialog.showDialog('Thông báo', 'Chưa chọn phòng giáo dục');
                    return;
                }
                this.searchForm.maTruong = '';
                this.searchForm.grade = '';
                this.searchForm.student = '';
                this.getListSchool({
                    maPhong: this.searchForm.pgd
                })
            },

            selectedSchool(e) {
                console.log(e);
                if (!this.searchForm.maTruong) {
                    return;
                }
                this.optionGrade = [];
                this.searchForm.grade = '';
                this.listSchool.map(item => {
                    if (item.maTruongHoc == e) {
                        this.searchForm.education = item.capHoc;
                        if (item.capHoc == 1) {
                            this.optionGrade = this.khoiCap1;
                        } else if (item.capHoc == 2) {
                            this.optionGrade = this.khoiCap2;
                        } else {
                            this.optionGrade = this.khoiCap3;
                        }
                    }
                    return item;
                })
            },

            capHocChange() {
                this.searchForm.student = '';
                this.searchForm.status = '';
                this.searchForm.grade = ''
                if (!this.searchForm.education) {
                    this.optionGrade = this.khoiCap1.concat(this.khoiCap2, this.khoiCap3)
                    return;
                } else {
                    if (this.searchForm.education == 1) {
                        this.optionGrade = this.khoiCap1;
                    } else if (this.searchForm.education == 2) {
                        this.optionGrade = this.khoiCap2;
                    } else {
                        this.optionGrade = this.khoiCap3;
                    }
                }
            },
            khoiHocChange() {
                // if (!this.searchForm.grade){
                //     return;
                // }
                this.searchForm.class = '';
                this.getlistLopHoc();
            },

            getTotalPage(number) {
                return Math.ceil(number / (this.paginate.limit));
            },

            getlistLopHoc() {
                this.getListLop({
                    start: 0,
                    limit: 999,
                    khoiHoc: this.searchForm.grade,
                    maTruong: this.searchForm.maTruong,
                    namHoc : this.searchForm.year,
                    // lopHoc : this.searchForm.class
                })
            },

            viewFile(id) {
                this.fullscreenLoading = true;
                this.getFile({
                    maHocBa: id
                })
            },

            getFullYear() {
                let d = new Date();
                return d.getFullYear();
            },
            checkHocBa(item) {
                if (!item.lops.length) {
                    return 'Đủ học bạ các năm.';
                } else {
                    let html = '<div>Thiếu học bạ</div>';
                    item.lops.map(o => {
                        html += '<div>- Năm học: ' + o.namHoc + '. Lớp : ' + o.tenLop + '</div>'
                        return o;
                    });
                    return html;
                }
            },
            statusHocba(item) {
                return item.lops.length;
            },
            indexMethod(index) {
                return (this.paginate.start + index) + 1;
            },
            openFancyBox(url) {
                $.fancybox.open({
                    src: url,
                    type: 'iframe',
                });
            },

        },
        mounted() {
            this.searchForm.year = this.getFullYear();
            let currentMonth = this.getCurrentMonth()+1;
            if (currentMonth >= 9) {} else {
                this.searchForm.year -= 1;
            }

            this.getListHocBa({
                start: this.paginate.start,
                limit: this.paginate.limit,
                maLop: this.searchForm.class,
                namHoc: this.searchForm.year ? this.searchForm.year : '',
                capHoc: this.searchForm.education,
                khoiHoc: this.searchForm.grade,
                maHS: this.searchForm.student,
                maPhong: this.searchForm.pgd,
                maTruong: this.searchForm.maTruong
            });

        },
        watch: {
            fileDetail(val) {
                if (this.fileStatus == 2) {
                    this.fullscreenLoading = false;
                    this.openFancyBox(this.fileDetail)
                }
                if (this.fileStatus == 1) {
                    this.fullscreenLoading = false;
                    dialog.showDialog('Thông báo', this.fileDetail)
                }
            },

            reportUrl(val) {
                if (this.reportStatus == 2) {
                    this.fullscreenLoading = false;
                    if (val) {
                        this.openFancyBox(this.reportUrl)
                    } else {
                        dialog.showDialog('Thông báo', 'Không tìm thấy báo cáo')
                    }
                }
                if (this.reportStatus == 1) {
                    this.fullscreenLoading = false;
                    dialog.showDialog('Thông báo', this.reportUrl)
                }
            },

        }
    }
</script>

<style scoped>
    .btn__table_action button {
        padding: 8px 10px;
    }
</style>
