<template>
    <div class="page page-login">
        <div class="overlay">
            <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
        <div class="container">
            <div class="row vh-100 align-items-center justify-content-center">
                <div class="col-md-4"></div>
                <div class="col-md-4">
                    <img src="/images/bg1.png" alt="bg">
                    <div class="login-panel card">
                        <div class="card-header pb-0 border-0" style="background-color: transparent;">

                            <h3 class="panel-title text-center font-weight-bold"
                                style="font-size: 20px; color: #21BEF4;">Đăng nhập</h3>
                            <!--                        <p class="m-0 text-center" style="font-size: 14px;">Nhập tài khoản được cấp để đăng nhập hệ thống</p>-->
                        </div>
                        <div class="card-body login__form pt-0">
                            <el-form ref="formLogin" :model="formLogin" :rules="rules">
                                <el-form-item>
                                    <el-alert style="line-height: 1;" class="animated flipInX text-center"
                                        v-bind:title="alert.message" v-if="alert.message" v-bind:type="alert.type"
                                        :closable="false">
                                    </el-alert>
                                </el-form-item>
                                <el-form-item prop="code">
                                    <el-input name="code" v-model="formLogin.code"
                                        @keyup.enter.native="submitForm('formLogin')" placeholder="Nhập"
                                        title="Mã học sinh"></el-input>
                                </el-form-item>
                                <el-form-item prop="otp">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <el-input name="otp" @keyup.enter.native="submitForm('formLogin')"
                                                v-model="formLogin.otp" placeholder="Nhập mã OTP" title="Mã OTP">
                                            </el-input>
                                        </div>
                                        <div class="col-md-6 text-center">
                                            <el-link @click.prevent="getOptCode" style="line-height: 1;">Lấy mã OTP <i
                                                    class="el-icon-refresh"></i></el-link>
                                        </div>
                                    </div>
                                </el-form-item>
                                <el-form-item v-if="capCha" prop="capChaValue">
                                    <el-row :gutter="0" style="margin-bottom:0">
                                        <el-col v-loading="!capCha" :span="8">
                                            <img :src="capCha"
                                                style="width:100%;height:40px;border:1px solid black;border-radius:4px"
                                                alt="Capcha" />
                                        </el-col>
                                        <el-col :span="16">
                                            <el-input name="capChaValue" placeholder="Nhập capcha"
                                                v-model="formLogin.capChaValue" class="input-with-select">
                                                <el-button slot="append" @click.prevent="refreshCapCha"
                                                    icon="el-icon-refresh"></el-button>
                                            </el-input>
                                        </el-col>
                                    </el-row>
                                </el-form-item>
                                <el-form-item class="m-0">
                                    <div>
                                        <el-button class="btn btn-lg btn-block btn-login"
                                            style="background-color: #00A65A;" type="primary"
                                            @click="submitForm('formLogin')">Đăng nhập</el-button>
                                    </div>
                                    <p class="text-center m-0" style="color: #0C9AE3;">Hoặc</p>
                                </el-form-item>
                            </el-form>
                            <!-- <div>
                                <el-link :href="'/'" class="d-block" :underline="false">
                                    <el-button class="btn btn-lg btn-block btn-login" type="primary">Đăng nhập bằng Email</el-button>
                                </el-link>
                            </div> -->
                            <div class="row">
                                <div class="col-md-6">
                                    <el-button class="btn btn-lg btn-block btn-login" @click="linkToLogin" type="primary">Đăng nhập Email
                                    </el-button>
                                </div>
                                <div class="col-md-6">
                                    <el-button @click.prevent="linkToSSO()" class="btn btn-lg btn-block" type="danger">
                                        Đăng nhập SSO</el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        mapState,
        mapActions
    } from 'vuex';
    import dialog from "../dialog";
    import api from '../_helpers/api';
    import constant_api from '../_helpers/constant_api';

    export default {
        name: "LoginOtp",
        metaInfo: {
            title: 'Học bạ số',
            titleTemplate: '%s | Đăng nhập OTP',
            meta: [{
                vmid: 'description',
                name: 'description',
                content: ''
            }]
        },
        computed: {
            ...mapState('account', ['status', 'user', 'capCha']),
            ...mapState({
                alert: state => state.alert
            }),
        },
        methods: {
            ...mapActions('account', [
                'loginOtp',
                "getOtp",
                "reGetCapCha"
            ]),
            ...mapActions({
                clearAlert: 'alert/clear',
                showError: 'alert/error'
            }),
            linkToLogin(){
                let uri = "/login?check=true"
                window.location.href = uri;
            },

            refreshCapCha() {
                if (this.formLogin.otp) {
                    this.reGetCapCha({
                        email: this.formLogin.otp
                    })
                }
            },
            linkToSSO2() {
                let uri = "https://sso-edu.viettel.vn/?page=Account.App.login&userId=5f9a6bb662aa4a0d003eceb2"
                window.location.href = uri;
            },
            linkToSSO() {
                let url = constant_api.login.getCodeSSO;
                console.log('url:' + url)
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                api.get(url, {}).then(
                    response => {
                        console.log("Res:")
                        console.log(response)
                        if (response.data.code == 200) {
                            loading.close();
                            let code_challenge = response.data.data.code_challenge;
                            let code_verifyer = response.data.data.code_verifier;
                            localStorage.setItem('code_verifyer', code_verifyer)
                            let params = {
                                response_type: 'code',
                                redirect_uri: 'https://hocba.edu.vn',
                                client_id: 'hocbadientu_client',
                                state: this.generateString(5),
                                scope: 'openid profile offline_access esmartup',
                                code_challenge: code_challenge,
                                code_challenge_method: 'S256',
                            };
                            console.log(params)
                            let query = this.objectToQueryString(params);
                            let urlLogin = 'https://id.nentanggiaoduc.edu.vn/connect/authorize?' + query;
                            console.log("urlLogin")
                            console.log(urlLogin)
                            loading.close();
                            window.location.href = urlLogin;
                        } else {
                            this.thongBao('error', response.data.msg)
                            loading.close();
                        }
                    }
                )
            },
            objectToQueryString(obj) {
                let str = [];
                for (let p in obj)
                    if (obj.hasOwnProperty(p)) {
                        str.push(p + "=" + obj[p]);
                    }
                return str.join("&");
            },
            generateString(length) {
                let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let result = '';
                const charactersLength = characters.length;
                for (let i = 0; i < length; i++) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }

                return result;
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.submitted = true;
                        const {
                            code,
                            otp,
                            capChaValue
                        } = this.formLogin;
                        if (code && otp) {
                            this.loginOtp({
                                maHocSinh: code,
                                otpCode: otp,
                                capChaValue: capChaValue
                            })
                        }
                    } else {
                        return false;
                    }
                });
            },
            getOptCode() {
                if (!this.formLogin.code) {
                    this.showError('Vui lòng nhập mã học sinh');
                    return;
                }
                this.getOtp(this.formLogin.code);
            },
        },
        data() {
            return {
                rememberPassword: true,
                isLogin: false,
                submitted: false,
                formLogin: {
                    code: '',
                    otp: '',
                },
                rules: {
                    code: [{
                        required: true,
                        message: 'Bạn chưa nhập mã học sinh.',
                        trigger:['change','blur']
                    }, ],
                    otp: [{
                        required: true,
                        message: 'Bạn chưa nhập mã OTP.',
                        trigger:['change','blur']
                    }, ],
                    capChaValue: [{
                        required: true,
                        message: 'Vui lòng nhập mã captcha.',
                        trigger:['change','blur']
                    }, ],
                }
            }
        },
        mounted() {
            window.localStorage.removeItem('messInfoOtpCode');
            window.localStorage.removeItem('OtpCode');
        }
    }
</script>

<style scoped>

</style>