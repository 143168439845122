<template>
    <div v-if="info">
        <span v-if="openID !== info.maPhong && openID!==info.maTruongHoc">{{ new Date(info.thoiGian) | moment("DD/MM/YYYY") }}</span>
        <span v-if="openID && (openID == info.maPhong || openID==info.maTruongHoc)">
                         <el-date-picker v-if="" :picker-options="pickerOptions" style="width: 150px"
                                         v-model="ngayChotSo" format="dd/MM/yyyy" type="date"
                                         placeholder="Ngày chốt ký" value-format="timestamp">
                         </el-date-picker>
                        <el-button type="success" @click="" size="mini" title="Xác nhận" v-on:click="updateSoAction"
                                   icon="el-icon-check"></el-button>
                        <el-button type="danger" icon="el-icon-close" size="mini" title="Hủy"
                                   v-on:click="closeEdit"></el-button>
                    </span>
        <el-button v-if="openID !== info.maPhong && openID!==info.maTruongHoc" type="primary" @click="" size="mini" title="Sửa ngày chốt" v-on:click="openEdit"><i
                class="el-icon-edit"></i></el-button>
    </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import dialog from "../../dialog";

export default {
    name: "UpdateNgayChot",
    props: [
        'info',
        'type',
        'id'
    ],
    computed: {
        ...mapState("account", [
            "user"
        ]),
        ...mapState("configKyModule", [
            "listConfigKy",
            "updateStatus",
            "openID",
        ]),
    },
    data() {
        return {
            editSo: false,
            ngayChotSo: '',
            pickerOptions: {
                disabledDate(time) {
                    // return time.getTime() < Date.now();
                    var date = new Date();
                    date.setDate(date.getDate() - 3 )

                    return time.getTime() < date;
                },
            }
        }
    },
    methods: {
        ...mapMutations("configKyModule", [
            "setOpenID"
        ]),
        ...mapActions("configKyModule", [
            "getConfigKy",
            "updateConfig2"
        ]),

        closeEdit(){
            this.editSo = false;
            this.setOpenID(null);
        },

        openEdit() {
            this.editSo = true;
            this.ngayChotSo = this.info.thoiGian;
            let id = '';
            if (this.type == 1) {
                id = this.info.maPhong;
            } else {
                id = this.info.maTruongHoc;
            }
            this.setOpenID(id);

        },

        updateSoAction() {
            if (!this.ngayChotSo) {
                dialog.showDialog('Thông báo chưa chọn ngày chốt');
                return;
            }
            this.$emit('updateChild');
            let params = {
                "id": this.id,
                "thoiGian": this.ngayChotSo,
                nguoiTao: this.user.displayName
            }
            if (this.type == 1) {
                params.maPhong = this.info.maPhong;
            }
            if (this.type == 2) {
                params.maTruong = this.info.maTruongHoc;
            }
            this.updateConfig2(params);
        }
    },
    watch: {
        updateStatus(val) {
            if (val) {
                this.editSo = false;
            }
        }
    }
}
</script>

<style scoped>

</style>
