var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 24 } },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c(
              "div",
              [
                _c("iframe", {
                  ref: "pdfIframe",
                  staticStyle: { height: "500px", width: "100%" },
                  attrs: { src: "/files/pdf/demo.pdf" },
                }),
                _vm._v(" "),
                _c(
                  "draggable",
                  {
                    model: {
                      value: _vm.elements,
                      callback: function ($$v) {
                        _vm.elements = $$v
                      },
                      expression: "elements",
                    },
                  },
                  _vm._l(_vm.elements, function (element, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "draggable-element" },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(element.name) +
                            "\n                        "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "page__title d-flex align-items-center justify-content-between",
      },
      [_c("h1", { staticClass: "title" }, [_vm._v("Ký file pdf")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }