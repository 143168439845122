<template>
  <el-dialog
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :title="title"
      :class="'dialog__alert '+dialogClass"
      :destroy-on-close="true"
      center
      :before-close="closeDialog"
      :visible.sync="showDialog"
      append-to-body
  >
    <div class="text-center">{{ message }}</div>
    <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="closeDialog()">Đóng</el-button>
        </span>
  </el-dialog>
</template>

<script>
export default {
  name: "DialogAlert",
  props: [
    'showDialog',
    'message',
    'title',
    'dialogClass'
  ],
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    }
  }
}
</script>

<style scoped>

</style>
