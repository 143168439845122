<template>
  <div class="d-flex w-100 header__nav">
    <div class="d-flex align-items-center w-100">
      <div class="menu-icon-mobile">
        <el-link :underline="false" @click.prevent="setMenuMobile(true)">
          <i class="ion ion-md-menu"></i>
        </el-link>
      </div>
      <div class="logo">
<!--        <img src="/images/logo.png" alt="logo" />-->
        Học Bạ Số
      </div>
      <div class="right ml-auto">
        <div style="display: none">
          <el-tooltip
            class="item"
            effect="dark"
            content="Tính năng đang trong quá trình phát triển"
            placement="top"
          >
            <div class="notify">
              <el-badge is-dot class="item" style="padding: 0 5px">
                <div class="notify--square cursor-pointer">
                  <i class="el-icon-message-solid" type="primary"></i>
                </div>
              </el-badge>
            </div>
          </el-tooltip>
        </div>
        <div v-if="shouldShowInstruct" class="instruct">
          <a
            href="https://docs.google.com/document/d/1SiqD4grynkkEd6tOFLHU8kG7OQtXT189/edit"
            target="_blank"
          >
            Hướng Dẫn Sử Dụng
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";

export default {
  name: "Header",
  computed: {
    ...mapState("account", ["user", "profile"]),
    ...mapState("until", ["menuMobileOpen"]),
    shouldShowInstruct() {
      const currentPath = this.$route.path;
      console.log("cu", currentPath);
      const hiddenPaths = [
        "/nhap-du-lieu-bo-sung",
        "/import-giao-vien",
        "/import-lop-hoc",
        "/import-hoc-sinh",
        "/import-ket-qua-hoc-tap",
        "/import-nhan-xet-khen-thuong",
      ];
      // Kiểm tra xem currentPath có nằm trong danh sách các path ẩn không
      return !hiddenPaths.includes(currentPath);
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapMutations("until", ["setMenuMobile"]),
  },
  mounted() {},
};
</script>

<style scoped></style>
