var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "page page-file file detailHocBa",
      attrs: { id: "listConfig" },
    },
    [
      _c("div", { staticClass: "box-upload-file" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pl-3" },
          [
            _c(
              "el-form",
              {
                ref: "searchForm",
                attrs: {
                  rules: _vm.rulesSearch,
                  model: _vm.searchForm,
                  "hide-required-asterisk": true,
                },
              },
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "d-inline-block",
                    staticStyle: { width: "250px" },
                    attrs: { label: "Năm học", prop: "namHoc" },
                  },
                  [
                    _c("SelectNamHoc", {
                      model: {
                        value: _vm.searchForm.namHoc,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchForm, "namHoc", $$v)
                        },
                        expression: "searchForm.namHoc",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticClass: "d-inline-block",
                    staticStyle: { width: "300px" },
                    attrs: { label: "Đối tượng", prop: "namHoc" },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "Chọn đối tượng" },
                        model: {
                          value: _vm.searchForm.doiTuong,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "doiTuong", $$v)
                          },
                          expression: "searchForm.doiTuong",
                        },
                      },
                      _vm._l(
                        [
                          { value: 3, label: "Hiệu trưởng" },
                          { value: 2, label: "GVCN" },
                        ],
                        function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }
                      ),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "d-inline-block" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.submitSearch("searchForm")
                          },
                        },
                      },
                      [_vm._v("Tìm kiếm")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "d-inline-block" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { icon: "el-icon-document", type: "primary" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.dowloadFile($event)
                          },
                        },
                      },
                      [_vm._v("Xuất báo cáo")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "page__content" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-3 col-6" },
            [
              _c("PieChart", {
                attrs: { title: "Tổng quan", "data-pie": this.thongKe.caTinh },
              }),
              _vm._v(" "),
              _c("table", { staticClass: "table table-bordered mt-4" }, [
                _c("tbody", [
                  _c("tr", [
                    _c("th", [_vm._v("Tổng")]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm.thongKe.caTinh[0] + _vm.thongKe.caTinh[1])
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("Chưa ký")]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.thongKe.caTinh[0]))]),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("Đã ký")]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.thongKe.caTinh[1]))]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-3 col-6" },
            [
              _c("PieChart", {
                attrs: { title: "Cấp 1", "data-pie": this.thongKe.cap1 },
              }),
              _vm._v(" "),
              _c("table", { staticClass: "table table-bordered mt-4" }, [
                _c("tbody", [
                  _c("tr", [
                    _c("th", [_vm._v("Tổng")]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(_vm._s(_vm.thongKe.cap1[0] + _vm.thongKe.cap1[1])),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("Chưa ký")]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.thongKe.cap1[0]))]),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("Đã ký")]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.thongKe.cap1[1]))]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-3 col-6" },
            [
              _c("PieChart", {
                attrs: { title: "Cấp 2", "data-pie": this.thongKe.cap2 },
              }),
              _vm._v(" "),
              _c("table", { staticClass: "table table-bordered mt-4" }, [
                _c("tbody", [
                  _c("tr", [
                    _c("th", [_vm._v("Tổng")]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(_vm._s(_vm.thongKe.cap2[0] + _vm.thongKe.cap2[1])),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("Chưa ký")]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.thongKe.cap2[0]))]),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("Đã ký")]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.thongKe.cap2[1]))]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-3 col-6" },
            [
              _c("PieChart", {
                attrs: { title: "Cấp 3", "data-pie": this.thongKe.cap3 },
              }),
              _vm._v(" "),
              _c("table", { staticClass: "table table-bordered mt-4" }, [
                _c("tbody", [
                  _c("tr", [
                    _c("th", [_vm._v("Tổng")]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(_vm._s(_vm.thongKe.cap3[0] + _vm.thongKe.cap3[1])),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("Chưa ký")]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.thongKe.cap3[0]))]),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("Đã ký")]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.thongKe.cap3[1]))]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("h4", [_vm._v("Thống kê theo phòng")]),
            _vm._v(" "),
            _c(
              "el-table",
              {
                staticClass: "configSo--phong",
                attrs: {
                  "row-key": _vm.getRowKeys,
                  "expand-row-keys": _vm.expandArr,
                  border: "",
                  height: "600",
                  stripe: "",
                  data: _vm.detailReport.baoCaoKyTheoPhongs,
                },
                on: { "expand-change": _vm.expandChange },
              },
              [
                _c("el-table-column", {
                  attrs: { type: "expand" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: props.row.loading,
                                  expression: "props.row.loading",
                                },
                              ],
                              staticClass: "configSo--truong",
                              attrs: {
                                "element-loading-text": "Đang lấy dữ liệu...",
                                "element-loading-spinner": "el-icon-loading",
                                "element-loading-background":
                                  "rgba(255, 255, 255, 0.8)",
                                border: "",
                                fit: "",
                                height: "300",
                                data: props.row.baoCaoKyTheoTruongs,
                              },
                            },
                            [
                              _c("el-table-column", {
                                staticStyle: { width: "60px" },
                                attrs: {
                                  label: "STT",
                                  width: "60",
                                  type: "index",
                                  "class-name": "text-center",
                                  index: _vm.indexMethod,
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "Tên Phòng GD",
                                  property: "tenPhong",
                                  width: "400",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "Mã trường học",
                                  width: "150",
                                  property: "maTruongHoc",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "Tên trường học",
                                  width: "400",
                                  property: "tenTruongHoc",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "Tổng số",
                                  property: "tongSoHocBa",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "Chưa ký",
                                  property: "tongHocBaChuaKy",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "Đã ký",
                                  property: "tongHocBaDaKy",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "Tỷ lệ chưa ký",
                                  property: "phanTramChuaKy",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "Tỷ lệ đã ký",
                                  property: "phanTramDaKy",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  staticStyle: { width: "60px" },
                  attrs: {
                    label: "STT",
                    width: "60",
                    type: "index",
                    "class-name": "text-center",
                    index: _vm.indexMethod,
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "Tên Phòng GD",
                    property: "tenPhong",
                    width: "400",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "Mã trường học", width: "150" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "Tên trường học", width: "400" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "Tổng số", property: "tongSoHocBa" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "Chưa ký", property: "tongHocBaChuaKy" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "Đã ký", property: "tongHocBaDaKy" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "Tỷ lệ chưa ký", property: "phanTramChuaKy" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "Tỷ lệ đã ký", property: "phanTramDaKy" },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex flex-wrap" }, [
      _c("div", { staticClass: "d-inline-block pl-3 pr-3 pb-3" }, [
        _c("h5", { staticClass: "font-weight-bold m-0" }, [
          _vm._v("Thống kê ký học bạ"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }