<template>
  <div id="baoCaoKy" class="page page-file file detailHocBa" v-loading.fullscreen.lock="fullscreenLoading">
    <div class="page__title d-flex align-items-center justify-content-between">
      <h1 class="title">Báo cáo ký</h1>
    </div>
    <div class="box-upload-file">
      <el-row :gutter="24" class="margin-bottom-report">
        <el-col :xs="24" :sm="12" :md="8" :lg="6">
          <label>Năm học</label>
          <eselect style="width:100%" collapseTags v-model="namHoc" @change="chonNamHoc"
                   placeholder="Chọn" filterable :data="list_nam_hoc" :fields="['name','value']"/>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="6">
          <label>Lớp học</label>
          <eselect style="width:100%" collapseTags v-model="maLop" placeholder="Chọn" filterable
                   :data="danh_sach_lop" :fields="['tenLop','maLop']"/>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="6">
          <div style="color: transparent">Tìm kiếm</div>
          <el-button class="m-0" @click.prevent="layDanhSachHocSinh" type="primary">Tìm kiếm</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="page__content">
      <div class="listNew mb-5">
        <el-row :gutter="20">
          <el-col v-if="danh_sach_hoc_ba&&danh_sach_hoc_ba.length>0" class="text-right">
            <!--                        <span>Ghi chú:</span>-->
            <i class="el-icon-check"></i> : Đã ký
            <i class="el-icon-close" style="padding-left:10px"></i> : Chưa ký
          </el-col>
          <el-col :span="24">
            <el-table fit lazy :data="danh_sach_hoc_ba" border style="width: 100%;" :max-height="maxHeight">
              <el-table-column fixed align="center" type="index" label="STT" width="50">
              </el-table-column>
              <el-table-column align="left" header-align="center" fixed prop="hoTen" label="Họ tên"
                               width="200">
              </el-table-column>
              <el-table-column align="center" header-align="center" label="Cán bộ quản lý">
                <template slot-scope="scope">
                  <el-tooltip :content="scope.row.tenHieuTruong" placement="top">
                    <i v-if="scope.row.trangThaiXacNhanHieuTruong==1" class="el-icon-check"></i>
                    <i v-else class="el-icon-close"></i>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column align="center" header-align="center" prop="trangThaiXacNhanGVCN"
                               label="Giáo viên chủ nhiệm">
                <template slot-scope="scope">
                  <el-tooltip :content="scope.row.tenGVCN" placement="top">
                    <i v-if="scope.row.trangThaiXacNhanGVCN==1" class="el-icon-check"></i>
                    <i v-else class="el-icon-close"></i>
                  </el-tooltip>
                </template>
              </el-table-column>
              <template v-for="(item,index) in danh_sach_mon">
                <el-table-column align="center" :label="item.tenMon">
                  <template slot-scope="scope">
                    <div>
                      <el-tooltip :content="getKetQuaHocTapTenGv(item.maMon,scope.row.ketQuaHocTaps)"
                                  placement="top">
                        <template v-if="getKetQuaHocTap(item.maMon,scope.row.ketQuaHocTaps)!=3">
                          <i v-if="getKetQuaHocTap(item.maMon,scope.row.ketQuaHocTaps)"
                             class="el-icon-check"></i>
                          <i v-else class="el-icon-close"></i>
                        </template>
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
              </template>
            </el-table>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import {
  mapActions,
  mapState
} from 'vuex';
import ESelectVue from "./Ui/ESelect";
import constant from "../_helpers/constant_api";
import api from "../_helpers/api";

export default {
  name: "BaoCaoKy",
  metaInfo: {
    title: 'Báo cáo ký',
    titleTemplate: '',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: ''
    }]
  },
  components: {
    'eselect': ESelectVue,
  },
  computed: {
    ...mapState('account', [
      'user',
      'list_nam_hoc',
      'nam_hoc_hien_tai'
    ]),
    ...mapState('until', [
      'window',
    ])
  },
  mounted() {
    this.maxHeight = this.getHeightTable()
    this.namHoc = this.nam_hoc_hien_tai;
    // let currentMonth = this.getCurrentMonth() + 1;
    // if (currentMonth >= 9) {} else {
    //     this.namHoc -= 1;
    // }
    this.layDanhSachLopHoc();
  },
  data() {
    return {
      fullscreenLoading: false,
      maxHeight: window.innerHeight,
      namHoc: '',
      maLop: '',
      danh_sach_mon: [],
      danh_sach_ma_mon: [],
      danh_sach_lop: [],
      danh_sach_hoc_ba: [],
    }
  },
  methods: {
    getKetQuaHocTap(maMon, monHocs){
      console.log('getKetQuaHocTap')
      console.log(maMon)
      console.log(monHocs)
      let monHoc = monHocs.filter(hoc => hoc.maMonHoc===maMon);
      console.log('monHoc', monHoc)
      if(monHoc&&monHoc.length){
        return monHoc[0].trangThaiXacNhan;
      }else{
        return 3
      }
    },
    getKetQuaHocTapTenGv(maMon, monHocs){
      console.log('getKetQuaHocTap')
      console.log(maMon)
      console.log(monHocs)
      let monHoc = monHocs.filter(hoc => hoc.maMonHoc===maMon);
      console.log('monHoc', monHoc)
      if(monHoc&&monHoc.length){
        return monHoc[0].tenGV;
      }else{
        return 3
      }
    },
    getHeightTable() {
      if (this.window.height && this.window.height > 950) {
        return this.window.height - 100
      }
      if (this.window.height && this.window.height > 850) {
        return 800
      }
      if (this.window.height && this.window.height > 750) {
        return 700
      }
      return 600
    },
    chonNamHoc() {
      this.danh_sach_lop = [];
      this.maLop = '';
      if (this.namHoc) {
        this.layDanhSachLopHoc();
      }
    },
    layDanhSachHocSinh() {
      if (!this.maLop || this.maLop == '') {
        this.thongBao('error', 'Vui lòng chọn lớp học');
        return;
      }
      this.danh_sach_mon = [];
      this.danh_sach_ma_mon = [];
      this.danh_sach_hoc_ba = [];
      let params = {
        maLop: this.maLop
      }
      let uri = constant.truong.getListHocBa;
      this.fullscreenLoading = true;
      api.get(uri, params).then(
          response => {
            this.fullscreenLoading = false;
            console.log("Danh sách học sinh trả về:")
            console.log(response)
            if (response.data.code == 200) {
              if (response.data.data) {
                this.thongBao('success', 'Lấy dữ liệu thành công.')
                // this.danh_sach_mon = response.data.data.tenMonHocs;
                this.danh_sach_mon = response.data.data.tenMonHocResponses;
                this.danh_sach_ma_mon = response.data.data.tenMonHocResponses.map(hs => hs.maMon);
                this.danh_sach_hoc_ba = response.data.data.kqhtHocSinhs;
              } else {
                this.thongBao('error', 'Không tìm thấy dữ liệu phù hợp.')
              }
            } else {
            }
            console.log('danh_sach_ma_mon')
            console.table(this.danh_sach_ma_mon)
            console.table('danh_sach_mon')
            console.table(this.danh_sach_mon)
            this.fullscreenLoading = false;
          }
      );
    },
    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t
      }
      if (type == "success") {
        cl = "dts-noty-success"
      }
      if (type == "warning") {
        cl = "dts-noty-warning"
      }
      if (type == "error") {
        cl = "dts-noty-error"
      }
      if (type == "info") {
        cl = "dts-noty-info"
      }
      document.querySelector('.overlay').style.display = 'block';
      this.$message({
        onClose: () => {
          document.querySelector('.overlay').style.display = 'none';
        },
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000
      });
    },
    layDanhSachLopHoc() {
      let uri = constant.truong.getListKy;
      let params = {
        namHoc: this.namHoc
      }
      this.fullscreenLoading = true;
      api.get(uri, params).then(
          response => {
            this.fullscreenLoading = false;
            console.log("Danh sách lớp trả về:")
            console.log(response)
            if (response.data.code == 200) {
              this.danh_sach_lop = response.data.data;
            } else {
              this.thongBao('error', response.data.msg)
            }
          }
      );
    },
    getCurrentMonth() {
      let d = new Date();
      return d.getMonth();
    },
    getFullYear() {
      let d = new Date();
      return d.getFullYear();
    },
  }
}
</script>
<style scoped>
.el-icon-close {
  color: #da1d1d;
  font-weight: 900;
}

.el-icon-check {
  color: blue;
  font-weight: 900;
}
</style>