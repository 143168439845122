<template>
  <component v-if="user.role == 5" :is="viewHocSinh"></component>
  <component v-else :is="viewQuanLy"></component>
</template>

<script>
import HocSinh from "./DanhSachHocBa/HocSinh";
import QuanLy from "./DanhSachHocBa/QuanLy";
import {mapState} from 'vuex';

export default {
  name: "SchoolProfile",
  metaInfo : {
    title : 'Danh sách học bạ',
    meta : [
      { vmid: 'description', name: 'description', content: '' }
    ]
  },

  computed: {
    ...mapState('account',[
      "user"
    ]),
  },

  data : () => {
    return {
      viewHocSinh : HocSinh,
      viewQuanLy : QuanLy,
    }
  }

}
</script>

<style scoped>

</style>
