<template>
    <div id="detailHocBa" class="page page-file file detailHocBa">
        <div id="upload_file_hdsd" ref="upload_file_hdsd" class="">
            <div class="">
                <div class="box">

                    <div class="box-body">
                        <div class="row">

                            <div class="col-md-3">
                                <label>Path Module</label>
                                <el-input v-model="module_choose"></el-input>
                            </div>
                            <div class="col-md-2">
                                <label>Số dòng hiển thị</label>
                                <el-input-number style="display:block;" v-model="num" :min="100"
                                                 placeholder="Số dòng cần xem" :step="50"></el-input-number>

                            </div>
                            <div class="col-md-3">
                                <label style="color:transparent;display: block">Số dòng hiển thị</label>
                                <button type="button" class="btn btn-primary" @click="btnSearchPackage()">View log
                                </button>

                            </div>
                        </div>
                        <div class="row" style="margin-top: 10px">


                            <div class="col-md-12">
                                <VueCodeEditor
                                    @input="editChange"
                                    v-model="item"
                                    @init="editorInit"
                                    theme="monokai"
                                    width="100%"
                                    height="750px"
                                    :options="{
        fontSize: 14,
        useWorker:true,
        showLineNumbers: true,
        tabSize: 2,
        showPrintMargin: false,
        showGutter: true,
         enableSnippets: false,
    }" :commands="[
        {
            name: 'save',
            bindKey: { win: 'Ctrl-s', mac: 'Command-s' },
            exec: null,
            readOnly: true,
        },
    ]"/>

                            </div>

                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>

</template>

<script>

import VueCodeEditor from 'vue2-code-editor';
import constant from "../_helpers/constant_api";
import api from "../_helpers/api";


export default {
    name: "ViewLog",
    metaInfo: {
        title: 'View Log',
        titleTemplate: '',
        meta: [{
            vmid: 'description',
            name: 'description',
            content: ''
        }]
    },
    components: {
        VueCodeEditor,
    },
    data() {
        return {
            item: '',
            show_add_package: false,
            num: 100,
            module_choose: '',
        }
    },
    methods: {
        editChange(data) {
        },
        editorInit: function () {
            // vue2-code-editor/node_modules/
            require('brace/ext/language_tools') //language extension prerequsite...
            // require('brace/mode/html') //language
            // require('brace/mode/javascript')
            // require('brace/mode/less')
            require('brace/mode/xml')
            require('brace/theme/monokai')
            // require('brace/snippets/javascript') //snippet
            // require('brace/snippets/xml') //snippet
        },

        btnSearchPackage() {

            if (!this.module_choose) {
                // dialog.showDialog('Thông báo','Chưa chọn module');
                this.$alert('Chưa có path module', 'Thông báo', {
                    confirmButtonText: 'Đóng',
                    type: 'warning',
                    center: true,
                    callback: action => {

                    }
                });
                return
            }


            let uri = constant.vaitro.viewLog;
            let params = {
                id: this.module_choose,
                code: this.num
            }

            api.post(uri, params).then(
                response => {

                    if (response.data.code == 200) {
                        this.item = response.data.data;
                    } else {

                    }
                }
            );

        },

    }
}
</script>

<style scoped>

</style>