<template>
  <div class="page" v-loading="loading" :element-loading-text="'Loading...'" element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="page__title d-flex align-items-center justify-content-between">
      <h1 class="title">Khởi tạo dữ liệu HCM </h1>
      <!--      <el-tooltip class="item" effect="dark" content="Hướng dẫn nhập dữ liệu" placement="top">-->
      <!--        <a href="/files/huong_dan_nhap_du_lieu.docx"> Hướng dẫn nhập dữ liệu</a>-->
      <!--      </el-tooltip>-->
    </div>

    <div class="page__header">
      <el-row :gutter="20">

        <el-form :model="infoSchool" :rules="rules" ref="ruleForm"
                 class="demo-ruleForm">
          <el-row :gutter="24">
            <el-col :span="6">
              <el-form-item class="d-inline-block" prop="namHoc" label="Năm học">
                <eselect style="width:100%" ref="namHoc" collapseTags v-model="infoSchool.namHoc" :placeholder="'Chọn'"
                         filterable :data="list_nam_hoc" :fields="['name','value']"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="Tài khoản" prop="username">
                <el-input ref="username" clearable v-model="infoSchool.username" placeholder="Nhập"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="Mật khẩu" prop="password">
                <el-input ref="password" type="password" auto-complete="false" :autocomplete="false" clearable v-model="infoSchool.password" placeholder="Nhập "></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <label for="" style="color: transparent;display: block">a</label>
              <el-button type="warning" @click="updateInfoSubmit('ruleForm',1)">Khởi tạo</el-button>
              <el-button type="primary" @click="updateInfoSubmit('ruleForm',2)">Kiểm tra kết quả</el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-row>

    </div>
  </div>

</template>

<script>
// import ImportGeneral from "./ImportGeneral";
import ImportGeneral from "./ImportChung.vue";
import websocket from "../../websocket";
import constant_api from "../../_helpers/constant_api";
import ESelect from "../Ui/ESelect";
import {
  mapState
} from "vuex";
import ImportForNhanXet from "./ImportChung.vue";
import msgType from "../../_helpers/constant_msg_type";
import api from "../../_helpers/api";
import LZString from "lz-string";

export default {
  name: "ImportCanBo",
  metaInfo: {
    title: 'Khởi tạo dữ liệu HCM',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: ''
    }],
  },
  components: {
    ImportForNhanXet,
    ImportGeneral,
    'eselect': ESelect,
  },
  data() {
    return {
      loading: false,
      infoSchool: {
        username: '',
        password: '',
        matruong: '',
        namHoc: '',
      },
      rules: {
        username: [{
          required: true,
          message: 'Vui lòng nhập tên tài khoản',
          trigger:['change','blur']
        }],
        password: [{
          required: true,
          message: 'Vui lòng nhập mật khẩu',
          trigger:['change','blur']
        }],
        namHoc: [{
          required: true,
          message: 'Vui lòng chọn năm học',
          trigger:['change','blur']
        }],
      },
    }
  },
  methods: {

    onReceiveMessage(e) {
      let data = e.data;
      console.log('onReceiveMessage')
      console.log(data)
      if (data.other == 82) {
        console.log('onReceiveMessage nộp dữ liệu HCM')
        if (data.code == 200) {
          this.thongBao('success', data.msg)
        } else {
          this.thongBao('error', data.msg)
        }
      }


    },
    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t
      }
      if (type == "success") {
        cl = "dts-noty-success"
      }
      if (type == "warning") {
        cl = "dts-noty-warning"
      }
      if (type == "error") {
        cl = "dts-noty-error"
      }
      if (type == "info") {
        cl = "dts-noty-info"
      }
      document.querySelector('.overlay').style.display = 'block';
      this.$message({
        onClose: () => {
          document.querySelector('.overlay').style.display = 'none';
        },
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000
      });
    },
    getFullYear() {
      let d = new Date();
      return d.getFullYear();
    },
    getListCapHoc() {
      if (this.user && this.user.capHoc) {
        let ch = this.user.capHoc.toString();
        let cap_hoc = ch.split('');
        this.capHoc = parseInt(cap_hoc[0]);
      }
    }
    ,
    updateInfoSubmit(formName, type) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (type == 1) {
            this.$confirm('Xác nhận khởi tạo thông tin?', 'Thông báo', {
              confirmButtonText: 'Khởi tạo',
              cancelButtonText: 'Hủy',
              confirmButtonClass: 'el-button--warning',
              closeOnClickModal: false,
            })
                .then(_ => {
                  console.log('đồng ý')
                  let params = {
                    namHoc: this.infoSchool.namHoc,
                    username: this.infoSchool.username,
                    password: this.infoSchool.password,
                    maTruong: this.user.maTruong,
                    msgType: 82
                  }
                  console.log('Gửi wsk:')
                  console.log(params)
                  // websocket.sendMessage(data);
                  this.loading = true
                  let url = '/hocbadientu-sync-service/api/import-hcm/import-all'
                  api.postHcm(url, params).then(response => {
                    console.log('phản hồi validate:')
                    console.log(response)
                    if (response.data.code == 200) {
                      this.thongBao('success', response.data.msg)
                    } else {
                      this.thongBao('error', response.data.msg)
                    }
                    this.loading = false;
                  }).catch((e) => {
                    console.log('catch')
                    this.loading = false;
                  })
                })
                .catch(_ => {
                });
          } else {


            console.log('đồng ý')
            let params = {
              namHoc: this.infoSchool.namHoc,
              username: this.infoSchool.username,
              password: this.infoSchool.password,
              maTruong: this.user.maTruong,
              msgType: 82
            }
            console.log('Gửi wsk:')
            console.log(params)
            // websocket.sendMessage(data);
            this.loading = true
            let url = '/hocbadientu-sync-service/api/import-hcm/check-result'
            api.postHcm(url, params).then(response => {
              console.log('phản hồi validate:')
              console.log(response)
              if (response.data.code == 200) {
                this.thongBao('success', response.data.data.des)
                if(response.data.data.url){
                  window.location.href = response.data.data.url;
                }
              } else {
                this.thongBao('error', response.data.msg)
              }
              this.loading = false;
            }).catch((e) => {
              console.log('catch')
              this.loading = false;
            })
          }

        } else {
          console.log(valid)
        }
      });
    },
  },

  beforeMount() {
    window.addEventListener('message', this.onReceiveMessage, false);
  },
  mounted() {
    this.getListCapHoc();
    this.infoSchool.namHoc = this.nam_hoc_hien_tai;
  },
  computed: {
    ...mapState('account',
        [
          "user",
          'list_nam_hoc',
          'nam_hoc_hien_tai',
          'list_cap_hoc',
        ]),

  },
}
</script>

<style scoped>
.title {
  margin-bottom: 0;
}
</style>