var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page page-file file detailHocBa" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading.fullscreen.lock",
            value: _vm.fullscreenLoading,
            expression: "fullscreenLoading",
            modifiers: { fullscreen: true, lock: true },
          },
        ],
        attrs: {
          "element-loading-text": "Đang đồng bộ...",
          "element-loading-spinner": "el-icon-loading",
          "element-loading-background": "rgba(0, 0, 0, 0.8)",
        },
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "box-upload-file" },
          [
            _c(
              "el-form",
              {
                ref: "searchForm",
                attrs: {
                  rules: _vm.rulesSearch,
                  model: _vm.searchForm,
                  "hide-required-asterisk": true,
                },
              },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 4 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "d-inline-block",
                            attrs: { prop: "namHoc", label: "Năm học" },
                          },
                          [
                            _c("eselect", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                collapseTags: "",
                                placeholder: "Chọn",
                                filterable: "",
                                data: _vm.list_nam_hoc,
                                fields: ["name", "value"],
                              },
                              on: { change: _vm.chonNamHoc },
                              model: {
                                value: _vm.searchForm.namHoc,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "namHoc", $$v)
                                },
                                expression: "searchForm.namHoc",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 4 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "d-inline-block",
                            attrs: { prop: "capHoc", label: "Cấp học" },
                          },
                          [
                            _c("eselect", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                disabled: _vm.list_cap_hoc.length == 1,
                                collapseTags: "",
                                placeholder: "Chọn",
                                filterable: "",
                                data: _vm.list_cap_hoc,
                                fields: ["name", "value"],
                                clearable: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.chonKhoiHoc()
                                },
                              },
                              model: {
                                value: _vm.searchForm.capHoc,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "capHoc", $$v)
                                },
                                expression: "searchForm.capHoc",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.user && _vm.user.role == 8
                      ? _c(
                          "el-col",
                          { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 4 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "d-inline-block",
                                attrs: {
                                  prop: "maPhong",
                                  label: "Phòng giáo dục",
                                },
                              },
                              [
                                _c("eselect", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    collapseTags: "",
                                    placeholder: "Phòng giáo dục",
                                    filterable: "",
                                    data: _vm.list_phong,
                                    fields: ["name", "value"],
                                  },
                                  on: { change: _vm.layDanhSachTruongHoc },
                                  model: {
                                    value: _vm.searchForm.maPhong,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "maPhong", $$v)
                                    },
                                    expression: "searchForm.maPhong",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.user && _vm.user.role == 8
                      ? _c(
                          "el-col",
                          { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 4 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "d-inline-block",
                                attrs: {
                                  prop: "maTruong",
                                  label: "Trường học",
                                },
                              },
                              [
                                _c("eselect", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    collapseTags: "",
                                    placeholder: "Trường học",
                                    filterable: "",
                                    data: _vm.list_truong,
                                    fields: ["name", "value"],
                                  },
                                  model: {
                                    value: _vm.searchForm.maTruong,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "maTruong", $$v)
                                    },
                                    expression: "searchForm.maTruong",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 4 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "d-inline-block",
                            attrs: { prop: "typeSync", label: "Loại đồng bộ" },
                          },
                          [
                            _c("eselect", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                collapseTags: "",
                                placeholder: "Loại đồng bộ",
                                filterable: "",
                                data: _vm.typeSync,
                                fields: ["NAME", "VALUE"],
                                clearable: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.chonLoaiDongBo()
                                },
                              },
                              model: {
                                value: _vm.searchForm.typeSync,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "typeSync", $$v)
                                },
                                expression: "searchForm.typeSync",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.searchForm.typeSync == 4 ||
                    _vm.searchForm.typeSync == 3 ||
                    _vm.searchForm.typeSync == 2 ||
                    _vm.searchForm.typeSync == 14
                      ? _c(
                          "el-col",
                          { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 4 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "d-inline-block",
                                attrs: { prop: "grade", label: "Khối học" },
                              },
                              [
                                _c("eselect", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    collapseTags: "",
                                    placeholder: "Chọn",
                                    filterable: "",
                                    data: _vm.optionGrade,
                                    fields: ["label", "value"],
                                  },
                                  on: { change: _vm.khoiHocChange },
                                  model: {
                                    value: _vm.searchForm.grade,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "grade", $$v)
                                    },
                                    expression: "searchForm.grade",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.searchForm.typeSync == 4 ||
                    _vm.searchForm.typeSync == 3 ||
                    _vm.searchForm.typeSync == 14
                      ? _c(
                          "el-col",
                          { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 4 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "d-inline-block",
                                attrs: { prop: "class", label: "Lớp học" },
                              },
                              [
                                _c("eselect", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    multiple: "",
                                    placeholder: "Chọn",
                                    filterable: "",
                                    data: _vm.danh_sach_lop_hoc,
                                    fields: ["tenLop", "maLop"],
                                  },
                                  model: {
                                    value: _vm.searchForm.class,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "class", $$v)
                                    },
                                    expression: "searchForm.class",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.searchForm.typeSync == 2
                      ? _c(
                          "el-col",
                          { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 4 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "d-inline-block",
                                attrs: { label: "Lớp học" },
                              },
                              [
                                _c("eselect", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    multiple: "",
                                    placeholder: "Chọn",
                                    filterable: "",
                                    data: _vm.danh_sach_lop_hoc,
                                    fields: ["tenLop", "maLop"],
                                  },
                                  model: {
                                    value: _vm.searchForm.class,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "class", $$v)
                                    },
                                    expression: "searchForm.class",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.searchForm.typeSync == 4 && false
                      ? _c(
                          "el-col",
                          { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 4 } },
                          [
                            _c(
                              "label",
                              {
                                staticStyle: {
                                  color: "transparent",
                                  display: "block",
                                },
                              },
                              [_vm._v("Đồng bộ điểm")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-checkbox",
                              {
                                model: {
                                  value: _vm.searchForm.dongBoDiem,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "dongBoDiem", $$v)
                                  },
                                  expression: "searchForm.dongBoDiem",
                                },
                              },
                              [_vm._v("Đồng bộ điểm")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 4 } },
                      [
                        _vm.user && _vm.user.role == 7
                          ? [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "d-inline-block",
                                  attrs: { prop: "pgd" },
                                },
                                [
                                  _c("eselect", {
                                    attrs: {
                                      data: _vm.listPGD,
                                      filterable: "",
                                      "custom-style": "width: 370px",
                                      placeholder: "Chọn phòng giáo dục",
                                      fields: ["tenDonVi", "maDonVi"],
                                    },
                                    on: { change: _vm.getListTruong },
                                    model: {
                                      value: _vm.searchForm.pgd,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchForm, "pgd", $$v)
                                      },
                                      expression: "searchForm.pgd",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "d-inline-block",
                                  attrs: { prop: "pgd" },
                                },
                                [
                                  _c("eselect", {
                                    attrs: {
                                      data: _vm.listSchool,
                                      filterable: "",
                                      multiple: "",
                                      "collapse-tags": "",
                                      "custom-style": "width: 370px",
                                      placeholder: "Chọn trường",
                                      fields: ["tenTruongHoc", "maTruongHoc"],
                                    },
                                    model: {
                                      value: _vm.searchForm.maTruong,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "maTruong",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.maTruong",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.user && _vm.user.role == 6
                          ? [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "d-inline-block",
                                  attrs: { prop: "pgd" },
                                },
                                [
                                  _c("eselect", {
                                    attrs: {
                                      disabled: "",
                                      data: _vm.listPGD,
                                      filterable: "",
                                      "custom-style": "width: 370px",
                                      placeholder: "Chọn phòng giáo dục",
                                      fields: ["tenDonVi", "maDonVi"],
                                    },
                                    on: { change: _vm.getListTruong },
                                    model: {
                                      value: _vm.searchForm.pgd,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchForm, "pgd", $$v)
                                      },
                                      expression: "searchForm.pgd",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "d-inline-block",
                                  attrs: { prop: "pgd" },
                                },
                                [
                                  _c("eselect", {
                                    attrs: {
                                      data: _vm.listSchool,
                                      filterable: "",
                                      multiple: "",
                                      "collapse-tags": "",
                                      "custom-style": "width: 370px",
                                      placeholder: "Chọn trường",
                                      fields: ["tenTruongHoc", "maTruongHoc"],
                                    },
                                    model: {
                                      value: _vm.searchForm.maTruong,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "maTruong",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.maTruong",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { staticClass: "text-left" },
                          [
                            _c(
                              "label",
                              {
                                staticStyle: {
                                  color: "transparent",
                                  display: "block",
                                  "line-height": "1.7",
                                },
                              },
                              [_vm._v("Đồng bộ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.submitForm("searchForm")
                                  },
                                },
                              },
                              [_vm._v("Đồng bộ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "page__content" },
      [
        _c(
          "el-table",
          {
            ref: "listGiaoVien",
            attrs: {
              border: "",
              fit: "",
              lazy: true,
              data: _vm.historySync ? _vm.historySync.list : [],
              width: "100%",
              "max-height": _vm.maxHeight,
            },
          },
          [
            _c("el-table-column", {
              staticStyle: { width: "50px" },
              attrs: {
                label: "STT",
                width: "80",
                type: "index",
                "class-name": "text-center",
                index: _vm.indexMethod,
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "Năm học",
                property: "strNamHoc",
                align: "center",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "Mã trường",
                property: "maTruong",
                "class-name": "text-center",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "Trường",
                property: "tenTruong",
                "header-align": "center",
                align: "center",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "Thời gian", "class-name": "text-center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("moment")(
                              new Date(scope.row.createdDate),
                              "H:mm:ss DD/MM/YYYY"
                            )
                          ) +
                          "\n        "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "Kết quả",
                "header-align": "center",
                align: "left",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.ketQua.length >= 50
                        ? _c("div", [
                            _c("p", { staticClass: "resultText" }, [
                              _vm._v(" " + _vm._s(scope.row.ketQua)),
                            ]),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "showMore",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.showResult(scope.row.ketQua)
                                  },
                                },
                              },
                              [_c("i", {}, [_c("small", [_vm._v("chi tiết")])])]
                            ),
                          ])
                        : _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.ketQua) +
                                "\n          "
                            ),
                          ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "Thực hiện",
                property: "nguoiDongBo",
                "header-align": "center",
                align: "left",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "text-right mt-2" }, [
          _vm.historySync && _vm.historySync.list
            ? _c(
                "div",
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { staticClass: "text-left", attrs: { span: 8 } },
                        [
                          _c("chonSoLuong", {
                            attrs: { show: _vm.paginate.limit },
                            on: {
                              chonXongSoLuong: function ($event) {
                                return _vm.ChonSoLuongBanGhi($event)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { staticClass: "text-right", attrs: { span: 16 } },
                        [
                          _c("small", { staticClass: "pd-r-10" }, [
                            _c("i", [
                              _vm._v(
                                "Danh sách có " +
                                  _vm._s(_vm.historySync.total) +
                                  " bản ghi "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm.historySync && _vm.historySync.total
                            ? _c("pagination", {
                                attrs: {
                                  value: _vm.paginate.current,
                                  "page-count": _vm.getTotalPage(
                                    _vm.historySync.total
                                  ),
                                  "click-handler": _vm.getHistory,
                                  "prev-text": "Trước",
                                  "next-text": "Sau",
                                  "container-class": "el-pager",
                                  pageClass: "number",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "el-dialog",
          {
            attrs: {
              "close-on-press-escape": false,
              "close-on-click-modal": false,
              title: "Tải xuống file dữ liệu",
              center: "",
              visible: _vm.hien_thi_tai_file,
              closeOnPressEscape: false,
              closeOnClickModal: false,
            },
            on: {
              "update:visible": function ($event) {
                _vm.hien_thi_tai_file = $event
              },
            },
          },
          [
            _c(
              "el-table",
              {
                attrs: {
                  border: "",
                  fit: "",
                  data: _vm.danh_sach_dong_bo_theo_lop,
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    align: "center",
                    label: "STT",
                    width: "150",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    property: "tenLop",
                    align: "center",
                    label: "Tên lớp",
                    width: "200",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "default", size: "mini" },
                    on: {
                      click: function ($event) {
                        _vm.hien_thi_tai_file = false
                      },
                    },
                  },
                  [_vm._v("Đóng")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              "close-on-press-escape": false,
              "close-on-click-modal": false,
              title: "Kết quả đồng bộ",
              width: "40%",
              center: "",
              visible: _vm.show_text,
            },
            on: {
              "update:visible": function ($event) {
                _vm.show_text = $event
              },
            },
          },
          [
            _c(
              "div",
              _vm._l(_vm.textShow, function (text) {
                return _c("p", [_vm._v(_vm._s(text))])
              }),
              0
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page__title" }, [
      _c("h1", { staticClass: "title" }, [_vm._v("Đồng bộ dữ liệu")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "file--title" }, [
      _c("h5", { staticClass: "title" }, [_vm._v("Lịch sử đồng bộ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }