<template>
    <div>
       <div class="el-loading-mask is-fullscreen" style="z-index: 2000;"><div class="el-loading-spinner"><svg viewBox="25 25 50 50" class="circular"><circle cx="50" cy="50" r="20" fill="none" class="path"></circle></svg><!----></div></div>
    <el-dialog
        :title="title"
        class="dialog__alert"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        :before-close="closeDialog"
        center
        :visible.sync="showDialog"
        append-to-body
    >
        <div class="text-center">{{ message }}</div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="closeDialog()">Đóng</el-button>
        </span>
    </el-dialog>
    </div>
</template>
<script>
    import { mapState, mapActions,mapMutations } from 'vuex';
    import util from "../util";

    export default {
        name: "LoginSSO",
        components : {
        
        },
        computed: {
              ...mapState('alert',[
                  "type",
                  "message",
              ]),
            ...mapState('account', [
                'user'
            ]),
        },

        data() {
            return {
                code:"",
                showDialog : false,
                title: 'Thông báo',
             
            }
        },

        mounted() {
            this.code = this.$route.query.code;
            if(this.code){
                console.log("Mout login sso")
                window.localStorage.removeItem('messInfoOtpCode');
                window.localStorage.removeItem('OtpCode');
                window.localStorage.removeItem('user');
                console.log("clear localstorage")
                console.log("check callback sso")
                console.log("Đăng nhập qua sso")
                this.loginWithCode(this.code)
            }else{
                let token = util.getCookie('token');
                if (this.user && token){
                    if (this.user.role == 8){
                        this.$router.push({ name: 'CauHinhNguoiDung' });
                    } else if (this.user.role == 5){
                        this.$router.push({ name: 'DanhSachHocBa' });
                    }else if (this.user.role == 4||this.user.role == 6||this.user.role == 7){
                        this.$router.push({ name: 'ThongKeBaoCao' });
                    }
                    else {
                        this.$router.push({ name: 'DashBoard' });
                    }
                }else{
                    // console.log("Chuyển qua trang đăng nhập thường")
                    this.logout();
                    this.$router.push({ name: 'Login' });
                }

            }
        },
        methods:{
            ...mapActions({
                clearAlert: 'alert/clear'
            }),
            ...mapActions('sso', ['loginWithCode']),

            ...mapMutations ('account',[
                "logout",
            ]),

            closeDialog(){
                this.clearAlert();
                this.$router.push({ name: 'Login' });
            }
            
        },
    
        watch : {
            message(val){
                console.log('alert:',val)
                if(val){
                    this.showDialog = true;
                }
            }
        }
    }

</script>

<style scoped>

</style>
