var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "page page-file file detailHocBa",
      attrs: { id: "detailHocBa" },
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 24 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Hành động")]),
              _vm._v(" "),
              _c("eselect", {
                staticStyle: { width: "100%" },
                attrs: {
                  collapseTags: "",
                  placeholder: "Chọn",
                  filterable: "",
                  data: _vm.list_action,
                  fields: ["name", "value"],
                },
                model: {
                  value: _vm.optionAction,
                  callback: function ($$v) {
                    _vm.optionAction = $$v
                  },
                  expression: "optionAction",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-upload",
                {
                  ref: "uploadBia",
                  staticClass: "upload-demo text-center",
                  attrs: {
                    drag: "",
                    action: "/",
                    "file-list": _vm.fileList,
                    "before-upload": _vm.beforeUpload,
                    "on-change": _vm.uploadFile,
                    "on-remove": _vm.uploadFile,
                    accept: "'.pdf,.xlsx,.xls'",
                    "auto-upload": false,
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-upload" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "el-upload__text" }, [
                    _vm._v("Kéo thả hoặc "),
                    _c("em", [_vm._v("click ở đây")]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { staticClass: "text-center", attrs: { span: 24 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: !this.base64Upload || !this.optionAction,
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.uploadFileToSv($event)
                    },
                  },
                },
                [_vm._v("Tải lên")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "box-upload-file" }, [
      _c("div", { staticClass: "d-flex flex-wrap" }, [
        _c("div", { staticClass: "d-inline-block pl-3 pr-3 pb-3" }, [
          _c("h5", { staticClass: "font-weight-bold m-0" }, [
            _vm._v("Tool mở trường"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }