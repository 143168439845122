export default {
    list_limit_paginate:[
        {name:'10 bản ghi/trang',value:10},
        {name:'50 bản ghi/trang',value:50},
        {name:'100 bản ghi/trang',value:100},
        {name:'500 bản ghi/trang',value:500},
        {name:'1000 bản ghi/trang',value:1000},
        {name:'2000 bản ghi/trang',value:2000},
    ]
};
