var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "hocbaDetail" },
    [
      _c(
        "div",
        { attrs: { data: "page" } },
        [
          !_vm.detailHocBa
            ? [
                _c("div", { staticClass: "text-center" }, [
                  _vm._v(
                    "\n        Học sinh chưa có học bạ hoặc không tồn tại học bạ này\n      "
                  ),
                ]),
              ]
            : [
                _c(
                  "div",
                  { staticClass: "info d-flex justify-content-between" },
                  [
                    _c("div", [
                      _vm._v("Họ và tên: " + _vm._s(_vm.detailHocBa.hoten)),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v("Lớp: " + _vm._s(_vm.detailHocBa.tenLop)),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "Năm học: " +
                          _vm._s(_vm.detailHocBa.namHoc) +
                          "-" +
                          _vm._s(_vm.detailHocBa.namHoc + 1)
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "listMon" }, [
                  _c("table", { staticClass: "table table-bordered" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.kq_hoc_tap, function (item, index) {
                        return _c("tr", { key: item.id }, [
                          _c("td", { staticClass: "text-left" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.tenMonHoc) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.diemHocKyI) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.diemHocKyII) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.diemCaNam) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.diemThiLai) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-left" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  item.trangThaiXacNhan ? "Đã ký" : "Chưa ký"
                                ) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-left" }, [
                            _c("p", { staticClass: "text-break" }, [
                              _vm._v(_vm._s(item.tenGV)),
                            ]),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]),
              ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "popupEditDiem",
          attrs: {
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            title: "Sửa điểm",
            "append-to-body": "",
            "modal-append-to-body": "",
            visible: _vm.showPopUp,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showPopUp = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "editForm",
                  attrs: {
                    rules: _vm.rulesForm,
                    model: _vm.formEdit,
                    "append-to-body": true,
                    "hide-required-asterisk": true,
                  },
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "d-inline-block",
                            attrs: { prop: "diemHocKyI" },
                          },
                          [
                            _c("label", [_vm._v("Điểm TB học kỳ I")]),
                            _vm._v(" "),
                            _c("el-input", {
                              attrs: { placeholder: "Điểm TB học kỳ 1" },
                              model: {
                                value: _vm.formEdit.diemHocKyI,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formEdit, "diemHocKyI", $$v)
                                },
                                expression: "formEdit.diemHocKyI",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "d-inline-block",
                            attrs: { prop: "diemHocKyII" },
                          },
                          [
                            _c("label", [_vm._v("Điểm TB học kỳ II")]),
                            _vm._v(" "),
                            _c("el-input", {
                              attrs: { placeholder: "Điểm TB học kỳ 2" },
                              model: {
                                value: _vm.formEdit.diemHocKyII,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formEdit, "diemHocKyII", $$v)
                                },
                                expression: "formEdit.diemHocKyII",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "d-inline-block",
                            attrs: { prop: "diemCaNam" },
                          },
                          [
                            _c("label", [_vm._v("Điểm TB cả năm")]),
                            _vm._v(" "),
                            _c("el-input", {
                              attrs: { placeholder: "Điểm TB cả năm" },
                              model: {
                                value: _vm.formEdit.diemCaNam,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formEdit, "diemCaNam", $$v)
                                },
                                expression: "formEdit.diemCaNam",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-3" }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c(
                          "el-form-item",
                          { staticClass: "d-inline-block" },
                          [
                            _c("label", [
                              _vm._v("Điểm hoặc xếp loại sau KT lại (nếu có)"),
                            ]),
                            _vm._v(" "),
                            _c("el-input", {
                              attrs: {
                                placeholder: "Điểm hoặc xếp loại sau KT lại",
                              },
                              model: {
                                value: _vm.formEdit.diemThiLai,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formEdit, "diemThiLai", $$v)
                                },
                                expression: "formEdit.diemThiLai",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closePopup } }, [
                _vm._v("Thoát"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.editDiem("editForm")
                    },
                  },
                },
                [_vm._v("Sửa điểm")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("FormOtp", {
        attrs: {
          "data-mon": _vm.hocbaDatas,
          "show-dialog-otp": _vm.showDialogOtp,
        },
        on: {
          closeDialogOtp: _vm.chitietCloseDialogOtp,
          success: _vm.successOtp,
        },
      }),
      _vm._v(" "),
      _c("FormConfirm", {
        attrs: {
          "dialog-class": "confirm--ky",
          title: "Xác nhận ký",
          message: "Bạn muốn ký môn học này?",
          "show-confirm": _vm.showConfirm,
        },
        on: { close: _vm.closeConfirm, confirm: _vm.confirmKy },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "text-center", attrs: { rowspan: "2" } }, [
          _vm._v("Môn học /"),
          _c("br"),
          _vm._v(" Hoạt động GD"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-center", attrs: { colspan: "3" } }, [
          _vm._v("Điểm trung bình hoặc xếp loại các môn"),
        ]),
        _vm._v(" "),
        _c(
          "th",
          {
            staticClass: "text-center",
            staticStyle: { "max-width": "200px" },
            attrs: { rowspan: "2" },
          },
          [_vm._v("Điểm hoặc xếp loại sau KT lại (nếu có)\n            ")]
        ),
        _vm._v(" "),
        _c("th", { staticClass: "text-center", attrs: { rowspan: "2" } }, [
          _vm._v("\n              Trạng thái\n            "),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-center", attrs: { rowspan: "2" } }, [
          _vm._v("\n              Giáo viên\n            "),
        ]),
      ]),
      _vm._v(" "),
      _c("tr", [
        _c("td", { staticClass: "text-center" }, [_vm._v("H Kỳ I")]),
        _vm._v(" "),
        _c("td", { staticClass: "text-center" }, [_vm._v("H Kỳ II")]),
        _vm._v(" "),
        _c("td", { staticClass: "text-center" }, [_vm._v("CN")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }