<template>
  <div v-loading="loading.fullScreen" element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(255, 255, 255, 0.8)">
    <el-row :gutter="24" style="padding-top:70px;">
      <el-col :sm="12" :xs="24">
        <div class="grid-content bg-purple">

          <v-jstree :data="listReport">
            <template slot-scope="_">
              <div style="display: inherit">
                <i v-if="_.model.menu" class="el-icon-folder"></i>
                <i v-else class="el-icon-menu"></i>
                <span style="padding-right:30px;">{{ _.model.menuName }}</span>
                <a v-if="_.model.menu" style="margin:5px" @click.prevent="themNhomMenuCon( _.model)"
                   href="#" title="Thêm nhóm"><b><i class="el-icon-plus"></i></b></a>
                <a v-if="_.model.menu" style="margin:5px" @click.prevent="themMenuCon( _.model)"
                   href="#" title="Thêm"><b><i class="el-icon-plus"></i></b></a>
                <a style="margin:5px" href="#" @click.prevent="chinhSuaMenuCon( _.model)"
                   title="Sửa"><b><i class="el-icon-edit"></i></b></a>
                <a style="margin:5px" href="#" @click.prevent="xoaMenuCon( _.model)" title="Xóa"><b><i
                    class="el-icon-delete"></i></b></a>
              </div>
            </template>
          </v-jstree>
        </div>
      </el-col>
      <el-col :span="12" :xs="24">
        <div class="grid-content bg-purple">
          <el-button style="float:right;margin-bottom:5px" size="mini" @click="themMoiVaiTro" type="primary">
            Thêm mới
          </el-button>
          <el-table border :data="listVaiTro" stripe style="width:100%">
            <el-table-column align="center" type="index" width="50">
            </el-table-column>
            <el-table-column align="left" header-align="center" prop="roleName" label="Tên">
            </el-table-column>
            <el-table-column prop="role" align="center" label="Mã" width="50">
            </el-table-column>
            <el-table-column align="center" label="Thao tác">
              <template slot-scope="scope">
                <el-button size="mini" type="warning" @click.prevent="phanQuyenVaiTro(scope.row)">Phân
                  quyền
                </el-button>
                <el-button size="mini" type="danger" @click.prevent="XoaVaiTro(scope.row)">Xóa</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>


    <!-- Menu -->
    <el-row>
      <el-col>
        <el-dialog
            :close-on-press-escape="false"
            :close-on-click-modal="false" title="Thêm mới vai trò" center :visible.sync="hienThi.themMoiVaiTro">
          <el-row :gutter="24">
            <el-col :xs="24" :sm="12">
              <label>Tên vai trò</label>
              <el-input size="mini" placeholder="Nhập" v-model="vaiTro.roleName">
              </el-input>
            </el-col>
            <el-col :xs="24" :sm="12">
              <label>Mã</label>
              <el-input size="mini" placeholder="Nhập" v-model="vaiTro.role">
              </el-input>
            </el-col>
          </el-row>
          <div slot="footer" class="dialog-footer">
            <el-button size="mini" @click="hienThi.themMoiVaiTro = false">Đóng</el-button>
            <el-button size="mini" type="primary" @click.prevent="confirmThemVaiTro">Thêm</el-button>
          </div>
        </el-dialog>

        <el-dialog
            :close-on-press-escape="false"
            :close-on-click-modal="false" title="Thêm thông tin" center :visible.sync="hienThi.themNhomMenuCon">
          <el-row :gutter="24">
            <el-col :xs="24" :sm="12">
              <label>Tên menu</label>
              <el-input size="mini" placeholder="Nhập" v-model="thongTinThemNhom.menuName">
              </el-input>
            </el-col>
            <el-col :xs="24" :sm="12">
              <label>Đường dẫn</label>
              <el-input size="mini" placeholder="Nhập" v-model="thongTinThemNhom.menuUrl">
              </el-input>
            </el-col>
            <el-col :xs="24" :sm="12">
              <label>Vị trí sắp xếp</label>
              <el-input size="mini" placeholder="Nhập" v-model="thongTinThemNhom.sort">
              </el-input>
            </el-col>
            <el-col :xs="24" :sm="12">
              <label>Icon</label>
              <el-input size="mini" placeholder="Nhập" v-model="thongTinThemNhom.menuIcon">
              </el-input>
            </el-col>
          </el-row>
          <div slot="footer" class="dialog-footer">
            <el-button size="mini" @click="hienThi.themNhomMenuCon = false">Đóng</el-button>
            <el-button size="mini" type="primary" @click.prevent="confirmThemNhomMenuCon">Thêm</el-button>
          </div>
        </el-dialog>
        <el-dialog
            :close-on-press-escape="false"
            :close-on-click-modal="false" title="Thêm thông tin" center :visible.sync="hienThi.themMenuCon">
          <el-row :gutter="24">
            <el-col :xs="24" :sm="12">
              <label>Tên menu</label>
              <el-input size="mini" placeholder="Nhập" v-model="thongTinThem.menuName">
              </el-input>
            </el-col>
            <el-col :xs="24" :sm="12">
              <label>Đường dẫn</label>
              <el-input size="mini" placeholder="Nhập" v-model="thongTinThem.menuUrl">
              </el-input>
            </el-col>
            <el-col :xs="24" :sm="12">
              <label>Vị trí sắp xếp</label>
              <el-input size="mini" placeholder="Nhập" v-model="thongTinThem.sort">
              </el-input>
            </el-col>
            <el-col :xs="24" :sm="12">
              <label>Icon</label>
              <el-input size="mini" placeholder="Nhập" v-model="thongTinThem.menuIcon">
              </el-input>
            </el-col>
          </el-row>
          <div slot="footer" class="dialog-footer">
            <el-button size="mini" @click="hienThi.themMenuCon = false">Đóng</el-button>
            <el-button size="mini" type="primary" @click.prevent="confirmThemMenuCon">Thêm</el-button>
          </div>
        </el-dialog>
        <el-dialog
            :close-on-press-escape="false"
            :close-on-click-modal="false" title="Chỉnh sửa thông tin" center :visible.sync="hienThi.chinhSuaMenuCon">
          <el-row :gutter="24">
            <el-col :xs="24" :sm="12">
              <label>Tên menu</label>
              <el-input size="mini" placeholder="Nhập" v-model="thongTinChinhSua.menuName">
              </el-input>
            </el-col>
            <el-col :xs="24" :sm="12">
              <label>Đường dẫn</label>
              <el-input size="mini" placeholder="Nhập" v-model="thongTinChinhSua.menuUrl">
              </el-input>
            </el-col>
            <el-col :xs="24" :sm="12">
              <label>Vị trí sắp xếp</label>
              <el-input size="mini" placeholder="Nhập" v-model="thongTinChinhSua.sort">
              </el-input>
            </el-col>
            <el-col :xs="24" :sm="12">
              <label>Icon</label>
              <el-input size="mini" placeholder="Nhập" v-model="thongTinChinhSua.menuIcon">
              </el-input>
            </el-col>
          </el-row>
          <div slot="footer" class="dialog-footer">
            <el-button size="mini" @click="hienThi.themMenuCon = false">Đóng</el-button>
            <el-button size="mini" type="primary" @click.prevent="confirmChinhSuaMenuCon">Thêm</el-button>
          </div>
        </el-dialog>
        <el-dialog
            :close-on-press-escape="false"
            :close-on-click-modal="false" title="Phân quyền vai trò" width="70%" center
            :visible.sync="hienThi.phanQuyenVaiTro">
          <el-row :gutter="24">
            <el-col :span="18">
              <eselect style="width:100%" collapseTags v-model="maDonVi"
                       :placeholder="'Chọn Sở Giáo dục và Đào tạo'" filterable :data="list_so"
                       :fields="['name','value']"/>
            </el-col>
            <el-col :span="6">
              <el-button type="primary" @click="findMenuRoleByMaSo()">Tìm kiếm</el-button>
            </el-col>
            <el-col :span="12">
              <div class="bd" v-if="listVaiTroPhanQuyen">
                <v-jstree :data="listVaiTroPhanQuyen" show-checkbox="show-checkbox" multiple="multiple"
                          allow-batch="allow-batch" whole-row="whole-row" @item-click="itemClick2">
                  <template slot-scope="_">
                    <div style="display: inherit">
                      <i v-if="_.model.menu" class="el-icon-folder"></i>
                      <i v-else class="el-icon-menu"></i>
                      <span style="padding-right:30px;">{{ _.model.menuName }}</span>
                    </div>
                  </template>
                </v-jstree>
              </div>
            </el-col>
          </el-row>
          <div slot="footer" class="dialog-footer">
            <el-button size="mini" @click="hienThi.themMenuCon = false">Đóng</el-button>
            <el-button size="mini" type="primary" @click.prevent="confirmPhanQuyenVaiTro">Phân quyền</el-button>
          </div>
        </el-dialog>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  mapActions,
  mapState
} from 'vuex';
import api from '../_helpers/api';
import constant from '../_helpers/constant_api';
import VJstree from 'vue-jstree'
import ESelectVue from "./Ui/ESelect";

export default {
  name: "QuanLyMenu",
  metaInfo: {
    title: 'Quản lý menu',
    titleTemplate: '',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: ''
    }]
  },
  computed: {
    ...mapState('account', [
      'user',
    ]),
  },
  components: {
    VJstree,
    'eselect': ESelectVue,
  },

  data() {
    return {
      maDonVi: '',
      idPhanQuyen: '',
      list_so: [],
      listReport: [],
      listVaiTro: [],
      listVaiTroPhanQuyen: [],
      vaiTro: {
        roleName: '',
        role: ''
      },
      loading: {
        fullScreen: false,
      },
      hienThi: {
        themMenuCon: false,
        chinhSuaMenuCon: false,
        themNhomMenuCon: false,
        themMoiVaiTro: false,
        phanQuyenVaiTro: false,
      },
      thongTinThem: {},
      thongTinChinhSua: {},
      thongTinThemNhom: {},
    }
  },
  mounted() {
    this.getListMenu();
    this.getListVaiTro();
    this.getDanhSachSo();
  },
  methods: {
    itemClick2(node) {
      console.log(node)
      let allCheck2 = [];
      if (node.data.children) {
        allCheck2 = node
            .data
            .children
            .filter((obj) => obj.selected);
      }
      if (node.model.selected) {
        node.data.selected = true;
      } else {
        if (!allCheck2.length) {
          node.data.selected = false;
        }
      }
      console.log("chọn:");
      console.log(JSON.stringify(node.data));
    },
    findMenuRoleByMaSo() {
      if (!this.maDonVi) {
        this.thongBao('error', 'Thiếu tên đơn vị');
        return
      }
      ;
      let params = {
        maSo: this.maDonVi,
        roleId: this.idPhanQuyen,
      }
      let url = constant.vaitro.findMenuRoleByMaSo;
      this.loading.fullScreen = true;
      api.get(url, params).then(
          response => {
            console.log("Res:")
            console.log(response)
            if (response.data.code == 200) {
              this.listVaiTroPhanQuyen = response.data.data;
            } else {
            }
            this.loading.fullScreen = false;
          }
      )
    },
    getDanhSachSo() {
      let url = constant.configKy.getDanhSachSo;
      api.get(url, {}).then(
          response => {
            console.log("res")
            console.log(response)
            if (response.data.code == 200) {
              let dulieu = response.data.data

              let ds_so = [];
              for (let i = 0; i < dulieu.length; i++) {
                let obj = {
                  name: dulieu[i].tenDonVi + " - [" + dulieu[i].maDonVi + "]",
                  value: dulieu[i].maDonVi
                }
                ds_so.push(obj)
              }
              this.list_so = ds_so;
            } else {
            }
          }
      )
    },
    XoaVaiTro(e) {
      this.idPhanQuyen = e.id;
      this.$confirm('Xác nhận xóa vai trò?', 'Thông báo', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
        closeOnClickModal: false,
      })
          .then(_ => {
            let url = constant.vaitro.delete;
            this.loading.fullScreen = true;
            let params = {
              id: this.idPhanQuyen
            }
            api.get(url, params).then(
                response => {
                  this.loading.fullScreen = false;
                  if (response.data.code == 200) {
                    this.thongBao('success', 'Xóa thông tin thành công');
                    this.getListVaiTro();
                  } else {
                    this.thongBao('error', response.data.msg)
                  }
                }
            )
          })
          .catch(_ => {
          });
    },
    phanQuyenVaiTro(e) {
      console.log("Phân quyền cho:")
      console.log(e)
      this.idPhanQuyen = e.id;
      this.maDonVi = '';
      this.listVaiTroPhanQuyen = [];
      this.hienThi.phanQuyenVaiTro = true;
    },
    confirmPhanQuyenVaiTro() {
      this.$confirm('Xác nhận phân quyền vai trò?', 'Thông báo', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
      })
          .then(_ => {
            let url = constant.vaitro.createMenuRole;
            console.log(url)
            this.loading.fullScreen = true;
            api.post(url, this.listVaiTroPhanQuyen).then(
                response => {
                  console.log("res")
                  console.log(response)
                  this.loading.fullScreen = false;
                  if (response.data.code == 200) {
                    this.thongBao('success', 'Phân quyền thông tin thành công');
                    this.loading.fullScreen = false;
                    this.hienThi.phanQuyenVaiTro = false;
                    this.getListVaiTro();
                  } else {
                    this.thongBao('error', response.data.msg)
                  }
                }
            )
          })
          .catch(_ => {
          });
    },
    confirmThemVaiTro() {
      if (!this.vaiTro.roleName) {
        this.thongBao('error', 'Thiếu tên vai trò');
        return
      }
      ;
      if (!this.vaiTro.role) {
        this.thongBao('error', 'Thiếu mã vai trò');
        return
      }
      ;
      this.$confirm('Xác nhận thêm mới vai trò?', 'Thông báo', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
      })
          .then(_ => {
            this.loading.fullScreen = true;
            let url = constant.vaitro.create;
            console.log(url)
            api.post(url, this.vaiTro).then(
                response => {
                  console.log("res")
                  console.log(response)
                  this.loading.fullScreen = false;
                  if (response.data.code == 200) {
                    this.thongBao('success', 'Thêm thông tin thành công');
                    this.loading.fullScreen = false;
                    this.hienThi.themMoiVaiTro = false;
                    this.getListVaiTro();
                  } else {
                    this.thongBao('error', response.data.msg)
                  }
                }
            )
          })
          .catch(_ => {
          });
    },
    themMoiVaiTro() {
      console.log("Thêm mới vai trò")
      this.hienThi.themMoiVaiTro = true;
    },
    getListVaiTro() {
      this.loading.fullScreen = true;
      let params = {
        start: 0,
        limit: 20
      }
      let url = constant.vaitro.listForPage;
      api.get(url, params).then(
          response => {
            console.log("Res:")
            console.log(response)
            if (response.data.code == 200) {
              this.listVaiTro = response.data.data.list;
            } else {
            }
            this.loading.fullScreen = false;
          }
      )
    },


    confirmChinhSuaMenuCon() {
      if (!this.thongTinChinhSua.menuName) {
        this.thongBao('error', 'Thiếu tên menu');
        return
      }
      ;
      if (!this.thongTinChinhSua.menuUrl) {
        this.thongBao('error', 'Thiếu đường dẫn menu');
        return
      }
      ;
      this.$confirm('Xác nhận chỉnh sửa?', 'Thông báo', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
      })
          .then(_ => {
            this.loading.fullScreen = true;
            let params = {
              id: this.thongTinChinhSua.id,
              menuIcon: this.thongTinChinhSua.menuIcon,
              menuName: this.thongTinChinhSua.menuName,
              menuUrl: this.thongTinChinhSua.menuUrl,
              parentId: this.thongTinChinhSua.parentId,
              sort: this.thongTinChinhSua.sort,
            }
            console.log("Thông tin thêm:")
            console.log(params)
            let url = constant.menu.update;
            api.post(url, params).then(
                response => {
                  this.loading.fullScreen = false;
                  console.log("Res:")
                  console.log(response)
                  if (response.data.code == 200) {
                    this.thongBao('success', 'Chỉnh sửa thông tin thành công');
                    this.loading.fullScreen = false;
                    this.hienThi.chinhSuaMenuCon = false;
                    this.getListMenu();
                  } else {
                    this.thongBao('error', response.data.msg)
                  }
                }
            )
          })
          .catch(_ => {
          });
    },
    confirmThemMenuCon() {
      if (!this.thongTinThem.menuName) {
        this.thongBao('error', 'Thiếu tên menu');
        return
      }
      ;
      if (!this.thongTinThem.menuUrl) {
        this.thongBao('error', 'Thiếu đường dẫn menu');
        return
      }
      ;
      this.$confirm('Xác nhận thêm mới?', 'Thông báo', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
      })
          .then(_ => {
            this.loading.fullScreen = true;
            let params = {
              menuIcon: this.thongTinThem.menuIcon,
              menuName: this.thongTinThem.menuName,
              menuUrl: this.thongTinThem.menuUrl,
              parentId: this.thongTinThem.id,
              sort: this.thongTinThem.sort,
              menu: false,
            }
            console.log("Thông tin thêm:")
            console.log(params)
            let url = constant.menu.create;
            api.post(url, params).then(
                response => {
                  this.loading.fullScreen = false;
                  console.log("Res:")
                  console.log(response)
                  if (response.data.code == 200) {
                    this.thongBao('success', 'Thêm mới thông tin thành công');
                    this.loading.fullScreen = false;
                    this.hienThi.themMenuCon = false;
                    this.getListMenu();
                  } else {
                    this.thongBao('error', response.data.msg)
                  }
                }
            )
          })
          .catch(_ => {
          });
    },
    confirmThemNhomMenuCon() {
      if (!this.thongTinThemNhom.menuName) {
        this.thongBao('error', 'Thiếu tên menu');
        return
      }
      ;
      if (!this.thongTinThemNhom.menuUrl) {
        this.thongBao('error', 'Thiếu đường dẫn menu');
        return
      }
      ;
      this.$confirm('Xác nhận thêm mới?', 'Thông báo', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
      })
          .then(_ => {
            this.loading.fullScreen = true;
            let params = {
              parentId: this.thongTinThemNhom.id,
              menuIcon: this.thongTinThemNhom.menuIcon,
              menuName: this.thongTinThemNhom.menuName,
              menuUrl: this.thongTinThemNhom.menuUrl,
              sort: this.thongTinThemNhom.sort,
              menu: true,
            }
            console.log("Thông tin thêm:")
            console.log(params)
            let url = constant.menu.create;
            api.post(url, params).then(
                response => {
                  this.loading.fullScreen = false;
                  console.log("Res:")
                  console.log(response)
                  if (response.data.code == 200) {
                    this.thongBao('success', 'Thêm mới thông tin thành công');
                    this.loading.fullScreen = false;
                    this.hienThi.themNhomMenuCon = false;
                    this.getListMenu();
                  } else {
                    this.thongBao('error', response.data.msg)
                  }
                }
            )
          })
          .catch(_ => {
          });
    },
    xoaMenuCon(e) {
      this.$confirm('Xác nhận xóa thông tin?', 'Thông báo', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
      })
          .then(_ => {
            this.loading.fullScreen = true;
            let params = {
              id: e.id,
            }
            console.log("Thông tin thêm:")
            console.log(params)
            let url = constant.menu.delete;
            api.get(url, params).then(
                response => {
                  this.loading.fullScreen = false;
                  console.log(response)
                  if (response.data.code == 200) {
                    this.thongBao('success', 'Xóa thông tin thành công');
                    this.getListMenu();
                  } else {
                    this.thongBao('error', response.data.msg)
                  }
                }
            )
          })
          .catch(_ => {
          });
    },
    themNhomMenuCon(e) {
      this.thongTinThemNhom = JSON.parse(JSON.stringify(e));
      this.hienThi.themNhomMenuCon = true;
    },
    themMenuCon(e) {
      this.thongTinThem = JSON.parse(JSON.stringify(e));
      this.hienThi.themMenuCon = true;
    },
    chinhSuaMenuCon(e) {
      this.thongTinChinhSua = JSON.parse(JSON.stringify(e));
      this.hienThi.chinhSuaMenuCon = true;
    },
    demoFunction() {
      console.log("demoFunction")
    },
    getListMenu() {
      this.loading.fullScreen = true;
      let params = {}
      let url = constant.menu.findAll;
      api.get(url, params).then(
          response => {
            this.loading.fullScreen = false;
            console.log("Res:")
            console.log(response)
            if (response.data.code == 200) {
              this.listReport = response.data.data;
            } else {
            }
            this.loading.fullScreen = false;
          }
      )
    },
    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t
      }
      if (type == "success") {
        cl = "dts-noty-success"
      }
      if (type == "warning") {
        cl = "dts-noty-warning"
      }
      if (type == "error") {
        cl = "dts-noty-error"
      }
      if (type == "info") {
        cl = "dts-noty-info"
      }
      document.querySelector('.overlay').style.display = 'block';
      this.$message({
        onClose: () => {
          document.querySelector('.overlay').style.display = 'none';
        },
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000
      });
    },

  },
  watch: {}
}
</script>

<style scoped>
.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>