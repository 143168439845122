var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "logo" }, [
    _c(
      "div",
      { staticClass: "name mr-3" },
      [
        _c(
          "el-link",
          {
            staticClass: "userinfo d-flex align-items-center",
            attrs: { underline: false },
            on: {
              click: function ($event) {
                return _vm.openPage()
              },
            },
          },
          [
            _c("div", { staticClass: "d-flex align-items-center" }, [
              _c("img", { attrs: { src: "/images/img2.png", alt: "logo" } }),
              _vm._v(" "),
              _c("span", { staticClass: "name--display" }, [
                _vm._v(
                  " " +
                    _vm._s(this.thongtin ? this.thongtin.displayName : "") +
                    " "
                ),
              ]),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }