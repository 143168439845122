import {untilService} from "../_services/until.service";
import {reportService} from "../_services";

const state = {
    detailReport: '',
};

const actions = {
    getReport({commit}, data) {
        reportService.getReport(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('getReport', response.data.data)
                } else {
                    commit('getReport', '')
                }
            }
        ).catch(function (e) {
            commit('getReport', '')
        })
    },

    getReportPhong({commit}, data) {
        let maPhong = data.maPhong;
        reportService.getReportPhong(data).then(
            response => {
                console.log(response);
                if (response.data && response.data.code == 200) {
                    commit('getReportPhong', {maPhong: maPhong, data: response.data.data})
                } else {
                    commit('getReportPhong', '')
                }
            }
        ).catch(function (e) {
            commit('getReportPhong', '')
        })
    },

    getReportKyLai({commit}, data) {
        reportService.getReportKyLai(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('getReportKyLai', response.data.data)
                } else {
                    commit('getReportKyLai', '')
                }
            }
        ).catch(function (e) {
            commit('getReport', '')
        })
    },
    getReportKyLaiPhong({commit}, data) {
        let maPhong = data.maPhong;
        reportService.getReportKyLaiPhong(data).then(
            response => {
                console.log(response);
                if (response.data && response.data.code == 200) {
                    commit('getReportKyLaiPhong', {maPhong: maPhong, data: response.data.data})
                } else {
                    commit('getReportKyLaiPhong', '')
                }
            }
        ).catch(function (e) {
            commit('getReportKyLaiPhong', '')
        })
    },
};

const mutations = {
    getReport(state, data) {
        if (data && data.baoCaoKyTheoPhongs) {
            data.baoCaoKyTheoPhongs.map((item) => {
                item.loading = true;
                item.baoCaoKyTheoTruongs = [];
                return item;
            });
        }
        state.detailReport = data;

    },

    getReportPhong(state, data) {
        console.log(data, data.maPhong, data.data);
        let maPhong = data.maPhong;
        let bcPhong = data.data.baoCaoKyTheoPhongs[0];
        let lstphong = JSON.parse(JSON.stringify(state.detailReport.baoCaoKyTheoPhongs));

        lstphong.map((item) => {
            console.log(item.maPhong, maPhong)
            if (item.maPhong == maPhong) {
                item.loading = false;
                item.baoCaoKyTheoTruongs = bcPhong.baoCaoKyTheoTruongs
            }
            return item;
        })

        state.detailReport.baoCaoKyTheoPhongs = lstphong
        // if (data && data.maPhong && data.data){
        //
        //     let data = data.data;
        //     let baoCao = data.data.baoCaoKyTheoPhongs[0];
        //     console.log(data.maPhong,baoCao)
        //     // baoCaoKyTheoTruongs
        //     state.detailReport.baoCaoKyTheoPhongs.map(item =>{
        //         if (item.maPhong == data.maPhong){
        //             item.loading = false;
        //             item.baoCaoKyTheoTruongs = baoCao.baoCaoKyTheoTruongs;
        //         }
        //         return item;
        //     })
        // }
    },

    getReportKyLai(state, data) {
        if (data && data.baoCaoKyTheoPhongs) {
            data.baoCaoKyTheoPhongs.map((item) => {
                item.loading = true;
                item.baoCaoKyTheoTruongs = [];
                return item;
            });
        }
        state.detailReport = data;

    },

    getReportKyLaiPhong(state, data) {
        console.log(data, data.maPhong, data.data);
        let maPhong = data.maPhong;
        let bcPhong = data.data.baoCaoKyTheoPhongs[0];
        let lstphong = JSON.parse(JSON.stringify(state.detailReport.baoCaoKyTheoPhongs));

        lstphong.map((item) => {
            console.log(item.maPhong, maPhong)
            if (item.maPhong == maPhong) {
                item.loading = false;
                item.baoCaoKyTheoTruongs = bcPhong.baoCaoKyTheoTruongs
            }
            return item;
        })

        state.detailReport.baoCaoKyTheoPhongs = lstphong
        // if (data && data.maPhong && data.data){
        //
        //     let data = data.data;
        //     let baoCao = data.data.baoCaoKyTheoPhongs[0];
        //     console.log(data.maPhong,baoCao)
        //     // baoCaoKyTheoTruongs
        //     state.detailReport.baoCaoKyTheoPhongs.map(item =>{
        //         if (item.maPhong == data.maPhong){
        //             item.loading = false;
        //             item.baoCaoKyTheoTruongs = baoCao.baoCaoKyTheoTruongs;
        //         }
        //         return item;
        //     })
        // }
    },

};

export const reportKy = {
    namespaced: true,
    state,
    actions,
    mutations
};
