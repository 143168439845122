var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.info
    ? _c(
        "div",
        [
          _vm.openID !== _vm.info.maPhong && _vm.openID !== _vm.info.maTruongHoc
            ? _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("moment")(new Date(_vm.info.thoiGian), "DD/MM/YYYY")
                  )
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.openID &&
          (_vm.openID == _vm.info.maPhong || _vm.openID == _vm.info.maTruongHoc)
            ? _c(
                "span",
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "150px" },
                    attrs: {
                      "picker-options": _vm.pickerOptions,
                      format: "dd/MM/yyyy",
                      type: "date",
                      placeholder: "Ngày chốt ký",
                      "value-format": "timestamp",
                    },
                    model: {
                      value: _vm.ngayChotSo,
                      callback: function ($$v) {
                        _vm.ngayChotSo = $$v
                      },
                      expression: "ngayChotSo",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-button", {
                    attrs: {
                      type: "success",
                      size: "mini",
                      title: "Xác nhận",
                      icon: "el-icon-check",
                    },
                    on: { click: [function ($event) {}, _vm.updateSoAction] },
                  }),
                  _vm._v(" "),
                  _c("el-button", {
                    attrs: {
                      type: "danger",
                      icon: "el-icon-close",
                      size: "mini",
                      title: "Hủy",
                    },
                    on: { click: _vm.closeEdit },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.openID !== _vm.info.maPhong && _vm.openID !== _vm.info.maTruongHoc
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    title: "Sửa ngày chốt",
                  },
                  on: { click: [function ($event) {}, _vm.openEdit] },
                },
                [_c("i", { staticClass: "el-icon-edit" })]
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }