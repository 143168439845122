import {schoolService, teacherService} from "../_services";
import {router} from "../_router";

const state = {
    listPGD: null,
    listSchool: null,
    infoSchool: null,
    statusUpdate: null,
    dongBo: null,
    historySync : null
}

const actions = {
    getInfo({commit}, data) {
        console.log("Lấy thông tin tài khoản:")
        commit('getInfo', null)
        schoolService.getInfo(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('getInfo', response.data.data)
                } else {
                    commit('getInfo', null)
                }
            }
        ).catch(function (e) {
            commit('getInfo', null)
        });
    },

    updateInfo({commit}, data) {
        commit('updateInfo', null);
        let type = data.type;
        let dataImage = data.image;
        let maTruong = data.maTruong;
        let email = data.email;
        let sdt = data.sdt;
        let params = {};
        if (dataImage) {
            teacherService.uploadFile({data: dataImage}).then(
                response => {
                    schoolService.updateInfo({
                        type: type,
                        id: maTruong,
                        data: dataImage
                    }).then(
                        response => {
                            if (response.data && response.data.code == 200) {
                                commit('updateInfo', {
                                    status: 200,
                                    msg: 'Cập nhật dấu thành công',
                                });
                            } else {
                                commit('updateInfo', {
                                    status: response.data.code,
                                    msg: response.data.msg,
                                });
                            }
                        }
                    ).catch(function (e) {
                        commit('updateInfo', {
                            status: 400,
                            msg: "Lỗi kết nối vui lòng thử lại sau",
                        });
                    });
                }
            ).catch(function (e) {
                commit('updateInfo', {
                    status: 400,
                    msg: "Lỗi kết nối vui lòng thử lại sau",
                });
            });
        } else {
            schoolService.updateInfo({
                type: type,
                id: maTruong,
                emailLienHe: email,
                sdtLienHe: sdt
            }).then(
                response => {
                    if (response.data && response.data.code == 200) {
                        commit('updateInfo', {
                            status: 200,
                            msg: 'Cập nhật thông tin thành công',
                        });
                    } else {
                        commit('updateInfo', {
                            status: response.data.code,
                            msg: response.data.msg,
                        });
                    }
                }
            ).catch(function (e) {
                commit('updateInfo', {
                    status: 400,
                    msg: "Lỗi kết nối vui lòng thử lại sau",
                });
            });
        }


    },

    getListPGD({commit}, data) {
        commit('getListPGD', null)
        schoolService.getListPGD(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('getListPGD', response.data.data)
                } else {
                    commit('getListPGD', null)
                }
            }
        ).catch(function (e) {
            commit('getListPGD', null)
        });
    },

    getHistorySync({commit}, data) {
        schoolService.getHistorySync(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('getHistorySync', response.data.data)
                } else {
                    commit('getHistorySync', null)
                }
            }
        ).catch(function (e) {
            commit('getHistorySync', null)
        })
    },


    getListSchool({commit}, data) {
        commit('getListSchool', null);
        schoolService.getListShool(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('getListSchool', response.data.data)
                } else {
                    commit('getListSchool', null)
                }
            }
        ).catch(function (e) {
            commit('getListSchool', null)
        })
    },

    actionDongBo({commit}, data) {
        commit('dongBoDuLieu',null)
        schoolService.dongBo(data).then(
            response => {
                console.log(response)
                if (response.data && response.data.code == 200) {
                    commit('dongBoDuLieu', {
                        status: 200,
                        msg: 'Đồng bộ dữ liệu thành công',
                    });
                } else {
                    commit('dongBoDuLieu', {
                        status: response.data.code,
                        msg: response.data.msg,
                    });

                }
            }
        ).catch(function (e) {
            commit('dongBoDuLieu', {
                status: 400,
                msg: "Lỗi kết nối vui lòng thử lại sau",
            });
        })
    }
}

const mutations = {
    getListPGD(state, data) {
        state.listPGD = data;
    },
    getListSchool(state, data) {
        state.listSchool = data;
    },
    getInfo(state, data) {
        state.infoSchool = data;
    },
    updateInfo(state, data) {
        state.statusUpdate = data;
    },
    dongBoDuLieu(state, data) {
        state.dongBo = data;
    },
    getHistorySync (state,data){
        state.historySync = data;
    }
}

export const school = {
    namespaced: true,
    state,
    actions,
    mutations
};
