var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "page page-file file detailHocBa",
      attrs: { id: "baoCaoKy" },
    },
    [_vm._m(0), _vm._v(" "), _vm._m(1)]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "page__title d-flex align-items-center justify-content-between",
      },
      [_c("h1", { staticClass: "title" }, [_vm._v("Quản lý gói cước")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page__content" }, [
      _c("div", { staticClass: "listNew mb-5" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }