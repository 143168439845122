import axios from 'axios';

import {
    authHeader
} from './auth-header'
import LZString from "lz-string";

export default {

    DEFAULT_TIME_OUT: 60000,
    /**
     * Send request
     * Method: POST
     *
     * */
    put(url, params, timeout = this.DEFAULT_TIME_OUT) {
        let tm = timeout;
        console.log("PUT " + url);
        console.log("req = " + JSON.stringify(params));
        let data = {
            "path": url,
            "method": "PUT",
            "data": params,
            "timeout": timeout
        };
        return this._request(data, tm);
    },
    /**
     * Send request
     * Method: POST
     *
     * */
    post(url, params, timeout = this.DEFAULT_TIME_OUT) {
        let baseUri = BASE_URL_API;
        let tm = timeout;
        console.log("GET " + url);
        console.log("req = " + JSON.stringify(params));
        let config = {
            timeout: tm,
            headers: authHeader()
        };
        let uri = baseUri + url;
        return axios.post(uri,params,config)
    },
    postSmas(url, params, timeout = this.DEFAULT_TIME_OUT) {
        let baseUri = BASE_URL_API;
        let tm = timeout;
        console.log("GET " + url);
        console.log("req = " + JSON.stringify(params));
        let config = {
            timeout: tm,
            headers: params
        };
        let uri = baseUri + url;
        return axios.post(uri,params,config)
    },
    postLogin(url, params, timeout = this.DEFAULT_TIME_OUT) {
        let tm = timeout;
        console.log("POST " + url);
        console.log("req = " + JSON.stringify(params));
        let data = {
            "path": url,
            "method": "POST",
            "data": params,
            "timeout": timeout
        };
        return this._request(data, tm);
    },
    postSso(url, params, timeout = this.DEFAULT_TIME_OUT) {
        
        let tm = timeout;
        console.log("GET " + url);
        console.log("req = " + JSON.stringify(params));
        let config = {
            timeout: tm,
            headers: authHeader()
        };
        let uri = url;
        return axios.post(uri,params,config)
    },
    /**
     * Send request
     * Method: GET
     *
     * */
    // get(url, params, timeout = this.DEFAULT_TIME_OUT) {
    //     let tm = timeout;
    //     console.log("GET " + url);
    //     console.log("req = " + JSON.stringify(params));
    //     let data = {
    //         "path": url,
    //         "method": "GET",
    //         "data": params,
    //         "timeout" : timeout
    //     };
    //     return this._request(data, tm);
    // },


    getBak(url, params, timeout = this.DEFAULT_TIME_OUT) {
        let baseUri = BASE_URL_API;
        let tm = timeout;
        let config = {
            timeout: tm,
            headers: authHeader()
        };

        // let query = new URLSearchParams(params);
        let query = this.objectToQueryString(params);
        let uri = baseUri + url + "?" + query;
        return axios.get(uri,config)

    },
    async get(url, params, timeout = this.DEFAULT_TIME_OUT) {
        console.warn('get')
        let baseUri = BASE_URL_API;
        let tm = timeout;
        let config = {
            timeout: tm,
            headers: authHeader()
        };
        let query = this.objectToQueryString(params);
        let uri = url + "?" + query;
        let dataSend = {
            path: uri,
            method: 'GET',
            data: ''
        }
        var compressed = LZString.compressToBase64(JSON.stringify(dataSend));
        console.log('Send:')
        console.log(LZString.decompressFromBase64(compressed))
        let pr = {
            data: this.randomString(3) + compressed
        }
        // let result = axios.post(baseUri + '/'+this.randomString(15), pr, config)
        // return result
        let result
        await axios.post(baseUri + '/'+this.randomString(15), pr, config).then((response)=>{
            result = response.data
        });
        let dt = JSON.parse(JSON.stringify(result.data.slice(3)));
        result.data = JSON.parse(LZString.decompressFromBase64(dt))
        return result
    },

    getSso(url, params, timeout = this.DEFAULT_TIME_OUT) {
        let tm = timeout;
        let config = {
            timeout: tm,
            headers: authHeader()
        };

        // let query = new URLSearchParams(params);
        let query = this.objectToQueryString(params);
        let uri = url + "?" + query;
        return axios.get(uri,config)
    },

    objectToQueryString(obj) {
        let str = [];
        for (let p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    },

    _request(data, tm) {
        let config = {
            timeout: tm,
            headers: authHeader()
        };
        return axios.post("/forward-api", data, config)

    },
    randomString(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }
}
