import {untilService} from "../_services/until.service";
import {testService} from "../_services";

const state = {
    danhSachLop : [],
};

const actions = {
    getdanhSachLop({commit},data){
        testService.test(data).then(
            response => {
                if (response.data && response.data.code == 200){
                    commit('getdanhSachLop',response.data.data)
                }else {
                    commit('getdanhSachLop',[])
                }
            }
        ).catch(function (e){
            commit('getdanhSachLop',[])
        })
    }
};

const mutations = {
    getdanhSachLop(state,data) {
        state.danhSachLop = data;
    },

};

export const test = {
    namespaced: true,
    state,
    actions,
    mutations
};
