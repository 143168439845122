import api from "../api";
import {sprintf} from "sprintf-js";
import restApi from "../_helpers/api";

export const configKy = {
    layDanhSachCauHinhTheoPhong,
    layDanhSachCauHinhTheoSo,
    getDanhSachCauHinh,
    chinhSuaKieuKy,
    themMoiCauHinhSo,
    xoaCauHinhSo,
    getDanhSachSo,
    getListConfig,
    updateConfig,
    updateConfig2,
    create
};

function layDanhSachCauHinhTheoPhong(data) {
    let uri = api.configKy.layDanhSachCauHinhTheoPhong;
    return restApi.get(uri,data);
}
function layDanhSachCauHinhTheoSo(data) {
    let uri = api.configKy.layDanhSachCauHinhTheoSo;
    return restApi.get(uri,data);
}
function themMoiCauHinhSo(data) {
    let uri = api.configKy.themMoiCauHinhSo;
    return restApi.post(uri, data);
}
function chinhSuaKieuKy(data) {
    let uri = api.configKy.chinhSuaKieuKy;
    return restApi.post(uri, data);
}
function xoaCauHinhSo(data) {
    let uri = api.configKy.xoaCauHinhSo;
    return restApi.post(uri, data);
}
function getDanhSachCauHinh(data){
    let uri = api.configKy.danhSachCauHinh;
    return restApi.get(uri, data);
}
function getDanhSachSo(data) {
    let uri = api.configKy.getDanhSachSo;
    return restApi.get(uri, data);
}
function getListConfig(data) {
    let uri = api.configKy.getConfigKy;
    return restApi.get(uri, data);
}

function updateConfig(data){
    let uri = api.configKy.updateConfigKy;
    return restApi.post(uri, data);
}

function updateConfig2(data){
    let uri = api.configKy.updateConfig2;
    return restApi.post(uri, data);
}

function create(data){
    let uri = api.configKy.createConfigKy;
    return restApi.post(uri, data);
}