var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading.fullScreen,
          expression: "loading.fullScreen",
        },
      ],
      staticClass: "page page-request",
      attrs: {
        "element-loading-text": "Loading...",
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(255, 255, 255, 0.8)",
      },
    },
    [
      _vm.infoGiaoVien
        ? _c(
            "div",
            { staticClass: "page__content infoAccount" },
            [
              _c("iframe", {
                staticStyle: { display: "contents" },
                attrs: {
                  height: "1",
                  width: "1",
                  src: _vm.infoGiaoVien.linkChuKy,
                },
              }),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "dts-content", attrs: { gutter: 10 } },
                [
                  _c("el-col", { attrs: { lg: 3, md: 3, sm: 3 } }),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "bg-smoke text-center  card-info",
                      attrs: { lg: 18, md: 18, sm: 18, xs: 24 },
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 24 } },
                        [
                          _c("el-col", { staticClass: "text-center" }, [
                            _c("div", { staticClass: "img" }, [
                              _c("img", {
                                attrs: {
                                  src: "/images/default-avatar.png",
                                  width: "150px",
                                  alt: "Ảnh đại diện",
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("h3", [_vm._v(_vm._s(_vm.infoGiaoVien.tenGV))]),
                            _vm._v(" "),
                            _c(
                              "p",
                              { staticClass: "mt-2" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Đổi mật khẩu",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.showUpdatePass($event)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-key",
                                          staticStyle: { "font-size": "14px" },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 24 } },
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "text-left",
                              attrs: { sm: 12, xs: 24 },
                            },
                            [
                              _c("el-card", { attrs: { shadow: "always" } }, [
                                _c("div", { staticClass: "iconInfo" }, [
                                  _c("i", { staticClass: "el-icon-user" }),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "textInfo" }, [
                                  _c("span", [_vm._v("Mã giáo viên")]),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v(_vm._s(_vm.infoGiaoVien.maGV)),
                                  ]),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticClass: "text-left",
                              attrs: { sm: 12, xs: 24 },
                            },
                            [
                              _c("el-card", { attrs: { shadow: "always" } }, [
                                _c("div", { staticClass: "iconInfo" }, [
                                  _c("i", {
                                    staticClass: "el-icon-phone-outline",
                                  }),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "textInfo" }, [
                                  !_vm.updateSdt
                                    ? _c("div", [
                                        _c("span", [_vm._v("Số điện thoại")]),
                                        _vm._v(" "),
                                        _vm.infoGiaoVien.soDienThoai
                                          ? _c(
                                              "p",
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.infoGiaoVien.soDienThoai
                                                  ) + "\n                    "
                                                ),
                                                false
                                                  ? _c(
                                                      "el-tooltip",
                                                      {
                                                        staticClass: "item",
                                                        attrs: {
                                                          effect: "dark",
                                                          content:
                                                            "Chỉnh sửa số điện thoại",
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-edit-outline float-right cursor-pointer",
                                                          on: {
                                                            click:
                                                              _vm.showChinhSuaSdt,
                                                          },
                                                        }),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "p",
                                              [
                                                _vm._v(
                                                  "Chưa cập nhật\n                    "
                                                ),
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      effect: "dark",
                                                      content:
                                                        "Thêm thông tin số điện thoại",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-circle-plus-outline float-right cursor-pointer",
                                                      on: {
                                                        click:
                                                          _vm.showChinhSuaSdt,
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                      ])
                                    : _c(
                                        "div",
                                        [
                                          _c("span", [_vm._v("Số điện thoại")]),
                                          _vm._v(" "),
                                          _c(
                                            "el-input",
                                            {
                                              attrs: {
                                                placeholder: "Nhập sdt...",
                                                size: "mini",
                                              },
                                              model: {
                                                value:
                                                  _vm.infoGiaoVien.soDienThoai,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.infoGiaoVien,
                                                    "soDienThoai",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "infoGiaoVien.soDienThoai",
                                              },
                                            },
                                            [
                                              _c("el-button", {
                                                attrs: {
                                                  slot: "append",
                                                  icon: "el-icon-check",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.thayDoiSdt(
                                                      $event
                                                    )
                                                  },
                                                },
                                                slot: "append",
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticClass: "text-left",
                              attrs: { sm: 12, xs: 24 },
                            },
                            [
                              _c("el-card", { attrs: { shadow: "always" } }, [
                                _c("div", { staticClass: "iconInfo" }, [
                                  _c("i", { staticClass: "el-icon-message" }),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "textInfo" }, [
                                  _c("span", [_vm._v("Email")]),
                                  _vm._v(" "),
                                  _vm.infoGiaoVien.email
                                    ? _c("p", [
                                        _vm._v(_vm._s(_vm.infoGiaoVien.email)),
                                      ])
                                    : _c("p", [_vm._v("Chưa cập nhật")]),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticClass: "text-left",
                              attrs: { sm: 12, xs: 24 },
                            },
                            [
                              _c("el-card", { attrs: { shadow: "always" } }, [
                                _c("div", { staticClass: "iconInfo" }, [
                                  _c("i", { staticClass: "el-icon-edit" }),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "textInfo",
                                    staticStyle: { display: "flex" },
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticStyle: {
                                          "padding-right": "10px",
                                        },
                                      },
                                      [_c("span", [_vm._v("Chữ ký")])]
                                    ),
                                    _vm._v(" "),
                                    _vm.infoGiaoVien.linkChuKy
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "padding-right": "15px",
                                            },
                                          },
                                          [
                                            _c("el-image", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.infoGiaoVien.linkChuKy,
                                                  expression:
                                                    "infoGiaoVien.linkChuKy",
                                                },
                                              ],
                                              staticStyle: {
                                                width: "110px",
                                                height: "45px",
                                              },
                                              attrs: {
                                                src: _vm.infoGiaoVien.linkChuKy,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.xemAnh(
                                                    _vm.infoGiaoVien.linkChuKy
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          position: "absolute",
                                          right: "15px",
                                          bottom: "30px",
                                        },
                                      },
                                      [
                                        _vm.infoGiaoVien.trangThaiChuKy != 1
                                          ? _c(
                                              "el-tooltip",
                                              {
                                                attrs: {
                                                  content: "Tải lên chữ ký",
                                                  placement: "top",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "primary",
                                                      size: "mini",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.beforeUpload(
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-upload",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              content: "Xem trước",
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  size: "mini",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.xemAnhKy($event)
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-coordinate",
                                                }),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.showSmartCA
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "text-left",
                                  attrs: { sm: 12, xs: 24 },
                                },
                                [
                                  _c(
                                    "el-card",
                                    { attrs: { shadow: "always" } },
                                    [
                                      _c("div", { staticClass: "iconInfo" }, [
                                        _c("i", {
                                          staticClass: "el-icon-cloudy",
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "textInfo" }, [
                                        !_vm.updateSMCA
                                          ? _c("div", [
                                              _c("span", [
                                                _vm._v(
                                                  "Tài khoản Viettel SmartCA"
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _vm.infoGiaoVien.usernameCAVT
                                                ? _c(
                                                    "p",
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.infoGiaoVien
                                                              .usernameCAVT
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                      false
                                                        ? _c(
                                                            "el-tooltip",
                                                            {
                                                              staticClass:
                                                                "item",
                                                              attrs: {
                                                                effect: "dark",
                                                                content:
                                                                  "Chỉnh sửa tài khoản Viettel SmartCa",
                                                                placement:
                                                                  "top",
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "el-icon-edit-outline float-right cursor-pointer",
                                                                on: {
                                                                  click:
                                                                    _vm.showChinhSuaSMCA,
                                                                },
                                                              }),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "p",
                                                    [
                                                      _vm._v(
                                                        "Chưa cập nhật\n                    "
                                                      ),
                                                      _c(
                                                        "el-tooltip",
                                                        {
                                                          staticClass: "item",
                                                          attrs: {
                                                            effect: "dark",
                                                            content:
                                                              "Thêm thông tin tài khoản",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-circle-plus-outline float-right cursor-pointer",
                                                            on: {
                                                              click:
                                                                _vm.showChinhSuaSMCA,
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                            ])
                                          : _c(
                                              "div",
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "Tài khoản Viettel SmartCA"
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "el-input",
                                                  {
                                                    attrs: {
                                                      placeholder:
                                                        "Nhập thông tin...",
                                                      size: "mini",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.infoGiaoVien
                                                          .usernameCAVT,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.infoGiaoVien,
                                                          "usernameCAVT",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "infoGiaoVien.usernameCAVT",
                                                    },
                                                  },
                                                  [
                                                    _c("el-button", {
                                                      attrs: {
                                                        slot: "append",
                                                        icon: "el-icon-check",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.thayDoiSMCA(
                                                            $event
                                                          )
                                                        },
                                                      },
                                                      slot: "append",
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticClass: "text-left",
                              attrs: { sm: 12, xs: 24 },
                            },
                            [
                              _c("el-card", { attrs: { shadow: "always" } }, [
                                _c("div", { staticClass: "iconInfo" }, [
                                  _c("i", { staticClass: "el-icon-cloudy" }),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "textInfo" }, [
                                  _c(
                                    "div",
                                    [
                                      _c("span", [_vm._v("Serial Number")]),
                                      _vm._v(" "),
                                      _c(
                                        "el-row",
                                        { attrs: { gutter: 24 } },
                                        [
                                          _c(
                                            "el-col",
                                            {
                                              staticStyle: { margin: "0" },
                                              attrs: { span: 22 },
                                            },
                                            [
                                              _vm.infoGiaoVien.serialNumber
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticStyle: {
                                                        "white-space": "nowrap",
                                                        overflow: "hidden",
                                                        "text-overflow":
                                                          "ellipsis",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.infoGiaoVien
                                                              .serialNumber
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                    ]
                                                  )
                                                : _c("p", [
                                                    _vm._v(
                                                      "Chưa cập nhật\n                      "
                                                    ),
                                                  ]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          false
                                            ? _c(
                                                "el-col",
                                                { attrs: { span: 2 } },
                                                [
                                                  _vm.infoGiaoVien.serialNumber
                                                    ? _c(
                                                        "el-tooltip",
                                                        {
                                                          staticClass: "item",
                                                          attrs: {
                                                            effect: "dark",
                                                            content:
                                                              "Chỉnh sửa thông tin serial number",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-edit-outline float-right cursor-pointer",
                                                            on: {
                                                              click:
                                                                _vm.layChungThuSo,
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _c(
                                                        "el-tooltip",
                                                        {
                                                          staticClass: "item",
                                                          attrs: {
                                                            effect: "dark",
                                                            content:
                                                              "Thêm thông tin tài khoản",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-circle-plus-outline float-right cursor-pointer",
                                                            on: {
                                                              click:
                                                                _vm.layChungThuSo,
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.hien_thi_cap_nhat_cts
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "text-left",
                                  attrs: { sm: 24, xs: 24 },
                                },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      attrs: {
                                        fit: "",
                                        border: "",
                                        data: _vm.danh_sach_chung_thu_so,
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          "header-align": "center",
                                          label: "Số serial",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("el-input", {
                                                    attrs: { type: "text" },
                                                    model: {
                                                      value:
                                                        scope.row.serialNumber,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "serialNumber",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.serialNumber",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1723996229
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          property: "status",
                                          label: "Trạng thái",
                                          align: "center",
                                          "header-align": "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("el-input", {
                                                    attrs: { type: "text" },
                                                    model: {
                                                      value: scope.row.status,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "status",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.status",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1440842482
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          property: "validFrom",
                                          "header-align": "center",
                                          align: "center",
                                          label: "Ngày hiệu lực",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("el-input", {
                                                    attrs: { type: "text" },
                                                    model: {
                                                      value:
                                                        scope.row.validFrom,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "validFrom",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.validFrom",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          4063563654
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          property: "validTo",
                                          "header-align": "center",
                                          align: "center",
                                          label: "Ngày hết hiệu lực",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("el-input", {
                                                    attrs: { type: "text" },
                                                    model: {
                                                      value: scope.row.validTo,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "validTo",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.validTo",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1043937099
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          property: "nhaPhatHanh",
                                          "header-align": "center",
                                          align: "center",
                                          label: "Nhà phát hành",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("el-input", {
                                                    attrs: { type: "text" },
                                                    model: {
                                                      value:
                                                        scope.row.nhaPhatHanh,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "nhaPhatHanh",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.nhaPhatHanh",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1518932547
                                        ),
                                      }),
                                      _vm._v(" "),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          "header-align": "center",
                                          label: "Hành động",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        size: "mini",
                                                        type: "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.suDungCTS(
                                                            scope.row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("Sử dụng")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          122910693
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            title: "Ảnh chữ ký",
            width: "30%",
            center: "",
            visible: _vm.hienThiAnh,
          },
          on: {
            "update:visible": function ($event) {
              _vm.hienThiAnh = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "demo-image__lazy" },
            [
              _c("el-image", {
                staticStyle: { width: "100%" },
                attrs: { src: _vm.linkAnh, title: "Ảnh chữ ký", lazy: "" },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            title: "Xem trước ảnh khi ký",
            width: "60%",
            "custom-class": "anhKhiKyGiaoVien",
            center: "",
            visible: _vm.hien_thi_anh_ky,
          },
          on: {
            "update:visible": function ($event) {
              _vm.hien_thi_anh_ky = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "text-center", staticStyle: { height: "50vh" } },
            [
              _c(
                "p",
                { staticStyle: { "text-align": "left", color: "blue" } },
                [
                  _vm._v(
                    "Hình ảnh chữ ký tải lên dưới đây sẽ tương quan so với chữ ký ở học bạ khi\n        ký. Quý thầy cô vui lòng cân nhắc tải lại lên nếu chữ ký quá nhỏ hoặc chưa đạt tiêu chuẩn!"
                  ),
                ]
              ),
              _vm._v(" "),
              _c("iframe", {
                staticStyle: { width: "100%", height: "90%" },
                attrs: { src: _vm.linkAnh },
              }),
              _vm._v(" "),
              false
                ? _c("table", { staticClass: "border table table-bordered" }, [
                    _c("tr", [
                      _c("th", { attrs: { rowspan: "2" } }, [
                        _c("p", [_c("b", [_vm._v("Môn học / Hoạt động GD")])]),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { colspan: "3" } }, [
                        _c("p", [
                          _c("b", [
                            _vm._v("Điểm trung bình hoặc xếp loại môn"),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { rowspan: "2" } }, [
                        _c("p", [
                          _c("b", [
                            _vm._v(
                              "Điểm hoặc xếp loại KT sau thi lại (nếu có)"
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { rowspan: "2" } }, [
                        _c("p", [
                          _c("b", [
                            _vm._v(
                              "Giáo viên bộ môn ký xác nhận ĐTB môn hoặc hoặc xếp loại sửa chữa (nếu có),(Ký & ghi rõ\n              họ tên)"
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("th", [_c("p", [_vm._v("HK I")])]),
                      _vm._v(" "),
                      _c("th", [_c("p", [_vm._v("HK II")])]),
                      _vm._v(" "),
                      _c("th", [_c("p", [_vm._v("CN")])]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_c("p", [_vm._v("[Môn học]")])]),
                      _vm._v(" "),
                      _c("td", [_c("p", [_vm._v("[Điểm]")])]),
                      _vm._v(" "),
                      _c("td", [_c("p", [_vm._v("[Điểm]")])]),
                      _vm._v(" "),
                      _c("td", [_c("p", [_vm._v("[Điểm]")])]),
                      _vm._v(" "),
                      _c("td", [_c("p")]),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass: "text-left",
                          staticStyle: {
                            "align-self": "center",
                            display: "flex",
                          },
                        },
                        [
                          _c("img", {
                            staticStyle: { width: "150px", height: "40px" },
                            attrs: { src: _vm.linkAnh, alt: "Ảnh chữ ký" },
                          }),
                          _vm._v(" "),
                          _c("p", { staticStyle: { "align-self": "center" } }, [
                            _vm._v(_vm._s(_vm.infoGiaoVien.tenGV)),
                          ]),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            title: "Xem trước chữ ký",
            top: "5vh",
            width: "30%",
            "custom-class": "thongTinKyTheoMonHoc",
            center: "",
            visible: _vm.showChuKy,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showChuKy = $event
            },
          },
        },
        [
          _c("div", { staticClass: "text-center" }, [
            _c("p", [_c("b", [_vm._v("HỌC BẠ ĐIÊN TỬ")])]),
            _vm._v(" "),
            _c("p", [_c("b", [_vm._v("XEM TRƯỚC VỊ TRÍ DẤU - CHỮ KÝ")])]),
            _vm._v(" "),
            _c("div", { staticClass: "pt-3" }, [
              _c("i", [
                _vm._v(
                  "Địa danh ký, ngày........., tháng.........,năm.........."
                ),
              ]),
              _vm._v(" "),
              _c("p", [_c("b", [_vm._v("HIỆU TRƯỞNG HOẶC PHÓ HIỆU TRƯỞNG")])]),
              _vm._v(" "),
              _c("i", [_vm._v(" (Ký, ghi rõ họ tên và đóng dấu)")]),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "165px",
                    height: "165px",
                    margin: "0 auto",
                    position: "relative",
                  },
                },
                [
                  _c("img", {
                    staticStyle: {
                      position: "absolute",
                      left: "94px",
                      bottom: "18px",
                      height: "97.5px",
                      width: "210px",
                    },
                    attrs: { src: _vm.infoGiaoVien.linkChuKy, alt: "" },
                  }),
                  _vm._v(" "),
                  _c("img", {
                    staticStyle: { width: "100%", height: "100%" },
                    attrs: { src: _vm.getLinkDauNhaTruong(), alt: "" },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("p", [_c("b", [_vm._v(_vm._s(_vm.infoGiaoVien.tenGV))])]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "text-left pt-2" }, [
            _c("p", [
              _c("i", [
                _c("b", [_vm._v("Ghi chú:")]),
                _vm._v(
                  " Để điều chỉnh vị trí chữ ký, Quý Thầy-Cô quay trở lại upload chữ ký, tải lại chữ ký di\n        chuyển ảnh\n        chữ ký kéo sang bên trái khung hình."
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "uploadSignature",
          attrs: {
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            title: "Tải lên chữ ký",
            "custom-class": "thongTinKyTheoMonHoc",
            center: "",
            "before-close": _vm.closeDialogUpload,
            visible: _vm.dialogUploadSignatureVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogUploadSignatureVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { attrs: { id: "createSign" } },
            [
              _vm.dialogUploadSignatureVisible
                ? _c("crop-image", {
                    attrs: {
                      "style-box": _vm.styleBox,
                      step: _vm.step,
                      option: _vm.optionCrope,
                      showCrop: _vm.dialogUploadSignatureVisible,
                    },
                    on: {
                      goStep: _vm.goStep,
                      uploadLuon: _vm.uploadLuon,
                      getBase64: _vm.getBase64,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.dialogUploadSignatureVisible = false
                      _vm.step = 1
                    },
                  },
                },
                [_vm._v("Hủy")]
              ),
              _vm._v(" "),
              _vm.step == 3
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.uploadSignature },
                    },
                    [_vm._v("Xác nhận")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            "custom-class": "thongTinKyTheoMonHoc",
            center: "",
            title: "Tài liệu hướng dẫn",
            visible: _vm.show_huong_dan,
          },
          on: {
            "update:visible": function ($event) {
              _vm.show_huong_dan = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c("ol", { staticClass: "taiLieu" }, [
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://google.com",
                          target: "_blank",
                          title: "Tài liệu hướng dẫn",
                        },
                      },
                      [_vm._v("Tài liệu hướng\n            dẫn")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://youtube.com",
                          target: "_blank",
                          title: "Video hướng dẫn",
                        },
                      },
                      [_vm._v("Video hướng dẫn")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://facebook.com",
                          target: "_blank",
                          title: "Ảnh chữ ký mẫu",
                        },
                      },
                      [
                        _c("img", {
                          attrs: { src: "images/huong_dan_upload_anh.png" },
                        }),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.show_huong_dan = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            width: "50%",
            top: "5vh",
            "custom-class": "thongTinKyTheoMonHoc",
            center: "",
            title: "Hướng dẫn",
            visible: _vm.show_huong_dan_upload,
          },
          on: {
            "update:visible": function ($event) {
              _vm.show_huong_dan_upload = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c("h5", [_vm._v("Ảnh mẫu:")]),
                _vm._v(" "),
                _c("img", {
                  attrs: {
                    src: "/images/huongdan.png",
                    width: "100%",
                    height: "auto",
                    alt: "Ảnh hướng dẫn",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 18 } }, [
                _c("h5", [_vm._v("Lưu ý:")]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _c("p", [_c("b", [_vm._v("Dùng bút mực xanh nét to.")])]),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [
                      _c("b", [_vm._v("Ký trên giấy trắng không có dòng kẻ.")]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [_c("b", [_vm._v("Chụp ảnh chữ ký rõ nét.")])]),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [
                      _c("b", [
                        _vm._v(
                          "Khi tải ảnh chữ ký thực hiện xóa nền trước khi xác nhận."
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 24 } }, [
                _vm.show_huong_dan_upload
                  ? _c("video", { attrs: { width: "100%", controls: "" } }, [
                      _c("source", {
                        attrs: {
                          src: "https://cdnhocbadientu.csdl.edu.vn/media/huong_dan_upload_chu_ky.mp4",
                          type: "video/mp4",
                        },
                      }),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "success", size: "mini" },
                  on: { click: _vm.uploadChuKy },
                },
                [_vm._v("Đã hiểu")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "updateInfoTruong",
          attrs: {
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            title: "Đổi mật khẩu",
            width: "50%",
            visible: _vm.show_update_pass,
          },
          on: {
            "update:visible": function ($event) {
              _vm.show_update_pass = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "matKhau",
              attrs: { model: _vm.matKhau, rules: _vm.rulesChangePassword },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "moi" } },
                        [
                          _c("label", [_vm._v("Mật khẩu mới")]),
                          _vm._v(" "),
                          _c("InputPassword", {
                            ref: "moi",
                            attrs: { hideClearable: true, placeholder: "Nhập" },
                            on: {
                              input: function ($event) {
                                _vm.confirm_matKhau = true
                              },
                            },
                            model: {
                              value: _vm.matKhau.moi,
                              callback: function ($$v) {
                                _vm.$set(_vm.matKhau, "moi", $$v)
                              },
                              expression: "matKhau.moi",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "nhapLai" } },
                        [
                          _c("label", [_vm._v("Xác nhận mật khẩu mới")]),
                          _vm._v(" "),
                          _c("InputPassword", {
                            ref: "nhapLai",
                            attrs: { hideClearable: true, placeholder: "Nhập" },
                            on: {
                              input: function ($event) {
                                _vm.confirm_matKhau = true
                              },
                            },
                            model: {
                              value: _vm.matKhau.nhapLai,
                              callback: function ($$v) {
                                _vm.$set(_vm.matKhau, "nhapLai", $$v)
                              },
                              expression: "matKhau.nhapLai",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer text-center",
              staticStyle: { "text-align": "center !important" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.show_update_pass = false
                    },
                  },
                },
                [_vm._v("Hủy")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    disabled: !_vm.confirm_matKhau,
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.xacNhanThayDoiMatKhau("matKhau")
                    },
                  },
                },
                [_vm._v("Lưu")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }