var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "page__content",
    },
    [
      _c(
        "el-alert",
        {
          staticClass: "mb-2",
          attrs: {
            title: "Hướng dẫn",
            closable: false,
            "show-icon": "",
            type: "info",
          },
        },
        [
          _c("div", [
            _vm._v("\n            Bước 1: Chọn lớp muốn thêm học bạ"),
            _c("br"),
            _vm._v(
              "\n            Bước 2: Chọn Hình thức thêm, mặc định hệ thống hiển thị là “Upload file”. Nếu muốn nhập tay thì lựa chọn hình thức là “Nhập thông tin”"
            ),
            _c("br"),
            _vm._v(
              "\n            Bước 3: Nếu hình thức là upload file thì chọn File upload, người dùng scan kết quả học tập của năm học đó rồi upload file lên hệ thống, Trang bìa không phải scan. Nếu hình thức là Nhập thông tin thì điền các thông tin của học bạ."
            ),
            _c("br"),
            _vm._v("\n            Bước 4: Nhấn tạo học bạ"),
            _c("br"),
            _vm._v(
              "\n            Bước 5: Lặp lại từ bước 1 nếu muốn tạo tiếp học bạ hoặc nhấn button Đóng để kết thúc việc thêm mới.\n        "
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.listLopThieu.length
        ? _c(
            "el-alert",
            {
              attrs: {
                title: "Thiếu học bạ",
                type: "warning",
                closable: false,
                "show-icon": "",
              },
            },
            [
              _c(
                "ul",
                _vm._l(_vm.listLopThieu, function (item) {
                  return _c("li", { key: item.maLop }, [
                    _c("div", [
                      _vm._v(
                        " Năm học: " +
                          _vm._s(item.namHoc) +
                          ". Lớp : " +
                          _vm._s(item.tenLop)
                      ),
                    ]),
                  ])
                }),
                0
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("h5", [_vm._v("A. Thông tin học sinh")]),
          _vm._v(" "),
          _c("el-form", [
            _c(
              "div",
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "d-inline-block",
                    staticStyle: { width: "220px" },
                    attrs: { prop: "maHS" },
                  },
                  [
                    _c("div", [_vm._v("Mã học sinh:")]),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: {
                        disabled: _vm.checkDisable(),
                        placeholder: "Nhập",
                      },
                      model: {
                        value: (_vm.formHocBa.maHS = _vm.getValue("maHS")),
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formHocBa,
                            "maHS = getValue('maHS')",
                            $$v
                          )
                        },
                        expression: "formHocBa.maHS = getValue('maHS')",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticClass: "d-inline-block",
                    staticStyle: { width: "220px" },
                    attrs: { prop: "hoten" },
                  },
                  [
                    _c("div", [_vm._v("Họ tên học sinh:")]),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: {
                        disabled: _vm.checkDisable(),
                        placeholder: "Nhập",
                      },
                      model: {
                        value: (_vm.formHocBa.hoten = _vm.getValue("tenHS")),
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formHocBa,
                            "hoten = getValue('tenHS')",
                            $$v
                          )
                        },
                        expression: "formHocBa.hoten = getValue('tenHS')",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticClass: "d-inline-block",
                    staticStyle: { width: "120px" },
                  },
                  [
                    _c("div", [_vm._v("Giới tính:")]),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        attrs: {
                          disabled: _vm.checkDisable(),
                          placeholder: "Giới tính",
                        },
                        model: {
                          value: (_vm.formHocBa.gioiTinh =
                            _vm.getValue("gioiTinh")),
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formHocBa,
                              "gioiTinh = getValue('gioiTinh')",
                              $$v
                            )
                          },
                          expression:
                            "formHocBa.gioiTinh = getValue('gioiTinh')",
                        },
                      },
                      _vm._l(_vm.gioiTinh, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "d-inline-block", attrs: { prop: "year" } },
                  [
                    _c("div", [_vm._v("Ngày sinh:")]),
                    _vm._v(" "),
                    _c("el-date-picker", {
                      staticStyle: { width: "150px" },
                      attrs: {
                        disabled: _vm.checkDisable(),
                        format: "dd/MM/yyyy",
                        type: "date",
                        placeholder: "Ngày sinh",
                        "value-format": "dd/MM/yyyy",
                      },
                      model: {
                        value: (_vm.formHocBa.ngaySinh =
                          _vm.getValue("ngaySinh")),
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formHocBa,
                            "ngaySinh = getValue('ngaySinh')",
                            $$v
                          )
                        },
                        expression: "formHocBa.ngaySinh = getValue('ngaySinh')",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "el-form-item",
                  { staticClass: "d-inline-block", attrs: { prop: "namHoc" } },
                  [
                    _c("div", [_vm._v("Năm học:")]),
                    _vm._v(" "),
                    _c("el-date-picker", {
                      staticStyle: { width: "150px" },
                      attrs: {
                        disabled: "",
                        prop: "namHoc",
                        type: "year",
                        placeholder: "Năm học",
                        "value-format": "yyyy",
                      },
                      model: {
                        value: _vm.formHocBa.namHoc,
                        callback: function ($$v) {
                          _vm.$set(_vm.formHocBa, "namHoc", $$v)
                        },
                        expression: "formHocBa.namHoc",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticClass: "d-inline-block",
                    staticStyle: { width: "130px" },
                    attrs: { prop: "maLop" },
                  },
                  [
                    _c("div", [_vm._v("Lớp:")]),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "Chọn lớp" },
                        on: { change: _vm.lopChange },
                        model: {
                          value: _vm.formHocBa.maLop,
                          callback: function ($$v) {
                            _vm.$set(_vm.formHocBa, "maLop", $$v)
                          },
                          expression: "formHocBa.maLop",
                        },
                      },
                      _vm._l(_vm.listLopThieu, function (item) {
                        return _c("el-option", {
                          key: item.maLop,
                          attrs: { label: item.tenLop, value: item.maLop },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "h4",
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.showManual,
                callback: function ($$v) {
                  _vm.showManual = $$v
                },
                expression: "showManual",
              },
            },
            [
              _c("el-radio", { attrs: { label: 1 } }, [_vm._v("Upload file")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 2 } }, [
                _vm._v("Nhập thông tin"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "formHocBa",
          attrs: {
            rules: _vm.rulesHocBa,
            model: _vm.formHocBa,
            "hide-required-asterisk": true,
          },
        },
        [
          _vm.showManual == 1
            ? [
                _c("h5", [_vm._v("B. Tải lên file học bạ")]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("UploadImageBase64", {
                      staticClass: "hocba__upload mb-1",
                      staticStyle: { width: "100px", cursor: "pointer" },
                      attrs: {
                        "default-preview": _vm.urlFile,
                        "image-class": "v1-image",
                        "input-class": "v1-image",
                        "max-size": _vm.customImageMaxSize,
                        placeholder: "Tải lên học bạ",
                      },
                      on: {
                        "size-exceeded": _vm.onSizeExceeded,
                        load: _vm.onLoad,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "showButton",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "hocba__uploadfile" },
                                  [
                                    _c("i", { staticClass: "el-icon-upload" }),
                                    _vm._v(" Tải lên file học bạ"),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3113980298
                      ),
                    }),
                  ],
                  1
                ),
              ]
            : [
                _c("div", [
                  _c("h5", [_vm._v("B. Các môn học và hoạt động giáo dục")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _vm._l(_vm.monHoc, function (item, index) {
                        return _c(
                          "div",
                          {
                            staticClass:
                              "col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3",
                          },
                          [
                            _c("div", [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(index + 1) + ". " + _vm._s(item.monHoc)
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "d-flex" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "mr-1",
                                    staticStyle: { width: "160px" },
                                  },
                                  [
                                    _c(
                                      "label",
                                      [
                                        _vm._v(
                                          "Mức đạt:\n                                    "
                                        ),
                                        _c(
                                          "el-select",
                                          {
                                            attrs: { placeholder: "Mức đạt" },
                                            model: {
                                              value: item.mucDat,
                                              callback: function ($$v) {
                                                _vm.$set(item, "mucDat", $$v)
                                              },
                                              expression: "item.mucDat",
                                            },
                                          },
                                          _vm._l(
                                            _vm.mucDatList,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "d-inline-block mr-1",
                                    staticStyle: { width: "160px" },
                                  },
                                  [
                                    _c(
                                      "label",
                                      [
                                        _vm._v(
                                          "Điểm kiểm tra định kỳ:\n                                    "
                                        ),
                                        _c("el-input", {
                                          attrs: { placeholder: "Điểm KTĐK" },
                                          model: {
                                            value: item.diemKTDK,
                                            callback: function ($$v) {
                                              _vm.$set(item, "diemKTDK", $$v)
                                            },
                                            expression: "item.diemKTDK",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-12" }, [
                        _c(
                          "label",
                          [
                            _vm._v(
                              "Nhận xét các môn học và hoạt động giáo dục:\n                            "
                            ),
                            _c("el-input", {
                              attrs: { rows: "3", type: "textarea" },
                              model: {
                                value: _vm.formHocBa.danhGiaMonHoc,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formHocBa, "danhGiaMonHoc", $$v)
                                },
                                expression: "formHocBa.danhGiaMonHoc",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mt-3" }, [
                  _c("h5", [_vm._v("C. Những phẩm chất chủ yếu")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _vm._l(_vm.phamChat, function (item) {
                        return _c(
                          "div",
                          {
                            staticClass:
                              "col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-2",
                          },
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "strong",
                                  {
                                    staticStyle: {
                                      width: "110px",
                                      display: "inline-block",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.ten) + " : ")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      size: "small",
                                      placeholder: "Mức đạt",
                                    },
                                    model: {
                                      value: item.mucDat,
                                      callback: function ($$v) {
                                        _vm.$set(item, "mucDat", $$v)
                                      },
                                      expression: "item.mucDat",
                                    },
                                  },
                                  _vm._l(_vm.mucDatList, function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-12" }, [
                        _c(
                          "label",
                          [
                            _vm._v(
                              "Nhận xét các phẩm chất:\n                            "
                            ),
                            _c("el-input", {
                              attrs: { rows: "3", type: "textarea" },
                              model: {
                                value: _vm.formHocBa.danhGiaPhamChat,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formHocBa,
                                    "danhGiaPhamChat",
                                    $$v
                                  )
                                },
                                expression: "formHocBa.danhGiaPhamChat",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mt-3" }, [
                  _c("h5", [_vm._v("D. Năng lực cốt lõi")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("h6", [_vm._v(" 1.Những năng lực chung ")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "row" },
                        [
                          _vm._l(_vm.nangLucChung, function (item) {
                            return _c(
                              "div",
                              { staticClass: "col-sm-12 mb-3" },
                              [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "strong",
                                      {
                                        staticStyle: {
                                          width: "250px",
                                          display: "inline-block",
                                        },
                                      },
                                      [_vm._v(_vm._s(item.ten) + " : ")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          size: "small",
                                          placeholder: "Mức đạt",
                                        },
                                        model: {
                                          value: item.mucDat,
                                          callback: function ($$v) {
                                            _vm.$set(item, "mucDat", $$v)
                                          },
                                          expression: "item.mucDat",
                                        },
                                      },
                                      _vm._l(_vm.mucDatList, function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-12 mb-4" }, [
                            _c(
                              "label",
                              [
                                _vm._v(
                                  "Nhận xét về năng lực chung:\n                                    "
                                ),
                                _c("el-input", {
                                  attrs: { rows: "3", type: "textarea" },
                                  model: {
                                    value: _vm.formHocBa.danhGiaNangLucChung,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formHocBa,
                                        "danhGiaNangLucChung",
                                        $$v
                                      )
                                    },
                                    expression: "formHocBa.danhGiaNangLucChung",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        2
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("h6", [_vm._v(" 2.Những năng lực đặc thù ")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "row" },
                        [
                          _vm._l(_vm.nangLucDacThu, function (item) {
                            return _c(
                              "div",
                              { staticClass: "col-sm-12 mb-3" },
                              [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "strong",
                                      {
                                        staticStyle: {
                                          width: "110px",
                                          display: "inline-block",
                                        },
                                      },
                                      [_vm._v(_vm._s(item.ten) + " : ")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          size: "small",
                                          placeholder: "Mức đạt",
                                        },
                                        model: {
                                          value: item.mucDat,
                                          callback: function ($$v) {
                                            _vm.$set(item, "mucDat", $$v)
                                          },
                                          expression: "item.mucDat",
                                        },
                                      },
                                      _vm._l(_vm.mucDatList, function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-12" }, [
                            _c(
                              "label",
                              [
                                _vm._v(
                                  "Nhận xét về năng lực đặc thù:\n                                    "
                                ),
                                _c("el-input", {
                                  attrs: { rows: "3", type: "textarea" },
                                  model: {
                                    value: _vm.formHocBa.danhGiaNangLucDacThu,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formHocBa,
                                        "danhGiaNangLucDacThu",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formHocBa.danhGiaNangLucDacThu",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mt-3" }, [
                  _c("h5", [_vm._v("E. Đánh giá chung")]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "label",
                      [
                        _vm._v(
                          "Đánh giá kết quả giảo dục:\n                        "
                        ),
                        _c("el-input", {
                          attrs: { rows: "3", type: "textarea" },
                          model: {
                            value: _vm.formHocBa.danhGiaKqGd,
                            callback: function ($$v) {
                              _vm.$set(_vm.formHocBa, "danhGiaKqGd", $$v)
                            },
                            expression: "formHocBa.danhGiaKqGd",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "label",
                      [
                        _vm._v("Khen Thưởng:\n                        "),
                        _c("el-input", {
                          attrs: { rows: "3", type: "textarea" },
                          model: {
                            value: _vm.formHocBa.khenThuong,
                            callback: function ($$v) {
                              _vm.$set(_vm.formHocBa, "khenThuong", $$v)
                            },
                            expression: "formHocBa.khenThuong",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "label",
                      [
                        _vm._v(
                          "Hoàn thành chương trình:\n                        "
                        ),
                        _c("el-input", {
                          attrs: { rows: "3", type: "textarea" },
                          model: {
                            value: _vm.formHocBa.hoanThanhChuongTrinh,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formHocBa,
                                "hoanThanhChuongTrinh",
                                $$v
                              )
                            },
                            expression: "formHocBa.hoanThanhChuongTrinh",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ],
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "button mb-5 mt-4" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.taoHocBa("formHocBa")
                    },
                  },
                },
                [_vm._v("Tạo học bạ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "info" }, on: { click: _vm.closeClick } },
                [_vm._v("Đóng")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("dialog-alert", {
        attrs: {
          title: "Thông báo",
          message: _vm.messageAlert,
          "show-dialog": _vm.showAlert,
        },
        on: {
          closeDialog: function ($event) {
            _vm.showAlert = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }