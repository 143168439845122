<template>
  <div id="detailHocBa" class="page page-file file " v-loading="loading.fullScreen" element-loading-text="Loading..."
       element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 0.8)">
    <div class="page__title">
      <el-row :gutter="20">
        <el-col v-if="showThongBao" :span="24" class="text-center">
          <i><b style="color: #DA1D1D">Viettel trân trọng thông báo: <br> Trong thời gian nghỉ Tết từ ngày 20/01/2023
            (29 Tết) đến hết
            26/01/2023 (mùng 5 Tết), Viettel duy trì phục vụ khách hàng qua tổng đài 18008000. <br> Viettel xin
            kính chúc Quý khách một năm mới An khang - Thịnh vượng. Trân trọng cảm ơn Quý khách đã đồng hành
            với Viettel!</b></i>
          <hr>
        </el-col>
        <el-col :span="24">
          <h1 class="title">Tổng quan</h1>
        </el-col>
      </el-row>

    </div>
    <div class="box-upload-file">
      <el-row :gutter="24">
        <el-col :span="4">
          <label>Năm học</label>
          <eselect style="width: 100%" collapseTags v-model="searchForm.year" :placeholder="'Chọn'"
                   filterable :data="list_nam_hoc" :fields="['name','value']"/>
        </el-col>
        <el-col :span="4">
          <div style="color: transparent">Tìm kiếm</div>
          <el-button class="m-0" type="primary" @click="getRole()">Tìm kiếm</el-button>
        </el-col>
      </el-row>
      <el-form v-if="false" ref="searchForm" label-position="top" :model="searchForm"
               :hide-required-asterisk="true">
        <el-form-item label="Năm học" prop="year">
          <eselect style="width:160px" collapseTags v-model="searchForm.year" placeholder="Chọn"
                   filterable :data="list_nam_hoc" :fields="['name','value']"/>
        </el-form-item>
        <el-form-item style="width: 370px" prop="pgd"
                      v-if="user.role==6 || user.role==7">
          <eselect
              style="width: 100%"
              v-if="user.role==6"
              disabled
              :data="listPGD"
              filterable
              @change="getListTruong"
              custom-style="width: 370px"
              v-model="searchForm.pgd"
              placeholder="Chọn phòng giáo dục"
              :fields="['tenDonVi','maDonVi']"
          ></eselect>
          <eselect
              style="width: 100%"
              v-else
              :data="listPGD"
              filterable
              @change="getListTruong"
              custom-style="width: 370px"
              v-model="searchForm.pgd"
              placeholder="Chọn phòng giáo dục"
              :fields="['tenDonVi','maDonVi']"
          ></eselect>
        </el-form-item>
        <el-form-item v-if="user.role==6 || user.role==7">
          <eselect
              :data="listSchool"
              style="width: 100%"
              filterable
              custom-style="width: 350px"
              v-model="searchForm.maTruong"
              placeholder="Chọn trường"
              :fields="['tenTruongHoc','maTruongHoc']"
          ></eselect>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getRole()">Tìm kiếm</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="" v-if="user && this.infoRole && this.infoRole.isGVBM && !checkCapHoc('1',user.capHoc)"
         class="card-bieu-do">
      <el-row :gutter="24">
        <el-col :span="24" class="header_page">
          <h5>
            <font-awesome-icon :icon="['fas', 'users']" class="icon alt"/>
            GIÁO VIÊN BỘ MÔN
          </h5>
          <el-divider></el-divider>
        </el-col>
        <el-col :sm="12" :md="12" :lg="12">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">BIỂU ĐỒ KÝ HỌC BẠ LỚP HỌC GIẢNG DẠY</span>
                <span class="dts-right">
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="layDuLieuTrangThaiBieuDoTheoLop"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
              </div>
              <div class="dts-card-body" v-loading="loading.giaoVienBoMon.trangThaiHocBaTheoLop"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-show="!loading.giaoVienBoMon.trangThaiHocBaTheoLop">
                  <BieuDoCotSW :dulieu="duLieuBieuDoTrangThaiHocBaTheoLop"></BieuDoCotSW>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <div style="" v-if="this.infoRole && this.infoRole.isGVCN" class="card-bieu-do">
      <el-row :gutter="24">
        <el-col :span="24" class="header_page">
          <h5>
            <font-awesome-icon :icon="['fas', 'user-edit']" class="icon alt"/>
            GIÁO VIÊN CHỦ NHIỆM
          </h5>
          <el-divider></el-divider>
        </el-col>

        <el-col :sm="12" :md="8" :lg="8">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">Biểu đồ trạng thái học sinh</span>
                <span class="dts-right">
                                    <el-tooltip content="Chi tiết" placement="top">
                                        <a href="#" @click.prevent="showChiTietThongKeTrangThaiHocSinhGvcn"><i
                                            class="el-icon-info"></i></a>
                                    </el-tooltip>
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="layDuLieuThongKeTrangThaiHocSinhGVCN"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
              </div>
              <div class="dts-card-body" v-loading="loading.giaoVienChuNhiem.thongKeTrangThaiHocSinhGvcn"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-show="!loading.giaoVienChuNhiem.thongKeTrangThaiHocSinhGvcn">
                  <BieuDoCotDistributed :label="trangThaiHocSinh"
                                        :dulieu="duLieuBieuDoThongKeTrangThaiHocSinhGvcn"></BieuDoCotDistributed>
                </div>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :sm="12" :md="8" :lg="8">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">BIỂU ĐỒ HỌC BẠ - HỌC SINH LỚP CHỦ NHIỆM</span>
                <span class="dts-right">
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="layDuLieuBieuDoHocBaHocSinhGvcn"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
              </div>
              <div class="dts-card-body" v-loading="loading.giaoVienChuNhiem.soLuongHocBaHocSinh"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-show="!loading.giaoVienChuNhiem.soLuongHocBaHocSinh">
                  <BieuDoCotKhongXepChong :dulieu="duLieuBieuDoHocBaGvcn"></BieuDoCotKhongXepChong>
                </div>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :sm="12" :md="8" :lg="8" v-if="anBieuDo">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">Biểu đồ học sinh lên lớp - thi lại</span>
                <span class="dts-right">
                                    <el-tooltip content="Chi tiết" placement="top">
                                        <a href="#" @click.prevent="showChiTietThongKeHocSinhThiLaiGvcn"><i
                                            class="el-icon-info"></i></a>
                                    </el-tooltip>
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="layDuLieuThongKeHocSinhThiLaiGvcn"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
              </div>
              <div class="dts-card-body" v-loading="loading.giaoVienChuNhiem.thongKeHocSinhThiLai"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-show="!loading.giaoVienChuNhiem.thongKeHocSinhThiLai">
                  <BieuDoCotDistributed :label="hocSinhThiLaiHieuTruong"
                                        :dulieu="duLieuBieuDoThongKeHocSinhThiLaiGvcn"></BieuDoCotDistributed>
                </div>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :sm="12" :md="8" :lg="8">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">BIỂU ĐỒ KÝ HỌC BẠ LỚP CHỦ NHIỆM</span>
                <span class="dts-right">
                                        <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                            <a href="#"
                                               @click.prevent="layDuLieuTrangThaiBieuDoTrangThaiHocBaTheoGvcn()"><i
                                                class="el-icon-refresh"></i></a>
                                        </el-tooltip>
                                    </span>
              </div>
              <div class="dts-card-body" v-loading="loading.giaoVienChuNhiem.trangThaiHocBa"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-show="!loading.giaoVienChuNhiem.trangThaiHocBa">
                  <BieuDoHinhTron :label="daKyChuaKy"
                                  :dulieu="duLieuBieuDoTrangThaiHocBaTheoTrangThaiGiaoVien"></BieuDoHinhTron>
                </div>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :sm="12" :md="8" :lg="8" v-if="this.user && checkCapHoc('1',user.capHoc)&&anBieuDo">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">BIỂU ĐỒ THỐNG KÊ KẾT QUẢ GIÁO DỤC</span>
                <span class="dts-right">
<!--                                    <el-tooltip content="Chi tiết" placement="top">-->
                  <!--                                        <a href="#" @click.prevent="showChiTietThongKeTrangThaiHocSinhHieuTruong"><i-->
                  <!--                                            class="el-icon-info"></i></a>-->
                  <!--                                    </el-tooltip>-->
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="layDuLieuThongKeDanhGiaKetQuaGiaoDuc"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
              </div>
              <div class="dts-card-body" v-loading="loading.giaoVienChuNhiem.thongKeKQGD"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-show="!loading.giaoVienChuNhiem.thongKeKQGD">
                  <BieuDoHinhTron :label="danhGia" :dulieu="duLieuBieuDoThongKeKQGD"></BieuDoHinhTron>
                </div>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :sm="24" :md="16" :lg="16" v-if="this.user && user.capHoc > 1&&anBieuDo">
          <!--                  ẩn ngày 13/06/2022-->
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">BIỂU ĐỒ THỐNG KÊ HẠNH KIỂM - HỌC LỰC HỌC SINH</span>
                <span class="dts-right">
                                    <el-select @change="onHocKyChange" v-model="hocKy" size="mini"
                                               placeholder="Chọn học kì">
                                        <el-option :value="1" label="Học kỳ I"></el-option>
                                        <el-option :value="2" label="Học kỳ II"></el-option>
                                        <el-option :value="3" label="Cả năm"></el-option>
                                    </el-select>
                                </span>
              </div>
              <div class="dts-card-body" v-loading="loading.hieuTruong.thongKeHanhKiem"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">

                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="d-flex">
                      <strong>Thống kê hạnh kiểm</strong>
                      <span class="dts-right">
<!--                                    <el-tooltip content="Chi tiết" placement="top">-->
                        <!--                                        <a href="#" @click.prevent="showChiTietThongKeTrangThaiHocSinhHieuTruong"><i-->
                        <!--                                                class="el-icon-info"></i></a>-->
                        <!--                                    </el-tooltip>-->
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="layDuLieuThongKeHanhKiem"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
                    </div>

                    <div v-show="!loading.hieuTruong.thongKeHanhKiem">
                      <BieuDoHinhTron :label="hanhKiem"
                                      :dulieu="duLieuBieuDoThongKeHanhKiem"></BieuDoHinhTron>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="d-flex">
                      <strong class="">Thống kê học lực</strong>
                      <span class="dts-right">
<!--                                    <el-tooltip content="Chi tiết" placement="top">-->
                        <!--                                        <a href="#" @click.prevent="showChiTietThongKeTrangThaiHocSinhHieuTruong"><i-->
                        <!--                                                class="el-icon-info"></i></a>-->
                        <!--                                    </el-tooltip>-->
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="layDuLieuThongKeHocLuc"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
                    </div>
                    <div v-show="!loading.hieuTruong.thongKeHocLuc">
                      <BieuDoHinhTron :label="hocLuc"
                                      :dulieu="duLieuBieuDoThongKeHocLuc"></BieuDoHinhTron>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </el-col>

        <el-col :sm="12" :md="12" :lg="12" v-if="this.user && user.capHoc > 1">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">Biểu đồ trạng thái theo môn học</span>
                <span class="dts-right">
<!--                                    <el-tooltip content="Chi tiết" placement="top">-->
                  <!--                                        <a href="#" @click.prevent="showHienThiChiTiet"><i class="el-icon-info"></i></a>-->
                  <!--                                    </el-tooltip>-->
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="layDuLieuBieuDoTrangThaiHocBaTheoMonHocGvcn"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
              </div>
              <div class="dts-card-body" v-loading="loading.giaoVienChuNhiem.trangThaiHocBaTheoMonHoc"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-show="!loading.giaoVienChuNhiem.trangThaiHocBaTheoMonHoc">
                  <BieuDoTrangThaiTheoMon :type="1" :dulieu="duLieuBieuDoTrangThaiHocBaTheoMonHoc">
                  </BieuDoTrangThaiTheoMon>
                </div>
              </div>
            </div>
          </div>
        </el-col>

      </el-row>
    </div>

    <div style="" v-if="this.infoRole && this.infoRole.isHieuTruong" class="card-bieu-do">
      <el-row :gutter="24">
        <el-col :span="24" class="header_page">
          <h5>
            <font-awesome-icon :icon="['fas', 'user-tie']" class="icon alt"/>
            HIỆU TRƯỞNG
          </h5>
          <el-divider></el-divider>
        </el-col>

        <el-col :sm="12" :md="8" :lg="8">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">Biểu đồ trạng thái học sinh toàn trường </span>
                <span class="dts-right">
                                    <el-tooltip content="Chi tiết" placement="top">
                                        <a href="#" @click.prevent="showChiTietThongKeTrangThaiHocSinhHieuTruong"><i
                                            class="el-icon-info"></i></a>
                                    </el-tooltip>
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="layDuLieuThongKeTrangThaiHocSinhHieuTruong"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
              </div>
              <div class="dts-card-body" v-loading="loading.hieuTruong.thongKeTrangThaiHocSinh"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-show="!loading.hieuTruong.thongKeTrangThaiHocSinh">
                  <BieuDoCotDistributed :label="trangThaiHocSinh"
                                        :dulieu="duLieuBieuDoThongKeTrangThaiHocSinhHieuTruong"></BieuDoCotDistributed>
                </div>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :sm="12" :md="8" :lg="8">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">BIỂU ĐỒ HỌC BẠ - HỌC SINH TOÀN TRƯỜNG</span>
                <span class="dts-right">
                                    <el-tooltip content="Chi tiết" placement="top">
                                        <a href="#" @click.prevent="xemChiTietHocBaTheoGiaoVienCN"><i
                                            class="el-icon-info"></i></a>
                                    </el-tooltip>
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="layDuLieuBieuDoTrangThaiHocBaTheoMonHoc"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
              </div>
              <div class="dts-card-body" v-loading="loading.hieuTruong.hocBaHieuTruong"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-show="!loading.hieuTruong.hocBaHieuTruong">
                  <BieuDoCotDistributed :dulieu="duLieuBieuDoHocBaHocSinhHieuTruong">
                  </BieuDoCotDistributed>
                </div>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :sm="12" :md="8" :lg="8" v-if="anBieuDo">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">Biểu đồ học sinh lên lớp - thi lại</span>
                <span class="dts-right">
                                    <el-tooltip content="Chi tiết" placement="top">
                                        <a href="#" @click.prevent="showChiTietThongKeHocSinhThiLaiHieuTruong"><i
                                            class="el-icon-info"></i></a>
                                    </el-tooltip>
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="layDuLieuThongKeHocSinhThiLaiHieuTruong"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
              </div>
              <div class="dts-card-body" v-loading="loading.hieuTruong.thongKeHocSinhThiLai"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-show="!loading.hieuTruong.thongKeHocSinhThiLai">
                  <BieuDoCotDistributed :label="hocSinhThiLaiHieuTruong"
                                        :dulieu="duLieuBieuDoThongKeHocSinhThiLaiHieuTruong"></BieuDoCotDistributed>
                </div>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :sm="12" :md="12" :lg="12">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">BIỂU ĐỒ GVCN KÝ HỌC BẠ</span>
                <span class="dts-right">
                                   <el-tooltip content="Chi tiết trạng thái" placement="top">
                                        <a href="#" @click.prevent="thongKeChiTietTrangThaiHocBaGvcn"><i
                                            class="el-icon-info"></i></a>
                                    </el-tooltip>

                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="layDuLieuTrangThaiBieuDoTheoLopGVCNToanTruong"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
              </div>
              <div class="dts-card-body"
                   v-loading="loading.hieuTruong.trangThaiHocBaTheoLopGVCNToanTruong"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-show="!loading.giaoVienBoMon.trangThaiHocBaTheoLopGVCNToanTruong">
                  <BieuDoCotSW
                      :dulieu="duLieuBieuDoTrangThaiHocBaTheoLopGVCNToanTruong"></BieuDoCotSW>
                </div>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :sm="12" :md="12" :lg="12">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">BIỂU ĐỒ HIỆU TRƯỞNG KÝ HỌC BẠ</span>
                <span class="dts-right">
                                    <el-tooltip content="Chi tiết" placement="top">
                                        <a href="#" @click.prevent="xemChiTietTongQuanHieuTruong"><i
                                            class="el-icon-info"></i></a>
                                    </el-tooltip>
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="layDuLieuBieuDoTrangThaiHocBaTongQuanHieuTruong"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
              </div>
              <div class="dts-card-body" v-loading="loading.hieuTruong.tongQuan"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-show="!loading.hieuTruong.tongQuan">
                  <BieuDoCotSW :label="daKyChuaKy"
                               :dulieu="duLieuBieuDoTrangThaiHocBaTongQuanHieuTruong"></BieuDoCotSW>
                </div>
              </div>
            </div>
          </div>
        </el-col>

        <el-col class="mt-3" :sm="12" :md="8" :lg="8" v-if="user && user.capHoc > 1">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">Biểu đồ trạng thái theo môn học</span>
                <span class="dts-right">
<!--                                    <el-tooltip content="Chi tiết" placement="top">-->
                  <!--                                        <a href="#" @click.prevent="showHienThiChiTiet"><i class="el-icon-info"></i></a>-->
                  <!--                                    </el-tooltip>-->
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="layDuLieuBieuDoTrangThaiHocBaTheoMonHocGvcn"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
              </div>
              <div class="dts-card-body" v-loading="loading.giaoVienChuNhiem.trangThaiHocBaTheoMonHoc"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-show="!loading.giaoVienChuNhiem.trangThaiHocBaTheoMonHoc">
                  <BieuDoTrangThaiTheoMon :type="1" :dulieu="duLieuBieuDoTrangThaiHocBaTheoMonHoc">
                  </BieuDoTrangThaiTheoMon>
                </div>
              </div>
            </div>
          </div>
        </el-col>

        <el-col class="mt-3" :sm="24" :md="16" :lg="16" v-if="user && user.capHoc > 1&&anBieuDo">
          <!--                  ẩn ngày 13/06/2022-->
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">BIỂU ĐỒ THỐNG KÊ HẠNH KIỂM - HỌC LỰC HỌC SINH</span>
                <span class="dts-right">
                                    <el-select @change="onHocKyChange" v-model="hocKy" size="mini"
                                               placeholder="Chọn học kì">
                                        <el-option :value="1" label="Học kỳ I"></el-option>
                                        <el-option :value="2" label="Học kỳ II"></el-option>
                                        <el-option :value="3" label="Cả năm"></el-option>
                                    </el-select>
                                </span>
              </div>
              <div class="dts-card-body" v-loading="loading.hieuTruong.thongKeHanhKiem"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">

                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="d-flex">
                      <strong>Thống kê hạnh kiểm</strong>
                      <span class="dts-right">
<!--                                    <el-tooltip content="Chi tiết" placement="top">-->
                        <!--                                        <a href="#" @click.prevent="showChiTietThongKeTrangThaiHocSinhHieuTruong"><i-->
                        <!--                                            class="el-icon-info"></i></a>-->
                        <!--                                    </el-tooltip>-->
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="layDuLieuThongKeHanhKiem"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
                    </div>

                    <div v-show="!loading.hieuTruong.thongKeHanhKiem">
                      <BieuDoHinhTron :label="hanhKiem"
                                      :dulieu="duLieuBieuDoThongKeHanhKiem"></BieuDoHinhTron>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="d-flex">
                      <strong class="">Thống kê học lực</strong>
                      <span class="dts-right">
<!--                                    <el-tooltip content="Chi tiết" placement="top">-->
                        <!--                                        <a href="#" @click.prevent="showChiTietThongKeTrangThaiHocSinhHieuTruong"><i-->
                        <!--                                            class="el-icon-info"></i></a>-->
                        <!--                                    </el-tooltip>-->
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="layDuLieuThongKeHocLuc"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
                    </div>
                    <div v-show="!loading.hieuTruong.thongKeHocLuc">
                      <BieuDoHinhTron :label="hocLuc"
                                      :dulieu="duLieuBieuDoThongKeHocLuc"></BieuDoHinhTron>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </el-col>

        <el-col :sm="12" :md="8" :lg="8" v-if="this.user && checkCapHoc('1',user.capHoc)&&anBieuDo">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">BIỂU ĐỒ THỐNG KÊ KẾT QUẢ GIÁO DỤC</span>
                <span class="dts-right">
<!--                                    <el-tooltip content="Chi tiết" placement="top">-->
                  <!--                                        <a href="#" @click.prevent="showChiTietThongKeTrangThaiHocSinhHieuTruong"><i-->
                  <!--                                            class="el-icon-info"></i></a>-->
                  <!--                                    </el-tooltip>-->
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="layDuLieuThongKeDanhGiaKetQuaGiaoDuc"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
              </div>
              <div class="dts-card-body" v-loading="loading.hieuTruong.thongKeKQGD"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-show="!loading.hieuTruong.thongKeKQGD">
                  <BieuDoHinhTron :label="danhGia" :dulieu="duLieuBieuDoThongKeKQGD"></BieuDoHinhTron>
                </div>
              </div>
            </div>
          </div>
        </el-col>

      </el-row>
    </div>

    <div style="" v-if="this.infoRole && this.infoRole.isCanBo" class="card-bieu-do">
      <el-row :gutter="24">
        <el-col :span="24" class="header_page">
          <h5>
            <font-awesome-icon :icon="['fas', 'user-cog']" class="icon alt"/>
            CÁN BỘ QUẢN LÝ
          </h5>
          <el-divider></el-divider>
        </el-col>

        <el-col :sm="12" :md="12" :lg="8">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">Biểu đồ trạng thái học sinh toàn trường </span>
                <span class="dts-right">
                                    <el-tooltip content="Chi tiết" placement="top">
                                        <a href="#" @click.prevent="showChiTietThongKeTrangThaiHocSinhHieuTruong"><i
                                            class="el-icon-info"></i></a>
                                    </el-tooltip>
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="layDuLieuThongKeTrangThaiHocSinhHieuTruong"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
              </div>
              <div class="dts-card-body" v-loading="loading.hieuTruong.thongKeTrangThaiHocSinh"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-show="!loading.hieuTruong.thongKeTrangThaiHocSinh">
                  <BieuDoCotDistributed :label="trangThaiHocSinh"
                                        :dulieu="duLieuBieuDoThongKeTrangThaiHocSinhHieuTruong"></BieuDoCotDistributed>
                </div>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :sm="12" :md="12" :lg="8">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">BIỂU ĐỒ HỌC BẠ - HỌC SINH TOÀN TRƯỜNG</span>
                <span class="dts-right">
                                    <el-tooltip content="Chi tiết" placement="top">
                                        <a href="#" @click.prevent="xemChiTietHocBaTheoGiaoVienCN"><i
                                            class="el-icon-info"></i></a>
                                    </el-tooltip>
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="layDuLieuBieuDoTrangThaiHocBaTheoMonHoc"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
              </div>
              <div class="dts-card-body" v-loading="loading.hieuTruong.hocBaHieuTruong"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-show="!loading.hieuTruong.hocBaHieuTruong">
                  <BieuDoCotDistributed :dulieu="duLieuBieuDoHocBaHocSinhHieuTruong">
                  </BieuDoCotDistributed>
                </div>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :sm="12" :md="12" :lg="8">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">Biểu đồ trạng thái upload chữ ký </span>
                <span class="dts-right">
<!--                                    <el-tooltip content="Chi tiết" placement="top">-->
                  <!--                                        <a href="#"><i-->
                  <!--                                            class="el-icon-info"></i></a>-->
                  <!--                                    </el-tooltip>-->
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="layDuLieuThongKeTrangThaiUploadChuKy"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
              </div>
              <div class="dts-card-body" v-loading="loading.hieuTruong.thongKeTrangThaiUploadChuKy"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-show="!loading.hieuTruong.thongKeTrangThaiUploadChuKy">
                  <BieuDoCotDistributed :label="trangThaiChuKy"
                                        :dulieu="duLieuBieuDoThongKeTrangThaiUploadChuKy"></BieuDoCotDistributed>
                </div>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :sm="12" :md="12" :lg="12">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">BIỂU ĐỒ GVCN KÝ HỌC BẠ</span>
                <span class="dts-right">
                                   <el-tooltip content="Chi tiết trạng thái" placement="top">
                                        <a href="#" @click.prevent="thongKeChiTietTrangThaiHocBaGvcn"><i
                                            class="el-icon-info"></i></a>
                                    </el-tooltip>

                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="layDuLieuTrangThaiBieuDoTheoLopGVCNToanTruong"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
              </div>
              <div class="dts-card-body"
                   v-loading="loading.hieuTruong.trangThaiHocBaTheoLopGVCNToanTruong"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-show="!loading.giaoVienBoMon.trangThaiHocBaTheoLopGVCNToanTruong">
                  <BieuDoCotSW
                      :dulieu="duLieuBieuDoTrangThaiHocBaTheoLopGVCNToanTruong"></BieuDoCotSW>
                </div>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :sm="12" :md="12" :lg="12" v-if="user && user.capHoc > 1">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">Biểu đồ trạng thái theo môn học</span>
                <span class="dts-right">
<!--                                    <el-tooltip content="Chi tiết" placement="top">-->
                  <!--                                        <a href="#" @click.prevent="showHienThiChiTiet"><i class="el-icon-info"></i></a>-->
                  <!--                                    </el-tooltip>-->
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="layDuLieuBieuDoTrangThaiHocBaTheoMonHocGvcn"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
              </div>
              <div class="dts-card-body" v-loading="loading.giaoVienChuNhiem.trangThaiHocBaTheoMonHoc"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-show="!loading.giaoVienChuNhiem.trangThaiHocBaTheoMonHoc">
                  <BieuDoTrangThaiTheoMon :type="1" :dulieu="duLieuBieuDoTrangThaiHocBaTheoMonHoc">
                  </BieuDoTrangThaiTheoMon>
                </div>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :sm="12" :md="12" :lg="12">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">BIỂU ĐỒ HIỆU TRƯỞNG KÝ HỌC BẠ</span>
                <span class="dts-right">
                                    <el-tooltip content="Chi tiết" placement="top">
                                        <a href="#" @click.prevent="xemChiTietTongQuanHieuTruong"><i
                                            class="el-icon-info"></i></a>
                                    </el-tooltip>
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="layDuLieuBieuDoTrangThaiHocBaTongQuanHieuTruong"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
              </div>
              <div class="dts-card-body" v-loading="loading.hieuTruong.tongQuan"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-show="!loading.hieuTruong.tongQuan">
                  <BieuDoCotSW :label="daKyChuaKy"
                               :dulieu="duLieuBieuDoTrangThaiHocBaTongQuanHieuTruong"></BieuDoCotSW>
                </div>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :sm="12" :md="12" :lg="12" v-if="anBieuDo">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">Biểu đồ học sinh lên lớp - thi lại</span>
                <span class="dts-right">
                                    <el-tooltip content="Chi tiết" placement="top">
                                        <a href="#" @click.prevent="showChiTietThongKeHocSinhThiLaiHieuTruong"><i
                                            class="el-icon-info"></i></a>
                                    </el-tooltip>
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="layDuLieuThongKeHocSinhThiLaiHieuTruong"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
              </div>
              <div class="dts-card-body" v-loading="loading.hieuTruong.thongKeHocSinhThiLai"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-show="!loading.hieuTruong.thongKeHocSinhThiLai">
                  <BieuDoCotDistributed :label="hocSinhThiLaiHieuTruong"
                                        :dulieu="duLieuBieuDoThongKeHocSinhThiLaiHieuTruong"></BieuDoCotDistributed>
                </div>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :sm="24" :md="16" :lg="16" v-if="user && user.capHoc > 1 &&anBieuDo">
          <!--                  ẩn ngày 13/06/2022-->
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">BIỂU ĐỒ THỐNG KÊ HẠNH KIỂM - HỌC LỰC HỌC SINH</span>
                <span class="dts-right">
                                    <el-select @change="onHocKyChange" v-model="hocKy" size="mini"
                                               placeholder="Chọn học kì">
                                        <el-option :value="1" label="Học kỳ I"></el-option>
                                        <el-option :value="2" label="Học kỳ II"></el-option>
                                        <el-option :value="3" label="Cả năm"></el-option>
                                    </el-select>
                                </span>
              </div>
              <div class="dts-card-body" v-loading="loading.hieuTruong.thongKeHanhKiem"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">

                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="d-flex">
                      <strong>Thống kê hạnh kiểm</strong>
                      <span class="dts-right">
<!--                                    <el-tooltip content="Chi tiết" placement="top">-->
                        <!--                                        <a href="#" @click.prevent="showChiTietThongKeTrangThaiHocSinhHieuTruong"><i-->
                        <!--                                            class="el-icon-info"></i></a>-->
                        <!--                                    </el-tooltip>-->
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="layDuLieuThongKeHanhKiem"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
                    </div>

                    <div v-show="!loading.hieuTruong.thongKeHanhKiem">
                      <BieuDoHinhTron :label="hanhKiem"
                                      :dulieu="duLieuBieuDoThongKeHanhKiem"></BieuDoHinhTron>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="d-flex">
                      <strong class="">Thống kê học lực</strong>
                      <span class="dts-right">
<!--                                    <el-tooltip content="Chi tiết" placement="top">-->
                        <!--                                        <a href="#" @click.prevent="showChiTietThongKeTrangThaiHocSinhHieuTruong"><i-->
                        <!--                                            class="el-icon-info"></i></a>-->
                        <!--                                    </el-tooltip>-->
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="layDuLieuThongKeHocLuc"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
                    </div>
                    <div v-show="!loading.hieuTruong.thongKeHocLuc">
                      <BieuDoHinhTron :label="hocLuc"
                                      :dulieu="duLieuBieuDoThongKeHocLuc"></BieuDoHinhTron>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </el-col>

        <el-col :sm="12" :md="12" :lg="12" v-if="user && checkCapHoc('1',user.capHoc)&&anBieuDo">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">BIỂU ĐỒ THỐNG KÊ KẾT QUẢ GIÁO DỤC</span>
                <span class="dts-right">
<!--                                    <el-tooltip content="Chi tiết" placement="top">-->
                  <!--                                        <a href="#" @click.prevent="showChiTietThongKeTrangThaiHocSinhHieuTruong"><i-->
                  <!--                                            class="el-icon-info"></i></a>-->
                  <!--                                    </el-tooltip>-->
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="layDuLieuThongKeDanhGiaKetQuaGiaoDuc"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
              </div>
              <div class="dts-card-body" v-loading="loading.hieuTruong.thongKeKQGD"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-show="!loading.hieuTruong.thongKeKQGD">
                  <BieuDoHinhTron :label="danhGia" :dulieu="duLieuBieuDoThongKeKQGD"></BieuDoHinhTron>
                </div>
              </div>
            </div>
          </div>
        </el-col>

      </el-row>
    </div>

    <div style="" v-if="(user && user.role === 6) || (user && user.role === 7)" class="card-bieu-do">
      <el-row :gutter="24">
        <el-col :span="24" class="header_page">
          <h5>
            <font-awesome-icon :icon="['fas', 'chart-bar']" class="icon alt"/>
            TỔNG QUAN
          </h5>
          <el-divider></el-divider>
        </el-col>

        <el-col :sm="12" :md="8" :lg="8">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">Học sinh</span>
                <span class="dts-right">
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="getBieuDoTongQuanHocSinhTheoSoPhong"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
              </div>
              <div class="dts-card-body" v-loading="loading.tongQuan.hocSinh"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-show="!loading.tongQuan.hocSinh">
                  <BieuDoTron :dulieu="duLieuBieuDo.tongQuan.hocSinh"></BieuDoTron>
                </div>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :sm="12" :md="8" :lg="8">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">Học bạ</span>
                <span class="dts-right">
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="getBieuDoTongQuanHocBaTheoSoPhong"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
              </div>
              <div class="dts-card-body" v-loading="loading.tongQuan.hocBa"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-show="!loading.tongQuan.hocBa">
                  <BieuDoTron :dulieu="duLieuBieuDo.tongQuan.hocBa"></BieuDoTron>
                </div>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :sm="12" :md="8" :lg="8">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">Học bạ - học sinh</span>
                <span class="dts-right">
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="layLaiDuLieuHocBaHocSinhTheoSoPhong"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
              </div>
              <div class="dts-card-body"
                   v-loading="loading.tongQuan.hocSinh || loading.tongQuan.hocBa||loading.tongQuan.hocBaHocSinh"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div
                    v-show="!loading.tongQuan.hocSinh && !loading.tongQuan.hocBa && !loading.tongQuan.hocBaHocSinh">
                  <BieuDoCot :dulieu="duLieuBieuDo.tongQuan.hocBaHocSinh">
                  </BieuDoCot>
                </div>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :sm="12" :md="8" :lg="8">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">Trạng thái giáo viên chủ nhiệm ký học bạ</span>
                <span class="dts-right">
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="getBieuDoTongQuanGVCNTheoPhongSo"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>

              </div>
              <div class="dts-card-body" v-loading="loading.tongQuan.tongQuanGVCN"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-show="!loading.tongQuan.tongQuanGVCN">
                  <BieuDoCot :label="daKyChuaKy" :dulieu="duLieuBieuDo.tongQuan.tongQuanGVCN">
                  </BieuDoCot>
                </div>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :sm="12" :md="8" :lg="8">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">Trạng thái hiệu trưởng ký học bạ</span>
                <span class="dts-right">
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="getBieuDoTongQuanHieuTruongTheoPhongSo"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>

              </div>
              <div class="dts-card-body" v-loading="loading.tongQuan.tongQuanHieuTruong"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-show="!loading.tongQuan.tongQuanHieuTruong">
                  <BieuDoCot :label="daKyChuaKy" :dulieu="duLieuBieuDo.tongQuan.tongQuanHieuTruong">
                  </BieuDoCot>
                </div>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :sm="12" :md="8" :lg="8">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">Số lượng học bạ ký lại</span>
                <span class="dts-right">
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="getBieuDoTongQuanKyLaiHocBaTheoPhongSo"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>

              </div>
              <div class="dts-card-body" v-loading="loading.tongQuan.trangThaiKyLaiHocBa"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-show="!loading.tongQuan.trangThaiKyLaiHocBa">
                  <BieuDoCot :dulieu="duLieuBieuDo.tongQuan.trangThaiKyLaiHocBa">
                  </BieuDoCot>
                </div>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :sm="12" :md="12" :lg="12">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">Trạng thái ký học bạ theo môn học THCS</span>
                <span class="dts-right">
<!--                                    <el-tooltip content="Chi tiết" placement="top">-->
                  <!--                                        <a href="#" @click.prevent="showHienThiChiTiet"><i class="el-icon-info"></i></a>-->
                  <!--                                    </el-tooltip>-->
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="getBieuDoTrangThaiHocBaTheoMonHocTHCSTheoSoPhong"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
              </div>
              <div class="dts-card-body" v-loading="loading.tongQuan.trangThaiHocBaTHCSTheoMonHoc"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-show="!loading.tongQuan.trangThaiHocBaTHCSTheoMonHoc">
                  <BieuDoTrangThaiTheoMon :type="1"
                                          :dulieu="duLieuBieuDo.tongQuan.trangThaiHocBaTHCSTheoMonHoc">
                  </BieuDoTrangThaiTheoMon>
                </div>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :sm="12" :md="12" :lg="12">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">Trạng thái ký học bạ theo môn học THPT</span>
                <span class="dts-right">
<!--                                    <el-tooltip content="Chi tiết" placement="top">-->
                  <!--                                        <a href="#" @click.prevent="showHienThiChiTiet"><i class="el-icon-info"></i></a>-->
                  <!--                                    </el-tooltip>-->
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="getBieuDoTrangThaiHocBaTheoMonHocTHPTTheoSoPhong"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
              </div>
              <div class="dts-card-body" v-loading="loading.tongQuan.trangThaiHocBaTHPTTheoMonHoc"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-show="!loading.tongQuan.trangThaiHocBaTHPTTheoMonHoc">
                  <BieuDoTrangThaiTheoMon :type="1"
                                          :dulieu="duLieuBieuDo.tongQuan.trangThaiHocBaTHPTTheoMonHoc">
                  </BieuDoTrangThaiTheoMon>
                </div>
              </div>
            </div>
          </div>
        </el-col>

      </el-row>
    </div>

    <div style="" v-if="(user && user.role === 6) || (user && user.role === 7)" class="card-bieu-do">
      <el-row :gutter="24">
        <el-col :span="24" class="header_page">
          <h5>
            <font-awesome-icon :icon="['fas', 'school']" class="icon alt"/>
            TIỂU HỌC
          </h5>
          <el-divider></el-divider>
        </el-col>

        <el-col :sm="12" :md="8" :lg="8">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">THỐNG KÊ ĐÁNH GIÁ KẾT QUẢ GIÁO DỤC</span>
                <span class="dts-right">
<!--                                    <el-tooltip content="Chi tiết" placement="top">-->
                  <!--                                        <a href="#" @click.prevent="showChiTietThongKeTrangThaiHocSinhHieuTruong"><i-->
                  <!--                                            class="el-icon-info"></i></a>-->
                  <!--                                    </el-tooltip>-->
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="getDuLieuThongKeDanhGiaKetQuaGiaoDucTheoSoPhong"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
              </div>
              <div class="dts-card-body" v-loading="loading.capMot.thongKeKQGD"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-show="!loading.capMot.thongKeKQGD">
                  <BieuDoHinhTron :label="danhGia"
                                  :dulieu="duLieuBieuDo.capMot.duLieuBieuDoThongKeKQGD"></BieuDoHinhTron>
                </div>
              </div>
            </div>
          </div>
        </el-col>

      </el-row>
    </div>

    <div style="" v-if="(user && user.role === 6) || (user && user.role === 7)" class="card-bieu-do">
      <el-row :gutter="24">
        <el-col :span="24" class="header_page">
          <h5>
            <font-awesome-icon :icon="['fas', 'school']" class="icon alt"/>
            TRUNG HỌC CƠ SỞ
          </h5>
          <el-divider></el-divider>
        </el-col>

        <el-col :sm="24" :md="16" :lg="16">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">THỐNG KÊ HẠNH KIỂM - HỌC LỰC HỌC SINH</span>
                <span class="dts-right">
                                    <el-select @change="onHocLucHanhKiemHocKyTHCSChange" v-model="hocKy" size="mini"
                                               placeholder="Chọn học kì">
                                        <el-option :value="1" label="Học kỳ I"></el-option>
                                        <el-option :value="2" label="Học kỳ II"></el-option>
                                        <el-option :value="3" label="Cả năm"></el-option>
                                    </el-select>
                                </span>
              </div>
              <div class="dts-card-body">

                <div class="row">
                  <div class="col-sm-12 col-md-6" v-loading="loading.capHai.thongKeHanhKiem"
                       element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                       element-loading-background="rgba(255, 255, 255, 0.8)">
                    <div class="d-flex">
                      <strong>Thống kê hạnh kiểm</strong>
                      <span class="dts-right">
<!--                                    <el-tooltip content="Chi tiết" placement="top">-->
                        <!--                                        <a href="#" @click.prevent="showChiTietThongKeTrangThaiHocSinhHieuTruong"><i-->
                        <!--                                                class="el-icon-info"></i></a>-->
                        <!--                                    </el-tooltip>-->
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="getDuLieuThongKeHanhKiemTHCSTheoSoPhong"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
                    </div>

                    <div v-show="!loading.capHai.thongKeHanhKiem">
                      <BieuDoHinhTron :label="hanhKiem"
                                      :dulieu="duLieuBieuDo.capHai.thongKeHanhKiem"></BieuDoHinhTron>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6" v-loading="loading.capHai.thongKeHocLuc"
                       element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                       element-loading-background="rgba(255, 255, 255, 0.8)">
                    <div class="d-flex">
                      <strong class="">Thống kê học lực</strong>
                      <span class="dts-right">
<!--                                    <el-tooltip content="Chi tiết" placement="top">-->
                        <!--                                        <a href="#" @click.prevent="showChiTietThongKeTrangThaiHocSinhHieuTruong"><i-->
                        <!--                                                class="el-icon-info"></i></a>-->
                        <!--                                    </el-tooltip>-->
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="getDuLieuThongKeHocLucTHCSTheoSoPhong"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
                    </div>
                    <div v-show="!loading.capHai.thongKeHocLuc">
                      <BieuDoHinhTron :label="hocLuc"
                                      :dulieu="duLieuBieuDo.capHai.thongKeHocLuc"></BieuDoHinhTron>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </el-col>

      </el-row>
    </div>

    <div style="" v-if="(user  && user.role === 6) || (user && user.role === 7)" class="card-bieu-do">
      <el-row :gutter="24">
        <el-col :span="24" class="header_page">
          <h5>
            <font-awesome-icon :icon="['fas', 'school']" class="icon alt"/>
            TRUNG HỌC PHỔ THÔNG
          </h5>
          <el-divider></el-divider>
        </el-col>

        <el-col :sm="24" :md="16" :lg="16">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">THỐNG KÊ HẠNH KIỂM - HỌC LỰC HỌC SINH</span>
                <span class="dts-right">
                                    <el-select @change="onHocLucHanhKiemHocKyTHPTChange" v-model="hocKy" size="mini"
                                               placeholder="Chọn học kì">
                                        <el-option :value="1" label="Học kỳ I"></el-option>
                                        <el-option :value="2" label="Học kỳ II"></el-option>
                                        <el-option :value="3" label="Cả năm"></el-option>
                                    </el-select>
                                </span>
              </div>
              <div class="dts-card-body">

                <div class="row">
                  <div class="col-sm-12 col-md-6" v-loading="loading.capBa.thongKeHanhKiem"
                       element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                       element-loading-background="rgba(255, 255, 255, 0.8)">
                    <div class="d-flex">
                      <strong>Thống kê hạnh kiểm</strong>
                      <span class="dts-right">
<!--                                    <el-tooltip content="Chi tiết" placement="top">-->
                        <!--                                        <a href="#" @click.prevent="showChiTietThongKeTrangThaiHocSinhHieuTruong"><i-->
                        <!--                                                class="el-icon-info"></i></a>-->
                        <!--                                    </el-tooltip>-->
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="getDuLieuThongKeHanhKiemTHPTTheoSoPhong"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
                    </div>

                    <div v-show="!loading.capBa.thongKeHanhKiem">
                      <BieuDoHinhTron :label="hanhKiem"
                                      :dulieu="duLieuBieuDo.capBa.thongKeHanhKiem"></BieuDoHinhTron>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6" v-loading="loading.capBa.thongKeHocLuc"
                       element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                       element-loading-background="rgba(255, 255, 255, 0.8)">
                    <div class="d-flex">
                      <strong class="">Thống kê học lực</strong>
                      <span class="dts-right">
<!--                                    <el-tooltip content="Chi tiết" placement="top">-->
                        <!--                                        <a href="#" @click.prevent="showChiTietThongKeTrangThaiHocSinhHieuTruong"><i-->
                        <!--                                                class="el-icon-info"></i></a>-->
                        <!--                                    </el-tooltip>-->
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="getDuLieuThongKeHocLucTHPTTheoSoPhong"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
                    </div>
                    <div v-show="!loading.capBa.thongKeHocLuc">
                      <BieuDoHinhTron :label="hocLuc"
                                      :dulieu="duLieuBieuDo.capBa.thongKeHocLuc"></BieuDoHinhTron>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <!-- Thông tin chi tiết -->
    <el-row>
      <el-dialog
          :close-on-press-escape="false"
          :close-on-click-modal="false" title="Trạng thái học bạ theo lớp môn" fullscreen center @close="dongThongTin"
          :visible.sync="hien_thi_chi_tiet_trang_thai_theo_mon">
        <ChiTietBieuDoTrangThaiTheoMon :loadChiTiet="loadChiTiet"
                                       :du_lieu_bieu_do="du_lieu_bieu_do_trang_thai_hoc_ba_theo_lop">
        </ChiTietBieuDoTrangThaiTheoMon>
      </el-dialog>

      <el-dialog
          :close-on-press-escape="false"
          :close-on-click-modal="false" title="Chi tiết" center width="70%" top="5vh"
          :visible.sync="chi_tiet_danh_sach_trang_thai_ky_hoc_ba_theo_gvcn">
        <el-table border :max-height="maxHeight" :data="danh_sach_trang_thai_ky_hoc_ba_theo_gvcn" show-summary
                  :summary-method="getSummaries">
          <el-table-column label="STT" width="100" align="center" type="index" class-name="text-center">
          </el-table-column>
          <el-table-column header-align="center" align="center" property="tenLop" label="Tên lớp">
          </el-table-column>
          <el-table-column property="hocBa" align="center" header-align="center" label="Học bạ">
          </el-table-column>
          <el-table-column property="hocSinh" align="center" header-align="center" label="Học sinh">
          </el-table-column>
        </el-table>
      </el-dialog>

      <el-dialog
          :close-on-press-escape="false"
          :close-on-click-modal="false" title="Chi tiết" center width="70%" top="5vh"
          :visible.sync="chi_tiet_danh_sach_trang_thai_hoc_sinh_theo_gvcn">
        <el-table border :max-height="maxHeight" :data="danh_sach_trang_thai_hoc_sinh_theo_gvcn">
          <el-table-column label="STT" width="100" align="center" type="index" class-name="text-center">
          </el-table-column>
          <el-table-column header-align="center" align="center" property="tenLop" label="Tên lớp">
          </el-table-column>
          <el-table-column property="tongSoHocSinh" align="center" header-align="center"
                           label="Tổng số học sinh">
          </el-table-column>
          <el-table-column property="dangHoc" align="center" header-align="center" label="Đang học">
          </el-table-column>
          <el-table-column property="boHoc" align="center" header-align="center" label="Bỏ học">
          </el-table-column>
          <el-table-column property="chuyenTruong" align="center" header-align="center" label="Chuyển trường">
          </el-table-column>
        </el-table>
      </el-dialog>

      <el-dialog
          :close-on-press-escape="false"
          :close-on-click-modal="false" title="Chi tiết" center width="70%" top="5vh"
          :visible.sync="chi_tiet_danh_sach_hoc_sinh_thi_lai_theo_gvcn">
        <el-table border :max-height="maxHeight" :data="danh_sach_hoc_sinh_thi_lai_theo_gvcn">
          <el-table-column label="STT" width="100" align="center" type="index" class-name="text-center">
          </el-table-column>
          <el-table-column header-align="center" align="center" property="tenLop" label="Tên lớp">
          </el-table-column>
          <el-table-column property="tongSoHocBa" align="center" header-align="center" label="Tổng số học bạ">
          </el-table-column>
          <el-table-column property="lenLop" align="center" header-align="center" label="Lên lớp">
          </el-table-column>
          <el-table-column property="thiLai" align="center" header-align="center" label="Thi lại">
          </el-table-column>
          <el-table-column property="lenLopSauThiLai" align="center" header-align="center"
                           label="Lên lớp sau thi lại">
          </el-table-column>
        </el-table>
      </el-dialog>

      <el-dialog
          :close-on-press-escape="false"
          :close-on-click-modal="false" title="Chi tiết" center width="70%" top="5vh"
          :visible.sync="chi_tiet_so_luong_hoc_ba_hoc_sinh_hieu_truong">
        <el-table border :max-height="maxHeight" :data="danh_sach_trang_thai_ky_hoc_ba_theo_lop">
          <el-table-column label="STT" width="70" align="center" type="index" class-name="text-center">
          </el-table-column>
          <el-table-column header-align="center" align="center" property="tenLop" label="Tên lớp">
          </el-table-column>
          <el-table-column property="tongHocBaDaKy" align="center" header-align="center" label="Đã ký">
          </el-table-column>
          <el-table-column property="phanTramDaKy" align="center" header-align="center" label="Tỷ lệ (%)">
          </el-table-column>
          <el-table-column property="tongHocBaChuaKy" align="center" header-align="center" label="Chưa ký">
          </el-table-column>
          <el-table-column property="phanTramChuaKy" align="center" header-align="center" label="Tỷ lệ (%)">
          </el-table-column>
          <el-table-column label="Tổng số học bạ" width="100" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.tongHocBaDaKy + scope.row.tongHocBaChuaKy }}</p>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>

      <el-dialog
          :close-on-press-escape="false"
          :close-on-click-modal="false" title="TRẠNG THÁI HỌC BẠ THEO GVCN" center width="70%" top="5vh"
          :visible.sync="du_lieu_bieu_do_trang_thai_hoc_ba_theo_gvcn">
        <el-table border :max-height="maxHeight" :data="danh_sach_trang_thai_ky_hoc_ba_theo_gvcn">
          <el-table-column label="STT" width="70" align="center" type="index" class-name="text-center">
          </el-table-column>
          <el-table-column header-align="center" align="center" property="tenLop" label="Lớp">
          </el-table-column>
          <!-- <el-table-column property="hocBa" align="center" header-align="center" label="Số lượng học bạ">
          </el-table-column>
          <el-table-column property="hocSinh" align="center" header-align="center" label="Số lượng học sinh">
          </el-table-column> -->
          <el-table-column property="tongHocBaChuaKy" align="center" header-align="center"
                           label="Học bạ chưa ký">
          </el-table-column>
          <el-table-column property="phanTramChuaKy" align="center" header-align="center" label="Chưa ký (%)">
          </el-table-column>
          <el-table-column property="tongHocBaDaKy" align="center" header-align="center" label="Học bạ đã ký">
          </el-table-column>
          <el-table-column property="phanTramDaKy" align="center" header-align="center" label="Đã ký (%)">
          </el-table-column>
        </el-table>
      </el-dialog>
    </el-row>
  </div>

</template>

<script>
import {
  mapActions,
  mapState
} from 'vuex';
import api from '../_helpers/api';
import websocket from "../websocket";
import constant from '../_helpers/constant_api';
import BieuDoHinhTron from "./Dashboard/TongQuanHinhTron";
import BieuDoTron from "./Dashboard/BieuDoTron";
import BieuDoCot from "./Dashboard/BieuDoCot";
import BieuDoCotSW from "./Dashboard/BieuDoCotScorllWidth";
import BieuDoCotDistributed from "./Dashboard/BieuDoCotDistributed";
import BieuDoCotKhongXepChong from "./Dashboard/BieuDoCotKhongXepChong";
import BieuDoCotHieuTruong from "./Dashboard/BieuDoCotHieuTruong";
import BieuDoTrangThaiTheoMon from "./Dashboard/BieuDoTrangThaiTheoMon";
import ChiTietBieuDoTrangThaiTheoMon from "./Dashboard/ChiTietBieuDoTrangThaiTheoMon";
import ESelectVue from "./Ui/ESelect";
import util from '../util';
import dialog from "../dialog";

export default {
  name: "DashBoard",
  metaInfo: {
    title: 'Tổng quan',
    titleTemplate: '',
    meta: [{
      vmid: 'DashBoard',
      name: 'DashBoard',
      content: ''
    }]
  },
  computed: {
    ...mapState('account', [
      'user',
      'list_nam_hoc'
    ]),
    ...mapState('school', [
      "listPGD",
      "listSchool",
    ])
  },
  components: {
    BieuDoHinhTron: BieuDoHinhTron,
    BieuDoCot: BieuDoCot,
    BieuDoCotSW: BieuDoCotSW,
    BieuDoCotDistributed: BieuDoCotDistributed,
    BieuDoCotHieuTruong: BieuDoCotHieuTruong,
    BieuDoTron: BieuDoTron,
    BieuDoCotKhongXepChong: BieuDoCotKhongXepChong,
    BieuDoTrangThaiTheoMon: BieuDoTrangThaiTheoMon,
    ChiTietBieuDoTrangThaiTheoMon: ChiTietBieuDoTrangThaiTheoMon,
    'eselect': ESelectVue,
  },

  data() {

    return {
      anBieuDo: false,
      hocKy: 1,
      infoRole: null,
      maxHeight: window.innerHeight,
      loadChiTiet: true,
      loading: {
        fullScreen: false,
        hieuTruong: {
          tongQuan: false,
          hocBaHieuTruong: false,
          thongKeHocSinhThiLai: false,
          trangThaiHocBaTheoLopGVCNToanTruong: false,
          thongKeKQGD: false
        },
        giaoVienBoMon: {
          trangThaiHocBaTheoLop: false,
          thongKeHocSinhThiLai: false,
        },
        giaoVienChuNhiem: {
          trangThaiHocBa: false,
          soLuongHocBaHocSinh: false,
          trangThaiHocBaTheoMonHoc: false,
          thongKeHocSinhThiLai: false,
          thongKeKQGD: false
        },
        trang_thai_hoc_ba_theo_mon_hoc: false,
        capMot: {
          thongKeKQGD: false,
        },
        capHai: {
          thongKeHocLuc: false,
          thongKeHanhKiem: false
        },
        capBa: {
          thongKeHocLuc: false,
          thongKeHanhKiem: false
        },
        tongQuan: {
          hocSinh: false,
          hocBa: false,
          hocBaHocSinh: false,
          tongQuanGVCN: false,
          tongQuanHieuTruong: false,
          trangThaiKyLaiHocBa: false,
          trangThaiHocBaTHPTTheoMonHoc: false,
          trangThaiHocBaTHCSTheoMonHoc: false,
        },
      },
      duLieuBieuDo: {
        capMot: {
          tongQuanHieuTruong: [],
          tongQuanGVCN: [],
          duLieuBieuDoThongKeKQGD: [],
        },
        capHai: {
          trangThaiHocSinh: {},
          tongQuanHieuTruong: [],
          tongQuanGVCN: [],
          trangThaiHocBaTheoMon: {
            series: [],
            listMon: [],
          },
          thongKeHanhKiem: {},
          thongKeHocLuc: {}
        },
        capBa: {
          thongKeHanhKiem: {},
          thongKeHocLuc: {}
        },
        tongQuan: {
          hocSinh: {},
          hocBa: {},
          hocBaHocSinh: {},
          tongQuanGVCN: {},
          tongQuanHieuTruong: {},
          trangThaiKyLaiHocBa: {},
          trangThaiHocBaTHPTTheoMonHoc: {},
          trangThaiHocBaTHCSTheoMonHoc: {},
        },
      },
      total_hocsinh: 0,
      total_hocba: 0,
      danh_sach_trang_thai_ky_hoc_ba_theo_lop: [],
      danh_sach_trang_thai_ky_hoc_ba_theo_gvcn: [],
      danh_sach_trang_thai_hoc_sinh_theo_gvcn: [],
      danh_sach_hoc_sinh_thi_lai_theo_gvcn: [],
      duLieuBieuDoHocBaGvcn: {},
      duLieuBieuDoTrangThaiHocBaTheoMonHoc: {},
      duLieuBieuDoTrangThaiHocBaTheoLop: {},
      duLieuBieuDoTrangThaiHocBaTheoLopGVCNToanTruong: {},
      duLieuBieuDoThongKeHocSinhThiLaiGvbm: {},
      duLieuBieuDoThongKeHocSinhThiLaiGvcn: [],
      duLieuBieuDoThongKeTrangThaiHocSinhGvcn: [],
      duLieuBieuDoThongKeTrangThaiHocSinhHieuTruong: {},
      duLieuBieuDoThongKeTrangThaiUploadChuKy: {},
      duLieuBieuDoThongKeHanhKiem: {},
      duLieuBieuDoThongKeHocLuc: {},
      duLieuBieuDoThongKeKQGD: {},
      duLieuBieuDoThongKeHocSinhThiLaiHieuTruong: {},
      hocSinhThiLaiHieuTruong: ['Lên lớp', 'Thi lại'],
      daKyChuaKy: ['Đã ký', 'Chưa ký'],
      trangThaiHocSinh: ['Đang học', 'Bỏ học', 'Chuyển trường'],
      trangThaiChuKy: ['Hoạt động', 'Chưa upload', 'Chờ xác nhận', 'Bị từ chối'],
      hanhKiem: ['Tốt', 'Khá', 'Trung bình', 'Yếu', 'Kém'],
      hocLuc: ['Giỏi', 'Khá', 'Trung bình', 'Yếu', 'Kém'],
      danhGia: ['Xuất sắc', 'Tốt', 'Hoàn thành', 'Chưa hoàn thành'],
      duLieuBieuDoTrangThaiHocBaTheoTrangThaiGiaoVien: [],
      duLieuBieuDoTrangThaiHocBaTongQuan: [],
      duLieuBieuDoTrangThaiHocBaTongQuanHieuTruong: [],
      duLieuBieuDoHocBaHocSinhHieuTruong: [],
      showThongBao: false,
      searchForm: {
        year: '',
        pgd: '',
        maTruong: '',
      },
      du_lieu_bieu_do_trang_thai_hoc_ba_theo_gvcn: false,
      chi_tiet_danh_sach_trang_thai_ky_hoc_ba_theo_gvcn: false,
      chi_tiet_danh_sach_trang_thai_hoc_sinh_theo_gvcn: false,
      chi_tiet_danh_sach_hoc_sinh_thi_lai_theo_gvcn: false,
      chi_tiet_so_luong_hoc_ba_hoc_sinh_hieu_truong: false,
      hien_thi_chi_tiet_trang_thai_theo_mon: false,
      du_lieu_bieu_do_trang_thai_hoc_ba_theo_lop: [],
    }
  },

  methods: {

    ...mapActions('school', [
      "getListPGD",
      "getListSchool"
    ]),
    checkTimeThongBao() {
      this.showThongBao = false;
      var dateFrom = "15/01/2023";
      var dateTo = "26/01/2023";
      const today = new Date();
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();

      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;
      const dateCheck = dd + '/' + mm + '/' + yyyy;

      var d1 = dateFrom.split("/");
      var d2 = dateTo.split("/");
      var c = dateCheck.split("/");

      var from = new Date(d1[2], parseInt(d1[1]) - 1, d1[0]);  // -1 because months are from 0 to 11
      var to = new Date(d2[2], parseInt(d2[1]) - 1, d2[0]);
      var check = new Date(c[2], parseInt(c[1]) - 1, c[0]);
      if (check > from && check < to) {
        this.showThongBao = true;
      }
    },
    getListTruong() {
      this.searchForm.maTruong = '';
      this.searchForm.grade = '';
      this.searchForm.student = '';
      this.getListSchool({
        maPhong: this.searchForm.pgd,
        maTinh: this.user.maSo
      })
    },

    checkCapHoc(str, data) {
      return util.regexChar(str, data)
    },

    onHocKyChange() {
      this.layDuLieuThongKeHanhKiem();
      this.layDuLieuThongKeHocLuc()
    },
    onHocLucHanhKiemHocKyTHCSChange() {
      this.getDuLieuThongKeHanhKiemTHCSTheoSoPhong();
      this.getDuLieuThongKeHocLucTHCSTheoSoPhong()
    },
    onHocLucHanhKiemHocKyTHPTChange() {
      this.getDuLieuThongKeHanhKiemTHPTTheoSoPhong();
      this.getDuLieuThongKeHocLucTHPTTheoSoPhong()
    },
    getRole() {
      let url = constant.giaovien.getRole;
      let params = {
        namHoc: this.searchForm.year,
      }
      api.get(url, params).then(
          data => {
            if (data.data.code == 200) {
              this.infoRole = data.data.data;
              this.getAllChart();
            } else {
              this.infoRole = null
            }
          }
      ).catch((e) => {
        this.infoRole = null
      })
    },

    getAllChart() {
      console.error('getAllChart:')
      console.log(this.infoRole)
      if (this.infoRole.isGVBM) {
        this.layDuLieuTrangThaiBieuDoTheoLop();
        // this.layDuLieuThongKeHocSinhThiLaiGvbm();
      }
      if (this.infoRole.isGVCN) {
        this.layDuLieuTrangThaiBieuDoTrangThaiHocBaTheoGvcn();
        this.layDuLieuBieuDoHocBaHocSinhGvcn();
        this.layDuLieuThongKeHocSinhThiLaiGvcn();
        this.layDuLieuThongKeTrangThaiHocSinhGVCN();
        this.layDuLieuBieuDoTrangThaiHocBaTheoMonHocGvcn();
        this.layDuLieuThongKeHanhKiem();
        this.layDuLieuThongKeDanhGiaKetQuaGiaoDuc();
        this.layDuLieuThongKeHocLuc();
      }
      if (this.infoRole.isHieuTruong) {
        this.layDuLieuBieuDoTrangThaiHocBaTongQuanHieuTruong();
        this.layDuLieuBieuDoTrangThaiHocBaTheoMonHoc();
        this.layDuLieuThongKeTrangThaiHocSinhHieuTruong();
        // this.layDuLieuThongKeHocSinhThiLaiHieuTruong();
        this.layDuLieuBieuDoTrangThaiHocBaTheoMonHocGvcn();
        this.layDuLieuTrangThaiBieuDoTheoLopGVCNToanTruong();
        // this.layDuLieuThongKeHanhKiem();
        // this.layDuLieuThongKeHocLuc();
        // this.layDuLieuThongKeDanhGiaKetQuaGiaoDuc();
      }
      if (this.infoRole.isCanBo) {
        this.layDuLieuBieuDoTrangThaiHocBaTongQuanHieuTruong();
        this.layDuLieuThongKeTrangThaiHocSinhHieuTruong()
        this.layDuLieuBieuDoTrangThaiHocBaTheoMonHoc();
        this.layDuLieuThongKeHanhKiem();
        this.layDuLieuThongKeHocLuc();
        this.layDuLieuThongKeHocSinhThiLaiHieuTruong();
        this.layDuLieuBieuDoTrangThaiHocBaTheoMonHocGvcn();
        this.layDuLieuTrangThaiBieuDoTheoLopGVCNToanTruong();
        this.layDuLieuThongKeTrangThaiUploadChuKy();
        this.layDuLieuThongKeDanhGiaKetQuaGiaoDuc();
      }
      if (this.user && (this.user.role == 6 || this.user.role == 7)) {
        this.getBieuDoTongQuanHocBaTheoSoPhong();
        this.getBieuDoTongQuanHocSinhTheoSoPhong();
        this.getBieuDoTongQuanGVCNTheoPhongSo();
        this.getBieuDoTongQuanGVCNTheoPhongSo();
        this.getBieuDoTongQuanHieuTruongTheoPhongSo();
        this.getBieuDoTongQuanKyLaiHocBaTheoPhongSo();
        this.getBieuDoTrangThaiHocBaTheoMonHocTHCSTheoSoPhong();
        this.getBieuDoTrangThaiHocBaTheoMonHocTHPTTheoSoPhong();
        this.getDuLieuThongKeDanhGiaKetQuaGiaoDucTheoSoPhong();
        this.getDuLieuThongKeHanhKiemTHCSTheoSoPhong();
        this.getDuLieuThongKeHocLucTHCSTheoSoPhong();
        this.getDuLieuThongKeHanhKiemTHPTTheoSoPhong();
        this.getDuLieuThongKeHocLucTHPTTheoSoPhong();

      }
    },

    dongThongTin() {
      this.hien_thi_chi_tiet_trang_thai_theo_mon = false;
      this.du_lieu_bieu_do_trang_thai_hoc_ba_theo_lop = [];
    },

    showChiTietThongKeTrangThaiHocSinhGvcn() {
      this.loading.fullScreen = true;
      let params = {
        namHoc: this.searchForm.year,

      }
      let url = constant.bieudo.chiTietThongKeThaiHocSinhGvcn;
      api.get(url, params).then(
          response => {
            this.loading.fullScreen = false;
            console.log("Res:")
            console.log(response)
            if (response.data.code == 200) {
              this.danh_sach_trang_thai_hoc_sinh_theo_gvcn = response.data.data;
              console.log("Danh sách có:")
              this.chi_tiet_danh_sach_trang_thai_hoc_sinh_theo_gvcn = true;
            } else {
            }
          }
      )
    },

    showChiTietThongKeHocSinhThiLaiGvcn() {
      this.loading.fullScreen = true;
      let params = {
        namHoc: this.searchForm.year,
      }
      let url = constant.bieudo.chiTietThongKeHocSinhThiLaiGvcn;
      api.get(url, params).then(
          response => {
            this.loading.fullScreen = false;
            console.log("Res:")
            console.log(response)
            if (response.data.code == 200) {
              this.danh_sach_hoc_sinh_thi_lai_theo_gvcn = response.data.data;
              console.log("Danh sách có:")
              console.log(this.danh_sach_hoc_sinh_thi_lai_theo_gvcn)
              this.chi_tiet_danh_sach_hoc_sinh_thi_lai_theo_gvcn = true;
            } else {
            }
          }
      )
    },

    showChiTietThongKeTrangThaiHocSinhHieuTruong() {
      this.loading.fullScreen = true;
      let params = {
        namHoc: this.searchForm.year,
      }
      let url = constant.bieudo.chiTietThongKeTrangThaiHocSinhHieuTruong;
      api.get(url, params).then(
          response => {
            this.loading.fullScreen = false;
            console.log("Res:")
            console.log(response)
            if (response.data.code == 200) {
              this.danh_sach_trang_thai_hoc_sinh_theo_gvcn = response.data.data;
              this.chi_tiet_danh_sach_trang_thai_hoc_sinh_theo_gvcn = true;
            } else {
            }
          }
      )
    },

    showChiTietThongKeHocSinhThiLaiHieuTruong() {
      this.loading.fullScreen = true;
      let params = {
        namHoc: this.searchForm.year,
      }
      let url = constant.bieudo.chiTietThongKeHocSinhThiLaiHieuTruong;
      api.get(url, params).then(
          response => {
            this.loading.fullScreen = false;
            console.log("Res:")
            console.log(response)
            if (response.data.code == 200) {
              this.danh_sach_hoc_sinh_thi_lai_theo_gvcn = response.data.data;
              console.log("Danh sách có:")
              console.log(this.danh_sach_hoc_sinh_thi_lai_theo_gvcn)
              this.chi_tiet_danh_sach_hoc_sinh_thi_lai_theo_gvcn = true;
            } else {
            }
          }
      )
    },

    showHienThiChiTiet() {
      this.loading.fullScreen = true;
      this.loadChiTiet = true;
      this.hien_thi_chi_tiet_trang_thai_theo_mon = true;
      this.xemChiTietTrangThaiHocBaTheoLopMon();
    },

    xemChiTietHocBaTheoGiaoVienCN() {
      this.loading.fullScreen = true;
      let params = {
        namHoc: this.searchForm.year,
      }
      let url = constant.bieudo.chiTietTrangThaiHocBaTheoGVCN;
      api.get(url, params).then(
          response => {
            this.loading.fullScreen = false;
            console.log("Res:")
            console.log(response)
            if (response.data.code == 200) {
              this.danh_sach_trang_thai_ky_hoc_ba_theo_gvcn = response.data.data;
              this.chi_tiet_danh_sach_trang_thai_ky_hoc_ba_theo_gvcn = true;
            } else {
            }
          }
      )
    },

    xemChiTietTongQuanHieuTruong() {
      this.loading.fullScreen = true;
      let params = {
        namHoc: this.searchForm.year,
      }
      let url = constant.bieudo.trangThaiHocBaTheoLop;
      api.get(url, params).then(
          response => {
            this.loading.fullScreen = false;
            console.log("Res:")
            console.log(response)
            if (response.data.code == 200) {
              this.danh_sach_trang_thai_ky_hoc_ba_theo_lop = response.data.data;
              this.chi_tiet_so_luong_hoc_ba_hoc_sinh_hieu_truong = true;
            } else {
            }
          }
      )
    },

    layDuLieuBieuDoTrangThaiHocBaTongQuanHieuTruong() {
      this.loading.hieuTruong.tongQuan = true;
      let params = {
        namHoc: this.searchForm.year,
      }
      let url = constant.bieudo.tongQuanHieuTruong;
      api.get(url, params).then(
          response => {
            this.loading.hieuTruong.tongQuan = false;
            console.log("Res:")
            console.log(response)
            if (response.data.code == 200) {
              let duLieu = response.data.data;

              let chuaKy = duLieu.chuaKys;
              let daKy = duLieu.daKys;
              let tenLop = duLieu.tenLops;
              this.duLieuBieuDoTrangThaiHocBaTongQuanHieuTruong = {
                list_lop: tenLop,
                du_lieu: [{
                  name: "Đã ký",
                  data: daKy
                },
                  {
                    name: "Chưa ký",
                    data: chuaKy
                  }
                ]
              };

            } else {
            }
          }
      )
    },

    layDuLieuBieuDoTrangThaiHocBaTheoMonHoc() {
      this.loading.hieuTruong.hocBaHieuTruong = true;
      let params = {
        namHoc: this.searchForm.year,
      }
      let url = constant.bieudo.hocBaHieuTruong;
      api.get(url, params).then(
          response => {
            this.loading.hieuTruong.hocBaHieuTruong = false;
            console.log("Res:")
            console.log(response)
            if (response.data.code == 200) {

              let duLieu = response.data.data;
              let data = duLieu.data;
              let tenLop = duLieu.tieuDes;
              let duLieuBieuDoHocBaHocSinhHieuTruong = {
                list_lop: tenLop,
                du_lieu: [
                  {
                    data: data
                  }
                ]
              };
              this.duLieuBieuDoHocBaHocSinhHieuTruong = duLieuBieuDoHocBaHocSinhHieuTruong;
              console.log(this.duLieuBieuDoHocBaHocSinhHieuTruong)

            } else {
            }
          }
      )
    },

    getBieuDoTrangThaiHocBaTheoMonHocTHCSTheoSoPhong() {
      this.loading.tongQuan.trangThaiHocBaTHCSTheoMonHoc = true;
      let params = {
        namHoc: this.searchForm.year,
        maSo: this.user.maSo,
        maPhong: this.searchForm.pgd,
        maTruong: this.searchForm.maTruong,
        capHoc: 2,
      }

      let url = constant.bieudo.trangThaiHocBaTheoMonTheoSoPhong;
      api.get(url, params).then(
          response => {
            this.loading.tongQuan.trangThaiHocBaTHCSTheoMonHoc = false;
            console.log("Res:")
            console.log(response)
            if (response.data.code == 200) {
              let duLieu = response.data.data;
              let duLieuBieuDoTrangThaiHocBaTheoMonHoc = {};
              duLieuBieuDoTrangThaiHocBaTheoMonHoc.datas = duLieu.datas;
              duLieuBieuDoTrangThaiHocBaTheoMonHoc.listMon = duLieu.listMon;
              this.duLieuBieuDo.tongQuan.trangThaiHocBaTHCSTheoMonHoc = duLieuBieuDoTrangThaiHocBaTheoMonHoc;
            } else {
            }
          }
      )
    },

    getBieuDoTrangThaiHocBaTheoMonHocTHPTTheoSoPhong() {
      this.loading.tongQuan.trangThaiHocBaTHPTTheoMonHoc = true;
      let params = {
        namHoc: this.searchForm.year,
        maSo: this.user.maSo,
        maPhong: this.searchForm.pgd,
        maTruong: this.searchForm.maTruong,
        capHoc: 3,
      }

      let url = constant.bieudo.trangThaiHocBaTheoMonTheoSoPhong;
      api.get(url, params).then(
          response => {
            this.loading.tongQuan.trangThaiHocBaTHPTTheoMonHoc = false;
            console.log("Res:")
            console.log(response)
            if (response.data.code == 200) {
              let duLieu = response.data.data;
              let duLieuBieuDoTrangThaiHocBaTheoMonHoc = {};
              duLieuBieuDoTrangThaiHocBaTheoMonHoc.datas = duLieu.datas;
              duLieuBieuDoTrangThaiHocBaTheoMonHoc.listMon = duLieu.listMon;
              this.duLieuBieuDo.tongQuan.trangThaiHocBaTHPTTheoMonHoc = duLieuBieuDoTrangThaiHocBaTheoMonHoc;
            } else {
            }
          }
      )
    },

    layDuLieuBieuDoTrangThaiHocBaTheoMonHocGvcn() {
      this.loading.giaoVienChuNhiem.trangThaiHocBaTheoMonHoc = true;
      let params = {
        namHoc: this.searchForm.year,
      }
      let url = constant.bieudo.trangThaiTheoMonHoc;
      api.get(url, params).then(
          response => {
            this.loading.giaoVienChuNhiem.trangThaiHocBaTheoMonHoc = false;
            console.log("Res:")
            console.log(response)
            if (response.data.code == 200) {
              let duLieu = response.data.data;
              let duLieuBieuDoTrangThaiHocBaTheoMonHoc = {};
              duLieuBieuDoTrangThaiHocBaTheoMonHoc.datas = duLieu.datas;
              duLieuBieuDoTrangThaiHocBaTheoMonHoc.listMon = duLieu.listMon;
              this.duLieuBieuDoTrangThaiHocBaTheoMonHoc = duLieuBieuDoTrangThaiHocBaTheoMonHoc;
            } else {
            }
          }
      )
    },

    layDuLieuBieuDoHocBaHocSinhGvcn() {
      this.loading.giaoVienChuNhiem.soLuongHocBaHocSinh = true;
      let params = {
        namHoc: this.searchForm.year,
      }
      let url = constant.bieudo.hocBaGvcn;
      api.get(url, params).then(
          response => {
            this.loading.giaoVienChuNhiem.soLuongHocBaHocSinh = false;
            console.log("Res:")
            console.log(response)
            if (response.data.code == 200) {
              let duLieu = response.data.data;
              let hocBa = duLieu.hocBas;
              let hocSinh = duLieu.hocSinhs;
              let tenLop = duLieu.tenLops;
              let duLieuBieuDoHocBaGvcn = {
                list_lop: tenLop,
                du_lieu: [{
                  name: "Học bạ",
                  data: hocBa
                },
                  {
                    name: "Học sinh",
                    data: hocSinh
                  }
                ]
              };
              this.duLieuBieuDoHocBaGvcn = duLieuBieuDoHocBaGvcn;
            } else {
            }
          }
      )
    },

    layDuLieuTrangThaiBieuDoTrangThaiHocBaTheoGvcn() {
      this.loading.giaoVienChuNhiem.trangThaiHocBa = true;
      let params = {
        namHoc: this.searchForm.year,
      }
      let url = constant.bieudo.trangThaiTheoGiaoVien;
      api.get(url, params).then(
          response => {
            this.loading.giaoVienChuNhiem.trangThaiHocBa = false;
            console.log("Res:")
            console.log(response)
            if (response.data.code == 200) {
              this.duLieuBieuDoTrangThaiHocBaTheoTrangThaiGiaoVien = response.data.data.data;
            } else {
            }
          }
      )
    },

    layDuLieuThongKeTrangThaiHocSinhHieuTruong() {
      this.loading.hieuTruong.thongKeTrangThaiHocSinh = true;
      let params = {
        namHoc: this.searchForm.year,
      }
      let url = constant.bieudo.thongKeTrangThaiHocSinhHieuTruong;
      api.get(url, params).then(
          response => {
            this.loading.hieuTruong.thongKeTrangThaiHocSinh = false;
            console.log("Res:")
            console.log(response)
            if (response.data.code == 200) {
              console.log("Biểu đồ thống kê học sinh thi lại:")
              let duLieu = response.data.data;

              let data = duLieu.data;
              let tieuDe = duLieu.tieuDes;

              this.duLieuBieuDoThongKeTrangThaiHocSinhHieuTruong = {
                list_lop: tieuDe,
                du_lieu: [
                  {
                    data: data
                  }
                ]
              };
            } else {
            }
          }
      )
    },

    layDuLieuThongKeTrangThaiUploadChuKy() {
      this.loading.hieuTruong.thongKeTrangThaiUploadChuKy = true;
      let params = {
        namHoc: this.searchForm.year,
      }
      let url = constant.bieudo.thongKeUploadChuKy;
      api.get(url, params).then(
          response => {
            this.loading.hieuTruong.thongKeTrangThaiUploadChuKy = false;
            console.log("Res:")
            console.log(response)
            if (response.data.code == 200) {
              console.log("Biểu đồ thống kê học sinh thi lại:")
              let duLieu = response.data.data;

              let data = duLieu.data;
              let tieuDe = duLieu.tieuDes;

              this.duLieuBieuDoThongKeTrangThaiUploadChuKy = {
                list_lop: tieuDe,
                du_lieu: [
                  {
                    data: data
                  }
                ]
              };
            } else {
            }
          }
      )
    },

    layDuLieuThongKeHanhKiem() {
      this.loading.hieuTruong.thongKeHanhKiem = true;
      let params = {
        namHoc: this.searchForm.year,
        hocKy: this.hocKy
      }
      let url = constant.bieudo.thongKeHanhKiem;
      api.get(url, params).then(
          response => {
            this.loading.hieuTruong.thongKeHanhKiem = false;
            console.log("Res:")
            console.log(response)
            if (response.data.code == 200) {
              console.log("Biểu đồ thống kê học sinh thi lại:")
              this.duLieuBieuDoThongKeHanhKiem = response.data.data.duLieu
            } else {
            }
          }
      )
    },

    layDuLieuThongKeHocLuc() {
      this.loading.hieuTruong.thongKeHocLuc = true;
      let params = {
        namHoc: this.searchForm.year,
        hocKy: this.hocKy
      }
      let url = constant.bieudo.thongKeHocLuc;
      api.get(url, params).then(
          response => {
            this.loading.hieuTruong.thongKeHocLuc = false;
            if (response.data.code == 200) {
              this.duLieuBieuDoThongKeHocLuc = response.data.data.duLieu
            } else {
            }
          }
      )
    },

    getDuLieuThongKeHanhKiemTHCSTheoSoPhong() {
      this.loading.capHai.thongKeHanhKiem = true;
      let params = {
        namHoc: this.searchForm.year,
        hocKy: this.hocKy,
        maSo: this.user.maSo,
        maPhong: this.searchForm.pgd,
        maTruong: this.searchForm.maTruong,
        capHoc: 2
      }
      let url = constant.bieudo.thongKeHanhKiemTheoSoPhong;
      api.get(url, params).then(
          response => {
            this.loading.capHai.thongKeHanhKiem = false;
            if (response.data.code == 200) {
              let duLieu = response.data.data.duLieu;
              this.duLieuBieuDo.capHai.thongKeHanhKiem = duLieu
            } else {
            }
          }
      )
    },

    getDuLieuThongKeHocLucTHCSTheoSoPhong() {
      this.loading.capHai.thongKeHocLuc = true;
      let params = {
        namHoc: this.searchForm.year,
        hocKy: this.hocKy,
        maSo: this.user.maSo,
        maPhong: this.searchForm.pgd,
        maTruong: this.searchForm.maTruong,
        capHoc: 2
      }
      let url = constant.bieudo.thongKeHocLucTheoSoPhong;
      api.get(url, params).then(
          response => {
            this.loading.capHai.thongKeHocLuc = false;
            if (response.data.code == 200) {
              let duLieu = response.data.data.duLieu;
              this.duLieuBieuDo.capHai.thongKeHocLuc = duLieu
            } else {
            }
          }
      )
    },

    getDuLieuThongKeHanhKiemTHPTTheoSoPhong() {
      this.loading.capBa.thongKeHanhKiem = true;
      let params = {
        namHoc: this.searchForm.year,
        hocKy: this.hocKy,
        maSo: this.user.maSo,
        maPhong: this.searchForm.pgd,
        maTruong: this.searchForm.maTruong,
        capHoc: 3
      }
      let url = constant.bieudo.thongKeHanhKiemTheoSoPhong;
      api.get(url, params).then(
          response => {
            this.loading.capBa.thongKeHanhKiem = false;
            if (response.data.code == 200) {
              let duLieu = response.data.data.duLieu;
              this.duLieuBieuDo.capBa.thongKeHanhKiem = duLieu
            } else {
            }
          }
      )
    },

    getDuLieuThongKeHocLucTHPTTheoSoPhong() {
      this.loading.capBa.thongKeHocLuc = true;
      let params = {
        namHoc: this.searchForm.year,
        hocKy: this.hocKy,
        maSo: this.user.maSo,
        maPhong: this.searchForm.pgd,
        maTruong: this.searchForm.maTruong,
        capHoc: 3
      }
      let url = constant.bieudo.thongKeHocLucTheoSoPhong;
      api.get(url, params).then(
          response => {
            this.loading.capBa.thongKeHocLuc = false;
            if (response.data.code == 200) {
              let duLieu = response.data.data.duLieu;
              this.duLieuBieuDo.capBa.thongKeHocLuc = duLieu
            } else {
            }
          }
      )
    },

    getDuLieuThongKeDanhGiaKetQuaGiaoDucTheoSoPhong() {
      this.loading.capMot.thongKeKQGD = true;
      let params = {
        namHoc: this.searchForm.year,
        maSo: this.user.maSo,
        maPhong: this.searchForm.pgd,
        maTruong: this.searchForm.maTruong,

      }
      let url = constant.bieudo.thongKeKQGDTheoSoPhong;
      api.get(url, params).then(
          response => {
            this.loading.capMot.thongKeKQGD = false;
            if (response.data.code == 200) {
              let duLieu = response.data.data;
              this.duLieuBieuDo.capMot.duLieuBieuDoThongKeKQGD = response.data.data.duLieu
            } else {
            }
          }
      )
    },

    layDuLieuThongKeDanhGiaKetQuaGiaoDuc() {
      this.loading.giaoVienChuNhiem.thongKeKqgd = true;
      let params = {
        namHoc: this.searchForm.year,
      }
      let url = constant.bieudo.thongKeKQGD;
      api.get(url, params).then(
          response => {
            this.loading.giaoVienChuNhiem.thongKeKqgd = false;
            console.log("Res:")
            console.log(response)
            if (response.data.code == 200) {
              console.log("Biểu đồ thống kê học sinh thi lại:")
              let duLieu = response.data.data;
              this.duLieuBieuDoThongKeKQGD = response.data.data.duLieu
            } else {
            }
          }
      )
    },

    layDuLieuThongKeHocSinhThiLaiHieuTruong() {
      this.loading.hieuTruong.thongKeHocSinhThiLai = true;
      let params = {
        namHoc: this.searchForm.year,
      }
      let url = constant.bieudo.thongKeHocSinhThiLaiHieuTruong;
      api.get(url, params).then(
          response => {
            this.loading.hieuTruong.thongKeHocSinhThiLai = false;
            console.log("Res:")
            console.log(response)
            if (response.data.code == 200) {
              console.log("Biểu đồ thống kê học sinh thi lại:")
              let duLieu = response.data.data;

              let data = duLieu.data;
              let tieuDe = duLieu.tieuDes;

              this.duLieuBieuDoThongKeHocSinhThiLaiHieuTruong = {
                list_lop: tieuDe,
                du_lieu: [
                  {
                    data: data
                  }
                ]
              };
            } else {
            }
          }
      )
    },

    layDuLieuThongKeTrangThaiHocSinhGVCN() {
      this.loading.giaoVienChuNhiem.thongKeTrangThaiHocSinh = true;
      let params = {
        namHoc: this.searchForm.year,
      }
      let url = constant.bieudo.thongKeTrangThaiHocSinhGvcn;
      api.get(url, params).then(
          response => {
            this.loading.giaoVienChuNhiem.thongKeTrangThaiHocSinh = false;
            console.log("Res:")
            console.log(response)
            if (response.data.code == 200) {
              console.log("Biểu đồ thống kê trạng thái học sinh:")
              let duLieu = response.data.data;

              let data = duLieu.data;
              let tieuDe = duLieu.tieuDes;

              this.duLieuBieuDoThongKeTrangThaiHocSinhGvcn = {
                list_lop: tieuDe,
                du_lieu: [
                  {
                    data: data
                  }
                ]
              };
            } else {
            }
          }
      )
    },

    layDuLieuThongKeHocSinhThiLaiGvcn() {
      this.loading.giaoVienChuNhiem.thongKeHocSinhThiLai = true;
      let params = {
        namHoc: this.searchForm.year,
      }
      let url = constant.bieudo.thongKeHocSinhThiLaiGvcn;
      api.get(url, params).then(
          response => {
            this.loading.giaoVienChuNhiem.thongKeHocSinhThiLai = false;
            console.log("Res:")
            console.log(response)
            if (response.data.code == 200) {
              let data = response.data.data.data;
              let tieuDe = response.data.data.tieuDes;

              console.log("data" + data)
              console.log("tieuDe" + tieuDe)
              this.duLieuBieuDoThongKeHocSinhThiLaiGvcn = {
                list_lop: tieuDe,
                du_lieu: [
                  {
                    data: data
                  }
                ]
              };

            } else {
            }
          }
      )
    },

    layDuLieuTrangThaiBieuDoTheoLop() {
      this.loading.giaoVienBoMon.trangThaiHocBaTheoLop = true;
      let params = {
        namHoc: this.searchForm.year,
      }
      let url = constant.bieudo.trangThaiTheoLop;
      api.get(url, params).then(
          response => {
            this.loading.giaoVienBoMon.trangThaiHocBaTheoLop = false;
            console.log("Res:")
            console.log(response)
            if (response.data.code == 200) {
              console.log("Biểu đồ trạng thái học bạ theo lớp:")
              let duLieu = response.data.data;
              console.log(duLieu)
              let chuaKy = duLieu.chuaKys;
              let daKy = duLieu.daKys;
              let tenLop = duLieu.tenLops;
              let duLieuBieuDoTrangThaiHocBaTheoLop = {
                list_lop: tenLop,
                du_lieu: [{
                  name: "Đã ký",
                  data: daKy
                },
                  {
                    name: "Chưa ký",
                    data: chuaKy
                  }
                ]
              };
              this.duLieuBieuDoTrangThaiHocBaTheoLop = duLieuBieuDoTrangThaiHocBaTheoLop;
            } else {
            }
          }
      )
    },

    layDuLieuTrangThaiBieuDoTheoLopGVCNToanTruong() {
      this.loading.hieuTruong.trangThaiHocBaTheoLopGVCNToanTruong = true;
      let params = {
        namHoc: this.searchForm.year,
      }
      let url = constant.bieudo.trangThaiHocBaTheoLopGVCNToanTruong;
      api.get(url, params).then(
          response => {
            this.loading.hieuTruong.trangThaiHocBaTheoLopGVCNToanTruong = false;
            console.log("Res:")
            console.log(response)
            if (response.data.code == 200) {
              console.log("Biểu đồ trạng thái học bạ theo lớp:")
              let duLieu = response.data.data;
              console.log(duLieu)
              let chuaKy = duLieu.chuaKys;
              let daKy = duLieu.daKys;
              let tenLop = duLieu.tenLops;
              let duLieuBieuDoTrangThaiHocBaTheoLopGVCNToanTruong = {
                list_lop: tenLop,
                du_lieu: [{
                  name: "Đã ký",
                  data: daKy
                },
                  {
                    name: "Chưa ký",
                    data: chuaKy
                  }
                ]
              };
              this.duLieuBieuDoTrangThaiHocBaTheoLopGVCNToanTruong = duLieuBieuDoTrangThaiHocBaTheoLopGVCNToanTruong;
            } else {
            }
          }
      )
    },

    thongKeChiTietTrangThaiHocBaGvcn() {
      let params = {
        namHoc: this.searchForm.year,
      }
      let url = constant.bieudo.trangThaiHocBaHocSinhTheoGvcn;
      this.loading.fullScreen = true;
      api.get(url, params).then(
          response => {
            this.loading.fullScreen = false;
            console.log("Res:")
            console.log(response)
            this.danh_sach_trang_thai_ky_hoc_ba_theo_gvcn = [];
            if (response.data.code == 200) {
              this.du_lieu_bieu_do_trang_thai_hoc_ba_theo_gvcn = true;
              this.danh_sach_trang_thai_ky_hoc_ba_theo_gvcn = response.data.data
            } else {
              this.thongBao('error', 'Không lấy được dữ liệu')
            }
          }
      )

    },

    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t
      }
      if (type == "success") {
        cl = "dts-noty-success"
      }
      if (type == "warning") {
        cl = "dts-noty-warning"
      }
      if (type == "error") {
        cl = "dts-noty-error"
      }
      if (type == "info") {
        cl = "dts-noty-info"
      }
      document.querySelector('.overlay').style.display = 'block';
      this.$message({
        onClose: () => {
          document.querySelector('.overlay').style.display = 'none';
        },
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000
      });
    },

    getBieuDoTongQuanGVCNTheoPhongSo() {
      let params = {
        namHoc: this.searchForm.year,
        maSo: this.user.maSo,
        maPhong: this.searchForm.pgd,
        maTruong: this.searchForm.maTruong,
      }
      let url = constant.bieudo.tongQuanTrangThaiHocBaGVCNTheoCap;
      this.loading.tongQuan.tongQuanGVCN = true;
      api.get(url, params).then(
          response => {
            console.log("Res tổng quan gvcn:")
            console.log(response)
            if (response.data.code == 200) {
              let data = response.data.data;

              let chuaKy = data.chuaKys;
              let daKy = data.daKys;
              let tieuDe = data.tieuDes;
              this.duLieuBieuDo.tongQuan.tongQuanGVCN = {
                list_lop: tieuDe,
                du_lieu: [{
                  name: "Đã ký",
                  data: daKy
                },
                  {
                    name: "Chưa ký",
                    data: chuaKy
                  }
                ]
              };
            }
            this.loading.tongQuan.tongQuanGVCN = false;
          }
      )
    },

    getBieuDoTongQuanHieuTruongTheoPhongSo() {
      let params = {
        namHoc: this.searchForm.year,
        maSo: this.user.maSo,
        maPhong: this.searchForm.pgd,
        maTruong: this.searchForm.maTruong,
      }

      let url = constant.bieudo.tongQuanTrangThaiHocBaHieuTruongTheoCap;
      this.loading.tongQuan.tongQuanHieuTruong = true;
      api.get(url, params).then(
          response => {
            console.log("Res tổng quan gvcn:")
            console.log(response)
            if (response.data.code == 200) {
              let data = response.data.data;

              let chuaKy = data.chuaKys;
              let daKy = data.daKys;
              let tieuDe = data.tieuDes;
              this.duLieuBieuDo.tongQuan.tongQuanHieuTruong = {
                list_lop: tieuDe,
                du_lieu: [{
                  name: "Đã ký",
                  data: daKy
                },
                  {
                    name: "Chưa ký",
                    data: chuaKy
                  }
                ]
              };
            }
            this.loading.tongQuan.tongQuanHieuTruong = false;
          }
      )
    },

    getBieuDoTongQuanKyLaiHocBaTheoPhongSo() {
      let params = {
        namHoc: this.searchForm.year,
        maSo: this.user.maSo,
        maPhong: this.searchForm.pgd,
        maTruong: this.searchForm.maTruong,
      }

      let url = constant.bieudo.tongQuanTrangThaiKyLaiHocBa;
      this.loading.tongQuan.trangThaiKyLaiHocBa = true;
      api.get(url, params).then(
          response => {
            console.log("Res tổng quan gvcn:")
            console.log(response)
            if (response.data.code == 200) {
              let data = response.data.data;

              let daKy = data.data;
              let tieuDe = data.tieuDe;
              this.duLieuBieuDo.tongQuan.trangThaiKyLaiHocBa = {
                list_lop: tieuDe,
                du_lieu: [{
                  name: 'Số lượng',
                  data: daKy
                }
                ]
              };
              console.log('&*&*')
              console.log(this.duLieuBieuDo.tongQuan.trangThaiKyLaiHocBa)
            }
            this.loading.tongQuan.trangThaiKyLaiHocBa = false;
          }
      )
    },

    layLaiDuLieuHocBaHocSinhTheoSoPhong() {
      let params = {
        namHoc: this.searchForm.year,
        maSo: this.user.maSo,
        maPhong: this.user.maPhong,
      }
      let dataHocBa = [];
      let dataHocSinh = [];
      let urlHocBa = constant.bieudo.tongQuanHocBaTheoSoPhong;
      let urlHocSinh = constant.bieudo.tongQuanHocSinhTheoSoPhong;
      this.loading.tongQuan.hocBaHocSinh = true;
      api.get(urlHocBa, params).then(
          response => {
            if (response.data.code == 200) {
              dataHocBa = response.data.data.data;
              api.get(urlHocSinh, params).then(
                  response => {
                    if (response.data.code == 200) {
                      dataHocSinh = response.data.data.data;
                      this.loading.tongQuan.hocBaHocSinh = false;
                      this.getBieuDoTrangThaiHocBaHocSinhTheoSoPhong(dataHocBa, dataHocSinh);
                    }
                  }
              )
            }
          }
      );
    },

    getBieuDoTongQuanHocBaTheoSoPhong() {
      let params = {
        namHoc: this.searchForm.year,
        maSo: this.user.maSo,
        maPhong: this.searchForm.pgd,
        maTruong: this.searchForm.maTruong,
      }
      let url = constant.bieudo.tongQuanHocBaTheoSoPhong;
      this.loading.tongQuan.hocBa = true;
      api.get(url, params).then(
          response => {
            console.log("Res tổng quan hiệu trưởng:")
            console.log(response)
            if (response.data.code == 200) {
              let data = response.data.data;
              this.duLieuBieuDo.tongQuan.hocBa = data;
              this.getBieuDoTrangThaiHocBaHocSinhTheoSoPhong(null, null)
            }
            this.loading.tongQuan.hocBa = false;
          }
      )
    },

    getBieuDoTongQuanHocSinhTheoSoPhong() {
      let params = {
        namHoc: this.searchForm.year,
        maSo: this.user.maSo,
        maPhong: this.searchForm.pgd,
        maTruong: this.searchForm.maTruong,
      }
      let url = constant.bieudo.tongQuanHocSinhTheoSoPhong;
      this.loading.tongQuan.hocSinh = true;
      api.get(url, params).then(
          response => {
            console.log("Res tổng quan hiệu trưởng:")
            console.log(response)
            if (response.data.code == 200) {
              let data = response.data.data;
              console.log("------------------------");
              console.log(data)
              this.duLieuBieuDo.tongQuan.hocSinh = data;
              this.getBieuDoTrangThaiHocBaHocSinhTheoSoPhong(null, null)
            }
            this.loading.tongQuan.hocSinh = false;
          }
      )
    },

    getBieuDoTrangThaiHocBaHocSinhTheoSoPhong(hocBa, hocSinh) {
      let hb = null;
      let hs = null;
      if (hocBa) {
        hb = hocBa;
      } else {
        hb = this.duLieuBieuDo.tongQuan.hocBa.data;
      }
      if (hocSinh) {
        hs = hocSinh;
      } else {
        hs = this.duLieuBieuDo.tongQuan.hocSinh.data;
      }
      let data_default = {
        list_lop: ["Tiểu học", "THCS", "THPT"],
        du_lieu: [{
          name: "Học bạ ",
          data: hb
        },
          {
            name: "Học sinh",
            data: hs
          }
        ]
      }
      this.duLieuBieuDo.tongQuan.hocBaHocSinh = data_default;
    },

    xemChiTietTrangThaiHocBaTheoLopMon() {
      let params = {
        namHoc: this.searchForm.year,
      }
      let url = constant.bieudo.trangThaiTheoLopMonHoc;
      this.du_lieu_bieu_do_trang_thai_hoc_ba_theo_lop = [];
      this.loadChiTiet = true;
      api.get(url, params).then(
          response => {
            console.log("CHi tiết trả về:")
            console.log(response)
            if (response.data.code == 200) {
              this.du_lieu_bieu_do_trang_thai_hoc_ba_theo_lop = response.data.data;
            } else {
              this.duLieuBieuDoTrangThaiHocBaTheoMonHoc = {}
            }
            this.loading.fullScreen = false;
            this.loadChiTiet = false;
          }
      )
    },

    getCurrentMonth() {
      let d = new Date();
      return d.getMonth();
    },

    getFullYear() {
      let d = new Date();
      return d.getFullYear();
    },

    getSummaries(param) {
      const {
        columns,
        data
      } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Tổng số';
          return;
        }
        if (index === 1) {
          sums[index] = '';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        } else {
          sums[index] = 'N/A';
        }
      });

      return sums;
    }
  },

  mounted() {
    this.checkTimeThongBao();
    if (this.user && this.user.role == 7) {
      this.getListPGD({
        maSo: this.user.maSo
      });
    }
    if (this.user && this.user.role == 6) {
      this.getListPGD({
        maSo: this.user.maSo
      });
      this.searchForm.pgd = this.user.maPhong;
      this.getListTruong()
    }
    this.maxHeight = this.maxHeight - (this.maxHeight / 4);
    this.searchForm.year = this.getFullYear();
    let currentMonth = this.getCurrentMonth() + 1;
    console.log("Tháng hiện tại:" + currentMonth)
    console.log("Năm hiện tại:" + this.searchForm.year)
    if (currentMonth >= 9) {

    } else {
      this.searchForm.year -= 1;
    }
    this.getRole()
  },

  watch: {},
}
</script>

<style scoped>
.btn__table_action button {
  padding: 8px 10px;
}

.dts-moibieudo {
  display: contents;
  border: 1px solid blue;
}


p {
  padding: 0;
  margin: 0
}

</style>