<template>
    <div class="page__content" v-loading.fullscreen.lock="fullscreenLoading">

        <h5>A. Thông tin học sinh</h5>
        <el-form>
            <div>
                <el-form-item style="width: 220px" class="d-inline-block" prop="maHS">
                    <div>Mã học sinh:</div>
                    <el-input :disabled="checkDisable()" v-model="formHocBa.maHS = getValue('maHS')" placeholder="Nhập"></el-input>
                </el-form-item>
                <el-form-item style="width: 220px" class="d-inline-block" prop="hoten">
                    <div>Họ tên học sinh:</div>
                    <el-input :disabled="checkDisable()" v-model="formHocBa.hoten = getValue('hoten')" placeholder="Nhập"></el-input>
                </el-form-item>
                <el-form-item style="width: 120px" class="d-inline-block">
                    <div>Giới tính:</div>
                    <el-select :disabled="checkDisable()" v-model="formHocBa.gioiTinh = getValue('gioiTinh')" placeholder="Giới tính">
                        <el-option v-for="item in gioiTinh" :label="item.label" :key="item.value"
                                   :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="d-inline-block" prop="year">
                    <div>Ngày sinh:</div>
                    <el-date-picker disabled style="width: 150px" v-model="formHocBa.ngaySinh = getValue('ngaySinh')" format="dd/MM/yyyy" type="date"
                                    placeholder="Ngày sinh" value-format="dd/MM/yyyy">
                    </el-date-picker>
                </el-form-item>
            </div>
            <div>

                <el-form-item class="d-inline-block" prop="namHoc">
                    <div>Năm học:</div>
                    <el-input disabled style="width: 200px" :value="detailHs.namHoc"></el-input>
                </el-form-item>
                <el-form-item style="width: 130px" class="d-inline-block" prop="maLop">
                    <div>Lớp:</div>
                    <el-input disabled :value="detailHs.lop"  placeholder="Chọn lớp"></el-input>
                </el-form-item>
            </div>
        </el-form>

        <el-form :rules="rulesHocBa" ref="formHocBa" :model="formHocBa" :hide-required-asterisk="true">


            <div v-if="!checkDisable()">
                <el-form-item style="width: 400px" class="d-inline-block">
                    <div>Nơi sinh:</div>
                    <el-input type="textarea" v-model="formHocBa.noiSinh = getValue('noiSinh')" placeholder="Nơi sinh"></el-input>
                </el-form-item>
                <el-form-item style="width: 400px" class="d-inline-block">
                    <div>Nơi ở hiện tại:</div>
                    <el-input type="textarea" v-model="formHocBa.noiOHienNay = getValue('noiOHienNay')" placeholder="Nơi ở hiện tại"></el-input>
                </el-form-item>
            </div>
            <div v-if="!checkDisable()">
                <el-form-item style="width: 250px" class="d-inline-block">
                    <div>Họ tên bố:</div>
                    <el-input v-model="formHocBa.hoTenCha = getValue('hoTenCha')" placeholder="Họ tên bố"></el-input>
                </el-form-item>
                <el-form-item style="width: 250px" class="d-inline-block">
                    <div>Nghề nghiệp bố:</div>
                    <el-input v-model="formHocBa.ngheNghiepCha = getValue('ngheNghiepCha')" placeholder="Nghề nghiệp bố"></el-input>
                </el-form-item>
            </div>
            <div v-if="!checkDisable()">
                <el-form-item style="width: 250px" class="d-inline-block">
                    <div>Họ tên mẹ:</div>
                    <el-input v-model="formHocBa.hoTenMe = getValue('hoTenMe')" placeholder="Họ tên mẹ"></el-input>
                </el-form-item>
                <el-form-item style="width: 250px" class="d-inline-block">
                    <div>Nghề nghiệp Mẹ:</div>
                    <el-input v-model="formHocBa.ngheNghiepMe = getValue('ngheNghiepMe')" placeholder="Nghề nghiệp mẹ"></el-input>
                </el-form-item>
            </div>
            <h5>B. Kết quả học tập</h5>
            <div v-for="(item,index) in monHoc">
                <div><strong>{{index+1}}. {{item.monHoc}}</strong></div>
                <div>
                    <el-form-item style="width: 120px" class="d-inline-block">
                        <label>Điểm TB HK I:
                            <el-input v-model="item.diemHocKyI" placeholder="Điểm HK I"></el-input>
                        </label>
                    </el-form-item>
                    <el-form-item style="width: 120px" class="d-inline-block">
                        <label>Điểm TB HK II:
                            <el-input v-model="item.diemHocKyII" placeholder="Điểm HK II"></el-input>
                        </label>
                    </el-form-item>
                    <el-form-item style="width: 120px" class="d-inline-block">
                        <label>Điểm TB cả năm:
                            <el-input v-model="item.diemCaNam" placeholder="Điểm cả năm"></el-input>
                        </label>
                    </el-form-item>
                    <el-form-item class="d-inline-block">
                        <label>Điểm KT lại hoặc xếp loại sau KT lại:
                            <el-input v-model="item.diemThiLai" placeholder="Điểm KT lại"></el-input>
                        </label>
                    </el-form-item>
                </div>
            </div>

            <div>
                <h5>C. Tổng kết cả năm</h5>
                <div>
                    <el-form-item style="width: 120px" class="d-inline-block">
                        <label>Điểm TB HK I:
                            <el-input v-model="formHocBa.diemTBHKI" placeholder="Điểm TB HK I"></el-input>
                        </label>
                    </el-form-item>
                    <el-form-item style="width: 120px" class="d-inline-block">
                        <label>Điểm TB HK II:
                            <el-input v-model="formHocBa.diemTBHKII" placeholder="Điểm TB HK II"></el-input>
                        </label>
                    </el-form-item>
                    <el-form-item style="width: 120px" class="d-inline-block">
                        <label>Điểm TB cả năm:
                            <el-input v-model="formHocBa.diemTBCaNam" placeholder="Điểm TB cả năm"></el-input>
                        </label>
                    </el-form-item>
                    <el-form-item class="d-inline-block">
                        <label>Điểm trung bình sau KT lại:
                            <el-input v-model="formHocBa.diemThiLai" placeholder="Điểm TB sau thi lại"></el-input>
                        </label>
                    </el-form-item>
                </div>
                <div>
                    <el-form-item style="width: 120px" class="d-inline-block">
                        <label>Học lực HK I:
                            <el-input v-model="formHocBa.hocLucHKI" placeholder="Học lực HK I"></el-input>
                        </label>
                    </el-form-item>
                    <el-form-item style="width: 120px" class="d-inline-block">
                        <label>Học lực HK II:
                            <el-input v-model="formHocBa.hocLucHKII" placeholder="Học lực HK II"></el-input>
                        </label>
                    </el-form-item>
                    <el-form-item style="width: 120px" class="d-inline-block">
                        <label>Học lực cả năm:
                            <el-input v-model="formHocBa.hocLucCN" placeholder="Học lưc cả năm"></el-input>
                        </label>
                    </el-form-item>
                    <el-form-item class="d-inline-block">
                        <label>Học lực sau KT lại:
                            <el-input v-model="formHocBa.hocLucThiLai" placeholder="Học lực sau thi lại"></el-input>
                        </label>
                    </el-form-item>
                </div>
                <div>
                    <el-form-item style="width: 120px" class="d-inline-block">
                        <label>Hạnh kiểm HK I:
                            <el-input v-model="formHocBa.hanhKiemHKI" placeholder="Hạnh kiểm"></el-input>
                        </label>
                    </el-form-item>
                    <el-form-item style="width: 120px" class="d-inline-block">
                        <label>Hạnh kiểm HK II:
                            <el-input v-model="formHocBa.hanhKiemHKII" placeholder="Hạnh kiểm HK II"></el-input>
                        </label>
                    </el-form-item>
                    <el-form-item style="width: 120px" class="d-inline-block">
                        <label>Hạnh kiểm cả năm:
                            <el-input v-model="formHocBa.hanhKiemCaNam" placeholder="Hạnh kiểm cả năm"></el-input>
                        </label>
                    </el-form-item>
                    <el-form-item class="d-inline-block">
                        <label>Hạnh kiểm sau KT lại:
                            <el-input v-model="formHocBa.hanhKiemThiLai" placeholder="Hạnh kiểm sau thi lại"></el-input>
                        </label>
                    </el-form-item>
                </div>
            </div>
            <div class="button mb-5">
                <el-button @click="closeEdit">Quay lại</el-button>
                <el-button type="primary" @click="taoHocBa('formHocBa')">Cập nhật học bạ</el-button>
            </div>
        </el-form>
        <dialog-alert title="Thông báo" :message="messageAlert" :show-dialog="showAlert" @closeDialog="closeAlert"></dialog-alert>

    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import dialog from "../../dialog";
import DialogAlert from "../../components/DialogAlert";

export default {
    name: "TrungHoc",
    components : {
        DialogAlert,
    },
    computed: {
        ...mapState('teacher', [
            "listLop",

        ]),
        ...mapState('account', [
            'user'
        ]),
        ...mapState('hocba', [
            "createStatus",
            "updateStatus",
            "detailHocBa",
            "detailHs"
        ])
    },
    data: () => {
        return {
            listLopThieu : [],
            messageAlert : '',
            showAlert : false,
            gioiTinh: [
                {value: '', label: 'Giới tính'},
                {value: 0, label: 'Nữ'},
                {value: 1, label: 'Nam'}
            ],
            fullscreenLoading: false,
            formHocBa: {
                id : '',
                capHoc: '',
                khoiHoc: '',
                namHoc: '',
                maLop: '',
                maHS: '',
                hoTenMe: '',
                ngheNghiepCha: "",
                ngheNghiepMe: "",
                ngheNghiepNguoiGiamHo: "",
                hoTenCha: '',
                gioiTinh: '',
                hoten: '',
                hanhKiemCaNam: "",
                hanhKiemHKI: "",
                hanhKiemHKII: "",
                hanhKiemThiLai: "",
                hocLucCN: "",
                hocLucHKI: "",
                hocLucHKII: "",
                hocLucThiLai: "",
                diemTBHKI: "",
                diemTBHKII: "",
                diemTBCaNam: "",
                ngaySinh: "",
                noiOHienNay: "",
                noiSinh: "",
                ketQuaHocTaps: [],
                diemThiLai : ""
            },
            monHoc: [
                {
                    diemCaNam: "",
                    diemHocKyI: "",
                    diemHocKyII: "",
                    diemThiLai: "",
                    maMonHoc: "TOAN",
                    monHoc: "Toán"
                },
                {
                    diemCaNam: "",
                    diemHocKyI: "",
                    diemHocKyII: "",
                    diemThiLai: "",
                    maMonHoc: "VATLY",
                    monHoc: "Vật lý"
                },
                {
                    maMonHoc: "TINHOC",
                    monHoc: "Tin học",
                    diemCaNam: "",
                    diemHocKyI: "",
                    diemHocKyII: "",
                    diemThiLai: "",
                },
                {
                    maMonHoc: "NGUVAN",
                    monHoc: "Ngữ văn",
                    diemCaNam: "",
                    diemHocKyI: "",
                    diemHocKyII: "",
                    diemThiLai: "",
                },
                {
                    maMonHoc: "SINHHOC",
                    monHoc: "Sinh học",
                    diemCaNam: "",
                    diemHocKyI: "",
                    diemHocKyII: "",
                    diemThiLai: "",
                },
                {
                    maMonHoc: "LICHSU",
                    monHoc: "Lịch sử",
                    diemCaNam: "",
                    diemHocKyI: "",
                    diemHocKyII: "",
                    diemThiLai: "",
                },
                {
                    maMonHoc: "DIALY",
                    monHoc: "Địa lý",
                    diemCaNam: "",
                    diemHocKyI: "",
                    diemHocKyII: "",
                    diemThiLai: "",
                },
                {
                    maMonHoc: "NGOAINGU",
                    monHoc: "Ngoại ngữ",
                    diemCaNam: "",
                    diemHocKyI: "",
                    diemHocKyII: "",
                    diemThiLai: "",
                },

                {
                    maMonHoc: "GDCD",
                    monHoc: "GDCD",
                    diemCaNam: "",
                    diemHocKyI: "",
                    diemHocKyII: "",
                    diemThiLai: "",
                },
                {
                    maMonHoc: "CONGNGHE",
                    monHoc: "Công nghệ",
                    diemCaNam: "",
                    diemHocKyI: "",
                    diemHocKyII: "",
                    diemThiLai: "",
                },
                {
                    maMonHoc: "THEDUC",
                    monHoc: "Thể dục",
                    diemCaNam: "",
                    diemHocKyI: "",
                    diemHocKyII: "",
                    diemThiLai: "",
                },
                {
                    maMonHoc: "AMNHAC",
                    monHoc: "Âm nhạc",
                    diemCaNam: "",
                    diemHocKyI: "",
                    diemHocKyII: "",
                    diemThiLai: "",
                },
                {
                    maMonHoc: "MYTHUAT",
                    monHoc: "Mỹ thuật",
                    diemCaNam: "",
                    diemHocKyI: "",
                    diemHocKyII: "",
                    diemThiLai: "",
                },
            ],

            rulesHocBa: {
                khoiHoc: [{
                    required: true,
                    message: 'Chưa chọn khối học',
                    trigger: 'change'
                }],
                maHS: [{
                    required: true,
                    message: 'Chưa nhập mã học sinh',
                    trigger: 'change'
                }],
                maLop: [{
                    required: true,
                    message: 'Chưa chọn lớp học',
                    trigger: 'change'
                }],
                hoten: [{
                    required: true,
                    message: 'Chưa thêm tên học sinh',
                    trigger: 'change'
                }],
                namHoc: [{
                    required: true,
                    message: 'Chưa chọn năm học',
                    trigger: 'change'
                }]
            },
            optionEducation: [
                {
                    value: 1,
                    label: 'Cấp 1'
                },
                {
                    value: 2,
                    label: 'Cấp 2'
                },
                {
                    value: 3,
                    label: 'Cấp 3'
                },
            ],
            optionGrade: [],
            khoiCap1: [{
                value: 1,
                label: 'Khối 1'
            },
                {
                    value: 2,
                    label: 'Khối 2'
                },
                {
                    value: 3,
                    label: 'Khối 3'
                },
                {
                    value: 4,
                    label: 'Khối 4'
                },
                {
                    value: 5,
                    label: 'Khối 5'
                },
            ],
            khoiCap2: [{
                value: 6,
                label: 'Khối 6'
            },
                {
                    value: 7,
                    label: 'Khối 7'
                },
                {
                    value: 8,
                    label: 'Khối 8'
                },
                {
                    value: 9,
                    label: 'Khối 9'
                },
            ],
            khoiCap3: [{
                value: 10,
                label: 'Khối 10'
            },
                {
                    value: 11,
                    label: 'Khối 11'
                },
                {
                    value: 12,
                    label: 'Khối 12'
                },

            ],
        }
    },
    methods: {
        ...mapActions('teacher', [
            "getListLop",
            "clear",
        ]),
        ...mapActions('hocba', [
            "updateHocBaTrungHoc",
            "setDataHocSinh"
        ]),

        closeAlert(){
            this.showAlert = false;
            this.$emit('close');
        },
        closeEdit(){
            this.$emit('close');
        },
        getKhoiHoc() {
            if (this.user.capHoc == 1) {
                this.optionGrade = this.khoiCap1;
            } else if (this.user.capHoc == 2) {
                this.optionGrade = this.khoiCap2;
            } else if (this.user.capHoc == 3) {
                this.optionGrade = this.khoiCap3;
            } else {
                this.optionGrade = this.khoiCap1.concat(this.khoiCap2, this.khoiCap3)
            }
        },

        getlistLopHoc() {
            this.getListLop({
                start: 0,
                limit: 999,
                khoiHoc: this.detailHs.khoiHoc,
                // namHoc : this.searchForm.year,
                // lopHoc : this.searchForm.class
            })
        },
        clearDiem(){
            this.formHocBa.diemTBCaNam = '';
            this.formHocBa.diemTBHKI = '';
            this.formHocBa.diemTBHKII = '';
            this.formHocBa.diemThiLai = '';
            this.formHocBa.hocLucCN = '';
            this.formHocBa.hocLucHKI = '';
            this.formHocBa.hocLucHKII = '';
            this.formHocBa.hocLucThiLai = '';
            this.formHocBa.hanhKiemHKI = '';
            this.formHocBa.hanhKiemHKII = '';
            this.formHocBa.hanhKiemThiLai = '';
            this.formHocBa.hanhKiemCaNam = '';

            this.monHoc.map((item) => {
                item.diemCaNam = "";
                item.diemHocKyI = "";
                item.diemHocKyII = "";
                item.diemThiLai = "";
                return item
            })
        },
        taoHocBa(formName) {

            this.fullscreenLoading = true;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let param = this.formHocBa;
                    param.id = this.detailHs.id;
                    param.ketQuaHocTaps = Object.values(this.monHoc);
                    this.updateHocBaTrungHoc(param);
                } else {
                    this.fullscreenLoading = false;
                    this.messageAlert = 'Vui lòng kiểm tra lại các trường thông tin.'
                    this.showAlert = true
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        getValueLop(key){
            let item = this.listLopThieu[0];
            this.formHocBa.maLop = item.maLop;
            this.getNamHoc();
        },
        getNamHoc(){
            let item = this.detailHs.lops;
            let namHoc = '';

            item.map((o)=> {
                if (o.maLop == this.formHocBa.maLop){
                    namHoc = o.namHoc;
                }
                return o;
            });
            this.formHocBa.namHoc = namHoc.toString();
        },
        getValue(key){
            let item = this.detailHs;
            if (item == null){
                return '';
            }
            return item[key];
        },
        checkDisable(){
            let item = this.detailHs;
            if (item == null){
                return false;
            }else{
                return true
            }
        },
        lopChange(){
            this.getNamHoc();
        },
        removeLop(){
            let listLop = this.listLopThieu;
            _.remove(listLop,(item) => {
                return item.maLop == this.formHocBa.maLop;
            })
            if (listLop.length){
                this.listLopThieu = listLop;
                this.getValueLop('maLop');
                $('.add-hocba').animate({ scrollTop: 0 });
            }else{
                this.$refs['formHocBa'].resetFields();
                this.clearDiem();
                this.$emit('close')
            }

        }
    },
    beforeMount() {
        this.getlistLopHoc();
        this.formHocBa = this.detailHs;
        this.monHoc = this.detailHs.ketQuaHocTaps;

    },
    mounted() {


    },
    watch: {
        updateStatus(val) {
            if (val) {
                this.fullscreenLoading = false;
                this.showAlert = true;
                this.messageAlert = val.message;
                if (val.status=='success'){
                    this.setDataHocSinh(data);
                }
                this.$refs['formHocBa'].resetFields();
                this.clearDiem();
            }
        },

    }
}
</script>

<style scoped>

</style>
