var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "page page-file file detailHocBa",
      attrs: { id: "detailHocBa" },
    },
    [
      _c(
        "div",
        { ref: "upload_file_hdsd", attrs: { id: "upload_file_hdsd" } },
        [
          _c("div", {}, [
            _c("div", { staticClass: "box" }, [
              _c("div", { staticClass: "box-body" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-3" },
                    [
                      _c("label", [_vm._v("Path Module")]),
                      _vm._v(" "),
                      _c("el-input", {
                        model: {
                          value: _vm.module_choose,
                          callback: function ($$v) {
                            _vm.module_choose = $$v
                          },
                          expression: "module_choose",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-2" },
                    [
                      _c("label", [_vm._v("Số dòng hiển thị")]),
                      _vm._v(" "),
                      _c("el-input-number", {
                        staticStyle: { display: "block" },
                        attrs: {
                          min: 100,
                          placeholder: "Số dòng cần xem",
                          step: 50,
                        },
                        model: {
                          value: _vm.num,
                          callback: function ($$v) {
                            _vm.num = $$v
                          },
                          expression: "num",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-3" }, [
                    _c(
                      "label",
                      {
                        staticStyle: { color: "transparent", display: "block" },
                      },
                      [_vm._v("Số dòng hiển thị")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.btnSearchPackage()
                          },
                        },
                      },
                      [_vm._v("View log\n                            ")]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "row", staticStyle: { "margin-top": "10px" } },
                  [
                    _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [
                        _c("VueCodeEditor", {
                          attrs: {
                            theme: "monokai",
                            width: "100%",
                            height: "750px",
                            options: {
                              fontSize: 14,
                              useWorker: true,
                              showLineNumbers: true,
                              tabSize: 2,
                              showPrintMargin: false,
                              showGutter: true,
                              enableSnippets: false,
                            },
                            commands: [
                              {
                                name: "save",
                                bindKey: { win: "Ctrl-s", mac: "Command-s" },
                                exec: null,
                                readOnly: true,
                              },
                            ],
                          },
                          on: { input: _vm.editChange, init: _vm.editorInit },
                          model: {
                            value: _vm.item,
                            callback: function ($$v) {
                              _vm.item = $$v
                            },
                            expression: "item",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }