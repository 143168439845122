<template>
  <div class="page">
    <div class="page__title d-flex align-items-center justify-content-between">
      <h1 class="title">Thống kê truy cập</h1>
    </div>
    <div class="page__header">
      <el-row :gutter="24">
        <el-col :span="6">
          <label>Giáo viên</label>
          <el-select clearable v-if="listGiaoVien && listGiaoVien.list" v-model="formSearch.gvId" style="width: 100%"
                     filterable placeholder="-- Chọn --">
            <!--                        <el-option label="Chọn" value=""></el-option>-->
            <el-option v-for="item in listGiaoVien.list" :label="item.tenGV" :value="item.id"
                       :key="item.id"></el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <label>Thời gian</label>
          <el-date-picker
              format="dd/MM/yyyy"
              style="width: 100%"
              value-format="timestamp"
              v-model="formSearch.date"
              type="daterange"
              align="right"
              start-placeholder="Từ ngày"
              end-placeholder="Đến ngày">
          </el-date-picker>
        </el-col>
        <el-col :span="4">
          <div style="color: transparent">Tìm kiếm</div>
          <el-button class="m-0" type="primary" @click="onSubmit">Thống kê</el-button>
        </el-col>
      </el-row>
      <el-form v-if="false" label-position="top" :inline="true" size="small" :model="formSearch">
        <el-form-item :lg="4" label="Giáo viên">
          <el-select v-if="listGiaoVien && listGiaoVien.list" v-model="formSearch.gvId" filterable
                     placeholder="--Chọn--">
            <!--                        <el-option label="&#45;&#45;Chọn giáo viên&#45;&#45;" value=""></el-option>-->
            <el-option v-for="item in listGiaoVien.list" :label="item.tenGV" :value="item.id"
                       :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :lg="4" label="Khoảng thời gian">
          <el-date-picker
              format="dd/MM/yyyy"
              value-format="timestamp"
              v-model="formSearch.date"
              type="daterange"
              align="right"
              start-placeholder="Từ ngày"
              end-placeholder="Đến ngày">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button style="margin-top:30px  " type="primary" size="small" @click="onSubmit">Thống kê</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="page__container">

      <el-card shadow="never">
        <h5>Lượt truy cập</h5>
        <div id="chart">
          <apexchart v-if="chartOptions" type="line" height="350" :options="chartOptions" :series="series"></apexchart>
        </div>
      </el-card>

      <el-card shadow="never" class="mt-3">
        <h5 class="title">Lịch sử truy cập</h5>
        <el-table border fit :lazy="true" :data="history_access">
          <el-table-column label="STT" type="index" :index="indexMethod" align="center">
          </el-table-column>
          <el-table-column prop="tenGV" label="Họ và tên" header-align="center" align="left"></el-table-column>
          <el-table-column prop="action" label="Hành động" align="center"></el-table-column>
          <el-table-column prop="description" label="Mô tả" align="center"></el-table-column>
          <el-table-column label="Thời gian" align="center">
            <template slot-scope="scope">
              {{ new Date(scope.row.createdDate) | moment("DD/MM/YYYY HH:mm:ss") }}
            </template>
          </el-table-column>
        </el-table>
        <el-row :gutter="20">
          <el-col :span="8">
            <chonSoLuong @chonXongSoLuong="ChonSoLuongBanGhi($event)"></chonSoLuong>
          </el-col>
          <el-col :span="16" class="text-right">
            <small class="pd-r-10">
              <i>Danh sách có {{ total }} bản ghi </i>
            </small>
            <pagination :value="paginate.page"
                        :page-count="getTotalPage(total)" :click-handler="getLichSu"
                        :prev-text="'Trước'"
                        :next-text="'Sau'" :container-class="'el-pager'" :pageClass="'number'">

            </pagination>
          </el-col>
        </el-row>
<!--        <div class="text-right mt-2">-->



<!--          <pagination v-if="history_access.length" :page-count="getTotalPage(total)" v-model="paginate.page"-->
<!--                      :initial-page="paginate.page" :click-handler="getLichSu" :prev-text="'Trước'"-->
<!--                      :next-text="'Sau'"-->
<!--                      :container-class="'el-pager'" :pageClass="'number'">-->

<!--          </pagination>-->
<!--        </div>-->


      </el-card>

    </div>
  </div>
</template>

<script>
import constant_api from "../_helpers/constant_api";
import api from "../_helpers/api";
import {mapActions, mapState} from "vuex";
import ApexCharts from 'vue-apexcharts';
import Pagination from "../components/Pagination";
import chonSoLuong from "./Ui/ChonSoLuong.vue";

export default {
  name: "ThongKeTruyCap",
  metaInfo: {
    title: 'Thống kê truy cập',
    meta: [
      {vmid: 'description', name: 'description', content: ''}
    ],
  },
  components: {
    chonSoLuong,
    apexchart: ApexCharts,
    pagination: Pagination,
  },
  data() {
    return {
      total: 0,
      history_access: [],
      formSearch: {
        gvId: '',
        date: []
      },
      paginate: {
        start: 0,
        limit: 50,
        page: 1,
      },
      series: [{
        name: "Số lượt",
        data: []
      }],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },

        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: [],
        }
      },
    }
  },
  computed: {
    ...mapState('teacher', [
      "listGiaoVien",
    ]),
    ...mapState('account', [
      'user'
    ]),
  },
  methods: {
    ...mapActions('teacher', [
      "getListGiaoVien",
    ]),
    getTotalPage(number) {
      return Math.ceil(number / (this.paginate.limit));
    },
    onSubmit() {
      console.log(this.formSearch.date)
      if (!this.formSearch.date.length) {
        this.$alert('Vui lòng khoảng thời gian', 'Thông báo', {
          confirmButtonText: 'Đóng',
          type: 'warning',
          callback: action => {

          }
        });
        return;
      }
      this.getTruyCap();
      this.getLichSu()
    },
    indexMethod(index) {
      return (this.paginate.start + index) + 1;
    },
    getTruyCap() {

      let uri = constant_api.giaovien.thongKeTruyCap;
      let params = {
        dateTo: this.formSearch.date[1],
        dateFrom: this.formSearch.date[0],
        id: this.formSearch.gvId
      }
      api.get(uri, params).then(response => {
        console.log('res:')
        console.log(response)
        if (response.data.code == 200) {
          console.log(response.data.data.date)

          this.series = [{
            data: response.data.data.data
          }]
          this.chartOptions = {
            ...this.chartOptions, ...{
              xaxis: {
                categories: response.data.data.date
              }
            }
          };
        }
      }).catch((e) => {

      })


    },

    ChonSoLuongBanGhi(e) {
      console.log('ChonSoLuongBanGhi');
      console.log(e)
      this.showDialogOtp = false;
      this.paginate.start = 0;
      this.paginate.limit = e.soluong;
      this.paginate.current = 1;
      this.getLichSu(1);
    },
    getLichSu(select) {
      this.paginate.start = (select - 1) * this.paginate.limit ? (select - 1) * this.paginate.limit : 0;
      this.paginate.page = select;
      let uri = constant_api.giaovien.lichSuHoatDong;
      let params = {
        start: this.paginate.start,
        limit: this.paginate.limit,
        id: this.formSearch.gvId,
        dateTo: this.formSearch.date[1],
        dateFrom: this.formSearch.date[0],
      }
      api.get(uri, params).then(response => {
        if (response.data.code == 200) {
          this.history_access = response.data.data.list.length ? response.data.data.list : [];
          this.total = response.data.data.total
        }
      }).catch((e) => {

      })

    }
  },
  mounted() {
    let now = new Date();
    let myPastDate = new Date(now);
    myPastDate.setDate(myPastDate.getDate() - 7);
    this.formSearch.date = [myPastDate.getTime(), now.getTime()]
    this.getListGiaoVien({
      start: 0,
      limit: 500,
      maTruong: this.user.maTruong,
    })
    this.onSubmit();
  }
}
</script>

<style scoped>

</style>