import api from "../api";
import {sprintf} from "sprintf-js";
import restApi from "../_helpers/rest_api";
import rApi from "../_helpers/api";

export const hocbaService = {
    getDetail,
    getDetailHocBa,
    getFile,
    getDetailHocBaPdfPost,
    getDetailHocBaPdf,
    createHocBaTrungHoc,
    createHocBaTieuHoc,
    updateHocBaTrungHoc,
    getListHocBa,
    getThongTinHocBa,
    layDiemTongKetMon,
    layBieuDoTrangThaiHocBaTheoLop,
    layBieuDoTrangThaiHocBaTheoGiaoVien,
    layBieuDoTrangThaiHocBaTheoMonHoc,
    layChiTietBieuDoTrangThaiTheoLopMon,
    layBieuDoTrangThaiHocBaTongQuan,
    layBieuDoTrangThaiHocBaTongQuanHieuTruong,
    layBieuDoHocBaHocSinhHieuTruong,
    layBieuDoHocBaHocSinhGvcn,
    layChiTietTrangThaiHocBaTheoLop,
    layChiTietTrangThaiHocBaTheoGVCN,
    permission,
    getHistory
};

function getDetail(data){
    let uri = api.hocba.getDetail;
    return restApi.get(uri,data);
}

function getHistory(data){
    let uri = api.hocba.getHistory;
    return restApi.get(uri,data);
}

function permission(data){

    // type : 1-Bộ môn,2-Chủ nhiệm,3-hiệu trưởng
    // id : id học bạ
    // maMonHoc
    // ghiChu
    let uri = api.hocba.permission;
    return restApi.post(uri,data);
}


function getDetailHocBa(data){
    let uri = api.hocba.detail;
    return restApi.get(uri,data);
}

function getFile(data){
    let uri = api.hocba.getFile;
    return restApi.get(uri,data)
}
function getDetailHocBaPdf(data){
    let uri = api.hocba.detailPdf;
    return restApi.get(uri,data);
}

function getDetailHocBaPdfPost(data){
    let uri = api.hocba.detailPdf;
    return rApi.post(uri,data);
}

function createHocBaTieuHoc(data){
    let uri = api.hocba.createHocBaTieuHoc;
    return restApi.post(uri,data);
}

function createHocBaTrungHoc(data){
    let uri = api.hocba.createHocBaTrungHoc;
    return restApi.post(uri,data);
}

function updateHocBaTrungHoc(data){
    let uri = api.hocba.updateHocBaTrungHoc;
    return restApi.post(uri,data);
}

function getListHocBa(data){
    let uri = api.hocba.listHocba;
    return restApi.get(uri,data);
}
function layDiemTongKetMon(data){
    let uri = api.hocba.layDiemTongKet;
    return restApi.get(uri,data);
}
function layBieuDoTrangThaiHocBaTheoLop(data){
    let uri = api.bieudo.trangThaiTheoLop;
    return restApi.get(uri,data);
}
function layChiTietTrangThaiHocBaTheoGVCN(data){
    let uri = api.bieudo.chiTietTrangThaiHocBaTheoGVCN;
    return restApi.get(uri,data);
}
function layBieuDoTrangThaiHocBaTheoGiaoVien(data){
    let uri = api.bieudo.trangThaiTheoGiaoVien;
    return restApi.get(uri,data);
}
function layBieuDoTrangThaiHocBaTheoMonHoc(data){
    let uri = api.bieudo.trangThaiTheoMonHoc;
    return restApi.get(uri,data);
}
function layChiTietBieuDoTrangThaiTheoLopMon(data){
    let uri = api.bieudo.trangThaiTheoLopMonHoc;
    return restApi.get(uri,data);
}
function layBieuDoTrangThaiHocBaTongQuan(data){
    let uri = api.bieudo.tongQuan;
    return restApi.get(uri,data);
}
function layChiTietTrangThaiHocBaTheoLop(data){
    let uri = api.bieudo.trangThaiHocBaTheoLop;
    return restApi.get(uri,data);
}
function layBieuDoTrangThaiHocBaTongQuanHieuTruong(data){
    let uri = api.bieudo.tongQuanHieuTruong;
    return restApi.get(uri,data);
}
function layBieuDoHocBaHocSinhHieuTruong(data){
    let uri = api.bieudo.hocBaHieuTruong;
    return restApi.get(uri,data);
}
function layBieuDoHocBaHocSinhGvcn(data){
    let uri = api.bieudo.hocBaGvcn;
    return restApi.get(uri,data);
}
function getThongTinHocBa(data){
    let uri = api.hocba.hocBaDetail;
    return restApi.get(uri,data);
}