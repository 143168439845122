<template>
  <div>

    <el-button @click="print">In thử</el-button>
    <div style=" font-family: 'Times New Roman', Times, serif;  padding-top: 25px" ref="printContent">
      <div v-if="false"
           style=" font-family: 'Times New Roman', Times, serif;width: 210mm; height: 297mm;  border: 1px solid black;margin: 0 auto">
        <div class="bia"
             style=" font-family: 'Times New Roman', Times, serif;border: 4px double black; width: 190mm; height: 277mm; margin: 10mm auto; position: relative ">
          <div class="tenTruong" style=" font-family: 'Times New Roman', Times, serif;margin: 10mm 15mm">
            <h6 style=" font-family: 'Times New Roman', Times, serif;font-size: 18px">TRƯỜNG:
              {{ thongTinHocBaBia.tenTruong }}</h6>
            <h6 style=" font-family: 'Times New Roman', Times, serif;font-size: 18px">Huyện/Quận/Thị xã/Thành phố:
              {{ thongTinHocBaBia.tenHuyen }}</h6>
            <h6 style=" font-family: 'Times New Roman', Times, serif;font-size: 18px">Tỉnh/Thành phố:
              {{ thongTinHocBaBia.tenTinh }}</h6>
          </div>
          <div class="logoBiaHocBa"
               style=" font-family: 'Times New Roman', Times, serif;text-align: center;padding-top: 50mm">
            <h1 style=" font-family: 'Times New Roman', Times, serif;font-size: 90px">HỌC BẠ</h1>
            <h4>TRUNG HỌC CƠ SỞ</h4>
          </div>
          <div class="tenHocSinh"
               style=" font-family: 'Times New Roman', Times, serif;text-align: center;padding-top: 50mm">
            <h6 style=" font-family: 'Times New Roman', Times, serif;font-size: 18px">Họ và tên học sinh</h6>
            <h6 style=" font-family: 'Times New Roman', Times, serif;font-size: 18px">{{ thongTinHocBaBia.tenHS }}</h6>
          </div>
          <div class="tenHocSinh"
               style=" font-family: 'Times New Roman', Times, serif;text-align: center; position: absolute; bottom: 0; width: 100%">
            <h6>Sổ đăng bộ PCGD: {{ thongTinHocBaBia.soDangBo }}</h6>
          </div>
        </div>
      </div>
      <div v-if="false"
           style=" font-family: 'Times New Roman', Times, serif;width: 210mm; height: 297mm;  border: 1px solid black;margin: 0 auto">
        <div class="bia"
             style=" font-family: 'Times New Roman', Times, serif;width: 190mm; height: 277mm; margin: 10mm auto; position: relative ">
          <div class="text-center">
            <h4>HƯỚNG DẪN SỬ DỤNG HỌC BẠ</h4>
          </div>
          <div>
            <h6 style=" font-family: 'Times New Roman', Times, serif;padding-top: 25px;font-size: 16px">1. Quy định
              chung</h6>
            <p>- Học bạ học sinh được nhà trường quản lý và bảo quản trong trường; đầu năm học, cuối học kỳ, cuối
              năm học, được bàn giao cho giáo viên chủ nhiệm lớp để thực hiện việc ghi vào Học bạ và thu lại sau
              khi đã hoàn thành.</p>
            <p>- Nội dung trang 1 phải được ghi đầy đủ khi xác lập Học bạ; Hiệu trưởng ký, đóng dấu xác nhận quá
              trình học tập từng năm học từ lớp 6 đến lớp 9.</p>
            <h6 style=" font-family: 'Times New Roman', Times, serif;padding-top: 25px; font-size: 16px">2. Giáo viên
              môn học</h6>
            <p>- Ghi điểm trung bình môn học hoặc mức đánh giá kết quả học tập theo môn học từng học kì, cả năm
              học; nhận xét sự tiến bộ, ưu điểm nổi bật, hạn chế chủ yếu (nếu có) của học sinh.</p>
            <p>- Khi sửa chữa (nếu có), dùng bút mực đỏ gạch ngang nội dung cũ, ghi nội dung mới vào phía trên
              bên phải vị trí ghi nội dung cũ, ký xác nhận về việc sửa chữa bên cạnh nội dung đã sửa.</p>
            <h6 style=" font-family: 'Times New Roman', Times, serif;padding-top: 25px;font-size: 16px">3. Giáo viên chủ
              nhiệm</h6>
            <p>- Tiếp nhận và bàn giao lại Học bạ học sinh với văn phòng nhà trường.</p>
            <p>- Đôn đốc việc ghi vào Học bạ điểm trung bình môn học hoặc mức đánh giá kết quả học tập của học
              sinh của giáo viên môn học.</p>
            <p>- Ghi đầy đủ các nội dung trên trang 1, nội dung ở phần đầu các trang tiếp theo, nhận xét kết quả rèn
              luyện và học tập của học sinh theo từng năm học.</p>
            <p>- Ghi kết quả tổng hợp đánh giá; mức đánh giá lại môn học hoặc rèn luyện thêm trong kì nghỉ hè
              (nếu có); đánh giá mức độ hoàn thành nhiệm vụ đối với học sinh phải rèn luyện thêm trong kì nghỉ
              hè (nếu có).</p>
            <p>- Ghi rõ được lên lớp hoặc không được lên lớp; hoàn thành hoặc chưa hoàn thành chương trình trung
              học cơ sở; chứng chỉ (nếu có), kết quả tham gia các cuộc thi (nếu có), khen thưởng (nếu có).</p>
            <p>- Ghi nhận xét sự tiến bộ, ưu điểm nổi bật, hạn chế chủ yếu và những biểu hiện nổi bật của học sinh
              trong quá trình rèn luyện và học tập; những vấn đề cần quan tâm giúp đỡ thêm trong quá trình rèn
              luyện và học tập.</p>
            <h6 style=" font-family: 'Times New Roman', Times, serif;padding-top: 25px; font-size: 16px">4. Hiệu
              trưởng</h6>
            <p>- Phê duyệt Học bạ của học sinh khi kết thúc năm học.</p>
            <p>- Kiểm tra việc quản lý, bảo quản, ghi Học bạ.</p>
          </div>
        </div>
      </div>
      <!--      trang học bạ-->
      <div v-if="true"
           style=" font-family: 'Times New Roman', Times, serif;width: 210mm; height: 297mm;  border: 1px solid black;margin: 0 auto">
        <div class="biaHocBa"
             style=" font-family: 'Times New Roman', Times, serif;width: 190mm; height: 277mm; margin: 10mm auto; position: relative ">
          <div style=" font-family: 'Times New Roman', Times, serif;position: relative">
            <div class="anhHs" style=" font-family: 'Times New Roman', Times, serif;position: absolute; top:40px">
              <img :src="thongTinHocBaBia.anhTuQLNT?thongTinHocBaBia.anhTuQLNT:''"
                   style=" font-family: 'Times New Roman', Times, serif;width: 40mm; height: 60mm" alt="">
              <img style=" width: 111px; position: absolute;left: 80px;top: 130px;"
                   src="https://huviron-static.dtsgroup.com.vn//dau/7900001_1713932530683.png" alt="">
            </div>
            <div
                style=" font-family: 'Times New Roman', Times, serif;text-align: center;position: absolute;width: 100%;top: 0">
              <div>
                <h5>CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</h5>
                <h6><u>Độc lập - Tự do - Hạnh phúc</u></h6>
              </div>
              <div style=" font-family: 'Times New Roman', Times, serif;margin-top: 100px">
                <h2 style=" font-family: 'Times New Roman', Times, serif;font-size: 70px"><b>HỌC BẠ</b></h2>
                <h3 style=" font-family: 'Times New Roman', Times, serif;font-size: 28px"><b>TRUNG HỌC CƠ SỞ</b></h3>
                <!--                Thông tin gia đình-->
                <div style=" font-family: 'Times New Roman', Times, serif;padding-top: 40px">
                  <table style=" font-family: 'Times New Roman', Times, serif;width: 100%">
                    <tr>
                      <td style=" font-family: 'Times New Roman', Times, serif;text-align: left">Họ và tên:</td>
                      <td style=" font-family: 'Times New Roman', Times, serif;text-align: left" colspan="2">
                        <b>{{ thongTinHocBaBia.tenHS }}</b></td>
                      <td style=" font-family: 'Times New Roman', Times, serif;text-align: left">Giới tính:</td>
                      <td style=" font-family: 'Times New Roman', Times, serif;text-align: left">
                        {{ thongTinHocBaBia.gioiTinh == 1 ? 'Nam' : 'Nữ' }}
                      </td>
                      <td style=" font-family: 'Times New Roman', Times, serif;text-align: left; width: 20%"></td>
                    </tr>
                    <tr>
                      <td style=" font-family: 'Times New Roman', Times, serif;text-align: left">Ngày sinh:</td>
                      <td style=" font-family: 'Times New Roman', Times, serif;text-align: left" colspan="2">
                        {{ thongTinHocBaBia.ngaySinh }}
                      </td>
                      <td style=" font-family: 'Times New Roman', Times, serif;text-align: left">Dân tộc:</td>
                      <td style=" font-family: 'Times New Roman', Times, serif;text-align: left">
                        {{ thongTinHocBaBia.tenDanToc }}
                      </td>
                      <td style=" font-family: 'Times New Roman', Times, serif;text-align: left"></td>
                    </tr>
                    <tr>
                      <td style=" font-family: 'Times New Roman', Times, serif;text-align: left">Nơi sinh:</td>
                      <td style=" font-family: 'Times New Roman', Times, serif;text-align: left" colspan="4">
                        {{ thongTinHocBaBia.noiSinh }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="6" style=" font-family: 'Times New Roman', Times, serif;text-align: left">Đối tượng
                        (con liệt sĩ, con thương binh,...): {{ thongTinHocBaBia.tenDtuongCsach }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="6" style=" font-family: 'Times New Roman', Times, serif;text-align: left">Chỗ ở hiện
                        tại: {{ thongTinHocBaBia.noiOHienNay }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" style=" font-family: 'Times New Roman', Times, serif;text-align: left">Họ và tên
                        cha: {{ thongTinHocBaBia.hoTenCha }}
                      </td>
                      <td colspan="3" style=" font-family: 'Times New Roman', Times, serif;text-align: left">Nghề
                        nghiệp: {{ thongTinHocBaBia.ngheNghiepCha }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" style=" font-family: 'Times New Roman', Times, serif;text-align: left">Họ và tên
                        mẹ: {{ thongTinHocBaBia.hoTenMe }}
                      </td>
                      <td colspan="3" style=" font-family: 'Times New Roman', Times, serif;text-align: left">Nghề
                        nghiệp: {{ thongTinHocBaBia.ngheNghiepMe }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" style=" font-family: 'Times New Roman', Times, serif;text-align: left">Họ và tên
                        người giám hộ: {{ thongTinHocBaBia.hoTenCha }}
                      </td>
                      <td colspan="3" style=" font-family: 'Times New Roman', Times, serif;text-align: left">Nghề
                        nghiệp: {{ thongTinHocBaBia.hoTenCha }}
                      </td>
                    </tr>
                  </table>
                </div>
                <!--                Ngày ký-->
                <div>
                  <div
                      style=" font-family: 'Times New Roman', Times, serif;height: 160px; width: 50%; margin-left: 50%; position: relative">
                    <i>{{ thongTinHocBaBia.ngayKyBia }}</i>
                    <br>
                    <b>{{ thongTinHocBaBia.tieuDeKyThay }}</b>
                    <p style=" font-family: 'Times New Roman', Times, serif;padding-top: 110px">
                      {{ thongTinHocBaBia.tenHieuTruong }}
                    </p>
                    <img style=" position: absolute;  top: 30px;width: 111px;left: 70px;"
                         :src="thongTinHocBaBia.pathDau?'https://huviron-static.dtsgroup.com.vn//dau/7900001_1713932530683.png':'https://huviron-static.dtsgroup.com.vn//dau/7900001_1713932530683.png'"
                         alt="Ảnh dấu">
                    <img style=" position: absolute;  top: 70px;width: 140px;left: 130px;"
                         src="https://huviron-static.dtsgroup.com.vn//chuky/1/0/0/1/1/3/8/4/7/4/GV1001138474_7900001_1713932583300.png"
                         alt="">
                  </div>
                </div>
                <!--                Quá trình học tập-->
                <div style=" font-family: 'Times New Roman', Times, serif;padding-top: 15px">
                  <h5>QUÁ TRÌNH HỌC TẬP</h5>
                  <table style=" font-family: 'Times New Roman', Times, serif;border-collapse: collapse;width: 100%">
                    <tr>
                      <td style=" font-family: 'Times New Roman', Times, serif;border: 1px solid;"><p><b>Năm học</b></p>
                      </td>
                      <td style=" font-family: 'Times New Roman', Times, serif;border: 1px solid;"><p><b>Lớp</b></p>
                      </td>
                      <td style=" font-family: 'Times New Roman', Times, serif;border: 1px solid;"><p><b>Tên trường,
                        tỉnh/thành phố</b></p></td>
                    </tr>
                    <tr>
                      <td style=" font-family: 'Times New Roman', Times, serif;border: 1px solid; height: 34px"><p>
                        {{ thongTinHocBaBia.quaTrinhHocTaps[0].namHoc }}</p></td>
                      <td style=" font-family: 'Times New Roman', Times, serif;border: 1px solid; height: 34px"><p>
                        {{ thongTinHocBaBia.quaTrinhHocTaps[0].tenLop }}</p></td>
                      <td style=" font-family: 'Times New Roman', Times, serif;border: 1px solid; height: 34px;text-align: left">
                        <p style="padding-left: 50px"> {{ thongTinHocBaBia.quaTrinhHocTaps[0].tenTruong }}</p>
                        <div style="position: relative">
                          <img style=" position: absolute;  top: -65px;width: 111px;right: 100px;"
                               :src="thongTinHocBaBia.pathDau?'https://huviron-static.dtsgroup.com.vn//dau/7900001_1713932530683.png':'https://huviron-static.dtsgroup.com.vn//dau/7900001_1713932530683.png'"
                               alt="Ảnh dấu">
                          <img style=" position: absolute;  top: -50px;width: 140px;right: 0px;"
                               src="https://huviron-static.dtsgroup.com.vn//chuky/1/0/0/1/1/3/8/4/7/4/GV1001138474_7900001_1713932583300.png"
                               alt="">
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td style=" font-family: 'Times New Roman', Times, serif;border: 1px solid; height: 34px"><p>
                        {{ thongTinHocBaBia.quaTrinhHocTaps[1].namHoc }}</p></td>
                      <td style=" font-family: 'Times New Roman', Times, serif;border: 1px solid; height: 34px"><p>
                        {{ thongTinHocBaBia.quaTrinhHocTaps[1].tenLop }}</p></td>
                      <td style=" font-family: 'Times New Roman', Times, serif;border: 1px solid; height: 34px"><p>
                        {{ thongTinHocBaBia.quaTrinhHocTaps[1].tenTruong }}</p></td>
                    </tr>
                    <tr>
                      <td style=" font-family: 'Times New Roman', Times, serif;border: 1px solid; height: 34px"><p>
                        {{ thongTinHocBaBia.quaTrinhHocTaps[2].namHoc }}</p></td>
                      <td style=" font-family: 'Times New Roman', Times, serif;border: 1px solid; height: 34px"><p>
                        {{ thongTinHocBaBia.quaTrinhHocTaps[2].tenLop }}</p></td>
                      <td style=" font-family: 'Times New Roman', Times, serif;border: 1px solid; height: 34px"><p>
                        {{ thongTinHocBaBia.quaTrinhHocTaps[2].tenTruong }}</p></td>
                    </tr>
                    <tr>
                      <td style=" font-family: 'Times New Roman', Times, serif;border: 1px solid; height: 34px"><p>
                        {{ thongTinHocBaBia.quaTrinhHocTaps[3].namHoc }}</p></td>
                      <td style=" font-family: 'Times New Roman', Times, serif;border: 1px solid; height: 34px"><p>
                        {{ thongTinHocBaBia.quaTrinhHocTaps[3].tenLop }}</p></td>
                      <td style=" font-family: 'Times New Roman', Times, serif;border: 1px solid; height: 34px"><p>
                        {{ thongTinHocBaBia.quaTrinhHocTaps[3].tenTruong }}</p></td>
                    </tr>
                    <tr>
                      <td style=" font-family: 'Times New Roman', Times, serif;border: 1px solid; height: 34px"><p>
                        {{ thongTinHocBaBia.quaTrinhHocTaps[4].namHoc }}</p></td>
                      <td style=" font-family: 'Times New Roman', Times, serif;border: 1px solid; height: 34px"><p>
                        {{ thongTinHocBaBia.quaTrinhHocTaps[4].tenLop }}</p></td>
                      <td style=" font-family: 'Times New Roman', Times, serif;border: 1px solid; height: 34px"><p>
                        {{ thongTinHocBaBia.quaTrinhHocTaps[4].tenTruong }}</p></td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--      Quá trình học tập-->
      <template v-for="(kqht,index) in thongTinKqht">
        <div
             style=" font-family: 'Times New Roman', Times, serif;width: 210mm; height: 297mm;  border: 1px solid black;margin: 0 auto">
          <div class="biaHocBa"
               style=" font-family: 'Times New Roman', Times, serif;width: 190mm; height: 277mm; margin: 10mm auto; ">
            <div>
              <table style=" font-family: 'Times New Roman', Times, serif;width: 100%">
                <tr>
                  <td><span>Họ và tên:</span><b> {{ kqht.hoten }}</b></td>
                  <td><span>Lớp:</span><b> {{ kqht.tenLop }}</b></td>
                  <td><span>Năm học:</span><b> {{ kqht.strNamHoc }}</b></td>
                </tr>
              </table>
              <table style=" font-family: 'Times New Roman', Times, serif;border-collapse: collapse;width: 100%">
                <tr>
                  <td style=" font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black;width: 4cm"
                      rowspan="2">Môn học/hoạt động giáo dục
                  </td>
                  <td style=" font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black"
                      colspan="3"><b>Điểm trung bình môn học hoặc mắc
                    đánh giá</b></td>
                  <td style=" font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black"
                      rowspan="2"><b>Điểm trung bình môn học hoặc mắc
                    đánh giá</b></td>
                  <td style=" font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black"
                      rowspan="2"><b>Nhận xét sự tiến bộ, ưu điểm nổi
                    bật,
                    hạn chế chủ yếu (nếu có) và chữ kí của giáo viên môn học</b></td>
                </tr>
                <tr>
                  <td style=" font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black">
                    <p>Học kỳ I</p></td>
                  <td style=" font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black">
                    <p>Học kỳ II</p></td>
                  <td style=" font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black">
                    <p>Cả năm</p></td>
                </tr>
                <tr v-for="item in kqht.ketQuaHocTaps">
                  <td style=" font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black">
                    <p style="padding:7px">{{ item.tenMonHoc }}</p></td>
                  <td style=" font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black">
                    <p>{{ item.diemHocKyI }}</p></td>
                  <td style=" font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black">
                    <p>{{ item.diemHocKyII }}</p></td>
                  <td style=" font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black">
                    <p>{{ item.diemCaNam }}</p></td>
                  <td style=" font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black">
                    <p></p></td>
                  <td style=" font-family: 'Times New Roman', Times, serif;text-align: left; border: 1px solid black">
                    <p style="padding-left: 100px">{{ item.tenGiaoVien }}</p>
                    <div style="position: relative">
                      <img style=" position: absolute;  top: -40px;width: 140px;left: 0px;"
                           src="https://huviron-static.dtsgroup.com.vn//chuky/1/0/0/1/1/3/8/4/7/4/GV1001138474_7900001_1713932583300.png"
                           alt="">
                    </div>
                  </td>
                </tr>
              </table>
              <div>
                <p>Trong bảng này có sửa chữa ở: Không chỗ, thuộc môn học, hoạt động giáo dục:
                </p>
                <table style=" font-family: 'Times New Roman', Times, serif;width: 100%; margin-top: 60px">
                  <tr>
                    <td style=" font-family: 'Times New Roman', Times, serif;text-align: center">
                      <div><b>Xác nhận của Giáo viên chủ nhiệm</b>
                        <p><i>(Ký và ghi rõ họ tên)</i></p>
                        <div style="position: relative">
                          <img style=" position: absolute;  top: 30px;width: 150px;left: 100px;"
                               src="https://huviron-static.dtsgroup.com.vn//chuky/1/0/0/1/1/3/8/4/7/4/GV1001138474_7900001_1713932583300.png"
                               alt="">
                        </div>
                        <p style=" font-family: 'Times New Roman', Times, serif;padding-top: 100px">{{item.tenGiaoVien}}</p>
                      </div>
                    </td>
                    <td style=" font-family: 'Times New Roman', Times, serif;text-align: center">
                      <div><b>Xác nhận của Hiệu trưởng</b>
                        <p><i>(Ký và ghi rõ họ tên)</i></p>

                        <div style="position: relative">
                          <img style=" position: absolute;  top: 0px;width: 111px;left: 45px;"
                               :src="item.pathDau?'https://huviron-static.dtsgroup.com.vn//dau/7900001_1713932530683.png':'https://huviron-static.dtsgroup.com.vn//dau/7900001_1713932530683.png'"
                               alt="Ảnh dấu">
                          <img style=" position: absolute;  top: 30px;width: 160px;left: 100px;"
                               src="https://huviron-static.dtsgroup.com.vn//chuky/1/0/0/1/1/3/8/4/7/4/GV1001138474_7900001_1713932583300.png"
                               alt="">
                        </div>
                        <p style=" font-family: 'Times New Roman', Times, serif;padding-top: 100px">
                          {{ thongTinHocBaBia.tenHieuTruong }}</p>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!--      Trang tiếp-->
        <div
            style=" font-family: 'Times New Roman', Times, serif;width: 210mm; height: 297mm;  border: 1px solid black;margin: 0 auto">
          <div class="biaHocBa"
               style=" font-family: 'Times New Roman', Times, serif;width: 190mm; height: 277mm; margin: 10mm auto; ">
            <div>
              <table style=" font-family: 'Times New Roman', Times, serif;width: 100%">
                <tr>
                  <td><span>Họ và tên:</span><b> {{ kqht.hoten }}</b></td>
                  <td><span>Lớp:</span><b> {{ kqht.tenLop }}</b></td>
                  <td><span>Năm học:</span><b> {{ kqht.strNamHoc }}</b></td>
                </tr>
              </table>
              <table>
                <tr>
                  <td style=" font-family: 'Times New Roman', Times, serif;text-align: left; border: 1px solid black;padding: 0;margin: 0">
                    <table style=" font-family: 'Times New Roman', Times, serif;border-collapse: collapse;width: 100%">
                      <tr>
                        <td style=" font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black;width: 2cm"
                            rowspan="2"><b>Học kỳ</b></td>
                        <td style=" font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black"
                            colspan="2"><b>Mức đánh giá</b></td>
                        <td style=" font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black"
                            rowspan="2"><b>Tổng số buổi nghỉ học cả
                          năm</b>
                        </td>
                        <td style="font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black"
                            colspan="2"><b>Mức đánh giá sau đánh
                          giá lại môn học hoặc rèn
                          luyện trong kì nghỉ hè
                          (nếu có)</b></td>
                        <td style=" font-family: 'Times New Roman', Times, serif;text-align: left; border: 1px solid black; width: 4cm;"
                            rowspan="5">
                          <p style="font-family: 'Times New Roman', Times, serif;">Được lên lớp:</p>
                          <br>
                          <p style="font-family: 'Times New Roman', Times, serif;">Không được lên lớp:</p>
                        </td>
                      </tr>
                      <tr>
                        <td style=" font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black;width: 2cm">
                          <p>Kết quả rèn luyện</p></td>
                        <td style=" font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black">
                          <p>Kết quả học tập</p></td>
                        <td style=" font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black">
                          <p>Kết quả rèn luyện</p></td>
                        <td style=" font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black">
                          <p>Kết quả học tập</p></td>
                      </tr>
                      <tr>
                        <td style=" font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black">
                          <p>Học kỳ I</p>
                        </td>
                        <td style=" font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black">
                          <p>Tốt</p>
                        </td>
                        <td style=" font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black;width: 2cm">
                          <p>Chưa đạt</p>
                        </td>
                        <td rowspan="3" style=" font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black">
                          <p>Học kỳ I</p>
                        </td>
                        <td rowspan="3" style=" font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black">
                          <p>Học kỳ I</p>
                        </td>
                        <td rowspan="3" style=" font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black">
                          <p>Học kỳ I</p>
                        </td>
                      </tr>
                      <tr>
                        <td style=" font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black">
                          <p>Học kỳ II</p>
                        </td>
                        <td style=" font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black">
                          <p>Tốt</p>
                        </td>
                        <td style=" font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black">
                          <p>Đạt</p>
                        </td>
                      </tr>
                      <tr>
                        <td style=" font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black">
                          <p>Cả năm</p>
                        </td>
                        <td style=" font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black">
                          <p>Tốt</p>
                        </td>
                        <td style=" font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black">
                          <p>Đạt</p>
                        </td>
                      </tr>
                    </table>
                    <div style=" font-family: 'Times New Roman', Times, serif;padding-left: 10px">
                      <p><i>- Nếu là lớp cuối cấp thì ghi: Hoàn thành hay không hoàn thành chương trình trung học cơ
                        sở:</i></p>
                      <table style=" font-family: 'Times New Roman', Times, serif;width: 100%; margin-top: 25px">
                        <tr>
                          <td style=" font-family: 'Times New Roman', Times, serif;text-align: left; width: 70%">
                            <p>- Chứng chỉ (nếu có): Không</p>
                          </td>
                          <td style=" font-family: 'Times New Roman', Times, serif;text-align: left">
                            <p>Loại:</p>
                          </td>
                        </tr>
                      </table>

                      <p><i>- Nếu là lớp cuối cấp thì ghi: Hoàn thành hay không hoàn thành chương trình trung học cơ
                        sở:</i></p>
                      <p><i>- Kết quả tham gia các cuộc thi (nếu có): Không</i></p>
                      <p><i>- Khen thưởng (nếu có): Không</i></p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style=" font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black;padding: 0; margin: 0; height: 3cm; display: block;padding-top: 10px">
                    <h5><b>KẾT QUẢ RÈN LUYỆN TRONG KÌ NGHỈ HÈ</b></h5>
                    <i>(Nếu có)</i>
                  </td>
                </tr>
                <tr>
                  <td style=" font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black;padding: 0; margin: 0; display: block;padding-top: 10px">
                    <h5><b>NHẬN XÉT CỦA GIÁO VIÊN CHỦ NHIỆM</b></h5>
                    <p style=" font-family: 'Times New Roman', Times, serif;text-align: left; padding: 10px">Ngoan, có ý
                      thức tổ chức kỷ luật, có tinh thần trách nhiệm,
                      hoàn thành tốt các công việc được
                      giao.</p>
                    <div style="padding-top: 30px">
                      <table style=" font-family: 'Times New Roman', Times, serif;width: 100%">
                        <tr>
                          <td style=" font-family: 'Times New Roman', Times, serif;width: 70%;"></td>
                          <td style=" font-family: 'Times New Roman', Times, serif;width: 30%; text-align: center">
                            <div style="position: relative">
                              <p><i>(Ký và ghi rõ họ tên)</i></p>

                              <img style=" position: absolute;  top: 30px;width: 160px;left: 0px;"
                                   src="https://huviron-static.dtsgroup.com.vn//chuky/1/0/0/1/1/3/8/4/7/4/GV1001138474_7900001_1713932583300.png"
                                   alt="">
                              <p style=" font-family: 'Times New Roman', Times, serif;padding-top: 70px">
                                <b>{{ kqht.tenGVCN }}</b></p>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style=" font-family: 'Times New Roman', Times, serif;text-align: center; border: 1px solid black;padding: 0; margin: 0; height: 75mm; display: block;padding-top: 10px">
                    <h5><b>PHÊ DUYỆT CỦA HIỆU TRƯỞNG</b></h5>
                    <div>
                      <table style=" font-family: 'Times New Roman', Times, serif;width: 100%">
                        <tr>
                          <td style=" font-family: 'Times New Roman', Times, serif;width: 60%; text-align: left">
                            <p style="padding-left: 15px">Đồng ý với nhận xét của giáo viên chủ nhiệm</p>
                          </td>
                          <td style=" font-family: 'Times New Roman', Times, serif;width: 40%; text-align: center">
                            <div style="position: relative">
                              <i>{{kqht.ngayHieuTruongKy}}</i>
                              <p><b>HIỆU TRƯỞNG</b></p>
                              <p><i>(Ký và ghi rõ họ tên)</i></p>
                              <p style=" font-family: 'Times New Roman', Times, serif;padding-top: 110px">
                                <b>{{ thongTinHocBaBia.tenHieuTruong }}</b></p>
                              <img src="https://huviron-static.dtsgroup.com.vn//chuky/1/0/0/1/1/3/8/4/7/4/GV1001138474_7900001_1713932583300.png"
                                   style=" font-family: 'Times New Roman', Times, serif;width: 140px; height: auto;position: absolute;bottom: 30px; ;left: 80px" alt="Ảnh chữ ký">
                              <img style=" width: 111px; position: absolute;left: 15px;top: 60px;"
                                   src="https://huviron-static.dtsgroup.com.vn//dau/7900001_1713932530683.png" alt="Ảnh dấu">
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  props: ['item'],
  data() {
    return {
      thongTinHocBaBia: {
        anhTuQLNT: '',
        quaTrinhHocTaps: [
          {namHoc: '', tenLop: '', tenTruong: ''}
        ],

      },
      thongTinKqht: [],
      dataForm: {
        schoolName: ''
      }
    }
  },
  mounted() {
    console.log('mounted view xem học bạ:')
    console.log(JSON.stringify(this.item))
    if (this.item)
      this.thongTinHocBaBia = this.item.BIA;
    this.thongTinKqht = this.item.KQHT;
    console.log('thongTinKqht')
    console.log(this.thongTinKqht)
  },
  methods: {
    capitalizeFirstLetter() {
      console.log('capitalizeFirstLetter')
      var words = this.dataForm.schoolName.split(" ");
      for (let i = 0; i < words.length; i++) {
        console.log(words[i])
        if (words[i]) {
          words[i] = words[i][0].toUpperCase() + words[i].substring(1).toLowerCase();
        }
      }
      this.dataForm.schoolName = words.join(" ");
    },

    print() {
      let userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.indexOf('ubuntu') !== -1) {
        alert('Vui lòng chuyển hệ điều hành khi sử dụng hệ thống. Cảm ơn.')
      }


      const printContent = this.$refs.printContent;

      // Tạo một đối tượng Window mới cho quá trình in
      const printWindow = window.open('', '_blank');
      printWindow.document.open();

      // Chèn nội dung vào đối tượng Window in
      printWindow.document.write(printContent.innerHTML);
      printWindow.document.close();

      // Đợi cho nội dung được tải và sau đó gọi phương thức in
      printWindow.onload = function () {
        printWindow.print();
        printWindow.close();
      };
    }
  }
};
</script>
<style>
</style>