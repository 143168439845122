<template>
  <div class="page">
    <div class="page__title d-flex align-items-center justify-content-between"
         v-loading.fullscreen.lock="loadingScreen">

      <h1 v-if="typeAction=='ky'" class="title">Ký sổ phân công</h1>
      <h1 v-else-if="typeAction=='dongDau'" class="title">Đóng dấu sổ phân công</h1>
      <h1 v-else class="title">Danh sách sổ phân công</h1>
    </div>
    <div class="box-upload-file">
      <el-row :gutter="24">
        <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
          <label>Năm học</label>
          <eselect style="width: 100%" collapseTags v-model="dataSearch.namHoc" :placeholder="'Chọn'"
                   filterable :data="list_nam_hoc" :fields="['name','value']"/>
        </el-col>
        <el-col :xs="12" :sm="8" :md="6" :lg="3" :xl="3">
          <label>Cấp học</label>
          <eselect :disabled="list_cap_hoc.length==1" style="width: 100%" collapseTags
                   v-model="dataSearch.capHoc"
                   :placeholder="'Chọn'"
                   filterable :data="list_cap_hoc" :fields="['name','value']"/>
        </el-col>
        <el-col :xs="12" :sm="8" :md="6" :lg="3" :xl="3" class=" lg">
          <div style="color: transparent">Tìm kiếm</div>
          <el-button class="m-0" type="primary" @click.prevent="getlistSoPhanCong()">Xem</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="page__container " style="margin-top:25px " v-if="dataFileSoDiem.url">
      <div class="page__content">
        <div class="listNew mb-5">
          <el-row :gutter="24">
            <el-col :span="12">
              <p style="color: #0b2e13">Trạng thái: <b style="color: blue">{{ getTrangThai(dataFileSoDiem) }}</b></p>
            </el-col>
            <el-col :span="12" class="text-right">
              <div style="display: contents" v-if="typeAction=='ky'">
                <el-button v-if="(!dataFileSoDiem.daKy&&user.role==3)&&dataFileSoDiem.chot" @click="kySoPhanCong(1)"
                           size="mini"
                           type="primary">Ký sổ phân
                  công
                </el-button>
                <!--                <el-tooltip content="Trạng thái phân công" placement="top">-->
                <!--                  <el-button v-if="dataFileSoDiem.daKy" size="mini" type="success">Đã ký-->
                <!--                  </el-button>-->
                <!--                </el-tooltip>-->
              </div>
              <div style="display: contents" v-else-if="typeAction=='dongDau'">
                <el-button v-if="(!dataFileSoDiem.daDongDau)&&user.role==1&&dataFileSoDiem.daKy&&dataFileSoDiem.chot"
                           @click="kySoPhanCong(2)" size="mini"
                           type="primary">Đóng dấu
                  sổ phân công
                </el-button>
                <!--                <el-tooltip content="Trạng thái phân công" placement="top">-->
                <!--                  <el-button v-if="dataFileSoDiem.daDongDau" size="mini" type="success">Đã đóng dấu-->
                <!--                  </el-button>-->
                <!--                </el-tooltip>-->
              </div>
              <div style="display: contents" v-else>
                <el-button v-if="!dataFileSoDiem.chot" @click="chotDuLieuSoDiem(1)" size="mini" type="primary"> Chốt
                  dữ
                  liệu sổ phân công
                </el-button>
                <el-button v-if="dataFileSoDiem.chot" size="mini" @click="chotDuLieuSoDiem(2)" type="primary"> Hủy
                  chốt
                  dữ liệu sổ phân công
                </el-button>
              </div>
              <div class="cursor-pointer" style="display: contents" v-if="kieuKyGiaoVien">
                <el-tooltip content="Thay đổi kiểu ký" placement="top">
                  <el-button type="primary" :disabled="countTypeSign==1"
                             style="padding:7px 5px 5px 5px;margin: 0; margin-left: 8px"
                             @click.prevent="showChonKieuKy = true ">
                    <img style="width: 30px; height: 17px" :src="getPathImgByType(kieuKyGiaoVien)" alt="">
                    Kiểu ký
                  </el-button>
                </el-tooltip>
              </div>
            </el-col>
            <el-col :span="24">
              <div class="box-upload-file" style="overflow: scroll">
                <iframe :src="dataFileSoDiem.url" style="width:100%; height:80vh"></iframe>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div>
      <el-dialog
          :close-on-press-escape="false"
          :close-on-click-modal="false" top="5vh" title="Xác nhận ký tài liệu" width="50%" append-to-body
          :show-close="false"
          center custom-class="hienThiThoiGianCho"
          :visible.sync="hien_thi_thoi_gian_doi_ky">
        <el-row :gutter="24">
          <el-col :span="24">
            <p>Yêu cầu ký số đã được gửi về thiết bị di động.</p>
            <p style="white-space: nowrap">Mở
              <span v-if="kieuKyGiaoVien!=2">ứng dụng</span>
              <span v-if="kieuKyGiaoVien==2">thiết bị di động <b>nhập mã PIN</b></span>
              <span v-if="kieuKyGiaoVien==5">MySign</span>
              <span v-if="kieuKyGiaoVien==6">VNPT SmartCA</span>
              và nhấn <b>Xác nhận</b> để ký tài liệu.</p>
          </el-col>
          <el-col :xs="24" :sm="12">
            <div class="bg-purple-light pt-3">
              <p><b class="pd-r-14">Ứng dụng: </b>
                <span v-if="kieuKyGiaoVien==2">Viettel CA</span>
                <span v-if="kieuKyGiaoVien==5">MySign</span>
                <span v-if="kieuKyGiaoVien==6">VNPT SmartCA</span>
                <span v-if="kieuKyGiaoVien==7">VGCA</span>
              </p>
              <p style="white-space: nowrap"><b class="pd-r-10">Trạng thái: </b>Đang thực hiện ký</p>
            </div>
            <div v-if="kieuKyGiaoVien!=2" class="text-center pt-3">
              <p>Thời gian còn lại</p>
              <h4 style="color: #00A65A">{{ getMinutes(timeLeft) }}</h4>
            </div>
            <div>
              <hr/>
              <p v-if="kieuKyGiaoVien==5">
                <span><b style="color: blue">Lưu ý:</b></span>
                Nếu sau 10s không thấy có thông báo kí được gửi tới ứng dụng hãy bấm vào "Tải lại yêu cầu" trên ứng
                dụng.
                Nếu sau 2 phút vẫn không có yêu cầu kí hãy gọi tới số <b style="color: #DA1D1D">18008000</b> bấm nhánh
                <b
                    style="color: #DA1D1D">1</b> để được trợ
                giúp.</p>
              <p v-if="kieuKyGiaoVien==2">
                <b style="color: blue">Lưu ý:</b>
                Có bất kì lỗi gì về kí hãy gọi số <b style="color: #DA1D1D">18008000</b> bấm nhánh <b
                  style="color: #DA1D1D">1</b>
                để được trợ.
              </p>
              <hr/>
            </div>
            <div v-if="kieuKyGiaoVien!=2">
              <p>Cài đặt ứng dụng tại</p>
            </div>
            <div v-if="kieuKyGiaoVien!=2">
              <el-row :gutter="24">
                <el-col :span="12">
                  <img @click.prevent="getAppAndroid()" src="/images/ch_android.png" alt="">
                  <img @click.prevent="getAppIos()" src="/images/appstore_ios.png" alt="">
                </el-col>
                <el-col :span="12">
                  <img v-if="kieuKyGiaoVien==5" style="width: 80% !important;"
                       src="/images/qr_code_viettel.png"
                       alt="Ảnh qr">
                  <img v-if="kieuKyGiaoVien==6" style="width: 80% !important;"
                       src="/images/qr_code_vnpt.png"
                       alt="Ảnh qr">
                  <img v-if="kieuKyGiaoVien==7" style="width: 80% !important;"
                       src="/images/qr_code_vnpt.png"
                       alt="Ảnh qr">
                </el-col>
              </el-row>
            </div>
            <div v-else class="text-center">
              <img src="/images/loading1.gif" alt="">
              <div class="text-left">
                <p><b>Hệ thống Viettel CA đang thực hiện thao tác ký, Quý Thầy cô vui lòng chờ trong giây
                  lát đến khi hệ thống hoàn thành việc ký. Trân trọng cảm ơn!</b></p>
              </div>
            </div>
          </el-col>
          <el-col :sm="12" class="hidden-xs-only hide-in-mobile">
            <img src="/images/mobile_ca.png" alt="ảnh">
          </el-col>
        </el-row>
      </el-dialog>
      <!--      Chn kiê ký-->
      <el-dialog
          :close-on-press-escape="false"
          :close-on-click-modal="false" title="Chọn kiểu ký" width="40%" center custom-class="thongTinKyTheoMonHoc"
          append-to-body :show-close="false"
          :visible.sync="showChonKieuKy">
        <el-row :gutter="24">
          <el-col :span="24" class="text-center">
            <el-radio class="checkTypeSign" v-for="(type,i) in listKieuKy" v-model="kieuKyGiaoVien" :key="i"
                      @change="chonKieuKy()" :label="type.value">
              <img style="width:90px; height: 70px" :src="getPathImg(type)" alt="Ảnh">
            </el-radio>
          </el-col>
        </el-row>
      </el-dialog>
      <!--      -->

      <el-dialog
          :close-on-press-escape="false"
          :close-on-click-modal="false" title="Chọn chứng thư số" width="50%" center custom-class="thongTinKyTheoMonHoc"
          append-to-body :show-close="false"
          show-close
          :visible.sync="hien_thi_chon_chung_thu_so">
        <el-row :gutter="24">
          <el-table border :data="danh_sach_chung_thu_so">
            <el-table-column label="STT" width="50" align="center" type="index">
            </el-table-column>
            <el-table-column header-align="center" label="Số serial">
              <template slot-scope="scope">
                {{ scope.row.serialNumberDecimal }}
              </template>
            </el-table-column>
            <el-table-column property="status" label="Trạng thái" align="center" width="150"
                             header-align="center"></el-table-column>
            <el-table-column property="validTo" header-align="center" align="center" label="Ngày hết hiệu lực"
                             width='220'></el-table-column>
            <el-table-column align="center" header-align="center" label="Hành động" width="150">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" @click.prevent="setChungThuSo(scope.row)">Sử dụng</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-dialog>
      <!--      Usb-->

      <KyHocBaUsbAllV2 v-if="show_ky_usb" :caphoc="dataSearch.capHoc" :namhoc="dataSearch.namHoc"
                       :thuchienky="thuc_hien_ky_usb_edoc" :key="'kySoPhanCong'" :typeKy="typeKy"
                       :dulieu="duLieuKyDongDau"
                       @done="getlistSoPhanCong()" @close="kyHocBaUsbXong()"></KyHocBaUsbAllV2>
    </div>
  </div>
</template>

<script>
import {
  mapActions,
  mapState
} from 'vuex';
import api from "../_helpers/api";
import constant from "../_helpers/constant_api";
import ESelectVue from "./Ui/ESelect";
import constant_api from "../_helpers/constant_api";
import viewHocBaC1 from "./HocBa/viewHocBa/c1.vue";
import msgType from "../_helpers/constant_msg_type";
import websocket from "../websocket";
import constant_2 from "../api";
import sr from "element-ui/src/locale/lang/sr";
import KyHocBaUsbAllV2 from "./KyListHocBaUsbAllV2Edoc2V2.vue";

export default {
  name: "BaoCaoDuLieuHocBa",
  metaInfo: {
    title: 'Sổ phân công',
    meta: [
      {vmid: 'description', name: 'description', content: ''}
    ],
  },
  components: {
    KyHocBaUsbAllV2,
    viewHocBaC1,
    'eselect': ESelectVue,
  },
  data() {
    return {
      maxHeightTable: window.innerHeight,
      list_don_vi: [],
      danh_sach_khoi_hoc: [],
      list_data: [],
      danh_sach_lop_hoc: [],
      list_giai_doan: [
        {name: 'Giữa kỳ 1', value: 1},
        {name: 'Cuối kỳ 1', value: 2},
        {name: 'Giữa kỳ 2', value: 3},
        {name: 'Cuối kỳ 2', value: 4},
        {name: 'Cả năm', value: ''},
      ],
      list_cau_hinh: [
        {name: 'Ẩn chữ ký', value: '1'},
        {name: 'Ẩn chữ ký hiệu trưởng', value: '2'},
        {name: 'Ẩn dấu', value: '3'},
      ],
      loadingScreen: false,
      linkViewReport: '',
      dataFileSoDiem: {
        url: ''
      },
      linkDownloadReport: '',
      kieuKyGiaoVien: '',
      timeLeft: 90,
      hien_thi_thoi_gian_doi_ky: false,
      demNguoc: null,
      duLieuKyMySign: null,
      thuc_hien_ky_usb: false,
      thuc_hien_ky_usb_all: false,
      show_ky_usb: false,
      duLieuKyDongDau: null,
      typeAction: false,
      typeKy: '',
      danh_sach_chung_thu_so: [],
      chungThuSo: {
        giatri: null,
        expiredTime: null,
        token: null
      },
      hien_thi_chon_chung_thu_so: false,
      thuc_hien_ky_usb_edoc: false,
      showChonKieuKy: false,
      show_slect_cer_vgca: false,
      dataKyVgca: '',
      typeOtpSms: {label: 'SIMCA', value: 1, icon: 'icon_otp_sms.png'},
      typeSimCa: {label: 'SIMCA', value: 2, icon: 'icon_sim_ca.png'},
      typeUsbToken: {label: 'USB TOKEN', value: 4, icon: 'icon_usb_token.png'},
      typeSmartCaVt: {label: 'SMART_CA_VIETTEL', value: 5, icon: 'icon_mysign.png'},
      typeSmartCaVnpt: {label: 'SMART_CA_VNPT', value: 6, icon: 'icon_vnpt_ca.png'},
      typeSmartVGCA: {label: 'VGCA', value: 7, icon: 'icon_vgca.png'},
      countTypeSign: 0,
      listKieuKy: [],
      typeThucHien: '',
      dataSearch: {
        namHoc: '',
        cauHinh: [],
        capHoc: '',
        giaiDoan: '',
        khoiHoc: '',
        lopHoc: '',
      },
      paginate: {
        start: 0,
        limit: 100,
        page: 1,
      },
      khoiCap1: [{
        value: 1,
        label: 'Khối 1'
      },
        {
          value: 2,
          label: 'Khối 2'
        },
        {
          value: 3,
          label: 'Khối 3'
        },
        {
          value: 4,
          label: 'Khối 4'
        },
        {
          value: 5,
          label: 'Khối 5'
        },
      ],
      khoiCap2: [{
        value: 6,
        label: 'Khối 6'
      },
        {
          value: 7,
          label: 'Khối 7'
        },
        {
          value: 8,
          label: 'Khối 8'
        },
        {
          value: 9,
          label: 'Khối 9'
        },
      ],
      khoiCap3: [{
        value: 10,
        label: 'Khối 10'
      },
        {
          value: 11,
          label: 'Khối 11'
        },
        {
          value: 12,
          label: 'Khối 12'
        },

      ],
    }
  },
  computed: {
    ...mapState('account', [
      'user',
      'nam_hoc_hien_tai',
      'list_nam_hoc',
      'list_cap_hoc',
    ]),
  },
  beforeMount() {
    window.addEventListener('message', this.onReceiveMessage, false);
  },
  beforeDestroy() {
    console.log('beforeDestroy ký bìa học bạ');
    window.removeEventListener('message', this.onReceiveMessage)
    this.show_ky_usb = false;
  },
  kyHocBaUsbXong() {
    console.log("Hủy toàn bộ thông tin ký:")
    this.show_ky_usb = false;
    this.thuc_hien_ky_usb = false;
    this.thuc_hien_ky_usb_edoc = false;
  },
  mounted() {
    let action = this.$route.fullPath;
    if (action == '/ky-so-phan-cong') {
      this.typeAction = 'ky'
      this.typeKy = 'kySoPhanCong'
    } else if (action == '/dong-dau-so-phan-cong') {
      this.typeAction = 'dongDau'
      this.typeKy = 'dongDauSoPhanCong'
    } else {
      this.typeAction = ''
    }
    console.log('typeAction');
    console.log(this.typeAction);
    console.error('Thông tin:')
    console.error(this.user)
    console.error(this.list_cap_hoc)
    console.log('Cấp học')
    this.dataSearch.capHoc = this.list_cap_hoc[0].value
    this.dataSearch.namHoc = this.nam_hoc_hien_tai;
    // this.mapDanhSachKhoi()
    // this.getlistLopHoc();
    if (this.user.role != 4) {
      this.getKieuKy();
    }
  },
  methods: {
    kyHocBaUsbXong() {
      this.loadingScreen = false;
      this.show_ky_usb = false;
      this.thuc_hien_ky_usb_edoc = false;
      this.getlistSoPhanCong();
    },
    getTrangThai(stt) {
      console.log('getTrangThai')
      console.log(stt)
      let str = ''
      if (stt.chot) {
        str = 'Đã chốt'
      } else {
        str = 'Chưa chốt'
      }
      if (stt.daKy) {
        str = 'Đã ký'
      }
      if (stt.daDongDau) {
        str = 'Đã đóng dấu'
      }
      return str;
    },
    setChungThuSo(item) {
      console.log('setChungThuSo')
      console.log(item);
      this.chungThuSo.giatri = item;
      this.kyDongDauSoPhanCongMysign()
    },
    kyDongDauSoPhanCongMysign() {
      console.log('kyDongDauSoPhanCongMysign')
      let data = {
        namHoc: this.dataSearch.namHoc,
        capHoc: this.dataSearch.capHoc,
        msgType: this.typeThucHien == 1 ? msgType.type.kySoPhanCong : msgType.type.dongDauSoPhanCong,
        certBO: this.chungThuSo.giatri,
        token: this.chungThuSo.token,
        expiredTime: this.chungThuSo.expiredTime,

      }
      console.error('Data ký:')
      console.error(data)

      console.log("Gửi:")
      console.log(data)
      websocket.sendMessage(data);
      this.showDialogWait();
      // this.$confirm('Xác nhận ký danh sách học bạ đã chọn?', 'Thông báo', {
      //   confirmButtonText: 'Đồng ý',
      //   cancelButtonText: 'Hủy',
      // })
      //     .then(_ => {
      //     })
      //     .catch(_ => {
      //     });
    },
    onReceiveMessage(e) {
      let data = e.data;
      if (data.other == msgType.type.kySoPhanCong || data.other == msgType.type.dongDauSoPhanCong) {
        console.log('onReceiveMessage')
        console.log(data)
        if (data.code == 200) {
          this.getlistSoPhanCong();
          this.thongBao('success', data.msg)
          this.loadingScreen = false;
          this.hien_thi_thoi_gian_doi_ky = false;
          this.show_slect_cer_vgca = false;
          this.dataKyVgca = '';
          this.hien_thi_chon_chung_thu_so = false;
        } else {
          this.loadingScreen = false;
          this.thongBao('error', data.msg)
          this.show_slect_cer_vgca = false;
          this.dataKyVgca = '';
          this.hien_thi_thoi_gian_doi_ky = false;
          this.hien_thi_chon_chung_thu_so = false;
        }
      }


    },
    chonKieuKy() {
      console.error('Chọn kiểu kỳ:')
      console.error(this.kieuKyGiaoVien)
      localStorage.setItem('kieuKyBiaGiaoVien', this.kieuKyGiaoVien);
      this.showChonKieuKy = false;
    },
    getPathImg(item) {
      let result = window.location.protocol + '//' + window.location.hostname + '/images/' + item.icon;
      return result;
    },
    getMinutes(second) {
      if (second <= 0) {
        return '00:00'
      } else {
        let sec = 60;
        let phut = (second - second % sec) / sec;
        let giay = second % sec;
        if (phut < 10) {
          phut = '0' + phut;
        }
        if (giay < 10) {
          giay = '0' + giay
        }
        return phut + ':' + giay;
      }
    },
    getKieuKy() {
      console.log('getKieuKy');
      this.loadingScreen = true;
      let uri = constant_2.giaovien.getKieuKy;
      console.log('uri:' + uri)
      let params = {}
      this.listKieuKy = [];
      api.get(uri, params).then(
          response => {
            console.error("Kiểu ký trả về:")
            console.error(response)
            var kieuKyBiaGiaoVien = localStorage.getItem('kieuKyBiaGiaoVien')
            if (response.data.code == 200) {
              this.countTypeSign = response.data.data.toString().split('').length;
              if (this.countTypeSign > 1) {
                let listKieuKy = response.data.data.toString().split('');
                for (let i = 0; i < listKieuKy.length; i++) {
                  if (listKieuKy[i] == 1) {
                    this.listKieuKy.push(this.typeOtpSms)
                  }
                  if (listKieuKy[i] == 2) {
                    this.listKieuKy.push(this.typeSimCa)
                  }
                  if (listKieuKy[i] == 4) {
                    this.listKieuKy.push(this.typeUsbToken)
                  }
                  if (listKieuKy[i] == 5) {
                    this.listKieuKy.push(this.typeSmartCaVt)
                  }
                  if (listKieuKy[i] == 6) {
                    this.listKieuKy.push(this.typeSmartCaVnpt)
                  }
                  if (listKieuKy[i] == 7) {
                    this.listKieuKy.push(this.typeSmartVGCA)
                  }
                }
                console.error(this.listKieuKy)
                console.log('listKieuKy')
                console.log(this.listKieuKy)
                if (!kieuKyBiaGiaoVien) {
                  console.log('case show')
                  this.showChonKieuKy = true;
                } else {
                  console.log(' !show')
                  this.kieuKyGiaoVien = parseInt(kieuKyBiaGiaoVien)
                }
              } else {
                this.kieuKyGiaoVien = response.data.data
                console.error(this.kieuKyGiaoVien)
              }
            }
            this.loadingScreen = false;
          }
      ).catch((e) => {
        // this.thongBao('success', 'Hệ thống đang cập nhật dữ liệu. Vui lòng chờ trong ít phút.');
        this.loadingScreen = false;
      })
    },
    layDanhSachChungChi() {
      console.log('layDanhSachChungChi')
      let url = constant_2.giaovien.danhSachChungChi;
      let params = {}
      this.loadingScreen = true;
      console.log(url)
      api.get(url, params).then(
          response => {
            console.log("Danh sách chứng chỉ:")
            console.log(response.data)
            if (response.data.code == 200) {
              this.danh_sach_chung_thu_so = response.data.data.certBOS;
              this.chungThuSo.expiredTime = response.data.data.expiredTime;
              this.chungThuSo.token = response.data.data.token;
              this.hien_thi_chon_chung_thu_so = true;
            } else {
              this.thongBao("error", response.data.msg)
            }
            console.log('Danh sách chứng thư  số:')
            console.log(this.danh_sach_chung_thu_so)
            this.loadingScreen = false;
          }
      )
    },

    getPathImgByType(type) {
      let icon = ''
      if (type == 1) {
        icon = 'icon_otp_sms.png'
      }
      if (type == 2) {
        icon = 'icon_sim_ca.png'
      }
      if (type == 4) {
        icon = 'icon_usb_token.png'
      }
      if (type == 5) {
        icon = 'icon_mysign.png'
      }
      if (type == 6) {
        icon = 'icon_vnpt_ca.png'
      }
      if (type == 7) {
        icon = 'icon_vgca.png'
      }
      let objIcon = {
        icon: icon
      }
      return this.getPathImg(objIcon)
    },
    kySoPhanCong(stt) {
      console.log('kySoPhanCong')
      console.log('kieuKyGiaoVien:', this.kieuKyGiaoVien)
      this.typeThucHien = stt;
      if (this.kieuKyGiaoVien == 5) {
        console.log('Ký mysign')
        this.layDanhSachChungChi()
      }
      if (this.kieuKyGiaoVien == 4) {
        console.log('Ký USB Token')
        this.duLieuKyDongDau = {
          namHoc: this.dataSearch.namHoc,
          capHoc: this.dataSearch.capHoc
        }
        this.show_ky_usb = true;
      }
    },
    chotDuLieuSoDiem(stt) {
      let params = {
        namHoc: this.dataSearch.namHoc,
        capHoc: this.dataSearch.capHoc,
        maTruong: this.user.maTruong
      }
      console.log(params)
      // try {
      let uri = constant_api.hocsinh.chotSoPhanCong;
      if (stt == 2) {
        uri = constant_api.hocsinh.huyChotSoPhanCong;
      }
      console.error('Data ký:')
      this.loadingScreen = true;
      api.post(uri, params).then(
          response => {
            console.log("Chốt sổ điểm trả về:")
            console.log(response.data);
            if (response.data.code == 200) {
              this.thongBao('success', response.data.msg)
              this.getlistSoPhanCong();
            } else {
              this.thongBao('error', response.data.msg)
            }
            this.loadingScreen = false;
          }
      ).catch((e) => {
        this.loadingScreen = false;
      })
      // } catch (e) {
      //   this.thongBao('error', 'Vui lòng thao tác lại.')
      // }
    },

    showDialogWait() {
      this.hien_thi_thoi_gian_doi_ky = true;
      this.clearTimer()
      this.getTimeDown();
      this.demNguocThoiGian(true);
    },
    getTimeDown() {
      if (this.kieuKyGiaoVien == 5) {
        this.timeLeft = 185;
      }
      if (this.kieuKyGiaoVien == 6) {
        this.timeLeft = 300;
      }
      if (this.kieuKyGiaoVien == 7) {
        this.timeLeft = 1200;
      }
    },
    clearTimer() {
      if (this.demNguoc) {
        clearInterval(this.demNguoc);
        this.demNguoc = null
      }
    },
    demNguocThoiGian(check) {
      console.log('demNguocThoiGian')
      if (this.timeLeft >= 0) {
        this.demNguoc = setInterval(() => {
          this.timeLeft--
        }, 1000)
      } else {
        clearInterval(this.demNguoc);
        this.demNguoc = null
      }
    },
    mapDanhSachKhoi() {
      let arr = [];
      this.danh_sach_khoi_hoc = [];
      for (let i = 0; i < this.list_cap_hoc.length; i++) {
        if (this.list_cap_hoc[i].value == 1) {
          arr = arr.concat(this.khoiCap1)
        }
        if (this.list_cap_hoc[i].value == 2) {
          arr = arr.concat(this.khoiCap2)
        }
        if (this.list_cap_hoc[i].value == 3) {
          arr = arr.concat(this.khoiCap3)
        }
      }
      this.danh_sach_khoi_hoc = arr;
    },
    capHocChange() {
      this.dataSearch.khoiHoc = '';
      if (!this.dataSearch.capHoc) {
        this.mapDanhSachKhoi();
        return;
      } else {
        if (this.dataSearch.capHoc == 1) {
          this.danh_sach_khoi_hoc = this.khoiCap1;
        } else if (this.dataSearch.capHoc == 2) {
          this.danh_sach_khoi_hoc = this.khoiCap2;
        } else {
          this.danh_sach_khoi_hoc = this.khoiCap3;
        }
      }
    },
    getTime() {
      var d = new Date();
      var n = d.getTime();
      return n;
    },
    indexMethod(index) {
      return (this.paginate.start + index) + 1;
    },
    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t
      }
      if (type == "success") {
        cl = "dts-noty-success"
      }
      if (type == "warning") {
        cl = "dts-noty-warning"
      }
      if (type == "error") {
        cl = "dts-noty-error"
      }
      if (type == "info") {
        cl = "dts-noty-info"
      }
      document.querySelector('.overlay').style.display = 'block';
      this.$message({
        onClose: () => {
          document.querySelector('.overlay').style.display = 'none';
        },
        dangerouslyUseHTMLString: true,
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000
      });
    },
    chonKhoiHoc() {
      this.dataSearch.lopHoc = '';
      this.getlistLopHoc();
    },
    getlistSoPhanCong() {
      console.log('getlistSoPhanCong')
      let params = {
        namHoc: this.dataSearch.namHoc,
        capHoc: this.dataSearch.capHoc,
      }
      console.log(params)
      try {
        let uri = constant_api.hocsinh.getListSoPhanCong;
        console.log('uri: ' + uri)
        this.loadingScreen = true;
        api.post(uri, params).then(
            response => {
              console.log("Lấy sổ phân công trả về:")
              console.log(response.data);
              if (response.data.code == 200) {
                this.dataFileSoDiem = response.data.data;
                let timestamp = Date.now();
                console.log(timestamp);
                this.dataFileSoDiem.url += '?t=' + timestamp
                console.log('dataFileSoDiem')
                // this.thongBao('success', response.data.msg)
                console.log(this.dataFileSoDiem)
              } else {
                this.thongBao('error', response.data.msg)
              }
              this.loadingScreen = false;
            }
        ).catch((e) => {
          this.loadingScreen = false;
        })

      } catch (e) {
        this.thongBao('error', 'Vui lòng thao tác lại.')
      }
    },
    getlistLopHoc() {
      console.log('lấy danh sách lớp')
      if (!this.dataSearch.namHoc) {
        this.thongBao('error', 'Vui lòng bổ sung thông tin năm học.')
        return;
      }
      console.log(this.user)
      let params = {
        start: 0,
        limit: 999,
        khoiHoc: this.dataSearch.khoiHoc,
        namHoc: this.dataSearch.namHoc,
        maTruong: this.user.maTruong,
      }
      console.log(params)

      try {
        let uri = constant_api.hocsinh.getListLop;
        console.log('uri: ' + uri)
        this.loadingScreen = true;
        this.danh_sach_lop_hoc = [];
        api.get(uri, params).then(
            response => {
              console.log("Lấy ds học bạ trả về:")
              console.log(response.data.data.list);
              if (response.data.code == 200) {
                let arr = response.data.data.list;
                for (let i = 0; i < arr.length; i++) {
                  if (arr[i].maLop) {
                    this.danh_sach_lop_hoc.push(arr[i]);
                  }
                }
                // this.danh_sach_lop_hoc = response.data.data.list;
                if (this.danh_sach_lop_hoc.length == 0) {
                  this.thongBao('error', 'Không tìm thấy danh sách lớp')
                }
              } else {
                this.thongBao('error', 'Không tìm thấy danh sách lớp')
              }
              this.loadingScreen = false;
            }
        ).catch((e) => {
          this.loadingScreen = false;
        })

      } catch (e) {
        this.thongBao('error', 'Vui lòng thao tác lại.')
      }
    },
  }
}
</script>

<style scoped>

</style>