import api from "../api";
import {sprintf} from "sprintf-js";
import restApi from "../_helpers/rest_api";

export const testService = {
    test
};

function test(data) {
    let uri = api.test.url;
    return restApi.get(uri, data);
}
