var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user.role == 5
    ? _c(_vm.viewHocSinh, { tag: "component" })
    : _c(_vm.viewQuanLy, { tag: "component" })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }