<template>
  <div id="detailHocBa" class="page page-file file detailHocBa"
       v-loading.fullscreen.lock="fullscreenLoading"
       element-loading-text="Loading.."
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="page__title d-flex align-items-center justify-content-between">
      <h1 class="title">Nộp CSDL Học Bạ</h1>
    </div>
    <div class="box-upload-file">
      <el-form :rules="rulesSearch" ref="searchForm" :model="searchForm" :hide-required-asterisk="true">
        <el-row :gutter="24" @keyup.enter.native="submitSearch('searchForm')">
          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
            <el-form-item class="d-inline-block" prop="education" label="Cấp học">
              <eselect style="width:100%" :disabled="list_cap_hoc.length==1" collapseTags v-model="searchForm.education"
                       @change="capHocChange" placeholder="Chọn" filterable :data="list_cap_hoc"
                       :fields="['name','value']"/>
            </el-form-item>

          </el-col>
          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
            <el-form-item class="d-inline-block" prop="year" label="Năm học">
              <eselect style="width:100%" collapseTags v-model="searchForm.year" placeholder="Chọn"
                       @change="namHocChange" filterable :data="list_nam_hoc" :fields="['name','value']"/>
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
            <el-form-item class="d-inline-block" prop="grade" label="Khối học">
              <eselect style="width:100%" ref="grade" collapseTags v-model="searchForm.grade" @change="khoiHocChange"
                       placeholder="Chọn" filterable :data="optionGrade"
                       :fields="['label','value']"/>
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
            <el-form-item class="d-inline-block" label="Lớp học" prop="class">
              <eselect style="width:100%" collapseTags v-model="searchForm.class" ref="class" placeholder="Chọn"
                       filterable :data="danh_sach_lop_hoc" :fields="['tenLop','maLop']"/>
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
            <el-form-item class="d-inline-block" label="Trạng thái">
              <eselect style="width:100%" collapseTags v-model="searchForm.checkStatusNopKqht"
                       :placeholder="'Trạng thái học sinh'"
                       :data="[{label:'Đã nộp', value:1}, {label:'Chưa nộp', value:0}]"
                       :fields="['label','value']"/>
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
            <el-form-item class="d-inline-block" label="Từ khoá">
              <el-input v-model="searchForm.student" clearable placeholder="Nhập"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="6" :lg="24" :xl="3" class="text-left">
            <el-form-item class="d-inline-block text-left">
              <div style="color: transparent">Tìm kiếm</div>
              <el-button class="m-0" type="primary"
                         @click="submitSearch('searchForm')">Tìm kiếm
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-row :gutter="20">
      <el-col :span="24">
        <div class="text-right">
          <!--                <el-button @click="viewAddHocBa" class="mb-1" plain size="small"><i class="el-icon-s-grid"></i> Thêm học bạ-->
          <!--                </el-button>   -->
          <el-button type="primary" v-if="false" @click.prevent="checkShowUploadBia" class="mb-1" plain size="small"><i
              class="el-icon-printer"></i> Scan bìa học bạ
          </el-button>
          <!--                    <el-button type="danger" @click.prevent="checkShowUploadKQHT" class="mb-1" plain size="small"><i-->
          <!--                        class="el-icon-printer"></i> Scan học bạ-->
          <!--                    </el-button>-->
          <el-button v-if="user.qlnt!=0&&false" type="primary" @click="updateMutileImage" class="mb-1" size="small">
            <i
                class="el-icon-upload2"></i> Cập nhật nhiều ảnh
          </el-button>
        </div>
      </el-col>
    </el-row>

    <div class="page__content" ref="page_content">
      <div class="listNew mb-5">
        <el-row>
          <el-col :span="12" class="text-right " style="text-align: left !important;">
            <el-badge :hidden="listHocSinhCheckBia.length==0" :value="listHocSinhCheckBia.length" class="item"
                      type="primary">
              <el-button size="mini" @click="layThongTinXMLNopChungThuSo()"
                         type="primary">Nộp chứng thư số
              </el-button>
            </el-badge>
            <el-badge v-if="show_nop" :hidden="listHocSinhCheckBia.length==0" :value="listHocSinhCheckBia.length"
                      class="item"
                      type="primary">
              <el-button size="mini" v-show="show_nop" :disabled="listHocSinhCheckBia.length==0"
                         @click="chuanBiNopLenBoBia(4)"
                         type="primary">
                Nộp
                hồ sơ Bìa lên sở
              </el-button>
            </el-badge>
            <el-badge :hidden="listHocSinhCheck.length==0" :value="listHocSinhCheck.length" class="item" type="primary">
              <el-button size="mini" :disabled="listHocSinhCheck.length==0" @click="layThongTinXMLCanNop()"
                         type="primary">Nộp học bạ
              </el-button>
            </el-badge>
            <el-badge :hidden="listHocSinhCheckThuHoi.length==0" :value="listHocSinhCheckThuHoi.length" class="item"
                      type="primary">
              <el-button size="mini" :disabled="listHocSinhCheckThuHoi.length==0" @click="nhapLyDoThuHoi(6)"
                         type="primary">
                Thu hồi học bạ
              </el-button>
            </el-badge>
          </el-col>
          <el-col :span="12" class="text-left" style="text-align: right !important;">
            <div class="cursor-pointer d-inline">
              <el-tooltip content="Xem lịch sử" placement="left">
                <el-button size="mini" type="primary" :disabled="!searchForm.class"
                           @click.prevent="xemLichSu">
                  Lịch sử
                </el-button>
              </el-tooltip>
            </div>
            <div class="cursor-pointer d-inline" >
              <el-tooltip content="Thay đổi kiểu ký" placement="left">
                <el-button type="primary" :disabled="countTypeSign==1" style="padding: 7px 7px 5px 7px;margin: 0"
                           @click.prevent="showChonKieuKy = true ">
                  <img v-if="kieuKyGiaoVien" style="width: 30px; height: 17px" :src="getPathImgByType(kieuKyGiaoVien)" alt="">
                 {{kieuKyGiaoVien?'Kiểu ký':'Chọn kiểu ký'}}
                </el-button>
              </el-tooltip>
            </div>
          </el-col>
        </el-row>
        <el-table border fit :lazy="true" :data="listHs ? listHs.list : []" width="100%"
                  :max-height="maxHeight"
                  @selection-change="handleSelectionChange" ref="listFile">

          <el-table-column label="STT" style="width: 50px" width="50" type="index" class-name="text-center"
                           :index="indexMethod">
          </el-table-column>
          <el-table-column header-align="center" width="80" height="80" label="Ảnh">
            <template slot-scope="scope">
              <div class="avatar">
                <img :src="scope.row.avatar ? scope.row.avatar+'?t='+getTime() : defaultAvatar" alt=""/>
                <el-tooltip content="Xóa ảnh" placement="top">
                  <a class="remove-avatar" v-if="scope.row.removeAvatar" href=""
                     @click.prevent="removeAvatar(scope.row)"><i class="ion ion-ios-close"></i></a>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="Mã học sinh" property="maHS" align="center">
          </el-table-column>
          <el-table-column header-align="center" label="Số đăng bộ" property="soDangBo" align="center">
          </el-table-column>
          <el-table-column header-align="center" label="Họ và tên" property="tenHS"></el-table-column>
          <el-table-column header-align="center" label="Lớp" width="100" property="tenLop" align="center">
          </el-table-column>
          <!--          <el-table-column header-align="center" label="Trạng thái học sinh" align="center">-->
          <!--            <template slot-scope="scope">-->
          <!--              <div v-html="getTrangThaiHocSinh(scope.row.trangThai)"></div>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
          <el-table-column v-if="show_nop" header-align="center" label="Trạng thái nộp bìa" align="left">
            <template slot-scope="scope">
              <p>{{ getTrangThaiNop(scope.row.trangThaiBia) }}
                <el-tooltip v-if="scope.row.moTaTrangThaiNopBia" placement="top">
                  <div slot="content">
                    <span>{{ scope.row.moTaTrangThaiNopBia }}</span>
                  </div>
                  <i style="cursor: pointer; color: blue" class="el-icon-info"></i>
                </el-tooltip>
              </p>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="Trạng thái nộp học bạ" align="left">
            <template slot-scope="scope">
              <p>
                {{ getTrangThaiNop(scope.row.trangThaiKQHT) }}
                <el-tooltip v-if="scope.row.moTaTrangThaiNopHocBa" placement="top">
                  <div slot="content">
                    <span>{{ scope.row.moTaTrangThaiNopHocBa }}</span>
                  </div>
                  <i style="cursor: pointer; color: blue" class="el-icon-info"></i>
                </el-tooltip>
              </p>

            </template>
          </el-table-column>
          <el-table-column header-align="center" label="Trạng thái thu hồi" property="moTaTrangThaiYeuCauHieuChinh"
                           align="center">
            <template slot-scope="scope">
              <p>
                {{ getTrangThaiHieuChinh(scope.row.trangThaiYeuCauHieuChinh) }}
                <el-tooltip v-if="scope.row.moTaTrangThaiYeuCauHieuChinh&&scope.row.trangThaiYeuCauHieuChinh!='-1'"
                            placement="top">
                  <div slot="content">
                    <span>{{ scope.row.moTaTrangThaiYeuCauHieuChinh }}</span>
                  </div>
                  <i style="cursor: pointer; color: blue" class="el-icon-info"></i>
                </el-tooltip>
              </p>

            </template>
          </el-table-column>
          <el-table-column label="Chọn nộp" align="center">
            <el-table-column
                style="width: 100px"
                v-if="show_nop"
                width="100"
                align="center" header-align="center">
              <template slot="header" slot-scope="scope">
                <el-checkbox @change="ChonToanBoHocBaNopHoSoLenBoBia" :disabled="khongChoChonTatCaBia"
                             v-model="CheckAllBia"><span
                    style="color: white">{{ [1, 2, 3, 4, 5].includes(khoiHocXem) ? 'Bìa' : 'Bìa' }}</span>
                </el-checkbox>
              </template>
              <template slot-scope="scope">
                <el-checkbox @change="chonMoiHocBaNopHoSoBia(scope.row)"
                             :disabled="scope.row.checkStatusNopBia"
                             v-model="scope.row.checkChonBia"></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column
                style="width: 100px"
                width="100"
                align="center" header-align="center">
              <template slot="header" slot-scope="scope">
                <el-checkbox @change="ChonToanBoHocBaNopHoSoLenBo" :disabled="khongChoChonTatCaKQHT"
                             v-model="CheckAllKQHT"><span
                    style="color: white">{{ [1, 2, 3, 4, 5].includes(khoiHocXem) ? 'KQHT' : 'KQHT' }}</span>
                </el-checkbox>
              </template>
              <template slot-scope="scope">
                <el-checkbox @change="chonMoiHocBaNopHoSo(scope.row)"
                             :disabled="scope.row.checkStatusNopKqht"
                             v-model="scope.row.checkChonKqht"></el-checkbox>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="Thu hồi" align="center">
            <el-table-column
                style="width: 100px"
                width="100"
                align="center" header-align="center">
              <template slot="header" slot-scope="scope">
                <el-checkbox @change="ChonToanBoHocBaThuHoi" :disabled="khongChoChonTatCaThuHoi"
                             v-model="CheckAllThuHoi"><span
                    style="color: white">{{ [1, 2, 3, 4, 5].includes(khoiHocXem) ? 'Học bạ' : 'Học bạ' }}</span>
                </el-checkbox>
              </template>
              <template slot-scope="scope">
                <el-checkbox @change="chonMoiHocBaThuHoi(scope.row)"
                             :disabled="scope.row.trangThaiHocBaNopLenSo!=1"
                             v-model="scope.row.checkChonThuHoi"></el-checkbox>
              </template>
            </el-table-column>
          </el-table-column>

          <!--          <el-table-column header-align="center" label="Hành động" align="center">-->
          <!--            <template slot-scope="scope">-->
          <!--              <el-tooltip placement="top">-->
          <!--                <div slot="content">-->
          <!--                  <span>Kiểm tra trạng thái</span>-->
          <!--                </div>-->
          <!--                <el-button size="mini" type="primary" @click.prevent="kiemTraTrangThaiNop(scope.row)">Kiểm tra-->
          <!--                </el-button>-->
          <!--              </el-tooltip>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
        </el-table>
        <div v-if="listHs && listHs.total" class="mt-2">
          <el-row :gutter="20">
            <el-col :span="8">
              <chonSoLuong @chonXongSoLuong="ChonSoLuongBanGhi($event)"></chonSoLuong>
            </el-col>
            <el-col :span="16" class="text-right">
              <small class="pd-r-10">
                <i>Danh sách có {{ listHs.total }} học bạ </i>
              </small>
              <pagination :value="paginate.current"
                          :page-count="getTotalPage(listHs.total)" :click-handler="getHocBa"
                          :prev-text="'Trước'"
                          :next-text="'Sau'" :container-class="'el-pager'" :pageClass="'number'">

              </pagination>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <el-dialog
        :close-on-press-escape="false"
        :close-on-click-modal="false" title="Thông tin học bạ" append-to-body class="info-hocba"
        :visible.sync="showInfoHocba" center>
      <el-table fit border :lazy="true" :data="listHocBa" width="100%">
        <el-table-column label="STT" style="width: 50px" width="50" type="index" class-name="text-center"
                         :index="indexMethod">
        </el-table-column>
        <el-table-column label="Họ và tên" property="hoten" class-name="text-center">
        </el-table-column>
        <el-table-column label="Lớp" property="tenLop" width="70" class-name="text-center"></el-table-column>
        <el-table-column label="GVCN" property="tenGVCN" class-name="text-center"></el-table-column>
        <el-table-column label="Năm học" property="" class-name="text-center">
          <template slot-scope="scope">
            {{ scope.row.namHoc }} - {{ scope.row.namHoc + 1 }}
          </template>
        </el-table-column>
        <!--        <el-table-column label="File bìa ký số" class-name="text-center">-->
        <!--          <template slot-scope="scope">-->
        <!--            <a v-if="scope.row.biaHocBa" :href="scope.row.biaHocBa.linkView" target="_blank">Xem file</a>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <!--        <el-table-column label="File học bạ ký số" class-name="text-center">-->
        <!--          <template slot-scope="scope">-->
        <!--            <a v-if="scope.row.linkFilePdf" :href="scope.row.linkFilePdf" target="_blank">Xem file</a>-->
        <!--          </template>-->
        <!--        </el-table-column>-->

        <el-table-column label="Hành động" class-name="text-center">
          <template slot-scope="scope">
            <div class="btn__table_action">
              <!--              <UploadImageBase64 @click.native="dataSelect = scope.row"-->
              <!--                                 style="width: 100px; cursor: pointer;" disable-preview class="upload-thumb mb-1"-->
              <!--                                 image-class="v1-image" input-class="v1-image" @size-exceeded="onSizeExceeded"-->
              <!--                                 placeholder="Tải lên bìa học bạ" @load="onBiaLoad">-->
              <!--                <template v-slot:showButton>-->
              <!--                  <el-tooltip content="Tải lên bìa học bạ" placement="top">-->
              <!--                    <el-button icon="el-icon-upload2"></el-button>-->
              <!--                  </el-tooltip>-->
              <!--                </template>-->
              <!--              </UploadImageBase64>-->

              <!--              <UploadImageBase64 @click.native="dataSelect = scope.row.id"-->
              <!--                                 style="width: 100px; cursor: pointer;" disable-preview class="upload-thumb mb-1"-->
              <!--                                 image-class="v1-image" input-class="v1-image" :max-size="customImageMaxSize"-->
              <!--                                 @size-exceeded="onSizeExceeded" placeholder="Tải lên kqht" @load="onLoad">-->
              <!--                <template v-slot:showButton>-->
              <!--                  <el-tooltip content="Tải lên kqht" placement="top">-->
              <!--                    <el-button icon="el-icon-upload"></el-button>-->
              <!--                  </el-tooltip>-->

              <!--                </template>-->
              <!--              </UploadImageBase64>-->

              <el-tooltip content="Xem học bạ" placement="top">
                <el-button @click="viewFile(scope.row.id)" class="mb-1" icon="ion ion-md-eye" plain>
                </el-button>
              </el-tooltip>
              <!--                            <el-tooltip content="Phân quyền sửa" placement="top">-->
              <!--                                <el-button class="mb-1" icon="el-icon-edit" @click="permissionEdit(scope.row)">-->
              <!--                                </el-button>-->
              <!--                            </el-tooltip>-->
              <el-tooltip v-if="scope.row.nguonDuLieu" content="Sửa học bạ" placement="top">
                <el-button class="mb-1" type="primary" icon="el-icon-edit" @click="suaHocBa(scope.row)">
                </el-button>

              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="closeDialogCreate()">Đóng</el-button>
            </span>
    </el-dialog>

    <ThongTinHocBa :show="thongTinHocBa" :id_hoc_ba="idHocBa" @close="thongTinHocBa = false"></ThongTinHocBa>
    <el-dialog
        :close-on-press-escape="false"
        :close-on-click-modal="false" title="Thêm học bạ" :visible.sync="showAddHocBa" append-to-body
        :before-close="closeDialogCreate"
        center class="add-hocba">
      <them-hoc-ba @close="closeDialogCreate()">

      </them-hoc-ba>
    </el-dialog>
    <el-dialog
        :close-on-press-escape="false"
        :close-on-click-modal="false" title="Sửa học bạ" :visible.sync="showEditHocBa" append-to-body center
        class="edit-hocba">
      <sua-hoc-ba @close="closeDialogEdit()"></sua-hoc-ba>
    </el-dialog>
    <el-dialog
        :close-on-press-escape="false"
        :close-on-click-modal="false" title="Tải lên ảnh học sinh" append-to-body center custom-class="add-mutile-image"
        :visible.sync="showUpdateMutile">
      <el-upload ref="uploadMutile" class="upload-demo text-center" drag action="/" :file-list="fileList"
                 :on-error="showError" :on-change="uploadMutilAvatarChange" :on-remove="uploadMutilAvatarChange"
                 accept=".png,.jpg,.jpeg" :auto-upload="false" multiple>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">Kéo thả hoặc <em>click ở đây</em></div>
        <div class="el-upload__tip" slot="tip">
          Bạn hãy chọn tất cả ảnh của lớp (định dạng png,jpg). Lưu ý: đặt tên file là mã học sinh.<br>
          Hệ thống sẽ tự động upload tất cả ảnh học sinh có tên file trùng với mã học sinh tương ứng.
        </div>
      </el-upload>
      <div class="text-center">
        <el-button size="small" type="primary" @click.prevent="uploadMutilAvatar">Tải ảnh lên</el-button>
      </div>
    </el-dialog>
    <el-dialog
        :close-on-press-escape="false"
        :close-on-click-modal="false" title="File Scan trang bìa học bạ" append-to-body center
        custom-class="add-mutile-image"
        :visible.sync="showUploadBia">
      <el-upload ref="uploadBia" class="upload-demo text-center" drag action="/"
                 :on-change="uploadMutilAvatarChange" :on-remove="uploadMutilAvatarChange" accept=".pdf,.zip,.zar"
                 :auto-upload="false" multiple>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">Kéo thả hoặc <em>click ở đây</em></div>
        <div class="el-upload__tip" slot="tip">
          Bạn hãy chọn tất cả file của lớp (định dạng pdf). Lưu ý: đặt tên file là mã học sinh.<br>
          Hệ thống sẽ tự động upload tất cả file học sinh có tên file trùng với mã học sinh tương ứng.<br/>
          Lưu ý: Nếu hệ thống đã sinh file pdf, upload từ ngoài vào hệ thống sẽ thay bằng file upload.
        </div>
      </el-upload>
      <div class="text-center">
        <el-button size="small" type="success" @click.prevent="uploadBiaHocBa">Tải lên</el-button>
      </div>
    </el-dialog>

    <el-dialog
        :close-on-press-escape="false"
        :close-on-click-modal="false" title="File Scan trang học bạ" append-to-body center
        custom-class="add-mutile-image"
        :visible.sync="showUploadHocBa">
      <el-upload ref="uploadFileHocBa" class="upload-demo text-center" drag action="/"
                 :on-change="uploadMutilAvatarChange" :on-remove="uploadMutilAvatarChange" accept=".pdf"
                 :auto-upload="false" multiple>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">Kéo thả hoặc <em>click ở đây</em></div>
        <div class="el-upload__tip" slot="tip">
          Bạn hãy chọn tất cả file học bạ của lớp (định dạng pdf). Lưu ý: đặt tên file là mã học sinh.<br>
          Hệ thống sẽ tự động upload tất cả file học sinh có tên file trùng với mã học sinh tương ứng.<br>
          Lưu ý: Nếu hệ thống đã sinh file pdf, upload từ ngoài vào hệ thống sẽ thay bằng file upload.
        </div>
      </el-upload>
      <div class="text-center">
        <el-button size="small" type="primary" @click.prevent="uploadFileHocBa">Tải lên</el-button>
      </div>
    </el-dialog>


    <el-dialog
        :close-on-press-escape="false"
        :close-on-click-modal="false" class="popupPhanQuyenBoMon" title="Phân quyền sửa học bạ" append-to-body center
        :visible.sync="showPermissionEdit">
      <el-tabs type="card">
        <el-tab-pane v-if="user.capHoc > 1" label="Giáo viên bộ môn">
          <div class="page">
            <h4>Danh sách giáo viên bộ môn</h4>

            <el-table :data="lstMonDetail.ketQuaHocTaps" border class="table-custom" style="width: 100%">
              <el-table-column align="center" prop="tenMonHoc" label="Tên môn học">
              </el-table-column>
              <el-table-column header-align="center" prop="tenGiaoVien" label="Tên giáo viên">
              </el-table-column>
              <el-table-column align="center" label="Trạng thái">
                <template slot-scope="scope">
                  {{ scope.row.trangThaiXacNhan == 1 ? 'Đã ký' : 'Chưa ký' }}
                </template>
              </el-table-column>
              <el-table-column align="center" prop="ghiChu" label="Ghi chú">
              </el-table-column>
              <el-table-column align="center" label="Hành động">
                <template slot-scope="scope">
                  <el-tooltip content="Phân quyền" effect="dark" placement="top">
                    <el-button size="small" type="danger" @click="phanQuyenBoMon(scope.row)"><i
                        class="el-icon-edit"></i></el-button>

                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Giáo viên chủ nhiệm">
          <div class="text-center">
            <div><label>Giáo viên chủ nhiệm:</label> {{ lstMonDetail.tenGVCN }} - Lớp :
              {{ lstMonDetail.tenLop }}
            </div>
            <el-input type="textarea" :rows="4" placeholder="Nhập lý do" v-model="lyDoGVCN">
            </el-input>
            <el-button type="primary" class="mt-2" @click="submitPermissionGVCN">Phân quyền</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Hiệu trưởng">
          <div class="text-center">
            <div><label>Hiệu trưởng:</label> {{ lstMonDetail.tenHieuTruong }}</div>

            <el-input type="textarea" :rows="4" placeholder="Nhập lý do" v-model="lydoHieuTruong">
            </el-input>
            <el-button type="primary" class="mt-2" @click="submitPermissionHieuTruong">Phân quyền
            </el-button>
          </div>
        </el-tab-pane>

      </el-tabs>
    </el-dialog>

    <el-dialog
        :close-on-press-escape="false"
        :close-on-click-modal="false" class="" title="Phân quyền cho bộ môn" append-to-body center
        :visible.sync="showPopUpBoMon">
      <div>
        <div>
          <label>Tên giáo viên:</label> {{ dataBomon.tenGiaoVien }} - <label>Môn học:</label>
          {{ dataBomon.tenMonHoc }}
        </div>
        <el-input type="textarea" :rows="5" placeholder="Nhập lý do" v-model="lyDoBoMon">
        </el-input>
        <div class="text-center mt-3">
          <el-button type="primary" @click.prevent="submitPermissionBoMon">Xác nhận</el-button>
        </div>
      </div>

    </el-dialog>
    <el-dialog
        :close-on-press-escape="false"
        :close-on-click-modal="false" class="" title="Chỉnh sửa thông tin"
        append-to-body center :visible.sync="show_update_ngay_vao_truong">
      <div>
        <el-row :gutter="24">
          <el-col :span="12">
            <label>Tên học sinh</label>
            <el-input disabled v-model="infoUpdate.tenHS"></el-input>
          </el-col>
          <el-col :span="12">
            <label>Mã học sinh</label>
            <el-input disabled v-model="infoUpdate.maHS"></el-input>
          </el-col>
          <el-col :span="12" class="mt-3">
            <label>Địa danh ký <span style="color: #DA1D1D">*</span></label>
            <el-input
                maxlength="50"
                placeholder="Nhập địa danh ký"
                show-word-limit clearable v-model="infoUpdate.tenDiaDanhKyBia"></el-input>
          </el-col>
          <el-col :span="12" class="mt-3">
            <label>Ngày ký lý lịch học sinh <span style="color: #DA1D1D">*</span></label>
            <el-date-picker
                style="width: 100%;"
                format="dd/MM/yyyy"
                v-model="infoUpdate.ngayKyBia"
                value-format="dd/MM/yyyy"
                type="date"
                placeholder="dd/mm/yyyy">
            </el-date-picker>
          </el-col>
        </el-row>
        <div class="text-center mt-5">
          <el-button type="default" @click.prevent="show_update_ngay_vao_truong=false">Đóng</el-button>
          <el-button type="warning" @click.prevent="chinhSuaNgayKyBia()">Chỉnh sửa</el-button>
        </div>
      </div>

    </el-dialog>
    <!-- <KyHocBa></KyHocBa> -->

    <dialog-alert title="Thông báo" :message="messageAlert" :show-dialog="showDialogAlert"
                  @closeDialog="closeDialogAlert"></dialog-alert>
    <el-dialog
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        :title="hieuChinh.title"
        :visible.sync="show_nop_hieu_chinh"
        :before-close="handleCloseNopHieuChinh"
        width="30%">
      <el-row :gutter="24">
        <el-col :span="24">
          <div class="demo-input-suffix">
            <span class="demo-input-label">Nội dung hiệu chỉnh</span>
            <el-input
                type="textarea"
                :rows="2"
                maxlength="500"
                show-word-limit
                placeholder="Nhập nội dung hiệu chỉnh"
                suffix-icon="el-icon-check"
                v-model="hieuChinh.noiDung">
            </el-input>
          </div>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
    <el-button size="mini" @click="show_nop_hieu_chinh = false">Đóng</el-button>
    <el-button size="mini" :disabled="!hieuChinh.noiDung" :type="hieuChinh.loai==4?'warning':'success'"
               @click="nopHieuChinhLenBo">{{ hieuChinh.title }}</el-button>
  </span>
    </el-dialog>
    <!--    CHọn kiểu ký-->

    <el-dialog
        :close-on-press-escape="false"
        :close-on-click-modal="false" title="Chọn kiểu ký" width="40%" center custom-class="thongTinKyTheoMonHoc"
        append-to-body :show-close="true"
        @close="handleCloseChonKieuKy"
        :visible.sync="showChonKieuKy">
      <el-row :gutter="24">
        <el-col :span="24" class="text-center">
          <el-radio class="checkTypeSign" v-for="(type,i) in listKieuKy" v-model="kieuKyGiaoVien" :key="i"
                    @change="chonKieuKy()" :label="type.value">
            <img style="width:90px; height: 70px" :src="getPathImg(type)" alt="Ảnh">
          </el-radio>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog
        :close-on-press-escape="false"
        :close-on-click-modal="false" title="Chọn chứng thư số" width="50%" center custom-class="thongTinKyTheoMonHoc"
        append-to-body :show-close="false"
        show-close
        :visible.sync="hien_thi_chon_chung_thu_so">
      <el-row :gutter="24">
        <el-table border :data="danh_sach_chung_thu_so">
          <el-table-column label="STT" width="50" align="center" type="index">
          </el-table-column>
          <el-table-column header-align="center" label="Số serial">
            <template slot-scope="scope">
              {{ scope.row.serialNumberDecimal }}
            </template>
          </el-table-column>
          <el-table-column property="status" label="Trạng thái" align="center" width="150"
                           header-align="center"></el-table-column>
          <el-table-column property="validTo" header-align="center" align="center" label="Ngày hết hiệu lực"
                           width='220'></el-table-column>
          <el-table-column align="center" header-align="center" label="Hành động" width="150">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click.prevent="setChungThuSo(scope.row)">Sử dụng</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-dialog>

    <el-dialog
        :close-on-press-escape="false"
        :close-on-click-modal="false" title="Lịch sử thao tác" width="50%" center custom-class="thongTinKyTheoMonHoc"
        append-to-body :show-close="false"
        show-close
        :visible.sync="show_lich_su_thao_tac">
      <el-row :gutter="24">
        <el-table border :data="danh_sach_chung_thu_so">
          <el-table-column label="STT" width="50" align="center" type="index">
          </el-table-column>
          <el-table-column header-align="center" label="Số serial">
            <template slot-scope="scope">
              {{ scope.row.serialNumberDecimal }}
            </template>
          </el-table-column>
          <el-table-column property="status" label="Trạng thái" align="center" width="150"
                           header-align="center"></el-table-column>
          <el-table-column property="validTo" header-align="center" align="center" label="Ngày hết hiệu lực"
                           width='220'></el-table-column>
          <el-table-column align="center" header-align="center" label="Hành động" width="150">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click.prevent="setChungThuSo(scope.row)">Sử dụng</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-dialog>

    <el-dialog
        :close-on-press-escape="false"
        :close-on-click-modal="false" title="Đăng nhập hệ thống VGCA" width="50%" center
        custom-class="thongTinKyTheoMonHoc"
        append-to-body :show-close="false"
        show-close
        :visible.sync="show_check_account_vgca">
      <el-row :gutter="24">
        <el-form :rules="ruleVgca" ref="vgcaForm" :model="vgcaForm" :hide-required-asterisk="true">
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item class="d-inline-block" prop="username" label="Tài khoản">
                <el-input style="width:100%" v-model="vgcaForm.username" clearable
                          placeholder="Nhập...">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item class="d-inline-block" prop="password" label="Mật khẩu">
                <el-input type="password" show-password style="width:100%" v-model="vgcaForm.password" clearable
                          placeholder="Nhập">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-row>
      <span slot="footer" class="dialog-footer">
    <el-button size="mini" @click="show_check_account_vgca = false">Hủy</el-button>
    <el-button size="mini" type="success" @click="submitForm('vgcaForm')">Đăng nhập</el-button>
  </span>
    </el-dialog>
    <el-dialog
        :close-on-press-escape="false"
        :close-on-click-modal="false" title="Chọn chứng chỉ VGCA" width="50%" center custom-class="thongTinKyTheoMonHoc"
        append-to-body :show-close="false"
        show-close
        :visible.sync="show_slect_cer_vgca">
      <el-row :gutter="24">
        <el-form :rules="ruleVgca" ref="vgcaForm" :model="vgcaForm" :hide-required-asterisk="true">
          <el-row :gutter="24">
            <el-col :span="12">

              <el-radio class="checkTypeSign" v-for="(type,i) in list_cer_vgca" v-model="cerVgca" :key="i" border
                        :label="type">
                {{ type.credentialId }}
              </el-radio>
            </el-col>
          </el-row>
        </el-form>
      </el-row>
      <span slot="footer" class="dialog-footer">
    <el-button size="mini" @click="show_slect_cer_vgca = false">Hủy</el-button>
    <el-button size="mini" type="warning" :disabled="!cerVgca" @click="kyHocBaListVGCA">Ký</el-button>
  </span>
    </el-dialog>
    <el-dialog
        :close-on-press-escape="false"
        :close-on-click-modal="false" top="5vh" title="Xác nhận ký tài liệu" width="50%" append-to-body
        :show-close="false" center custom-class="hienThiThoiGianCho"
        :visible.sync="hien_thi_thoi_gian_doi_ky">
      <el-row :gutter="24">
        <el-col :span="24">
          <p>Yêu cầu ký số đã được gửi về thiết bị di động.</p>
          <p style="white-space: nowrap">Mở
            <span v-if="kieuKyGiaoVien!=2">ứng dụng</span>
            <span v-if="kieuKyGiaoVien==2">thiết bị di động <b>nhập mã PIN</b></span>
            <span v-if="kieuKyGiaoVien==5">MySign</span>
            <span v-if="kieuKyGiaoVien==6">VNPT SmartCA</span>
            và nhấn <b>Xác nhận</b> để ký tài liệu.</p>
        </el-col>
        <el-col :xs="24" :sm="12">
          <div class="bg-purple-light pt-3">
            <p><b class="pd-r-14">Ứng dụng: </b>
              <span v-if="kieuKyGiaoVien==2">Viettel CA</span>
              <span v-if="kieuKyGiaoVien==5">MySign</span>
              <span v-if="kieuKyGiaoVien==6">VNPT SmartCA</span>
              <span v-if="kieuKyGiaoVien==7">VGCA</span>
            </p>
            <p style="white-space: nowrap"><b class="pd-r-10">Trạng thái: </b>Đang thực hiện ký</p>
          </div>
          <div v-if="kieuKyGiaoVien!=2" class="text-center pt-3">
            <p>Thời gian còn lại</p>
            <h4 style="color: #00A65A">{{ getMinutes(timeLeft) }}</h4>
          </div>
          <div>
            <hr/>
            <p v-if="kieuKyGiaoVien==5">
              <span><b style="color: blue">Lưu ý:</b></span>
              Nếu sau 10s không thấy có thông báo kí được gửi tới ứng dụng hãy bấm vào "Tải lại yêu cầu" trên ứng dụng.
              Nếu sau 2 phút vẫn không có yêu cầu kí hãy gọi tới số <b style="color: #DA1D1D">18008000</b> bấm nhánh <b
                style="color: #DA1D1D">1</b> để được trợ
              giúp.</p>
            <p v-if="kieuKyGiaoVien==2">
              <b style="color: blue">Lưu ý:</b>
              Có bất kì lỗi gì về kí hãy gọi số <b style="color: #DA1D1D">18008000</b> bấm nhánh <b
                style="color: #DA1D1D">1</b>
              để được trợ.
            </p>
            <hr/>
          </div>
          <div v-if="kieuKyGiaoVien!=2">
            <p>Cài đặt ứng dụng tại</p>
          </div>
          <div v-if="kieuKyGiaoVien!=2">
            <el-row :gutter="24">
              <el-col :span="12">
                <img @click.prevent="getAppAndroid()" src="/images/ch_android.png" alt="">
                <img @click.prevent="getAppIos()" src="/images/appstore_ios.png" alt="">
              </el-col>
              <el-col :span="12">
                <img v-if="kieuKyGiaoVien==5" style="width: 80% !important;"
                     src="/images/qr_code_viettel.png"
                     alt="Ảnh qr">
                <img v-if="kieuKyGiaoVien==6" style="width: 80% !important;"
                     src="/images/qr_code_vnpt.png"
                     alt="Ảnh qr">
                <img v-if="kieuKyGiaoVien==7" style="width: 80% !important;"
                     src="/images/qr_code_vnpt.png"
                     alt="Ảnh qr">
              </el-col>
            </el-row>
          </div>
          <div v-else class="text-center">
            <img src="/images/loading1.gif" alt="">
            <div class="text-left">
              <p><b>Hệ thống Viettel CA đang thực hiện thao tác ký, Quý Thầy cô vui lòng chờ trong giây
                lát đến khi hệ thống hoàn thành việc ký. Trân trọng cảm ơn!</b></p>
            </div>
          </div>
        </el-col>
        <el-col :sm="12" class="hidden-xs-only hide-in-mobile">
          <img src="/images/mobile_ca.png" alt="ảnh">
        </el-col>
      </el-row>
    </el-dialog>
    <el-dialog
        :close-on-press-escape="false"
        :close-on-click-modal="false" class="" top="10vh"
        title="Lịch sử nộp hồ sơ"
        width="80%" append-to-body center :visible.sync="show_lich_su">
      <el-row :gutter="24">
        <el-col :span="24">
          <el-tabs type="border-card">
            <el-tab-pane label="Đã nộp">
              <el-table :data="thongTinLichSu.DA_NOP" border class="table-custom" style="width: 100%">
                <el-table-column align="center" width="150" label="Loại hồ sơ">
                  <template slot-scope="scope">
                    <p>
                      {{
                        scope.row.loaiHoSo == 1 ? 'VIETTEL' : scope.row.loaiHoSo == 2 ? 'VNPT' : scope.row.loaiHoSo == 3 ? 'QUANG_ICH' : scope.row.loaiHoSo == 4 ? 'HCM' : ''
                      }}</p>
                  </template>
                </el-table-column>
                <el-table-column width="250" header-align="center" prop="loaiYeuCau" label="Loại yêu cầu">
                </el-table-column>
                <el-table-column align="center" prop="ngayNop" label="Thời gian nộp"></el-table-column>
                <el-table-column align="center" prop="uuid" label="Mã tra cứu">
                </el-table-column>
                <el-table-column width="150" align="center" label="Hành động">
                  <template slot-scope="scope">
                    <el-tooltip :content="scope.row.ketQuaNops?'Xem chi tiết':'Không có dữ liệu'" effect="dark"
                                placement="top">
                      <el-button :disabled="scope.row.ketQuaNops.length==0" size="mini" type="primary"
                                 @click="xemChiTietLichSu(scope.row.ketQuaNops)"><i
                          class="el-icon-view"></i>
                      </el-button>
                    </el-tooltip>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="Đang xử lý">
              <el-table :data="thongTinLichSu.DANG_XU_LY" border class="table-custom" style="width: 100%">
                <el-table-column width="150" align="center" label="Loại hồ sơ">
                  <template slot-scope="scope">
                    <p>
                      {{
                        scope.row.loaiHoSo == 1 ? 'VIETTEL' : scope.row.loaiHoSo == 2 ? 'VNPT' : scope.row.loaiHoSo == 3 ? 'QUANG_ICH' : scope.row.loaiHoSo == 4 ? 'HCM' : ''
                      }}</p>
                  </template>
                </el-table-column>
                <el-table-column width="200" header-align="center" prop="loaiYeuCau" label="Loại yêu cầu">
                </el-table-column>
                <el-table-column width="200" align="center" prop="ngayNop" label="Thời gian nộp"></el-table-column>
                <el-table-column align="center" prop="uuid" label="Mã tra cứu">
                </el-table-column>
                <el-table-column width="150" align="center" label="Hành động">
                  <template slot-scope="scope">
                    <el-tooltip :content="scope.row.ketQuaNops?'Xem chi tiết':'Không có dữ liệu chi tiết'" effect="dark"
                                placement="top">
                      <el-button :disabled="!scope.row.ketQuaNops||scope.row.ketQuaNops.length==0" size="mini"
                                 type="success" @click="xemChiTietLichSu(scope.row.ketQuaNops)"><i
                          class="el-icon-view"></i>
                      </el-button>
                    </el-tooltip>
                    <el-tooltip content="Xem trạng thái" effect="dark" placement="top">
                      <el-button size="mini" type="primary" @click="xemTrangThai(scope.row)"><i
                          class="el-icon-refresh"></i>
                      </el-button>
                    </el-tooltip>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
    <el-button size="mini" @click="show_lich_su = false">Đóng</el-button>
  </span>

      <el-dialog
          :close-on-press-escape="false"
          :close-on-click-modal="false"
          width="70%"
          title="Chi tiết nộp"
          :visible.sync="hien_thi_chi_tiet_nop"
          append-to-body>
        <el-table :data="chi_tiet_nop_ho_so" border class="table-custom" style="width: 100%">
          <el-table-column width="200" align="center" prop="tenHocSinh" header-align="left" label="Tên học sinh">
          </el-table-column>
          <el-table-column width="150" header-align="center" prop="maHS" label="Mã học sinh">
          </el-table-column>
          <el-table-column align="left" header-align="center" prop="moTa" label="Mô tả"></el-table-column>
          <el-table-column align="center" width="100" prop="trangThai" label="Trạng thái">
          </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
    <el-button size="mini" @click="hien_thi_chi_tiet_nop = false">Đóng</el-button>
  </span>
      </el-dialog>
    </el-dialog>
    <el-dialog
        :close-on-press-escape="false"
        :close-on-click-modal="false" class="" title="Thu hồi học bạ" append-to-body center
        :visible.sync="show_ly_do_thu_hoi">
      <div>
        <div>
          <label>Lý do thu hồi học bạ:</label>
        </div>
        <el-input type="textarea"
                  maxlength="500"
                  show-word-limit :rows="5" placeholder="Nhập lý do" v-model="lyDoThuHoi">
        </el-input>
        <div class="text-center mt-3">
          <el-button type="primary" :disabled="!lyDoThuHoi" @click.prevent="layThongTinXMLCanThuHoi">Xác nhận thu hồi
          </el-button>
        </div>
      </div>

    </el-dialog>

    <KyHocBaUsbAllV2 v-if="show_ky_usb" :thuchienky="thuc_hien_ky_usb_edoc" :key="'nopHSV2'" :typeKy="'nopHSV2'"
                     :maLop="searchForm.class"
                     :dulieu="duLieuKyHocBaUsbV2"
                     @layXmlThanhCong="layThanhCongXml($event)"
                     @done="submitSearch('searchForm')" @close="kyHocBaUsbXong()"></KyHocBaUsbAllV2>
  </div>
</template>

<script>
import KyHocBa from "./Ui/kyHocBa";
import ChiTietHocBa from "./GiaoVien/ChiTietHocBa";
import {
  mapActions,
  mapState
} from 'vuex';
import Pagination from "../components/Pagination";
import dialog from "../dialog";
import FormOtp from "../components/FormOtp";
import UploadImageBase64 from "../components/UploadImageBase64";
import ThemHocBa from "./ThemHocBa";
import SuaHocBa from "./SuaHocBa";
import DialogAlert from "../components/DialogAlert";
import SelectNamHoc from "../components/SelectNamHoc";
import ChonKhoiHoc from "../components/ChonKhoiHoc";
import ThongTinHocBa from "../pages/HocBa/ThongTinHocBa";
import ESelectVue from "./Ui/ESelect";
import chonSoLuong from "./Ui/ChonSoLuong";
import constant_api from "../_helpers/constant_api";
import constant from "../_helpers/constant_define";
import api from "../_helpers/api";
import constant_2 from "../../js/api";
import LZString from 'lz-string';
import {
  hocbaService
} from "../_services";
import msgType from "../_helpers/constant_msg_type";
import websocket from "../websocket";
import KyHocBaUsbAllV2 from "./KyListHocBaUsbAllV2Edoc2V2.vue";

export default {
  name: "NopHoSoHocBa",
  metaInfo: {
    title: 'Nộp CSDL Học Bạ',
    titleTemplate: '',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: ''
    }]
  },
  computed: {
    ...mapState('teacher', [
      "listLop",
      "updateMessage",
      "updateSuccess",
      "uploadStatus"
    ]),
    ...mapState('hocba', [
      'ketQuaHocTapTemp',
      "fileDetail",
      "fileStatus"

    ]),
    ...mapState('account', [
      'user',
      'list_nam_hoc',
      'list_cap_hoc',
      'nam_hoc_hien_tai',
    ]),
    ...mapState('until', [
      'window',
    ])
  },
  components: {
    KyHocBaUsbAllV2,
    DialogAlert,
    SuaHocBa,
    chonSoLuong,
    Pagination,
    FormOtp,
    UploadImageBase64,
    ThemHocBa,
    SelectNamHoc,
    ChonKhoiHoc,
    ThongTinHocBa,
    'eselect': ESelectVue,
    'KyHocBa': KyHocBa,
  },

  data() {
    return {
      maxHeight: '',
      list_limit_paginate: constant.list_limit_paginate,
      fileList: [],
      hieuChinh: {
        loai: '',
        title: '',
        noiDung: ''
      },
      show_nop_hieu_chinh: false,
      showUpdateMutile: false,
      showUploadBia: false,
      showUploadHocBa: false,
      hocSinhSelect: '',
      defaultAvatar: '/images/avatar.jpg',
      showDialogAlert: false,
      messageAlert: '',
      danh_sach_lop_hoc: [],
      listHocBa: [],
      show_update_ngay_vao_truong: false,
      infoUpdate: {},
      showEditHocBa: false,
      showAddHocBa: false,
      showInfoHocba: false,
      thongTinHocBa: false,
      dataSelect: '',
      idHocBa: '',
      customImageMaxSize: 2,
      typeKy: null,
      hocbaDatas: [],
      showDialogOtp: false,
      listHocBaCheck: [],
      viewPopup: ChiTietHocBa,
      idEdit: '',
      lopHocHienTai: '',
      showEditDiem: false,
      paginate: {
        start: 0,
        limit: 50,
        current: 1,
      },
      data: [1, 2, 3, 4, 5],
      show_ly_do_thu_hoi: false,
      lyDoThuHoi: '',
      listHs: {
        total: 0,
        list: []
      },
      listHocSinhCheck: [],
      loaiKy: null,
      loaiNopLenSo: 4,
      listHocSinhCheckBia: [],
      listHocSinhCheckThuHoi: [],
      CheckAllKQHT: false,
      CheckAllThuHoi: false,
      khongChoChonTatCaBia: true,
      khongChoChonTatCaKQHT: true,
      khongChoChonTatCaThuHoi: true,
      CheckAllBia: false,
      fullscreenLoading: false,
      hien_thi_chi_tiet_nop: false,
      chi_tiet_nop_ho_so: [],
      duLieuXML: null,
      duLieuXMLDaKy: null,
      countTypeSign: 0,
      listKieuKy: [],
      showChonKieuKy: false,
      kieuKyGiaoVien: '',
      show_lich_su_thao_tac: false,
      show_lich_su: false,
      thongTinLichSu: {
        DA_NOP: [],
        DANG_XU_LY: []
      },
      list_cer_vgca: [],
      vgcaForm: {
        username: '',
        password: '',
      },
      ruleVgca: {
        username: [{
          required: true,
          message: 'Bạn chưa nhập tài khoản',
          trigger: ['change', 'blur']
        }],
        password: [{
          required: true,
          message: 'Bạn chưa nhập mật khẩu',
          trigger: ['change', 'blur']
        }],
      },
      dataKyVgca: [],
      cerVgca: '',
      show_check_account_vgca: false,
      show_slect_cer_vgca: false,
      danh_sach_chung_thu_so: [],
      hien_thi_chon_chung_thu_so: false,
      timeLeft: 90,
      chungThuSo: {
        giatri: null,
        expiredTime: null,
        token: null
      },
      tokenSmartCa: null,
      hien_thi_thoi_gian_doi_ky: false,
      demNguoc: null,
      duLieuKyMySign: null,
      thuc_hien_ky_usb: false,
      thuc_hien_ky_usb_all: false,
      show_ky_usb: false,
      thuc_hien_ky_usb_edoc: false,
      duLieuKyHocBaUsb: {},
      duLieuKyHocBaUsbV2: {},
      typeOtpSms: {label: 'SIMCA', value: 1, icon: 'icon_otp_sms.png'},
      typeSimCa: {label: 'SIMCA', value: 2, icon: 'icon_sim_ca.png'},
      typeUsbToken: {label: 'USB TOKEN', value: 4, icon: 'icon_usb_token.png'},
      typeSmartCaVt: {label: 'SMART_CA_VIETTEL', value: 5, icon: 'icon_mysign.png'},
      typeSmartCaVnpt: {label: 'SMART_CA_VNPT', value: 6, icon: 'icon_vnpt_ca.png'},
      typeSmartVGCA: {label: 'VGCA', value: 7, icon: 'icon_vgca.png'},
      show_nop: false,
      khoiHocXem: 0,
      dataFormScanBia: [],
      optionEducation: [{
        value: 1,
        label: 'Cấp 1'
      },
        {
          value: 2,
          label: 'Cấp 2'
        },
        {
          value: 3,
          label: 'Cấp 3'
        },
        {
          value: 15,
          label: 'Liên cấp'
        },
        {
          value: 145,
          label: 'Liên cấp'
        },
        {
          value: 14,
          label: 'Liên cấp'
        },
        {
          value: 13,
          label: 'Liên cấp'
        },
        {
          value: 451,
          label: 'Liên cấp'
        },
        {
          value: 23,
          label: 'Liên cấp'
        },
        {
          value: 236,
          label: 'Liên cấp'
        },
        {
          value: 36,
          label: 'Liên cấp'
        },
        {
          value: 452,
          label: 'Liên cấp'
        },
        {
          value: 1245,
          label: 'Liên cấp'
        },
        {
          value: 12,
          label: 'Liên cấp'
        },
        {
          value: 123,
          label: 'Liên cấp'
        },
        {
          value: 12345,
          label: 'Liên cấp'
        },
        {
          value: 1235,
          label: 'Liên cấp'
        },
        {
          value: 6,
          label: 'Trung tâm GDTX'
        },
      ],
      optionGrade: [],
      khoiCap1: [{
        value: 1,
        label: 'Khối 1'
      },
        {
          value: 2,
          label: 'Khối 2'
        },
        {
          value: 3,
          label: 'Khối 3'
        },
        {
          value: 4,
          label: 'Khối 4'
        },
        {
          value: 5,
          label: 'Khối 5'
        },
      ],
      khoiCap2: [{
        value: 6,
        label: 'Khối 6'
      },
        {
          value: 7,
          label: 'Khối 7'
        },
        {
          value: 8,
          label: 'Khối 8'
        },
        {
          value: 9,
          label: 'Khối 9'
        },
      ],
      khoiCap3: [{
        value: 10,
        label: 'Khối 10'
      },
        {
          value: 11,
          label: 'Khối 11'
        },
        {
          value: 12,
          label: 'Khối 12'
        },

      ],
      optionStudent: [{
        value: '',
        label: 'Học sinh'
      },
        {
          value: 1,
          label: 'HS 1'
        },
        {
          value: 2,
          label: 'HS 2'
        },
        {
          value: 3,
          label: 'HS 3'
        },
      ],
      optionStatus: [{
        value: '',
        label: 'Trạng thái'
      },
        {
          value: 1,
          label: 'Đã ký'
        },
        {
          value: 2,
          label: 'Đã ký duyệt'
        },
        {
          value: 3,
          label: 'Chưa ký'
        },
      ],

      searchForm: {
        year: '',
        education: '',
        grade: '',
        class: '',
        student: '',
        status: '',
        checkStatusNopKqht: ''
      },
      rulesSearch: {
        grade: [{
          required: true,
          message: 'Bạn chưa chọn khối học',
          trigger: 'change'
        }],
        class: [{
          required: true,
          message: 'Bạn chưa chọn lớp học',
          trigger: 'change'
        }],
      },
      itemEdit: '',
      indexSelect: null,
      dataUpload: [],
      showPermissionEdit: false,
      lstMonDetail: '',
      lyDoGVCN: '',
      dataPermission: '',
      lydoHieuTruong: '',
      dataBomon: '',
      showPopUpBoMon: false,
      lyDoBoMon: '',
      duLieuUploadMutilFile: '',
    }
  },
  methods: {
    xemChiTietLichSu(data) {
      console.log('xemChiTietLichSu')
      console.log(data)
      if (data && data.length) {
        this.chi_tiet_nop_ho_so = data
        this.hien_thi_chi_tiet_nop = true;
      } else {
        this.thongBao('error', 'Không có thông tin chi tiết')
      }
    },
    xemTrangThai(item) {
      console.log('Xem trạng thái')
      console.log(item)
      this.fullscreenLoading = true;
      api.get('/hocbadientu-sync-service/api/lich-su-nop-du-lieu/trang-thai-yeu-cau-dang-nop', {
        maLop: this.lopHocHienTai,
        uuid: item.uuid
      }).then(
          response => {
            this.fullscreenLoading = false;
            console.log("Res lịch sử nộp:")
            console.log(response)
            if (response.data.code == 200) {
              this.thongBao('success', response.data.data.MO_TA_LOI ?? response.data.msg)
            } else {
              this.thongBao("error", "Không lấy được dữ liệu.")
            }
            this.fullscreenLoading = false;
          }
      )
    },
    xemLichSu() {
      console.log('xemLichSu')
      this.fullscreenLoading = true;
      this.thongTinLichSu.DA_NOP = []
      this.thongTinLichSu.DANG_XU_LY = []
      api.get('/hocbadientu-sync-service/api/lich-su-nop-du-lieu/lich-su-theo-lop', {
        maLop: this.searchForm.class
      }).then(
          response => {
            this.fullscreenLoading = false;
            console.log("Res lịch sử nộp:")
            console.log(response)
            if (response.data.code == 200) {
              this.thongTinLichSu = response.data.data;
              this.show_lich_su = true
            } else {
              this.thongBao("error", "Không lấy được dữ liệu.")
            }
            console.log('Thông tin lịch sử:')
            console.log(this.thongTinLichSu)
            this.fullscreenLoading = false;
          }
      )
    },
    handleCloseChonKieuKy() {
      this.showChonKieuKy = false;
      return;
      this.kieuKyGiaoVien ? (this.showChonKieuKy = false) :
          (this.thongBao('error', "Vui lòng chọn kiểu ký"), this.showChonKieuKy = true);
    },
    chonKieuKy() {
      console.error('Chọn kiểu kỳ:')
      console.error(this.kieuKyGiaoVien)
      localStorage.setItem('kieuKyBiaGiaoVien', this.kieuKyGiaoVien);
      this.showChonKieuKy = false;
    },
    getPathImg(item) {
      let result = window.location.protocol + '//' + window.location.hostname + '/images/' + item.icon;
      return result;
    },
    getKieuKy() {
      console.log('getKieuKy');
      this.fullscreenLoading = true;
      let uri = constant_2.giaovien.getKieuKy;
      console.log('uri:' + uri)
      let params = {}
      this.listKieuKy = [];
      api.get(uri, params).then(
          response => {
            console.error("Kiểu ký trả về:")
            console.error(response)
            var kieuKyBiaGiaoVien = localStorage.getItem('kieuKyBiaGiaoVien')
            if (response.data.code == 200) {
              this.countTypeSign = response.data.data.toString().split('').length;
              if (this.countTypeSign > 1) {
                let listKieuKy = response.data.data.toString().split('');
                for (let i = 0; i < listKieuKy.length; i++) {
                  if (listKieuKy[i] == 1) {
                    this.listKieuKy.push(this.typeOtpSms)
                  }
                  if (listKieuKy[i] == 2) {
                    this.listKieuKy.push(this.typeSimCa)
                  }
                  if (listKieuKy[i] == 4) {
                    this.listKieuKy.push(this.typeUsbToken)
                  }
                  if (listKieuKy[i] == 5) {
                    this.listKieuKy.push(this.typeSmartCaVt)
                  }
                  if (listKieuKy[i] == 6) {
                    this.listKieuKy.push(this.typeSmartCaVnpt)
                  }
                  if (listKieuKy[i] == 7) {
                    this.listKieuKy.push(this.typeSmartVGCA)
                  }
                }
                console.error(this.listKieuKy)
                if (!kieuKyBiaGiaoVien) {
                  this.showChonKieuKy = true;
                } else {
                  this.kieuKyGiaoVien = parseInt(kieuKyBiaGiaoVien)
                }
              } else {
                this.kieuKyGiaoVien = response.data.data
                console.error(this.kieuKyGiaoVien)

              }
            }
            this.fullscreenLoading = false;
          }
      ).catch((e) => {
        // this.thongBao('success', 'Hệ thống đang cập nhật dữ liệu. Vui lòng chờ trong ít phút.');
        this.fullscreenLoading = false;
      })
    },
    getTrangThaiHieuChinh(item) {
      let str = '';
      if (item == -1) {
        str = ''
      }
      if (item == 0) {
        str = 'Chưa xác nhận'
      }
      if (item == 1) {
        str = 'Đã xác nhận'
      }
      if (item == 2) {
        str = 'Đang xử lý'
      }
      if (item == 3) {
        str = 'Từ chối yêu cầu'
      }
      return str;
    },
    getTrangThaiNop(item) {
      let str = 'Chưa nộp';
      if (item == 1) {
        str = 'Đã xác nhận từ sở'
      }
      if (item == 2) {
        str = 'Đang nộp'
      }
      if (item == 3) {
        str = 'Đã nộp lên Sở'
      }
      if (item == 4) {
        str = 'Chưa nộp lên Sở'
      }
      if (item == 5) {
        str = 'Nộp thất bại'
      }
      return str;
    },
    handleCloseNopHieuChinh() {
      this.show_nop_hieu_chinh = false;
    },
    getHeightTable() {
      if (this.window.height && this.window.height > 950) {
        return this.window.height - 100
      }
      if (this.window.height && this.window.height > 850) {
        return 800
      }
      if (this.window.height && this.window.height > 750) {
        return 700
      }
      return 600
    },
    kiemTraTrangThaiNop(item) {
      console.log('kiemTraTrangThaiNop')
      console.log(item)
      this.fullscreenLoading = true;
      let params = {
        maTruong: item.maTruong,
        namHoc: item.namHoc,
        maLop: item.maLop,
        tenYeuCau: "DANG_KY_SERIAL",
        content: "",
        messageId: "95b6828f-be02-446e-aa49-2c7d4e7122e1"
      }
      let uri = '/hocbadientu-sync-service/api/nop-hoc-ba/kiem-tra-trang-thai-yeu-cau';
      api.post(uri, params).then(data => {
        if (data.data.code === 200) {
        } else {
        }
        this.fullscreenLoading = false;
      }).catch((e) => {
      })
    },
    ChonSoLuongBanGhi(e) {
      console.log('ChonSoLuongBanGhi');
      console.log(e)
      this.showDialogOtp = false;
      this.paginate.start = 0;
      this.paginate.limit = e.soluong;
      this.paginate.current = 1;
      this.getDataCurenPage();
    },
    getTime() {
      var d = new Date();
      var n = d.getTime();
      return n;
    },
    namHocChange() {
      this.searchForm.student = '';
      this.searchForm.status = '';
      this.searchForm.grade = ''
      this.searchForm.class = ''
    },

    LopHocChange() {
      this.getlistHocSinh();
    },
    submitPermissionHieuTruong() {
      if (!this.lydoHieuTruong) {
        this.$alert('Vui lòng nhập lý do', 'Thông báo', {
          confirmButtonText: 'Đóng',
          type: 'warning',
          center: true,
          callback: action => {

          }
        });
        return;
      }

      let params = {
        type: 3,
        ghiChu: this.lydoHieuTruong,
        id: this.dataPermission.id,
      }


      this.$confirm('Xác nhận phân quyền cho hiệu trưởng?', 'Warning', {
        confirmButtonText: 'Xác nhận',
        cancelButtonText: 'Hủy',
        center: true,
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        hocbaService.permission(params).then(res => {
          if (res.data.code == 200) {
            this.showPermissionEdit = false;

            let params = {
              id: this.dataPermission.id
            }
            hocbaService.getDetail(params).then(res => {
              if (res.data.code == 200) {
                this.lstMonDetail = res.data.data;
                this.lyDoGVCN = this.lstMonDetail.ghiChuGVCN ? this
                    .lstMonDetail.ghiChuGVCN : '';
                this.lydoHieuTruong = this.lstMonDetail.ghiChuHieuTruong ?
                    this.lstMonDetail.ghiChuHieuTruong : '';
              }
            }).catch((e) => {

            })

            this.thongBao('success', res.data.msg);
          } else {
            this.thongBao('error', res.data.msg);
          }

        }).catch((e) => {
          this.thongBao('warning', 'Lỗi kết nối API')
        })
      }).catch(() => {

      });

    },


    submitPermissionGVCN() {
      if (!this.lyDoGVCN) {
        this.$alert('Vui lòng nhập lý do', 'Thông báo', {
          confirmButtonText: 'Đóng',
          type: 'warning',
          center: true,
          callback: action => {

          }
        });
        return;
      }

      let params = {
        type: 2,
        ghiChu: this.lyDoGVCN,
        id: this.dataPermission.id,
      }

      this.$confirm('Xác nhận phân quyền cho GVCN?', 'Warning', {
        confirmButtonText: 'Xác nhận',
        cancelButtonText: 'Hủy',
        center: true,
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        hocbaService.permission(params).then(res => {
          if (res.data.code == 200) {
            this.showPermissionEdit = false;

            let params = {
              id: this.dataPermission.id
            }
            hocbaService.getDetail(params).then(res => {
              if (res.data.code == 200) {
                this.lstMonDetail = res.data.data;
                this.lyDoGVCN = this.lstMonDetail.ghiChuGVCN ? this
                    .lstMonDetail.ghiChuGVCN : '';
                this.lydoHieuTruong = this.lstMonDetail.ghiChuHieuTruong ?
                    this.lstMonDetail.ghiChuHieuTruong : '';
              }
            }).catch((e) => {

            })

            this.thongBao('success', res.data.msg);
          } else {
            this.thongBao('error', res.data.msg);
          }

        }).catch((e) => {
          this.thongBao('warning', 'Lỗi kết nối API')
        })
      }).catch(() => {

      });


    },


    submitPermissionBoMon() {
      if (!this.lyDoBoMon) {
        this.$alert('Vui lòng nhập lý do', 'Thông báo', {
          confirmButtonText: 'Đóng',
          type: 'warning',
          center: true,
          callback: action => {

          }
        });
        return;
      }

      let params = {
        type: 1,
        ghiChu: this.lyDoBoMon,
        maMonHoc: this.dataBomon.maMonHoc,
        id: this.dataPermission.id,
      }

      this.$confirm('Xác nhận phân quyền cho GV bộ môn?', 'Warning', {
        confirmButtonText: 'Xác nhận',
        cancelButtonText: 'Hủy',
        center: true,
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        hocbaService.permission(params).then(res => {
          if (res.data.code == 200) {
            this.showPopUpBoMon = false;

            let params = {
              id: this.dataPermission.id
            }
            hocbaService.getDetail(params).then(res => {
              if (res.data.code == 200) {
                this.lstMonDetail = res.data.data;
                this.lyDoGVCN = this.lstMonDetail.ghiChuGVCN ? this
                    .lstMonDetail.ghiChuGVCN : '';
                this.lydoHieuTruong = this.lstMonDetail.ghiChuHieuTruong ?
                    this.lstMonDetail.ghiChuHieuTruong : '';
              }
            }).catch((e) => {

            })

            this.thongBao('success', res.data.msg);
          } else {
            this.thongBao('error', res.data.msg);
          }

        }).catch((e) => {
          this.thongBao('warning', 'Lỗi kết nối API')
        })
      }).catch(() => {

      });


    },

    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t
      }
      if (type == "success") {
        cl = "dts-noty-success"
      }
      if (type == "warning") {
        cl = "dts-noty-warning"
      }
      if (type == "error") {
        cl = "dts-noty-error"
      }
      if (type == "info") {
        cl = "dts-noty-info"
      }
      document.querySelector('.overlay').style.display = 'block';
      this.$message({
        onClose: () => {
          document.querySelector('.overlay').style.display = 'none';
        },
        customClass: cl,
        dangerouslyUseHTMLString: true,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000
      });
    },

    phanQuyenBoMon(data) {
      this.lyDoBoMon = data.ghiChu ? data.ghiChu : '';
      this.dataBomon = data;
      this.showPopUpBoMon = true;
    },

    showError(err, file, fileList) {
      console.log(err, file, fileList)

    },

    permissionEdit(data) {
      this.dataPermission = data;
      this.lyDoGVCN = '';
      this.lydoHieuTruong = '';
      let params = {
        id: data.id
      }
      hocbaService.getDetail(params).then(res => {
        if (res.data.code == 200) {
          this.lstMonDetail = res.data.data;
          this.lyDoGVCN = this.lstMonDetail.ghiChuGVCN ? this.lstMonDetail.ghiChuGVCN : '';
          this.lydoHieuTruong = this.lstMonDetail.ghiChuHieuTruong ? this.lstMonDetail
              .ghiChuHieuTruong : '';
        }
      }).catch((e) => {

      })
      this.showPermissionEdit = true;
    },


    checkShowUploadBia() {
      if (!this.searchForm.class) {
        this.$alert('Vui lòng chọn lớp học để thực hiện chức năng.', 'Thông báo', {
          type: 'warning',
          confirmButtonText: 'Đóng',
          center: true
        })
        this.showUploadBia = false;
      } else {
        this.showUploadBia = true;
      }
    },

    checkShowUploadKQHT() {
      if (!this.searchForm.class) {
        this.$alert('Vui lòng chọn lớp học để thực hiện chức năng.', 'Thông báo', {
          type: 'warning',
          confirmButtonText: 'Đóng',
          center: true
        })
        this.showUploadHocBa = false;
      } else {
        this.showUploadHocBa = true;
      }
    },

    uploadFileHocBa() {
      if (!this.dataUpload.length) {
        this.$alert('Chưa có file nào được chọn.', 'Thông báo', {
          type: 'warning',
          confirmButtonText: 'Đóng',
          center: true
        })
        return;
      }

      this.fullscreenLoading = true;

      let params = {
        data: LZString.compressToBase64(JSON.stringify(this.dataUpload)),
        maLop: this.searchForm.class,
        namHoc: this.searchForm.year
      }

      let uri = constant_api.hocsinh.uploadMultiFileKQHTPdf;

      api.post(uri, params).then(data => {
        if (data.data.code === 200) {
          document.querySelector('.overlay').style.display = 'block';
          this.$message({
            onClose: () => {
              document.querySelector('.overlay').style.display = 'none';
            },
            customClass: 'dts-noty-success',
            showClose: true,
            message: data.data.msg,
            type: 'success',
            duration: 3000
          });
          this.getDataCurenPage();
        } else {
          document.querySelector('.overlay').style.display = 'block';
          this.$message({
            onClose: () => {
              document.querySelector('.overlay').style.display = 'none';
            },
            customClass: 'dts-noty-error',
            showClose: true,
            message: data.data.msg,
            type: 'error',
            duration: 3000
          });
        }
        this.$refs.uploadFileHocBa.clearFiles();
        this.fullscreenLoading = false;
        this.showUploadHocBa = false
      }).catch((e) => {
        document.querySelector('.overlay').style.display = 'block';
        this.$message({
          onClose: () => {
            document.querySelector('.overlay').style.display = 'none';
          },
          customClass: 'dts-noty-error',
          showClose: true,
          message: 'Lỗi kết nối',
          type: 'error',
          duration: 3000
        });
        this.$refs.uploadFileHocBa.clearFiles();
        this.fullscreenLoading = false;
        this.showUploadHocBa = false
      })
    },

    uploadBiaHocBa() {
      if (!this.dataUpload.length) {
        this.$alert('Chưa có file nào được chọn.', 'Thông báo', {
          type: 'warning',
          confirmButtonText: 'Đóng',
          center: true
        })
        return;
      }

      this.fullscreenLoading = true;
      var dataForm = new FormData()
      dataForm.append('namHoc', this.searchForm.year)
      dataForm.append('capHoc', this.searchForm.education)
      dataForm.append('maLop', this.searchForm.class)
      dataForm.append('khoiHoc', this.searchForm.grade)
      if (this.dataFormScanBia && this.dataFormScanBia.length) {
        Array
            .from(Array(this.dataFormScanBia.length).keys())
            .map(x => {
              dataForm.append('file', this.dataFormScanBia[x], this.dataFormScanBia[x].name)
            })
      }
      let uri = constant_api.hocsinh.uploadScanBiaHocBa;
      api.post(uri, dataForm).then(
          response => {
            if (response && response.data.rc == 0) {
              this.thongBao('success', 'Chỉnh sửa dữ liệu thành công')
            } else {
              this.thongBao('error', 'Hệ thống bận. Vui lòng thử lại sau ít phút.')
            }
            this.fullscreenLoading = false;
          }
      ).catch((e) => {
      })

      return;
      let params = {
        data: LZString.compressToBase64(JSON.stringify(this.dataUpload)),
        maLop: this.searchForm.class,
        namHoc: this.searchForm.year
      }

      // let uri = constant_api.hocsinh.uploadMultiFileBiaPdf;

      api.post(uri, params).then(data => {
        if (data.data.code === 200) {
          this.$message({
            customClass: 'dts-noty-success',
            showClose: true,
            message: data.data.msg,
            type: 'success',
            duration: 3000
          });
          this.getDataCurenPage();
        } else {
          this.$message({
            customClass: 'dts-noty-error',
            showClose: true,
            message: data.data.msg,
            type: 'error',
            duration: 3000
          });
        }
        this.$refs.uploadBia.clearFiles();
        this.fullscreenLoading = false;
        this.showUploadBia = false
      }).catch((e) => {
        document.querySelector('.overlay').style.display = 'block';
        this.$message({
          onClose: () => {
            document.querySelector('.overlay').style.display = 'none';
          },
          customClass: 'dts-noty-error',
          showClose: true,
          message: 'Lỗi kết nối',
          type: 'error',
          duration: 3000
        });
        this.$refs.uploadBia.clearFiles();
        this.fullscreenLoading = false;
        this.showUploadBia = false
      })
    },

    uploadMutilAvatar() {

      this.fullscreenLoading = true;
      if (!this.dataUpload.length) {
        this.$alert('Chưa có file nào được chọn.', 'Thông báo', {
          type: 'warning',
          confirmButtonText: 'Đóng',
          center: true
        })
        return;
      }
      setTimeout(() => {
        this.duLieuUploadMutilFile = LZString.compressToBase64(JSON.stringify(this.dataUpload))
      }, 500)


    },

    readAsDataURL(name, file) {
      return new Promise((resolve, reject) => {
        let fr = new FileReader();

        fr.readAsDataURL(file);

        fr.onload = (e) => {
          resolve({
            id: name,
            data: e.target.result
          });
        };


      });
    },

    uploadMutilAvatarChange(file, fileList) {
      this.dataUpload = [];
      if (fileList.length > 100) {
        this.$alert('Số lượng file đã vượt quá giới hạn cho phép là 100.', 'Thông báo', {
          type: 'warning',
          confirmButtonText: 'Đóng',
          center: true
        })
        return;
      }
      if (fileList.length) {
        let readers = [];
        this.fullscreenLoading = true
        fileList.forEach(item => {
          readers.push(this.readAsDataURL(item.name, item.raw));
          this.dataFormScanBia.push(item.raw)
        })
        Promise.all(readers).then((values) => {
          this.dataUpload = values;
          this.fullscreenLoading = false;
        });
      }
    },

    updateMutileImage() {
      this.showUpdateMutile = true;
    },
    dongBoDuLieu(hocsinh) {
      let uri = constant_api.school.dongBo;
      let params = {
        maHSs: [hocsinh.maHS],
        loaiDongBo: 4,
        namHoc: this.searchForm.year,
        maTruongs: [this.user.maTruong],
        maSo: this.user.maSo,
        maPhong: this.user.maPhong,
        nguoiDongBo: this.user.displayName,
        capHoc: this.searchForm.education,
      }
      this.$confirm('Xác nhận đồng bộ lại dữ liệu của học sinh?', 'Thông báo', {
        confirmButtonText: 'Đồng bộ',
        cancelButtonText: 'Hủy',
        confirmButtonClass: 'el-button--success',
        closeOnClickModal: false,
      })
          .then(_ => {
            this.fullscreenLoading = true
            api.post(uri, params).then(data => {
              if (data.data.code === 200) {
                this.thongBao('success', data.data.msg)
                this.getDataCurenPage();
              } else {
                this.thongBao('error', data.data.msg)
              }
              this.fullscreenLoading = false
            }).catch((e) => {
              this.thongBao('error', 'Lỗi kết nối. Vui lòng thử lại')
            })
          })
          .catch(_ => {
          });

    },
    removeAvatar(hocsinh) {
      let uri = constant_api.hocsinh.removeAvatar;
      let params = {
        id: hocsinh.maHS,
      }
      this.$confirm('Xác nhận xoá ảnh học sinh?', 'Thông báo', {
        confirmButtonText: 'Xoá ảnh',
        cancelButtonText: 'Hủy',
        confirmButtonClass: 'el-button--danger'
      })
          .then(_ => {
            api.post(uri, params).then(data => {
              if (data.data.code === 200) {
                document.querySelector('.overlay').style.display = 'block';
                this.$message({
                  onClose: () => {
                    document.querySelector('.overlay').style.display = 'none';
                  },
                  customClass: 'dts-noty-success',
                  showClose: true,
                  message: data.data.msg,
                  type: 'success',
                  duration: 3000
                });
                this.getDataCurenPage();
              } else {
                document.querySelector('.overlay').style.display = 'block';
                this.$message({
                  onClose: () => {
                    document.querySelector('.overlay').style.display = 'none';
                  },
                  customClass: 'dts-noty-error',
                  showClose: true,
                  message: data.data.msg,
                  type: 'error',
                  duration: 3000
                });
              }
            }).catch((e) => {
              document.querySelector('.overlay').style.display = 'block';
              this.$message({
                onClose: () => {
                  document.querySelector('.overlay').style.display = 'none';
                },
                customClass: 'dts-noty-error',
                showClose: true,
                message: 'Lỗi kết nối',
                type: 'error',
                duration: 3000
              });
            })
          })
          .catch(_ => {
          });

    },

    uploadAvatarHocSinh(id, avatar) {
      let uri = constant_api.hocsinh.uploadAvatar;
      let params = {
        id: id,
        data: avatar
      }
      api.post(uri, params).then(data => {
        if (data.data.code === 200) {
          document.querySelector('.overlay').style.display = 'block';
          this.$message({
            onClose: () => {
              document.querySelector('.overlay').style.display = 'none';
            },
            customClass: 'dts-noty-success',
            showClose: true,
            message: data.data.msg,
            type: 'success',
            duration: 3000
          });
          this.getDataCurenPage();
        } else {
          this.$message({
            customClass: 'dts-noty-error',
            showClose: true,
            message: data.data.msg,
            type: 'error',
            duration: 3000
          });
        }
      }).catch((e) => {
        this.$message({
          customClass: 'dts-noty-error',
          showClose: true,
          message: 'Lỗi kết nối',
          type: 'error',
          duration: 3000
        });
      })
    },

    uploadAvatar(file) {
      let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      if (!allowedExtensions.exec(file.name)) {
        this.thongBao('error', 'Vui lòng chọn file ảnh.')
        return false;
      }
      let reader = new FileReader()
      reader.readAsDataURL(file.raw);
      reader.onload = (e) => {
        let data;
        if (typeof e.target.result === 'object') {
          data = window.URL.createObjectURL(new Blob([e.target.result]))
        } else {
          data = e.target.result
        }
        if (data) {
          this.uploadAvatarHocSinh(this.hocSinhSelect.maHS, data)
        }
      }
    },

    xemHocBa(e) {
      this.idHocBa = e;
      this.thongTinHocBa = true;
    },

    getCurrentMonth() {
      let d = new Date();
      return d.getMonth();
    },
    ...mapActions('teacher', [
      "clear",
      "updateHocBa",
      "uploadHocBa",
      "uploadFileBiaHocBa",
    ]),
    ...mapActions("hocba", [
      "getDetail",
      "saveTempKq",
      "getFile",
      "setDataHocSinh"
    ]),
    getDataCurenPage() {
      let params = {
        start: this.paginate.start,
        limit: this.paginate.limit,
        maLop: this.searchForm.class,
        namHoc: this.searchForm.year ? this.searchForm.year : '',
        capHoc: this.searchForm.education,
        khoiHoc: this.searchForm.grade,
        maHS: this.searchForm.student,
        checkStatusNopKqht: this.searchForm.checkStatusNopKqht,
        maSo: this.user.maSo,
        maPhong: this.user.maPhong,
        maTruong: this.user.maTruong,
      }
      this.lopHocHienTai = JSON.parse(JSON.stringify(this.searchForm.class))
      this.getListHocBa(params);
    },
    async getListHocBa(params) {
      console.log("Lấy danh sách học bạ:")
      console.log(params)
      this.khongChoChonTatCaBia = true;
      this.khongChoChonTatCaKQHT = true;
      this.khongChoChonTatCaThuHoi = true;
      this.listHocSinhCheck = [];
      this.listHocSinhCheckBia = [];
      this.listHocSinhCheckThuHoi = [];
      try {
        // this.listHocSinhCheck = [];
        // this.listHocSinhCheckBia = [];
        // this.listHocSinhCheckThuHoi = [];
        this.CheckAllKQHT = false;
        this.CheckAllThuHoi = false;
        this.show_ly_do_thu_hoi = false;
        this.CheckAllBia = false;
        let uri = constant_api.hocsinh.getListHSNopHoSoV2;
        console.log('uri: ' + uri)
        this.show_nop = false;
        this.fullscreenLoading = true;
        this.listHs.list = [];
        this.listHs.total = 0;
        api.get(uri, params).then(
            response => {
              console.log("Lấy ds học bạ trả về:")
              console.log(response);
              if (response.data.code == 200) {
                let listHs = response.data.data;
                console.log('listHs:')
                console.log(listHs)
                console.log('năm học:' + listHs.namHoc)
                console.log('khối học:' + listHs.khoiHoc)
                let namHoc = listHs.namHoc;
                let khoiHoc = listHs.khoiHoc;
                this.khoiHocXem = khoiHoc
                let arr_khoi_hoc = [1, 2, 3, 4];
                if (arr_khoi_hoc.includes(khoiHoc) && namHoc >= 2023 || namHoc >= 2024) {
                  console.log('Ẩn')
                  this.show_nop = false;
                } else {
                  this.show_nop = true;
                  console.log('Hiện')
                }
                if (!listHs.list || listHs.list.length == 0) {
                  this.thongBao('error', 'Không tìm thấy dữ liệu')
                } else {
                  listHs.list.forEach(function (element) {
                    element.checkChonKqht = false;
                    element.checkChonBia = false;
                    element.checkChonThuHoi = false;
                  });
                  this.listHs = JSON.parse(JSON.stringify(listHs));

                  this.khongChoChonTatCaBia = !(this.listHs.list.some(obj => obj.checkStatusNopBia === false))
                  this.khongChoChonTatCaKQHT = !(this.listHs.list.some(obj => obj.checkStatusNopKqht === false))
                  this.khongChoChonTatCaThuHoi = !(this.listHs.list.some(obj => obj.trangThaiHocBaNopLenSo == 1))
                  // this.thongBao('success', 'Lấy dữ liệu thành công')
                  const el = this.$refs.page_content;
                  // this.mapTrangThaiNopHoSo(listHs)

                  if (el) {
                    el.scrollIntoView({behavior: "smooth"});
                  }
                }
                console.log('listHs:')
                console.log(this.listHs)
              } else {
                this.thongBao('error', 'Không tìm thấy dữ liệu')
              }
              this.fullscreenLoading = false;
            }
        ).catch((e) => {
          // this.thongBao('success', 'Hệ thống đang cập nhật dữ liệu. Vui lòng chờ trong ít phút.');
          this.fullscreenLoading = false;
        })

      } catch (e) {
        this.thongBao('error', 'Vui lòng thao tác lại.')
      }
    },
    async mapTrangThaiNopHoSo(data) {
      console.log('map danh sách học sinh kèm trạng thái nộp hồ sơ')
      console.log(data)
      let arr = data.list;
      for (let i = 0; i < arr.length; i++) {
        let hs = await this.getTrangThaiNopHoSo(arr[i]);
      }

    },
    getTrangThaiNopHoSo(hs) {
      console.log('getTrangThaiNopHoSo')
      console.log(hs)
      let uri = constant_api.school.checkTrangThaiNopHoSoLenBo;
      let params = {
        maHSs: [hs.maHS],
        maTruong: hs.maTruong,
        namHoc: hs.namHoc,
        loaiNop: "1"
      }
      this.fullscreenLoading = true;
      api.post(uri, params).then(
          response => {
            console.log("Lấy trạng học bạ trả về:")
            console.log(response);
            this.fullscreenLoading = false;
          }
      ).catch((e) => {
        this.fullscreenLoading = false;
      })
    },
    closeDialogAlert() {
      let select = this.listHs.list[this.indexSelect];
      this.listHocBa = select.hocBas ? select.hocBas : [];
      console.log(this.listHs);
      this.showDialogAlert = false;
    },
    chinhSuaNgayKyBia() {
      if (!this.infoUpdate.tenDiaDanhKyBia || this.infoUpdate.tenDiaDanhKyBia == '') {
        this.thongBao('error', 'Thiếu thông tin địa danh ký.')
        return;
      }
      if (!this.infoUpdate.ngayKyBia || this.infoUpdate.ngayKyBia == '') {
        this.thongBao('error', 'Thiếu thông tin ngày ký lý lịch.')
        return;
      }

      this.$confirm('Xác nhận chỉnh sửa thông tin học sinh: ' + this.infoUpdate.tenHS + ' - [' + this.infoUpdate.maHS + '] thành: ' + this.infoUpdate.ngayKyBia + '?', 'Thông báo', {
        confirmButtonText: 'Chỉnh sửa',
        cancelButtonText: 'Hủy',
        confirmButtonClass: 'el-button--warning',
      })
          .then(_ => {

            let uri = constant_api.hocsinh.capNhatNgayKyBia;
            let params = {
              capHoc: this.infoUpdate.capHoc,
              maHS: this.infoUpdate.maHS,
              tenDiaDanhKyBia: this.infoUpdate.tenDiaDanhKyBia,
              thoiGianVaoTruong: this.infoUpdate.ngayKyBia
            }
            this.fullscreenLoading = true;
            api.post(uri, params).then(
                response => {
                  console.log("Lấy ds học bạ trả về:")
                  console.log(response);
                  if (response.data.code == 200) {
                    this.thongBao('success', response.data.msg)
                    this.show_update_ngay_vao_truong = false;
                    this.infoUpdate = {};
                    this.getDataCurenPage();
                  } else {
                    this.thongBao('error', 'Vui lòng thử lại sau ít phút')
                  }
                  this.fullscreenLoading = false;
                }
            ).catch((e) => {
              this.fullscreenLoading = false;
            })
          })
          .catch(_ => {
          });

    },
    hienThiCapNhatNgayVaoTruong(item) {
      this.show_update_ngay_vao_truong = true;
      this.infoUpdate = JSON.parse(JSON.stringify(item));

    },
    showDialogInfoHocBa(item, index) {
      this.listHocBa = [];
      this.indexSelect = index;
      if (item && item.hocBas && item.hocBas.length > 0) {
        this.listHocBa = item.hocBas;
        this.showInfoHocba = true;
      } else {
        this.thongBao('error', 'Chưa có thông tin chi tiết')
      }
    },
    addHocBa(data) {
      this.setDataHocSinh(data);
      // this.$router.push({name : 'ThemHocBa'})
      this.showAddHocBa = true
    },

    suaHocBa(data) {
      this.setDataHocSinh(data);
      this.showEditHocBa = true;
    },
    closeDialogEdit() {
      this.getDataCurenPage();

      this.showEditHocBa = false;
    },
    closeDialogCreate() {
      this.getDataCurenPage();
      this.showInfoHocba = false;
      this.showAddHocBa = false;
      this.thongTinHocBa = false;
    },
    onSizeExceeded(size) {
      const h = this.$createElement;
      this.$notify.error({
        title: 'Thông báo',
        message: `Vượt quá giới hạn ${this.customImageMaxSize}Mb!`
      });
    },

    onLoad(dataUri) {
      this.uploadHocBa({
        data: dataUri,
        id: this.dataSelect
      });
      console.log(this.dataSelect);
    },
    onBiaLoad(dataUri) {
      this.uploadFileBiaHocBa({
        data: dataUri,
        id: this.dataSelect.maHS,
        capHoc: this.dataSelect.loaiHocBa,
        namHoc: this.dataSelect.namHoc
      });
      console.log(this.dataSelect);
    },

    indexMethod(index) {
      return (this.paginate.start + index) + 1;
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.listFile.toggleRowSelection(row);
        });
      } else {
        this.$refs.listFile.clearSelection();
      }
    },
    getKhoiHoc() {
      this.optionGrade = [];
      if (this.searchForm.education == 1) {
        this.optionGrade = this.khoiCap1;
      } else if (this.searchForm.education == 2) {
        this.optionGrade = this.khoiCap2;
      } else if (this.searchForm.education == 452) {
        this.optionGrade = this.khoiCap2;
      } else if (this.searchForm.education == 3) {
        this.optionGrade = this.khoiCap3;
      } else if (this.searchForm.education == 36) {
        this.optionGrade = this.khoiCap3;
      } else if (this.searchForm.education == 12) {
        this.optionGrade = this.khoiCap1.concat(this.khoiCap2)
      } else if (this.searchForm.education == 23) {
        this.optionGrade = this.khoiCap2.concat(this.khoiCap3)
      } else if (this.searchForm.education == 236) {
        this.optionGrade = this.khoiCap2.concat(this.khoiCap3)
      } else if (this.searchForm.education == 13) {
        this.optionGrade = this.khoiCap1.concat(this.khoiCap3)
      } else if (this.searchForm.education == 1245) {
        this.optionGrade = this.khoiCap1.concat(this.khoiCap2)
      } else if (this.searchForm.education == 123) {
        this.optionGrade = this.khoiCap1.concat(this.khoiCap2).concat(this.khoiCap3)
      } else if (this.searchForm.education == 12345) {
        this.optionGrade = this.khoiCap1.concat(this.khoiCap2).concat(this.khoiCap3)
      } else if (this.searchForm.education == 1235) {
        this.optionGrade = this.khoiCap1.concat(this.khoiCap2).concat(this.khoiCap3)
      } else if (this.searchForm.education == 145) {
        this.optionGrade = this.khoiCap1
      } else if (this.searchForm.education == 14) {
        this.optionGrade = this.khoiCap1
      } else if (this.searchForm.education == 451) {
        this.optionGrade = this.khoiCap1
      } else {
        this.optionGrade = this.khoiCap1.concat(this.khoiCap2, this.khoiCap3)
      }
    },
    closeDialogOtp() {
      this.toggleSelection();
      this.showDialogOtp = false;
    },
    chonMoiHocBaNopHoSo(e) {
      console.log('chonMoiHocBaNopHoSo')
      console.log(e.checkChonKqht)
      console.log("CHeck")
      let newCheck = e.checkChonKqht;
      console.log('Chuyn thành: ' + newCheck)
      for (let i = 0; i < this.listHs.list.length; i++) {
        if (this.listHs.list[i].id == e.id) {
          this.listHs.list[i].checkChonKqht = newCheck;
        }
      }
      this.maplistHocSinhNopHoSo();
    },
    chonMoiHocBaThuHoi(e) {
      console.log('chonMoiHocBaThuHoi')
      console.log(e.checkChonThuHoi)
      console.log("CHeck")
      let newCheck = e.checkChonThuHoi;
      console.log('Chuyn thành: ' + newCheck)
      for (let i = 0; i < this.listHs.list.length; i++) {
        if (this.listHs.list[i].id == e.id) {
          this.listHs.list[i].checkChonThuHoi = newCheck;
        }
      }
      this.maplistHocSinhThuHoiHoSo();
    },
    maplistHocSinhNopHoSo() {
      console.log('maplistHocSinhNopHoSo')
      let data = [];
      for (let i = 0; i < this.listHs.list.length; i++) {
        if (this.listHs.list[i].checkChonKqht == true) {
          data.push(this.listHs.list[i])
        }
      }
      this.listHocSinhCheck = data;
      console.log("Danh sách học bạ check:")
      console.log(this.listHocSinhCheck)
      // if (this.listHocSinhCheck.length == this.listHs.list.length) {
      //   console.log('Case chọn tất cả')
      //   this.CheckAllKQHT = true;
      // } else {
      //   console.log('Case huỷ tất cả')
      //   this.CheckAllKQHT = false;
      // }
    },
    ChonToanBoHocBaThuHoi() {
      console.log('ChonToanBoHocBaThuHoi')
      if (this.CheckAllThuHoi) {
        console.log('case 1:')
        console.log(this.listHs.list.length)
        for (let i = 0; i < this.listHs.list.length; i++) {
          if (this.listHs.list[i].trangThaiHocBaNopLenSo == 1) {
            this.listHs.list[i].checkChonThuHoi = true;
          }
          console.log(this.listHs.list[i])
        }
      } else {
        console.log('case 2:')
        for (let i = 0; i < this.listHs.list.length; i++) {
          this.listHs.list[i].checkChonThuHoi = false;
        }
      }
      this.maplistHocSinhThuHoiHoSo();
    },
    maplistHocSinhThuHoiHoSo() {
      console.log('maplistHocSinhThuHoiHoSo')
      let data = [];
      for (let i = 0; i < this.listHs.list.length; i++) {
        if (this.listHs.list[i].checkChonThuHoi == true) {
          data.push(this.listHs.list[i])
        }
      }
      this.listHocSinhCheckThuHoi = data;
      console.log("Danh sách học bạ check thu hồi:")
      console.log(this.listHocSinhCheckThuHoi)
      console.log(this.listHocSinhCheckThuHoi.length)
      console.log(this.listHs.list.length)
      // if ((this.listHocSinhCheckThuHoi.length == this.listHs.list.length) && this.listHs.list.length != 0) {
      //   console.log('Case chọn tất cả')
      //   this.CheckAllThuHoi = true;
      // } else {
      //   console.log('Case huỷ tất cả')
      //   this.CheckAllThuHoi = false;
      // }
      if (this.listHocSinhCheckThuHoi && this.listHocSinhCheckThuHoi.length) {
        // this.thongBao('success', 'Đã chọn được ' + this.listHocSinhCheckThuHoi.length + ' học sinh')
      } else {
        // this.thongBao('error', 'Không chọn được học sinh nào có thể thu hồi hồ sơ')
      }
    },
    ChonToanBoHocBaNopHoSoLenBo() {
      console.log('ChonToanBoHocBaNopHoSoLenBo')
      if (this.CheckAllKQHT) {
        console.log('case 1:')
        console.log(this.listHs.list.length)
        for (let i = 0; i < this.listHs.list.length; i++) {
          if (!this.listHs.list[i].checkStatusNopKqht) {
            this.listHs.list[i].checkChonKqht = true;
          }
          console.log(this.listHs.list[i])
        }
      } else {
        console.log('case 2:')
        for (let i = 0; i < this.listHs.list.length; i++) {
          this.listHs.list[i].checkChonKqht = false;
        }
      }
      this.maplistHocSinhNopHoSo();
    },
    chonMoiHocBaNopHoSoBia(e) {
      console.log('chonMoiHocBaNopHoSoBia')
      console.log(e.checkChonBia)
      console.log("CHeck")
      let newCheck = e.checkChonBia;
      console.log('Chuyn thành: ' + newCheck)
      for (let i = 0; i < this.listHs.list.length; i++) {
        if (this.listHs.list[i].id == e.id) {
          this.listHs.list[i].checkChonBia = newCheck;
        }
      }
      this.maplistHocSinhNopHoSoBia();
    },
    maplistHocSinhNopHoSoBia() {
      console.log('maplistHocSinhNopHoSoBia')
      let data = [];
      for (let i = 0; i < this.listHs.list.length; i++) {
        if (this.listHs.list[i].checkChonBia == true) {
          data.push(this.listHs.list[i])
        }
      }
      this.listHocSinhCheckBia = data;
      console.log("Danh sách học bạ check:")
      console.log(this.listHocSinhCheckBia)
      // if (this.listHocSinhCheckBia.length == this.listHs.list.length) {
      //   console.log('Case chọn tất cả')
      //   this.CheckAllBia = true;
      // } else {
      //   console.log('Case huỷ tất cả')
      //   this.CheckAllBia = false;
      // }
    },
    layThongTinXMLCanThuHoi() {
      this.loaiKy = 'thuHoi'
      console.log('layThongTinXMLCanThuHoi')
      let maHs = this.listHocSinhCheckThuHoi.map(a => a.maHS);
      console.log(maHs)
      let params = {
        maTruong: this.user.maTruong,
        namHoc: this.searchForm.year,
        maLop: this.lopHocHienTai,
        lstMaHocSinh: maHs,
        lyDoThuHoi: this.lyDoThuHoi
      }
      this.fullscreenLoading = true;
      let url = '/hocbadientu-sync-service/api/nop-hoc-ba/tao-yeu-cau-yeu-cau-thu-hoi';
      api.post(url, params).then(
          response => {
            console.log("Lấy xml trả về:")
            console.log(response)
            if (response.data.code == 200) {
              this.duLieuXML = response.data.data;
              this.kyDuLieuHocBa();
            } else {
              this.thongBao("error", response.data.msg)
            }
            this.fullscreenLoading = false;
          }
      )
    },
    layThongTinXMLNopChungThuSo() {
      console.log('layThongTinXMLNopChungThuSo')
      this.loaiKy = 'nopCTS'
      let params = {
        maTruong: this.user.maTruong,
      }
      this.fullscreenLoading = true;
      let url = '/hocbadientu-sync-service/api/nop-hoc-ba/tao-yeu-cau-nop-chung-thu';
      api.post(url, params).then(
          response => {
            console.log("Lấy xml nộp CTS trả về:")
            console.log(response)
            if (response.data.code == 200) {
              this.duLieuXML = response.data.data;
              this.kyDuLieuHocBa();
            } else {
              this.thongBao("error", response.data.msg)
            }
            this.fullscreenLoading = false;
          }
      )
    },
    nhapLyDoThuHoi() {
      if(!this.kieuKyGiaoVien){
        this.thongBao('error','Vui lòng chọn kiểu ký')
        return;
      }
      console.log('nhapLyDoThuHoi')
      this.lyDoThuHoi = '';
      this.show_ly_do_thu_hoi = true;
    },
    layThongTinXMLCanNop() {
      if(!this.kieuKyGiaoVien){
        this.thongBao('error','Vui lòng chọn kiểu ký')
        return;
      }
      this.loaiKy = 'nop'
      console.log('layThongTinXMLCanNop')
      this.fullscreenLoading = true;
      let maHs = this.listHocSinhCheck.map(a => a.maHS);
      console.log(maHs)
      let params = {
        maTruong: this.user.maTruong,
        namHoc: this.searchForm.year,
        maLop: this.lopHocHienTai,
        lstMaHocSinh: maHs
      }
      let url = '/hocbadientu-sync-service/api/nop-hoc-ba/tao-yeu-cau-nop-hoc-ba';
      api.post(url, params).then(
          response => {
            console.log("Lấy xml trả về:")
            console.log(response)
            if (response.data.code == 200) {
              this.duLieuXML = response.data.data;
              this.kyDuLieuHocBa();
            } else {
              this.thongBao("error", response.data.msg)
            }
            this.fullscreenLoading = false;
          }
      ).catch(_ => {
        console.log('catch')
        this.fullscreenLoading = false;
        this.thongBao('error', 'Vui lòng thử lại sau ít phút')
      });
    },
    checkKyDuLieu() {
      console.log('checkKyDuLieu')
      console.log(type)
      try {
        console.error('listHocBa:')
        // this.mapListHocBaCheck();
        console.log('listHocSinhCheck')
        console.log(this.listHocSinhCheck);
        if (!this.listHocSinhCheck || this.listHocSinhCheck.length == 0) {
          this.thongBao('error', 'Vui lòng chọn học bạ cần nộp.');
          return;
        } else {
          let ids = this.listHocSinhCheck.map(a => a.maHS);
          console.error('ids:')
          console.error(ids)
          this.kyDuLieuHocBa(ids);
        }
      } catch (e) {
        this.thongBao('success', 'Vui lòng thực hiện lại sau ít phút.')
      }
    },
    kyDuLieuHocBa() {
      if(!this.kieuKyGiaoVien){
        this.thongBao('error','Vui lòng chọn kiểu ký')
        return;
      }
      let arr = this.duLieuXML;
      console.error('Ký dữ liệu học bạ:')
      console.error(this.kieuKyGiaoVien)
      //kieuKyGiaoVien: 4 ký USB Token; 2: ký sim CA
      if (this.kieuKyGiaoVien == 7) {
        this.dataKyVgca = arr;
        this.getListCerVgca();
      }
      if (this.kieuKyGiaoVien == 6) {
        this.checkTokenVnpt();
        this.kyHocBaListCloudCAVNPT(arr);
      }
      if (this.kieuKyGiaoVien == 5) {
        console.log("ký smart ca")
        this.layDanhSachChungChi()
      }
      if (this.kieuKyGiaoVien == 4) {
        console.log("ký usb")
        this.show_ky_usb = true;
        setTimeout(() => {
          this.duLieuKyHocBaUsbV2 = arr;
          this.thuc_hien_ky_usb_edoc = true;
        }, 500)
      }
      if (this.kieuKyGiaoVien == 2) {
        console.log("Ký số")
        this.kySo(arr)
      }
      if (this.kieuKyGiaoVien == 1) {
        console.log("Ký bìa")
        this.duLieuHocBaCanKy = {};
        this.duLieuHocBaCanKy.loaiHocBa = msgType.type.sendOtpBiaHocBa;
        this.duLieuHocBaCanKy.maLop = this.lopHocHienTai;
        let ids = [];
        for (let i = 0; i < arr.length; i++) {
          ids.push(arr[i].id)
        }
        this.hocbaDatas = ids;
        console.error('hocbaDatas')
        console.error(this.hocbaDatas)
        console.error('duLieuHocBaCanKy')
        console.error(this.duLieuHocBaCanKy)
        this.showDialogOtp = true;
        // this.kySo(arr)
      }
    },

    kySo(arr) {
      try {
        let duLieu = arr;
        var ids = [];
        for (let i = 0; i < duLieu.length; i++) {
          let obj = {
            id: duLieu[i].id
          }
          ids.push(obj)
        }
        console.log(ids)
        let msg = '';
        if (duLieu.length == 1) {
          msg = 'Xác nhận ký bìa học bạ?'
        } else {
          msg = 'Xác nhận ký bìa của ' + duLieu.length + ' học bạ đã chọn?'
        }
        this.$confirm(msg, 'Thông báo', {
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Hủy',
        })
            .then(_ => {
              let data = {
                maLop: this.lopHocHienTai,
                reqs: arr,
                msgType: msgType.type.signSimCAHieuTruongKyDauBiaHocBa
              }
              console.log("Thông tin ký")
              console.log(data)
              websocket.sendMessage(data);
              this.showDialogWait();
            })
            .catch(_ => {
            });
      } catch (e) {
        this.thongBao('error', 'Vui lòng thử lại sau ít phút')
      }
    },
    layDanhSachChungChi() {
      console.log('layDanhSachChungChi')
      let url = constant_2.giaovien.danhSachChungChi;
      let params = {}
      this.fullscreenLoading = true;
      api.get(url, params).then(
          response => {
            console.log("Danh sách chứng chỉ:")
            console.log(response)
            if (response.data.code == 200) {
              this.danh_sach_chung_thu_so = response.data.data.certBOS;
              this.chungThuSo.expiredTime = response.data.data.expiredTime;
              this.chungThuSo.token = response.data.data.token;
              this.hien_thi_chon_chung_thu_so = true;
            } else {
              this.thongBao("error", response.data.msg)
            }
            console.log('Danh sách chứng thư  số:')
            console.log(this.danh_sach_chung_thu_so)
            this.fullscreenLoading = false;
          }
      )
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sendDataCheckVgca()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    setDataBiaHocBaVgca(list_hoc_ba) {
      this.dataKyVgca = list_hoc_ba;
      let checkLoged = localStorage.getItem('logedVgca');
      console.log(checkLoged ? 'Đã đăng nhập' : 'Chưa đăng nhập')
      if (checkLoged) {
        this.getListCerVgca();
      } else {
        this.show_check_account_vgca = true;
      }
    },
    sendDataCheckVgca() {
      this.fullscreenLoading = true;
      let url = constant.configKy.checkAccountVgca;
      api.post(url, this.vgcaForm).then(
          response => {
            this.fullscreenLoading = false;
            console.log("Res chi tiết check tài khoản vgca:")
            console.log(response)
            if (response.data.code == 200) {
              this.show_check_account_vgca = false;
              localStorage.setItem('logedVgca', true);
              if (this.dataKyVgca & this.dataKyVgca.length != 0) {
                this.getListCerVgca();
              } else {
                this.thongBao('success', response.data.msg)
              }
            } else {
              this.thongBao('error', response.data.msg)
            }
          }
      )
    },
    kyHocBaListVGCA() {
      let duLieu = this.dataKyVgca;
      var ids = [];
      for (let i = 0; i < duLieu.length; i++) {
        let obj = {
          id: duLieu[i].id
        }
        ids.push(obj)
      }
      console.log(ids)
      let data = {
        maLop: this.lopHocHienTai,
        reqs: ids,
        msgType: msgType.type.kyBiaHocBaVgca,
        certBO: this.cerVgca,
      }
      console.error('Data ký:')
      console.error(data)

      console.log("Gửi:")
      console.log(data)
      websocket.sendMessage(data);
      this.showDialogWait();
    },
    setChungThuSo(item) {
      console.log('setChungThuSo')
      console.log(item);
      this.chungThuSo.giatri = item;
      this.kyHocBaListCloudCA()
    },
    kyHocBaListCloudCA() {
      console.log('kyHocBaListCloudCA')
      let data = {
        maLop: this.lopHocHienTai,
        xml: this.duLieuXML,
        msgType: msgType.type.kyHoSoNopLenBoMySign,
        certBO: this.chungThuSo.giatri,
        token: this.chungThuSo.token,
        expiredTime: this.chungThuSo.expiredTime,

      }
      console.error('Data ký:')
      console.error(data)

      console.log("Gửi:")
      console.log(data)
      websocket.sendMessage(data);
      this.showDialogWait();
      // this.$confirm('Xác nhận ký danh sách học bạ đã chọn?', 'Thông báo', {
      //   confirmButtonText: 'Đồng ý',
      //   cancelButtonText: 'Hủy',
      // })
      //     .then(_ => {
      //     })
      //     .catch(_ => {
      //     });
    },
    getMinutes(second) {
      if (second <= 0) {
        return '00:00'
      } else {
        let sec = 60;
        let phut = (second - second % sec) / sec;
        let giay = second % sec;
        if (phut < 10) {
          phut = '0' + phut;
        }
        if (giay < 10) {
          giay = '0' + giay
        }
        return phut + ':' + giay;
      }
    },
    showDialogWait() {
      this.hien_thi_thoi_gian_doi_ky = true;
      this.clearTimer()
      this.getTimeDown();
      this.demNguocThoiGian(true);
    },
    getTimeDown() {
      if (this.kieuKyGiaoVien == 5) {
        this.timeLeft = 185;
      }
      if (this.kieuKyGiaoVien == 6) {
        this.timeLeft = 300;
      }
      if (this.kieuKyGiaoVien == 7) {
        this.timeLeft = 1200;
      }
    },
    clearTimer() {
      if (this.demNguoc) {
        clearInterval(this.demNguoc);
        this.demNguoc = null
      }
    },
    demNguocThoiGian(check) {
      console.log('demNguocThoiGian')
      if (this.timeLeft >= 0) {
        this.demNguoc = setInterval(() => {
          this.timeLeft--
        }, 1000)
      } else {
        clearInterval(this.demNguoc);
        this.demNguoc = null
      }
    },
    ChonToanBoHocBaNopHoSoLenBoBia() {
      console.log('ChonToanBoHocBaNopHoSoLenBoBia')
      if (this.CheckAllBia) {
        console.log('case 1:')
        console.log(this.listHs.list.length)
        for (let i = 0; i < this.listHs.list.length; i++) {
          if (!this.listHs.list[i].checkStatusNopBia) {
            this.listHs.list[i].checkChonBia = true;
          }
          console.log(this.listHs.list[i])
        }
      } else {
        console.log('case 2:')
        for (let i = 0; i < this.listHs.list.length; i++) {
          this.listHs.list[i].checkChonBia = false;
        }
      }
      this.maplistHocSinhNopHoSoBia();
    },
    handleSelectionChange(val) {
      this.listHocBaCheck = val;
    },
    getPathImgByType(type) {
      let icon = ''
      if (type == 1) {
        icon = 'icon_otp_sms.png'
      }
      if (type == 2) {
        icon = 'icon_sim_ca.png'
      }
      if (type == 4) {
        icon = 'icon_usb_token.png'
      }
      if (type == 5) {
        icon = 'icon_mysign.png'
      }
      if (type == 6) {
        icon = 'icon_vnpt_ca.png'
      }
      if (type == 7) {
        icon = 'icon_vgca.png'
      }
      let objIcon = {
        icon: icon
      }
      return this.getPathImg(objIcon)
    },
    nopHieuChinh(hs, loai) {
      console.log('nopHieuChinh')
      console.log(hs)
      this.hieuChinh.hs = hs;
      this.hieuChinh.loai = loai;
      this.hieuChinh.noiDung = '';
      this.hieuChinh.title = 'Nộp hiệu chỉnh ' + (loai == 4 ? ' bìa' : ' kết quả học tập')
      this.show_nop_hieu_chinh = true;
    },
    nopHieuChinhLenBo() {
      console.log('nopHieuChinhLenBo')
      console.log(this.hieuChinh)
      let uri = constant_api.school.nopDuLieuLenBo;
      let params = {
        type: this.hieuChinh.loai,
        maLop: this.lopHocHienTai,
        maHSs: this.hieuChinh.hs,
        noiDung: this.hieuChinh.noiDung,
        namHoc: this.searchForm.year,
        maTruong: this.user.maTruong,
        maSo: this.user.maSo,
        maPhong: this.user.maPhong,
        nguoiDongBo: this.user.displayName,
        capHoc: this.searchForm.education,
        loaiNop: 3
      }
      this.$confirm('Xác nhận nộp hiệu chỉnh?', 'Thông báo', {
        confirmButtonText: this.hieuChinh.title,
        cancelButtonText: 'Hủy',
        confirmButtonClass: this.hieuChinh.loai == 4 ? 'el-button--warning' : 'el-button--success',
      })
          .then(_ => {
            this.fullscreenLoading = true
            api.post(uri, params).then(data => {
              console.log('Nộp hiệu chỉnh trả về:')
              console.log(data.data)
              if (data.data.code == 200) {
                console.log('Case thành công:')
                this.thongBao('success', data.data.msg)
                this.getDataCurenPage();
              } else {
                console.log('case thất bại')
                this.thongBao('error', data.data.msg)
              }
              this.fullscreenLoading = false
              this.handleCloseNopHieuChinh()
            }).catch((e) => {
              this.fullscreenLoading = false;
              this.thongBao('error', 'Lỗi kết nối. Vui lòng thử lại')
            })
          })
          .catch(_ => {
            this.fullscreenLoading = false;
          });
    },
    nopBoSung(maHSs, loaiNop) {
      console.log('nopHoSoLenBo')
      console.log(maHSs)
      let uri = constant_api.school.nopDuLieuLenBo;
      let params = {
        type: loaiNop,
        maHSs: maHSs,
        maLop: this.lopHocHienTai,
        namHoc: this.searchForm.year,
        maTruong: this.user.maTruong,
        maSo: this.user.maSo,
        maPhong: this.user.maPhong,
        nguoiDongBo: this.user.displayName,
        capHoc: this.searchForm.education,
        loaiNop: 2
      }
      this.$confirm('Xác nhận nộp dữ liệu hồ sơ của học sinh đã chọn?', 'Thông báo', {
        confirmButtonText: loaiNop == 4 ? 'Nộp bổ sung bìa' : 'Nộp bổ sung KQHT',
        cancelButtonText: 'Hủy',
        confirmButtonClass: loaiNop == 4 ? 'el-button--warning' : 'el-button--success',
      })
          .then(_ => {
            this.fullscreenLoading = true
            api.post(uri, params).then(data => {
              console.log('Nộp bổ sung trả về:')
              console.log(data)
              if (data.data.code == 200) {
                this.thongBao('success', data.data.msg)
                this.getDataCurenPage();
              } else {
                this.thongBao('error', data.data.msg)
              }
              this.fullscreenLoading = false
            }).catch((e) => {
              this.fullscreenLoading = false;
              this.thongBao('error', 'Lỗi kết nối. Vui lòng thử lại')
            })
          })
          .catch(_ => {
            this.fullscreenLoading = false;
          });
    },
    nopHoSoLenBo() {
      this.fullscreenLoading = true
      console.log('nopHoSoLenBo')
      console.log('nộp hồ sơ đã ký')
      console.log(this.duLieuXMLDaKy)
      let uri = '/hocbadientu-sync-service/api/nop-hoc-ba/nop-yeu-cau';
      let params = {
        maTruong: this.user.maTruong,
        namHoc: this.searchForm.year,
        maLop: this.lopHocHienTai,
        maHSs: this.loaiKy == 'thuHoi' ? this.listHocSinhCheckThuHoi.map(a => a.maHS) : this.listHocSinhCheck.map(a => a.maHS),
        content: this.duLieuXMLDaKy,
        lyDoThuHoi: this.lyDoThuHoi,
        tenYeuCau: this.loaiKy == 'nop' ? 'PHAT_HANH_HOC_BA_SO_C1' : this.loaiKy == 'thuHoi' ? 'THU_HOI_HOC_BA_SO' : 'DANG_KY_SERIAL'
      }

      api.post(uri, params).then(data => {
        console.log('Nộp lên bộ trả về:')
        console.log(data)
        if (data.data.code == 200) {
          this.thongBao('success', data.data.msg)
          if (this.loaiKy != 'nopCTS') {
            this.getDataCurenPage();
          }
          this.fullscreenLoading = false
        } else {
          this.thongBao('error', data.data.msg)
          this.fullscreenLoading = false
        }
      }).catch((e) => {
        this.fullscreenLoading = false;
        this.thongBao('error', 'Lỗi kết nối. Vui lòng thử lại')
      })
    },
    thuHoiHoSoDaNop() {
      console.log('thuHoiHoSoDaNop')
      console.log(this.listHocSinhCheckThuHoi)
      let maHSs = this.listHocSinhCheckThuHoi.map(item => {
        return item.maHS;
      });
      console.log('Danh sách học sinh thu hồi:')
      console.log(maHSs)
      this.fullscreenLoading = true
      let params = {
        maTruong: this.user.maTruong,
        maHSs: maHSs,
        namHoc: this.searchForm.year,
        maLop: this.lopHocHienTai,
        lyDoThuHoi: this.lyDoThuHoi
      }
      let uri = '/hocbadientu-sync-service/api/v2/edu/profile/yeu-cau-hieu-chinh-hoc-ba';
      api.post(uri, params).then(data => {
        console.log('Thu hồi trả về:')
        console.log(data)
        if (data.data.code == 200) {
          this.thongBao('success', data.data.msg)
          this.getDataCurenPage();
        } else {
          this.thongBao('error', data.data.msg)
        }
        this.fullscreenLoading = false
      }).catch((e) => {
        this.fullscreenLoading = false;
        this.thongBao('error', 'Lỗi kết nối. Vui lòng thử lại')
      })
      return;
      this.$confirm('Xác nhận thu hồi dữ liệu đã nộp của các học sinh đã chọn?', 'Thông báo', {
        confirmButtonText: 'Thu hồi',
        cancelButtonText: 'Hủy',
        confirmButtonClass: 'el-button--warning'
      })
          .then(_ => {
            this.fullscreenLoading = true
            let params = {
              maTruong: this.user.maTruong,
              maHSs: maHSs,
              namHoc: this.searchForm.year,
              maLop: this.searchForm.class,
            }
            let uri = '/hocbadientu-sync-service/api/v2/edu/profile/yeu-cau-hieu-chinh-hoc-ba';
            api.post(uri, params).then(data => {
              console.log('Thu hồi trả về:')
              console.log(data)
              if (data.data.code == 200) {
                this.thongBao('success', data.data.msg)
                this.getDataCurenPage();
              } else {
                this.thongBao('error', data.data.msg)
              }
              this.fullscreenLoading = false
            }).catch((e) => {
              this.fullscreenLoading = false;
              this.thongBao('error', 'Lỗi kết nối. Vui lòng thử lại')
            })
          })
          .catch(_ => {
            this.fullscreenLoading = false;
          });
    },
    chuanBiNopLenBoBia() {
      console.log('Nộp hồ sơ lên sở')
      console.log(this.listHocSinhCheckBia)
      let maHSs = this.listHocSinhCheckBia.map(item => item.maHS)
      console.log('danh sách mã học sinh:')
      console.log(maHSs)
      this.nopHoSoLenBo(maHSs)

    },
    detailHocBaClose() {
      this.saveTempKq(null);
      this.showEditDiem = false;
    },
    saveHocBa() {
      this.showEditDiem = false
      if (!this.ketQuaHocTapTemp) {
        this.showEditDiem = false
      } else {
        this.updateHocBa({
          id: this.idEdit,
          ketQuaHocTaps: this.ketQuaHocTapTemp
        });
      }
    },
    kyHocBaUsbXong() {
      console.log("Hủy toàn bộ thông tin ký:")
      this.show_ky_usb = false;
      this.fullscreenLoading = false;
      this.thuc_hien_ky_usb_all = false;
      this.thuc_hien_ky_usb = false;
      this.thuc_hien_ky_usb_edoc = false;
      this.duLieuKyHocBaUsb = {};
      // this.getHocBa(this.paginate.page);
    },
    layThanhCongXml(e) {
      console.log('layThanhCongXml')
      console.log(e)
      this.duLieuXMLDaKy = (e.data && e.data.xml) ? e.data.xml : '';
      console.log('Dữ liệu XML đã ký')
      console.log(this.duLieuXMLDaKy)
      this.nopHoSoLenBo();
    },
    submitSearch(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getHocBa(1)
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    getHocBa(select = 1) {
      this.showDialogOtp = false;
      let start = (select - 1) * this.paginate.limit ? (select - 1) * this.paginate.limit : 0;
      this.paginate.start = start;
      this.paginate.current = select;
      this.getDataCurenPage();
    },
    capHocChange() {
      console.log("CHọn cấp học")
      this.searchForm.student = '';
      this.searchForm.status = '';
      this.searchForm.grade = '';
      this.getKhoiHoc();
    },
    khoiHocChange() {
      this.searchForm.class = '';
      this.getlistLopHoc();
    },

    getTotalPage(number) {
      return Math.ceil(number / (this.paginate.limit));
    },

    getlistLopHoc() {
      let params = {
        start: 0,
        limit: 999,
        khoiHoc: this.searchForm.grade,
        namHoc: this.searchForm.year,
      }
      console.log(params)

      try {
        let uri = constant_api.hocsinh.getListLop;
        if (this.user.role == 1) {
          uri = constant_api.hocsinh.getListLopGvcn
        }
        console.log('uri: ' + uri)
        this.fullscreenLoading = true;
        this.danh_sach_lop_hoc = [];
        api.get(uri, params).then(
            response => {
              console.log("Lấy ds học bạ trả về:")
              console.log(response.data.data.list);
              if (response.data.code == 200) {
                this.danh_sach_lop_hoc = response.data.data.list;
                if (this.danh_sach_lop_hoc.length == 0) {
                  this.thongBao('error', 'Không tìm thấy danh sách lớp')
                }
              } else {
                this.thongBao('error', 'Không tìm thấy danh sách lớp')
              }
              this.fullscreenLoading = false;
            }
        ).catch((e) => {
          // this.thongBao('success', 'Hệ thống đang cập nhật dữ liệu. Vui lòng chờ trong ít phút.');
          this.fullscreenLoading = false;
        })

      } catch (e) {
        this.thongBao('error', 'Vui lòng thao tác lại.')
      }
    },

    viewFile(id) {
      this.fullscreenLoading = true;
      this.getFile({
        maHocBa: id,
        isBia: true
      })
    },

    openFancybox(id) {
      this.idEdit = id;
      this.getDetail({
        id: id
      })
      this.showEditDiem = true;
    },
    openFormOtp(id, type) {
      if (type) {
        this.typeKy = type;
      } else {
        this.typeKy = null;
      }
      this.hocbaDatas = [id];
      this.showDialogOtp = true;
    },
    openFormOtp2(ids, type) {
      if (type) {
        this.typeKy = type;
      } else {
        this.typeKy = null;
      }
      let datas = [];
      ids.map(item => {
        datas.push(item.id)
        return item;
      })
      this.hocbaDatas = datas;
      this.showDialogOtp = true;
    },
    openFancyBox(url) {
      $.fancybox.open({
        src: url,
        type: 'iframe',
      });
    },

    onReceiveMessage(e) {
      let data = e.data;
      if (data.other == msgType.type.kyHoSoNopLenBoMySign || data.other == msgType.type.signHoSoBangUSBToken) {
        console.error('onReceiveMessage')
        console.log(e)
        if (data.code == 200) {
          this.duLieuXMLDaKy = data.data.xml;
          this.nopHoSoLenBo();
          // this.fullscreenLoading = false;
          this.hien_thi_thoi_gian_doi_ky = false;
          this.show_slect_cer_vgca = false;
          this.show_ky_usb = false;
          this.dataKyVgca = '';
          this.hien_thi_chon_chung_thu_so = false;
        } else {
          this.fullscreenLoading = false;
          this.thongBao('error', data.msg)
          this.show_slect_cer_vgca = false;
          this.dataKyVgca = '';
          this.hien_thi_thoi_gian_doi_ky = false;
          this.hien_thi_chon_chung_thu_so = false;
        }
      }


    },
    checkDuyet(item) {
      let role = this.user.role;
      if (role == 2) {
        if (item.trangThaiKyGVCN) {
          return true
        }
      } else if (role == 3) {
        if (item.trangThaiXacNhanGVCN) {
          return true;
        }
      }
      return false;
    },
    getFullYear() {
      let d = new Date();
      return d.getFullYear();
    },
    getTrangThaiHocSinh(status) {
      if (status == 1) {
        return "Đang học";
      }

      if (status == 3) {
        return "Chuyển trường";
      }

      if (status == 4) {
        return "Bỏ học";
      }
      return "Trạng thái khác";
    },

  },
  beforeMount() {
    window.addEventListener('message', this.onReceiveMessage, false);
  },
  beforeDestroy() {
    console.log('beforeDestroy ký bìa học bạ');
    window.removeEventListener('message', this.onReceiveMessage)
    this.show_ky_usb = false;
  },
  mounted() {
    console.log('mount nộp hồ sơ học bạ:')
    this.maxHeight = this.getHeightTable()

    this.getKieuKy();
    this.searchForm.year = this.nam_hoc_hien_tai
    console.log('list_cap_hoc:')
    console.log(this.list_cap_hoc)
    if (this.list_cap_hoc.length > 1) {
      console.log('case 1')
      this.searchForm.education = this.list_cap_hoc[0].value
    } else {
      console.log('case 2')
      this.searchForm.education = this.list_cap_hoc[0].value
    }
    console.log(this.searchForm.education)
    this.getKhoiHoc();

  },
  watch: {
    duLieuUploadMutilFile(val) {
      if (val) {
        let params = {
          data: this.duLieuUploadMutilFile
        }

        let uri = constant_api.hocsinh.uploadMultile;
        api.post(uri, params).then(data => {
          if (data.data.code === 200) {
            this.$message({
              customClass: 'dts-noty-success',
              showClose: true,
              message: data.data.msg,
              type: 'success',
              duration: 3000
            });
            this.fullscreenLoading = false;
            this.duLieuUploadMutilFile = '';
            this.getDataCurenPage();
          } else {
            this.duLieuUploadMutilFile = '';
            this.$message({
              customClass: 'dts-noty-error',
              showClose: true,
              message: data.data.msg,
              type: 'error',
              duration: 3000
            });

            this.fullscreenLoading = false;
          }
          this.$refs.uploadMutile.clearFiles();
          this.showUpdateMutile = false
        }).catch((e) => {
          this.$message({
            customClass: 'dts-noty-error',
            showClose: true,
            message: 'Lỗi kết nối',
            type: 'error',
            duration: 3000
          });
          this.$refs.uploadMutile.clearFiles();
          this.showUpdateMutile = false
        })
      }
    },
    // listLop(val) {
    //   console.log("Danh sách lớp thay đổi:")
    //   console.log(val)
    //   if (val) {
    //     this.danh_sach_lop_hoc = val.list
    //   }
    // },
    updateSuccess(val) {
      if (val == 'success') {
        dialog.showDialog('Thông báo', this.updateMessage, () => {
          this.saveTempKq(null);
          this.showEditDiem = false;
          this.clear();
        })
      } else if (val == 'failure') {
        dialog.showDialog('Thông báo', this.updateMessage, () => {
          this.saveTempKq(null);
          this.showEditDiem = false;
          this.clear();
        })
      }
    },
    fileDetail(val) {
      if (this.fileStatus == 2) {
        this.fullscreenLoading = false;
        let linkPfd = this.fileDetail + '#toolbar=0';
        this.openFancyBox(linkPfd)
      }
    },
    uploadStatus(val) {
      if (val) {
        this.getDataCurenPage();
        this.messageAlert = val.message;
        this.showDialogAlert = true;

      }
    },
  }
}
</script>

<style scoped>
.btn__table_action button {
  padding: 8px 10px;
}

.el-form-item {
  width: 100%
}
</style>