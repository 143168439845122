var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page page-404" }, [
    _c(
      "div",
      {
        staticClass: "vh-100 d-flex align-items-center justify-content-center",
        attrs: { id: "notfound" },
      },
      [
        _c(
          "div",
          { staticClass: "notfound text-center" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("h2", [_vm._v("Hệ thống đang tiến hành bảo trì.")]),
            _vm._v(" "),
            _c("p", [_vm._v("Vui lòng quay trở lại trong ít phút.")]),
            _vm._v(" "),
            _c(
              "router-link",
              { attrs: { to: { name: "CallbackSso" } } },
              [
                _c("el-button", { attrs: { size: "mini", type: "primary" } }, [
                  _vm._v("Quay lại trang chủ "),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { size: "mini", type: "danger" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.checkLogOut()
                  },
                },
              },
              [_vm._v("Đăng nhập lại")]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "notfound-404" }, [
      _c("img", {
        staticStyle: {
          width: "50%",
          "min-width": "200px",
          "max-width": "400px",
        },
        attrs: { src: "/images/baoTri-2.gif", alt: "Bảo trì" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }