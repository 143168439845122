<template>
  <el-select
      style="width:120px"
      v-model="Content"
      placeholder="Cấp học"
      @change="onChange"
      @input="onInput"
      @visible-change="onVisible"
      @focus="onFocus"
      @blur="onBlur">
    <el-option v-for="(item, index) in lstCapHoc" :key="index" :label="item.label" :value="item.value">
      {{item.label}}
    </el-option>
  </el-select>
</template>

<script>


import {
  mapActions,
  mapState
} from 'vuex';

export default {
  name: "SelectKhoiHoc",

  props: {
    value: {
      required: true
    },
  },

  components: {},

  computed: {
    ...mapState('account', [
      'user'
    ]),
  },

  data() {
    return {
      lstCapHoc: [],
      Content: this.value,
      cap1:{label:"Cấp 1",value:1},
      cap2:{label:"Cấp 2",value:2},
      cap3:{label:"Cấp 3",value:3},
    }
  },
  mounted() {
  },
  beforeMount() {
    console.log(this.user);
    let list_cap_hoc = this.user.capHoc;
    list_cap_hoc = list_cap_hoc.toString();
    list_cap_hoc = list_cap_hoc.split("");
    let danh_sach_cap = [{label:"Tất cả",value:""}];
    for(let i = 0;i<list_cap_hoc.length;i++){
      if(list_cap_hoc[i]==1){danh_sach_cap = danh_sach_cap.concat(this.cap1)}
      if(list_cap_hoc[i]==2){danh_sach_cap = danh_sach_cap.concat(this.cap2)}
      if(list_cap_hoc[i]==3){danh_sach_cap = danh_sach_cap.concat(this.cap3)}
    }
    this.lstCapHoc = danh_sach_cap;
    if(this.lstCapHoc.length==1){
      this.Content = this.lstCapHoc[0].value;
    }


  },

  watch: {
    value() {
      this.Content = this.value;
    },
  },

  methods: {
    focus() {
      this.$refs.el_select.focus();
    },
    onChange(e) {

      this.$emit('change', e);
    },
    onInput(e) {
      this.$emit('input', e);
    },

    onVisible(e) {
      this.$emit('visible', e);
    },

    onFocus(e) {
      this.$emit('focus', e);
    },

    onBlur(e) {
      this.$emit('blur', e);
    },

  }
}
</script>

<style scoped>

</style>