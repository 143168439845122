<template>
    <div class="page page-request">
        <!-- <div class="file--title">
            <h2 class="title">Thông tin học sinh</h2>
        </div> -->
        <div class="page__content infoAccount" v-if="infoHocSinh">
            <el-row class="dts-content" :gutter="10">
                <el-col :lg="5" :md="4" :sm="3"></el-col>
                <el-col class="bg-smoke text-center" :lg="14" :md="16" :sm="18" :xs="24">
                    <el-row :gutter="24">
                        <el-col class="text-center">
                            <div class="grid-content ">
                                <div class="demo-basic--circle">
                                    <div class="text-center">
                                        <el-image class="rounded-circle image-circle-info"
                                                  :src="infoHocSinh.anhTuQLNT ? infoHocSinh.anhTuQLNT : infoHocSinh.anhTuHocBaDienTu">
                                            <div slot="error" class="image-slot">
                                                <el-image class="rounded-circle image-circle-info"
                                                          src="/images/default-avatar.png">
                                                </el-image>
                                            </div>
                                        </el-image>
                                    </div>
                                </div>
                            </div>
                            <h3>{{ infoHocSinh.tenHS }}</h3>

                        </el-col>

                    </el-row>
                    <el-row :gutter="24">
                        <el-col :sm="12" :xs="24" class="text-left">
                            <el-card shadow="always">
                                <div class="iconInfo">
                                    <i class="el-icon-user"></i>
                                </div>
                                <div class="textInfo">
                                    <span>Mã học sinh</span>
                                    <p>{{ infoHocSinh.maHS }}</p>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :sm="12" :xs="24" class="text-left">

                            <el-card shadow="always">
                                <div class="iconInfo">
                                    <i class="el-icon-date"></i>
                                </div>
                                <div class="textInfo">
                                    <span>Ngày sinh</span>
                                    <p v-if="infoHocSinh.ngaySinh">{{ infoHocSinh.ngaySinh }}</p>
                                    <p v-else>Chưa cập nhật</p>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :sm="12" :xs="24" class="text-left">
                            <el-card shadow="always">
                                <div class="iconInfo">
                                    <i class="el-icon-add-location"></i>
                                </div>
                                <div class="textInfo">
                                    <span>Nơi sinh</span>
                                    <p v-if="infoHocSinh.noiSinh">{{ infoHocSinh.noiSinh }}</p>
                                    <p v-else>Chưa cập nhật</p>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :sm="12" :xs="24" class="text-left">
                            <el-card shadow="always">
                                <div class="iconInfo">
                                    <i class="el-icon-location-outline"></i>
                                </div>
                                <div class="textInfo">
                                    <span>Nơi ở hiện nay</span>
                                    <p v-if="infoHocSinh.noiOHienNay">{{ infoHocSinh.noiOHienNay }}</p>
                                    <p v-else>Chưa cập nhật</p>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :sm="12" :xs="24" class="text-left">
                            <el-card shadow="always">
                                <div class="iconInfo">
                                    <i class="el-icon-map-location"></i>
                                </div>
                                <div class="textInfo">
                                    <span>Quê quán</span>
                                    <p v-if="infoHocSinh.queQuan">{{ infoHocSinh.queQuan }}</p>
                                    <p v-else>Chưa cập nhật</p>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :sm="12" :xs="24" class="text-left">
                            <el-card shadow="always">
                                <div class="iconInfo">
                                    <i class="el-icon-price-tag"></i>
                                </div>
                                <div class="textInfo">
                                    <span>Dân tộc</span>
                                    <p v-if="infoHocSinh.tenDanToc">{{ infoHocSinh.tenDanToc }}</p>
                                    <p v-else>Chưa cập nhật</p>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :sm="12" :xs="24" class="text-left">
                            <el-card shadow="always">
                                <div class="iconInfo">
                                    <i class="el-icon-s-custom"></i>
                                </div>
                                <div class="textInfo">
                                    <span>Họ tên bố</span>
                                    <p v-if="infoHocSinh.hoTenCha">{{ infoHocSinh.hoTenCha }}</p>
                                    <p v-else>&nbsp;</p>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :sm="12" :xs="24" class="text-left">
                            <el-card shadow="always">
                                <div class="iconInfo">
                                    <i class="el-icon-s-check"></i>
                                </div>
                                <div class="textInfo">
                                    <span>Họ tên mẹ</span>
                                    <p v-if="infoHocSinh.hoTenMe">{{ infoHocSinh.hoTenMe }}</p>
                                    <p v-else>&nbsp;</p>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :sm="12" :xs="24" class="text-left">
                            <el-card shadow="always">
                                <div class="iconInfo">
                                    <i class="el-icon-school"></i>
                                </div>
                                <div class="textInfo">
                                    <span>Nghề nghiệp bố</span>
                                    <p v-if="infoHocSinh.ngheNghiepCha">{{ infoHocSinh.ngheNghiepCha }}</p>
                                    <p v-else>&nbsp;</p>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :sm="12" :xs="24" class="text-left">
                            <el-card shadow="always">
                                <div class="iconInfo">
                                    <i class="el-icon-school"></i>
                                </div>
                                <div class="textInfo">
                                    <span>Nghề nghiệp mẹ</span>
                                    <p v-if="infoHocSinh.ngheNghiepMe">{{ infoHocSinh.ngheNghiepMe }}</p>
                                    <p v-else>&nbsp;</p>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :sm="12" :xs="24" class="text-left">
                            <el-card shadow="always">
                                <div class="iconInfo">
                                    <i class="el-icon-mobile-phone"></i>
                                </div>
                                <div class="textInfo">
                                    <span>Số điện thoại bố</span>
                                    <p v-if="infoHocSinh.sdtBo">{{ infoHocSinh.sdtBo }}</p>
                                    <p v-else>Chưa cập nhật</p>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :sm="12" :xs="24" class="text-left">
                            <el-card shadow="always">
                                <div class="iconInfo">
                                    <i class="el-icon-mobile-phone"></i>
                                </div>
                                <div class="textInfo">
                                    <span>Số điện thoại mẹ</span>
                                    <p v-if="infoHocSinh.sdtMe">{{ infoHocSinh.sdtMe }}</p>
                                    <p v-else>Chưa cập nhật</p>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :sm="12" :xs="24" class="text-left">
                            <el-card shadow="always">
                                <div class="iconInfo">
                                    <i class="el-icon-s-grid"></i>
                                </div>
                                <div class="textInfo">
                                    <span>Đối tượng chính sách</span>
                                    <p v-if="infoHocSinh.tenDtuongCsach">{{ infoHocSinh.tenDtuongCsach }}</p>
                                    <p v-else>&nbsp;</p>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import {
    mapState
} from 'vuex';

export default {
    name: "InfoHocSinh",
    computed: {
        ...mapState('hocsinh', [
            "infoHocSinh"
        ]),
    }
}
</script>

<style scoped="scoped">
.infoAccount label {
    width: auto;
}

input {
    width: 100%
}

.el-card__body {
    padding: 15px;
    position: relative
}

.textInfo {
    padding-left: 50px;
}

.textInfo > p {
    color: black;
}

.iconInfo {
    position: absolute
}

.iconInfo > i {
    font-size: 35px;
}
</style>