import middlewarePipeline from "./_router/middleware/middlewarePipeline";

require('./bootstrap');
require('summernote');


import Vue from 'vue';
import Default from "./layouts/App";
import None from "./layouts/None";
import VueRouter from 'vue-router'
import {
    router
} from './_router';
import {
    store
} from './_store';
// import firebaseMessaging from "./firebase";
import App from "./components/App";
import Meta from 'vue-meta';
import Vuebar from 'vuebar';
import VueMoment from 'vue-moment'
import localMoment from 'moment/locale/vi';
import Vue2TouchEvents from 'vue2-touch-events';
import ElementUI from 'element-ui';
import localeVI from 'element-ui/lib/locale/lang/vi';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';


library.add(fab)
library.add(fas)
library.add(far)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false


//ElementUI
import "element-ui/lib/theme-chalk/index.css";
import axios from "axios";

// Vue.prototype.$firebase = firebaseMessaging
Vue.use(require('vue-cookies'));

Vue.component('default-layout', Default);
Vue.component('none-layout', None);
Vue.use(VueRouter);
Vue.use(Meta);
Vue.use(Vuebar);
Vue.use(VueMoment, {
    localMoment
});
let token = localStorage.getItem('token');

// Vue.use(moment);
Vue.use(ElementUI, {
    locale: localeVI
});
// moment.updateLocale('vi',{
//     relativeTime : {
//         future : '%s tới',
//         past : '%s trước',
//         s : 'vài giây',
//         ss : '%d giây' ,
//         m : '1 phút',
//         mm : '%d phút',
//         h : '1 giờ',
//         hh : '%d giờ',
//         d : '1 ngày',
//         dd : '%d ngày',
//         M : '1 tháng',
//         MM : '%d tháng',
//         y : '1 năm',
//         yy : '%d năm'
//     },
// });
Vue.use(Vue2TouchEvents);
//123
router.beforeEach((to, from, next) => {
    if (!to.meta || !to.meta.middleware || to.name == 'Login' || to.name == 'CallbackSso') {
        return next()
    } else {
        if (!to.meta.middleware) {
            return next();
        }
        const middleware = to.meta.middleware;
        const context = {
            to,
            from,
            next,
            store
        };


        return middleware[0]({
            ...context,
            next: middlewarePipeline(context, middleware, 1)
        })
    }
});


const appMain = new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
});