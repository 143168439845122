<template>
    <div class="page page-login">
    </div>
</template>

<script>
import {mapActions} from "vuex";
    export default {
        name: "Logout",
        mounted() {
            console.error('Mounted logout...')
             this.checkLogOut();
        },
        methods:{
            ...mapActions('account', [
                "logout",
            ]),
            checkLogOut() {
                let domain = window.location.hostname;
                console.error('domain:' + domain)
                let userSso = localStorage.getItem('userSso')
                if (!userSso || userSso == 'false' || userSso == false) {
                    console.error('Đăng xuất tk thường:')
                    localStorage.clear();
                    this.logout();
                } else {
                    console.error('Đăng xuất sso')
                    let id_token = localStorage.getItem('id_token')
                    let state = this.ranDomString(5)
                    let post_logout_redirect_uri = domain+'/login'
                    let config = {
                        id_token_hint: id_token,
                        state: state,
                        post_logout_redirect_uri: post_logout_redirect_uri,
                    }
                    let base_url = 'https://id.nentanggiaoduc.edu.vn/connect/endsession?'
                    let url_logout = base_url + this.objectToQueryString(config);
                    console.error('url_logout')
                    console.error(url_logout)
                    window.location.href = url_logout

                }
            },
            ranDomString(length) {
                var result = '';
                var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                var charactersLength = characters.length;
                for (var i = 0; i < length; i++) {
                    result += characters.charAt(Math.floor(Math.random() *
                        charactersLength));
                }
                return result;
            },
            objectToQueryString(obj) {
                let str = [];
                for (let p in obj)
                    if (obj.hasOwnProperty(p)) {
                        str.push(p + "=" + obj[p]);
                    }
                return str.join("&");
            },
        }
    }
</script>
