<template>
  <div id="listConfig" class="page page-file file detailHocBa" v-loading.fullscreen.lock="fullscreenLoading">
    <div class="box-upload-file">
      <div class="d-flex flex-wrap">
        <div class="d-inline-block pl-3 pr-3 pb-3">
          <h5 class="font-weight-bold m-0">Danh Sách Sở Giáo dục và Đào tạo</h5>
        </div>
        <div>
        </div>
      </div>
      <div class="pl-3">
        <el-row :gutter="24">
          <el-col :span="8">
            <eselect style="width:100%" collapseTags v-model="maDonVi" multiple
                     :placeholder="'Chọn Sở Giáo dục và Đào tạo'" filterable :data="list_so"
                     :fields="['name','value']"/>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" @click="submitSearch()">Thêm mới</el-button>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="file--title">
      <h5 class="title">Danh sách cấu hình</h5>
    </div>
    <div class="page__content">
      <el-table @expand-change="expandChangeSo" :expand-row-keys="expandDanhSachPhong"
                class="configSo--phongCustom" border max-height="1000" fit stripe row-key="maSo" :data="list_ma_so">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-table @expand-change="expandChangePhong" class="configSo--truongCustom" border fit
                      max-height="600" v-loading="props.row.loading" element-loading-text="Đang lấy dữ liệu..."
                      element-loading-spinner="el-icon-loading"
                      element-loading-background="rgba(255, 255, 255, 0.8)" :data="props.row.list_phong">
              <el-table-column type="expand">
                <template slot-scope="truong">
                  <el-table class="configSo--truong" border fit max-height="600"
                            :data="truong.row.listTruong">
                    <el-table-column header-align="center" label="STT" style="width: 60px"
                                     width="60" type="index" class-name="text-center" :index="indexMethod">
                    </el-table-column>
                    <el-table-column header-align="center" label="Tên sở" property="tenSo"
                                     width="300">
                    </el-table-column>
                    <el-table-column header-align="center" label="Tên phòng" property="tenPhong"
                                     width="300">
                    </el-table-column>
                    <el-table-column header-align="center" label="Tên trường học"
                                     property="tenTruong">
                    </el-table-column>
                    <el-table-column header-align="center" label="Thao Tác" width="300">
                      <template slot-scope="moiTruong">
                        <el-tooltip v-if="moiTruong.row.trangThai==1" class="item" effect="dark"
                                    content="Xóa" placement="left-end">
                          <el-button type="danger" icon="el-icon-delete"
                                     @click.prevent="ConfirmXoaCauHinhChoTruong(moiTruong.row)"
                                     size="mini">
                          </el-button>
                        </el-tooltip>
                        <el-tooltip v-if="moiTruong.row.trangThai==0" class="item" effect="dark"
                                    content="Thêm" placement="left-end">
                          <el-button type="primary"
                                     @click.prevent="ConfirmThemCauHinhChoTruong(moiTruong.row)"
                                     icon="el-icon-circle-plus-outline" size="mini"></el-button>
                        </el-tooltip>
                        <el-tooltip v-if="moiTruong.row.trangThai==1" class="item" effect="dark"
                                    content="Chỉnh sửa kiểu ký" placement="left-end">
                          <el-button type="warning"
                                     @click.prevent="ChinhSuaKieuKy(moiTruong.row,3)"
                                     icon="el-icon-edit-outline" size="mini"></el-button>
                        </el-tooltip>
                      </template>

                    </el-table-column>
                  </el-table>
                </template>
              </el-table-column>
              <el-table-column header-align="center" label="STT" style="width: 60px" width="60"
                               type="index" class-name="text-center" :index="indexMethod">
              </el-table-column>
              <el-table-column header-align="center" label="Tên sở" property="tenSo" width="300">
              </el-table-column>
              <el-table-column header-align="center" label="Tên Phòng" property="tenPhong" width="300">
              </el-table-column>
              <el-table-column header-align="center" label="Tên trường học" property="name">
              </el-table-column>
              <el-table-column header-align="center" width="300" label="Thao Tác">
                <template slot-scope="scope">
                  <el-tooltip v-if="scope.row.trangThai==1" class="item" effect="dark" content="Xóa"
                              placement="left-end">
                    <el-button type="danger" icon="el-icon-delete"
                               @click.prevent="ConfirmXoaCauHinhChoPhong(scope.row)" size="mini">
                    </el-button>
                  </el-tooltip>
                  <el-tooltip v-if="scope.row.trangThai==0" class="item" effect="dark" content="Thêm"
                              placement="left-end">
                    <el-button type="primary" @click.prevent="ConfirmThemCauHinhChoPhong(scope.row)"
                               icon="el-icon-circle-plus-outline" size="mini"></el-button>
                  </el-tooltip>
                  <el-tooltip v-if="scope.row.trangThai==1" class="item" effect="dark"
                              content="Chỉnh sửa kiểu ký" placement="left-end">
                    <el-button type="warning" @click.prevent="ChinhSuaKieuKy(scope.row,2)"
                               icon="el-icon-edit-outline" size="mini"></el-button>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column label="STT" style="width: 60px" width="60" type="index" class-name="text-center"
                         :index="indexMethod">
        </el-table-column>
        <el-table-column header-align="center" label="Tên sở" property="tenSo" width="300">
        </el-table-column>
        <el-table-column header-align="center" label="Tên phòng" width="300">
        </el-table-column>
        <el-table-column header-align="center" label="Tên trường">
        </el-table-column>
        <el-table-column header-align="center" width="300" label="Thao tác">
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.trangThai==1" class="item" effect="dark" content="Xóa"
                        placement="left-end">
              <el-button type="danger" icon="el-icon-delete"
                         @click.prevent="ConfirmXoaCauHinhTheoSo(scope.row.id)" size="mini"></el-button>
            </el-tooltip>
            <el-tooltip v-if="scope.row.trangThai==1" class="item" effect="dark" content="Chỉnh sửa kiểu ký"
                        placement="left-end">
              <el-button type="warning" @click.prevent="ChinhSuaKieuKy(scope.row,1)"
                         icon="el-icon-edit-outline" size="mini"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>

      <el-dialog
              :close-on-press-escape="false"
              :close-on-click-modal="false" width="40%" title="Kiểu Ký" top="5vh" custom-class="thongTinKyTheoMonHoc"
                 :visible.sync="hien_thi_chinh_sua_kieu_ky">
        <el-row :gutter="24" type="flex">
          <el-col :span="12">
            <div class="dts-relative">
              <div class="dts-absolute">
                <p>Giáo viên</p>
              </div>
              <div class="dts-radio">
                <el-radio-group v-model="kieuKyGiaoVien">
                  <el-radio :label="1">Ký OTP</el-radio>
                  <el-radio :label="2">Ký SIM CA</el-radio>
                  <el-radio :label="3">Ký USB Token All</el-radio>
                  <el-radio :label="4">Ký Plugin Edoc</el-radio>
                    <el-radio :label="5">CLOUD_CA_VIETTEL</el-radio>
                    <el-radio :label="6">CLOUD_CA_VNPT</el-radio>
                </el-radio-group>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="dts-relative">
              <div class="dts-absolute">
                <p>Hiệu trưởng</p>
              </div>
              <div class="dts-radio">
                <el-radio-group v-model="kieuKyHieuTruong">
                  <el-radio :label="1">Ký OTP</el-radio>
                  <el-radio :label="2">Ký SIM CA</el-radio>
                  <el-radio :label="3">Ký USB Token All</el-radio>
                  <el-radio :label="4">Ký Plugin Edoc</el-radio>
                  <el-radio :label="5">CLOUD_CA_VIETTEL</el-radio>
                  <el-radio :label="6">CLOUD_CA_VNPT</el-radio>
                </el-radio-group>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="24" v-show="suaTruong">
          <el-col :span="12">
            <label>MobileCa</label>
            <el-input size="mini" placeholder="Nhập" autocomplete="off" v-model="thongTinTruong.mobileCA">
            </el-input>
          </el-col>
          <el-col :span="12">
            <label>mobileOTP</label>
            <el-input size="mini" placeholder="Nhập" autocomplete="off" v-model="thongTinTruong.mobileOTP">
            </el-input>
          </el-col>
          <el-col :span="12">
            <label>Mật khẩu</label>
            <el-input size="mini" placeholder="Nhập" autocomplete="off" v-model="thongTinTruong.passwordDemo"
                      show-password>
            </el-input>
          </el-col>
          <el-col :span="12">
            <label>Path chữ ký</label>
            <el-input size="mini" placeholder="Nhập" autocomplete="off" v-model="thongTinTruong.pathChuKyDemo">
            </el-input>
          </el-col>
          <el-col :span="12">
            <label>Thời hạn</label>
            <el-input size="mini" placeholder="Nhập" autocomplete="off" v-model="thongTinTruong.thoiHanDemo">
            </el-input>
          </el-col>
          <el-col :span="12">
            <label>QLNT</label>
            <eselect style="width:100%" size="mini" collapseTags v-model="thongTinTruong.qlnt" 
                     :placeholder="'Chọn'" filterable :data="list_qlnt"
                     :fields="['name','value']"/>
          </el-col>
          <el-col :span="12">
            <label>Tài khoản CSDLN</label>
            <el-input size="mini" placeholder="Nhập" autocomplete="off" v-model="thongTinTruong.usernameCSDLN">
            </el-input>
          </el-col>
          <el-col :span="12">
            <label>Mật khẩu CSDLN</label>
            <el-input size="mini" placeholder="Nhập" autocomplete="off" v-model="thongTinTruong.passwordCSDLN">
            </el-input>
          </el-col>
          <el-col :span="12" style="padding-top:16px">
            <el-checkbox v-model="thongTinTruong.demo">demo</el-checkbox>
          </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
                    <el-button size="mini" @click="hien_thi_chinh_sua_kieu_ky = false">Đóng</el-button>
                    <el-button size="mini" @click.prevent="confimChinhSuaCauHinhKy" type="primary">Chỉnh sửa</el-button>
                </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  mapState,
  mapActions,
  mapMutations
} from 'vuex';
import dialog from "../dialog";
import ESelectVue from "./Ui/ESelect";

export default {
  name: "CauHinhNguoiDung",
  components: {
    'eselect': ESelectVue,
  },
  data() {
    return {
      fullscreenLoading: true,
      list_qlnt: [
        {name:'VIETTEL',value:0},
        {name:'VNEDU',value:1},
        {name:'MOET',value:2},
      ],
      maDonVi: [],
      maSo: "",
      maPhong: "",
      maTruong: "",
      objType: "",
      list_so: [],
      expandDanhSachPhong: [],
      expandDanhSachTruong: [],
      list_ma_so: [],
      hien_thi_chinh_sua_kieu_ky: false,
      kieuKyGiaoVien: null,
      kieuKyHieuTruong: null,
      idBanGhi: null,
      thongTinTruong: {},
      suaTruong: false,
    }
  },
  computed: {
    ...mapState("account", [
      "user"
    ]),
    ...mapState("configKyModule", [
      "danhSachCauHinhSo",
      "themCauHinhSo",
      "xoaDuLieuCauHinhSo",
      "resChinhSuaKieuKy",
      "danhSachSo",
      "updateStatus",
      "hienThiTaiTrang",
      "createStatus"
    ]),
  },
  methods: {
    ChinhSuaKieuKy(e, t) {
      this.kieuKyGiaoVien = e.kieuKyGV;
      this.kieuKyHieuTruong = e.kieuKyHieuTruong;
      this.idBanGhi = e.id;
      this.objType = t;
      this.suaTruong = false;
      if (t == 1) {
        this.maSo = e.maSo;
      }
      if (t == 2) {
        this.maPhong = e.maPhong;
        this.maSo = e.maSo;
      }
      if (t == 3) {
        console.log("Sửa trường:")
        this.maPhong = e.maPhong;
        this.maSo = e.maSo;
        this.maTruong = e.maTruong;
        this.thongTinTruong = e;
        this.suaTruong = true;
      }
      this.hien_thi_chinh_sua_kieu_ky = true;
    },
    confimChinhSuaCauHinhKy() {
      this.$confirm('Xác nhận chỉnh sửa cấu hình ký?', 'Thông báo', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
        closeOnClickModal: false,
      })
          .then(_ => {
            this.fullscreenLoading = true;
            let params = {
              kieuKyGV: this.kieuKyGiaoVien,
              kieuKyHieuTruong: this.kieuKyHieuTruong,
              objType: this.objType
            };
            if (this.objType == 1) {
              params.maSo = this.maSo;
              params.id = this.idBanGhi;
            }
            if (this.objType == 2) {
              params.maSo = this.maSo;
              params.maPhong = this.maPhong;
            }
            if (this.objType == 3) {
              params.maSo = this.maSo;
              params.maPhong = this.maPhong;
              params.maTruong = this.maTruong;
              params.demo = this.thongTinTruong.demo;
              params.mobileCA = this.thongTinTruong.mobileCA;
              params.mobileOTP = this.thongTinTruong.mobileOTP;
              params.passwordDemo = this.thongTinTruong.passwordDemo;
              params.pathChuKyDemo = this.thongTinTruong.pathChuKyDemo;
              params.thoiHanDemo = this.thongTinTruong.thoiHanDemo;
              params.qlnt = this.thongTinTruong.qlnt;
              params.usernameCSDLN = this.thongTinTruong.usernameCSDLN;
              params.passwordCSDLN = this.thongTinTruong.passwordCSDLN;
            }
            this.chinhSuaKieuKy(params);
          })
          .catch(_ => {
          });
    },
    ConfirmThemCauHinhChoTruong(e) {
      console.log("Thêm Cấu hình cho trường");
      console.log(e);
      this.$confirm('Xác nhận thêm mới cấu hình cho trường đã chọn?', 'Thông báo', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
        closeOnClickModal: false,
      })
          .then(_ => {
            this.fullscreenLoading = true;
            this.themMoiCauHinhSo({
              maPhong: e.maPhong,
              maSo: e.maSo,
              maTruong: e.maTruong,
              objType: 3
            });
          })
          .catch(_ => {
          });
    },
    ConfirmXoaCauHinhChoTruong(e) {
      console.log("Xóa trường");
      console.log(e);
      this.$confirm('Xác nhận xóa cấu hình cho trường đã chọn?', 'Thông báo', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
        closeOnClickModal: false,
      })
          .then(_ => {
            this.xoaCauHinhSo({
              maPhong: e.maPhong,
              maSo: e.maSo,
              maTruong: e.maTruong,
              objType: 3
            });
          })
          .catch(_ => {
          });
    },
    ConfirmXoaCauHinhChoPhong(e) {
      console.log(e)
      console.log("Xóa của ^^")
      this.$confirm('Xác nhận xóa cấu hình cho phòng đã chọn?', 'Thông báo', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
        closeOnClickModal: false,
      })
          .then(_ => {
            this.xoaCauHinhSo({
              maPhong: e.maPhong,
              maSo: e.maSo,
              objType: 2
            });
          })
          .catch(_ => {
          });
    },
    ConfirmThemCauHinhChoPhong(e) {
      this.$confirm('Xác nhận thêm mới cấu hình cho phòng đã chọn?', 'Thông báo', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
        closeOnClickModal: false,
      })
          .then(_ => {
            this.fullscreenLoading = true;
            this.themMoiCauHinhSo({
              maPhong: e.maPhong,
              maSo: e.maSo,
              objType: 2
            });
          })
          .catch(_ => {
          });
    },
    ConfirmXoaCauHinhTheoSo(e) {
      console.log("Xóa cấu hình theo sở:")
      this.$confirm('Xác nhận xóa cấu hình?', 'Thông báo', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
        closeOnClickModal: false,
      })
          .then(_ => {
            console.log("Đồng ý xóa")
            this.xoaCauHinhSo({
              id: e,
              objType: 1
            });
          })
          .catch(_ => {
          });
    },
    expandChangeSo(row, expanded) {
      if (this.expandDanhSachPhong.length && this.expandDanhSachPhong[0] == row.maSo) {
        this.expandDanhSachPhong = [];
      } else {
        this.expandDanhSachPhong = [row.maSo];
      }

      if (row) {
        this.layDanhSachCauHinhTheoSo({
          maSo: row.maSo,
        })
      }
    },
    expandChangePhong(row, expanded) {
      console.log("Lấy danh sách trường")
      console.log(row);
      if (this.expandDanhSachTruong.length && this.expandDanhSachTruong[0] == row.maPhong) {
        this.expandDanhSachTruong = [];
      } else {
        this.expandDanhSachTruong = [row.maPhong];
      }

      if (row) {
        this.layDanhSachCauHinhTheoPhong({
          maSo: row.maSo,
          maPhong: row.maPhong,
        })
      }
    },
    ...mapActions("configKyModule", [
      "themMoiCauHinhSo",
      "xoaCauHinhSo",
      "getDanhSachCauHinh",
      "chinhSuaKieuKy",
      "layDanhSachCauHinhTheoSo",
      "layDanhSachCauHinhTheoPhong",
      "getDanhSachSo",
    ]),
    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t
      }
      if (type == "success") {
        cl = "dts-noty-success"
      }
      if (type == "warning") {
        cl = "dts-noty-warning"
      }
      if (type == "error") {
        cl = "dts-noty-error"
      }
      if (type == "info") {
        cl = "dts-noty-info"
      }
      document.querySelector('.overlay').style.display = 'block';
      this.$message({
        onClose: () => {
          document.querySelector('.overlay').style.display = 'none';
        },
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    indexMethod(index) {
      return (index) + 1;
    },
    submitSearch() {
      let maSo = "";
      if (this.maDonVi.length > 0) {
        for (let i = 0; i < this.maDonVi.length; i++) {
          maSo = maSo + this.maDonVi[i];
          if (i < this.maDonVi.length - 1) {
            maSo = maSo + ",";
          }
        }
      }
      this.$confirm('Xác nhận thêm mới cấu hình cho các sở đã chọn?', 'Thông báo', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
        closeOnClickModal: false,
      })
          .then(_ => {
            this.fullscreenLoading = true;
            this.themMoiCauHinhSo({
              maSo: maSo,
              objType: 1
            });
          })
          .catch(_ => {
          });
      console.log("Mã sở:" + maSo)
      console.log(maSo)
    },
  },
  async mounted() {
    let maPhong = '';
    this.getDanhSachSo();
    this.getDanhSachCauHinh();
    this.fullscreenLoading = false;
  },
  watch: {
    danhSachCauHinhSo: function (newVal) {
      if (newVal) {
        this.fullscreenLoading = false;
        this.list_ma_so = newVal;
      }
    },
    hienThiTaiTrang: function (newVal) {
      console.log("hienThiTaiTrang thay đổi" + newVal)
      if (newVal) {
        if (newVal == 2) {
          this.fullscreenLoading = true;
        } else {
          this.fullscreenLoading = false;
        }
      }
    },
    themCauHinhSo: function (newVal) {
      if (newVal) {
        console.log("thay đổi ")
        console.log(newVal)
        if (newVal.data.code == 200) {
          this.thongBao("success", "Thêm mới thành công");
          if (newVal.objType == 1) {
            this.getDanhSachCauHinh();
          }
        } else {
          this.thongBao("error", newVal.data.msg)
        }
      } else {
      }
    },
    resChinhSuaKieuKy: function (newVal) {
      if (newVal.stt == 1) {
        if (newVal.data.code == 200) {
          this.thongBao("success", "Chỉnh sửa dữ liệu thành công");
          this.hien_thi_chinh_sua_kieu_ky = false;
        } else {
          this.thongBao("error", newVal.data.msg)
        }
      }
    },
    xoaDuLieuCauHinhSo: function (newVal) {
      if (newVal) {
        console.log("Xóa thay đổi")
        console.log(newVal)
        if (newVal.code == 200) {
          this.thongBao("success", "Xóa dữ liệu thành công");
          if (newVal.objType == 1) {
            this.getDanhSachCauHinh();
          }
        } else {
          this.thongBao("error", newVal.msg)
        }
      }
    },
    danhSachSo: function (newVal) {
      if (newVal) {

        let dulieu = newVal;
        let ds_so = [];
        for (let i = 0; i < dulieu.length; i++) {
          let obj = {
            name: dulieu[i].tenDonVi + " - [" + dulieu[i].maDonVi + "]",
            value: dulieu[i].maDonVi
          }
          ds_so.push(obj)
        }
        this.list_so = ds_so;
      }

    }
  }
}
</script>
<style scoped="scoped">
.el-table td,
.el-table th {
  padding: 6px 0 !important;
}

.el-radio {
  width: 100% !important;
  padding-bottom: 12px !important;
}

.dts-relative {
  border: 1px solid #d7dae2;
  border-radius: 4px;
  position: relative;
  width: 100%;
  height: 100%
}

.dts-absolute {
  position: absolute;
  top: -20px;
  left: 40px;
  padding: 0 25px;
  background-color: white
}

.dts-absolute > p {
  font-size: 18px;
  color: black;
}

.dts-radio {
  padding: 15px 10px
}
</style>