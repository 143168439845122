<template>
    <div>
        <apexchart type="donut" height="280" :options="bieuTongQuanNhaTruong" :series="series"></apexchart>
    </div>
</template>
<style>
</style>
<script>
import ApexCharts from 'vue-apexcharts'

export default {
    props: ['dulieu'],

    data() {
        return {
            series: [],
            duLieuBieuDo: [0, 0, 0, 0, 0, 0],
            bieuTongQuanNhaTruong: {
                chart: {
                    width: '350',
                    type: 'donut',
                    zoom: {
                        enabled: true
                    },
                    animations: {
                        enabled: true
                    }
                },

                colors: ['#00a65a', '#FFA200', '#60BFFF', '#90ED7D', '#014839', '#FB4444', '#FAB4F4', '#AABBCC', '#999900'],

                stroke: {
                    curve: 'smooth',
                },
                legend: {
                    show: false
                },
                dataLabels: {
                    formatter(val, opts) {
                        const name = opts.w.globals.labels[opts.seriesIndex]
                        const tong = opts.w.globals.seriesTotals[opts.seriesIndex]
                        // return [name, tong +'('+ val.toFixed(2) + '%'+')']
                        return [name, val.toFixed(2) + '%']
                    }
                },
                labels: [],
                plotOptions: {
                    pie: {
                        startAngle: 0,
                        endAngle: 360,
                        expandOnClick: true,
                        offsetX: 0,
                        offsetY: 0,
                        customScale: 1,
                        dataLabels: {
                            offset: 0,
                            minAngleToShowLabel: 10
                        },
                        donut: {
                            size: '45%',
                            background: 'transparent',
                            labels: {
                                show: true,
                                name: {
                                    show: false,
                                    fontSize: '12px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 100,
                                    color: undefined,
                                    offsetY: 0,
                                    formatter: function (val) {
                                        return val
                                    }
                                },
                                value: {
                                    show: true,
                                    fontSize: '12px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 100,
                                    color: undefined,
                                    offsetY: 6,
                                    formatter: function (val) {
                                        return val
                                    }
                                },
                                total: {
                                    show: true,
                                    showAlways: true,
                                    label: 'Tổng số',
                                    fontSize: '12px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 100,
                                    color: '#373d3f',
                                    formatter: function (w) {
                                        let tongso = w.globals.seriesTotals.reduce((a, b) => {
                                            return a + b
                                        }, 0)
                                        return 'Tổng số ' + tongso.toLocaleString()
                                    }
                                }
                            }
                        },
                    }
                }
            },

        }
    },
    mounted() {
        let dt = [];
        if (this.dulieu && this.dulieu.length > 0) {
            dt = this.dulieu;
            this.bindData(dt);
        }

    },
    components: {
        apexchart: ApexCharts,
    },
    watch: {
        dulieu: function (newVal) {
            console.log("dulieudulieu", this.dulieu)
            if (newVal) {
                this.bindData(newVal)
            }
        }
    },
    methods: {
        bindData(e) {
            this.series = e.data;
            this.bieuTongQuanNhaTruong = {
                ...this.bieuTongQuanNhaTruong, ...{
                    labels: e.tieuDe
                }
            }
        },
    },
}

</script>
<style scoped="scoped">
.apexcharts-canvas {
    margin: 0 auto !important;
}

div#bieudobanh {
    margin: 0 auto;
    text-align: center;
    display: table;
}

</style>
