var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page page-login" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        { staticClass: "row vh-100 align-items-center justify-content-center" },
        [
          _c("div", { staticClass: "col-md-4" }),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4" }, [
            _c("img", { attrs: { src: "/images/bg1.png", alt: "bg" } }),
            _vm._v(" "),
            _c("div", { staticClass: "login-panel card" }, [
              _vm._m(1),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "card-body login__form pt-0" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "formLogin",
                      attrs: { model: _vm.formLogin, rules: _vm.rules },
                    },
                    [
                      _c(
                        "el-form-item",
                        [
                          _vm.alert.message
                            ? _c("el-alert", {
                                staticClass: "animated flipInX text-center",
                                staticStyle: { "line-height": "1" },
                                attrs: {
                                  title: _vm.alert.message,
                                  type: _vm.alert.type,
                                  closable: false,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "code" } },
                        [
                          _c("el-input", {
                            attrs: {
                              name: "code",
                              placeholder: "Nhập",
                              title: "Mã học sinh",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.submitForm("formLogin")
                              },
                            },
                            model: {
                              value: _vm.formLogin.code,
                              callback: function ($$v) {
                                _vm.$set(_vm.formLogin, "code", $$v)
                              },
                              expression: "formLogin.code",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-form-item", { attrs: { prop: "otp" } }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-6" },
                            [
                              _c("el-input", {
                                attrs: {
                                  name: "otp",
                                  placeholder: "Nhập mã OTP",
                                  title: "Mã OTP",
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.submitForm("formLogin")
                                  },
                                },
                                model: {
                                  value: _vm.formLogin.otp,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formLogin, "otp", $$v)
                                  },
                                  expression: "formLogin.otp",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-6 text-center" },
                            [
                              _c(
                                "el-link",
                                {
                                  staticStyle: { "line-height": "1" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.getOptCode($event)
                                    },
                                  },
                                },
                                [
                                  _vm._v("Lấy mã OTP "),
                                  _c("i", { staticClass: "el-icon-refresh" }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.capCha
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "capChaValue" } },
                            [
                              _c(
                                "el-row",
                                {
                                  staticStyle: { "margin-bottom": "0" },
                                  attrs: { gutter: 0 },
                                },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value: !_vm.capCha,
                                          expression: "!capCha",
                                        },
                                      ],
                                      attrs: { span: 8 },
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: {
                                          width: "100%",
                                          height: "40px",
                                          border: "1px solid black",
                                          "border-radius": "4px",
                                        },
                                        attrs: {
                                          src: _vm.capCha,
                                          alt: "Capcha",
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 16 } },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          staticClass: "input-with-select",
                                          attrs: {
                                            name: "capChaValue",
                                            placeholder: "Nhập capcha",
                                          },
                                          model: {
                                            value: _vm.formLogin.capChaValue,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formLogin,
                                                "capChaValue",
                                                $$v
                                              )
                                            },
                                            expression: "formLogin.capChaValue",
                                          },
                                        },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              slot: "append",
                                              icon: "el-icon-refresh",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.refreshCapCha($event)
                                              },
                                            },
                                            slot: "append",
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("el-form-item", { staticClass: "m-0" }, [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "btn btn-lg btn-block btn-login",
                                staticStyle: { "background-color": "#00A65A" },
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.submitForm("formLogin")
                                  },
                                },
                              },
                              [_vm._v("Đăng nhập")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass: "text-center m-0",
                            staticStyle: { color: "#0C9AE3" },
                          },
                          [_vm._v("Hoặc")]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "btn btn-lg btn-block btn-login",
                            attrs: { type: "primary" },
                            on: { click: _vm.linkToLogin },
                          },
                          [
                            _vm._v(
                              "Đăng nhập Email\n                                "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "btn btn-lg btn-block",
                            attrs: { type: "danger" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.linkToSSO()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                                    Đăng nhập SSO"
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4" }),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "overlay" }, [
      _c("div", { staticClass: "lds-ring" }, [
        _c("div"),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _c("div"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "card-header pb-0 border-0",
        staticStyle: { "background-color": "transparent" },
      },
      [
        _c(
          "h3",
          {
            staticClass: "panel-title text-center font-weight-bold",
            staticStyle: { "font-size": "20px", color: "#21BEF4" },
          },
          [_vm._v("Đăng nhập")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }