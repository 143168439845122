import { render, staticRenderFns } from "./DanhSachHocSinh2.vue?vue&type=template&id=4700a18e&scoped=true&"
import script from "./DanhSachHocSinh2.vue?vue&type=script&lang=js&"
export * from "./DanhSachHocSinh2.vue?vue&type=script&lang=js&"
import style0 from "./DanhSachHocSinh2.vue?vue&type=style&index=0&id=4700a18e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4700a18e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\xampp\\htdocs\\sProfiles\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4700a18e')) {
      api.createRecord('4700a18e', component.options)
    } else {
      api.reload('4700a18e', component.options)
    }
    module.hot.accept("./DanhSachHocSinh2.vue?vue&type=template&id=4700a18e&scoped=true&", function () {
      api.rerender('4700a18e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/pages/DanhSachHocSinh2.vue"
export default component.exports