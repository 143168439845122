var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "page",
      attrs: {
        "element-loading-text": "Loading...",
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(0, 0, 0, 0.8)",
      },
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page__header" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: { model: _vm.infoSchool, rules: _vm.rules },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "d-inline-block",
                              attrs: { prop: "namHoc", label: "Năm học" },
                            },
                            [
                              _c("eselect", {
                                ref: "namHoc",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  collapseTags: "",
                                  placeholder: "Chọn",
                                  filterable: "",
                                  data: _vm.list_nam_hoc,
                                  fields: ["name", "value"],
                                },
                                model: {
                                  value: _vm.infoSchool.namHoc,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.infoSchool, "namHoc", $$v)
                                  },
                                  expression: "infoSchool.namHoc",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Tài khoản", prop: "username" } },
                            [
                              _c("el-input", {
                                ref: "username",
                                attrs: { clearable: "", placeholder: "Nhập" },
                                model: {
                                  value: _vm.infoSchool.username,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.infoSchool, "username", $$v)
                                  },
                                  expression: "infoSchool.username",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Mật khẩu", prop: "password" } },
                            [
                              _c("el-input", {
                                ref: "password",
                                attrs: {
                                  type: "password",
                                  "auto-complete": "false",
                                  autocomplete: false,
                                  clearable: "",
                                  placeholder: "Nhập ",
                                },
                                model: {
                                  value: _vm.infoSchool.password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.infoSchool, "password", $$v)
                                  },
                                  expression: "infoSchool.password",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "label",
                            {
                              staticStyle: {
                                color: "transparent",
                                display: "block",
                              },
                              attrs: { for: "" },
                            },
                            [_vm._v("a")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "warning" },
                              on: {
                                click: function ($event) {
                                  return _vm.updateInfoSubmit("ruleForm", 1)
                                },
                              },
                            },
                            [_vm._v("Khởi tạo")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.updateInfoSubmit("ruleForm", 2)
                                },
                              },
                            },
                            [_vm._v("Kiểm tra kết quả")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "page__title d-flex align-items-center justify-content-between",
      },
      [_c("h1", { staticClass: "title" }, [_vm._v("Khởi tạo dữ liệu HCM ")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }