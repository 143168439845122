var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.fullscreenLoading,
            expression: "fullscreenLoading",
          },
        ],
        staticClass: "page page-file file detailHocBa",
        attrs: {
          "element-loading-text": "Hệ thống đang tải dữ liệu học bạ",
          "element-loading-spinner": "el-icon-loading",
          "element-loading-background": "rgba(255, 255, 255, 0.8)",
        },
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "box-upload-file" },
          [
            _c(
              "el-form",
              { attrs: { "hide-required-asterisk": true } },
              [
                _c(
                  "div",
                  { staticClass: "mb-3" },
                  [
                    [
                      _c(
                        "el-form",
                        {
                          ref: "searchForm",
                          attrs: {
                            rules: _vm.rulesSearch,
                            model: _vm.searchForm,
                            "hide-required-asterisk": true,
                          },
                        },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 24 } },
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "d-inline-block",
                                      attrs: { prop: "year", label: "Năm học" },
                                    },
                                    [
                                      _c("eselect", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          collapseTags: "",
                                          placeholder: "Chọn",
                                          filterable: "",
                                          data: _vm.list_nam_hoc,
                                          fields: ["name", "value"],
                                        },
                                        on: { change: _vm.namHocChange },
                                        model: {
                                          value: _vm.searchForm.year,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "year",
                                              $$v
                                            )
                                          },
                                          expression: "searchForm.year",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.user.role != 4 &&
                              _vm.user.role != 1 &&
                              _vm.user.role != 9 &&
                              _vm.user.role != 3
                                ? _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 12,
                                        sm: 8,
                                        md: 6,
                                        lg: 6,
                                        xl: 6,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "d-inline-block",
                                          attrs: {
                                            prop: "maDonVi",
                                            label: "Đơn vị",
                                          },
                                        },
                                        [
                                          _c("eselect", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              disabled: _vm.user.role != 7,
                                              collapseTags: "",
                                              placeholder: "Chọn",
                                              filterable: "",
                                              data: _vm.list_don_vi,
                                              fields: ["name", "value"],
                                            },
                                            on: { change: _vm.capHocChange },
                                            model: {
                                              value: _vm.searchForm.maDonVi,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.searchForm,
                                                  "maDonVi",
                                                  $$v
                                                )
                                              },
                                              expression: "searchForm.maDonVi",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "d-inline-block",
                                      attrs: {
                                        prop: "education",
                                        label: "Cấp học",
                                      },
                                    },
                                    [
                                      _c("eselect", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          disabled:
                                            _vm.optionEducation.length == 1,
                                          collapseTags: "",
                                          placeholder: "Chọn",
                                          filterable: "",
                                          data: _vm.optionEducation,
                                          fields: ["name", "value"],
                                        },
                                        on: { change: _vm.capHocChange },
                                        model: {
                                          value: _vm.searchForm.education,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "education",
                                              $$v
                                            )
                                          },
                                          expression: "searchForm.education",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.user.role != 4 &&
                              _vm.user.role != 1 &&
                              _vm.user.role != 9 &&
                              _vm.user.role != 3
                                ? _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 12,
                                        sm: 8,
                                        md: 6,
                                        lg: 6,
                                        xl: 6,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "d-inline-block",
                                          attrs: {
                                            prop: "education",
                                            label: "Trường học",
                                          },
                                        },
                                        [
                                          _c("eselect", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              collapseTags: "",
                                              placeholder: "Chọn",
                                              filterable: "",
                                              data: _vm.list_truong_hoc,
                                              fields: ["name", "value"],
                                            },
                                            model: {
                                              value: _vm.searchForm.maTruongHoc,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.searchForm,
                                                  "maTruongHoc",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "searchForm.maTruongHoc",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "d-inline-block",
                                      attrs: {
                                        prop: "grade",
                                        label: "Khối học",
                                      },
                                    },
                                    [
                                      _c("eselect", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          collapseTags: "",
                                          placeholder: "Chọn",
                                          filterable: "",
                                          data: _vm.optionGrade,
                                          fields: ["label", "value"],
                                        },
                                        on: { change: _vm.khoiHocChange },
                                        model: {
                                          value: _vm.searchForm.grade,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "grade",
                                              $$v
                                            )
                                          },
                                          expression: "searchForm.grade",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "d-inline-block",
                                      attrs: {
                                        prop: "maLop",
                                        label: "Lớp học",
                                      },
                                    },
                                    [
                                      _c("eselect", {
                                        ref: "maLop",
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          collapseTags: "",
                                          placeholder: "Chọn",
                                          filterable: "",
                                          data: _vm.danh_sach_lop_hoc,
                                          fields: ["name", "value"],
                                        },
                                        on: { change: _vm.LopHocChange },
                                        model: {
                                          value: _vm.searchForm.maLop,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "maLop",
                                              $$v
                                            )
                                          },
                                          expression: "searchForm.maLop",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: { xs: 24, sm: 8, md: 6, lg: 6, xl: 6 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "d-inline-block",
                                      attrs: { label: "Học sinh" },
                                    },
                                    [
                                      _c("eselect", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          collapseTags: "",
                                          placeholder: "Chọn học sinh",
                                          filterable: "",
                                          data: _vm.danh_sach_hoc_sinh,
                                          fields: ["tenHS", "maHS"],
                                        },
                                        model: {
                                          value: _vm.searchForm.student,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "student",
                                              $$v
                                            )
                                          },
                                          expression: "searchForm.student",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                  ],
                  2
                ),
                _vm._v(" "),
                _c("el-divider", { attrs: { "content-position": "left" } }, [
                  _c("span", { staticStyle: { "font-size": "20px" } }, [
                    _vm._v("Cấu hình in"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _vm.searchForm.education == 1
                      ? _c(
                          "el-col",
                          {
                            staticClass: "formInHocBa",
                            attrs: { xs: 24, sm: 6, md: 4, lg: 4, xl: 3 },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "d-inline-block ",
                                staticStyle: { "margin-bottom": "0" },
                              },
                              [
                                _c("label", [_vm._v("Mẫu in")]),
                                _vm._v(" "),
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "Chọn",
                                    },
                                    model: {
                                      value: _vm.isMauCu,
                                      callback: function ($$v) {
                                        _vm.isMauCu = $$v
                                      },
                                      expression: "isMauCu",
                                    },
                                  },
                                  _vm._l(_vm.mau_cu_hay_moi, function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.name,
                                        value: item.value,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isMauCu == 2
                      ? _c(
                          "el-col",
                          {
                            staticClass: "formInHocBa",
                            attrs: { xs: 24, sm: 8, md: 4, lg: 4, xl: 4 },
                          },
                          [
                            _c("label", [_vm._v("Cấu hình in")]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                staticClass: "d-inline-block ",
                                staticStyle: { "margin-bottom": "0" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "Chọn",
                                    },
                                    model: {
                                      value: _vm.inChuKy,
                                      callback: function ($$v) {
                                        _vm.inChuKy = $$v
                                      },
                                      expression: "inChuKy",
                                    },
                                  },
                                  _vm._l(_vm.list_co_khong, function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.name,
                                        value: item.value,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isMauCu != 2
                      ? _c(
                          "el-col",
                          { attrs: { xs: 24, sm: 12, md: 6 } },
                          [
                            _c("label", [_vm._v("Cấu hình in")]),
                            _vm._v(" "),
                            _c("eselect", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                multiple: "",
                                collapseTags: "",
                                placeholder: "Chọn cấu hình in",
                                filterable: "",
                                data: _vm.ds_cau_hinh_in,
                                fields: ["name", "value"],
                              },
                              model: {
                                value: _vm.dsCauHinhIn,
                                callback: function ($$v) {
                                  _vm.dsCauHinhIn = $$v
                                },
                                expression: "dsCauHinhIn",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isMauCu != 2
                      ? _c(
                          "el-col",
                          {
                            staticClass: "formInHocBa",
                            attrs: { xs: 24, sm: 8, md: 6, lg: 4, xl: 3 },
                          },
                          [
                            _c("label", [_vm._v("Đánh số trang KQHT")]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                staticClass: "d-inline-block ",
                                staticStyle: { "margin-bottom": "0" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "Chọn",
                                    },
                                    model: {
                                      value: _vm.isAnSoTrang,
                                      callback: function ($$v) {
                                        _vm.isAnSoTrang = $$v
                                      },
                                      expression: "isAnSoTrang",
                                    },
                                  },
                                  _vm._l(
                                    _vm.ds_tuy_chon_trang_in,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.name,
                                          value: item.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.dsCauHinhIn.includes("inTrang")
                      ? _c(
                          "el-col",
                          {
                            staticClass: "formInHocBa",
                            attrs: { xs: 24, sm: 8, md: 6, lg: 4, xl: 4 },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "d-inline-block ",
                                staticStyle: { "margin-bottom": "0" },
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: {
                                      type: "number",
                                      placeholder: "Trang in cụ thể",
                                      min: 1,
                                      max: 99,
                                    },
                                    model: {
                                      value: _vm.trangInCuThe,
                                      callback: function ($$v) {
                                        _vm.trangInCuThe = $$v
                                      },
                                      expression: "trangInCuThe",
                                    },
                                  },
                                  [
                                    _c("template", { slot: "prepend" }, [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "black" } },
                                        [_vm._v("In riêng trang")]
                                      ),
                                    ]),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.searchForm.education == 1
                      ? _c(
                          "el-col",
                          { attrs: { xs: 24, sm: 12, md: 10, lg: 10 } },
                          [
                            _c(
                              "el-form-item",
                              { staticClass: "d-inline-block" },
                              [
                                _c(
                                  "p",
                                  { staticStyle: { color: "transparent" } },
                                  [_vm._v("Tìm kiếm")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.submitSearch("searchForm", 1)
                                      },
                                    },
                                  },
                                  [_vm._v("Xem học bạ")]
                                ),
                                _vm._v(" "),
                                _vm.isMauCu != 2
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.submitSearch(
                                              "searchForm",
                                              2
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.searchForm.student
                                                ? "Xuất excel"
                                                : "Xuất excel cả lớp"
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.searchForm.education == 2 ||
                                _vm.searchForm.education == 3
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.taiFileKySo(1)
                                          },
                                        },
                                      },
                                      [_vm._v("Xem file ký số\n              ")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.searchForm.education == 2 ||
                                _vm.searchForm.education == 3
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.taiFileKySo(2)
                                          },
                                        },
                                      },
                                      [_vm._v("Tải file ký số\n              ")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.user.role == 4 && _vm.xemChuKy
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.searchForm.student
                                            ? "Xuất file xml"
                                            : "Chỉ có thể xem theo từng học sinh.",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              disabled:
                                                (!_vm.searchForm.student ||
                                                  _vm.searchForm.student ==
                                                    "") &&
                                                _vm.xemChuKy,
                                              type: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.submitSearch(
                                                  "searchForm",
                                                  3
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "Xem file XML\n                "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c(
                          "el-col",
                          { attrs: { xs: 24, sm: 12, md: 12, lg: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { staticClass: "d-inline-block" },
                              [
                                _c(
                                  "p",
                                  { staticStyle: { color: "transparent" } },
                                  [_vm._v("Tìm kiếm")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.submitSearch("searchForm", 1)
                                      },
                                    },
                                  },
                                  [_vm._v("Xem học bạ")]
                                ),
                                _vm._v(" "),
                                _vm.isMauCu != 2
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.submitSearch(
                                              "searchForm",
                                              2
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.searchForm.student
                                                ? "Xuất excel"
                                                : "Xuất excel cả lớp"
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.searchForm.education == 2 ||
                                _vm.searchForm.education == 3
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.taiFileKySo(1)
                                          },
                                        },
                                      },
                                      [_vm._v("Xem file ký số\n              ")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.searchForm.education == 2 ||
                                _vm.searchForm.education == 3
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.taiFileKySo(2)
                                          },
                                        },
                                      },
                                      [_vm._v("Tải file ký số\n              ")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.user.role == 4 && _vm.xemChuKy
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.searchForm.student
                                            ? "Xuất file xml"
                                            : "Chỉ có thể xem theo từng học sinh.",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              disabled:
                                                (!_vm.searchForm.student ||
                                                  _vm.searchForm.student ==
                                                    "") &&
                                                _vm.xemChuKy,
                                              type: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.submitSearch(
                                                  "searchForm",
                                                  3
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "Xem file XML\n                "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                  ],
                  1
                ),
                _vm._v(" "),
                false
                  ? _c(
                      "el-row",
                      {
                        staticStyle: { "margin-bottom": "0" },
                        attrs: { gutter: 24 },
                      },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "mt-3 text-center",
                            attrs: { xs: 24, span: 24 },
                          },
                          [
                            _c(
                              "el-form-item",
                              { staticClass: "d-inline-block" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.submitSearch("searchForm", 1)
                                      },
                                    },
                                  },
                                  [_vm._v("Xem học bạ")]
                                ),
                                _vm._v(" "),
                                _vm.isMauCu != 2
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.submitSearch(
                                              "searchForm",
                                              2
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.searchForm.student
                                                ? "Xuất excel"
                                                : "Xuất excel cả lớp"
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.searchForm.education == 2 ||
                                _vm.searchForm.education == 3
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.taiFileKySo(1)
                                          },
                                        },
                                      },
                                      [_vm._v("Xem file ký số\n              ")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.searchForm.education == 2 ||
                                _vm.searchForm.education == 3
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.taiFileKySo(2)
                                          },
                                        },
                                      },
                                      [_vm._v("Tải file ký số\n              ")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.user.role == 4 && _vm.xemChuKy
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.searchForm.student
                                            ? "Xuất file xml"
                                            : "Chỉ có thể xem theo từng học sinh.",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              disabled:
                                                (!_vm.searchForm.student ||
                                                  _vm.searchForm.student ==
                                                    "") &&
                                                _vm.xemChuKy,
                                              type: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.submitSearch(
                                                  "searchForm",
                                                  3
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "Xem file XML\n                "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "page__content" },
          [
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              [
                _c("el-col", { attrs: { span: _vm.xemChuKy ? 16 : 24 } }, [
                  _vm.chiTietHocBa || _vm.detailHocBaPdf
                    ? _c(
                        "label",
                        {
                          staticStyle: {
                            "font-size": "16px",
                            "padding-bottom": "6px",
                            "font-weight": "bold",
                          },
                        },
                        [_vm._v("Học bạ học sinh")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isClientRender
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 24 } },
                            [
                              _c("el-col", { attrs: { span: 24 } }, [
                                _c("div", { staticClass: "box-upload-file" }, [
                                  _vm.searchForm.grade <= 5
                                    ? _c("div", [
                                        _vm.isMauCuView == 2
                                          ? _c(
                                              "div",
                                              [
                                                _vm.chiTietHocBa
                                                  ? _c("viewPdfC1", {
                                                      attrs: {
                                                        cauHinh: _vm.cauHinhIn,
                                                        chuKy: _vm.inChuKy,
                                                        fileName: _vm.searchForm
                                                          .student
                                                          ? _vm.searchForm
                                                              .student
                                                          : _vm.searchForm.maLop
                                                          ? _vm.searchForm.maLop
                                                          : "Học Bạ Học Sinh",
                                                        item: _vm.chiTietHocBa,
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "div",
                                              [
                                                _vm.chiTietHocBa
                                                  ? _c("viewPdfC1Cu", {
                                                      attrs: {
                                                        cauHinh: _vm.cauHinhIn,
                                                        fileName: _vm.searchForm
                                                          .student
                                                          ? _vm.searchForm
                                                              .student
                                                          : _vm.searchForm.maLop
                                                          ? _vm.searchForm.maLop
                                                          : "Học Bạ Học Sinh",
                                                        item: _vm.chiTietHocBa,
                                                        khoiHoc:
                                                          _vm.searchForm.grade,
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                      ])
                                    : _c(
                                        "div",
                                        [
                                          _vm.chiTietHocBa
                                            ? _c("viewPdfC23", {
                                                attrs: {
                                                  cauHinh: _vm.cauHinhIn,
                                                  fileName: _vm.searchForm
                                                    .student
                                                    ? _vm.searchForm.student
                                                    : _vm.searchForm.maLop
                                                    ? _vm.searchForm.maLop
                                                    : "Học Bạ Học Sinh",
                                                  item: _vm.chiTietHocBa,
                                                  capHoc:
                                                    _vm.searchForm.education,
                                                  khoiHoc: _vm.searchForm.grade,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isClientRender
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.detailHocBaPdf,
                              expression: "detailHocBaPdf",
                            },
                          ],
                          staticClass: "viewHocBa",
                        },
                        [
                          _c("iframe", {
                            staticStyle: {
                              width: "100%",
                              height: "100vh",
                              "min-height": "600px",
                            },
                            attrs: { src: _vm.detailHocBaPdf },
                          }),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.xemChuKy
                  ? _c(
                      "el-col",
                      { staticStyle: { height: "100vh" }, attrs: { span: 8 } },
                      [
                        _vm.chiTietHocBa || _vm.detailHocBaPdf
                          ? _c(
                              "label",
                              {
                                staticStyle: {
                                  "font-size": "16px",
                                  "padding-bottom": "10px",
                                  "font-weight": "bold",
                                },
                              },
                              [_vm._v("Thông tin chữ ký")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.danh_sach_chu_ky.length
                          ? _c(
                              "el-card",
                              {
                                staticClass: "box-card",
                                staticStyle: {
                                  height: "inherit",
                                  overflow: "scroll",
                                },
                              },
                              [
                                _vm._l(
                                  _vm.danh_sach_chu_ky,
                                  function (item, index) {
                                    return [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c(
                                            "el-card",
                                            {
                                              staticClass:
                                                "box-card thongTinChuKy",
                                              staticStyle: {
                                                margin: "5px 10px !important",
                                                border: "1px solid #c1d4ff",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "clearfix",
                                                  attrs: { slot: "header" },
                                                  slot: "header",
                                                },
                                                [
                                                  _c("p", [
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(index + 1) +
                                                          ". Người ký:"
                                                      ),
                                                    ]),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("p", [
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(item.username)
                                                      ),
                                                    ]),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "text item" },
                                                [
                                                  _c("p", [
                                                    _vm._v("Chức vụ: "),
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.ownerPosition
                                                        )
                                                      ),
                                                    ]),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("p", [
                                                    _vm._v("Ngày ký: "),
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(item.signingTime)
                                                      ),
                                                    ]),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("p", [
                                                    _vm._v("Nhà cung cấp: "),
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(item.supplier)
                                                      ),
                                                    ]),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("p", [
                                                    _vm._v("Serial: "),
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.serialNumber
                                                        )
                                                      ),
                                                    ]),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  }
                                ),
                              ],
                              2
                            )
                          : _c(
                              "el-card",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.chiTietHocBa || _vm.detailHocBaPdf,
                                    expression: "chiTietHocBa||detailHocBaPdf",
                                  },
                                ],
                                staticClass: "box-card",
                                staticStyle: {
                                  height: "inherit",
                                  overflow: "scroll",
                                },
                              },
                              [
                                _c("div", { staticClass: "text-center" }, [
                                  _c("p", [
                                    _vm._v(
                                      " File học bạ không có danh sách thông tin chữ ký."
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("hr"),
                                _vm._v(" "),
                                _c("div", { staticClass: "text-left pt-2" }, [
                                  _c("p", [
                                    _c(
                                      "b",
                                      { staticStyle: { color: "#DA1D1D" } },
                                      [_vm._v("Lưu ý:")]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("ul", [
                                    _c("li", [
                                      _c(
                                        "p",
                                        { staticStyle: { color: "#DA1D1D" } },
                                        [
                                          _vm._v(
                                            "Chỉ có thể xem danh sách chữ ký theo từng học sinh"
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]
                            ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "el-dialog",
          {
            staticStyle: { "text-align": "center" },
            attrs: {
              "close-on-press-escape": false,
              "close-on-click-modal": false,
              title: "Thông báo",
              visible: _vm.hienThiThongBaoTongHop,
            },
            on: {
              "update:visible": function ($event) {
                _vm.hienThiThongBaoTongHop = $event
              },
            },
          },
          [
            _c("div", [
              _c("p", { staticStyle: { color: "black" } }, [
                _vm._v(
                  "Học bạ đã được tổng hợp. Bạn có thể xem file đã tổng hợp hoặc tổng hợp\n          mới"
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c("el-button", { on: { click: _vm.dongTongHopMoi } }, [
                  _vm._v("Xem"),
                ]),
                _vm._v(" "),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.tongHopMoi } },
                  [_vm._v("Tổng hợp mới")]
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "el-drawer",
          {
            attrs: { visible: _vm.xem_thong_tin_chu_ky, "with-header": true },
            on: {
              "update:visible": function ($event) {
                _vm.xem_thong_tin_chu_ky = $event
              },
            },
          },
          [
            _c("div", { attrs: { slot: "title" }, slot: "title" }, [
              _c("h4", [_vm._v("Thông tin chữ ký")]),
            ]),
            _vm._v(" "),
            _c(
              "el-row",
              {
                staticStyle: { height: "100vh", overflow: "scroll" },
                attrs: { gutter: 24 },
              },
              [
                _vm._l(_vm.danh_sach_chu_ky, function (item, index) {
                  return [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-card",
                          {
                            staticClass: "box-card",
                            staticStyle: {
                              margin: "5px 10px !important",
                              border: "1px solid #c1d4ff",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "clearfix",
                                attrs: { slot: "header" },
                                slot: "header",
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    " " + _vm._s(index + 1) + ". Người ký:"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(item.username))]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "text item" }, [
                              _c("p", [
                                _c("b", [_vm._v("Ngày ký:")]),
                                _vm._v(" " + _vm._s(item.signingTime)),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _c("b", [_vm._v("Nhà cung cấp:")]),
                                _vm._v(" " + _vm._s(item.supplier)),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _c("b", [_vm._v("Serial:")]),
                                _vm._v(" " + _vm._s(item.serialNumber)),
                              ]),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                }),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "page__title d-flex align-items-center justify-content-between",
      },
      [_c("h1", { staticClass: "title" }, [_vm._v("In học bạ")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }