var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "page page-file file",
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(_vm.view, { tag: "component", on: { close: _vm.closeEdit } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "file--title" }, [
        _c("h3", { staticClass: "title" }, [_vm._v("Sửa học bạ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }