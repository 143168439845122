import util from "../util";

export function authHeader() {
    // console.log('authHeader')

    let tokenSession = sessionStorage.getItem("token");
    // console.log('tokenSession')
    // console.error(tokenSession)
    let tokenCookie = util.getCookie('token')
    // console.log('tokenCookie:')
    // console.error(tokenCookie)
    let tokenLocalStorage = localStorage.getItem('token')
    // console.log('tokenLocalStorage')
    // console.error(tokenLocalStorage)
    let token = tokenCookie ? tokenCookie : tokenLocalStorage ? tokenLocalStorage : tokenSession ? tokenSession : 'Anonymous'
    // console.error('token: '+ token)
    let user = JSON.parse(localStorage.getItem('user'));
    if (token) {
        if (user) {
            return {
                'accessToken': token,
                'token': token,
                'role': user.role ?? 1,
                'userAgent': navigator.userAgent
            };
        } else {
            return {
                'accessToken': token,
                'token': token,
                'role': 1,
                'userAgent': navigator.userAgent
            };
        }
    } else {
        if (user) {
            return {
                'accessToken': 'Anonymous',
                'token': 'Anonymous',
                'role': user.role ?? 1,
                'userAgent': navigator.userAgent
            };
        } else {
            return {
                'token': "Anonymous",
                'userAgent': navigator.userAgent
            };
        }
    }
}
