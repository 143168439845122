var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading.fullScreen,
          expression: "loading.fullScreen",
        },
      ],
      staticClass: "page page-file file ",
      attrs: {
        id: "detailHocBa",
        "element-loading-text": "Loading...",
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(255, 255, 255, 0.8)",
      },
    },
    [
      _c(
        "div",
        { staticClass: "page__title" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _vm.showThongBao
                ? _c(
                    "el-col",
                    { staticClass: "text-center", attrs: { span: 24 } },
                    [
                      _c("i", [
                        _c("b", { staticStyle: { color: "#DA1D1D" } }, [
                          _vm._v("Viettel trân trọng thông báo: "),
                          _c("br"),
                          _vm._v(
                            " Trong thời gian nghỉ Tết từ ngày 20/01/2023\n            (29 Tết) đến hết\n            26/01/2023 (mùng 5 Tết), Viettel duy trì phục vụ khách hàng qua tổng đài 18008000. "
                          ),
                          _c("br"),
                          _vm._v(
                            " Viettel xin\n            kính chúc Quý khách một năm mới An khang - Thịnh vượng. Trân trọng cảm ơn Quý khách đã đồng hành\n            với Viettel!"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("hr"),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 24 } }, [
                _c("h1", { staticClass: "title" }, [_vm._v("Tổng quan")]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "box-upload-file" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("label", [_vm._v("Năm học")]),
                  _vm._v(" "),
                  _c("eselect", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      collapseTags: "",
                      placeholder: "Chọn",
                      filterable: "",
                      data: _vm.list_nam_hoc,
                      fields: ["name", "value"],
                    },
                    model: {
                      value: _vm.searchForm.year,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchForm, "year", $$v)
                      },
                      expression: "searchForm.year",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("div", { staticStyle: { color: "transparent" } }, [
                    _vm._v("Tìm kiếm"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "m-0",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.getRole()
                        },
                      },
                    },
                    [_vm._v("Tìm kiếm")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          false
            ? _c(
                "el-form",
                {
                  ref: "searchForm",
                  attrs: {
                    "label-position": "top",
                    model: _vm.searchForm,
                    "hide-required-asterisk": true,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Năm học", prop: "year" } },
                    [
                      _c("eselect", {
                        staticStyle: { width: "160px" },
                        attrs: {
                          collapseTags: "",
                          placeholder: "Chọn",
                          filterable: "",
                          data: _vm.list_nam_hoc,
                          fields: ["name", "value"],
                        },
                        model: {
                          value: _vm.searchForm.year,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "year", $$v)
                          },
                          expression: "searchForm.year",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.user.role == 6 || _vm.user.role == 7
                    ? _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "370px" },
                          attrs: { prop: "pgd" },
                        },
                        [
                          _vm.user.role == 6
                            ? _c("eselect", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  disabled: "",
                                  data: _vm.listPGD,
                                  filterable: "",
                                  "custom-style": "width: 370px",
                                  placeholder: "Chọn phòng giáo dục",
                                  fields: ["tenDonVi", "maDonVi"],
                                },
                                on: { change: _vm.getListTruong },
                                model: {
                                  value: _vm.searchForm.pgd,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "pgd", $$v)
                                  },
                                  expression: "searchForm.pgd",
                                },
                              })
                            : _c("eselect", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  data: _vm.listPGD,
                                  filterable: "",
                                  "custom-style": "width: 370px",
                                  placeholder: "Chọn phòng giáo dục",
                                  fields: ["tenDonVi", "maDonVi"],
                                },
                                on: { change: _vm.getListTruong },
                                model: {
                                  value: _vm.searchForm.pgd,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "pgd", $$v)
                                  },
                                  expression: "searchForm.pgd",
                                },
                              }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.user.role == 6 || _vm.user.role == 7
                    ? _c(
                        "el-form-item",
                        [
                          _c("eselect", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              data: _vm.listSchool,
                              filterable: "",
                              "custom-style": "width: 350px",
                              placeholder: "Chọn trường",
                              fields: ["tenTruongHoc", "maTruongHoc"],
                            },
                            model: {
                              value: _vm.searchForm.maTruong,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "maTruong", $$v)
                              },
                              expression: "searchForm.maTruong",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.getRole()
                            },
                          },
                        },
                        [_vm._v("Tìm kiếm")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.user &&
      this.infoRole &&
      this.infoRole.isGVBM &&
      !_vm.checkCapHoc("1", _vm.user.capHoc)
        ? _c(
            "div",
            { staticClass: "card-bieu-do" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { staticClass: "header_page", attrs: { span: 24 } },
                    [
                      _c(
                        "h5",
                        [
                          _c("font-awesome-icon", {
                            staticClass: "icon alt",
                            attrs: { icon: ["fas", "users"] },
                          }),
                          _vm._v("\n            GIÁO VIÊN BỘ MÔN\n          "),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-divider"),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { sm: 12, md: 12, lg: 12 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "dts-moibieudo grid-content bg-purple-light",
                        },
                        [
                          _c("div", { staticClass: "dts-card-header" }, [
                            _c("span", { staticClass: "dts-card-title" }, [
                              _vm._v("BIỂU ĐỒ KÝ HỌC BẠ LỚP HỌC GIẢNG DẠY"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "dts-right" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Lấy lại dữ liệu",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.layDuLieuTrangThaiBieuDoTheoLop(
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-refresh",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value:
                                    _vm.loading.giaoVienBoMon
                                      .trangThaiHocBaTheoLop,
                                  expression:
                                    "loading.giaoVienBoMon.trangThaiHocBaTheoLop",
                                },
                              ],
                              staticClass: "dts-card-body",
                              attrs: {
                                "element-loading-text": "Loading...",
                                "element-loading-spinner": "el-icon-loading",
                                "element-loading-background":
                                  "rgba(255, 255, 255, 0.8)",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        !_vm.loading.giaoVienBoMon
                                          .trangThaiHocBaTheoLop,
                                      expression:
                                        "!loading.giaoVienBoMon.trangThaiHocBaTheoLop",
                                    },
                                  ],
                                },
                                [
                                  _c("BieuDoCotSW", {
                                    attrs: {
                                      dulieu:
                                        _vm.duLieuBieuDoTrangThaiHocBaTheoLop,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      this.infoRole && this.infoRole.isGVCN
        ? _c(
            "div",
            { staticClass: "card-bieu-do" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { staticClass: "header_page", attrs: { span: 24 } },
                    [
                      _c(
                        "h5",
                        [
                          _c("font-awesome-icon", {
                            staticClass: "icon alt",
                            attrs: { icon: ["fas", "user-edit"] },
                          }),
                          _vm._v(
                            "\n            GIÁO VIÊN CHỦ NHIỆM\n          "
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-divider"),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { sm: 12, md: 8, lg: 8 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "dts-moibieudo grid-content bg-purple-light",
                        },
                        [
                          _c("div", { staticClass: "dts-card-header" }, [
                            _c("span", { staticClass: "dts-card-title" }, [
                              _vm._v("Biểu đồ trạng thái học sinh"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "dts-right" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Chi tiết",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.showChiTietThongKeTrangThaiHocSinhGvcn(
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [_c("i", { staticClass: "el-icon-info" })]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Lấy lại dữ liệu",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.layDuLieuThongKeTrangThaiHocSinhGVCN(
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-refresh",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value:
                                    _vm.loading.giaoVienChuNhiem
                                      .thongKeTrangThaiHocSinhGvcn,
                                  expression:
                                    "loading.giaoVienChuNhiem.thongKeTrangThaiHocSinhGvcn",
                                },
                              ],
                              staticClass: "dts-card-body",
                              attrs: {
                                "element-loading-text": "Loading...",
                                "element-loading-spinner": "el-icon-loading",
                                "element-loading-background":
                                  "rgba(255, 255, 255, 0.8)",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        !_vm.loading.giaoVienChuNhiem
                                          .thongKeTrangThaiHocSinhGvcn,
                                      expression:
                                        "!loading.giaoVienChuNhiem.thongKeTrangThaiHocSinhGvcn",
                                    },
                                  ],
                                },
                                [
                                  _c("BieuDoCotDistributed", {
                                    attrs: {
                                      label: _vm.trangThaiHocSinh,
                                      dulieu:
                                        _vm.duLieuBieuDoThongKeTrangThaiHocSinhGvcn,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { sm: 12, md: 8, lg: 8 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "dts-moibieudo grid-content bg-purple-light",
                        },
                        [
                          _c("div", { staticClass: "dts-card-header" }, [
                            _c("span", { staticClass: "dts-card-title" }, [
                              _vm._v("BIỂU ĐỒ HỌC BẠ - HỌC SINH LỚP CHỦ NHIỆM"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "dts-right" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Lấy lại dữ liệu",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.layDuLieuBieuDoHocBaHocSinhGvcn(
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-refresh",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value:
                                    _vm.loading.giaoVienChuNhiem
                                      .soLuongHocBaHocSinh,
                                  expression:
                                    "loading.giaoVienChuNhiem.soLuongHocBaHocSinh",
                                },
                              ],
                              staticClass: "dts-card-body",
                              attrs: {
                                "element-loading-text": "Loading...",
                                "element-loading-spinner": "el-icon-loading",
                                "element-loading-background":
                                  "rgba(255, 255, 255, 0.8)",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        !_vm.loading.giaoVienChuNhiem
                                          .soLuongHocBaHocSinh,
                                      expression:
                                        "!loading.giaoVienChuNhiem.soLuongHocBaHocSinh",
                                    },
                                  ],
                                },
                                [
                                  _c("BieuDoCotKhongXepChong", {
                                    attrs: {
                                      dulieu: _vm.duLieuBieuDoHocBaGvcn,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.anBieuDo
                    ? _c("el-col", { attrs: { sm: 12, md: 8, lg: 8 } }, [
                        _c("div", { staticClass: "grid-content" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "dts-moibieudo grid-content bg-purple-light",
                            },
                            [
                              _c("div", { staticClass: "dts-card-header" }, [
                                _c("span", { staticClass: "dts-card-title" }, [
                                  _vm._v("Biểu đồ học sinh lên lớp - thi lại"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "dts-right" },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          content: "Chi tiết",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.showChiTietThongKeHocSinhThiLaiGvcn(
                                                  $event
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-info",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          content: "Lấy lại dữ liệu",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.layDuLieuThongKeHocSinhThiLaiGvcn(
                                                  $event
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-refresh",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value:
                                        _vm.loading.giaoVienChuNhiem
                                          .thongKeHocSinhThiLai,
                                      expression:
                                        "loading.giaoVienChuNhiem.thongKeHocSinhThiLai",
                                    },
                                  ],
                                  staticClass: "dts-card-body",
                                  attrs: {
                                    "element-loading-text": "Loading...",
                                    "element-loading-spinner":
                                      "el-icon-loading",
                                    "element-loading-background":
                                      "rgba(255, 255, 255, 0.8)",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            !_vm.loading.giaoVienChuNhiem
                                              .thongKeHocSinhThiLai,
                                          expression:
                                            "!loading.giaoVienChuNhiem.thongKeHocSinhThiLai",
                                        },
                                      ],
                                    },
                                    [
                                      _c("BieuDoCotDistributed", {
                                        attrs: {
                                          label: _vm.hocSinhThiLaiHieuTruong,
                                          dulieu:
                                            _vm.duLieuBieuDoThongKeHocSinhThiLaiGvcn,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-col", { attrs: { sm: 12, md: 8, lg: 8 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "dts-moibieudo grid-content bg-purple-light",
                        },
                        [
                          _c("div", { staticClass: "dts-card-header" }, [
                            _c("span", { staticClass: "dts-card-title" }, [
                              _vm._v("BIỂU ĐỒ KÝ HỌC BẠ LỚP CHỦ NHIỆM"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "dts-right" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Lấy lại dữ liệu",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.layDuLieuTrangThaiBieuDoTrangThaiHocBaTheoGvcn()
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-refresh",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value:
                                    _vm.loading.giaoVienChuNhiem.trangThaiHocBa,
                                  expression:
                                    "loading.giaoVienChuNhiem.trangThaiHocBa",
                                },
                              ],
                              staticClass: "dts-card-body",
                              attrs: {
                                "element-loading-text": "Loading...",
                                "element-loading-spinner": "el-icon-loading",
                                "element-loading-background":
                                  "rgba(255, 255, 255, 0.8)",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        !_vm.loading.giaoVienChuNhiem
                                          .trangThaiHocBa,
                                      expression:
                                        "!loading.giaoVienChuNhiem.trangThaiHocBa",
                                    },
                                  ],
                                },
                                [
                                  _c("BieuDoHinhTron", {
                                    attrs: {
                                      label: _vm.daKyChuaKy,
                                      dulieu:
                                        _vm.duLieuBieuDoTrangThaiHocBaTheoTrangThaiGiaoVien,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  this.user &&
                  _vm.checkCapHoc("1", _vm.user.capHoc) &&
                  _vm.anBieuDo
                    ? _c("el-col", { attrs: { sm: 12, md: 8, lg: 8 } }, [
                        _c("div", { staticClass: "grid-content" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "dts-moibieudo grid-content bg-purple-light",
                            },
                            [
                              _c("div", { staticClass: "dts-card-header" }, [
                                _c("span", { staticClass: "dts-card-title" }, [
                                  _vm._v("BIỂU ĐỒ THỐNG KÊ KẾT QUẢ GIÁO DỤC"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "dts-right" },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          content: "Lấy lại dữ liệu",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.layDuLieuThongKeDanhGiaKetQuaGiaoDuc(
                                                  $event
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-refresh",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value:
                                        _vm.loading.giaoVienChuNhiem
                                          .thongKeKQGD,
                                      expression:
                                        "loading.giaoVienChuNhiem.thongKeKQGD",
                                    },
                                  ],
                                  staticClass: "dts-card-body",
                                  attrs: {
                                    "element-loading-text": "Loading...",
                                    "element-loading-spinner":
                                      "el-icon-loading",
                                    "element-loading-background":
                                      "rgba(255, 255, 255, 0.8)",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            !_vm.loading.giaoVienChuNhiem
                                              .thongKeKQGD,
                                          expression:
                                            "!loading.giaoVienChuNhiem.thongKeKQGD",
                                        },
                                      ],
                                    },
                                    [
                                      _c("BieuDoHinhTron", {
                                        attrs: {
                                          label: _vm.danhGia,
                                          dulieu: _vm.duLieuBieuDoThongKeKQGD,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  this.user && _vm.user.capHoc > 1 && _vm.anBieuDo
                    ? _c("el-col", { attrs: { sm: 24, md: 16, lg: 16 } }, [
                        _c("div", { staticClass: "grid-content" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "dts-moibieudo grid-content bg-purple-light",
                            },
                            [
                              _c("div", { staticClass: "dts-card-header" }, [
                                _c("span", { staticClass: "dts-card-title" }, [
                                  _vm._v(
                                    "BIỂU ĐỒ THỐNG KÊ HẠNH KIỂM - HỌC LỰC HỌC SINH"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "dts-right" },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          size: "mini",
                                          placeholder: "Chọn học kì",
                                        },
                                        on: { change: _vm.onHocKyChange },
                                        model: {
                                          value: _vm.hocKy,
                                          callback: function ($$v) {
                                            _vm.hocKy = $$v
                                          },
                                          expression: "hocKy",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            value: 1,
                                            label: "Học kỳ I",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: {
                                            value: 2,
                                            label: "Học kỳ II",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { value: 3, label: "Cả năm" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value:
                                        _vm.loading.hieuTruong.thongKeHanhKiem,
                                      expression:
                                        "loading.hieuTruong.thongKeHanhKiem",
                                    },
                                  ],
                                  staticClass: "dts-card-body",
                                  attrs: {
                                    "element-loading-text": "Loading...",
                                    "element-loading-spinner":
                                      "el-icon-loading",
                                    "element-loading-background":
                                      "rgba(255, 255, 255, 0.8)",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-sm-12 col-md-6" },
                                      [
                                        _c("div", { staticClass: "d-flex" }, [
                                          _c("strong", [
                                            _vm._v("Thống kê hạnh kiểm"),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "dts-right" },
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  attrs: {
                                                    content: "Lấy lại dữ liệu",
                                                    placement: "top",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: { href: "#" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.layDuLieuThongKeHanhKiem(
                                                            $event
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-refresh",
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  !_vm.loading.hieuTruong
                                                    .thongKeHanhKiem,
                                                expression:
                                                  "!loading.hieuTruong.thongKeHanhKiem",
                                              },
                                            ],
                                          },
                                          [
                                            _c("BieuDoHinhTron", {
                                              attrs: {
                                                label: _vm.hanhKiem,
                                                dulieu:
                                                  _vm.duLieuBieuDoThongKeHanhKiem,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-sm-12 col-md-6" },
                                      [
                                        _c("div", { staticClass: "d-flex" }, [
                                          _c("strong", {}, [
                                            _vm._v("Thống kê học lực"),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "dts-right" },
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  attrs: {
                                                    content: "Lấy lại dữ liệu",
                                                    placement: "top",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: { href: "#" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.layDuLieuThongKeHocLuc(
                                                            $event
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-refresh",
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  !_vm.loading.hieuTruong
                                                    .thongKeHocLuc,
                                                expression:
                                                  "!loading.hieuTruong.thongKeHocLuc",
                                              },
                                            ],
                                          },
                                          [
                                            _c("BieuDoHinhTron", {
                                              attrs: {
                                                label: _vm.hocLuc,
                                                dulieu:
                                                  _vm.duLieuBieuDoThongKeHocLuc,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  this.user && _vm.user.capHoc > 1
                    ? _c("el-col", { attrs: { sm: 12, md: 12, lg: 12 } }, [
                        _c("div", { staticClass: "grid-content" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "dts-moibieudo grid-content bg-purple-light",
                            },
                            [
                              _c("div", { staticClass: "dts-card-header" }, [
                                _c("span", { staticClass: "dts-card-title" }, [
                                  _vm._v("Biểu đồ trạng thái theo môn học"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "dts-right" },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          content: "Lấy lại dữ liệu",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.layDuLieuBieuDoTrangThaiHocBaTheoMonHocGvcn(
                                                  $event
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-refresh",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value:
                                        _vm.loading.giaoVienChuNhiem
                                          .trangThaiHocBaTheoMonHoc,
                                      expression:
                                        "loading.giaoVienChuNhiem.trangThaiHocBaTheoMonHoc",
                                    },
                                  ],
                                  staticClass: "dts-card-body",
                                  attrs: {
                                    "element-loading-text": "Loading...",
                                    "element-loading-spinner":
                                      "el-icon-loading",
                                    "element-loading-background":
                                      "rgba(255, 255, 255, 0.8)",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            !_vm.loading.giaoVienChuNhiem
                                              .trangThaiHocBaTheoMonHoc,
                                          expression:
                                            "!loading.giaoVienChuNhiem.trangThaiHocBaTheoMonHoc",
                                        },
                                      ],
                                    },
                                    [
                                      _c("BieuDoTrangThaiTheoMon", {
                                        attrs: {
                                          type: 1,
                                          dulieu:
                                            _vm.duLieuBieuDoTrangThaiHocBaTheoMonHoc,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      this.infoRole && this.infoRole.isHieuTruong
        ? _c(
            "div",
            { staticClass: "card-bieu-do" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { staticClass: "header_page", attrs: { span: 24 } },
                    [
                      _c(
                        "h5",
                        [
                          _c("font-awesome-icon", {
                            staticClass: "icon alt",
                            attrs: { icon: ["fas", "user-tie"] },
                          }),
                          _vm._v("\n            HIỆU TRƯỞNG\n          "),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-divider"),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { sm: 12, md: 8, lg: 8 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "dts-moibieudo grid-content bg-purple-light",
                        },
                        [
                          _c("div", { staticClass: "dts-card-header" }, [
                            _c("span", { staticClass: "dts-card-title" }, [
                              _vm._v(
                                "Biểu đồ trạng thái học sinh toàn trường "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "dts-right" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Chi tiết",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.showChiTietThongKeTrangThaiHocSinhHieuTruong(
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [_c("i", { staticClass: "el-icon-info" })]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Lấy lại dữ liệu",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.layDuLieuThongKeTrangThaiHocSinhHieuTruong(
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-refresh",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value:
                                    _vm.loading.hieuTruong
                                      .thongKeTrangThaiHocSinh,
                                  expression:
                                    "loading.hieuTruong.thongKeTrangThaiHocSinh",
                                },
                              ],
                              staticClass: "dts-card-body",
                              attrs: {
                                "element-loading-text": "Loading...",
                                "element-loading-spinner": "el-icon-loading",
                                "element-loading-background":
                                  "rgba(255, 255, 255, 0.8)",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        !_vm.loading.hieuTruong
                                          .thongKeTrangThaiHocSinh,
                                      expression:
                                        "!loading.hieuTruong.thongKeTrangThaiHocSinh",
                                    },
                                  ],
                                },
                                [
                                  _c("BieuDoCotDistributed", {
                                    attrs: {
                                      label: _vm.trangThaiHocSinh,
                                      dulieu:
                                        _vm.duLieuBieuDoThongKeTrangThaiHocSinhHieuTruong,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { sm: 12, md: 8, lg: 8 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "dts-moibieudo grid-content bg-purple-light",
                        },
                        [
                          _c("div", { staticClass: "dts-card-header" }, [
                            _c("span", { staticClass: "dts-card-title" }, [
                              _vm._v("BIỂU ĐỒ HỌC BẠ - HỌC SINH TOÀN TRƯỜNG"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "dts-right" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Chi tiết",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.xemChiTietHocBaTheoGiaoVienCN(
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [_c("i", { staticClass: "el-icon-info" })]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Lấy lại dữ liệu",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.layDuLieuBieuDoTrangThaiHocBaTheoMonHoc(
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-refresh",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loading.hieuTruong.hocBaHieuTruong,
                                  expression:
                                    "loading.hieuTruong.hocBaHieuTruong",
                                },
                              ],
                              staticClass: "dts-card-body",
                              attrs: {
                                "element-loading-text": "Loading...",
                                "element-loading-spinner": "el-icon-loading",
                                "element-loading-background":
                                  "rgba(255, 255, 255, 0.8)",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        !_vm.loading.hieuTruong.hocBaHieuTruong,
                                      expression:
                                        "!loading.hieuTruong.hocBaHieuTruong",
                                    },
                                  ],
                                },
                                [
                                  _c("BieuDoCotDistributed", {
                                    attrs: {
                                      dulieu:
                                        _vm.duLieuBieuDoHocBaHocSinhHieuTruong,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.anBieuDo
                    ? _c("el-col", { attrs: { sm: 12, md: 8, lg: 8 } }, [
                        _c("div", { staticClass: "grid-content" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "dts-moibieudo grid-content bg-purple-light",
                            },
                            [
                              _c("div", { staticClass: "dts-card-header" }, [
                                _c("span", { staticClass: "dts-card-title" }, [
                                  _vm._v("Biểu đồ học sinh lên lớp - thi lại"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "dts-right" },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          content: "Chi tiết",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.showChiTietThongKeHocSinhThiLaiHieuTruong(
                                                  $event
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-info",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          content: "Lấy lại dữ liệu",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.layDuLieuThongKeHocSinhThiLaiHieuTruong(
                                                  $event
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-refresh",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value:
                                        _vm.loading.hieuTruong
                                          .thongKeHocSinhThiLai,
                                      expression:
                                        "loading.hieuTruong.thongKeHocSinhThiLai",
                                    },
                                  ],
                                  staticClass: "dts-card-body",
                                  attrs: {
                                    "element-loading-text": "Loading...",
                                    "element-loading-spinner":
                                      "el-icon-loading",
                                    "element-loading-background":
                                      "rgba(255, 255, 255, 0.8)",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            !_vm.loading.hieuTruong
                                              .thongKeHocSinhThiLai,
                                          expression:
                                            "!loading.hieuTruong.thongKeHocSinhThiLai",
                                        },
                                      ],
                                    },
                                    [
                                      _c("BieuDoCotDistributed", {
                                        attrs: {
                                          label: _vm.hocSinhThiLaiHieuTruong,
                                          dulieu:
                                            _vm.duLieuBieuDoThongKeHocSinhThiLaiHieuTruong,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-col", { attrs: { sm: 12, md: 12, lg: 12 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "dts-moibieudo grid-content bg-purple-light",
                        },
                        [
                          _c("div", { staticClass: "dts-card-header" }, [
                            _c("span", { staticClass: "dts-card-title" }, [
                              _vm._v("BIỂU ĐỒ GVCN KÝ HỌC BẠ"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "dts-right" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Chi tiết trạng thái",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.thongKeChiTietTrangThaiHocBaGvcn(
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [_c("i", { staticClass: "el-icon-info" })]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Lấy lại dữ liệu",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.layDuLieuTrangThaiBieuDoTheoLopGVCNToanTruong(
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-refresh",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value:
                                    _vm.loading.hieuTruong
                                      .trangThaiHocBaTheoLopGVCNToanTruong,
                                  expression:
                                    "loading.hieuTruong.trangThaiHocBaTheoLopGVCNToanTruong",
                                },
                              ],
                              staticClass: "dts-card-body",
                              attrs: {
                                "element-loading-text": "Loading...",
                                "element-loading-spinner": "el-icon-loading",
                                "element-loading-background":
                                  "rgba(255, 255, 255, 0.8)",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        !_vm.loading.giaoVienBoMon
                                          .trangThaiHocBaTheoLopGVCNToanTruong,
                                      expression:
                                        "!loading.giaoVienBoMon.trangThaiHocBaTheoLopGVCNToanTruong",
                                    },
                                  ],
                                },
                                [
                                  _c("BieuDoCotSW", {
                                    attrs: {
                                      dulieu:
                                        _vm.duLieuBieuDoTrangThaiHocBaTheoLopGVCNToanTruong,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { sm: 12, md: 12, lg: 12 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "dts-moibieudo grid-content bg-purple-light",
                        },
                        [
                          _c("div", { staticClass: "dts-card-header" }, [
                            _c("span", { staticClass: "dts-card-title" }, [
                              _vm._v("BIỂU ĐỒ HIỆU TRƯỞNG KÝ HỌC BẠ"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "dts-right" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Chi tiết",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.xemChiTietTongQuanHieuTruong(
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [_c("i", { staticClass: "el-icon-info" })]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Lấy lại dữ liệu",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.layDuLieuBieuDoTrangThaiHocBaTongQuanHieuTruong(
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-refresh",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loading.hieuTruong.tongQuan,
                                  expression: "loading.hieuTruong.tongQuan",
                                },
                              ],
                              staticClass: "dts-card-body",
                              attrs: {
                                "element-loading-text": "Loading...",
                                "element-loading-spinner": "el-icon-loading",
                                "element-loading-background":
                                  "rgba(255, 255, 255, 0.8)",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.loading.hieuTruong.tongQuan,
                                      expression:
                                        "!loading.hieuTruong.tongQuan",
                                    },
                                  ],
                                },
                                [
                                  _c("BieuDoCotSW", {
                                    attrs: {
                                      label: _vm.daKyChuaKy,
                                      dulieu:
                                        _vm.duLieuBieuDoTrangThaiHocBaTongQuanHieuTruong,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.user && _vm.user.capHoc > 1
                    ? _c(
                        "el-col",
                        {
                          staticClass: "mt-3",
                          attrs: { sm: 12, md: 8, lg: 8 },
                        },
                        [
                          _c("div", { staticClass: "grid-content" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "dts-moibieudo grid-content bg-purple-light",
                              },
                              [
                                _c("div", { staticClass: "dts-card-header" }, [
                                  _c(
                                    "span",
                                    { staticClass: "dts-card-title" },
                                    [_vm._v("Biểu đồ trạng thái theo môn học")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "dts-right" },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            content: "Lấy lại dữ liệu",
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: { href: "#" },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.layDuLieuBieuDoTrangThaiHocBaTheoMonHocGvcn(
                                                    $event
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-refresh",
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value:
                                          _vm.loading.giaoVienChuNhiem
                                            .trangThaiHocBaTheoMonHoc,
                                        expression:
                                          "loading.giaoVienChuNhiem.trangThaiHocBaTheoMonHoc",
                                      },
                                    ],
                                    staticClass: "dts-card-body",
                                    attrs: {
                                      "element-loading-text": "Loading...",
                                      "element-loading-spinner":
                                        "el-icon-loading",
                                      "element-loading-background":
                                        "rgba(255, 255, 255, 0.8)",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              !_vm.loading.giaoVienChuNhiem
                                                .trangThaiHocBaTheoMonHoc,
                                            expression:
                                              "!loading.giaoVienChuNhiem.trangThaiHocBaTheoMonHoc",
                                          },
                                        ],
                                      },
                                      [
                                        _c("BieuDoTrangThaiTheoMon", {
                                          attrs: {
                                            type: 1,
                                            dulieu:
                                              _vm.duLieuBieuDoTrangThaiHocBaTheoMonHoc,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.user && _vm.user.capHoc > 1 && _vm.anBieuDo
                    ? _c(
                        "el-col",
                        {
                          staticClass: "mt-3",
                          attrs: { sm: 24, md: 16, lg: 16 },
                        },
                        [
                          _c("div", { staticClass: "grid-content" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "dts-moibieudo grid-content bg-purple-light",
                              },
                              [
                                _c("div", { staticClass: "dts-card-header" }, [
                                  _c(
                                    "span",
                                    { staticClass: "dts-card-title" },
                                    [
                                      _vm._v(
                                        "BIỂU ĐỒ THỐNG KÊ HẠNH KIỂM - HỌC LỰC HỌC SINH"
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "dts-right" },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            size: "mini",
                                            placeholder: "Chọn học kì",
                                          },
                                          on: { change: _vm.onHocKyChange },
                                          model: {
                                            value: _vm.hocKy,
                                            callback: function ($$v) {
                                              _vm.hocKy = $$v
                                            },
                                            expression: "hocKy",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              value: 1,
                                              label: "Học kỳ I",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              value: 2,
                                              label: "Học kỳ II",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              value: 3,
                                              label: "Cả năm",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value:
                                          _vm.loading.hieuTruong
                                            .thongKeHanhKiem,
                                        expression:
                                          "loading.hieuTruong.thongKeHanhKiem",
                                      },
                                    ],
                                    staticClass: "dts-card-body",
                                    attrs: {
                                      "element-loading-text": "Loading...",
                                      "element-loading-spinner":
                                        "el-icon-loading",
                                      "element-loading-background":
                                        "rgba(255, 255, 255, 0.8)",
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-sm-12 col-md-6" },
                                        [
                                          _c("div", { staticClass: "d-flex" }, [
                                            _c("strong", [
                                              _vm._v("Thống kê hạnh kiểm"),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "dts-right" },
                                              [
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    attrs: {
                                                      content:
                                                        "Lấy lại dữ liệu",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: { href: "#" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.layDuLieuThongKeHanhKiem(
                                                              $event
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-refresh",
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    !_vm.loading.hieuTruong
                                                      .thongKeHanhKiem,
                                                  expression:
                                                    "!loading.hieuTruong.thongKeHanhKiem",
                                                },
                                              ],
                                            },
                                            [
                                              _c("BieuDoHinhTron", {
                                                attrs: {
                                                  label: _vm.hanhKiem,
                                                  dulieu:
                                                    _vm.duLieuBieuDoThongKeHanhKiem,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-sm-12 col-md-6" },
                                        [
                                          _c("div", { staticClass: "d-flex" }, [
                                            _c("strong", {}, [
                                              _vm._v("Thống kê học lực"),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "dts-right" },
                                              [
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    attrs: {
                                                      content:
                                                        "Lấy lại dữ liệu",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: { href: "#" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.layDuLieuThongKeHocLuc(
                                                              $event
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-refresh",
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    !_vm.loading.hieuTruong
                                                      .thongKeHocLuc,
                                                  expression:
                                                    "!loading.hieuTruong.thongKeHocLuc",
                                                },
                                              ],
                                            },
                                            [
                                              _c("BieuDoHinhTron", {
                                                attrs: {
                                                  label: _vm.hocLuc,
                                                  dulieu:
                                                    _vm.duLieuBieuDoThongKeHocLuc,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  this.user &&
                  _vm.checkCapHoc("1", _vm.user.capHoc) &&
                  _vm.anBieuDo
                    ? _c("el-col", { attrs: { sm: 12, md: 8, lg: 8 } }, [
                        _c("div", { staticClass: "grid-content" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "dts-moibieudo grid-content bg-purple-light",
                            },
                            [
                              _c("div", { staticClass: "dts-card-header" }, [
                                _c("span", { staticClass: "dts-card-title" }, [
                                  _vm._v("BIỂU ĐỒ THỐNG KÊ KẾT QUẢ GIÁO DỤC"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "dts-right" },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          content: "Lấy lại dữ liệu",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.layDuLieuThongKeDanhGiaKetQuaGiaoDuc(
                                                  $event
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-refresh",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.loading.hieuTruong.thongKeKQGD,
                                      expression:
                                        "loading.hieuTruong.thongKeKQGD",
                                    },
                                  ],
                                  staticClass: "dts-card-body",
                                  attrs: {
                                    "element-loading-text": "Loading...",
                                    "element-loading-spinner":
                                      "el-icon-loading",
                                    "element-loading-background":
                                      "rgba(255, 255, 255, 0.8)",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            !_vm.loading.hieuTruong.thongKeKQGD,
                                          expression:
                                            "!loading.hieuTruong.thongKeKQGD",
                                        },
                                      ],
                                    },
                                    [
                                      _c("BieuDoHinhTron", {
                                        attrs: {
                                          label: _vm.danhGia,
                                          dulieu: _vm.duLieuBieuDoThongKeKQGD,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      this.infoRole && this.infoRole.isCanBo
        ? _c(
            "div",
            { staticClass: "card-bieu-do" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { staticClass: "header_page", attrs: { span: 24 } },
                    [
                      _c(
                        "h5",
                        [
                          _c("font-awesome-icon", {
                            staticClass: "icon alt",
                            attrs: { icon: ["fas", "user-cog"] },
                          }),
                          _vm._v("\n            CÁN BỘ QUẢN LÝ\n          "),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-divider"),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { sm: 12, md: 12, lg: 8 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "dts-moibieudo grid-content bg-purple-light",
                        },
                        [
                          _c("div", { staticClass: "dts-card-header" }, [
                            _c("span", { staticClass: "dts-card-title" }, [
                              _vm._v(
                                "Biểu đồ trạng thái học sinh toàn trường "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "dts-right" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Chi tiết",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.showChiTietThongKeTrangThaiHocSinhHieuTruong(
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [_c("i", { staticClass: "el-icon-info" })]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Lấy lại dữ liệu",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.layDuLieuThongKeTrangThaiHocSinhHieuTruong(
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-refresh",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value:
                                    _vm.loading.hieuTruong
                                      .thongKeTrangThaiHocSinh,
                                  expression:
                                    "loading.hieuTruong.thongKeTrangThaiHocSinh",
                                },
                              ],
                              staticClass: "dts-card-body",
                              attrs: {
                                "element-loading-text": "Loading...",
                                "element-loading-spinner": "el-icon-loading",
                                "element-loading-background":
                                  "rgba(255, 255, 255, 0.8)",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        !_vm.loading.hieuTruong
                                          .thongKeTrangThaiHocSinh,
                                      expression:
                                        "!loading.hieuTruong.thongKeTrangThaiHocSinh",
                                    },
                                  ],
                                },
                                [
                                  _c("BieuDoCotDistributed", {
                                    attrs: {
                                      label: _vm.trangThaiHocSinh,
                                      dulieu:
                                        _vm.duLieuBieuDoThongKeTrangThaiHocSinhHieuTruong,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { sm: 12, md: 12, lg: 8 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "dts-moibieudo grid-content bg-purple-light",
                        },
                        [
                          _c("div", { staticClass: "dts-card-header" }, [
                            _c("span", { staticClass: "dts-card-title" }, [
                              _vm._v("BIỂU ĐỒ HỌC BẠ - HỌC SINH TOÀN TRƯỜNG"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "dts-right" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Chi tiết",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.xemChiTietHocBaTheoGiaoVienCN(
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [_c("i", { staticClass: "el-icon-info" })]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Lấy lại dữ liệu",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.layDuLieuBieuDoTrangThaiHocBaTheoMonHoc(
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-refresh",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loading.hieuTruong.hocBaHieuTruong,
                                  expression:
                                    "loading.hieuTruong.hocBaHieuTruong",
                                },
                              ],
                              staticClass: "dts-card-body",
                              attrs: {
                                "element-loading-text": "Loading...",
                                "element-loading-spinner": "el-icon-loading",
                                "element-loading-background":
                                  "rgba(255, 255, 255, 0.8)",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        !_vm.loading.hieuTruong.hocBaHieuTruong,
                                      expression:
                                        "!loading.hieuTruong.hocBaHieuTruong",
                                    },
                                  ],
                                },
                                [
                                  _c("BieuDoCotDistributed", {
                                    attrs: {
                                      dulieu:
                                        _vm.duLieuBieuDoHocBaHocSinhHieuTruong,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { sm: 12, md: 12, lg: 8 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "dts-moibieudo grid-content bg-purple-light",
                        },
                        [
                          _c("div", { staticClass: "dts-card-header" }, [
                            _c("span", { staticClass: "dts-card-title" }, [
                              _vm._v("Biểu đồ trạng thái upload chữ ký "),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "dts-right" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Lấy lại dữ liệu",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.layDuLieuThongKeTrangThaiUploadChuKy(
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-refresh",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value:
                                    _vm.loading.hieuTruong
                                      .thongKeTrangThaiUploadChuKy,
                                  expression:
                                    "loading.hieuTruong.thongKeTrangThaiUploadChuKy",
                                },
                              ],
                              staticClass: "dts-card-body",
                              attrs: {
                                "element-loading-text": "Loading...",
                                "element-loading-spinner": "el-icon-loading",
                                "element-loading-background":
                                  "rgba(255, 255, 255, 0.8)",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        !_vm.loading.hieuTruong
                                          .thongKeTrangThaiUploadChuKy,
                                      expression:
                                        "!loading.hieuTruong.thongKeTrangThaiUploadChuKy",
                                    },
                                  ],
                                },
                                [
                                  _c("BieuDoCotDistributed", {
                                    attrs: {
                                      label: _vm.trangThaiChuKy,
                                      dulieu:
                                        _vm.duLieuBieuDoThongKeTrangThaiUploadChuKy,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { sm: 12, md: 12, lg: 12 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "dts-moibieudo grid-content bg-purple-light",
                        },
                        [
                          _c("div", { staticClass: "dts-card-header" }, [
                            _c("span", { staticClass: "dts-card-title" }, [
                              _vm._v("BIỂU ĐỒ GVCN KÝ HỌC BẠ"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "dts-right" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Chi tiết trạng thái",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.thongKeChiTietTrangThaiHocBaGvcn(
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [_c("i", { staticClass: "el-icon-info" })]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Lấy lại dữ liệu",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.layDuLieuTrangThaiBieuDoTheoLopGVCNToanTruong(
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-refresh",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value:
                                    _vm.loading.hieuTruong
                                      .trangThaiHocBaTheoLopGVCNToanTruong,
                                  expression:
                                    "loading.hieuTruong.trangThaiHocBaTheoLopGVCNToanTruong",
                                },
                              ],
                              staticClass: "dts-card-body",
                              attrs: {
                                "element-loading-text": "Loading...",
                                "element-loading-spinner": "el-icon-loading",
                                "element-loading-background":
                                  "rgba(255, 255, 255, 0.8)",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        !_vm.loading.giaoVienBoMon
                                          .trangThaiHocBaTheoLopGVCNToanTruong,
                                      expression:
                                        "!loading.giaoVienBoMon.trangThaiHocBaTheoLopGVCNToanTruong",
                                    },
                                  ],
                                },
                                [
                                  _c("BieuDoCotSW", {
                                    attrs: {
                                      dulieu:
                                        _vm.duLieuBieuDoTrangThaiHocBaTheoLopGVCNToanTruong,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.user && _vm.user.capHoc > 1
                    ? _c("el-col", { attrs: { sm: 12, md: 12, lg: 12 } }, [
                        _c("div", { staticClass: "grid-content" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "dts-moibieudo grid-content bg-purple-light",
                            },
                            [
                              _c("div", { staticClass: "dts-card-header" }, [
                                _c("span", { staticClass: "dts-card-title" }, [
                                  _vm._v("Biểu đồ trạng thái theo môn học"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "dts-right" },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          content: "Lấy lại dữ liệu",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.layDuLieuBieuDoTrangThaiHocBaTheoMonHocGvcn(
                                                  $event
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-refresh",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value:
                                        _vm.loading.giaoVienChuNhiem
                                          .trangThaiHocBaTheoMonHoc,
                                      expression:
                                        "loading.giaoVienChuNhiem.trangThaiHocBaTheoMonHoc",
                                    },
                                  ],
                                  staticClass: "dts-card-body",
                                  attrs: {
                                    "element-loading-text": "Loading...",
                                    "element-loading-spinner":
                                      "el-icon-loading",
                                    "element-loading-background":
                                      "rgba(255, 255, 255, 0.8)",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            !_vm.loading.giaoVienChuNhiem
                                              .trangThaiHocBaTheoMonHoc,
                                          expression:
                                            "!loading.giaoVienChuNhiem.trangThaiHocBaTheoMonHoc",
                                        },
                                      ],
                                    },
                                    [
                                      _c("BieuDoTrangThaiTheoMon", {
                                        attrs: {
                                          type: 1,
                                          dulieu:
                                            _vm.duLieuBieuDoTrangThaiHocBaTheoMonHoc,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-col", { attrs: { sm: 12, md: 12, lg: 12 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "dts-moibieudo grid-content bg-purple-light",
                        },
                        [
                          _c("div", { staticClass: "dts-card-header" }, [
                            _c("span", { staticClass: "dts-card-title" }, [
                              _vm._v("BIỂU ĐỒ HIỆU TRƯỞNG KÝ HỌC BẠ"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "dts-right" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Chi tiết",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.xemChiTietTongQuanHieuTruong(
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [_c("i", { staticClass: "el-icon-info" })]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Lấy lại dữ liệu",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.layDuLieuBieuDoTrangThaiHocBaTongQuanHieuTruong(
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-refresh",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loading.hieuTruong.tongQuan,
                                  expression: "loading.hieuTruong.tongQuan",
                                },
                              ],
                              staticClass: "dts-card-body",
                              attrs: {
                                "element-loading-text": "Loading...",
                                "element-loading-spinner": "el-icon-loading",
                                "element-loading-background":
                                  "rgba(255, 255, 255, 0.8)",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.loading.hieuTruong.tongQuan,
                                      expression:
                                        "!loading.hieuTruong.tongQuan",
                                    },
                                  ],
                                },
                                [
                                  _c("BieuDoCotSW", {
                                    attrs: {
                                      label: _vm.daKyChuaKy,
                                      dulieu:
                                        _vm.duLieuBieuDoTrangThaiHocBaTongQuanHieuTruong,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.anBieuDo
                    ? _c("el-col", { attrs: { sm: 12, md: 12, lg: 12 } }, [
                        _c("div", { staticClass: "grid-content" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "dts-moibieudo grid-content bg-purple-light",
                            },
                            [
                              _c("div", { staticClass: "dts-card-header" }, [
                                _c("span", { staticClass: "dts-card-title" }, [
                                  _vm._v("Biểu đồ học sinh lên lớp - thi lại"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "dts-right" },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          content: "Chi tiết",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.showChiTietThongKeHocSinhThiLaiHieuTruong(
                                                  $event
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-info",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          content: "Lấy lại dữ liệu",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.layDuLieuThongKeHocSinhThiLaiHieuTruong(
                                                  $event
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-refresh",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value:
                                        _vm.loading.hieuTruong
                                          .thongKeHocSinhThiLai,
                                      expression:
                                        "loading.hieuTruong.thongKeHocSinhThiLai",
                                    },
                                  ],
                                  staticClass: "dts-card-body",
                                  attrs: {
                                    "element-loading-text": "Loading...",
                                    "element-loading-spinner":
                                      "el-icon-loading",
                                    "element-loading-background":
                                      "rgba(255, 255, 255, 0.8)",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            !_vm.loading.hieuTruong
                                              .thongKeHocSinhThiLai,
                                          expression:
                                            "!loading.hieuTruong.thongKeHocSinhThiLai",
                                        },
                                      ],
                                    },
                                    [
                                      _c("BieuDoCotDistributed", {
                                        attrs: {
                                          label: _vm.hocSinhThiLaiHieuTruong,
                                          dulieu:
                                            _vm.duLieuBieuDoThongKeHocSinhThiLaiHieuTruong,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.user && _vm.user.capHoc > 1 && _vm.anBieuDo
                    ? _c("el-col", { attrs: { sm: 24, md: 16, lg: 16 } }, [
                        _c("div", { staticClass: "grid-content" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "dts-moibieudo grid-content bg-purple-light",
                            },
                            [
                              _c("div", { staticClass: "dts-card-header" }, [
                                _c("span", { staticClass: "dts-card-title" }, [
                                  _vm._v(
                                    "BIỂU ĐỒ THỐNG KÊ HẠNH KIỂM - HỌC LỰC HỌC SINH"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "dts-right" },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          size: "mini",
                                          placeholder: "Chọn học kì",
                                        },
                                        on: { change: _vm.onHocKyChange },
                                        model: {
                                          value: _vm.hocKy,
                                          callback: function ($$v) {
                                            _vm.hocKy = $$v
                                          },
                                          expression: "hocKy",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            value: 1,
                                            label: "Học kỳ I",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: {
                                            value: 2,
                                            label: "Học kỳ II",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { value: 3, label: "Cả năm" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value:
                                        _vm.loading.hieuTruong.thongKeHanhKiem,
                                      expression:
                                        "loading.hieuTruong.thongKeHanhKiem",
                                    },
                                  ],
                                  staticClass: "dts-card-body",
                                  attrs: {
                                    "element-loading-text": "Loading...",
                                    "element-loading-spinner":
                                      "el-icon-loading",
                                    "element-loading-background":
                                      "rgba(255, 255, 255, 0.8)",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-sm-12 col-md-6" },
                                      [
                                        _c("div", { staticClass: "d-flex" }, [
                                          _c("strong", [
                                            _vm._v("Thống kê hạnh kiểm"),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "dts-right" },
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  attrs: {
                                                    content: "Lấy lại dữ liệu",
                                                    placement: "top",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: { href: "#" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.layDuLieuThongKeHanhKiem(
                                                            $event
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-refresh",
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  !_vm.loading.hieuTruong
                                                    .thongKeHanhKiem,
                                                expression:
                                                  "!loading.hieuTruong.thongKeHanhKiem",
                                              },
                                            ],
                                          },
                                          [
                                            _c("BieuDoHinhTron", {
                                              attrs: {
                                                label: _vm.hanhKiem,
                                                dulieu:
                                                  _vm.duLieuBieuDoThongKeHanhKiem,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-sm-12 col-md-6" },
                                      [
                                        _c("div", { staticClass: "d-flex" }, [
                                          _c("strong", {}, [
                                            _vm._v("Thống kê học lực"),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "dts-right" },
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  attrs: {
                                                    content: "Lấy lại dữ liệu",
                                                    placement: "top",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: { href: "#" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.layDuLieuThongKeHocLuc(
                                                            $event
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-refresh",
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  !_vm.loading.hieuTruong
                                                    .thongKeHocLuc,
                                                expression:
                                                  "!loading.hieuTruong.thongKeHocLuc",
                                              },
                                            ],
                                          },
                                          [
                                            _c("BieuDoHinhTron", {
                                              attrs: {
                                                label: _vm.hocLuc,
                                                dulieu:
                                                  _vm.duLieuBieuDoThongKeHocLuc,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.user &&
                  _vm.checkCapHoc("1", _vm.user.capHoc) &&
                  _vm.anBieuDo
                    ? _c("el-col", { attrs: { sm: 12, md: 12, lg: 12 } }, [
                        _c("div", { staticClass: "grid-content" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "dts-moibieudo grid-content bg-purple-light",
                            },
                            [
                              _c("div", { staticClass: "dts-card-header" }, [
                                _c("span", { staticClass: "dts-card-title" }, [
                                  _vm._v("BIỂU ĐỒ THỐNG KÊ KẾT QUẢ GIÁO DỤC"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "dts-right" },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          content: "Lấy lại dữ liệu",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.layDuLieuThongKeDanhGiaKetQuaGiaoDuc(
                                                  $event
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-refresh",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.loading.hieuTruong.thongKeKQGD,
                                      expression:
                                        "loading.hieuTruong.thongKeKQGD",
                                    },
                                  ],
                                  staticClass: "dts-card-body",
                                  attrs: {
                                    "element-loading-text": "Loading...",
                                    "element-loading-spinner":
                                      "el-icon-loading",
                                    "element-loading-background":
                                      "rgba(255, 255, 255, 0.8)",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            !_vm.loading.hieuTruong.thongKeKQGD,
                                          expression:
                                            "!loading.hieuTruong.thongKeKQGD",
                                        },
                                      ],
                                    },
                                    [
                                      _c("BieuDoHinhTron", {
                                        attrs: {
                                          label: _vm.danhGia,
                                          dulieu: _vm.duLieuBieuDoThongKeKQGD,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      (_vm.user && _vm.user.role === 6) || (_vm.user && _vm.user.role === 7)
        ? _c(
            "div",
            { staticClass: "card-bieu-do" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { staticClass: "header_page", attrs: { span: 24 } },
                    [
                      _c(
                        "h5",
                        [
                          _c("font-awesome-icon", {
                            staticClass: "icon alt",
                            attrs: { icon: ["fas", "chart-bar"] },
                          }),
                          _vm._v("\n            TỔNG QUAN\n          "),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-divider"),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { sm: 12, md: 8, lg: 8 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "dts-moibieudo grid-content bg-purple-light",
                        },
                        [
                          _c("div", { staticClass: "dts-card-header" }, [
                            _c("span", { staticClass: "dts-card-title" }, [
                              _vm._v("Học sinh"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "dts-right" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Lấy lại dữ liệu",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.getBieuDoTongQuanHocSinhTheoSoPhong(
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-refresh",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loading.tongQuan.hocSinh,
                                  expression: "loading.tongQuan.hocSinh",
                                },
                              ],
                              staticClass: "dts-card-body",
                              attrs: {
                                "element-loading-text": "Loading...",
                                "element-loading-spinner": "el-icon-loading",
                                "element-loading-background":
                                  "rgba(255, 255, 255, 0.8)",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.loading.tongQuan.hocSinh,
                                      expression: "!loading.tongQuan.hocSinh",
                                    },
                                  ],
                                },
                                [
                                  _c("BieuDoTron", {
                                    attrs: {
                                      dulieu: _vm.duLieuBieuDo.tongQuan.hocSinh,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { sm: 12, md: 8, lg: 8 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "dts-moibieudo grid-content bg-purple-light",
                        },
                        [
                          _c("div", { staticClass: "dts-card-header" }, [
                            _c("span", { staticClass: "dts-card-title" }, [
                              _vm._v("Học bạ"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "dts-right" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Lấy lại dữ liệu",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.getBieuDoTongQuanHocBaTheoSoPhong(
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-refresh",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loading.tongQuan.hocBa,
                                  expression: "loading.tongQuan.hocBa",
                                },
                              ],
                              staticClass: "dts-card-body",
                              attrs: {
                                "element-loading-text": "Loading...",
                                "element-loading-spinner": "el-icon-loading",
                                "element-loading-background":
                                  "rgba(255, 255, 255, 0.8)",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.loading.tongQuan.hocBa,
                                      expression: "!loading.tongQuan.hocBa",
                                    },
                                  ],
                                },
                                [
                                  _c("BieuDoTron", {
                                    attrs: {
                                      dulieu: _vm.duLieuBieuDo.tongQuan.hocBa,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { sm: 12, md: 8, lg: 8 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "dts-moibieudo grid-content bg-purple-light",
                        },
                        [
                          _c("div", { staticClass: "dts-card-header" }, [
                            _c("span", { staticClass: "dts-card-title" }, [
                              _vm._v("Học bạ - học sinh"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "dts-right" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Lấy lại dữ liệu",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.layLaiDuLieuHocBaHocSinhTheoSoPhong(
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-refresh",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value:
                                    _vm.loading.tongQuan.hocSinh ||
                                    _vm.loading.tongQuan.hocBa ||
                                    _vm.loading.tongQuan.hocBaHocSinh,
                                  expression:
                                    "loading.tongQuan.hocSinh || loading.tongQuan.hocBa||loading.tongQuan.hocBaHocSinh",
                                },
                              ],
                              staticClass: "dts-card-body",
                              attrs: {
                                "element-loading-text": "Loading...",
                                "element-loading-spinner": "el-icon-loading",
                                "element-loading-background":
                                  "rgba(255, 255, 255, 0.8)",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        !_vm.loading.tongQuan.hocSinh &&
                                        !_vm.loading.tongQuan.hocBa &&
                                        !_vm.loading.tongQuan.hocBaHocSinh,
                                      expression:
                                        "!loading.tongQuan.hocSinh && !loading.tongQuan.hocBa && !loading.tongQuan.hocBaHocSinh",
                                    },
                                  ],
                                },
                                [
                                  _c("BieuDoCot", {
                                    attrs: {
                                      dulieu:
                                        _vm.duLieuBieuDo.tongQuan.hocBaHocSinh,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { sm: 12, md: 8, lg: 8 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "dts-moibieudo grid-content bg-purple-light",
                        },
                        [
                          _c("div", { staticClass: "dts-card-header" }, [
                            _c("span", { staticClass: "dts-card-title" }, [
                              _vm._v(
                                "Trạng thái giáo viên chủ nhiệm ký học bạ"
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "dts-right" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Lấy lại dữ liệu",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.getBieuDoTongQuanGVCNTheoPhongSo(
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-refresh",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loading.tongQuan.tongQuanGVCN,
                                  expression: "loading.tongQuan.tongQuanGVCN",
                                },
                              ],
                              staticClass: "dts-card-body",
                              attrs: {
                                "element-loading-text": "Loading...",
                                "element-loading-spinner": "el-icon-loading",
                                "element-loading-background":
                                  "rgba(255, 255, 255, 0.8)",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.loading.tongQuan.tongQuanGVCN,
                                      expression:
                                        "!loading.tongQuan.tongQuanGVCN",
                                    },
                                  ],
                                },
                                [
                                  _c("BieuDoCot", {
                                    attrs: {
                                      label: _vm.daKyChuaKy,
                                      dulieu:
                                        _vm.duLieuBieuDo.tongQuan.tongQuanGVCN,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { sm: 12, md: 8, lg: 8 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "dts-moibieudo grid-content bg-purple-light",
                        },
                        [
                          _c("div", { staticClass: "dts-card-header" }, [
                            _c("span", { staticClass: "dts-card-title" }, [
                              _vm._v("Trạng thái hiệu trưởng ký học bạ"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "dts-right" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Lấy lại dữ liệu",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.getBieuDoTongQuanHieuTruongTheoPhongSo(
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-refresh",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value:
                                    _vm.loading.tongQuan.tongQuanHieuTruong,
                                  expression:
                                    "loading.tongQuan.tongQuanHieuTruong",
                                },
                              ],
                              staticClass: "dts-card-body",
                              attrs: {
                                "element-loading-text": "Loading...",
                                "element-loading-spinner": "el-icon-loading",
                                "element-loading-background":
                                  "rgba(255, 255, 255, 0.8)",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        !_vm.loading.tongQuan
                                          .tongQuanHieuTruong,
                                      expression:
                                        "!loading.tongQuan.tongQuanHieuTruong",
                                    },
                                  ],
                                },
                                [
                                  _c("BieuDoCot", {
                                    attrs: {
                                      label: _vm.daKyChuaKy,
                                      dulieu:
                                        _vm.duLieuBieuDo.tongQuan
                                          .tongQuanHieuTruong,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { sm: 12, md: 8, lg: 8 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "dts-moibieudo grid-content bg-purple-light",
                        },
                        [
                          _c("div", { staticClass: "dts-card-header" }, [
                            _c("span", { staticClass: "dts-card-title" }, [
                              _vm._v("Số lượng học bạ ký lại"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "dts-right" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Lấy lại dữ liệu",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.getBieuDoTongQuanKyLaiHocBaTheoPhongSo(
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-refresh",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value:
                                    _vm.loading.tongQuan.trangThaiKyLaiHocBa,
                                  expression:
                                    "loading.tongQuan.trangThaiKyLaiHocBa",
                                },
                              ],
                              staticClass: "dts-card-body",
                              attrs: {
                                "element-loading-text": "Loading...",
                                "element-loading-spinner": "el-icon-loading",
                                "element-loading-background":
                                  "rgba(255, 255, 255, 0.8)",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        !_vm.loading.tongQuan
                                          .trangThaiKyLaiHocBa,
                                      expression:
                                        "!loading.tongQuan.trangThaiKyLaiHocBa",
                                    },
                                  ],
                                },
                                [
                                  _c("BieuDoCot", {
                                    attrs: {
                                      dulieu:
                                        _vm.duLieuBieuDo.tongQuan
                                          .trangThaiKyLaiHocBa,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { sm: 12, md: 12, lg: 12 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "dts-moibieudo grid-content bg-purple-light",
                        },
                        [
                          _c("div", { staticClass: "dts-card-header" }, [
                            _c("span", { staticClass: "dts-card-title" }, [
                              _vm._v("Trạng thái ký học bạ theo môn học THCS"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "dts-right" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Lấy lại dữ liệu",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.getBieuDoTrangThaiHocBaTheoMonHocTHCSTheoSoPhong(
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-refresh",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value:
                                    _vm.loading.tongQuan
                                      .trangThaiHocBaTHCSTheoMonHoc,
                                  expression:
                                    "loading.tongQuan.trangThaiHocBaTHCSTheoMonHoc",
                                },
                              ],
                              staticClass: "dts-card-body",
                              attrs: {
                                "element-loading-text": "Loading...",
                                "element-loading-spinner": "el-icon-loading",
                                "element-loading-background":
                                  "rgba(255, 255, 255, 0.8)",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        !_vm.loading.tongQuan
                                          .trangThaiHocBaTHCSTheoMonHoc,
                                      expression:
                                        "!loading.tongQuan.trangThaiHocBaTHCSTheoMonHoc",
                                    },
                                  ],
                                },
                                [
                                  _c("BieuDoTrangThaiTheoMon", {
                                    attrs: {
                                      type: 1,
                                      dulieu:
                                        _vm.duLieuBieuDo.tongQuan
                                          .trangThaiHocBaTHCSTheoMonHoc,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { sm: 12, md: 12, lg: 12 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "dts-moibieudo grid-content bg-purple-light",
                        },
                        [
                          _c("div", { staticClass: "dts-card-header" }, [
                            _c("span", { staticClass: "dts-card-title" }, [
                              _vm._v("Trạng thái ký học bạ theo môn học THPT"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "dts-right" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Lấy lại dữ liệu",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.getBieuDoTrangThaiHocBaTheoMonHocTHPTTheoSoPhong(
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-refresh",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value:
                                    _vm.loading.tongQuan
                                      .trangThaiHocBaTHPTTheoMonHoc,
                                  expression:
                                    "loading.tongQuan.trangThaiHocBaTHPTTheoMonHoc",
                                },
                              ],
                              staticClass: "dts-card-body",
                              attrs: {
                                "element-loading-text": "Loading...",
                                "element-loading-spinner": "el-icon-loading",
                                "element-loading-background":
                                  "rgba(255, 255, 255, 0.8)",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        !_vm.loading.tongQuan
                                          .trangThaiHocBaTHPTTheoMonHoc,
                                      expression:
                                        "!loading.tongQuan.trangThaiHocBaTHPTTheoMonHoc",
                                    },
                                  ],
                                },
                                [
                                  _c("BieuDoTrangThaiTheoMon", {
                                    attrs: {
                                      type: 1,
                                      dulieu:
                                        _vm.duLieuBieuDo.tongQuan
                                          .trangThaiHocBaTHPTTheoMonHoc,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      (_vm.user && _vm.user.role === 6) || (_vm.user && _vm.user.role === 7)
        ? _c(
            "div",
            { staticClass: "card-bieu-do" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { staticClass: "header_page", attrs: { span: 24 } },
                    [
                      _c(
                        "h5",
                        [
                          _c("font-awesome-icon", {
                            staticClass: "icon alt",
                            attrs: { icon: ["fas", "school"] },
                          }),
                          _vm._v("\n            TIỂU HỌC\n          "),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-divider"),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { sm: 12, md: 8, lg: 8 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "dts-moibieudo grid-content bg-purple-light",
                        },
                        [
                          _c("div", { staticClass: "dts-card-header" }, [
                            _c("span", { staticClass: "dts-card-title" }, [
                              _vm._v("THỐNG KÊ ĐÁNH GIÁ KẾT QUẢ GIÁO DỤC"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "dts-right" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Lấy lại dữ liệu",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.getDuLieuThongKeDanhGiaKetQuaGiaoDucTheoSoPhong(
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-refresh",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loading.capMot.thongKeKQGD,
                                  expression: "loading.capMot.thongKeKQGD",
                                },
                              ],
                              staticClass: "dts-card-body",
                              attrs: {
                                "element-loading-text": "Loading...",
                                "element-loading-spinner": "el-icon-loading",
                                "element-loading-background":
                                  "rgba(255, 255, 255, 0.8)",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.loading.capMot.thongKeKQGD,
                                      expression: "!loading.capMot.thongKeKQGD",
                                    },
                                  ],
                                },
                                [
                                  _c("BieuDoHinhTron", {
                                    attrs: {
                                      label: _vm.danhGia,
                                      dulieu:
                                        _vm.duLieuBieuDo.capMot
                                          .duLieuBieuDoThongKeKQGD,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      (_vm.user && _vm.user.role === 6) || (_vm.user && _vm.user.role === 7)
        ? _c(
            "div",
            { staticClass: "card-bieu-do" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { staticClass: "header_page", attrs: { span: 24 } },
                    [
                      _c(
                        "h5",
                        [
                          _c("font-awesome-icon", {
                            staticClass: "icon alt",
                            attrs: { icon: ["fas", "school"] },
                          }),
                          _vm._v("\n            TRUNG HỌC CƠ SỞ\n          "),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-divider"),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { sm: 24, md: 16, lg: 16 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "dts-moibieudo grid-content bg-purple-light",
                        },
                        [
                          _c("div", { staticClass: "dts-card-header" }, [
                            _c("span", { staticClass: "dts-card-title" }, [
                              _vm._v("THỐNG KÊ HẠNH KIỂM - HỌC LỰC HỌC SINH"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "dts-right" },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      size: "mini",
                                      placeholder: "Chọn học kì",
                                    },
                                    on: {
                                      change:
                                        _vm.onHocLucHanhKiemHocKyTHCSChange,
                                    },
                                    model: {
                                      value: _vm.hocKy,
                                      callback: function ($$v) {
                                        _vm.hocKy = $$v
                                      },
                                      expression: "hocKy",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { value: 1, label: "Học kỳ I" },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: { value: 2, label: "Học kỳ II" },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: { value: 3, label: "Cả năm" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "dts-card-body" }, [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.loading.capHai.thongKeHanhKiem,
                                      expression:
                                        "loading.capHai.thongKeHanhKiem",
                                    },
                                  ],
                                  staticClass: "col-sm-12 col-md-6",
                                  attrs: {
                                    "element-loading-text": "Loading...",
                                    "element-loading-spinner":
                                      "el-icon-loading",
                                    "element-loading-background":
                                      "rgba(255, 255, 255, 0.8)",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "d-flex" }, [
                                    _c("strong", [
                                      _vm._v("Thống kê hạnh kiểm"),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "dts-right" },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              content: "Lấy lại dữ liệu",
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.getDuLieuThongKeHanhKiemTHCSTheoSoPhong(
                                                      $event
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-refresh",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            !_vm.loading.capHai.thongKeHanhKiem,
                                          expression:
                                            "!loading.capHai.thongKeHanhKiem",
                                        },
                                      ],
                                    },
                                    [
                                      _c("BieuDoHinhTron", {
                                        attrs: {
                                          label: _vm.hanhKiem,
                                          dulieu:
                                            _vm.duLieuBieuDo.capHai
                                              .thongKeHanhKiem,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.loading.capHai.thongKeHocLuc,
                                      expression:
                                        "loading.capHai.thongKeHocLuc",
                                    },
                                  ],
                                  staticClass: "col-sm-12 col-md-6",
                                  attrs: {
                                    "element-loading-text": "Loading...",
                                    "element-loading-spinner":
                                      "el-icon-loading",
                                    "element-loading-background":
                                      "rgba(255, 255, 255, 0.8)",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "d-flex" }, [
                                    _c("strong", {}, [
                                      _vm._v("Thống kê học lực"),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "dts-right" },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              content: "Lấy lại dữ liệu",
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.getDuLieuThongKeHocLucTHCSTheoSoPhong(
                                                      $event
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-refresh",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            !_vm.loading.capHai.thongKeHocLuc,
                                          expression:
                                            "!loading.capHai.thongKeHocLuc",
                                        },
                                      ],
                                    },
                                    [
                                      _c("BieuDoHinhTron", {
                                        attrs: {
                                          label: _vm.hocLuc,
                                          dulieu:
                                            _vm.duLieuBieuDo.capHai
                                              .thongKeHocLuc,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      (_vm.user && _vm.user.role === 6) || (_vm.user && _vm.user.role === 7)
        ? _c(
            "div",
            { staticClass: "card-bieu-do" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { staticClass: "header_page", attrs: { span: 24 } },
                    [
                      _c(
                        "h5",
                        [
                          _c("font-awesome-icon", {
                            staticClass: "icon alt",
                            attrs: { icon: ["fas", "school"] },
                          }),
                          _vm._v(
                            "\n            TRUNG HỌC PHỔ THÔNG\n          "
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-divider"),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { sm: 24, md: 16, lg: 16 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "dts-moibieudo grid-content bg-purple-light",
                        },
                        [
                          _c("div", { staticClass: "dts-card-header" }, [
                            _c("span", { staticClass: "dts-card-title" }, [
                              _vm._v("THỐNG KÊ HẠNH KIỂM - HỌC LỰC HỌC SINH"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "dts-right" },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      size: "mini",
                                      placeholder: "Chọn học kì",
                                    },
                                    on: {
                                      change:
                                        _vm.onHocLucHanhKiemHocKyTHPTChange,
                                    },
                                    model: {
                                      value: _vm.hocKy,
                                      callback: function ($$v) {
                                        _vm.hocKy = $$v
                                      },
                                      expression: "hocKy",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { value: 1, label: "Học kỳ I" },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: { value: 2, label: "Học kỳ II" },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: { value: 3, label: "Cả năm" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "dts-card-body" }, [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.loading.capBa.thongKeHanhKiem,
                                      expression:
                                        "loading.capBa.thongKeHanhKiem",
                                    },
                                  ],
                                  staticClass: "col-sm-12 col-md-6",
                                  attrs: {
                                    "element-loading-text": "Loading...",
                                    "element-loading-spinner":
                                      "el-icon-loading",
                                    "element-loading-background":
                                      "rgba(255, 255, 255, 0.8)",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "d-flex" }, [
                                    _c("strong", [
                                      _vm._v("Thống kê hạnh kiểm"),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "dts-right" },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              content: "Lấy lại dữ liệu",
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.getDuLieuThongKeHanhKiemTHPTTheoSoPhong(
                                                      $event
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-refresh",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            !_vm.loading.capBa.thongKeHanhKiem,
                                          expression:
                                            "!loading.capBa.thongKeHanhKiem",
                                        },
                                      ],
                                    },
                                    [
                                      _c("BieuDoHinhTron", {
                                        attrs: {
                                          label: _vm.hanhKiem,
                                          dulieu:
                                            _vm.duLieuBieuDo.capBa
                                              .thongKeHanhKiem,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.loading.capBa.thongKeHocLuc,
                                      expression: "loading.capBa.thongKeHocLuc",
                                    },
                                  ],
                                  staticClass: "col-sm-12 col-md-6",
                                  attrs: {
                                    "element-loading-text": "Loading...",
                                    "element-loading-spinner":
                                      "el-icon-loading",
                                    "element-loading-background":
                                      "rgba(255, 255, 255, 0.8)",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "d-flex" }, [
                                    _c("strong", {}, [
                                      _vm._v("Thống kê học lực"),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "dts-right" },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              content: "Lấy lại dữ liệu",
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.getDuLieuThongKeHocLucTHPTTheoSoPhong(
                                                      $event
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-refresh",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            !_vm.loading.capBa.thongKeHocLuc,
                                          expression:
                                            "!loading.capBa.thongKeHocLuc",
                                        },
                                      ],
                                    },
                                    [
                                      _c("BieuDoHinhTron", {
                                        attrs: {
                                          label: _vm.hocLuc,
                                          dulieu:
                                            _vm.duLieuBieuDo.capBa
                                              .thongKeHocLuc,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                "close-on-press-escape": false,
                "close-on-click-modal": false,
                title: "Trạng thái học bạ theo lớp môn",
                fullscreen: "",
                center: "",
                visible: _vm.hien_thi_chi_tiet_trang_thai_theo_mon,
              },
              on: {
                close: _vm.dongThongTin,
                "update:visible": function ($event) {
                  _vm.hien_thi_chi_tiet_trang_thai_theo_mon = $event
                },
              },
            },
            [
              _c("ChiTietBieuDoTrangThaiTheoMon", {
                attrs: {
                  loadChiTiet: _vm.loadChiTiet,
                  du_lieu_bieu_do:
                    _vm.du_lieu_bieu_do_trang_thai_hoc_ba_theo_lop,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                "close-on-press-escape": false,
                "close-on-click-modal": false,
                title: "Chi tiết",
                center: "",
                width: "70%",
                top: "5vh",
                visible: _vm.chi_tiet_danh_sach_trang_thai_ky_hoc_ba_theo_gvcn,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.chi_tiet_danh_sach_trang_thai_ky_hoc_ba_theo_gvcn = $event
                },
              },
            },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    border: "",
                    "max-height": _vm.maxHeight,
                    data: _vm.danh_sach_trang_thai_ky_hoc_ba_theo_gvcn,
                    "show-summary": "",
                    "summary-method": _vm.getSummaries,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "STT",
                      width: "100",
                      align: "center",
                      type: "index",
                      "class-name": "text-center",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "header-align": "center",
                      align: "center",
                      property: "tenLop",
                      label: "Tên lớp",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      property: "hocBa",
                      align: "center",
                      "header-align": "center",
                      label: "Học bạ",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      property: "hocSinh",
                      align: "center",
                      "header-align": "center",
                      label: "Học sinh",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                "close-on-press-escape": false,
                "close-on-click-modal": false,
                title: "Chi tiết",
                center: "",
                width: "70%",
                top: "5vh",
                visible: _vm.chi_tiet_danh_sach_trang_thai_hoc_sinh_theo_gvcn,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.chi_tiet_danh_sach_trang_thai_hoc_sinh_theo_gvcn = $event
                },
              },
            },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    border: "",
                    "max-height": _vm.maxHeight,
                    data: _vm.danh_sach_trang_thai_hoc_sinh_theo_gvcn,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "STT",
                      width: "100",
                      align: "center",
                      type: "index",
                      "class-name": "text-center",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "header-align": "center",
                      align: "center",
                      property: "tenLop",
                      label: "Tên lớp",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      property: "tongSoHocSinh",
                      align: "center",
                      "header-align": "center",
                      label: "Tổng số học sinh",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      property: "dangHoc",
                      align: "center",
                      "header-align": "center",
                      label: "Đang học",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      property: "boHoc",
                      align: "center",
                      "header-align": "center",
                      label: "Bỏ học",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      property: "chuyenTruong",
                      align: "center",
                      "header-align": "center",
                      label: "Chuyển trường",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                "close-on-press-escape": false,
                "close-on-click-modal": false,
                title: "Chi tiết",
                center: "",
                width: "70%",
                top: "5vh",
                visible: _vm.chi_tiet_danh_sach_hoc_sinh_thi_lai_theo_gvcn,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.chi_tiet_danh_sach_hoc_sinh_thi_lai_theo_gvcn = $event
                },
              },
            },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    border: "",
                    "max-height": _vm.maxHeight,
                    data: _vm.danh_sach_hoc_sinh_thi_lai_theo_gvcn,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "STT",
                      width: "100",
                      align: "center",
                      type: "index",
                      "class-name": "text-center",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "header-align": "center",
                      align: "center",
                      property: "tenLop",
                      label: "Tên lớp",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      property: "tongSoHocBa",
                      align: "center",
                      "header-align": "center",
                      label: "Tổng số học bạ",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      property: "lenLop",
                      align: "center",
                      "header-align": "center",
                      label: "Lên lớp",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      property: "thiLai",
                      align: "center",
                      "header-align": "center",
                      label: "Thi lại",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      property: "lenLopSauThiLai",
                      align: "center",
                      "header-align": "center",
                      label: "Lên lớp sau thi lại",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                "close-on-press-escape": false,
                "close-on-click-modal": false,
                title: "Chi tiết",
                center: "",
                width: "70%",
                top: "5vh",
                visible: _vm.chi_tiet_so_luong_hoc_ba_hoc_sinh_hieu_truong,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.chi_tiet_so_luong_hoc_ba_hoc_sinh_hieu_truong = $event
                },
              },
            },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    border: "",
                    "max-height": _vm.maxHeight,
                    data: _vm.danh_sach_trang_thai_ky_hoc_ba_theo_lop,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "STT",
                      width: "70",
                      align: "center",
                      type: "index",
                      "class-name": "text-center",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "header-align": "center",
                      align: "center",
                      property: "tenLop",
                      label: "Tên lớp",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      property: "tongHocBaDaKy",
                      align: "center",
                      "header-align": "center",
                      label: "Đã ký",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      property: "phanTramDaKy",
                      align: "center",
                      "header-align": "center",
                      label: "Tỷ lệ (%)",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      property: "tongHocBaChuaKy",
                      align: "center",
                      "header-align": "center",
                      label: "Chưa ký",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      property: "phanTramChuaKy",
                      align: "center",
                      "header-align": "center",
                      label: "Tỷ lệ (%)",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "Tổng số học bạ",
                      width: "100",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  scope.row.tongHocBaDaKy +
                                    scope.row.tongHocBaChuaKy
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                "close-on-press-escape": false,
                "close-on-click-modal": false,
                title: "TRẠNG THÁI HỌC BẠ THEO GVCN",
                center: "",
                width: "70%",
                top: "5vh",
                visible: _vm.du_lieu_bieu_do_trang_thai_hoc_ba_theo_gvcn,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.du_lieu_bieu_do_trang_thai_hoc_ba_theo_gvcn = $event
                },
              },
            },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    border: "",
                    "max-height": _vm.maxHeight,
                    data: _vm.danh_sach_trang_thai_ky_hoc_ba_theo_gvcn,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "STT",
                      width: "70",
                      align: "center",
                      type: "index",
                      "class-name": "text-center",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      "header-align": "center",
                      align: "center",
                      property: "tenLop",
                      label: "Lớp",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      property: "tongHocBaChuaKy",
                      align: "center",
                      "header-align": "center",
                      label: "Học bạ chưa ký",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      property: "phanTramChuaKy",
                      align: "center",
                      "header-align": "center",
                      label: "Chưa ký (%)",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      property: "tongHocBaDaKy",
                      align: "center",
                      "header-align": "center",
                      label: "Học bạ đã ký",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      property: "phanTramDaKy",
                      align: "center",
                      "header-align": "center",
                      label: "Đã ký (%)",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }