<template>
    <div>
        <div class="thumbnail">
            <div class="preview"></div>
            <img alt="" :src="dataImage" @click="imageClick">
        </div>
        <div class="result">
            <span>HEX: #22142b</span>
            <span>RGB: rgb(34,20,43)</span>
        </div>
        <canvas id="cs"></canvas>
    </div>
</template>

<script>
export default {
    name: "ImageChooseColor",
    props: [
        "dataImage"
    ],
    data(){
        return {
            img : '',
            canvas : '',
            result : '',
            preview : '',
            x : '',
            y : '',
        }
    },
    methods : {

        _(el){
            return document.querySelector(el);
        },

        componentToHex(c) {
            let hex = c.toString(16);
            return hex.length == 1 ? "0" + hex : hex;
        },
        rgbToHex(r, g, b) {
            return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
        },

        findPos(obj) {
            let curleft = 0, curtop = 0;
            if (obj.offsetParent) {
                do {
                    curleft += obj.offsetLeft;
                    curtop += obj.offsetTop;
                } while (obj = obj.offsetParent);
                return { x: curleft, y: curtop };
            }
            return undefined;
        },
        imageClick(e){
            console.log(e);
            if(e.offsetX) {
                this.x = e.offsetX;
                this.y = e.offsetY;
            }
            else if(e.layerX) {
                this.x = e.layerX;
                this.y = e.layerY;
            }
            let canvas = document.getElementById("cs");

            if (canvas) {
                let ctx = canvas.getContext("2d");
                let image = new Image();
                image.onload = () => {
                    canvas.width = this.img.w;
                    canvas.height = this.img.h;
                    ctx.drawImage(image, 0, 0, this.img.w, this.img.h);
                };


            }

            let p = this.canvas.getContext('2d').getImageData(this.x, this.y, 1, 1).data;

            console.log(p)
            console.log(this.rgbToHex(p[0],p[1],p[2]))
        }
    },
    mounted() {
        this.img = _('.thumbnail img');
        this.canvas = document.getElementById("cs");
        this.result = _('.result');
        this.preview = _('.preview');
        this.x = '';
        this.y = '';

    },
    watch : {

    }
}
</script>

<style scoped>
#cs{
    display:none;
}
</style>