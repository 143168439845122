var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "el-loading-mask is-fullscreen",
          staticStyle: { "z-index": "2000" },
        },
        [
          _c("div", { staticClass: "el-loading-spinner" }, [
            _c(
              "svg",
              { staticClass: "circular", attrs: { viewBox: "25 25 50 50" } },
              [
                _c("circle", {
                  staticClass: "path",
                  attrs: { cx: "50", cy: "50", r: "20", fill: "none" },
                }),
              ]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "dialog__alert",
          attrs: {
            title: _vm.title,
            "destroy-on-close": true,
            "close-on-click-modal": false,
            "before-close": _vm.closeDialog,
            center: "",
            visible: _vm.showDialog,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c("div", { staticClass: "text-center" }, [
            _vm._v(_vm._s(_vm.message)),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }