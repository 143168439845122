import {
    teacherService
} from "../_services";
import {
    router
} from "../_router";

const state = {
    listHocBa: null,
    listRequestRut: [],
    listRequestNop: [],
    listLop: [],
    requestStatus: null,
    updateSuccess: null,
    duyetChuKyStatusLoad: 1,
    duyetChuKyStatus: '',
    duyetChuKyMess: '',
    updateMessage: '',
    trangThaiChotChuKy: null,
    getOtpMes: null,
    getOtpPhone: null,
    kyHocBaStatus: '',
    kyUsbStatus: null,
    kyHocBaMes: '',
    kyHocBaMesError: '',
    phanLop: null,
    uploadStatus: null,
    kySoStatus: null,
    urlFile: '',
    reportUrl: '',
    reportStatus: '',
    hash: '',
    pdfSignature: '',
    listGiaoVien: null,
    fileSignature: '',
    infoGiaoVien: null,
    codeOtp: '',
    fullScreenLoading: null,
    trangThaiUpLoadChuKy: null,
    serialNumber: ''
}

const actions = {
    getInfoGiaoVien({
        commit
    }) {
        commit('setFullScreenLoading', 2);
        teacherService.getInfoGv().then(
            response => {
                commit('setFullScreenLoading', 1);
                if (response.data && response.data.code == 200) {
                    commit('setDataGiaoVien', response.data.data);
                } else {
                    commit('setDataGiaoVien', null);
                }
            }
        ).catch(function (e) {
            commit('setDataGiaoVien', null);
            commit('setFullScreenLoading', 0);
        });
    },

    uploadFileSignature({
        commit
    }, data) {
        commit('setFullScreenLoading', 2);
        commit('getFileSignature', null);
        console.log("data.giaovien")
        console.log(data.giaovien)
        let dataImage = data.base64;
        let giaovienId = data.giaovien.giaovien.id;
        let params = {
            id: giaovienId,
            data: dataImage,
        }
        teacherService.updateFileSignature(params).then(
            response => {
                commit('setTrangThaiUpLoadChuKy', response.data);
                if (response.data && response.data.code == 200) {
                    commit('setFullScreenLoading', 1);
                    commit('getFileSignature', {
                        status: 200,
                        msg: 'Thêm chữ ký thành công'
                    })
                } else {
                    commit('setFullScreenLoading', 0);
                    commit('getFileSignature', {
                        status: 200,
                        msg: response.data.msg
                    })
                }
            }
        ).catch(function (e) {
            commit('getFileSignature', {
                status: 200,
                msg: 'Không thể thêm chữ ký'
            })
        })
        // teacherService.uploadFile({data : dataImage}).then(
        //     response => {
        //         console.log(response)
        //         if (response.data && response.data.code == 200){
        //             let params = {
        //                 id : giaovienId,
        //                 pathChuKy : response.data.data
        //             }
        //             teacherService.updateFileSignature(params).then(
        //                 response => {
        //                     console.log(response)
        //                     if (response.data && response.data.code == 200){
        //                         commit('getFileSignature',{status : 200, msg : 'Thêm chữ ký thành công'})
        //                     }else {
        //                         commit('getFileSignature',{status : 200, msg : response.data.msg})
        //                     }
        //                 }
        //             ).catch(function (e){
        //                 commit('getFileSignature',{status : 200, msg : 'Không thể thêm chữ ký'})
        //             })
        //         }else {
        //             commit('getFileSignature',{status : 200, msg : 'Không thể thêm chữ ký'})
        //         }
        //     }
        // ).catch(function (e){
        //     commit('getFileSignature','');
        // })
    },

    getListGiaoVien({
        commit
    }, data) {
        teacherService.getListGiaoVien(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('getListGiaoVien', response.data.data)
                } else {
                    commit('getListGiaoVien', null)
                }
            }
        ).catch(function (e) {
            commit('getListGiaoVien', null)
        })
    },
    duyetChuKyCacGiaoVienDaChon({
        commit
    }, data) {
        commit('setFullScreenLoading', 2);
        teacherService.duyetChuKyCacGiaoVienDaChon(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('setFullScreenLoading', 1);
                    console.log(data)
                    let msg = ""
                    if (data.trangThai == 1) {
                        msg = "Duyệt chữ ký thành công"
                    } else {
                        msg = "Từ chối duyệt chữ ký thành công"
                    }
                    commit('setTrangThaiChotChuKy', response.data)
                    commit('duyetChuKySuccess', msg)
                } else {
                    commit('duyetChuKyFalse', response.data.msg)
                }
            }
        ).catch(function (e) {
            commit('setFullScreenLoading', 0);
            // commit('getListGiaoVien',null)
        })
    },

    getHash({
        commit
    }, data) {
        console.log('getHash')
        teacherService.getHash(data).then(
            response => {
                console.log(response);
                if (response.data && response.data.code == 200) {
                    commit('getHash', {
                        status: 200,
                        data: response.data.data.hashBase64,
                    });
                    commit('getSerialNumber', {
                        status: 200,
                        data: response.data.data.serialNumber,
                    });
                    commit('getPdfSignature', response.data.data.pdfSignature)
                } else {
                    commit('getPdfSignature', '')
                    commit('getHash', {
                        status: response.data.code,
                        data: response.data.msg,
                    });
                }
            }
        ).catch(function (e) {
            commit('getHash', '');
            commit('getPdfSignature', '')
        })
    },

    getListHocBa({
        commit
    }, data) {

        teacherService.getListHocBa(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('getListHocBa', response.data.data)
                } else {
                    commit('getListHocBa', null)
                }
            }
        ).catch(function (e) {

        })
    },
    getListRequestNop({
        commit
    }, data) {
        teacherService.requestNop(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('getListRequestNop', response.data.data)
                } else {
                    commit('getListRequestNop', [])
                }
            }
        ).catch(function (e) {
            console.log(e)
        })
    },
    getListRequestRut({
        commit
    }, data) {
        teacherService.requestRut(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('getListRequestRut', response.data.data)
                } else {
                    commit('getListRequestRut', [])
                }
            }
        ).catch(function (e) {
            console.log(e)
        })
    },
    acceptRequestRut({
        commit
    }, data) {
        teacherService.pheDuyetRut(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('requestStatus', 'Yêu cầu đã được phê duyệt')
                } else {
                    commit('requestStatus', response.data.msg)
                }
            }
        ).catch(function (e) {
            commit('requestStatus', "Lỗi hệ thống vui lòng thử lại sau")
        })
    },
    rejectRequestRut({
        commit
    }, data) {
        teacherService.pheDuyetRut(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('requestStatus', 'Yêu cầu đã bị từ chối')
                } else {
                    commit('requestStatus', response.data.msg)
                }
            }
        ).catch(function (e) {
            commit('requestStatus', "Lỗi hệ thống vui lòng thử lại sau")
        })
    },
    acceptRequestNop({
        commit
    }, data) {
        teacherService.pheDuyetNop(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('requestStatus', 'Yêu cầu đã được phê duyệt')
                } else {
                    commit('requestStatus', response.data.msg)
                }
            }
        ).catch(function (e) {
            commit('requestStatus', "Lỗi hệ thống vui lòng thử lại sau")
        })
    },
    rejectRequestNop({
        commit
    }, data) {
        teacherService.pheDuyetNop(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('requestStatus', 'Yêu cầu đã bị từ chối')
                } else {
                    commit('requestStatus', response.data.msg)
                }
            }
        ).catch(function (e) {
            commit('requestStatus', "Lỗi hệ thống vui lòng thử lại sau")
        })
    },
    getListLop({
        commit
    }, data) {
        teacherService.getListLop(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('getListLop', response.data.data)
                } else {
                    commit('getListLop', [])
                }
            }
        ).catch(function (e) {
            console.log(e);
        })
    },
    clear({
        commit
    }) {
        commit('clear')
    },
    updateHocBa({
        commit,
        dispatch
    }, data) {
        teacherService.updateHocBa(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('updateSuccess')
                } else {
                    commit('updateFailure', response.data.msg)
                }
            }
        ).catch(function (e) {
            commit('updateFailure', 'Lỗi hệ thống vui lòng thử lại sau!')
        });
    },
    getOtpKygetOtpKy({
        commit
    }) {
        commit('updateOtpPhone', '');

        teacherService.getOptCode().then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('updateOtpMes', 'success')
                    commit('updateOtpPhone', response.data.data)
                } else {
                    commit('updateOtpMes', response.data.msg);
                }
            }
        ).catch(function (e) {
            commit('updateOtpMes', 'Vui lòng thử lại sau');
        })
    },
    kyHocBa({
        commit
    }, data) {
        commit('clearDataKy');
        commit('kyHocBaMesError', null);
        teacherService.kyHocBa(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('kyHocBaSuccess');
                    commit('kyHocBaMesError', null);
                    localStorage.setItem("messInfoOtpCode", response.data.msg);
                } else {
                    commit('kyHocBaError', response.data.msg);
                    commit('kyHocBaMesError', response.data);
                }
            }
        ).catch(function (e) {
            commit('kyHocBaError', 'Có lỗi. Vui lòng thử lại sau');
        })
    },
    kySoHocBa({
        commit
    }, data) {
        commit('clearDataKy');
        teacherService.kySoHocBa(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('kySoHocBa', {
                        status: 'success',
                        message: 'Ký thành công'
                    })
                } else {
                    commit('kySoHocBa', {
                        status: 'error',
                        message: 'Ký thất bại.' + response.data.msg
                    })

                }
            }
        ).catch(function (e) {
            commit('kySoHocBa', {
                status: 'error',
                message: 'Lỗi hệ thống'
            })
        })
    },
    kyUsbHocBa({
        commit
    }, data) {
        commit('clearDataKy');
        teacherService.kyUsbHocBa(data).then(
            response => {
                console.log(response);
                if (response.data && response.data.code == 200) {
                    commit('kyUsbHocBa', {
                        status: 'success',
                        message: 'Ký thành công'
                    })
                } else {
                    commit('kyUsbHocBa', {
                        status: 'error',
                        message: 'Ký thất bại.' + response.data.msg
                    })

                }
            }
        ).catch(function (e) {
            commit('kyUsbHocBa', {
                status: 'error',
                message: 'Lỗi hệ thống'
            })
        })
    },
    clearDataKy({
        commit
    }) {
        commit('clearDataKy')
    },
    actionPhanLop({
        commit
    }, data) {
        commit('phanLop', null);
        teacherService.phanLop(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('phanLop', {
                        status: 'success',
                        message: 'Phân lớp thành công'
                    })
                } else {
                    commit('phanLop', {
                        status: 'error',
                        message: 'Phân lớp thất bại.' + response.data.msg
                    })
                }
            }
        ).catch(function (e) {
            console.log(e);
            commit('phanLop', {
                status: 'error',
                message: 'Lỗi hệ thống vui lòng thử lại sau.'
            })
        })
    },
    uploadHocBa({
        commit
    }, data) {
        commit('uploadHocba', null)
        teacherService.uploadHocBa(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('uploadHocba', {
                        status: 'success',
                        message: 'Upload thành công'
                    })
                } else {
                    commit('uploadHocba', {
                        status: 'error',
                        message: 'Upload thất bại.' + response.data.msg
                    })
                }
            }
        ).catch(function (e) {
            commit('uploadHocba', {
                status: 'error',
                message: 'Lỗi hệ thống vui lòng thử lại sau.'
            })
        })
    },

    uploadFileBiaHocBa({
        commit
    }, data) {
        commit('uploadHocba', null)
        teacherService.uploadBiaHocBa(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('uploadHocba', {
                        status: 'success',
                        message: 'Upload thành công'
                    })
                } else {
                    commit('uploadHocba', {
                        status: 'error',
                        message: 'Upload thất bại.' + response.data.msg
                    })
                }
            }
        ).catch(function (e) {
            commit('uploadHocba', {
                status: 'error',
                message: 'Lỗi hệ thống vui lòng thử lại sau.'
            })
        })
    },

    uploadFile({
        commit
    }, data) {
        commit('uploadFile', null)
        teacherService.uploadHocBa(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('uploadFile', response.data.data)
                } else {
                    commit('uploadFile', null)
                }
            }
        ).catch(function (e) {
            commit('uploadFile', null)
        })
    },
    removeUploadFile({
        commit
    }) {
        commit('uploadFile', null)
    },
    getReport({
        commit
    }, data) {
        commit('getReport', {
            status: 0,
            data: ''
        });
        teacherService.getReport(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('getReport', {
                        status: 2,
                        data: response.data.data
                    })
                } else {
                    commit('getReport', {
                        status: 1,
                        data: response.data.msg
                    })
                }
            }
        ).catch(function (e) {
            commit('getReport', {
                status: 1,
                data: 'Lỗi hệ thống'
            })
        })
    }

}

const mutations = {
    setDataGiaoVien(state, data) {
        state.infoGiaoVien = data;
    },

    getHash(state, data) {
        state.hash = data;
    },

    getSerialNumber(state, data) {
        state.serialNumber = data;
    },

    getPdfSignature(state, data) {
        state.pdfSignature = data;
    },

    uploadFile(state, data) {
        state.urlFile = data;
    },
    uploadHocba(state, data) {
        state.uploadStatus = data;
    },
    phanLop(state, data) {
        state.phanLop = data;
    },
    clearDataKy(state) {
        state.kyHocBaStatus = '';
        state.kyHocBaMes = '';
        state.kySoStatus = null;
        state.kyUsbStatus = null;
        state.hash = '';
        state.pdfSignature = '';
    },
    kyHocBaError(state, data) {
        state.kyHocBaStatus = 'failure'
        state.kyHocBaMes = data;
    },
    kyHocBaMesError(state, data) {
        state.kyHocBaMesError = data;
    },
    setFullScreenLoading(state, data) {
        state.fullScreenLoading = data
    },
    setTrangThaiUpLoadChuKy(state,data){
        state.trangThaiUpLoadChuKy = data
    },

    kyHocBaSuccess(state) {
        state.kyHocBaStatus = 'success'
        state.kyHocBaMes = 'Ký học bạ thành công';
    },
    getListRequestRut(state, data) {
        state.listRequestRut = data;
    },
    getListRequestNop(state, data) {
        state.listRequestNop = data;
    },
    getListHocBa(state, data) {
        state.listHocBa = data;
    },

    requestStatus(state, data) {
        state.requestStatus = data;
    },
    getListLop(state, data) {
        state.listLop = data;
    },
    clear(state) {
        state.requestStatus = null;
        state.listRequestRut = [];
        state.listRequestNop = [];
        state.updateSucess = null;
        state.updateMessage = '';
        state.kySoStatus = null;
        state.listHocba = null;

    },
    updateSuccess(state) {
        state.updateSuccess = 'success';
        state.updateMessage = 'Sửa điểm thành công'
    },
    setTrangThaiChotChuKy(state,data){
        
        state.trangThaiChotChuKy = data;
    },
    duyetChuKySuccess(state, data) {
        state.duyetChuKyStatusLoad += 1;
        state.duyetChuKyStatus = 'success';
        state.duyetChuKyMess = data;

    },
    duyetChuKyFalse(state, data) {
        state.duyetChuKyStatusLoad += 1;
        state.duyetChuKyStatus = 'error';
        state.duyetChuKyMess = data;
    },
    updateFailure(state, data) {
        state.updateSuccess = 'failure';
        state.updateMessage = data
    },
    updateOtpMes(state, data) {
        state.getOtpMes = data;
    },

    updateOtpPhone(state, data) {
        state.getOtpPhone = data;
    },
    kySoHocBa(state, data) {
        state.kySoStatus = data;
    },
    kyUsbHocBa(state, data) {
        state.kyUsbStatus = data;
    },
    getReport(state, data) {
        state.reportUrl = data.data;
        state.reportStatus = data.status;
    },
    getListGiaoVien(state, data) {
        state.listGiaoVien = data;
    },

    getFileSignature(state, data) {
        state.fileSignature = data;
    },

    setOtpCode(state, data) {
        state.codeOtp = data;
    }
}

export const teacher = {
    namespaced: true,
    state,
    actions,
    mutations
};