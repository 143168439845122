<template>
    <div>
        <h1>Đơn giản</h1>
        {{ danhSachLop}}
    </div>
</template>

<script>
import {
    mapActions,
    mapState
} from 'vuex';

export default {
    name: "test",
    computed: {
        ...mapState('account', [
            'user'
        ]),
        ...mapState('test', [
            'danhSachLop'
        ]),
    },
    methods: {
        ...mapActions('test', [
            "getdanhSachLop"
        ]),
    },
    mounted() {
        console.log("fdfdfđ");
        this.getdanhSachLop({
            limit: 100,
            start: 0
        })
    }
}
</script>

<style scoped>

</style>
