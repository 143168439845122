<template>
    <div class="page page-file file" v-loading.fullscreen.lock="fullscreenLoading">
        <div>
            <div class="file--title">
                <h5 class="title">Danh sách học bạ</h5>
                <div class="file-actions">
                    <el-button @click.prevent="chuyenTruongClick" class="mb-1" type="info" icon="el-icon-office-building" plain>Chuyển trường</el-button>
                    <el-button @click.prevent="rutHocBaClick" class="mb-1" type="danger" icon="el-icon-s-cooperation" plain>Rút học bạ</el-button>

                </div>
            </div>
            <div class="page__content">
                <div class="listNew mb-5">
                    <el-table
                        border
                        stripe
                        fit
                        :lazy="true"
                        :data="listHocBa.list"
                        width="100%"
                        ref="listFile">
                        <el-table-column label="STT" property="title" style="width: 50px" width="50" type="index" class-name="text-center">
                        </el-table-column>
                        <el-table-column label="Họ tên" property="hoten">
                        </el-table-column>
                        <el-table-column label="Lớp" property="tenLop"></el-table-column>
                        <el-table-column label="GVCN" property="tenGVCN"></el-table-column>
                        <el-table-column label="Trường" property="tenTruong" width="300"></el-table-column>
                        <el-table-column label="Hành động" class-name="text-center">
                            <template slot-scope="scope">
                                <div class="btn__table_action">
                                    <el-button @click="viewFile(scope.row.id)" class="mb-1" type="info" icon="ion ion-md-eye" plain> Xem</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <div>
            <div class="file--title">
                <h5 class="title">Danh sách yêu cầu</h5>
            </div>
            <div class="page__content">
                <el-table
                    border
                    stripe
                    fit
                    :lazy="true"
                    :data="listRequest.list"
                    width="100%"
                    ref="listRequest">
                    <el-table-column label="STT" property="title" style="width: 50px" width="50" type="index" class-name="text-center"  :index="indexMethod">
                    </el-table-column>Phân lớp

                    <el-table-column label="Loại yêu cầu">
                        <template slot-scope="scope">
                            <div v-if="scope.row.tenTruongYC">
                                Chuyển trường
                            </div>
                            <div v-else>
                                Rút học bạ
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Trường yêu cầu" property="tenTruongYC" width="400"></el-table-column>
                    <el-table-column label="Trạng thái trường đang học" property="moTaTrangThaiTruongHS"></el-table-column>
                    <el-table-column label="Trạng thái trường yêu cầu" property="moTaTrangThaiTruongYC" >
                        <template slot-scope="scope">
                            <div v-if="scope.row.tenTruongYC">
                                {{scope.row.moTaTrangThaiTruongYC}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Ngày yêu cầu" >
                        <template slot-scope="scope">
                            {{ new Date(scope.row.createdDate) | moment("DD/MM/YYYY") }}
                        </template>
                    </el-table-column>
                </el-table>
                <div class="text-right mt-2">

                    <pagination v-if="listRequest.total" :page-count="getTotalPage(listRequest.total)" :click-handler="getListRequests" :prev-text="'Trước'" :next-text="'Sau'" :container-class="'el-pager'" :pageClass="'number'">

                    </pagination>
                </div>
            </div>

        </div>
        <el-dialog
              :close-on-press-escape="false"
              :close-on-click-modal="false" class="popupChuyenTruong" title="Chuyển trường" :visible.sync="showChuyenTruong" center>
            <div>
                <el-form v-model="formChuyenTruong" label-position="right" label-width="180px">
                    <el-form-item label="Tỉnh/Thành phố">
                        <el-select style="width: 100%" @change="provinceChange" v-model="formChuyenTruong.provinceId = user.tinh.maTinh" placeholder="Chọn Tỉnh/TP">
                            <el-option v-for="item in provinces" :label="item.tenTinh" :key="item.maTinh" :value="item.maTinh"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Cấp học">
                        <el-select disabled style="width: 100%" @change="capHocChange" v-model="formChuyenTruong.capHoc = user.capHoc" placeholder="Chọn cấp học">
                            <el-option v-for="item in optionEducation" :label="item.label" :key="item.value" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Trường chuyển đến">
                        <el-select style="width: 100%" filterable v-model="formChuyenTruong.truongHocId" placeholder="Chọn trường chuyển đến">
                            <el-option v-for="item in listTruong" :label="item.tenTruongHoc" :key="item.maTruongHoc"  :value="item.maTruongHoc"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showChuyenTruong = false">Thoát</el-button>
                <el-button type="primary" @click="confirmChuyenTruong">Xác nhận</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>

import {mapActions, mapState} from 'vuex';
import Pagination from "../../components/Pagination";
import dialog from "../../dialog";


export default {
    name: "SchoolProfile",
    metaInfo : {
        title : 'Danh sách học bạ',
        meta : [
            { vmid: 'description', name: 'description', content: '' }
        ]
    },
    components: {
        Pagination
    },

    computed: {
        ...mapState('account',[
            "user"
        ]),
        ...mapState('hocsinh',[
            'listHocBa',
            'requestHocSinhSuccess',
            'requestMessage',
            'listRequest',
        ]),
        ...mapState('until',[
            'provinces',
            'listTruong'
        ]),
        ...mapState("hocba",[
            "fileDetail",
            "fileStatus"
        ])
    },

    methods:{

        ...mapActions('hocsinh', [
            "getListHocBa",
            "requestHocSinh",
            "clearRequest",
            "getListRequest"
        ]),
        ...mapActions('until',[
            "getProvince",
            "getListTruong"
        ]),
        ...mapActions("hocba",[
            "getFile"
        ]),
        getTotalPage(number) {
            return Math.ceil(number / (this.paginate.limit));
        },
        indexMethod(index) {
            return (this.paginate.start + index) + 1;
        },
        viewFile(id){
            this.fullscreenLoading = true;
            this.getFile({
                maHocBa : id
            })
        },
        getListRequests(select){
            let start = (select - 1) * this.paginate.limit ? (select - 1) * this.paginate.limit : 0;
            this.paginate.start = start;
            this.getListRequest({
                    start : this.paginate.start,
                    limit : this.paginate.limit
                }
            );
        },
        chuyenTruongClick(){
            this.formChuyenTruong.truongHocId = '';
            this.formChuyenTruong.capHoc = '';
            this.formChuyenTruong.provinceId = '';
            this.showChuyenTruong = true;
        },
        rutHocBaClick(){
            dialog.confirmDialog('Bạn muốn rút học bạ?','Xác nhận',()=>{
                this.requestHocSinh();
            })
        },
        confirmChuyenTruong(){
            this.requestHocSinh(this.formChuyenTruong.truongHocId);
        },
        provinceChange(){
            this.formChuyenTruong.truongHocId = '';
            this.getListTruong({
                capHoc : this.formChuyenTruong.capHoc,
                provinceId : this.formChuyenTruong.provinceId
            })
        },
        capHocChange(){
            this.formChuyenTruong.truongHocId = '';
            this.getListTruong({
                capHoc : this.formChuyenTruong.capHoc,
                provinceId : this.formChuyenTruong.provinceId
            })
        },
        onHide(){
            this.clearRequest();
            this.getListRequest({
                start : this.paginate.start,
                limit : this.paginate.limit
            });
            this.showChuyenTruong = false;
        },
        openFancyBox(url){
            $.fancybox.open({
                src  : url,
                type : 'iframe',
            });
        }
    },

    data(){
        return {
            paginate: {
                start: 0,
                limit: 100,
            },
            showChuyenTruong : false,
            fullscreenLoading: false,
            optionEducation: [
                {
                    value: 1,
                    label: 'Cấp 1'
                },
                {
                    value: 2,
                    label: 'Cấp 2'
                },
                {
                    value: 3,
                    label: 'Cấp 3'
                },
            ],
            optionGrade: [
                {
                    value: '',
                    label: 'Chọn khối'
                },
                {
                    value: 1,
                    label: 'Khối A'
                },
                {
                    value: 2,
                    label: 'Khối B'
                },
                {
                    value: 3,
                    label: 'Khối C'
                },
            ],
            optionClass: [
                {
                    value: '',
                    label: 'Chọn lớp'
                },
                {
                    value: 1,
                    label: 'Lớp 1'
                },
                {
                    value: 2,
                    label: 'Lớp 2'
                },
                {
                    value: 3,
                    label: 'Lớp 3'
                },
            ],
            optionStudent: [
                {
                    value: '',
                    label: 'Học sinh'
                },
                {
                    value: 1,
                    label: 'HS 1'
                },
                {
                    value: 2,
                    label: 'HS 2'
                },
                {
                    value: 3,
                    label: 'HS 3'
                },
            ],
            optionStatus: [
                {
                    value: '',
                    label: 'Trạng thái'
                },
                {
                    value: 1,
                    label: 'Trạng thái 1'
                },
                {
                    value: 2,
                    label: 'Trạng thái 2'
                },
                {
                    value: 3,
                    label: 'Trạng thái 3'
                },
            ],
            searchForm :{
                year: '',
                education: '',
                grade: '',
                class: '',
                student: '',
                status: '',
            },
            rulesSearch:{
                year : [
                    // {required : true, message : 'Bạn chưa chọn năm học',trigger:['change','blur']}
                    {required : true, message : 'Bạn chưa chọn năm học',trigger: 'change'}
                ],
                education : [
                    {required : true, message : 'Bạn chưa chọn cấp học',trigger: 'change'}
                ],
                grade : [
                    {required : true, message : 'Bạn chưa chọn khối học',trigger: 'change'}
                ],
                class : [
                    {required : true, message : 'Bạn chưa chọn lớp học',trigger: 'change'}
                ],
                student : [
                    {required : true, message : 'Bạn chưa chọn học sinh',trigger: 'change'}
                ],
                status : [
                    {required : true, message : 'Bạn chưa chọn trạng thái',trigger: 'change'}
                ]
            },
            formChuyenTruong : {
                provinceId : '',
                capHoc : '',
                truongHocId : ''
            },
        }
    },

    beforeMount() {
        this.getListHocBa();
        this.getProvince();
        this.getListTruong({
            capHoc : this.formChuyenTruong.capHoc,
            provinceId : this.formChuyenTruong.provinceId
        }),
            this.getListRequest({
                start : this.paginate.start,
                limit : this.paginate.limit
            });
    },
    mounted() {
        this.getListTruong({
            capHoc : this.formChuyenTruong.capHoc,
            provinceId : this.formChuyenTruong.provinceId
        })
        // this.fullscreenLoading = true;
    },
    watch : {
        requestHocSinhSuccess(val){
            if (val){
                dialog.showDialog('Thông báo',this.requestMessage,this.onHide());
            }
        },
        fileDetail(val){
            if (this.fileStatus == 2){
                this.fullscreenLoading = false;
                let linkPfd = this.fileDetail+ '#toolbar=0';
                this.openFancyBox(linkPfd)
            }
        },
        fileStatus(val){
            // if (val == 2){
            //     this.openFancyBox(this.fileDetail)
            // }
        }
    }
}
</script>

<style scoped>
.btn__table_action button{
    padding: 6px 8px;
}
</style>
