var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "page page-file file detailHocBa",
      attrs: { id: "detailHocBa" },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "page__title d-flex align-items-center justify-content-between",
          staticStyle: { "padding-top": "20px" },
        },
        [
          _c("h1", { staticClass: "title" }, [_vm._v("Thời gian năm học")]),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "20px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.addNamHocClick($event)
                    },
                  },
                },
                [_vm._v("\n      Thêm mới\n    ")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            data: _vm.lstNamHoc.list,
            border: "",
            width: "100%",
            "element-loading-text": "Loading...",
            "element-loading-spinner": "el-icon-loading",
            "element-loading-background": "rgba(0, 0, 0, 0.8)",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              "header-align": "center",
              align: "center",
              label: "STT",
              type: "index",
              index: _vm.indexMethod,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              "header-align": "center",
              align: "center",
              label: "Năm học",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.namHoc) +
                        " - " +
                        _vm._s(scope.row.namHoc + 1) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              "header-align": "center",
              align: "center",
              label: "Ngày ký lý lịch học sinh",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("moment")(
                            new Date(scope.row.ngayBatDau),
                            "DD/MM/YYYY"
                          )
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "header-align": "center",
              label: "Ngày ký xác nhận hiệu trưởng",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("moment")(
                            new Date(scope.row.ngayKetThuc),
                            "DD/MM/YYYY"
                          )
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "header-align": "center",
              label: "Ngày ký học bạ thi lại",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("moment")(
                            new Date(scope.row.ngayKetThucThiLai),
                            "DD/MM/YYYY"
                          )
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              "header-align": "center",
              align: "center",
              property: "tenDiaDanh",
              label: "Tên địa danh ký",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              "header-align": "center",
              align: "center",
              property: "capQuanLySo",
              label: "Cấp quản lý ghi trang bìa",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "header-align": "center",
              label: "Hành động",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {},
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "Chỉnh sửa năm học",
                              placement: "top",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                size: "mini",
                                icon: "el-icon-edit-outline",
                                type: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.editNamHocClick(scope.row)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        !scope.row.status
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  slot: "reference",
                                  effect: "dark",
                                  content: "Xóa năm học",
                                  placement: "top",
                                },
                                slot: "reference",
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    size: "mini",
                                    type: "danger",
                                    icon: "el-icon-delete",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.confirmDel(
                                        scope.row.id,
                                        scope.row.namHoc
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      false
        ? _c(
            "div",
            { staticClass: "text-right mt-2" },
            [
              _vm.lstNamHoc && _vm.lstNamHoc.total
                ? _c("pagination", {
                    attrs: {
                      value: _vm.paginate.current,
                      "page-count": _vm.getTotalPage(_vm.lstNamHoc.total),
                      "click-handler": _vm.getListNamHoc,
                      "prev-text": "Trước",
                      "next-text": "Sau",
                      "container-class": "el-pager",
                      pageClass: "number",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "uploadSignature",
          attrs: {
            title: "Thêm mới năm học",
            width: "80%",
            center: "",
            visible: _vm.showPopUpAdd,
            "before-close": _vm.closeDialogFormCreate,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            "custom-class": "thongTinCauHinhNamHoc",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showPopUpAdd = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addNamHoc",
              attrs: { model: _vm.createData, inline: true, rules: _vm.rules },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { lg: 6, sm: 12, xs: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "namHoc" } },
                        [
                          _c("label", [_vm._v("Năm học")]),
                          _vm._v(" "),
                          _c("SelectNamHoc", {
                            staticStyle: { display: "block" },
                            model: {
                              value: _vm.createData.namHoc,
                              callback: function ($$v) {
                                _vm.$set(_vm.createData, "namHoc", $$v)
                              },
                              expression: "createData.namHoc",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { lg: 6, sm: 12, xs: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "startDate" } },
                        [
                          _c("label", [
                            _vm._v("Ngày ký lý lịch học sinh "),
                            _c("sup", { staticStyle: { color: "#DA1D1D" } }, [
                              _vm._v("*"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticStyle: { display: "block" },
                            attrs: {
                              "value-format": "timestamp",
                              format: "dd/MM/yyyy",
                              type: "date",
                              placeholder: "dd/mm/yyyy",
                            },
                            model: {
                              value: _vm.createData.startDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.createData, "startDate", $$v)
                              },
                              expression: "createData.startDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { lg: 6, sm: 12, xs: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "endDate" } },
                        [
                          _c("label", [
                            _vm._v("Ngày ký xác nhận hiệu trưởng "),
                            _c("sup", { staticStyle: { color: "#DA1D1D" } }, [
                              _vm._v("*"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticStyle: { display: "block" },
                            attrs: {
                              format: "dd/MM/yyyy",
                              "value-format": "timestamp",
                              type: "date",
                              placeholder: "dd/mm/yyyy",
                            },
                            model: {
                              value: _vm.createData.endDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.createData, "endDate", $$v)
                              },
                              expression: "createData.endDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { lg: 6, sm: 12, xs: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "ngayKetThucThiLai" } },
                        [
                          _c("label", [
                            _vm._v("Ngày ký học bạ thi lại "),
                            _c("sup", { staticStyle: { color: "#DA1D1D" } }, [
                              _vm._v("*"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticStyle: { display: "block" },
                            attrs: {
                              format: "dd/MM/yyyy",
                              "value-format": "timestamp",
                              type: "date",
                              placeholder: "dd/mm/yyyy",
                            },
                            model: {
                              value: _vm.createData.ngayKetThucThiLai,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.createData,
                                  "ngayKetThucThiLai",
                                  $$v
                                )
                              },
                              expression: "createData.ngayKetThucThiLai",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { lg: 6, sm: 12, xs: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "capQuanLySo" } },
                        [
                          _c(
                            "label",
                            [
                              _vm._v("Cấp quản lý ghi trang bìa "),
                              _c("sup", { staticStyle: { color: "#DA1D1D" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-tooltip",
                                { attrs: { placement: "top" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Ghi rõ: BỘ GIÁO DỤC VÀ ĐÀO TẠO hoặc SỞ GIÁO DỤC VÀ ĐÀO TẠO "
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "(Ví\n                  dụ: Sở Giáo dục và Đào tạo Thái Nguyên)\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("small", [
                                    _c(
                                      "i",
                                      { staticStyle: { color: "blue" } },
                                      [_vm._v("Hướng dẫn")]
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "Nhập",
                              type: "text",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleInputChange("capQuanLySo")
                              },
                            },
                            model: {
                              value: _vm.createData.capQuanLySo,
                              callback: function ($$v) {
                                _vm.$set(_vm.createData, "capQuanLySo", $$v)
                              },
                              expression: "createData.capQuanLySo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { lg: 6, sm: 12, xs: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "tenDiaDanh" } },
                        [
                          _c("label", [
                            _vm._v("Tên địa danh ký "),
                            _c("sup", { staticStyle: { color: "#DA1D1D" } }, [
                              _vm._v("*"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "Nhập",
                              type: "text",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleInputChange("tenDiaDanh")
                              },
                            },
                            model: {
                              value: _vm.createData.tenDiaDanh,
                              callback: function ($$v) {
                                _vm.$set(_vm.createData, "tenDiaDanh", $$v)
                              },
                              expression: "createData.tenDiaDanh",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: { click: _vm.closeDialogFormCreate },
                },
                [_vm._v("Hủy")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: !_vm.confirm_createData,
                    size: "mini",
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.submitAddNamHoc("addNamHoc")
                    },
                  },
                },
                [_vm._v("Lưu")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "uploadSignature",
          attrs: {
            title:
              "Chỉnh sửa năm học " +
              _vm.editData.namHoc +
              "-" +
              (_vm.editData.namHoc + 1),
            width: "80%",
            center: "",
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            visible: _vm.showPopUpEdit,
            "custom-class": "thongTinCauHinhNamHoc",
          },
          on: { close: _vm.closeDialogFormUpdate },
        },
        [
          _c(
            "el-form",
            {
              ref: "editNamHoc",
              attrs: { model: _vm.editData, inline: true, rules: _vm.rules },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { lg: 6, sm: 12, xs: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "namHoc" } },
                        [
                          _c("label", [_vm._v("Năm học")]),
                          _vm._v(" "),
                          _c("SelectNamHoc", {
                            staticStyle: { display: "block" },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.editData.namHoc,
                              callback: function ($$v) {
                                _vm.$set(_vm.editData, "namHoc", $$v)
                              },
                              expression: "editData.namHoc",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { lg: 6, sm: 12, xs: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "startDate" } },
                        [
                          _c("label", [
                            _vm._v("Ngày ký lý lịch học sinh "),
                            _c("sup", { staticStyle: { color: "#DA1D1D" } }, [
                              _vm._v("*"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticStyle: { display: "block" },
                            attrs: {
                              "value-format": "timestamp",
                              format: "dd/MM/yyyy",
                              type: "date",
                              placeholder: "dd/mm/yyyy",
                            },
                            model: {
                              value: _vm.editData.startDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.editData, "startDate", $$v)
                              },
                              expression: "editData.startDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { lg: 6, sm: 12, xs: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "endDate" } },
                        [
                          _c("label", [
                            _vm._v("Ngày ký xác nhận hiệu trưởng "),
                            _c("sup", { staticStyle: { color: "#DA1D1D" } }, [
                              _vm._v("*"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticStyle: { display: "block" },
                            attrs: {
                              format: "dd/MM/yyyy",
                              "value-format": "timestamp",
                              type: "date",
                              placeholder: "dd/mm/yyyy",
                            },
                            model: {
                              value: _vm.editData.endDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.editData, "endDate", $$v)
                              },
                              expression: "editData.endDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { lg: 6, sm: 12, xs: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "ngayKetThucThiLai" } },
                        [
                          _c("label", [
                            _vm._v("Ngày ký học bạ thi lại "),
                            _c("sup", { staticStyle: { color: "#DA1D1D" } }, [
                              _vm._v("*"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            staticStyle: { display: "block" },
                            attrs: {
                              format: "dd/MM/yyyy",
                              "value-format": "timestamp",
                              type: "date",
                              placeholder: "dd/mm/yyyy",
                            },
                            model: {
                              value: _vm.editData.ngayKetThucThiLai,
                              callback: function ($$v) {
                                _vm.$set(_vm.editData, "ngayKetThucThiLai", $$v)
                              },
                              expression: "editData.ngayKetThucThiLai",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { lg: 6, sm: 12, xs: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "capQuanLySo" } },
                        [
                          _c(
                            "label",
                            [
                              _vm._v("Cấp quản lý ghi trang bìa "),
                              _c("sup", { staticStyle: { color: "#DA1D1D" } }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-tooltip",
                                { attrs: { placement: "top" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Ghi rõ: BỘ GIÁO DỤC VÀ ĐÀO TẠO hoặc SỞ GIÁO DỤC VÀ ĐÀO TẠO "
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "\n                  (Ví dụ: Sở Giáo dục và Đào tạo Thái Nguyên)\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("small", [
                                    _c(
                                      "i",
                                      { staticStyle: { color: "blue" } },
                                      [_vm._v("Hướng dẫn")]
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "Nhập",
                              type: "text",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleInputChange("capQuanLySo")
                              },
                            },
                            model: {
                              value: _vm.editData.capQuanLySo,
                              callback: function ($$v) {
                                _vm.$set(_vm.editData, "capQuanLySo", $$v)
                              },
                              expression: "editData.capQuanLySo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { lg: 6, sm: 12, xs: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "tenDiaDanh" } },
                        [
                          _c("label", [
                            _vm._v("Tên địa danh ký "),
                            _c("sup", { staticStyle: { color: "#DA1D1D" } }, [
                              _vm._v("*"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "Nhập",
                              type: "text",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleInputChange("tenDiaDanh")
                              },
                            },
                            model: {
                              value: _vm.editData.tenDiaDanh,
                              callback: function ($$v) {
                                _vm.$set(_vm.editData, "tenDiaDanh", $$v)
                              },
                              expression: "editData.tenDiaDanh",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: { click: _vm.closeDialogFormUpdate },
                },
                [_vm._v("Hủy")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: !_vm.confirm_editData,
                    size: "mini",
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.submitEditNamHoc("editNamHoc")
                    },
                  },
                },
                [_vm._v("Lưu")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }