var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "page page-file file detailHocBa",
      attrs: { id: "detailHocBa" },
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "box-upload-file" },
        [
          _c(
            "el-form",
            {
              ref: "searchForm",
              attrs: {
                rules: _vm.rulesSearch,
                model: _vm.searchForm,
                "hide-required-asterisk": true,
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "mt-2 mb-2",
                      attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          attrs: { prop: "year" },
                        },
                        [
                          _c("eselect", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              collapseTags: "",
                              placeholder: "Chọn",
                              filterable: "",
                              data: _vm.list_nam_hoc,
                              fields: ["name", "value"],
                            },
                            on: { change: _vm.onYearChange },
                            model: {
                              value: _vm.searchForm.year,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "year", $$v)
                              },
                              expression: "searchForm.year",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "mt-2 mb-2",
                      attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          attrs: { prop: "education" },
                        },
                        [
                          _c("eselect", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              disabled: _vm.list_cap_hoc.length == 1,
                              placeholder: "Chọn",
                              filterable: "",
                              data: _vm.list_cap_hoc,
                              fields: ["name", "value"],
                            },
                            on: { change: _vm.capHocChange },
                            model: {
                              value: _vm.searchForm.education,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "education", $$v)
                              },
                              expression: "searchForm.education",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "mt-2 mb-2",
                      attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          attrs: { prop: "grade" },
                        },
                        [
                          _c("eselect", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              collapseTags: "",
                              placeholder: "Chọn",
                              filterable: "",
                              data: _vm.optionGrade,
                              fields: ["label", "value"],
                            },
                            on: { change: _vm.khoiHocChange },
                            model: {
                              value: _vm.searchForm.grade,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "grade", $$v)
                              },
                              expression: "searchForm.grade",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "mt-2 mb-2",
                      attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          attrs: { prop: "class" },
                        },
                        [
                          _c("eselect", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              collapseTags: "",
                              placeholder: "Chọn",
                              filterable: "",
                              data: _vm.danh_sach_lop_hoc,
                              fields: ["tenLop", "maLop"],
                            },
                            model: {
                              value: _vm.searchForm.class,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "class", $$v)
                              },
                              expression: "searchForm.class",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "mt-2 mb-2",
                      attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "d-inline-block" },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "Nhập" },
                            model: {
                              value: _vm.searchForm.student,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "student", $$v)
                              },
                              expression: "searchForm.student",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "mt-2 mb-2",
                      attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "d-inline-block" },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "Nhập" },
                            model: {
                              value: _vm.searchForm.maHS,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "maHS", $$v)
                              },
                              expression: "searchForm.maHS",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "text-center mt-2 mb-2",
                      attrs: { xs: 24, sm: 24, md: 6, lg: 24, xl: 3 },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitSearch("searchForm")
                            },
                          },
                        },
                        [_vm._v("Tìm kiếm")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "mb-1 mr-auto",
                      attrs: {
                        disabled:
                          !_vm.listHocBaCheck || _vm.listHocBaCheck.length == 0,
                        size: "small",
                        type: "success",
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.showThongTinKy()
                        },
                      },
                    },
                    [_vm._v(" Ký học bạ\n        ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "file-actions text-right" }, [
                  _vm.kieuKyGiaoVien
                    ? _c(
                        "div",
                        { staticClass: "cursor-pointer" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: "Đăng nhập VNPT SmartCA",
                                placement: "left",
                              },
                            },
                            [
                              _vm.kieuKyGiaoVien == 6 && !_vm.tokenSmartCa
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "primary" },
                                      on: { click: _vm.loginSmartCA },
                                    },
                                    [
                                      _vm._v(
                                        "Đăng\n                nhập VNPT SmartCA " +
                                          _vm._s(_vm.tokenSmartCa) +
                                          "\n              "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: "Thay đổi kiểu ký",
                                placement: "left",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { padding: "5px", margin: "0" },
                                  attrs: {
                                    type: "primary",
                                    disabled: _vm.countTypeSign == 1,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      _vm.showChonKieuKy = true
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      width: "30px",
                                      height: "20px",
                                    },
                                    attrs: {
                                      src: _vm.getPathImgByType(
                                        _vm.kieuKyGiaoVien
                                      ),
                                      alt: "",
                                    },
                                  }),
                                  _vm._v(
                                    "\n                Kiểu ký\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "page__content" }, [
        _c(
          "div",
          { staticClass: "listNew mb-5" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                ref: "listFile",
                attrs: {
                  border: "",
                  fit: "",
                  stripe: "",
                  lazy: true,
                  data: _vm.listHocBa ? _vm.listHocBa.list : [],
                  width: "100%",
                },
                on: { "selection-change": _vm.handleSelectionChange },
              },
              [
                _c("el-table-column", {
                  attrs: { type: "selection", align: "center", width: "40" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  staticStyle: { width: "50px" },
                  attrs: {
                    label: "STT",
                    width: "50",
                    type: "index",
                    "class-name": "text-center",
                    index: _vm.indexMethod,
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    "min-width": "120",
                    label: "Mã học sinh",
                    align: "center",
                    property: "maHS",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "Tên học sinh",
                    "header-align": "center",
                    align: "left",
                    property: "tenHS",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  staticClass: "text-center",
                  staticStyle: { "text-align": "center" },
                  attrs: {
                    label: "Lớp",
                    property: "tenLop",
                    align: "center",
                    width: "70",
                  },
                }),
                _vm._v(" "),
                _c(
                  "el-table-column",
                  { attrs: { label: "Trạng thái", align: "center" } },
                  [
                    _c("el-table-column", {
                      attrs: { width: "90", align: "center", label: "Dấu ảnh" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.dongDauAnh === 1
                                      ? "Đã đóng"
                                      : "Chưa đóng"
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { align: "center", label: "Dấu bìa", width: "90" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.dongDauBia === 1
                                      ? "Đã đóng"
                                      : "Chưa đóng"
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: "Dấu KQHT",
                        width: "90",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.dongDauQTHT === 1
                                      ? "Đã đóng"
                                      : "Chưa đóng"
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-table-column",
                  { attrs: { label: "Hành động", align: "center" } },
                  [
                    _c("el-table-column", {
                      attrs: {
                        width: "90",
                        align: "center",
                        label: "Đóng dấu ảnh",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.chotBia
                                ? _c(
                                    "div",
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          size: "mini",
                                          type: "warning",
                                          icon: "el-icon-s-check",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.kyMotHocSinh(
                                              scope.row,
                                              1
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _c("div", [_vm._v("Chưa chốt bìa")]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: "Đóng dấu bìa",
                        width: "90",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.chotBia
                                ? _c("div", [
                                    _vm._v(_vm._s(scope.row.dongDauBia)),
                                  ])
                                : _c("div", [_vm._v("Chưa chốt bìa")]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        label: "Đóng dấu KQHT",
                        width: "90",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.chotBia
                                ? _c(
                                    "div",
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          size: "mini",
                                          type: "warning",
                                          icon: "el-icon-s-check",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.kyMotHocSinh(
                                              scope.row,
                                              3
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _c("div", [_vm._v("Chưa chốt bìa")]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.listHocBa && _vm.listHocBa.total
              ? _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-between",
                  },
                  [
                    [
                      _c("small", [
                        _c("i", [
                          _vm._v(
                            "Danh sách có " +
                              _vm._s(_vm.listHocBa.total) +
                              " học sinh "
                          ),
                        ]),
                      ]),
                    ],
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "text-right mt-2" },
                      [
                        _vm.listHocBa
                          ? _c("pagination", {
                              attrs: {
                                "page-count": _vm.getTotalPage(
                                  _vm.listHocBa.total
                                ),
                                "initial-page": _vm.paginate.page,
                                "click-handler": _vm.getHocBa,
                                "prev-text": "Trước",
                                "next-text": "Sau",
                                "container-class": "el-pager",
                                pageClass: "number",
                              },
                              model: {
                                value: _vm.paginate.page,
                                callback: function ($$v) {
                                  _vm.$set(_vm.paginate, "page", $$v)
                                },
                                expression: "paginate.page",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  2
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("form-otp", {
        attrs: {
          type: _vm.typeKy,
          duLieu: _vm.duLieuHocBaCanKy,
          "list-hoc-ba": _vm.hocbaDatas,
          "show-dialog-otp": _vm.showDialogOtp,
        },
        on: {
          closeDialogOtp: function ($event) {
            return _vm.closeDialogOtp()
          },
          success: function ($event) {
            return _vm.success()
          },
        },
      }),
      _vm._v(" "),
      _vm.kySoStatus
        ? _c("dialog-alert", {
            attrs: {
              title: "Thông báo",
              message: _vm.kySoStatus.message,
              "show-dialog": _vm.showDialogAlert,
            },
            on: {
              closeDialog: function ($event) {
                return _vm.closeDialogAlert()
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.kyUsbStatus
        ? _c("dialog-alert", {
            attrs: {
              title: "Thông báo",
              message: _vm.kyUsbStatus.message,
              "show-dialog": _vm.showDialogAlertUsb,
            },
            on: {
              closeDialog: function ($event) {
                return _vm.closeDialogAlert()
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            title: "Chi tiết",
            width: "60%",
            center: "",
            "custom-class": "thongTinKyTheoMonHoc",
            visible: _vm.hien_thi_thong_tin_ky,
          },
          on: {
            "update:visible": function ($event) {
              _vm.hien_thi_thong_tin_ky = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              attrs: {
                "max-height": _vm.maxHeight,
                "row-class-name": _vm.tableRowClassName,
                data: _vm.list_trang_thai_ky_theo_mon,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "STT",
                  width: "50",
                  align: "center",
                  type: "index",
                  "class-name": "text-center",
                  index: _vm.indexMethod,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "tenMonHoc",
                  label: "Môn học",
                  width: "110",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "tenGiaoVien", label: "Giáo viên" },
              }),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { label: "GVCN" } },
                [[_c("p", [_vm._v(_vm._s(_vm.tenGiaoVienChuNhiem))])]],
                2
              ),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", label: "Trạng thái", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.trangThaiXacNhan == 1
                          ? _c("div", [_vm._v("Đã ký")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.trangThaiXacNhan == 0
                          ? _c("div", [_vm._v("Chưa ký")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            title: "Chọn kiểu ký",
            width: "40%",
            center: "",
            "custom-class": "thongTinKyTheoMonHoc",
            "append-to-body": "",
            "show-close": false,
            visible: _vm.showChonKieuKy,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showChonKieuKy = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { staticClass: "text-center", attrs: { span: 24 } },
                _vm._l(_vm.listKieuKy, function (type, i) {
                  return _c(
                    "el-radio",
                    {
                      key: i,
                      staticClass: "checkTypeSign",
                      attrs: { label: type.value },
                      on: {
                        change: function ($event) {
                          return _vm.chonKieuKy()
                        },
                      },
                      model: {
                        value: _vm.kieuKyGiaoVien,
                        callback: function ($$v) {
                          _vm.kieuKyGiaoVien = $$v
                        },
                        expression: "kieuKyGiaoVien",
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "90px", height: "70px" },
                        attrs: { src: _vm.getPathImg(type), alt: "Ảnh" },
                      }),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            top: "5vh",
            title: "Xác nhận ký tài liệu",
            width: "40%",
            "append-to-body": "",
            "show-close": false,
            center: "",
            "custom-class": "hienThiThoiGianCho",
            visible: _vm.hien_thi_thoi_gian_doi_ky,
          },
          on: {
            "update:visible": function ($event) {
              _vm.hien_thi_thoi_gian_doi_ky = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c("p", [
                  _vm._v("Yêu cầu ký số đã được gửi về thiết bị di động."),
                ]),
                _vm._v(" "),
                _c("p", { staticStyle: { "white-space": "nowrap" } }, [
                  _vm._v("Mở\n          "),
                  _vm.kieuKyGiaoVien != 2
                    ? _c("span", [_vm._v("ứng dụng")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.kieuKyGiaoVien == 2
                    ? _c("span", [
                        _vm._v("thiết bị di động "),
                        _c("b", [_vm._v("nhập mã PIN")]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.kieuKyGiaoVien == 5
                    ? _c("span", [_vm._v("MySign")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.kieuKyGiaoVien == 6
                    ? _c("span", [_vm._v("VNPT SmartCA")])
                    : _vm._e(),
                  _vm._v("\n          và nhấn "),
                  _c("b", [_vm._v("Xác nhận")]),
                  _vm._v(" để ký tài liệu."),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { xs: 24, sm: 12 } }, [
                _c("div", { staticClass: "bg-purple-light pt-3" }, [
                  _c("p", [
                    _c("b", { staticClass: "pd-r-14" }, [_vm._v("Ứng dụng: ")]),
                    _vm._v(" "),
                    _vm.kieuKyGiaoVien == 2
                      ? _c("span", [_vm._v("Viettel CA")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.kieuKyGiaoVien == 5
                      ? _c("span", [_vm._v("MySign")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.kieuKyGiaoVien == 6
                      ? _c("span", [_vm._v("VNPT SmartCA")])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticStyle: { "white-space": "nowrap" } }, [
                    _c("b", { staticClass: "pd-r-10" }, [
                      _vm._v("Trạng thái: "),
                    ]),
                    _vm._v(" Chờ xác nhận"),
                  ]),
                ]),
                _vm._v(" "),
                _vm.kieuKyGiaoVien != 2
                  ? _c("div", { staticClass: "text-center pt-3" }, [
                      _c("p", [_vm._v("Thời gian còn lại")]),
                      _vm._v(" "),
                      _c("h4", { staticStyle: { color: "#00A65A" } }, [
                        _vm._v(_vm._s(_vm.getMinutes(_vm.timeLeft))),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.kieuKyGiaoVien != 2
                  ? _c("div", [_c("p", [_vm._v("Cài đặt ứng dụng tại")])])
                  : _vm._e(),
                _vm._v(" "),
                _vm.kieuKyGiaoVien != 2
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 24 } },
                          [
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c("img", {
                                attrs: {
                                  src: "/images/ch_android.png",
                                  alt: "",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.getAppAndroid()
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                attrs: {
                                  src: "/images/appstore_ios.png",
                                  alt: "",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.getAppIos()
                                  },
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 12 } }, [
                              _vm.kieuKyGiaoVien == 5
                                ? _c("img", {
                                    staticStyle: { width: "80% !important" },
                                    attrs: {
                                      src: "/images/qr_code_viettel.png",
                                      alt: "Ảnh qr",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.kieuKyGiaoVien == 6
                                ? _c("img", {
                                    staticStyle: { width: "80% !important" },
                                    attrs: {
                                      src: "/images/qr_code_vnpt.png",
                                      alt: "Ảnh qr",
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c("div", { staticClass: "text-center" }, [
                      _c("img", {
                        attrs: { src: "/images/loading1.gif", alt: "" },
                      }),
                    ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                {
                  staticClass: "hidden-xs-only hide-in-mobile",
                  attrs: { sm: 12 },
                },
                [
                  _c("img", {
                    attrs: { src: "/images/mobile_ca.png", alt: "ảnh" },
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.kieuKy == 3
        ? _c("KyHocBaListUsbAll", {
            attrs: {
              duLieu: _vm.duLieuKyHocBaListUsbAll,
              thuchienky: _vm.thuc_hien_ky_list_usb_all,
            },
            on: {
              load: _vm.openLoading,
              close: function ($event) {
                return _vm.kyHocBaUsbXong()
              },
              error: _vm.closeLoading,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.kieuKyGiaoVien == 4
        ? _c("KyListHocBaUsbAllV2Edoc", {
            attrs: {
              typeKy: _vm.typeHanhDong,
              dulieu: _vm.duLieuKyHocBaListUsbAll,
              thuchienky: _vm.thuc_hien_ky_list_usb_all_edoc,
            },
            on: {
              load: _vm.openLoading,
              close: function ($event) {
                return _vm.kyHocBaUsbXong()
              },
              error: _vm.closeLoading,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.kieuKy == 4
        ? _c("KyHocBaUsbAllV2", {
            attrs: {
              dulieu: _vm.duLieuKyHocBaUsbAll,
              typeKy: "hocba",
              thuchienky: _vm.thuc_hien_ky_usb_v2,
            },
            on: {
              done: function ($event) {
                return _vm.submitSearch("searchForm")
              },
              close: function ($event) {
                return _vm.kyHocBaUsbXong()
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("ThongTinHocBa", {
        attrs: { show: _vm.ThongTinHocBa, id_hoc_ba: _vm.idHocBa },
        on: {
          close: function ($event) {
            _vm.ThongTinHocBa = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "page__title d-flex align-items-center justify-content-between",
      },
      [_c("h1", { staticClass: "title" }, [_vm._v("Danh sách học bạ")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }