var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-button",
    {
      attrs: { type: "warning", icon: "el-icon-edit-outline", size: "mini" },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.signUsb($event)
        },
      },
    },
    [_vm._v("Ký\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }