<template>
    <component :is="view"></component>
</template>

<script>
import {mapState,mapActions} from 'vuex';
import InfoGiaoVien from "./InfoGiaoVien";
import InfoHocSinh from "./InfoHocSinh";
import InfoCanBo from "./InfoCanBo";
import InfoAdmin from "./InfoAdmin.vue";

export default {
    name: "Detail",
    metaInfo : {
        title : 'Thông tin tài khoản',
        titleTemplate : '',
        meta : [
            { vmid: 'description', name: 'description', content: '' }
        ],
    },
    computed : {
        ...mapState('account',[
            "user"
        ]),
    },
    data(){
        return {
            view : null
        }
    },
    methods : {

        ...mapActions("teacher",[
            "getInfoGiaoVien"
        ]),
        ...mapActions("hocsinh",[
            "getInfoHocSinh"
        ])
    },
    beforeMount() {
        if (this.user.role == 5){
            this.getInfoHocSinh();
            this.view = InfoHocSinh
        }
        else if(this.user.role==4){
          // this.getInfoGiaoVien();
          this.view = InfoCanBo
        }
        else if(this.user.role==6||this.user.role==7||this.user.role==8){
          // this.getInfoGiaoVien();
          this.view = InfoAdmin
        }
        else{
            this.view = InfoGiaoVien
        }
    },
    mounted() {
      console.log('Mount')
    }
}
</script>

<style scoped>

</style>