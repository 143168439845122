<template>
  <div>
    <div ref="pdfViewer">
      <iframe
          style="width: 100%; height: 100vh; min-height: 600px"
          :src="urlPdf"
      ></iframe>
    </div>
  </div>
</template>
<script>
import jsPDF from "jspdf";
import {times} from "./../../../../../fonts/times-normal";
import {timesB} from "./../../../../../fonts/times-bold";
import {timesItalic} from "./../../../../../fonts/times-italic";
import {timesBoldItalic} from "./../../../../../fonts/times-bold-italic";
import "jspdf-autotable";

export default {
  props: ["item", "fileName", 'cauHinh'],
  data() {
    return {
      imgPassportWidth: 30,
      imgPassportHeight: 40,
      imgStampWidth: 40,
      imgStampHeight: 40,
      imgSignatureWidth: 40,
      imgSignatureHeight: 20,
      thongTinHocBaBia: {
        anhTuQLNT: "",
        quaTrinhHocTaps: [{namHoc: "", tenLop: "", tenTruong: ""}],
      },
      thongTinKqht: [],
      dataForm: {
        schoolName: "",
      },
      urlPdf: "",
      isThongTu22: false,
    };
  },
  mounted() {
    console.log("mounted view xem học bạ:");
    console.log(JSON.stringify(this.item));
    console.log('Cấu hình in')
    console.log(this.cauHinh)
    if (!this.cauHinh || this.cauHinh == null) {
      this.cauHinh = {}
    }
    this.generatePDF();
  },
  methods: {
    genHinhAnh(str) {
      console.log("genHinhAnh");
      console.log(str);
      let arrChuKy = str.trim();
      return arrChuKy.split("\n");
    },
    countCharacters(text, textDefault, n) {
      const maxLength = n ? n : 90;
      const combinedText = textDefault ? textDefault + text : text;
      const lineBreaks = Math.floor(combinedText.length / maxLength);
      let newText = combinedText;
      for (let i = 1; i <= lineBreaks; i++) {
        const breakIndex = newText.lastIndexOf(" ", i * maxLength + (i - 1));
        if (breakIndex !== -1) {
          newText = newText.slice(0, breakIndex) + "\n" + newText.slice(breakIndex + 1);
        } else {
          newText =
              newText.slice(0, i * maxLength + (i - 1)) +
              "\n" +
              newText.slice(i * maxLength + (i - 1));
        }
      }
      return newText;
    },
    convertNullsToEmptyStrings(obj) {
      let result = {};
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          result[key] = obj[key] === null ? "" : obj[key];
        }
      }
      return result;
    },
    getTextGender(e) {
      return e === 1 ? "Nam" : e === 0 ? "Nữ" : "";
    },
    formatTextWithBullets(str, current) {
      let text = str ? str.replace(/•\t/g, "").replace(/\t/g, "") : "";
      if (typeof text !== "string" || text.trim() === "") {
        return "";
      }
      const hasInitialDash = text.trim().startsWith("-");
      if (!text.trim().startsWith("-") && !text.trim().startsWith("+")) {
        text = "-" + text;
      }
      if (current === undefined) {
        current = 5;
      }
      const lines = text.split("\n");
      let formattedText = "";
      for (let line of lines) {
        let initialLineCount = formattedText.split("\n").length;
        if (initialLineCount < current) {
          if (line.length <= 93) {
            formattedText += line + "\n";
          } else {
            let currentLine = line;
            while (currentLine.length > 93) {
              let breakPoint = currentLine.lastIndexOf(" ", 93);
              if (breakPoint === -1) breakPoint = 93;
              formattedText += currentLine.slice(0, breakPoint) + "\n";
              currentLine = currentLine.slice(breakPoint).trim();
            }
            formattedText += currentLine + "\n";
          }
        } else {
          if (line.length <= 55) {
            formattedText += line + "\n";
          } else {
            let currentLine = line;
            while (currentLine.length > 55) {
              let breakPoint = currentLine.lastIndexOf(" ", 55);
              if (breakPoint === -1) breakPoint = 55;
              formattedText += currentLine.slice(0, breakPoint) + "\n";
              currentLine = currentLine.slice(breakPoint).trim();
            }
            formattedText += currentLine + "\n";
          }
        }
      }
      if (!hasInitialDash) {
        formattedText = formattedText.slice(1);
      }
      return formattedText.trim();
    },
    formatTextNgheNghiep() {
    },
    generatePDF() {
      const doc = new jsPDF("p", "mm", "a4");
      doc.addFileToVFS("Times-normal.ttf", times);
      doc.addFileToVFS("Times-bold.ttf", timesB);
      doc.addFileToVFS("Times-italic.ttf", timesItalic);
      doc.addFileToVFS("Times-bold-italic.ttf", timesBoldItalic);
      doc.addFont("Times-normal.ttf", "VNTimes", "normal");
      doc.addFont("Times-bold.ttf", "TimesBold", "normal");
      doc.addFont("Times-bold-italic.ttf", "TimesBoldItalic", "normal");
      doc.addFont("Times-italic.ttf", "VNTimesItalic", "normal");
      // Tạo đường viền cho trang đầu tiên
      doc.setFontSize(12);
      doc.setFont("VNTimes");
      for (let j = 0; j < this.item.length; j++) {
        console.log("file pdf thứ " + j);
        let moiHocBa = JSON.parse(JSON.stringify(this.item[j]));
        let totalPages = 3;
        let dulieuRender = moiHocBa.KQHT ? moiHocBa.KQHT : [];
        console.log('dulieuRender (KQHT):')
        console.log(dulieuRender)
        totalPages += 2 * (dulieuRender.length);
        console.log('totalPages:' + totalPages);
        let bia = moiHocBa.BIA ? moiHocBa.BIA : {};
        let convertBia = this.convertNullsToEmptyStrings(bia);
        const thongTu =
            moiHocBa.KQHT && moiHocBa.KQHT.length > 0
                ? moiHocBa.KQHT[0].maThongTu || ""
                : "";
        this.isThongTu22 = thongTu !== "58-2011";
        for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
          if (pageNumber == 1 && (!this.cauHinh.isBia)) {
            this.addContentToPage(doc, pageNumber, dulieuRender, convertBia);
            if (pageNumber <= totalPages) {
              if (!this.isThongTu22 && pageNumber == 2) {
              } else {
                doc.addPage();
              }
            }
          }
          if (pageNumber == 2 && (!this.cauHinh.isBia)) {
            this.addContentToPage(doc, pageNumber, dulieuRender, convertBia);
            if (pageNumber <= totalPages) {
              if (!this.isThongTu22 && pageNumber == 2) {
              } else {
                doc.addPage();
              }
            }
          }
          if (pageNumber == 3 && (!this.cauHinh.isInRiengThongTinHocSinh)) {
            this.addContentToPage(doc, pageNumber, dulieuRender, convertBia);
            if (pageNumber <= totalPages) {
              if (!this.isThongTu22 && pageNumber == 2) {
              } else {
                doc.addPage();
              }
            }
          }
          if (pageNumber == 4 && (!this.cauHinh.isKQHT)) {
            let pageWidth = doc.internal.pageSize.getWidth();
            if (this.cauHinh.isAnSoTrang != -1 && this.cauHinh.isAnSoTrang != null) {
              doc.setFont("VNTimes");
              let textHocBa1 = String(this.cauHinh.isAnSoTrang + pageNumber - 4);
              console.log('textHocBa1')
              console.log(textHocBa1)
              let fontSize1 = 13;
              let textWidth1 =
                  (doc.getStringUnitWidth(textHocBa1) * fontSize1) / doc.internal.scaleFactor;
              doc.setFontSize(fontSize1);
              doc.text(textHocBa1, (pageWidth - textWidth1) / 2, 295);
            }
            let dataRender = dulieuRender[0];
            this.addContentToPage(doc, pageNumber, dataRender, convertBia);
            if (pageNumber <= totalPages) {
              if (!this.isThongTu22 && pageNumber == 2) {
              } else {
                doc.addPage();
              }
            }
          }
          if (pageNumber == 5 && (!this.cauHinh.isKQHT)) {
            let pageWidth = doc.internal.pageSize.getWidth();
            if (this.cauHinh.isAnSoTrang != -1 && this.cauHinh.isAnSoTrang != null) {
              doc.setFont("VNTimes");
              let textHocBa1 = String(this.cauHinh.isAnSoTrang + pageNumber - 4);
              console.log('textHocBa1')
              console.log(textHocBa1)
              let fontSize1 = 13;
              let textWidth1 =
                  (doc.getStringUnitWidth(textHocBa1) * fontSize1) / doc.internal.scaleFactor;
              doc.setFontSize(fontSize1);
              doc.text(textHocBa1, (pageWidth - textWidth1) / 2, 295);
            }
            let dataRender = dulieuRender[0];
            this.addContentToPage(doc, pageNumber, dataRender, convertBia);
            if (pageNumber <= totalPages) {
              if (!this.isThongTu22 && pageNumber == 2) {
              } else {
                doc.addPage();
              }
            }
          }
          if (pageNumber == 6 && (!this.cauHinh.isKQHT)) {
            console.log('dulieuRender trang')
            let pageWidth = doc.internal.pageSize.getWidth();
            if (this.cauHinh.isAnSoTrang != -1 && this.cauHinh.isAnSoTrang != null) {
              doc.setFont("VNTimes");
              let textHocBa1 = String(this.cauHinh.isAnSoTrang + pageNumber - 4);
              console.log('textHocBa1')
              console.log(textHocBa1)
              let fontSize1 = 13;
              let textWidth1 =
                  (doc.getStringUnitWidth(textHocBa1) * fontSize1) / doc.internal.scaleFactor;
              doc.setFontSize(fontSize1);
              doc.text(textHocBa1, (pageWidth - textWidth1) / 2, 295);
            }
            let dataRender = dulieuRender[1];
            this.addContentToPage(doc, pageNumber, dataRender, convertBia);
            if (pageNumber <= totalPages) {
              if (!this.isThongTu22 && pageNumber == 2) {
              } else {
                doc.addPage();
              }
            }
          }
          if (pageNumber == 7 && (!this.cauHinh.isKQHT)) {
            let pageWidth = doc.internal.pageSize.getWidth();
            if (this.cauHinh.isAnSoTrang != -1 && this.cauHinh.isAnSoTrang != null) {
              doc.setFont("VNTimes");
              let textHocBa1 = String(this.cauHinh.isAnSoTrang + pageNumber - 4);
              console.log('textHocBa1')
              console.log(textHocBa1)
              let fontSize1 = 13;
              let textWidth1 =
                  (doc.getStringUnitWidth(textHocBa1) * fontSize1) / doc.internal.scaleFactor;
              doc.setFontSize(fontSize1);
              doc.text(textHocBa1, (pageWidth - textWidth1) / 2, 295);
            }
            let dataRender = dulieuRender[1];
            this.addContentToPage(doc, pageNumber, dataRender, convertBia);
            if (pageNumber <= totalPages) {
              if (!this.isThongTu22 && pageNumber == 2) {
              } else {
                doc.addPage();
              }
            }
          }
          if (pageNumber == 8 && (!this.cauHinh.isKQHT)) {
            let pageWidth = doc.internal.pageSize.getWidth();
            if (this.cauHinh.isAnSoTrang != -1 && this.cauHinh.isAnSoTrang != null) {
              doc.setFont("VNTimes");
              let textHocBa1 = String(this.cauHinh.isAnSoTrang + pageNumber - 4);
              console.log('textHocBa1')
              console.log(textHocBa1)
              let fontSize1 = 13;
              let textWidth1 =
                  (doc.getStringUnitWidth(textHocBa1) * fontSize1) / doc.internal.scaleFactor;
              doc.setFontSize(fontSize1);
              doc.text(textHocBa1, (pageWidth - textWidth1) / 2, 295);
            }
            let dataRender = dulieuRender[2];
            this.addContentToPage(doc, pageNumber, dataRender, convertBia);
            if (pageNumber <= totalPages) {
              if (!this.isThongTu22 && pageNumber == 2) {
              } else {
                doc.addPage();
              }
            }
          }
          if (pageNumber == 9 && (!this.cauHinh.isKQHT)) {
            let pageWidth = doc.internal.pageSize.getWidth();
            if (this.cauHinh.isAnSoTrang != -1 && this.cauHinh.isAnSoTrang != null) {
              doc.setFont("VNTimes");
              let textHocBa1 = String(this.cauHinh.isAnSoTrang + pageNumber - 4);
              console.log('textHocBa1')
              console.log(textHocBa1)
              let fontSize1 = 13;
              let textWidth1 =
                  (doc.getStringUnitWidth(textHocBa1) * fontSize1) / doc.internal.scaleFactor;
              doc.setFontSize(fontSize1);
              doc.text(textHocBa1, (pageWidth - textWidth1) / 2, 295);
            }
            let dataRender = dulieuRender[2];
            this.addContentToPage(doc, pageNumber, dataRender, convertBia);
            if (pageNumber <= totalPages) {
              if (!this.isThongTu22 && pageNumber == 2) {
              } else {
                doc.addPage();
              }
            }
          }
          if (pageNumber == 10 && (!this.cauHinh.isKQHT)) {
            let pageWidth = doc.internal.pageSize.getWidth();
            if (this.cauHinh.isAnSoTrang != -1 && this.cauHinh.isAnSoTrang != null) {
              doc.setFont("VNTimes");
              let textHocBa1 = String(this.cauHinh.isAnSoTrang + pageNumber - 4);
              console.log('textHocBa1')
              console.log(textHocBa1)
              let fontSize1 = 13;
              let textWidth1 =
                  (doc.getStringUnitWidth(textHocBa1) * fontSize1) / doc.internal.scaleFactor;
              doc.setFontSize(fontSize1);
              doc.text(textHocBa1, (pageWidth - textWidth1) / 2, 295);
            }
            let dataRender = dulieuRender[3];
            this.addContentToPage(doc, pageNumber, dataRender, convertBia);
            if (pageNumber <= totalPages) {
              if (!this.isThongTu22 && pageNumber == 2) {
              } else {
                doc.addPage();
              }
            }
          }
          if (pageNumber == 11 && (!this.cauHinh.isKQHT)) {
            let pageWidth = doc.internal.pageSize.getWidth();
            if (this.cauHinh.isAnSoTrang != -1 && this.cauHinh.isAnSoTrang != null) {
              doc.setFont("VNTimes");
              let textHocBa1 = String(this.cauHinh.isAnSoTrang + pageNumber - 4);
              console.log('textHocBa1')
              console.log(textHocBa1)
              let fontSize1 = 13;
              let textWidth1 =
                  (doc.getStringUnitWidth(textHocBa1) * fontSize1) / doc.internal.scaleFactor;
              doc.setFontSize(fontSize1);
              doc.text(textHocBa1, (pageWidth - textWidth1) / 2, 295);
            }
            let dataRender = dulieuRender[3];
            this.addContentToPage(doc, pageNumber, dataRender, convertBia);
            if (pageNumber <= totalPages) {
              if (!this.isThongTu22 && pageNumber == 2) {
              } else {
                doc.addPage();
              }
            }
          }

        }
      }
      const totalPages = doc.internal.getNumberOfPages();
      console.log("totalPages", totalPages);
      doc.deletePage(totalPages);

      doc.setDocumentProperties({
        title: this.fileName ? this.fileName : "Học Bạ Học Sinh",
      });

      const pdfDataUrl = doc.output("blob", {filename: "tenHocSinh"});

      this.xemPdf(pdfDataUrl);
    },
    xemPdf(dataUri) {
      console.log("xemPdf");
      console.log(dataUri);
      const url = URL.createObjectURL(dataUri);
      console.log("url:");
      console.log(url);
      this.urlPdf = url;
    },
    addContentToPage(doc, pageNumber, duLieuRender, bia) {
      let duLieu = null;
      if (Array.isArray(duLieuRender)) {
        duLieu = this.convertNullsToEmptyStrings(duLieuRender[0]);
      } else {
        duLieu = this.convertNullsToEmptyStrings(duLieuRender);
      }
      console.log("duLieu");
      console.log(duLieu);

      const tenTruong = bia.tenTruong ?? "";
      const tenHuyen = bia.tenHuyen ?? "";
      const tenTinh = bia.tenTinh ?? "";
      const hoten = bia.tenHS ?? "";
      var anhTuHBDT = (bia.anhTuHBDT && bia.anhTuHBDT != '') ? bia.anhTuHBDT : bia.anhTuQLNT;

      anhTuHBDT = anhTuHBDT.replace(
          "https://cdn.smas.edu.vn",
          "https://cndsmas.dtsgroup.com.vn"
      );
      console.error('Ảnh: ' + anhTuHBDT)
      const gioiTinh = this.getTextGender(bia.gioiTinh);
      const ngaySinh = bia.ngaySinh ?? "";
      const tenDanToc = bia.tenDanToc ?? "";
      const noiSinh = bia.noiSinh ?? "";
      const tenDtuongCsach = this.cauHinh.isDienChinhSach ? '' : (bia.tenDtuongCsach ? bia.tenDtuongCsach : "");
      const noiOHienNay =this.countCharacters(bia.noiOHienNay ?? "", "", 75);
      const hoTenCha = bia.hoTenCha ?? "";
      const ngheNghiepCha = bia.ngheNghiepCha ?? "";
      const hoTenMe = bia.hoTenMe ?? "";
      const ngheNghiepMe = bia.ngheNghiepMe ?? "";
      const hoTenNguoiGiamHo = bia.nguoiGiamHo ?? "";
      const ngheNghiepNguoiGiamHo = bia.ngheNghiepNguoiGiamHo ?? "";
      const ngayKyBia = bia.ngayKyBia ?? "";
      const tenHieuTruongBia = bia.tenHieuTruong ?? "";
      const tieuDeKyThayBia = bia.tieuDeKyThay ?? "";
      const noiDungRenLuyenTrongKyHe = this.countCharacters(duLieu.noiDungRenLuyenTrongKyHe ?? "");
      const capHocNhaTruong = bia.maCapHoc ?? 0;
      const pathChuKyHieuTruongBia = bia.pathChuKyHieuTruong ?? "";
      const pathDauBia = bia.pathDau ?? "";
      const capHoc = bia.capHoc ?? "";
      const loaiHocBa = duLieu.loaiHocBa ?? "";
      const khoiHoc = duLieu.khoiHoc ?? "";
      const tenGVCN = duLieu.tenGVCN ?? "";
      const pathChuKyHieuTruong = duLieu.pathChuKyHieuTruong ?? "";
      const pathChuKyGVCN = duLieu.pathChuKyGVCN ?? "";
      const pathDau = duLieu.pathDau ?? "";
      const hocLucHKI = duLieu.hocLucHKI ?? "";
      const hocLucCN = duLieu.hocLucCN ?? "";
      const hocLucHKII = duLieu.hocLucHKII ?? "";
      const hanhKiemCaNam = duLieu.hanhKiemCaNam ?? "";
      const hanhKiemHKI = duLieu.hanhKiemHKI ?? "";
      const hanhKiemHKII = duLieu.hanhKiemHKII ?? "";
      const soNgayNghi = duLieu.soNgayNghi ?? "";
      const chungChiNghe = this.countCharacters(
          duLieu.chungChiNghe ?? "",
          "- Chứng chỉ (nếu có): ",
          80
      );
      const khenThuong = this.countCharacters(
          duLieu.khenThuongDacBiet ?? "",
          "- Khen thưởng (nếu có): ",
          96
      );
      console.log("khenThuong", khenThuong);
      const tenLop = duLieu.tenLop ?? "";
      const namHoc = duLieu.strNamHoc ?? "";
      const nhanXetHieuTruong = duLieu.nhanXetHieuTruong
          ? this.countCharacters(duLieu.nhanXetHieuTruong, "", 55)
          : "Đồng ý với nhận xét của giáo viên chủ nhiệm";
      const ngayHieuTruongKy = duLieu.ngayHieuTruongKy ?? "";
      let pageWidth = doc.internal.pageSize.getWidth();
      if (pageNumber == 1) {
        doc.setLineWidth(0.3);
        if (this.isThongTu22) {
          doc.setFontSize(15);
          doc.setFont("TimesBold");
          doc.text(`TRƯỜNG: ${tenTruong} `, 30  , 20);
          doc.setFont("TimesBold");
          doc.text(`Huyện/Quận/Thị xã/Thành phố: ${tenHuyen}`, 30, 30);
          doc.setFont("TimesBold");
          doc.text(`Tỉnh/Thành phố: ${tenTinh}`, 30, 40);
        } else {
          doc.setFont("TimesBold");
          doc.setFontSize(20);
          const text = bia.capQuanLySo || "";
          const textWidth = doc.getTextWidth(text);
          const pageWidth = doc.internal.pageSize.getWidth();
          const x = (pageWidth - textWidth) / 2;
          doc.text(text, x, 20);
        }
        let textHocBa = "HỌC BẠ";
        let fontSize = 70;
        let textWidth =
            (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
        doc.setFontSize(fontSize);
        doc.text(textHocBa, (pageWidth - textWidth) / 2, 110);
        // Cấp học học bạ
        textHocBa =
            capHoc == 2 || [6, 7, 8, 9].includes(khoiHoc)
                ? "TRUNG HỌC CƠ SỞ"
                : capHoc == 3 || [10, 11, 12].includes(khoiHoc)
                    ? "TRUNG HỌC PHỔ THÔNG"
                    : "";
        fontSize = 20;
        doc.setFontSize(fontSize);
        textWidth =
            (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
        doc.text(textHocBa, (pageWidth - textWidth) / 2, 125);
        // Họ tên học sinh
        textHocBa = "Họ và tên học sinh";
        fontSize = 14;
        doc.setFontSize(fontSize);
        textWidth =
            (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
        doc.text(textHocBa, (pageWidth - textWidth) / 2, 190);
        // Họ tên học sinh
        textHocBa = hoten.toUpperCase();
        fontSize = 16;
        doc.setFontSize(fontSize);
        textWidth =
            (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
        doc.text(textHocBa, (pageWidth - textWidth) / 2, 200);
        // Số sổ đăng bộ
        let maSoHocBa;
        if (this.isThongTu22 && (capHoc == 2 || [6, 7, 8, 9].includes(khoiHoc))) {
          maSoHocBa = `Sổ đăng bộ PCGD:    ${bia.maSoHocBa}`;
        } else if (this.isThongTu22 && (capHoc == 3 || [10, 11, 12].includes(khoiHoc))) {
          maSoHocBa = `Số sổ đăng bộ:  ${bia.maSoHocBa}`;
        } else {
          maSoHocBa = `Số:  ${bia.maSoHocBa}`;
        }
        textHocBa = maSoHocBa;
        fontSize = 15;
        doc.setFontSize(fontSize);
        textWidth =
            (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
        doc.text(textHocBa, (pageWidth - textWidth) / 2, 282);
        doc.rect(
            10,
            10,
            doc.internal.pageSize.width - 20,
            doc.internal.pageSize.height - 20
        );
        doc.rect(
            11,
            11,
            doc.internal.pageSize.width - 22,
            doc.internal.pageSize.height - 22
        );
      } else if (pageNumber == 2 && this.isThongTu22) {
        let textHocBa = "HƯỚNG DẪN SỬ DỤNG HỌC BẠ";
        let fontSize = 20;
        let textWidth =
            (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
        doc.setFontSize(fontSize);
        doc.text(textHocBa, (pageWidth - textWidth) / 2, 15);
        doc.setFontSize(13);
        let lines;
        let contentWidth = 190;
        doc.setFont("TimesBold");
        doc.text("1. Quy định chung", 10, 30);
        doc.setFont("VNTimes");
        lines = doc.splitTextToSize(
            "- Học bạ học sinh được nhà trường quản lý và bảo quản trong trường; đầu năm học, cuối học kỳ, cuối năm học, được bàn giao cho giáo viên chủ nhiệm lớp để thực hiện việc ghi vào Học bạ và thu lại sau khi đã hoàn thành.",
            contentWidth
        );
        doc.text(lines, 10, 40);
        const noiDung =
            capHoc == 2 || [6, 7, 8, 9].includes(khoiHoc)
                ? "lớp 6 đến lớp 9"
                : capHoc == 3 || [10, 11, 12].includes(khoiHoc)
                    ? "lớp 10 đến lớp 12"
                    : "";
        lines = doc.splitTextToSize(
            `- Nội dung trang 1 phải được ghi đầy đủ khi xác lập Học bạ; Hiệu trưởng ký, đóng dấu xác nhận quá trình học tập từng năm học từ ${noiDung}`,
            contentWidth
        );
        doc.text(lines, 10, 58);
        doc.setFont("TimesBold");
        doc.text("2. Giáo viên môn học", 10, 75);
        doc.setFont("VNTimes");
        lines = doc.splitTextToSize(
            "- Ghi điểm trung bình môn học hoặc mức đánh giá kết quả học tập theo môn học từng học kì, cả năm học; nhận xét sự tiến bộ, ưu điểm nổi bật, hạn chế chủ yếu (nếu có) của học sinh..",
            contentWidth
        );
        doc.text(lines, 10, 83);
        lines = doc.splitTextToSize(
            "- Khi sửa chữa (nếu có), dùng bút mực đỏ gạch ngang nội dung cũ, ghi nội dung mới vào phía trên bên phải vị trí ghi nội dung cũ, ký xác nhận về việc sửa chữa bên cạnh nội dung đã sửa.",
            contentWidth
        );
        doc.text(lines, 10, 98);
        doc.setFont("TimesBold");
        doc.text("3. Giáo viên chủ nhiệm", 10, 115);
        doc.setFont("VNTimes");
        lines = doc.splitTextToSize(
            "- Tiếp nhận và bàn giao lại Học bạ học sinh với văn phòng nhà trường.",
            contentWidth
        );
        doc.text(lines, 10, 123);
        lines = doc.splitTextToSize(
            "- Đôn đốc việc ghi vào Học bạ điểm trung bình môn học hoặc mức đánh giá kết quả học tập của học sinh của giáo viên môn học",
            contentWidth
        );
        doc.text(lines, 10, 130);
        lines = doc.splitTextToSize(
            "- Ghi đầy đủ các nội dung trên trang 1, nội dung ở phần đầu các trang tiếp theo, nhận xét kết quả rèn luyện và học tập của học sinh theo từng năm học.",
            contentWidth - 2
        );
        doc.text(lines, 10, 143);
        lines = doc.splitTextToSize(
            "- Ghi kết quả tổng hợp đánh giá; mức đánh giá lại môn học hoặc rèn luyện thêm trong kì nghỉ hè (nếu có); đánh giá mức độ hoàn thành nhiệm vụ đối với học sinh phải rèn luyện thêm trong kì nghỉ hè (nếu có).",
            contentWidth - 2
        );
        doc.text(lines, 10, 156);
        lines = doc.splitTextToSize(
            "- Ghi nhận xét sự tiến bộ, ưu điểm nổi bật, hạn chế chủ yếu và những biểu hiện nổi bật của học sinh trong quá trình rèn luyện và học tập; những vấn đề cần quan tâm giúp đỡ thêm trong quá trình rèn luyện và học tập.",
            contentWidth - 2
        );
        doc.text(lines, 10, 176);
        doc.setFont("TimesBold");
        doc.text("4. Hiệu trưởng", 10, 195);
        doc.setFont("VNTimes");
        lines = doc.splitTextToSize(
            "- Phê duyệt Học bạ của học sinh khi kết thúc năm học.",
            contentWidth
        );
        doc.text(lines, 10, 203);
        lines = doc.splitTextToSize(
            "- Kiểm tra việc quản lý, bảo quản, ghi Học bạ.",
            contentWidth
        );
        doc.text(lines, 10, 210);
      } else if (pageNumber == 3) {
        doc.setFont("TimesBold");
        let fontSize = 13;
        let textHocBa = "CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM";
        let textWidth =
            (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
        doc.setFontSize(fontSize);
        doc.text(textHocBa, (pageWidth - textWidth) / 2, 12);
        fontSize = 13;
        const textTieuDe = "Độc lập - Tự do - Hạnh phúc";
        textWidth =
            (doc.getStringUnitWidth(textTieuDe) * fontSize) / doc.internal.scaleFactor;
        doc.setFontSize(fontSize);
        doc.text(textTieuDe, (pageWidth - textWidth) / 2, 18);
        const lineY = 20;
        const lineWidth = textWidth;
        // Vẽ đường kẻ
        doc.setLineWidth(0.5);
        doc.line((pageWidth - lineWidth) / 2, lineY, (pageWidth + lineWidth) / 2, lineY);
        fontSize = 40;
        doc.setFontSize(fontSize);
        textHocBa = "HỌC BẠ";
        textWidth =
            (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
        doc.text(textHocBa, (pageWidth - textWidth) / 2, 45);
        fontSize = 18;
        doc.setFontSize(fontSize);
        textHocBa =
            capHoc == 2 || [6, 7, 8, 9].includes(khoiHoc)
                ? "TRUNG HỌC CƠ SỞ"
                : capHoc == 3 || [10, 11, 12].includes(khoiHoc)
                    ? "TRUNG HỌC PHỔ THÔNG"
                    : "";
        textWidth =
            (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
        doc.text(textHocBa, (pageWidth - textWidth) / 2, 55);
        const imgPassportWidth = this.imgPassportWidth;
        const imgpassportHeight = this.imgPassportHeight;
        const passportX = 15;
        const passportY = 45 - imgpassportHeight / 2 - 10;
        if (anhTuHBDT && !this.cauHinh.isAnh) {
          console.log("new anhTuHBDT", anhTuHBDT);
          doc.addImage(
              anhTuHBDT,
              "JPEG",
              passportX,
              passportY,
              imgPassportWidth,
              imgpassportHeight
          );
        } else {
          const imgPassportWidth = this.imgPassportWidth;
          const imgPassportHeight = this.imgPassportHeight;
          doc.rect(passportX, passportY, imgPassportWidth, imgPassportHeight);
          doc.setFontSize(12);
          doc.text(
              "Ảnh 3x4 cm",
              passportX + imgPassportWidth / 2,
              passportY + imgPassportHeight / 2,
              {align: "center"}
          );
        }
        const imgPartWidth = imgPassportWidth / 2;
        const imgPartHeight = imgpassportHeight / 2;
        const imgPartCoordinates = [
          {
            x: passportX,
            y: passportY,
            width: imgPartWidth,
            height: imgPartHeight,
          },
          {
            x: passportX + imgPartWidth,
            y: passportY,
            width: imgPartWidth,
            height: imgPartHeight,
          },
          {
            x: passportX,
            y: passportY + imgPartHeight,
            width: imgPartWidth,
            height: imgPartHeight,
          },
          {
            x: passportX + imgPartWidth,
            y: passportY + imgPartHeight,
            width: imgPartWidth,
            height: imgPartHeight,
          },
        ];
        const img_Stamp_1_Width = this.imgStampWidth;
        const img_Stamp_1_Height = this.imgStampHeight;
        const img_Stamp_1_X = imgPartCoordinates[0].x + imgPartCoordinates[0].width - 5;
        const img_stamp_1_Y = imgPartCoordinates[0].y + imgPartCoordinates[0].height - 5;
        const pathDauAnh = bia.pathDauAnh ? bia.pathDauAnh : ""
        if (pathDauAnh && !this.cauHinh.isDau) {
          doc.addImage(
              pathDauAnh,
              "JPEG",
              img_Stamp_1_X,
              img_stamp_1_Y,
              img_Stamp_1_Width,
              img_Stamp_1_Height
          );
        }
        fontSize = 13;
        doc.setFontSize(fontSize);
        doc.setFont("VNTimes");
        doc.text("Họ và tên:", 15, 80);
        doc.setFont("TimesBold");
        doc.text(hoten ? hoten.toUpperCase() : '', 45, 80);
        doc.setFont("VNTimes");
        doc.text("Giới tính:", 130, 80);
        // doc.setFont("TimesBold");
        doc.text(gioiTinh, 150, 80);
        doc.setFont("VNTimes");
        doc.text("Ngày sinh:", 15, 88);
        // doc.setFont("TimesBold");
        doc.text(ngaySinh, 45, 88);
        doc.setFont("VNTimes");
        doc.text("Dân tộc:", 130, 88);
        // doc.setFont("TimesBold");
        doc.text(tenDanToc, 150, 88);
        doc.setFont("VNTimes");
        doc.text("Nơi sinh:", 15, 96);
        // doc.setFont("TimesBold");
        doc.text(noiSinh, 45, 96);
        let YGoc = 104;
        doc.setFont("VNTimes");
        let convertTenDtuongCsach = this.isThongTu22
            ? this.countCharacters(
                tenDtuongCsach,
                "Đối tượng (Con liệt sĩ, con thương binh,...): "
            )
            : this.countCharacters(
                tenDtuongCsach,
                "Con liệt sĩ, con thương binh (bệnh binh, người được hưởng chế độ như thương binh, gia đình có công với cách mạng): ",
                99
            );
        YGoc = convertTenDtuongCsach.length > 93 ? YGoc + 4 : YGoc;
        doc.setFont("VNTimes");
        doc.text(convertTenDtuongCsach, 15, 104);
        doc.setFont("VNTimes");
        doc.text("Chỗ ở hiện tại:", 15, YGoc + 8);
        // doc.setFont("TimesBold");
        doc.text(noiOHienNay, 45, noiOHienNay.length > 75 ? YGoc + 5 : YGoc + 8);
        doc.setFont("VNTimes");
        doc.text("Họ và tên cha:", 15, YGoc + 16);
        // doc.setFont("TimesBold");
        doc.text(hoTenCha, 45, YGoc + 16);
        doc.setFont("VNTimes");
        doc.text("Nghề nghiệp:", 130, YGoc + 16);
        // doc.setFont("TimesBold");
        doc.text(ngheNghiepCha, 160, YGoc + 16);
        doc.setFont("VNTimes");
        doc.text("Họ và tên mẹ:", 15, YGoc + 24);
        // doc.setFont("TimesBold");
        doc.text(hoTenMe, 45, YGoc + 24);
        doc.setFont("VNTimes");
        doc.text("Nghề nghiệp:", 130, YGoc + 24);
        // doc.setFont("TimesBold");
        doc.text(ngheNghiepMe, 160, YGoc + 24);
        doc.setFont("VNTimes");
        doc.text("Họ và tên người giám hộ:", 15, YGoc + 32);
        // doc.setFont("TimesBold");
        doc.text(hoTenNguoiGiamHo, 65, YGoc + 32);
        doc.setFont("VNTimes");
        doc.text("Nghề nghiệp:", 130, YGoc + 32);
        // doc.setFont("TimesBold");
        doc.text(ngheNghiepNguoiGiamHo, 160, YGoc + 32);
        doc.setFont("VNTimes");
        // Kiểm tra và căn giữa ngày ký bìa
        if (ngayKyBia) {
          var ngayKiBiaX = doc.internal.pageSize.getWidth() - doc.getTextWidth(ngayKyBia) - 5;
          doc.setFont("VNTimesItalic");
          //   Căn lề phải ngày ký bìa
          // doc.text('text Ngay Ky Bia', xTextGoc, yGoc - 20);
          var textLines = doc.splitTextToSize(ngayKyBia, pageWidth);
          var textWidths = doc.getStringUnitWidth(ngayKyBia) * doc.internal.getFontSize() / doc.internal.scaleFactor;
          var x = pageWidth - textWidths - 15; // 10 là khoảng cách từ lề phải
          // Thêm văn bản với căn lề phải
          for (let i = 0; i < textLines.length; i++) {
            doc.text(textLines[i], x, YGoc + 41);
          }

        }

        // Cố định giá trị X cho principal
        const principal = tieuDeKyThayBia;
        let principalWidth = doc.getTextWidth(principal); // Chiều rộng của principal

        if (principal.includes("\n")) {
          principalWidth /= 2;
        }

        const principalY = YGoc + 47;

        // Tính toán vị trí X cho principal sao cho nó căn giữa ngayKyBia
        const principalX =
            ngayKiBiaX + (doc.getTextWidth(ngayKyBia) - principalWidth) / 2;

        // Hiển thị principal
        doc.setFont("TimesBold");
        doc.text(principal, principalX, principalY);
        // Cố định giá trị X cho dòng text "(Ký, ghi rõ họ tên và đóng dấu)"
        const signText = "(Ký, ghi rõ họ tên và đóng dấu)";
        const signTextWidth = doc.getTextWidth(signText); // Chiều rộng của dòng text
        const signTextY = YGoc + (principal.includes("\n") ? 57 : 52);

        // Tính toán vị trí X cho dòng text sao cho nó căn giữa ngayKyBia
        const signTextX = ngayKiBiaX + (doc.getTextWidth(ngayKyBia) - signTextWidth) / 2;

        // Hiển thị dòng text
        doc.setFont("VNTimesItalic");
        doc.text(signText, signTextX, signTextY);
        doc.setFont("TimesBold");

        // Tính toán vị trí X cho dòng text tenHieuTruongBia sao cho nó căn giữa ngayKyBia
        const tenHieuTruongBiaWidth = doc.getTextWidth(tenHieuTruongBia); // Chiều rộng của dòng text tenHieuTruongBia

        // Tính toán vị trí X cho dòng text sao cho nó căn giữa ngayKyBia
        const tenHieuTruongBiaX =
            ngayKiBiaX + (doc.getTextWidth(ngayKyBia) - tenHieuTruongBiaWidth) / 2;

        // Hiển thị dòng text
        doc.text(tenHieuTruongBia, tenHieuTruongBiaX, YGoc + 91);

        const img_Stamp_2_Width = this.imgStampWidth;
        const img_Stamp_2_Height = this.imgStampHeight;
        const stamp_2_X = principalX - 30;
        const stamp_2_Y = principalY - 2;
        if (pathDauBia && !this.cauHinh.isDau) {
          doc.addImage(
              pathDauBia,
              "JPEG",
              stamp_2_X,
              stamp_2_Y,
              img_Stamp_2_Width,
              img_Stamp_2_Height
          );
        }

        const signatureX = stamp_2_X + img_Stamp_2_Width - 15;
        const signatureY = stamp_2_Y + img_Stamp_2_Height / 2 - 25 / 2;
        if (pathChuKyHieuTruongBia && !this.cauHinh.isAnAnhHieuTruong) {
          doc.addImage(pathChuKyHieuTruongBia, "JPEG", signatureX, signatureY, 40, 25);
        }
        fontSize = 13.5;
        doc.setFont("TimesBold");
        textHocBa = "QUÁ TRÌNH HỌC TẬP";
        textWidth =
            (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
        doc.text(textHocBa, (pageWidth - textWidth) / 2, YGoc + 101);
        //   Quá trình học tập
        var quaTrinhHocTaps = bia.quaTrinhHocTaps;
        console.log("Quá trình học tập: ", quaTrinhHocTaps);
        console.log(this.cauHinh)
        if (this.cauHinh.isQTHT) {
          //   Ẩn qtht
          quaTrinhHocTaps.forEach(obj => {
            for (let key in obj) {
              if (obj.hasOwnProperty(key)) {
                obj[key] = null;  // Đưa giá trị về null
              }
            }
          });
        }
        let header = [];
        this.isThongTu22
            ? (header = [
              [
                {content: "Năm học", colSpan: 1},
                {content: "Lớp", colSpan: 1},
                {
                  content: "Tên trường, tỉnh/thành phố",
                  colSpan: 1,
                },
              ],
            ])
            : (header = [
              [
                {content: "Năm học", colSpan: 1},
                {content: "Lớp", colSpan: 1},
                {
                  content: "Tên trường (huyện, quận, thị xã,TP thuộc tỉnh), tỉnh (TP) ",
                  colSpan: 1,
                },
                {content: "Xác nhận của hiệu trưởng (ký tên)", colSpan: 1},
              ],
            ]);
        let body = [];
        const handleNull = (value) => (value !== null ? value : "");
        if (Array.isArray(quaTrinhHocTaps) && quaTrinhHocTaps.length > 0) {
          body = quaTrinhHocTaps.map((item) => {
            let truongTinh =
                item.tenTruong && item.tenTinh
                    ? handleNull(item.tenTruong) + " - " + handleNull(item.tenTinh)
                    : handleNull(item.tenTruong) + handleNull(item.tenTinh);
            let truongHuyenTinh =
                item.tenTruong && item.tenHuyen
                    ? handleNull(item.tenTruong) + " - " + handleNull(item.tenHuyen)
                    : handleNull(item.tenTruong) + handleNull(item.tenHuyen);
            truongHuyenTinh +=
                item.tenHuyen && item.tenTinh
                    ? " - " + handleNull(item.tenTinh)
                    : handleNull(item.tenTinh);
            return [
              {content: handleNull(item.namHoc), colSpan: 1},
              {content: handleNull(item.tenLop), colSpan: 1},
              {
                content: this.isThongTu22 ? truongTinh : truongHuyenTinh,
                colSpan: 1,
              },
            ];
          });
        }
        body = body;
        let columnStyles = {
          0: {
            halign: "center",
            valign: "middle",
            fontSize: 11.5,
            cellWidth: 21,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
          },
          1: {
            halign: "center",
            fontSize: 11.5,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            cellWidth: 23,
          },
          2: {
            halign: "left",
            fontSize: 11.5,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
          },
          3: {
            halign: "left",
            fontSize: 11.5,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 10},
            valign: "middle",
          },
        };
        const isThongTu22 = this.isThongTu22;
        let that = this;
        doc.autoTable({
          tableLineColor: [70, 76, 70],
          columnStyles: columnStyles,
          head: header,
          body: body,
          startY: YGoc + 104,
          didDrawCell: function (data) {
            if (data.section === "body") {
              const item = quaTrinhHocTaps[data.row.index];
              const cell = data.cell;
              const imgX = cell.x;
              const imgY = cell.y;
              if (isThongTu22 && data.column.index === 2) {
                if (item?.pathChuKyHieuTruong && !that.cauHinh.isAnAnhHieuTruong)
                  doc.addImage(
                      item.pathChuKyHieuTruong,
                      "JPEG",
                      imgX + 118,
                      imgY - 1,
                      20,
                      10
                  );
                if (item?.pathDau && !that.cauHinh.isDau) {
                  doc.addImage(item.pathDau, "JPEG", imgX + 95, imgY - 29, 40, 40);
                }
              } else if (!isThongTu22 && data.column.index === 3) {
                if (item?.pathChuKyHieuTruong && !that.cauHinh.isAnAnhHieuTruong) {
                  doc.addImage(item.pathChuKyHieuTruong, "JPEG", imgX + 10, imgY, 20, 10);
                }
                if (item?.pathDau && !that.cauHinh.isDau) {
                  doc.addImage(item.pathDau, "JPEG", imgX - 10, imgY - 26, 40, 40);
                }
              }
            }
          },
          theme: "grid",
          headStyles: {
            fillColor: [255, 255, 255],
            textColor: [45, 45, 45],
            font: "TimesBold",
            fontStyle: "normal",
            lineWidth: 0.1,
            halign: "center",
            valign: "middle",
            fontSize: 13,
            cellPadding: 1,
            minCellHeight: 15,
          },
          styles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: "TimesBold",
            fontStyle: "normal",
          },
          bodyStyles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: "VNTimes",
            fontStyle: "normal",
            cellPadding: 1,
            fontSize: 12,
            minCellHeight: 10.5,
          },
        });
      } else if (pageNumber == 4) {
        const marginTop = 20; // 1,5cm
        const marginLeft = 14; // 2cm
        const marginRight = doc.internal.pageSize.getWidth() - 10; // 1cm từ lề phải
        const marginBottom = doc.internal.pageSize.getHeight() - 10; // 1cm từ lề dưới
        doc.setLineWidth(0.3);
        doc.line(marginLeft, marginTop, marginRight, marginTop); // Đường viền trên
        doc.line(marginLeft, marginTop, marginLeft, marginBottom); // Đường viền trái
        doc.line(marginRight, marginTop, marginRight, marginBottom); // Đường viền phải
        doc.line(marginLeft, marginBottom, marginRight, marginBottom); // Đường viền dưới
        const cap3ThongTu58 = !this.isThongTu22 && loaiHocBa == 3;
        if (cap3ThongTu58) {
          doc.setFont("TimesBoldItalic");
          doc.setFontSize(13);
          doc.text("Họ và tên:", 15, 10);
          doc.setFontSize(14);
          hoten.length > 15 ? doc.setFontSize(12) : doc.setFontSize(14);
          doc.text(hoten, 37, 10);
          doc.setFontSize(13);
          doc.text(`Lớp: ${tenLop}` ?? "", 110, 10);
          doc.setFontSize(13);
          doc.text(`Năm học: ${namHoc}` ?? "", 159, 10);
          doc.setFontSize(13);
          const phanBan = duLieu.phanBan ? duLieu.phanBan : "";
          doc.text(`Ban: ${phanBan}`, 15, 17);
          doc.setFontSize(13);
          const monHocPhanBan = duLieu.monHocPhanBan ? duLieu.monHocPhanBan : "";
          doc.text(`Môn chuyên: ${monHocPhanBan}`, 110, 17);
        } else {
          doc.setFont("TimesBoldItalic");
          doc.setFontSize(13);
          doc.text("Họ và tên:", 15, 15);
          hoten.length > 15 ? doc.setFontSize(12) : doc.setFontSize(14);
          doc.text(hoten, 37, 15);
          doc.setFontSize(13);
          doc.text(`Lớp: ${tenLop}` ?? "", 110, 15);
          doc.setFontSize(13);
          doc.text(`Năm học: ${namHoc}` ?? "", 159, 15);
        }
        let header = [
          [
            {content: "Môn học/Hoạt động giáo dục", rowSpan: 2},
            {
              content: "Điểm trung bình môn học hoặc mức đánh giá",
              colSpan: 3,
            },
            {
              content: this.isThongTu22
                  ? "Điểm trung bình môn học hoặc mức đánh giá lại sau đánh giá lại, rèn luyện thêm trong kỳ nghỉ hè (nếu có)"
                  : "Điểm hoặc xếp loại sau KT lại (nếu có)",
              rowSpan: 2,
              styles: this.isThongTu22 ? {fontSize: 10} : {},
            },
            {
              content: this.isThongTu22
                  ? "Nhận xét sự tiến bộ, ưu điểm nổi bật, hạn chế chủ yếu (nếu có) và chữ kí của giáo viên môn học"
                  : "Giáo viên bộ môn ký xác nhận ĐTB môn học hoặc xếp loại và sửa chữa (nếu có), (Ký và ghi rõ họ tên) ",
              rowSpan: 2,
            },
          ],
          [
            {content: "H Kỳ I", rowSpan: 1},
            {content: "H Kỳ II", rowSpan: 1},
            {content: "CN", rowSpan: 1},
          ],
        ];
        const ketQuaHocTaps = duLieu.ketQuaHocTaps;
        let body = [];
        const handleNull = (value) => (value !== null ? value : "");
        let tenGiaoVienCount = 0;
        if (Array.isArray(ketQuaHocTaps) && ketQuaHocTaps.length > 0) {
          body = ketQuaHocTaps.map((item) => {
            if (item.tenGiaoVien) {
              const giaoVienList = item.tenGiaoVien
                  .split("\n")
                  .filter((name) => name.trim() !== "");
              tenGiaoVienCount += giaoVienList.length;
            }
            return [
              {content: handleNull(item.tenMonHoc), colSpan: 1},
              {content: handleNull(item.diemHocKyI), colSpan: 1},
              {content: handleNull(item.diemHocKyII), colSpan: 1},
              {content: handleNull(item.diemCaNam), colSpan: 1},
              {content: handleNull(item.diemThiLai), colSpan: 1},
              {content: handleNull(item.tenGiaoVien), colSpan: 1},
            ];
          });
        }

        let columnStyles = {
          0: {
            halign: "center",
            valign: "middle",
            fontSize: 13,
            cellWidth: 45.8,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
          },
          1: {
            halign: "center",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            cellWidth: 17,
          },
          2: {
            halign: "center",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            cellWidth: 17,
          },
          3: {
            halign: "center",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            cellWidth: 17,
          },
          4: {
            halign: "center",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            cellWidth: 25,
          },
          5: {
            halign: "left",
            fontSize: 13,
            cellPadding: {top: 3, right: 3, bottom: 3, left: 15},
            valign: "middle",
            cellWidth: 64,
          },
        };
        let imgPositions = [];
        if (Array.isArray(ketQuaHocTaps) && ketQuaHocTaps.length > 0) {
          imgPositions = ketQuaHocTaps.map((item, index) => ({
            row: index,
            column: 5,
            offsetX: 0,
            offsetY: 0,
            // images: item.pathChuKyGV !== null ? this.genHinhAnh(item.pathChuKyGV) : [],
            images: item.chuKys && item.chuKys[0] != null ? item.chuKys : [],
          }));
          console.log("imgPositions", imgPositions);
        }
        let currentPage = 1;
        let startY = 20
        const createTable = (data, showHeader) => {
          doc.autoTable({
            tableLineColor: [70, 76, 70],
            columnStyles: columnStyles,
            head: showHeader ? header : [],
            body: data,
            startY: startY,
            didDrawCell: (data) => {
              if (data.section === "body" && data.cell) {
                imgPositions.forEach((position) => {
                  if (
                      data.row.index === position.row &&
                      data.column.index === position.column
                  ) {
                    const imgWidth = 15;
                    const imgHeight = 9;
                    position?.images.forEach((image, index) => {
                      const imgX = data.cell.x + position.offsetX + 0.5;
                      let imgY =
                          data.cell.y + position.offsetY + 1 + (index == 0 ? 0 : index * 6);
                      if (image && image != "") {
                        doc.addImage(image, "JPEG", imgX, imgY, imgWidth, imgHeight);
                      }
                    });
                  }
                });
              }
            },
            didDrawPage: (data) => {
              if (currentPage > 1 && tenGiaoVienCount > 16) {
                doc.setFillColor(255, 255, 255);
                doc.rect(
                    data.settings.margin.left,
                    0,
                    doc.internal.pageSize.width -
                    data.settings.margin.left -
                    data.settings.margin.right,
                    data.settings.margin.top,
                    "F"
                );
              }
              currentPage++;
            },
            theme: "grid",
            headStyles: {
              fillColor: [255, 255, 255],
              textColor: [45, 45, 45],
              font: "TimesBold",
              fontStyle: "normal",
              lineWidth: 0.1,
              halign: "center",
              valign: "middle",
              fontSize: 13,
              cellPadding: 1,
            },
            styles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "TimesBold",
              fontStyle: "normal",
            },
            bodyStyles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "VNTimes",
              fontStyle: "normal",
              cellPadding: 1,
              fontSize: 13,
            },
            pageBreak: "auto",
          });
        };
        const pageSizeLimit = 18;
        const endY = doc.autoTable.previous.finalY;
        const tableHeight = endY - startY;
        console.log('tableHeight', tableHeight);
        console.log("tenGiaoVienCount", tenGiaoVienCount);
        if (tenGiaoVienCount > pageSizeLimit && tableHeight > 255) {
          const firstPart = body.slice(0, pageSizeLimit);
          const remainingPart = body.slice(pageSizeLimit);
          createTable(firstPart, true);
          doc.addPage();
          createTable(remainingPart, false);
        } else {
          createTable(body, true);
        }
        let tableLength = doc.lastAutoTable.finalY;
        doc.setFont("VNTimes");
        doc.text(
            "Trong bảng này có sửa chữa ở: Không chỗ, thuộc các môn học: ",
            15,
            tableLength + 5
        );
        //   Ký
        // Đoạn văn bản đầu tiên
        const text1 = "Xác nhận của Giáo viên chủ nhiệm";
        const text1Width = doc.getTextWidth(text1);
        const text1X = doc.internal.pageSize.width / 4 - text1Width / 2;
        const text1Y = tableLength + 17;
        doc.setFont("TimesBold");
        doc.text(text1, text1X, text1Y);
        const text2 = "(Ký và ghi rõ họ tên)";
        const text2Width = doc.getTextWidth(text2);
        const text2X = doc.internal.pageSize.width / 4 - text2Width / 2;
        const text2Y = text1Y + 5;
        doc.setFont("VNTimesItalic");
        doc.text(text2, text2X, text2Y);
        const imgWidth = 50;
        const imgHeight = 20;
        const imgX = text2X + text2Width / 2 - imgWidth / 2;
        const imgY = text2Y + 4;
        if (pathChuKyGVCN && !this.cauHinh.isAnAnhGVCN) {
          doc.addImage(pathChuKyGVCN, "JPEG", imgX, imgY, imgWidth, imgHeight);
        }
        const text3 = tenGVCN;
        if (text3) {
          const text3Width = doc.getTextWidth(text3);
          const text3X = imgX + imgWidth / 2 - text3Width / 2;
          const text3Y = imgY + imgHeight + 9;
          doc.setFont("TimesBold");
          doc.text(text3, text3X, text3Y);
        }

        const text4 = duLieu.tieuDeKyThayKQHT ? duLieu.tieuDeKyThayKQHT : "";
        // const text4 = 'Xác nhận của '+ (parseInt(capHocNhaTruong) == 6 ? 'Giám đốc' : 'Hiệu trưởng');
        const hasNewline = text4.includes("\n");
        const countNewlines = text4.match(/\n/g);
        const hasTwoNewlines = countNewlines && countNewlines.length >= 2;
        let lengthNew = 0;
        if (hasTwoNewlines) {
          lengthNew = 10;
        } else if (hasNewline) {
          lengthNew = 5;
        }
        const YTieuDe = tableLength + 17 - lengthNew;
        const text4Width = doc.getTextWidth(text4);
        let text4X = (doc.internal.pageSize.width * 3) / 4 - text4Width / 2;
        if (hasTwoNewlines) {
          text4X += 50;
        } else if (hasNewline) {
          text4X += 20;
        }
        const text4Y = YTieuDe;
// doc.setFont("TimesBoldItalic");
        doc.text(text4, text4X, text4Y);
        const text5 = "(Ký, ghi rõ họ tên và đóng dấu)";
        const text5Width = doc.getTextWidth(text5);
        const text5X = (doc.internal.pageSize.width * 3) / 4 - text5Width / 2;
        const text5Y = text4Y + 5 + lengthNew;
        doc.setFont("VNTimesItalic");
        doc.text(text5, text5X, text5Y);
        const img_Stamp_3_Width = this.imgStampWidth;
        const img_Stamp_3_Height = this.imgStampHeight;
        const stamp_3_X = text5X - 10;
        const img_Stamp_3_Y = text5Y - 12;
        if (pathDau && !this.cauHinh.isDau) {
          doc.addImage(
              pathDau,
              "JPEG",
              stamp_3_X,
              img_Stamp_3_Y,
              img_Stamp_3_Width,
              img_Stamp_3_Height
          );
        }
        const imgSignatureWidth = this.imgSignatureWidth;
        const imgSignatureHeight = this.imgSignatureHeight;
        const signatureX = stamp_3_X + img_Stamp_3_Width * (2 / 3);
        const signatureY = img_Stamp_3_Y + (img_Stamp_3_Height - imgSignatureHeight) / 2;
        if (pathChuKyHieuTruong && !this.cauHinh.isAnAnhHieuTruong) {
          doc.addImage(
              pathChuKyHieuTruong,
              "JPEG",
              signatureX,
              signatureY,
              imgSignatureWidth,
              imgSignatureHeight
          );
        }
        const text6 = duLieu.tenHieuTruong;
        if (text6) {
          const text6Width = doc.getTextWidth(text6);
          const text6X = (doc.internal.pageSize.width * 3) / 4 - text6Width / 2;
          const text6Y = img_Stamp_3_Y + img_Stamp_3_Height + 5;
          doc.setFont("TimesBold");
          doc.text(text6, text6X, text6Y);
        }
      } else if (pageNumber == 5) {
        let marginTop = 20; // 1,5cm
        let marginLeft = 14; // 2cm
        let marginRight = doc.internal.pageSize.getWidth() - 10; // 1cm từ lề phải
        let marginBottom = doc.internal.pageSize.getHeight() - 10; // 1cm từ lề dưới
        doc.setLineWidth(0.6);
        doc.line(marginLeft, marginTop, marginRight, marginTop); // Đường viền trên
        doc.line(marginLeft, marginTop, marginLeft, marginBottom); // Đường viền trái
        doc.line(marginRight, marginTop, marginRight, marginBottom); // Đường viền phải
        doc.line(marginLeft, marginBottom, marginRight, marginBottom); // Đường viền dưới
        doc.setFont("TimesBold");
        doc.setFontSize(13);
        if (this.isThongTu22) {
          doc.setFontSize(13);
          doc.setFont("TimesBoldItalic");
          doc.text("Họ và tên:", 15, 15);
          hoten.length > 15 ? doc.setFontSize(12) : doc.setFontSize(14);
          doc.text(hoten, 37, 15);
          doc.setFontSize(13);
          doc.text(`Lớp: ${tenLop}` ?? "", 110, 15);
          doc.setFontSize(13);
          doc.text(`Năm học: ${namHoc}` ?? "", 159, 15);
        } else {
          doc.setFont("TimesBoldItalic");
          let tenTruongKQHT = duLieu.tenTruong ? duLieu.tenTruong : "";
          if (tenTruongKQHT.length > 15) {
            const lastSpaceIndex = tenTruongKQHT.lastIndexOf(" ", 15);
            tenTruongKQHT =
                tenTruongKQHT.slice(0, lastSpaceIndex) +
                "\n" +
                tenTruongKQHT.slice(lastSpaceIndex + 1);
            doc.text(`Trường: `, 15, 15);
            doc.text(tenTruongKQHT, 35, 12.5);
          } else {
            doc.text(`Trường: ${tenTruongKQHT}`, 15, 15);
          }
          doc.text(`Huyện: ${tenHuyen}`, 80, 15);
          doc.text(`Tỉnh: ${tenTinh}`, 153, 15);
        }

        const nhanXet = this.isThongTu22
            ? `-Được lên lớp:\n${
                duLieu.lenThangLop || duLieu.lenLopSauThiLai || ""
            }\n\n-Không được lên lớp:\n\n${duLieu.khongDuocLenLop ?? ""}`
            : `Được lên lớp thẳng:\n${
                duLieu.lenThangLop ?? ""
            }\n\n Được lên lớp sau khi KT lại môn học hoặc rèn luyện thêm về HK:\n${
                duLieu.lenLopSauThiLai ?? ""
            }\n\nKhông được lên lớp:\n${duLieu.khongDuocLenLop}`;
        let header = [
          [
            {content: "Học kỳ", rowSpan: 2},
            {
              content: "Kết quả xếp loại",
              colSpan: 2,
            },
            {
              content: this.isThongTu22
                  ? "Tổng số buổi nghỉ học cả năm học"
                  : "TS buổi nghỉ học cả năm",
              rowSpan: 2,
            },
            {
              content: this.isThongTu22
                  ? "Mức đánh giá sau đánh giá lại môn học hoặc rèn luyện trong kì nghỉ hè (nếu có)"
                  : "Xếp loại sau KT lại các môn học hoặc rèn luyện thêm về HK",
              colSpan: 2,
            },
            {
              content: nhanXet,
              rowSpan: 5,
              styles: {fontStyle: "normal", halign: "left"},
            },
          ],
          [
            {content: this.isThongTu22 ? "Kết quả rèn luyện" : "HK", rowSpan: 1},
            {
              content: this.isThongTu22 ? "Kết quả học tập" : "HL",
              rowSpan: 1,
            },
            {content: this.isThongTu22 ? "Kết quả rèn luyện" : "HK", rowSpan: 1},
            {content: this.isThongTu22 ? "Kết quả học tập" : "HL", rowSpan: 1},
          ],
        ];
        let body = [
          [
            {content: "Học kỳ I", colSpan: 1},
            {content: hanhKiemHKI, colSpan: 1},
            {content: hocLucHKI, colSpan: 1},
            {
              content: soNgayNghi,
              rowSpan: 3,
            },
            {
              content: duLieu.hanhKiemSauThiLai ? duLieu.hanhKiemSauThiLai : "",
              rowSpan: 3,
            },
            {
              content: duLieu.hocLucSauThiLai ? duLieu.hocLucSauThiLai : "",
              rowSpan: 3,
            },
          ],
          [
            {content: "Học kỳ II", colSpan: 1},
            {content: hanhKiemHKII, colSpan: 1},
            {content: hocLucHKII, colSpan: 1},
          ],
          [
            {content: "Cả năm", colSpan: 1},
            {content: hanhKiemCaNam, colSpan: 1},
            {content: hocLucCN, colSpan: 1},
          ],
        ];
        let columnStyles = {
          0: {
            halign: "center",
            valign: "middle",
            fontSize: 13,
            cellWidth: 30.8,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
          },
          1: {
            halign: "center",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            cellWidth: 23,
          },
          2: {
            halign: "center",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            cellWidth: 21,
          },
          3: {
            halign: "center",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            cellWidth: 21,
          },
          4: {
            halign: "center",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            cellWidth: 23,
          },
          5: {
            halign: "left",
            fontSize: 13,
            cellPadding: {top: 3, right: 3, bottom: 3, left: 1},
            valign: "middle",
            cellWidth: 21,
          },
          6: {
            halign: "left",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 15},
            valign: "middle",
            cellWidth: 46,
          },
        };
        doc.autoTable({
          tableLineColor: [70, 76, 70],
          columnStyles: columnStyles,
          head: header,
          body: body,
          startY: 20,
          theme: "grid",
          headStyles: {
            fillColor: [255, 255, 255],
            textColor: [45, 45, 45],
            font: "TimesBold",
            fontStyle: "normal",
            lineWidth: 0.1,
            halign: "center",
            valign: "middle",
            fontSize: 13,
            cellPadding: 1,
          },
          styles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: "TimesBold",
            fontStyle: "normal",
          },
          bodyStyles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: "VNTimes",
            fontStyle: "normal",
            cellPadding: 1,
            fontSize: 13,
          },
        });
        let tableLength = doc.lastAutoTable.finalY;
        doc.setFont("VNTimesItalic");
        doc.setFontSize(12);
        const capHoc =
            capHoc == 2 || [6, 7, 8, 9].includes(khoiHoc)
                ? "trung học cơ sở: "
                : capHoc == 3 || [10, 11, 12].includes(khoiHoc)
                    ? "trung học phổ thông: "
                    : "";
        doc.text(
            `- Nếu là lớp cuối cấp thì ghi: Hoàn thành hay không hoàn thành chương trình ${capHoc} `,
            15,
            tableLength + 5
        );
        doc.setFont("VNTimes");
        doc.setFontSize(12);
        doc.text(chungChiNghe, 15, tableLength + 14);
        const loaiChungChi = duLieu.loaiChungChi;
        const loaiChungChiMap = {
          1: "Giỏi",
          2: "Khá",
          3: "Trung bình",
        };
        const loaiChungChiText = loaiChungChiMap[loaiChungChi] || "";
        doc.setFont("VNTimes");
        doc.setFontSize(12);
        doc.text(`Loại:${loaiChungChiText} `, 150, tableLength + 14);
        doc.setFont("VNTimes");
        const khenThuongCapHuyen = this.countCharacters(
            duLieu.khenThuongCapHuyen ? duLieu.khenThuongCapHuyen : "",
            "- Kết quả tham gia các cuộc thi (nếu có):  ",
            96
        );
        doc.text(khenThuongCapHuyen, 15, tableLength + 24);

        doc.setFont("VNTimes");
        doc.setFontSize(12);
        doc.text(khenThuong, 15, tableLength + 44);
        doc.setLineWidth(0.5);
        doc.line(marginLeft, tableLength + 50, marginRight, tableLength + 50);
        let fontSize = 13;
        if (this.isThongTu22) {
          doc.setFontSize(fontSize);
          let textHocBa = "KẾT QUẢ RÈN LUYỆN TRONG KÌ NGHỈ HÈ";
          let textWidth =
              (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
          doc.setFont("TimesBold");
          doc.text(textHocBa, (pageWidth - textWidth) / 2, tableLength + 55);
          if (noiDungRenLuyenTrongKyHe) {
            doc.setFont("VNTimes");
            doc.text(noiDungRenLuyenTrongKyHe, marginLeft + 2, tableLength + 60);
          } else {
            let textHocBa = "(Nếu có)";
            let textWidth =
                (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
            doc.setFont("VNTimesItalic");
            doc.text(textHocBa, (pageWidth - textWidth) / 2, tableLength + 60);
          }
          doc.line(marginLeft, tableLength + 75, marginRight, tableLength + 75);
          textHocBa = "NHẬN XÉT CỦA GIÁO VIÊN CHỦ NHIỆM";
          textWidth =
              (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
          doc.setFont("TimesBold");
          doc.text(textHocBa, (pageWidth - textWidth) / 2, tableLength + 80);
          doc.setFont("VNTimes");
          const nhanXetGVCN1 = this.formatTextWithBullets(duLieu.nhanXet, 5);
          console.log("nhanXetGVCN1", nhanXetGVCN1);
          doc.text(nhanXetGVCN1, 15, tableLength + 85);
          let text1 = "(Ký và ghi rõ họ tên )";
          let text1Width = doc.getTextWidth(text1);
          let text1X = (doc.internal.pageSize.width * 3) / 4 - text1Width / 2;
          const text1Y = tableLength + 110;
          doc.setFont("VNTimesItalic");
          doc.text(text1, text1X, text1Y);
          const text2Y = tableLength + 120;
          const imgSignatureWidth = this.imgSignatureWidth;
          const imgSignatureHeight = this.imgSignatureHeight;
          const imageX = (doc.internal.pageSize.width * 3) / 4 - imgSignatureWidth / 2;
          if (pathChuKyGVCN && !this.cauHinh.isAnAnhGVCN) {
            doc.addImage(
                pathChuKyGVCN,
                "JPEG",
                imageX,
                text2Y - imgSignatureHeight + 13,
                imgSignatureWidth,
                imgSignatureHeight
            );
          }
          let text2 = tenGVCN;
          if (text2) {
            let text2Width = doc.getTextWidth(text2);
            let text2X = (doc.internal.pageSize.width * 3) / 4 - text2Width / 2;
            doc.setFont("TimesBold");
            doc.text(text2, text2X, text2Y + 17);
          }
          doc.line(marginLeft, tableLength + 140, marginRight, tableLength + 140);
        } else {
          let textHocBa = "NHẬN XÉT CỦA GIÁO VIÊN CHỦ NHIỆM";
          let textWidth =
              (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
          doc.setFont("TimesBold");
          doc.text(textHocBa, (pageWidth - textWidth) / 2, tableLength + 55);
          const nhanXetGVCN = this.formatTextWithBullets(duLieu.nhanXet, 4);
          console.log("nhanXetGVCN", nhanXetGVCN);
          doc.setFont("VNTimes");
          doc.text(nhanXetGVCN, 15, tableLength + 62);
          let text1 = "(Ký và ghi rõ họ tên )";
          let text1Width = doc.getTextWidth(text1);
          let text1X = (doc.internal.pageSize.width * 3) / 4 - text1Width / 2;
          const text1Y = tableLength + 105;
          doc.setFont("VNTimesItalic");
          doc.text(text1, text1X, text1Y);
          const text2Y = tableLength + 135;
          const imgSignatureWidth = this.imgSignatureWidth;
          const imgSignatureHeight = this.imgSignatureHeight;
          const imageX = (doc.internal.pageSize.width * 3) / 4 - imgSignatureWidth / 2;
          if (pathChuKyGVCN && !this.cauHinh.isAnAnhGVCN) {
            doc.addImage(
                pathChuKyGVCN,
                "JPEG",
                imageX,
                text2Y - imgSignatureHeight - 5,
                imgSignatureWidth,
                imgSignatureHeight
            );
          }
          let text2 = tenGVCN;
          let text2Width = doc.getTextWidth(text2);
          let text2X = (doc.internal.pageSize.width * 3) / 4 - text2Width / 2;
          doc.setFont("TimesBold");
          doc.text(text2, text2X, text2Y);
          doc.line(marginLeft, tableLength + 140, marginRight, tableLength + 140);
        }
        let textHocBa = "PHÊ DUYỆT CỦA " + (parseInt(capHocNhaTruong) == 6 ? 'GIÁM ĐỐC' : 'HIỆU TRƯỞNG');
        let textWidth =
            (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
        doc.text(textHocBa, (pageWidth - textWidth) / 2, tableLength + 145);
        doc.setFont("VNTimes");
        if (!this.cauHinh.isAnNhanXet) {
          doc.text(nhanXetHieuTruong, 15, tableLength + 165);
        }
        let text2 = ngayHieuTruongKy;
        if (text2) {
          let text2Width = doc.getTextWidth(text2);
          let text2X = (doc.internal.pageSize.width * 3) / 4 - text2Width / 2 - 2;
          doc.setFont("VNTimesItalic");
          // In thời gian ký
          var textLines = doc.splitTextToSize(text2, pageWidth);
          var textWidths = doc.getStringUnitWidth(text2) * doc.internal.getFontSize() / doc.internal.scaleFactor;
          var x = pageWidth - textWidths - 15; // 10 là khoảng cách từ lề phải
          // Thêm văn bản với căn lề phải
          for (let i = 0; i < textLines.length; i++) {
            doc.text(textLines[i], x, tableLength + 152);
          }
          // Hết thời gian ký
          text2 = duLieu.tieuDeKyThayKQHT ? duLieu.tieuDeKyThayKQHT : "";
          let hasNewline = text2.includes("\n");
          let countNewlines = text2.match(/\n/g);
          let hasTwoNewlines = countNewlines && countNewlines.length >= 2;
          text2Width = doc.getTextWidth(text2);
          if (hasTwoNewlines) {
            text2X = (doc.internal.pageSize.width * 3) / 4 - text2Width / 2 + 50;
          } else if (hasNewline) {
            text2X = (doc.internal.pageSize.width * 3) / 4 - text2Width / 2 + 20;
          } else {
            text2X = (doc.internal.pageSize.width * 3) / 4 - text2Width / 2;
          }
          doc.setFont("TimesBold");
          doc.text(
              text2,
              text2X,
              !hasTwoNewlines ? tableLength + 157 : tableLength + 157
          );
        }
        let signText = "(Ký, ghi rõ họ tên và đóng dấu)";
        let signTextWidth = doc.getTextWidth(signText);
        let signTextX = (doc.internal.pageSize.width * 3) / 4 - signTextWidth / 2;
        const signTextY = tableLength + 170;
        doc.setFont("VNTimesItalic");
        text2 = duLieu.tieuDeKyThayKQHT ? duLieu.tieuDeKyThayKQHT : "";
        let hasNewline = text2.includes("\n");
        doc.text(signText, signTextX + 3, signTextY - (!hasNewline ? 8 : 3));
        const nameTextY = tableLength + 203;
        const stampImageWidth = 50;
        const stampImageHeight = 20;
        const stampImageX = (doc.internal.pageSize.width * 3) / 4 - stampImageWidth / 2;
        let nameText = duLieu.tenHieuTruong ? duLieu.tenHieuTruong : "";
        let nameTextWidth = doc.getTextWidth(nameText);
        let nameTextX = (doc.internal.pageSize.width * 3) / 4 - nameTextWidth / 2;
        doc.setFont("TimesBold");
        doc.text(nameText, nameTextX, nameTextY - 8);
        const imgStampWidth = this.imgStampWidth;
        const imgStampHeight = this.imgStampHeight;
        const stampX = nameTextX - imgStampWidth / 2 - 5;
        const stampY = nameTextY - imgStampHeight - 5;
        if (pathDau && !this.cauHinh.isDau) {
          doc.addImage(pathDau, "JPEG", stampX, stampY, imgStampWidth, imgStampHeight);
        }
        if (pathChuKyHieuTruong && !this.cauHinh.isAnAnhHieuTruong) {
          doc.addImage(
              pathChuKyHieuTruong,
              "JPEG",
              stampImageX + 4,
              stampY + imgStampHeight / 2 - stampImageHeight / 2 + 2,
              stampImageWidth,
              stampImageHeight
          );
        }
      } else if (pageNumber == 6) {
        const marginTop = 20; // 1,5cm
        const marginLeft = 14; // 2cm
        const marginRight = doc.internal.pageSize.getWidth() - 10; // 1cm từ lề phải
        const marginBottom = doc.internal.pageSize.getHeight() - 10; // 1cm từ lề dưới
        doc.setLineWidth(0.3);
        doc.line(marginLeft, marginTop, marginRight, marginTop); // Đường viền trên
        doc.line(marginLeft, marginTop, marginLeft, marginBottom); // Đường viền trái
        doc.line(marginRight, marginTop, marginRight, marginBottom); // Đường viền phải
        doc.line(marginLeft, marginBottom, marginRight, marginBottom); // Đường viền dưới
        const cap3ThongTu58 = !this.isThongTu22 && loaiHocBa == 3;
        if (cap3ThongTu58) {
          doc.setFont("TimesBoldItalic");
          doc.setFontSize(13);
          doc.text("Họ và tên:", 15, 10);
          doc.setFontSize(14);
          hoten.length > 15 ? doc.setFontSize(12) : doc.setFontSize(14);
          doc.text(hoten, 37, 10);
          doc.setFontSize(13);
          doc.text(`Lớp: ${tenLop}` ?? "", 110, 10);
          doc.setFontSize(13);
          doc.text(`Năm học: ${namHoc}` ?? "", 159, 10);
          doc.setFontSize(13);
          const phanBan = duLieu.phanBan ? duLieu.phanBan : "";
          doc.text(`Ban: ${phanBan}`, 15, 17);
          doc.setFontSize(13);
          const monHocPhanBan = duLieu.monHocPhanBan ? duLieu.monHocPhanBan : "";
          doc.text(`Môn chuyên: ${monHocPhanBan}`, 110, 17);
        } else {
          doc.setFont("TimesBoldItalic");
          doc.setFontSize(13);
          doc.text("Họ và tên:", 15, 15);
          hoten.length > 15 ? doc.setFontSize(12) : doc.setFontSize(14);
          doc.text(hoten, 37, 15);
          doc.setFontSize(13);
          doc.text(`Lớp: ${tenLop}` ?? "", 110, 15);
          doc.setFontSize(13);
          doc.text(`Năm học: ${namHoc}` ?? "", 159, 15);
        }
        let header = [
          [
            {content: "Môn học/Hoạt động giáo dục", rowSpan: 2},
            {
              content: "Điểm trung bình môn học hoặc mức đánh giá",
              colSpan: 3,
            },
            {
              content: this.isThongTu22
                  ? "Điểm trung bình môn học hoặc mức đánh giá lại sau đánh giá lại, rèn luyện thêm trong kỳ nghỉ hè (nếu có)"
                  : "Điểm hoặc xếp loại sau KT lại (nếu có)",
              rowSpan: 2,
              styles: this.isThongTu22 ? {fontSize: 10} : {},
            },
            {
              content: this.isThongTu22
                  ? "Nhận xét sự tiến bộ, ưu điểm nổi bật, hạn chế chủ yếu (nếu có) và chữ kí của giáo viên môn học"
                  : "Giáo viên bộ môn ký xác nhận ĐTB môn học hoặc xếp loại và sửa chữa (nếu có), (Ký và ghi rõ họ tên) ",
              rowSpan: 2,
            },
          ],
          [
            {content: "H Kỳ I", rowSpan: 1},
            {content: "H Kỳ II", rowSpan: 1},
            {content: "CN", rowSpan: 1},
          ],
        ];
        const ketQuaHocTaps = duLieu.ketQuaHocTaps;
        let body = [];
        const handleNull = (value) => (value !== null ? value : "");
        let tenGiaoVienCount = 0;
        if (Array.isArray(ketQuaHocTaps) && ketQuaHocTaps.length > 0) {
          body = ketQuaHocTaps.map((item) => {
            if (item.tenGiaoVien) {
              const giaoVienList = item.tenGiaoVien
                  .split("\n")
                  .filter((name) => name.trim() !== "");
              tenGiaoVienCount += giaoVienList.length;
            }
            return [
              {content: handleNull(item.tenMonHoc), colSpan: 1},
              {content: handleNull(item.diemHocKyI), colSpan: 1},
              {content: handleNull(item.diemHocKyII), colSpan: 1},
              {content: handleNull(item.diemCaNam), colSpan: 1},
              {content: handleNull(item.diemThiLai), colSpan: 1},
              {content: handleNull(item.tenGiaoVien), colSpan: 1},
            ];
          });
        }

        let columnStyles = {
          0: {
            halign: "center",
            valign: "middle",
            fontSize: 13,
            cellWidth: 45.8,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
          },
          1: {
            halign: "center",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            cellWidth: 17,
          },
          2: {
            halign: "center",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            cellWidth: 17,
          },
          3: {
            halign: "center",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            cellWidth: 17,
          },
          4: {
            halign: "center",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            cellWidth: 25,
          },
          5: {
            halign: "left",
            fontSize: 13,
            cellPadding: {top: 3, right: 3, bottom: 3, left: 15},
            valign: "middle",
            cellWidth: 64,
          },
        };
        let imgPositions = [];
        if (Array.isArray(ketQuaHocTaps) && ketQuaHocTaps.length > 0) {
          imgPositions = ketQuaHocTaps.map((item, index) => ({
            row: index,
            column: 5,
            offsetX: 0,
            offsetY: 0,
            // images: item.pathChuKyGV !== null ? this.genHinhAnh(item.pathChuKyGV) : [],
            images: item.chuKys && item.chuKys[0] != null ? item.chuKys : [],
          }));
          console.log("imgPositions", imgPositions);
        }
        let currentPage = 1;
        let startY = 20
        const createTable = (data, showHeader) => {
          doc.autoTable({
            tableLineColor: [70, 76, 70],
            columnStyles: columnStyles,
            head: showHeader ? header : [],
            body: data,
            startY: startY,
            didDrawCell: (data) => {
              if (data.section === "body" && data.cell) {
                imgPositions.forEach((position) => {
                  if (
                      data.row.index === position.row &&
                      data.column.index === position.column
                  ) {
                    const imgWidth = 15;
                    const imgHeight = 9;
                    position?.images.forEach((image, index) => {
                      const imgX = data.cell.x + position.offsetX + 0.5;
                      let imgY =
                          data.cell.y + position.offsetY + 1 + (index == 0 ? 0 : index * 6);
                      if (image && image != "") {
                        doc.addImage(image, "JPEG", imgX, imgY, imgWidth, imgHeight);
                      }
                    });
                  }
                });
              }
            },
            didDrawPage: (data) => {
              if (currentPage > 1 && tenGiaoVienCount > 16) {
                doc.setFillColor(255, 255, 255);
                doc.rect(
                    data.settings.margin.left,
                    0,
                    doc.internal.pageSize.width -
                    data.settings.margin.left -
                    data.settings.margin.right,
                    data.settings.margin.top,
                    "F"
                );
              }
              currentPage++;
            },
            theme: "grid",
            headStyles: {
              fillColor: [255, 255, 255],
              textColor: [45, 45, 45],
              font: "TimesBold",
              fontStyle: "normal",
              lineWidth: 0.1,
              halign: "center",
              valign: "middle",
              fontSize: 13,
              cellPadding: 1,
            },
            styles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "TimesBold",
              fontStyle: "normal",
            },
            bodyStyles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "VNTimes",
              fontStyle: "normal",
              cellPadding: 1,
              fontSize: 13,
            },
            pageBreak: "auto",
          });
        };
        const pageSizeLimit = 18;
        const endY = doc.autoTable.previous.finalY;
        const tableHeight = endY - startY;
        console.log("tenGiaoVienCount", tenGiaoVienCount);
        if (tenGiaoVienCount > pageSizeLimit && tableHeight > 262) {
          const firstPart = body.slice(0, pageSizeLimit);
          const remainingPart = body.slice(pageSizeLimit);
          createTable(firstPart, true);
          doc.addPage();
          createTable(remainingPart, false);
        } else {
          createTable(body, true);
        }
        let tableLength = doc.lastAutoTable.finalY;
        doc.setFont("VNTimes");
        doc.text(
            "Trong bảng này có sửa chữa ở: Không chỗ, thuộc các môn học: ",
            15,
            tableLength + 5
        );
        //   Ký
        // Đoạn văn bản đầu tiên
        const text1 = "Xác nhận của Giáo viên chủ nhiệm";
        const text1Width = doc.getTextWidth(text1);
        const text1X = doc.internal.pageSize.width / 4 - text1Width / 2;
        const text1Y = tableLength + 17;
        doc.setFont("TimesBold");
        doc.text(text1, text1X, text1Y);
        const text2 = "(Ký và ghi rõ họ tên)";
        const text2Width = doc.getTextWidth(text2);
        const text2X = doc.internal.pageSize.width / 4 - text2Width / 2;
        const text2Y = text1Y + 5;
        doc.setFont("VNTimesItalic");
        doc.text(text2, text2X, text2Y);
        const imgWidth = 50;
        const imgHeight = 20;
        const imgX = text2X + text2Width / 2 - imgWidth / 2;
        const imgY = text2Y + 4;
        if (pathChuKyGVCN && !this.cauHinh.isAnAnhGVCN) {
          doc.addImage(pathChuKyGVCN, "JPEG", imgX, imgY, imgWidth, imgHeight);
        }
        const text3 = tenGVCN;
        if (text3) {
          const text3Width = doc.getTextWidth(text3);
          const text3X = imgX + imgWidth / 2 - text3Width / 2;
          const text3Y = imgY + imgHeight + 9;
          doc.setFont("TimesBold");
          doc.text(text3, text3X, text3Y);
        }
        const text4 = duLieu.tieuDeKyThayKQHT ? duLieu.tieuDeKyThayKQHT : "";
        // const text4 = 'Xác nhận của '+ (parseInt(capHocNhaTruong) == 6 ? 'Giám đốc' : 'Hiệu trưởng');
        const hasNewline = text4.includes("\n");
        const countNewlines = text4.match(/\n/g);
        const hasTwoNewlines = countNewlines && countNewlines.length >= 2;
        let lengthNew = 0;
        if (hasTwoNewlines) {
          lengthNew = 10;
        } else if (hasNewline) {
          lengthNew = 5;
        }
        const YTieuDe = tableLength + 17 - lengthNew;
        const text4Width = doc.getTextWidth(text4);
        let text4X = (doc.internal.pageSize.width * 3) / 4 - text4Width / 2;
        if (hasTwoNewlines) {
          text4X += 50;
        } else if (hasNewline) {
          text4X += 20;
        }
        const text4Y = YTieuDe;
// doc.setFont("TimesBoldItalic");
        doc.text(text4, text4X, text4Y);
        const text5 = "(Ký, ghi rõ họ tên và đóng dấu)";
        const text5Width = doc.getTextWidth(text5);
        const text5X = (doc.internal.pageSize.width * 3) / 4 - text5Width / 2;
        const text5Y = text4Y + 5 + lengthNew;
        doc.setFont("VNTimesItalic");
        doc.text(text5, text5X, text5Y);
        const img_Stamp_3_Width = this.imgStampWidth;
        const img_Stamp_3_Height = this.imgStampHeight;
        const stamp_3_X = text5X - 10;
        const img_Stamp_3_Y = text5Y - 12;
        if (pathDau && !this.cauHinh.isDau) {
          doc.addImage(
              pathDau,
              "JPEG",
              stamp_3_X,
              img_Stamp_3_Y,
              img_Stamp_3_Width,
              img_Stamp_3_Height
          );
        }
        const imgSignatureWidth = this.imgSignatureWidth;
        const imgSignatureHeight = this.imgSignatureHeight;
        const signatureX = stamp_3_X + img_Stamp_3_Width * (2 / 3);
        const signatureY = img_Stamp_3_Y + (img_Stamp_3_Height - imgSignatureHeight) / 2;
        if (pathChuKyHieuTruong && !this.cauHinh.isAnAnhHieuTruong) {
          doc.addImage(
              pathChuKyHieuTruong,
              "JPEG",
              signatureX,
              signatureY,
              imgSignatureWidth,
              imgSignatureHeight
          );
        }
        const text6 = duLieu.tenHieuTruong;
        if (text6) {
          const text6Width = doc.getTextWidth(text6);
          const text6X = (doc.internal.pageSize.width * 3) / 4 - text6Width / 2;
          const text6Y = img_Stamp_3_Y + img_Stamp_3_Height + 5;
          doc.setFont("TimesBold");
          doc.text(text6, text6X, text6Y);
        }
      } else if (pageNumber == 7) {
        let marginTop = 20; // 1,5cm
        let marginLeft = 14; // 2cm
        let marginRight = doc.internal.pageSize.getWidth() - 10; // 1cm từ lề phải
        let marginBottom = doc.internal.pageSize.getHeight() - 10; // 1cm từ lề dưới
        doc.setLineWidth(0.6);
        doc.line(marginLeft, marginTop, marginRight, marginTop); // Đường viền trên
        doc.line(marginLeft, marginTop, marginLeft, marginBottom); // Đường viền trái
        doc.line(marginRight, marginTop, marginRight, marginBottom); // Đường viền phải
        doc.line(marginLeft, marginBottom, marginRight, marginBottom); // Đường viền dưới
        doc.setFont("TimesBold");
        doc.setFontSize(13);
        if (this.isThongTu22) {
          doc.setFontSize(13);
          doc.text("Họ và tên:", 15, 15);
          doc.setFont("TimesBoldItalic");
          hoten.length > 15 ? doc.setFontSize(12) : doc.setFontSize(14);
          doc.text(hoten, 37, 15);
          doc.setFontSize(13);
          doc.text(`Lớp: ${tenLop}` ?? "", 110, 15);
          doc.setFontSize(13);
          doc.text(`Năm học: ${namHoc}` ?? "", 159, 15);
        } else {
          doc.setFont("TimesBoldItalic");
          let tenTruongKQHT = duLieu.tenTruong ? duLieu.tenTruong : "";
          if (tenTruongKQHT.length > 15) {
            const lastSpaceIndex = tenTruongKQHT.lastIndexOf(" ", 15);
            tenTruongKQHT =
                tenTruongKQHT.slice(0, lastSpaceIndex) +
                "\n" +
                tenTruongKQHT.slice(lastSpaceIndex + 1);
            doc.text(`Trường: `, 15, 15);
            doc.text(tenTruongKQHT, 35, 12.5);
          } else {
            doc.text(`Trường: ${tenTruongKQHT}`, 15, 15);
          }
          doc.text(`Huyện: ${tenHuyen}`, 80, 15);
          doc.text(`Tỉnh: ${tenTinh}`, 153, 15);
        }

        const nhanXet = this.isThongTu22
            ? `-Được lên lớp:\n${
                duLieu.lenThangLop || duLieu.lenLopSauThiLai || ""
            }\n\n-Không được lên lớp:\n\n${duLieu.khongDuocLenLop ?? ""}`
            : `Được lên lớp thẳng:\n${
                duLieu.lenThangLop ?? ""
            }\n\n Được lên lớp sau khi KT lại môn học hoặc rèn luyện thêm về HK:\n${
                duLieu.lenLopSauThiLai ?? ""
            }\n\nKhông được lên lớp:\n${duLieu.khongDuocLenLop}`;
        let header = [
          [
            {content: "Học kỳ", rowSpan: 2},
            {
              content: "Kết quả xếp loại",
              colSpan: 2,
            },
            {
              content: this.isThongTu22
                  ? "Tổng số buổi nghỉ học cả năm học"
                  : "TS buổi nghỉ học cả năm",
              rowSpan: 2,
            },
            {
              content: this.isThongTu22
                  ? "Mức đánh giá sau đánh giá lại môn học hoặc rèn luyện trong kì nghỉ hè (nếu có)"
                  : "Xếp loại sau KT lại các môn học hoặc rèn luyện thêm về HK",
              colSpan: 2,
            },
            {
              content: nhanXet,
              rowSpan: 5,
              styles: {fontStyle: "normal", halign: "left"},
            },
          ],
          [
            {content: this.isThongTu22 ? "Kết quả rèn luyện" : "HK", rowSpan: 1},
            {
              content: this.isThongTu22 ? "Kết quả học tập" : "HL",
              rowSpan: 1,
            },
            {content: this.isThongTu22 ? "Kết quả rèn luyện" : "HK", rowSpan: 1},
            {content: this.isThongTu22 ? "Kết quả học tập" : "HL", rowSpan: 1},
          ],
        ];
        let body = [
          [
            {content: "Học kỳ I", colSpan: 1},
            {content: hanhKiemHKI, colSpan: 1},
            {content: hocLucHKI, colSpan: 1},
            {
              content: soNgayNghi,
              rowSpan: 3,
            },
            {
              content: duLieu.hanhKiemSauThiLai ? duLieu.hanhKiemSauThiLai : "",
              rowSpan: 3,
            },
            {
              content: duLieu.hocLucSauThiLai ? duLieu.hocLucSauThiLai : "",
              rowSpan: 3,
            },
          ],
          [
            {content: "Học kỳ II", colSpan: 1},
            {content: hanhKiemHKII, colSpan: 1},
            {content: hocLucHKII, colSpan: 1},
          ],
          [
            {content: "Cả năm", colSpan: 1},
            {content: hanhKiemCaNam, colSpan: 1},
            {content: hocLucCN, colSpan: 1},
          ],
        ];
        let columnStyles = {
          0: {
            halign: "center",
            valign: "middle",
            fontSize: 13,
            cellWidth: 30.8,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
          },
          1: {
            halign: "center",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            cellWidth: 23,
          },
          2: {
            halign: "center",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            cellWidth: 21,
          },
          3: {
            halign: "center",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            cellWidth: 21,
          },
          4: {
            halign: "center",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            cellWidth: 23,
          },
          5: {
            halign: "left",
            fontSize: 13,
            cellPadding: {top: 3, right: 3, bottom: 3, left: 1},
            valign: "middle",
            cellWidth: 21,
          },
          6: {
            halign: "left",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 15},
            valign: "middle",
            cellWidth: 46,
          },
        };
        doc.autoTable({
          tableLineColor: [70, 76, 70],
          columnStyles: columnStyles,
          head: header,
          body: body,
          startY: 20,
          theme: "grid",
          headStyles: {
            fillColor: [255, 255, 255],
            textColor: [45, 45, 45],
            font: "TimesBold",
            fontStyle: "normal",
            lineWidth: 0.1,
            halign: "center",
            valign: "middle",
            fontSize: 13,
            cellPadding: 1,
          },
          styles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: "TimesBold",
            fontStyle: "normal",
          },
          bodyStyles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: "VNTimes",
            fontStyle: "normal",
            cellPadding: 1,
            fontSize: 13,
          },
        });
        let tableLength = doc.lastAutoTable.finalY;
        doc.setFont("VNTimesItalic");
        doc.setFontSize(12);
        const capHoc =
            capHoc == 2 || [6, 7, 8, 9].includes(khoiHoc)
                ? "trung học cơ sở: "
                : capHoc == 3 || [10, 11, 12].includes(khoiHoc)
                    ? "trung học phổ thông: "
                    : "";
        doc.text(
            `- Nếu là lớp cuối cấp thì ghi: Hoàn thành hay không hoàn thành chương trình ${capHoc} `,
            15,
            tableLength + 5
        );
        doc.setFont("VNTimes");
        doc.setFontSize(12);
        doc.text(chungChiNghe, 15, tableLength + 14);
        const loaiChungChi = duLieu.loaiChungChi;
        const loaiChungChiMap = {
          1: "Giỏi",
          2: "Khá",
          3: "Trung bình",
        };
        const loaiChungChiText = loaiChungChiMap[loaiChungChi] || "";
        doc.setFont("VNTimes");
        doc.setFontSize(12);
        doc.text(`Loại:${loaiChungChiText} `, 150, tableLength + 14);
        doc.setFont("VNTimes");
        const khenThuongCapHuyen = this.countCharacters(
            duLieu.khenThuongCapHuyen ? duLieu.khenThuongCapHuyen : "",
            "- Kết quả tham gia các cuộc thi (nếu có):  ",
            96
        );
        doc.text(khenThuongCapHuyen, 15, tableLength + 24);

        doc.setFont("VNTimes");
        doc.setFontSize(12);
        doc.text(khenThuong, 15, tableLength + 44);
        doc.setLineWidth(0.5);
        doc.line(marginLeft, tableLength + 50, marginRight, tableLength + 50);
        let fontSize = 13;
        if (this.isThongTu22) {
          doc.setFontSize(fontSize);
          let textHocBa = "KẾT QUẢ RÈN LUYỆN TRONG KÌ NGHỈ HÈ";
          let textWidth =
              (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
          doc.setFont("TimesBold");
          doc.text(textHocBa, (pageWidth - textWidth) / 2, tableLength + 55);
          if (noiDungRenLuyenTrongKyHe) {
            doc.setFont("VNTimes");
            doc.text(noiDungRenLuyenTrongKyHe, marginLeft + 2, tableLength + 60);
          } else {
            let textHocBa = "(Nếu có)";
            let textWidth =
                (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
            doc.setFont("VNTimesItalic");
            doc.text(textHocBa, (pageWidth - textWidth) / 2, tableLength + 60);
          }
          doc.line(marginLeft, tableLength + 75, marginRight, tableLength + 75);
          textHocBa = "NHẬN XÉT CỦA GIÁO VIÊN CHỦ NHIỆM";
          textWidth =
              (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
          doc.setFont("TimesBold");
          doc.text(textHocBa, (pageWidth - textWidth) / 2, tableLength + 80);
          doc.setFont("VNTimes");
          const nhanXetGVCN1 = this.formatTextWithBullets(duLieu.nhanXet, 5);
          console.log("nhanXetGVCN1", nhanXetGVCN1);
          doc.text(nhanXetGVCN1, 15, tableLength + 85);
          let text1 = "(Ký và ghi rõ họ tên )";
          let text1Width = doc.getTextWidth(text1);
          let text1X = (doc.internal.pageSize.width * 3) / 4 - text1Width / 2;
          const text1Y = tableLength + 110;
          doc.setFont("VNTimesItalic");
          doc.text(text1, text1X, text1Y);
          const text2Y = tableLength + 120;
          const imgSignatureWidth = this.imgSignatureWidth;
          const imgSignatureHeight = this.imgSignatureHeight;
          const imageX = (doc.internal.pageSize.width * 3) / 4 - imgSignatureWidth / 2;
          if (pathChuKyGVCN && !this.cauHinh.isAnAnhGVCN) {
            doc.addImage(
                pathChuKyGVCN,
                "JPEG",
                imageX,
                text2Y - imgSignatureHeight + 13,
                imgSignatureWidth,
                imgSignatureHeight
            );
          }
          let text2 = tenGVCN;
          if (text2) {
            let text2Width = doc.getTextWidth(text2);
            let text2X = (doc.internal.pageSize.width * 3) / 4 - text2Width / 2;
            doc.setFont("TimesBold");
            doc.text(text2, text2X, text2Y + 17);
          }
          doc.line(marginLeft, tableLength + 140, marginRight, tableLength + 140);
        } else {
          let textHocBa = "NHẬN XÉT CỦA GIÁO VIÊN CHỦ NHIỆM";
          let textWidth =
              (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
          doc.setFont("TimesBold");
          doc.text(textHocBa, (pageWidth - textWidth) / 2, tableLength + 55);
          const nhanXetGVCN = this.formatTextWithBullets(duLieu.nhanXet, 4);
          console.log("nhanXetGVCN", nhanXetGVCN);
          doc.setFont("VNTimes");
          doc.text(nhanXetGVCN, 15, tableLength + 62);
          let text1 = "(Ký và ghi rõ họ tên )";
          let text1Width = doc.getTextWidth(text1);
          let text1X = (doc.internal.pageSize.width * 3) / 4 - text1Width / 2;
          const text1Y = tableLength + 105;
          doc.setFont("VNTimesItalic");
          doc.text(text1, text1X, text1Y);
          const text2Y = tableLength + 135;
          const imgSignatureWidth = this.imgSignatureWidth;
          const imgSignatureHeight = this.imgSignatureHeight;
          const imageX = (doc.internal.pageSize.width * 3) / 4 - imgSignatureWidth / 2;
          if (pathChuKyGVCN && !this.cauHinh.isAnAnhGVCN) {
            doc.addImage(
                pathChuKyGVCN,
                "JPEG",
                imageX,
                text2Y - imgSignatureHeight - 5,
                imgSignatureWidth,
                imgSignatureHeight
            );
          }
          let text2 = tenGVCN;
          let text2Width = doc.getTextWidth(text2);
          let text2X = (doc.internal.pageSize.width * 3) / 4 - text2Width / 2;
          doc.setFont("TimesBold");
          doc.text(text2, text2X, text2Y);
          doc.line(marginLeft, tableLength + 140, marginRight, tableLength + 140);
        }
        let textHocBa = "PHÊ DUYỆT CỦA " + (parseInt(capHocNhaTruong) == 6 ? 'GIÁM ĐỐC' : 'HIỆU TRƯỞNG');
        let textWidth =
            (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
        doc.text(textHocBa, (pageWidth - textWidth) / 2, tableLength + 145);
        doc.setFont("VNTimes");
        if (!this.cauHinh.isAnNhanXet) {
          doc.text(nhanXetHieuTruong, 15, tableLength + 165);
        }
        let text2 = ngayHieuTruongKy;
        if (text2) {
          let text2Width = doc.getTextWidth(text2);
          let text2X = (doc.internal.pageSize.width * 3) / 4 - text2Width / 2 - 2;
          doc.setFont("VNTimesItalic");
          // In thời gian ký
          var textLines = doc.splitTextToSize(text2, pageWidth);
          var textWidths = doc.getStringUnitWidth(text2) * doc.internal.getFontSize() / doc.internal.scaleFactor;
          var x = pageWidth - textWidths - 15; // 10 là khoảng cách từ lề phải
          // Thêm văn bản với căn lề phải
          for (let i = 0; i < textLines.length; i++) {
            doc.text(textLines[i], x, tableLength + 152);
          }
          // doc.text(text2+' demo nè', text2X, tableLength + 150);
          // Hết thời gian ký
          text2 = duLieu.tieuDeKyThayKQHT ? duLieu.tieuDeKyThayKQHT : "";
          let hasNewline = text2.includes("\n");
          let countNewlines = text2.match(/\n/g);
          let hasTwoNewlines = countNewlines && countNewlines.length >= 2;
          text2Width = doc.getTextWidth(text2);
          if (hasTwoNewlines) {
            text2X = (doc.internal.pageSize.width * 3) / 4 - text2Width / 2 + 50;
          } else if (hasNewline) {
            text2X = (doc.internal.pageSize.width * 3) / 4 - text2Width / 2 + 20;
          } else {
            text2X = (doc.internal.pageSize.width * 3) / 4 - text2Width / 2;
          }
          doc.setFont("TimesBold");
          doc.text(
              text2,
              text2X,
              !hasTwoNewlines ? tableLength + 157 : tableLength + 157
          );
        }
        let signText = "(Ký, ghi rõ họ tên và đóng dấu)";
        let signTextWidth = doc.getTextWidth(signText);
        let signTextX = (doc.internal.pageSize.width * 3) / 4 - signTextWidth / 2;
        const signTextY = tableLength + 170;
        doc.setFont("VNTimesItalic");
        text2 = duLieu.tieuDeKyThayKQHT ? duLieu.tieuDeKyThayKQHT : "";
        let hasNewline = text2.includes("\n");
        doc.text(signText, signTextX + 3, signTextY - (!hasNewline ? 8 : 3));
        const nameTextY = tableLength + 203;
        const stampImageWidth = 50;
        const stampImageHeight = 20;
        const stampImageX = (doc.internal.pageSize.width * 3) / 4 - stampImageWidth / 2;
        let nameText = duLieu.tenHieuTruong ? duLieu.tenHieuTruong : "";
        let nameTextWidth = doc.getTextWidth(nameText);
        let nameTextX = (doc.internal.pageSize.width * 3) / 4 - nameTextWidth / 2;
        doc.setFont("TimesBold");
        doc.text(nameText, nameTextX, nameTextY - 8);
        const imgStampWidth = this.imgStampWidth;
        const imgStampHeight = this.imgStampHeight;
        const stampX = nameTextX - imgStampWidth / 2 - 5;
        const stampY = nameTextY - imgStampHeight - 5;
        if (pathDau && !this.cauHinh.isDau) {
          doc.addImage(pathDau, "JPEG", stampX, stampY, imgStampWidth, imgStampHeight);
        }
        if (pathChuKyHieuTruong && !this.cauHinh.isAnAnhHieuTruong) {
          doc.addImage(
              pathChuKyHieuTruong,
              "JPEG",
              stampImageX + 4,
              stampY + imgStampHeight / 2 - stampImageHeight / 2 + 2,
              stampImageWidth,
              stampImageHeight
          );
        }
      } else if (pageNumber == 8) {
        const marginTop = 20; // 1,5cm
        const marginLeft = 14; // 2cm
        const marginRight = doc.internal.pageSize.getWidth() - 10; // 1cm từ lề phải
        const marginBottom = doc.internal.pageSize.getHeight() - 10; // 1cm từ lề dưới
        doc.setLineWidth(0.3);
        doc.line(marginLeft, marginTop, marginRight, marginTop); // Đường viền trên
        doc.line(marginLeft, marginTop, marginLeft, marginBottom); // Đường viền trái
        doc.line(marginRight, marginTop, marginRight, marginBottom); // Đường viền phải
        doc.line(marginLeft, marginBottom, marginRight, marginBottom); // Đường viền dưới
        const cap3ThongTu58 = !this.isThongTu22 && loaiHocBa == 3;
        if (cap3ThongTu58) {
          doc.setFont("TimesBoldItalic");
          doc.setFontSize(13);
          doc.text("Họ và tên:", 15, 10);
          doc.setFontSize(14);
          hoten.length > 15 ? doc.setFontSize(12) : doc.setFontSize(14);
          doc.text(hoten, 37, 10);
          doc.setFontSize(13);
          doc.text(`Lớp: ${tenLop}` ?? "", 110, 10);
          doc.setFontSize(13);
          doc.text(`Năm học: ${namHoc}` ?? "", 159, 10);
          doc.setFontSize(13);
          const phanBan = duLieu.phanBan ? duLieu.phanBan : "";
          doc.text(`Ban: ${phanBan}`, 15, 17);
          doc.setFontSize(13);
          const monHocPhanBan = duLieu.monHocPhanBan ? duLieu.monHocPhanBan : "";
          doc.text(`Môn chuyên: ${monHocPhanBan}`, 110, 17);
        } else {
          doc.setFont("TimesBoldItalic");
          doc.setFontSize(13);
          doc.text("Họ và tên:", 15, 15);
          hoten.length > 15 ? doc.setFontSize(12) : doc.setFontSize(14);
          doc.text(hoten, 37, 15);
          doc.setFontSize(13);
          doc.text(`Lớp: ${tenLop}` ?? "", 110, 15);
          doc.setFontSize(13);
          doc.text(`Năm học: ${namHoc}` ?? "", 159, 15);
        }
        let header = [
          [
            {content: "Môn học/Hoạt động giáo dục", rowSpan: 2},
            {
              content: "Điểm trung bình môn học hoặc mức đánh giá",
              colSpan: 3,
            },
            {
              content: this.isThongTu22
                  ? "Điểm trung bình môn học hoặc mức đánh giá lại sau đánh giá lại, rèn luyện thêm trong kỳ nghỉ hè (nếu có)"
                  : "Điểm hoặc xếp loại sau KT lại (nếu có)",
              rowSpan: 2,
              styles: this.isThongTu22 ? {fontSize: 10} : {},
            },
            {
              content: this.isThongTu22
                  ? "Nhận xét sự tiến bộ, ưu điểm nổi bật, hạn chế chủ yếu (nếu có) và chữ kí của giáo viên môn học"
                  : "Giáo viên bộ môn ký xác nhận ĐTB môn học hoặc xếp loại và sửa chữa (nếu có), (Ký và ghi rõ họ tên) ",
              rowSpan: 2,
            },
          ],
          [
            {content: "H Kỳ I", rowSpan: 1},
            {content: "H Kỳ II", rowSpan: 1},
            {content: "CN", rowSpan: 1},
          ],
        ];
        const ketQuaHocTaps = duLieu.ketQuaHocTaps;
        let body = [];
        const handleNull = (value) => (value !== null ? value : "");
        let tenGiaoVienCount = 0;
        if (Array.isArray(ketQuaHocTaps) && ketQuaHocTaps.length > 0) {
          body = ketQuaHocTaps.map((item) => {
            if (item.tenGiaoVien) {
              const giaoVienList = item.tenGiaoVien
                  .split("\n")
                  .filter((name) => name.trim() !== "");
              tenGiaoVienCount += giaoVienList.length;
            }
            return [
              {content: handleNull(item.tenMonHoc), colSpan: 1},
              {content: handleNull(item.diemHocKyI), colSpan: 1},
              {content: handleNull(item.diemHocKyII), colSpan: 1},
              {content: handleNull(item.diemCaNam), colSpan: 1},
              {content: handleNull(item.diemThiLai), colSpan: 1},
              {content: handleNull(item.tenGiaoVien), colSpan: 1},
            ];
          });
        }

        let columnStyles = {
          0: {
            halign: "center",
            valign: "middle",
            fontSize: 13,
            cellWidth: 45.8,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
          },
          1: {
            halign: "center",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            cellWidth: 17,
          },
          2: {
            halign: "center",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            cellWidth: 17,
          },
          3: {
            halign: "center",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            cellWidth: 17,
          },
          4: {
            halign: "center",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            cellWidth: 25,
          },
          5: {
            halign: "left",
            fontSize: 13,
            cellPadding: {top: 3, right: 3, bottom: 3, left: 15},
            valign: "middle",
            cellWidth: 64,
          },
        };
        let imgPositions = [];
        if (Array.isArray(ketQuaHocTaps) && ketQuaHocTaps.length > 0) {
          imgPositions = ketQuaHocTaps.map((item, index) => ({
            row: index,
            column: 5,
            offsetX: 0,
            offsetY: 0,
            // images: item.pathChuKyGV !== null ? this.genHinhAnh(item.pathChuKyGV) : [],
            images: item.chuKys && item.chuKys[0] != null ? item.chuKys : [],
          }));
          console.log("imgPositions", imgPositions);
        }
        let currentPage = 1;
        let startY = 20
        const createTable = (data, showHeader) => {
          doc.autoTable({
            tableLineColor: [70, 76, 70],
            columnStyles: columnStyles,
            head: showHeader ? header : [],
            body: data,
            startY: startY,
            didDrawCell: (data) => {
              if (data.section === "body" && data.cell) {
                imgPositions.forEach((position) => {
                  if (
                      data.row.index === position.row &&
                      data.column.index === position.column
                  ) {
                    const imgWidth = 15;
                    const imgHeight = 9;
                    position?.images.forEach((image, index) => {
                      const imgX = data.cell.x + position.offsetX + 0.5;
                      let imgY =
                          data.cell.y + position.offsetY + 1 + (index == 0 ? 0 : index * 6);
                      if (image && image != "") {
                        doc.addImage(image, "JPEG", imgX, imgY, imgWidth, imgHeight);
                      }
                    });
                  }
                });
              }
            },
            didDrawPage: (data) => {
              if (currentPage > 1 && tenGiaoVienCount > 16) {
                doc.setFillColor(255, 255, 255);
                doc.rect(
                    data.settings.margin.left,
                    0,
                    doc.internal.pageSize.width -
                    data.settings.margin.left -
                    data.settings.margin.right,
                    data.settings.margin.top,
                    "F"
                );
              }
              currentPage++;
            },
            theme: "grid",
            headStyles: {
              fillColor: [255, 255, 255],
              textColor: [45, 45, 45],
              font: "TimesBold",
              fontStyle: "normal",
              lineWidth: 0.1,
              halign: "center",
              valign: "middle",
              fontSize: 13,
              cellPadding: 1,
            },
            styles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "TimesBold",
              fontStyle: "normal",
            },
            bodyStyles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "VNTimes",
              fontStyle: "normal",
              cellPadding: 1,
              fontSize: 13,
            },
            pageBreak: "auto",
          });
        };
        const pageSizeLimit = 18;
        const endY = doc.autoTable.previous.finalY;
        const tableHeight = endY - startY;
        console.log("tenGiaoVienCount", tenGiaoVienCount);
        if (tenGiaoVienCount > pageSizeLimit && tableHeight > 262) {
          const firstPart = body.slice(0, pageSizeLimit);
          const remainingPart = body.slice(pageSizeLimit);
          createTable(firstPart, true);
          doc.addPage();
          createTable(remainingPart, false);
        } else {
          createTable(body, true);
        }
        let tableLength = doc.lastAutoTable.finalY;
        doc.setFont("VNTimes");
        doc.text(
            "Trong bảng này có sửa chữa ở: Không chỗ, thuộc các môn học: ",
            15,
            tableLength + 5
        );
        //   Ký
        // Đoạn văn bản đầu tiên
        const text1 = "Xác nhận của Giáo viên chủ nhiệm";
        const text1Width = doc.getTextWidth(text1);
        const text1X = doc.internal.pageSize.width / 4 - text1Width / 2;
        const text1Y = tableLength + 17;
        doc.setFont("TimesBold");
        doc.text(text1, text1X, text1Y);
        const text2 = "(Ký và ghi rõ họ tên)";
        const text2Width = doc.getTextWidth(text2);
        const text2X = doc.internal.pageSize.width / 4 - text2Width / 2;
        const text2Y = text1Y + 5;
        doc.setFont("VNTimesItalic");
        doc.text(text2, text2X, text2Y);
        const imgWidth = 50;
        const imgHeight = 20;
        const imgX = text2X + text2Width / 2 - imgWidth / 2;
        const imgY = text2Y + 4;
        if (pathChuKyGVCN && !this.cauHinh.isAnAnhGVCN) {
          doc.addImage(pathChuKyGVCN, "JPEG", imgX, imgY, imgWidth, imgHeight);
        }
        const text3 = tenGVCN;
        if (text3) {
          const text3Width = doc.getTextWidth(text3);
          const text3X = imgX + imgWidth / 2 - text3Width / 2;
          const text3Y = imgY + imgHeight + 9;
          doc.setFont("TimesBold");
          doc.text(text3, text3X, text3Y);
        }
        const text4 = duLieu.tieuDeKyThayKQHT ? duLieu.tieuDeKyThayKQHT : "";
        // const text4 = 'Xác nhận của '+ (parseInt(capHocNhaTruong) == 6 ? 'Giám đốc' : 'Hiệu trưởng');
        const hasNewline = text4.includes("\n");
        const countNewlines = text4.match(/\n/g);
        const hasTwoNewlines = countNewlines && countNewlines.length >= 2;
        let lengthNew = 0;
        if (hasTwoNewlines) {
          lengthNew = 10;
        } else if (hasNewline) {
          lengthNew = 5;
        }
        const YTieuDe = tableLength + 17 - lengthNew;
        const text4Width = doc.getTextWidth(text4);
        let text4X = (doc.internal.pageSize.width * 3) / 4 - text4Width / 2;
        if (hasTwoNewlines) {
          text4X += 50;
        } else if (hasNewline) {
          text4X += 20;
        }
        const text4Y = YTieuDe;
// doc.setFont("TimesBoldItalic");
        doc.text(text4, text4X, text4Y);
        const text5 = "(Ký, ghi rõ họ tên và đóng dấu)";
        const text5Width = doc.getTextWidth(text5);
        const text5X = (doc.internal.pageSize.width * 3) / 4 - text5Width / 2;
        const text5Y = text4Y + 5 + lengthNew;
        doc.setFont("VNTimesItalic");
        doc.text(text5, text5X, text5Y);
        const img_Stamp_3_Width = this.imgStampWidth;
        const img_Stamp_3_Height = this.imgStampHeight;
        const stamp_3_X = text5X - 10;
        const img_Stamp_3_Y = text5Y - 12;
        if (pathDau && !this.cauHinh.isDau) {
          doc.addImage(
              pathDau,
              "JPEG",
              stamp_3_X,
              img_Stamp_3_Y,
              img_Stamp_3_Width,
              img_Stamp_3_Height
          );
        }
        const imgSignatureWidth = this.imgSignatureWidth;
        const imgSignatureHeight = this.imgSignatureHeight;
        const signatureX = stamp_3_X + img_Stamp_3_Width * (2 / 3);
        const signatureY = img_Stamp_3_Y + (img_Stamp_3_Height - imgSignatureHeight) / 2;
        if (pathChuKyHieuTruong && !this.cauHinh.isAnAnhHieuTruong) {
          doc.addImage(
              pathChuKyHieuTruong,
              "JPEG",
              signatureX,
              signatureY,
              imgSignatureWidth,
              imgSignatureHeight
          );
        }
        const text6 = duLieu.tenHieuTruong;
        if (text6) {
          const text6Width = doc.getTextWidth(text6);
          const text6X = (doc.internal.pageSize.width * 3) / 4 - text6Width / 2;
          const text6Y = img_Stamp_3_Y + img_Stamp_3_Height + 5;
          doc.setFont("TimesBold");
          doc.text(text6, text6X, text6Y);
        }
      } else if (pageNumber == 9) {
        let marginTop = 20; // 1,5cm
        let marginLeft = 14; // 2cm
        let marginRight = doc.internal.pageSize.getWidth() - 10; // 1cm từ lề phải
        let marginBottom = doc.internal.pageSize.getHeight() - 10; // 1cm từ lề dưới
        doc.setLineWidth(0.6);
        doc.line(marginLeft, marginTop, marginRight, marginTop); // Đường viền trên
        doc.line(marginLeft, marginTop, marginLeft, marginBottom); // Đường viền trái
        doc.line(marginRight, marginTop, marginRight, marginBottom); // Đường viền phải
        doc.line(marginLeft, marginBottom, marginRight, marginBottom); // Đường viền dưới
        doc.setFont("TimesBold");
        doc.setFontSize(13);
        if (this.isThongTu22) {
          doc.setFontSize(13);
          doc.setFont("TimesBoldItalic");
          doc.text("Họ và tên:", 15, 15);
          hoten.length > 15 ? doc.setFontSize(12) : doc.setFontSize(14);
          doc.text(hoten, 37, 15);
          doc.setFontSize(13);
          doc.text(`Lớp: ${tenLop}` ?? "", 110, 15);
          doc.setFontSize(13);
          doc.text(`Năm học: ${namHoc}` ?? "", 159, 15);
        } else {
          doc.setFont("TimesBoldItalic");
          let tenTruongKQHT = duLieu.tenTruong ? duLieu.tenTruong : "";
          if (tenTruongKQHT.length > 15) {
            const lastSpaceIndex = tenTruongKQHT.lastIndexOf(" ", 15);
            tenTruongKQHT =
                tenTruongKQHT.slice(0, lastSpaceIndex) +
                "\n" +
                tenTruongKQHT.slice(lastSpaceIndex + 1);
            doc.text(`Trường: `, 15, 15);
            doc.text(tenTruongKQHT, 35, 12.5);
          } else {
            doc.text(`Trường: ${tenTruongKQHT}`, 15, 15);
          }
          doc.text(`Huyện: ${tenHuyen}`, 80, 15);
          doc.text(`Tỉnh: ${tenTinh}`, 153, 15);
        }

        const nhanXet = this.isThongTu22
            ? `-Được lên lớp:\n${
                duLieu.lenThangLop || duLieu.lenLopSauThiLai || ""
            }\n\n-Không được lên lớp:\n\n${duLieu.khongDuocLenLop ?? ""}`
            : `Được lên lớp thẳng:\n${
                duLieu.lenThangLop ?? ""
            }\n\n Được lên lớp sau khi KT lại môn học hoặc rèn luyện thêm về HK:\n${
                duLieu.lenLopSauThiLai ?? ""
            }\n\nKhông được lên lớp:\n${duLieu.khongDuocLenLop}`;
        let header = [
          [
            {content: "Học kỳ", rowSpan: 2},
            {
              content: "Kết quả xếp loại",
              colSpan: 2,
            },
            {
              content: this.isThongTu22
                  ? "Tổng số buổi nghỉ học cả năm học"
                  : "TS buổi nghỉ học cả năm",
              rowSpan: 2,
            },
            {
              content: this.isThongTu22
                  ? "Mức đánh giá sau đánh giá lại môn học hoặc rèn luyện trong kì nghỉ hè (nếu có)"
                  : "Xếp loại sau KT lại các môn học hoặc rèn luyện thêm về HK",
              colSpan: 2,
            },
            {
              content: nhanXet,
              rowSpan: 5,
              styles: {fontStyle: "normal", halign: "left"},
            },
          ],
          [
            {content: this.isThongTu22 ? "Kết quả rèn luyện" : "HK", rowSpan: 1},
            {
              content: this.isThongTu22 ? "Kết quả học tập" : "HL",
              rowSpan: 1,
            },
            {content: this.isThongTu22 ? "Kết quả rèn luyện" : "HK", rowSpan: 1},
            {content: this.isThongTu22 ? "Kết quả học tập" : "HL", rowSpan: 1},
          ],
        ];
        let body = [
          [
            {content: "Học kỳ I", colSpan: 1},
            {content: hanhKiemHKI, colSpan: 1},
            {content: hocLucHKI, colSpan: 1},
            {
              content: soNgayNghi,
              rowSpan: 3,
            },
            {
              content: duLieu.hanhKiemSauThiLai ? duLieu.hanhKiemSauThiLai : "",
              rowSpan: 3,
            },
            {
              content: duLieu.hocLucSauThiLai ? duLieu.hocLucSauThiLai : "",
              rowSpan: 3,
            },
          ],
          [
            {content: "Học kỳ II", colSpan: 1},
            {content: hanhKiemHKII, colSpan: 1},
            {content: hocLucHKII, colSpan: 1},
          ],
          [
            {content: "Cả năm", colSpan: 1},
            {content: hanhKiemCaNam, colSpan: 1},
            {content: hocLucCN, colSpan: 1},
          ],
        ];
        let columnStyles = {
          0: {
            halign: "center",
            valign: "middle",
            fontSize: 13,
            cellWidth: 30.8,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
          },
          1: {
            halign: "center",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            cellWidth: 23,
          },
          2: {
            halign: "center",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            cellWidth: 21,
          },
          3: {
            halign: "center",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            cellWidth: 21,
          },
          4: {
            halign: "center",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            cellWidth: 23,
          },
          5: {
            halign: "left",
            fontSize: 13,
            cellPadding: {top: 3, right: 3, bottom: 3, left: 1},
            valign: "middle",
            cellWidth: 21,
          },
          6: {
            halign: "left",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 15},
            valign: "middle",
            cellWidth: 46,
          },
        };
        doc.autoTable({
          tableLineColor: [70, 76, 70],
          columnStyles: columnStyles,
          head: header,
          body: body,
          startY: 20,
          theme: "grid",
          headStyles: {
            fillColor: [255, 255, 255],
            textColor: [45, 45, 45],
            font: "TimesBold",
            fontStyle: "normal",
            lineWidth: 0.1,
            halign: "center",
            valign: "middle",
            fontSize: 13,
            cellPadding: 1,
          },
          styles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: "TimesBold",
            fontStyle: "normal",
          },
          bodyStyles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: "VNTimes",
            fontStyle: "normal",
            cellPadding: 1,
            fontSize: 13,
          },
        });
        let tableLength = doc.lastAutoTable.finalY;
        doc.setFont("VNTimesItalic");
        doc.setFontSize(12);
        const capHoc =
            capHoc == 2 || [6, 7, 8, 9].includes(khoiHoc)
                ? "trung học cơ sở: "
                : capHoc == 3 || [10, 11, 12].includes(khoiHoc)
                    ? "trung học phổ thông: "
                    : "";
        doc.text(
            `- Nếu là lớp cuối cấp thì ghi: Hoàn thành hay không hoàn thành chương trình ${capHoc} `,
            15,
            tableLength + 5
        );
        doc.setFont("VNTimes");
        doc.setFontSize(12);
        doc.text(chungChiNghe, 15, tableLength + 14);
        const loaiChungChi = duLieu.loaiChungChi;
        const loaiChungChiMap = {
          1: "Giỏi",
          2: "Khá",
          3: "Trung bình",
        };
        const loaiChungChiText = loaiChungChiMap[loaiChungChi] || "";
        doc.setFont("VNTimes");
        doc.setFontSize(12);
        doc.text(`Loại:${loaiChungChiText} `, 150, tableLength + 14);
        doc.setFont("VNTimes");
        const khenThuongCapHuyen = this.countCharacters(
            duLieu.khenThuongCapHuyen ? duLieu.khenThuongCapHuyen : "",
            "- Kết quả tham gia các cuộc thi (nếu có):  ",
            96
        );
        doc.text(khenThuongCapHuyen, 15, tableLength + 24);

        doc.setFont("VNTimes");
        doc.setFontSize(12);
        doc.text(khenThuong, 15, tableLength + 44);
        doc.setLineWidth(0.5);
        doc.line(marginLeft, tableLength + 50, marginRight, tableLength + 50);
        let fontSize = 13;
        if (this.isThongTu22) {
          doc.setFontSize(fontSize);
          let textHocBa = "KẾT QUẢ RÈN LUYỆN TRONG KÌ NGHỈ HÈ";
          let textWidth =
              (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
          doc.setFont("TimesBold");
          doc.text(textHocBa, (pageWidth - textWidth) / 2, tableLength + 55);
          if (noiDungRenLuyenTrongKyHe) {
            doc.setFont("VNTimes");
            doc.text(noiDungRenLuyenTrongKyHe, marginLeft + 2, tableLength + 60);
          } else {
            let textHocBa = "(Nếu có)";
            let textWidth =
                (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
            doc.setFont("VNTimesItalic");
            doc.text(textHocBa, (pageWidth - textWidth) / 2, tableLength + 60);
          }
          doc.line(marginLeft, tableLength + 75, marginRight, tableLength + 75);
          textHocBa = "NHẬN XÉT CỦA GIÁO VIÊN CHỦ NHIỆM";
          textWidth =
              (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
          doc.setFont("TimesBold");
          doc.text(textHocBa, (pageWidth - textWidth) / 2, tableLength + 80);
          doc.setFont("VNTimes");
          const nhanXetGVCN1 = this.formatTextWithBullets(duLieu.nhanXet, 5);
          console.log("nhanXetGVCN1", nhanXetGVCN1);
          doc.text(nhanXetGVCN1, 15, tableLength + 85);
          let text1 = "(Ký và ghi rõ họ tên )";
          let text1Width = doc.getTextWidth(text1);
          let text1X = (doc.internal.pageSize.width * 3) / 4 - text1Width / 2;
          const text1Y = tableLength + 110;
          doc.setFont("VNTimesItalic");
          doc.text(text1, text1X, text1Y);
          const text2Y = tableLength + 120;
          const imgSignatureWidth = this.imgSignatureWidth;
          const imgSignatureHeight = this.imgSignatureHeight;
          const imageX = (doc.internal.pageSize.width * 3) / 4 - imgSignatureWidth / 2;
          if (pathChuKyGVCN && !this.cauHinh.isAnAnhGVCN) {
            doc.addImage(
                pathChuKyGVCN,
                "JPEG",
                imageX,
                text2Y - imgSignatureHeight + 13,
                imgSignatureWidth,
                imgSignatureHeight
            );
          }
          let text2 = tenGVCN;
          if (text2) {
            let text2Width = doc.getTextWidth(text2);
            let text2X = (doc.internal.pageSize.width * 3) / 4 - text2Width / 2;
            doc.setFont("TimesBold");
            doc.text(text2, text2X, text2Y + 17);
          }
          doc.line(marginLeft, tableLength + 140, marginRight, tableLength + 140);
        } else {
          let textHocBa = "NHẬN XÉT CỦA GIÁO VIÊN CHỦ NHIỆM";
          let textWidth =
              (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
          doc.setFont("TimesBold");
          doc.text(textHocBa, (pageWidth - textWidth) / 2, tableLength + 55);
          const nhanXetGVCN = this.formatTextWithBullets(duLieu.nhanXet, 4);
          console.log("nhanXetGVCN", nhanXetGVCN);
          doc.setFont("VNTimes");
          doc.text(nhanXetGVCN, 15, tableLength + 62);
          let text1 = "(Ký và ghi rõ họ tên )";
          let text1Width = doc.getTextWidth(text1);
          let text1X = (doc.internal.pageSize.width * 3) / 4 - text1Width / 2;
          const text1Y = tableLength + 105;
          doc.setFont("VNTimesItalic");
          doc.text(text1, text1X, text1Y);
          const text2Y = tableLength + 135;
          const imgSignatureWidth = this.imgSignatureWidth;
          const imgSignatureHeight = this.imgSignatureHeight;
          const imageX = (doc.internal.pageSize.width * 3) / 4 - imgSignatureWidth / 2;
          if (pathChuKyGVCN && !this.cauHinh.isAnAnhGVCN) {
            doc.addImage(
                pathChuKyGVCN,
                "JPEG",
                imageX,
                text2Y - imgSignatureHeight - 5,
                imgSignatureWidth,
                imgSignatureHeight
            );
          }
          let text2 = tenGVCN;
          let text2Width = doc.getTextWidth(text2);
          let text2X = (doc.internal.pageSize.width * 3) / 4 - text2Width / 2;
          doc.setFont("TimesBold");
          doc.text(text2, text2X, text2Y);
          doc.line(marginLeft, tableLength + 140, marginRight, tableLength + 140);
        }
        let textHocBa = "PHÊ DUYỆT CỦA " + (parseInt(capHocNhaTruong) == 6 ? 'GIÁM ĐỐC' : 'HIỆU TRƯỞNG');
        let textWidth =
            (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
        doc.text(textHocBa, (pageWidth - textWidth) / 2, tableLength + 145);
        doc.setFont("VNTimes");
        if (!this.cauHinh.isAnNhanXet) {
          doc.text(nhanXetHieuTruong, 15, tableLength + 165);
        }
        let text2 = ngayHieuTruongKy;
        if (text2) {
          let text2Width = doc.getTextWidth(text2);
          let text2X = (doc.internal.pageSize.width * 3) / 4 - text2Width / 2 - 2;
          doc.setFont("VNTimesItalic");
          // In thời gian ký
          var textLines = doc.splitTextToSize(text2, pageWidth);
          var textWidths = doc.getStringUnitWidth(text2) * doc.internal.getFontSize() / doc.internal.scaleFactor;
          var x = pageWidth - textWidths - 15; // 10 là khoảng cách từ lề phải
          // Thêm văn bản với căn lề phải
          for (let i = 0; i < textLines.length; i++) {
            doc.text(textLines[i], x, tableLength + 152);
          }
          // doc.text(text2+' demo nè', text2X, tableLength + 150);
          // Hết thời gian ký
          text2 = duLieu.tieuDeKyThayKQHT ? duLieu.tieuDeKyThayKQHT : "";
          let hasNewline = text2.includes("\n");
          let countNewlines = text2.match(/\n/g);
          let hasTwoNewlines = countNewlines && countNewlines.length >= 2;
          text2Width = doc.getTextWidth(text2);
          if (hasTwoNewlines) {
            text2X = (doc.internal.pageSize.width * 3) / 4 - text2Width / 2 + 50;
          } else if (hasNewline) {
            text2X = (doc.internal.pageSize.width * 3) / 4 - text2Width / 2 + 20;
          } else {
            text2X = (doc.internal.pageSize.width * 3) / 4 - text2Width / 2;
          }
          doc.setFont("TimesBold");
          doc.text(
              text2,
              text2X,
              !hasTwoNewlines ? tableLength + 157 : tableLength + 157
          );
        }
        let signText = "(Ký, ghi rõ họ tên và đóng dấu)";
        let signTextWidth = doc.getTextWidth(signText);
        let signTextX = (doc.internal.pageSize.width * 3) / 4 - signTextWidth / 2;
        const signTextY = tableLength + 170;
        doc.setFont("VNTimesItalic");
        text2 = duLieu.tieuDeKyThayKQHT ? duLieu.tieuDeKyThayKQHT : "";
        let hasNewline = text2.includes("\n");
        doc.text(signText, signTextX + 3, signTextY - (!hasNewline ? 8 : 3));
        const nameTextY = tableLength + 203;
        const stampImageWidth = 50;
        const stampImageHeight = 20;
        const stampImageX = (doc.internal.pageSize.width * 3) / 4 - stampImageWidth / 2;
        let nameText = duLieu.tenHieuTruong ? duLieu.tenHieuTruong : "";
        let nameTextWidth = doc.getTextWidth(nameText);
        let nameTextX = (doc.internal.pageSize.width * 3) / 4 - nameTextWidth / 2;
        doc.setFont("TimesBold");
        doc.text(nameText, nameTextX, nameTextY - 8);
        const imgStampWidth = this.imgStampWidth;
        const imgStampHeight = this.imgStampHeight;
        const stampX = nameTextX - imgStampWidth / 2 - 5;
        const stampY = nameTextY - imgStampHeight - 5;
        if (pathDau && !this.cauHinh.isDau) {
          doc.addImage(pathDau, "JPEG", stampX, stampY, imgStampWidth, imgStampHeight);
        }
        if (pathChuKyHieuTruong && !this.cauHinh.isAnAnhHieuTruong) {
          doc.addImage(
              pathChuKyHieuTruong,
              "JPEG",
              stampImageX + 4,
              stampY + imgStampHeight / 2 - stampImageHeight / 2 + 2,
              stampImageWidth,
              stampImageHeight
          );
        }
      } else if (pageNumber == 10) {
        const marginTop = 20; // 1,5cm
        const marginLeft = 14; // 2cm
        const marginRight = doc.internal.pageSize.getWidth() - 10; // 1cm từ lề phải
        const marginBottom = doc.internal.pageSize.getHeight() - 10; // 1cm từ lề dưới
        doc.setLineWidth(0.3);
        doc.line(marginLeft, marginTop, marginRight, marginTop); // Đường viền trên
        doc.line(marginLeft, marginTop, marginLeft, marginBottom); // Đường viền trái
        doc.line(marginRight, marginTop, marginRight, marginBottom); // Đường viền phải
        doc.line(marginLeft, marginBottom, marginRight, marginBottom); // Đường viền dưới
        const cap3ThongTu58 = !this.isThongTu22 && loaiHocBa == 3;
        if (cap3ThongTu58) {
          doc.setFont("TimesBoldItalic");
          doc.setFontSize(13);
          doc.text("Họ và tên:", 15, 10);
          doc.setFontSize(14);
          hoten.length > 15 ? doc.setFontSize(12) : doc.setFontSize(14);
          doc.text(hoten, 37, 10);
          doc.setFontSize(13);
          doc.text(`Lớp: ${tenLop}` ?? "", 110, 10);
          doc.setFontSize(13);
          doc.text(`Năm học: ${namHoc}` ?? "", 159, 10);
          doc.setFontSize(13);
          const phanBan = duLieu.phanBan ? duLieu.phanBan : "";
          doc.text(`Ban: ${phanBan}`, 15, 17);
          doc.setFontSize(13);
          const monHocPhanBan = duLieu.monHocPhanBan ? duLieu.monHocPhanBan : "";
          doc.text(`Môn chuyên: ${monHocPhanBan}`, 110, 17);
        } else {
          doc.setFont("TimesBoldItalic");
          doc.setFontSize(13);
          doc.text("Họ và tên:", 15, 15);
          hoten.length > 15 ? doc.setFontSize(12) : doc.setFontSize(14);
          doc.text(hoten, 37, 15);
          doc.setFontSize(13);
          doc.text(`Lớp: ${tenLop}` ?? "", 110, 15);
          doc.setFontSize(13);
          doc.text(`Năm học: ${namHoc}` ?? "", 159, 15);
        }
        let header = [
          [
            {content: "Môn học/Hoạt động giáo dục", rowSpan: 2},
            {
              content: "Điểm trung bình môn học hoặc mức đánh giá",
              colSpan: 3,
            },
            {
              content: this.isThongTu22
                  ? "Điểm trung bình môn học hoặc mức đánh giá lại sau đánh giá lại, rèn luyện thêm trong kỳ nghỉ hè (nếu có)"
                  : "Điểm hoặc xếp loại sau KT lại (nếu có)",
              rowSpan: 2,
              styles: this.isThongTu22 ? {fontSize: 10} : {},
            },
            {
              content: this.isThongTu22
                  ? "Nhận xét sự tiến bộ, ưu điểm nổi bật, hạn chế chủ yếu (nếu có) và chữ kí của giáo viên môn học"
                  : "Giáo viên bộ môn ký xác nhận ĐTB môn học hoặc xếp loại và sửa chữa (nếu có), (Ký và ghi rõ họ tên) ",
              rowSpan: 2,
            },
          ],
          [
            {content: "H Kỳ I", rowSpan: 1},
            {content: "H Kỳ II", rowSpan: 1},
            {content: "CN", rowSpan: 1},
          ],
        ];
        const ketQuaHocTaps = duLieu.ketQuaHocTaps;
        let body = [];
        const handleNull = (value) => (value !== null ? value : "");
        let tenGiaoVienCount = 0;
        if (Array.isArray(ketQuaHocTaps) && ketQuaHocTaps.length > 0) {
          body = ketQuaHocTaps.map((item) => {
            if (item.tenGiaoVien) {
              const giaoVienList = item.tenGiaoVien
                  .split("\n")
                  .filter((name) => name.trim() !== "");
              tenGiaoVienCount += giaoVienList.length;
            }
            return [
              {content: handleNull(item.tenMonHoc), colSpan: 1},
              {content: handleNull(item.diemHocKyI), colSpan: 1},
              {content: handleNull(item.diemHocKyII), colSpan: 1},
              {content: handleNull(item.diemCaNam), colSpan: 1},
              {content: handleNull(item.diemThiLai), colSpan: 1},
              {content: handleNull(item.tenGiaoVien), colSpan: 1},
            ];
          });
        }

        let columnStyles = {
          0: {
            halign: "center",
            valign: "middle",
            fontSize: 13,
            cellWidth: 45.8,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
          },
          1: {
            halign: "center",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            cellWidth: 17,
          },
          2: {
            halign: "center",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            cellWidth: 17,
          },
          3: {
            halign: "center",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            cellWidth: 17,
          },
          4: {
            halign: "center",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            cellWidth: 25,
          },
          5: {
            halign: "left",
            fontSize: 13,
            cellPadding: {top: 3, right: 3, bottom: 3, left: 15},
            valign: "middle",
            cellWidth: 64,
          },
        };
        let imgPositions = [];
        if (Array.isArray(ketQuaHocTaps) && ketQuaHocTaps.length > 0) {
          imgPositions = ketQuaHocTaps.map((item, index) => ({
            row: index,
            column: 5,
            offsetX: 0,
            offsetY: 0,
            // images: item.pathChuKyGV !== null ? this.genHinhAnh(item.pathChuKyGV) : [],
            images: item.chuKys && item.chuKys[0] != null ? item.chuKys : [],
          }));
          console.log("imgPositions", imgPositions);
        }
        let currentPage = 1;
        let startY = 20
        const createTable = (data, showHeader) => {
          doc.autoTable({
            tableLineColor: [70, 76, 70],
            columnStyles: columnStyles,
            head: showHeader ? header : [],
            body: data,
            startY: startY,
            didDrawCell: (data) => {
              if (data.section === "body" && data.cell) {
                imgPositions.forEach((position) => {
                  if (
                      data.row.index === position.row &&
                      data.column.index === position.column
                  ) {
                    const imgWidth = 15;
                    const imgHeight = 9;
                    position?.images.forEach((image, index) => {
                      const imgX = data.cell.x + position.offsetX + 0.5;
                      let imgY =
                          data.cell.y + position.offsetY + 1 + (index == 0 ? 0 : index * 6);
                      if (image && image != "") {
                        doc.addImage(image, "JPEG", imgX, imgY, imgWidth, imgHeight);
                      }
                    });
                  }
                });
              }
            },
            didDrawPage: (data) => {
              if (currentPage > 1 && tenGiaoVienCount > 16) {
                doc.setFillColor(255, 255, 255);
                doc.rect(
                    data.settings.margin.left,
                    0,
                    doc.internal.pageSize.width -
                    data.settings.margin.left -
                    data.settings.margin.right,
                    data.settings.margin.top,
                    "F"
                );
              }
              currentPage++;
            },
            theme: "grid",
            headStyles: {
              fillColor: [255, 255, 255],
              textColor: [45, 45, 45],
              font: "TimesBold",
              fontStyle: "normal",
              lineWidth: 0.1,
              halign: "center",
              valign: "middle",
              fontSize: 13,
              cellPadding: 1,
            },
            styles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "TimesBold",
              fontStyle: "normal",
            },
            bodyStyles: {
              textColor: [45, 45, 45],
              lineColor: [70, 76, 70],
              font: "VNTimes",
              fontStyle: "normal",
              cellPadding: 1,
              fontSize: 13,
            },
            pageBreak: "auto",
          });
        };
        const pageSizeLimit = 18;
        const endY = doc.autoTable.previous.finalY;
        const tableHeight = endY - startY;
        console.log("tenGiaoVienCount", tenGiaoVienCount);
        if (tenGiaoVienCount > pageSizeLimit && tableHeight > 262) {
          const firstPart = body.slice(0, pageSizeLimit);
          const remainingPart = body.slice(pageSizeLimit);
          createTable(firstPart, true);
          doc.addPage();
          createTable(remainingPart, false);
        } else {
          createTable(body, true);
        }
        let tableLength = doc.lastAutoTable.finalY;
        doc.setFont("VNTimes");
        doc.text(
            "Trong bảng này có sửa chữa ở: Không chỗ, thuộc các môn học: ",
            15,
            tableLength + 5
        );
        //   Ký
        // Đoạn văn bản đầu tiên
        const text1 = "Xác nhận của Giáo viên chủ nhiệm";
        const text1Width = doc.getTextWidth(text1);
        const text1X = doc.internal.pageSize.width / 4 - text1Width / 2;
        const text1Y = tableLength + 17;
        doc.setFont("TimesBold");
        doc.text(text1, text1X, text1Y);
        const text2 = "(Ký và ghi rõ họ tên)";
        const text2Width = doc.getTextWidth(text2);
        const text2X = doc.internal.pageSize.width / 4 - text2Width / 2;
        const text2Y = text1Y + 5;
        doc.setFont("VNTimesItalic");
        doc.text(text2, text2X, text2Y);
        const imgWidth = 50;
        const imgHeight = 20;
        const imgX = text2X + text2Width / 2 - imgWidth / 2;
        const imgY = text2Y + 4;
        if (pathChuKyGVCN && !this.cauHinh.isAnAnhGVCN) {
          doc.addImage(pathChuKyGVCN, "JPEG", imgX, imgY, imgWidth, imgHeight);
        }
        const text3 = tenGVCN;
        if (text3) {
          const text3Width = doc.getTextWidth(text3);
          const text3X = imgX + imgWidth / 2 - text3Width / 2;
          const text3Y = imgY + imgHeight + 9;
          doc.setFont("TimesBold");
          doc.text(text3, text3X, text3Y);
        }
        const text4 = duLieu.tieuDeKyThayKQHT ? duLieu.tieuDeKyThayKQHT : "";
        // const text4 = 'Xác nhận của '+ (parseInt(capHocNhaTruong) == 6 ? 'Giám đốc' : 'Hiệu trưởng');
        const hasNewline = text4.includes("\n");
        const countNewlines = text4.match(/\n/g);
        const hasTwoNewlines = countNewlines && countNewlines.length >= 2;
        let lengthNew = 0;
        if (hasTwoNewlines) {
          lengthNew = 10;
        } else if (hasNewline) {
          lengthNew = 5;
        }
        const YTieuDe = tableLength + 17 - lengthNew;
        const text4Width = doc.getTextWidth(text4);
        let text4X = (doc.internal.pageSize.width * 3) / 4 - text4Width / 2;
        if (hasTwoNewlines) {
          text4X += 50;
        } else if (hasNewline) {
          text4X += 20;
        }
        const text4Y = YTieuDe;
        // doc.setFont("TimesBoldItalic");
        doc.text(text4, text4X, text4Y);
        const text5 = "(Ký, ghi rõ họ tên và đóng dấu)";
        const text5Width = doc.getTextWidth(text5);
        const text5X = (doc.internal.pageSize.width * 3) / 4 - text5Width / 2;
        const text5Y = text4Y + 5 + lengthNew;
        doc.setFont("VNTimesItalic");
        doc.text(text5, text5X, text5Y);
        const img_Stamp_3_Width = this.imgStampWidth;
        const img_Stamp_3_Height = this.imgStampHeight;
        const stamp_3_X = text5X - 10;
        const img_Stamp_3_Y = text5Y - 12;
        if (pathDau && !this.cauHinh.isDau) {
          doc.addImage(
              pathDau,
              "JPEG",
              stamp_3_X,
              img_Stamp_3_Y,
              img_Stamp_3_Width,
              img_Stamp_3_Height
          );
        }
        const imgSignatureWidth = this.imgSignatureWidth;
        const imgSignatureHeight = this.imgSignatureHeight;
        const signatureX = stamp_3_X + img_Stamp_3_Width * (2 / 3);
        const signatureY = img_Stamp_3_Y + (img_Stamp_3_Height - imgSignatureHeight) / 2;
        if (pathChuKyHieuTruong && !this.cauHinh.isAnAnhHieuTruong) {
          doc.addImage(
              pathChuKyHieuTruong,
              "JPEG",
              signatureX,
              signatureY,
              imgSignatureWidth,
              imgSignatureHeight
          );
        }
        const text6 = duLieu.tenHieuTruong;
        if (text6) {
          const text6Width = doc.getTextWidth(text6);
          const text6X = (doc.internal.pageSize.width * 3) / 4 - text6Width / 2;
          const text6Y = img_Stamp_3_Y + img_Stamp_3_Height + 5;
          doc.setFont("TimesBold");
          doc.text(text6, text6X, text6Y);
        }
      } else if (pageNumber == 11) {
        let marginTop = 20; // 1,5cm
        let marginLeft = 14; // 2cm
        let marginRight = doc.internal.pageSize.getWidth() - 10; // 1cm từ lề phải
        let marginBottom = doc.internal.pageSize.getHeight() - 10; // 1cm từ lề dưới
        doc.setLineWidth(0.6);
        doc.line(marginLeft, marginTop, marginRight, marginTop); // Đường viền trên
        doc.line(marginLeft, marginTop, marginLeft, marginBottom); // Đường viền trái
        doc.line(marginRight, marginTop, marginRight, marginBottom); // Đường viền phải
        doc.line(marginLeft, marginBottom, marginRight, marginBottom); // Đường viền dưới
        doc.setFont("TimesBold");
        doc.setFontSize(13);
        if (this.isThongTu22) {
          doc.setFont("TimesBoldItalic");
          doc.setFontSize(13);
          doc.text("Họ và tên:", 15, 15);
          hoten.length > 15 ? doc.setFontSize(12) : doc.setFontSize(14);
          doc.text(hoten, 37, 15);
          doc.setFontSize(13);
          doc.text(`Lớp: ${tenLop}` ?? "", 110, 15);
          doc.setFontSize(13);
          doc.text(`Năm học: ${namHoc}` ?? "", 159, 15);
        } else {
          doc.setFont("TimesBoldItalic");
          let tenTruongKQHT = duLieu.tenTruong ? duLieu.tenTruong : "";
          if (tenTruongKQHT.length > 15) {
            const lastSpaceIndex = tenTruongKQHT.lastIndexOf(" ", 15);
            tenTruongKQHT =
                tenTruongKQHT.slice(0, lastSpaceIndex) +
                "\n" +
                tenTruongKQHT.slice(lastSpaceIndex + 1);
            doc.text(`Trường: `, 15, 15);
            doc.text(tenTruongKQHT, 35, 12.5);
          } else {
            doc.text(`Trường: ${tenTruongKQHT}`, 15, 15);
          }
          doc.text(`Huyện: ${tenHuyen}`, 80, 15);
          doc.text(`Tỉnh: ${tenTinh}`, 153, 15);
        }

        const nhanXet = this.isThongTu22
            ? `-Được lên lớp:\n${
                duLieu.lenThangLop || duLieu.lenLopSauThiLai || ""
            }\n\n-Không được lên lớp:\n\n${duLieu.khongDuocLenLop ?? ""}`
            : `Được lên lớp thẳng:\n${
                duLieu.lenThangLop ?? ""
            }\n\n Được lên lớp sau khi KT lại môn học hoặc rèn luyện thêm về HK:\n${
                duLieu.lenLopSauThiLai ?? ""
            }\n\nKhông được lên lớp:\n${duLieu.khongDuocLenLop}`;
        let header = [
          [
            {content: "Học kỳ", rowSpan: 2},
            {
              content: "Kết quả xếp loại",
              colSpan: 2,
            },
            {
              content: this.isThongTu22
                  ? "Tổng số buổi nghỉ học cả năm học"
                  : "TS buổi nghỉ học cả năm",
              rowSpan: 2,
            },
            {
              content: this.isThongTu22
                  ? "Mức đánh giá sau đánh giá lại môn học hoặc rèn luyện trong kì nghỉ hè (nếu có)"
                  : "Xếp loại sau KT lại các môn học hoặc rèn luyện thêm về HK",
              colSpan: 2,
            },
            {
              content: nhanXet,
              rowSpan: 5,
              styles: {fontStyle: "normal", halign: "left"},
            },
          ],
          [
            {content: this.isThongTu22 ? "Kết quả rèn luyện" : "HK", rowSpan: 1},
            {
              content: this.isThongTu22 ? "Kết quả học tập" : "HL",
              rowSpan: 1,
            },
            {content: this.isThongTu22 ? "Kết quả rèn luyện" : "HK", rowSpan: 1},
            {content: this.isThongTu22 ? "Kết quả học tập" : "HL", rowSpan: 1},
          ],
        ];
        let body = [
          [
            {content: "Học kỳ I", colSpan: 1},
            {content: hanhKiemHKI, colSpan: 1},
            {content: hocLucHKI, colSpan: 1},
            {
              content: soNgayNghi,
              rowSpan: 3,
            },
            {
              content: duLieu.hanhKiemSauThiLai ? duLieu.hanhKiemSauThiLai : "",
              rowSpan: 3,
            },
            {
              content: duLieu.hocLucSauThiLai ? duLieu.hocLucSauThiLai : "",
              rowSpan: 3,
            },
          ],
          [
            {content: "Học kỳ II", colSpan: 1},
            {content: hanhKiemHKII, colSpan: 1},
            {content: hocLucHKII, colSpan: 1},
          ],
          [
            {content: "Cả năm", colSpan: 1},
            {content: hanhKiemCaNam, colSpan: 1},
            {content: hocLucCN, colSpan: 1},
          ],
        ];
        let columnStyles = {
          0: {
            halign: "center",
            valign: "middle",
            fontSize: 13,
            cellWidth: 30.8,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
          },
          1: {
            halign: "center",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            cellWidth: 23,
          },
          2: {
            halign: "center",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            cellWidth: 21,
          },
          3: {
            halign: "center",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            cellWidth: 21,
          },
          4: {
            halign: "center",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: "middle",
            cellWidth: 23,
          },
          5: {
            halign: "left",
            fontSize: 13,
            cellPadding: {top: 3, right: 3, bottom: 3, left: 1},
            valign: "middle",
            cellWidth: 21,
          },
          6: {
            halign: "left",
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 15},
            valign: "middle",
            cellWidth: 46,
          },
        };
        doc.autoTable({
          tableLineColor: [70, 76, 70],
          columnStyles: columnStyles,
          head: header,
          body: body,
          startY: 20,
          theme: "grid",
          headStyles: {
            fillColor: [255, 255, 255],
            textColor: [45, 45, 45],
            font: "TimesBold",
            fontStyle: "normal",
            lineWidth: 0.1,
            halign: "center",
            valign: "middle",
            fontSize: 13,
            cellPadding: 1,
          },
          styles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: "TimesBold",
            fontStyle: "normal",
          },
          bodyStyles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: "VNTimes",
            fontStyle: "normal",
            cellPadding: 1,
            fontSize: 13,
          },
        });
        let tableLength = doc.lastAutoTable.finalY;
        doc.setFont("VNTimesItalic");
        doc.setFontSize(12);
        const capHoc =
            capHoc == 2 || [6, 7, 8, 9].includes(khoiHoc)
                ? "trung học cơ sở: "
                : capHoc == 3 || [10, 11, 12].includes(khoiHoc)
                    ? "trung học phổ thông: "
                    : "";
        doc.text(
            `- Nếu là lớp cuối cấp thì ghi: Hoàn thành hay không hoàn thành chương trình ${capHoc} `,
            15,
            tableLength + 5
        );
        doc.setFont("VNTimes");
        doc.setFontSize(12);
        doc.text(chungChiNghe, 15, tableLength + 14);
        const loaiChungChi = duLieu.loaiChungChi;
        const loaiChungChiMap = {
          1: "Giỏi",
          2: "Khá",
          3: "Trung bình",
        };
        const loaiChungChiText = loaiChungChiMap[loaiChungChi] || "";
        doc.setFont("VNTimes");
        doc.setFontSize(12);
        doc.text(`Loại:${loaiChungChiText} `, 150, tableLength + 14);
        doc.setFont("VNTimes");
        const khenThuongCapHuyen = this.countCharacters(
            duLieu.khenThuongCapHuyen ? duLieu.khenThuongCapHuyen : "",
            "- Kết quả tham gia các cuộc thi (nếu có):  ",
            96
        );
        doc.text(khenThuongCapHuyen, 15, tableLength + 24);

        doc.setFont("VNTimes");
        doc.setFontSize(12);
        doc.text(khenThuong, 15, tableLength + 44);
        doc.setLineWidth(0.5);
        doc.line(marginLeft, tableLength + 50, marginRight, tableLength + 50);
        let fontSize = 13;
        if (this.isThongTu22) {
          doc.setFontSize(fontSize);
          let textHocBa = "KẾT QUẢ RÈN LUYỆN TRONG KÌ NGHỈ HÈ";
          let textWidth =
              (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
          doc.setFont("TimesBold");
          doc.text(textHocBa, (pageWidth - textWidth) / 2, tableLength + 55);
          if (noiDungRenLuyenTrongKyHe) {
            doc.setFont("VNTimes");
            doc.text(noiDungRenLuyenTrongKyHe, marginLeft + 2, tableLength + 60);
          } else {
            let textHocBa = "(Nếu có)";
            let textWidth =
                (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
            doc.setFont("VNTimesItalic");
            doc.text(textHocBa, (pageWidth - textWidth) / 2, tableLength + 60);
          }
          doc.line(marginLeft, tableLength + 75, marginRight, tableLength + 75);
          textHocBa = "NHẬN XÉT CỦA GIÁO VIÊN CHỦ NHIỆM";
          textWidth =
              (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
          doc.setFont("TimesBold");
          doc.text(textHocBa, (pageWidth - textWidth) / 2, tableLength + 80);
          doc.setFont("VNTimes");
          const nhanXetGVCN1 = this.formatTextWithBullets(duLieu.nhanXet, 5);
          console.log("nhanXetGVCN1", nhanXetGVCN1);
          doc.text(nhanXetGVCN1, 15, tableLength + 85);
          let text1 = "(Ký và ghi rõ họ tên )";
          let text1Width = doc.getTextWidth(text1);
          let text1X = (doc.internal.pageSize.width * 3) / 4 - text1Width / 2;
          const text1Y = tableLength + 110;
          doc.setFont("VNTimesItalic");
          doc.text(text1, text1X, text1Y);
          const text2Y = tableLength + 120;
          const imgSignatureWidth = this.imgSignatureWidth;
          const imgSignatureHeight = this.imgSignatureHeight;
          const imageX = (doc.internal.pageSize.width * 3) / 4 - imgSignatureWidth / 2;
          if (pathChuKyGVCN && !this.cauHinh.isAnAnhGVCN) {
            doc.addImage(
                pathChuKyGVCN,
                "JPEG",
                imageX,
                text2Y - imgSignatureHeight + 13,
                imgSignatureWidth,
                imgSignatureHeight
            );
          }
          let text2 = tenGVCN;
          if (text2) {
            let text2Width = doc.getTextWidth(text2);
            let text2X = (doc.internal.pageSize.width * 3) / 4 - text2Width / 2;
            doc.setFont("TimesBold");
            doc.text(text2, text2X, text2Y + 17);
          }
          doc.line(marginLeft, tableLength + 140, marginRight, tableLength + 140);
        } else {
          let textHocBa = "NHẬN XÉT CỦA GIÁO VIÊN CHỦ NHIỆM";
          let textWidth =
              (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
          doc.setFont("TimesBold");
          doc.text(textHocBa, (pageWidth - textWidth) / 2, tableLength + 55);
          const nhanXetGVCN = this.formatTextWithBullets(duLieu.nhanXet, 4);
          console.log("nhanXetGVCN", nhanXetGVCN);
          doc.setFont("VNTimes");
          doc.text(nhanXetGVCN, 15, tableLength + 62);
          let text1 = "(Ký và ghi rõ họ tên )";
          let text1Width = doc.getTextWidth(text1);
          let text1X = (doc.internal.pageSize.width * 3) / 4 - text1Width / 2;
          const text1Y = tableLength + 105;
          doc.setFont("VNTimesItalic");
          doc.text(text1, text1X, text1Y);
          const text2Y = tableLength + 135;
          const imgSignatureWidth = this.imgSignatureWidth;
          const imgSignatureHeight = this.imgSignatureHeight;
          const imageX = (doc.internal.pageSize.width * 3) / 4 - imgSignatureWidth / 2;
          if (pathChuKyGVCN && !this.cauHinh.isAnAnhGVCN) {
            doc.addImage(
                pathChuKyGVCN,
                "JPEG",
                imageX,
                text2Y - imgSignatureHeight - 5,
                imgSignatureWidth,
                imgSignatureHeight
            );
          }
          let text2 = tenGVCN;
          let text2Width = doc.getTextWidth(text2);
          let text2X = (doc.internal.pageSize.width * 3) / 4 - text2Width / 2;
          doc.setFont("TimesBold");
          doc.text(text2, text2X, text2Y);
          doc.line(marginLeft, tableLength + 140, marginRight, tableLength + 140);
        }
        let textHocBa = "PHÊ DUYỆT CỦA " + (parseInt(capHocNhaTruong) == 6 ? 'GIÁM ĐỐC' : 'HIỆU TRƯỞNG');
        let textWidth =
            (doc.getStringUnitWidth(textHocBa) * fontSize) / doc.internal.scaleFactor;
        doc.text(textHocBa, (pageWidth - textWidth) / 2, tableLength + 145);
        doc.setFont("VNTimes");
        if (!this.cauHinh.isAnNhanXet) {
          doc.text(nhanXetHieuTruong, 15, tableLength + 165);
        }
        let text2 = ngayHieuTruongKy;
        if (text2) {
          let text2Width = doc.getTextWidth(text2);
          let text2X = (doc.internal.pageSize.width * 3) / 4 - text2Width / 2 - 2;
          doc.setFont("VNTimesItalic");
          // In thời gian ký
          var textLines = doc.splitTextToSize(text2, pageWidth);
          var textWidths = doc.getStringUnitWidth(text2) * doc.internal.getFontSize() / doc.internal.scaleFactor;
          var x = pageWidth - textWidths - 15; // 10 là khoảng cách từ lề phải
          // Thêm văn bản với căn lề phải
          for (let i = 0; i < textLines.length; i++) {
            doc.text(textLines[i], x, tableLength + 152);
          }
          // doc.text(text2+' demo nè', text2X, tableLength + 150);
          // Hết thời gian ký
          text2 = duLieu.tieuDeKyThayKQHT ? duLieu.tieuDeKyThayKQHT : "";
          let hasNewline = text2.includes("\n");
          let countNewlines = text2.match(/\n/g);
          let hasTwoNewlines = countNewlines && countNewlines.length >= 2;
          text2Width = doc.getTextWidth(text2);
          if (hasTwoNewlines) {
            text2X = (doc.internal.pageSize.width * 3) / 4 - text2Width / 2 + 50;
          } else if (hasNewline) {
            text2X = (doc.internal.pageSize.width * 3) / 4 - text2Width / 2 + 20;
          } else {
            text2X = (doc.internal.pageSize.width * 3) / 4 - text2Width / 2;
          }
          doc.setFont("TimesBold");
          doc.text(
              text2,
              text2X,
              !hasTwoNewlines ? tableLength + 157 : tableLength + 157
          );
        }
        let signText = "(Ký, ghi rõ họ tên và đóng dấu)";
        let signTextWidth = doc.getTextWidth(signText);
        let signTextX = (doc.internal.pageSize.width * 3) / 4 - signTextWidth / 2;
        const signTextY = tableLength + 170;
        doc.setFont("VNTimesItalic");
        text2 = duLieu.tieuDeKyThayKQHT ? duLieu.tieuDeKyThayKQHT : "";
        let hasNewline = text2.includes("\n");
        doc.text(signText, signTextX + 3, signTextY - (!hasNewline ? 8 : 3));
        const nameTextY = tableLength + 203;
        const stampImageWidth = 50;
        const stampImageHeight = 20;
        const stampImageX = (doc.internal.pageSize.width * 3) / 4 - stampImageWidth / 2;
        let nameText = duLieu.tenHieuTruong ? duLieu.tenHieuTruong : "";
        let nameTextWidth = doc.getTextWidth(nameText);
        let nameTextX = (doc.internal.pageSize.width * 3) / 4 - nameTextWidth / 2;
        doc.setFont("TimesBold");
        doc.text(nameText, nameTextX, nameTextY - 8);
        const imgStampWidth = this.imgStampWidth;
        const imgStampHeight = this.imgStampHeight;
        const stampX = nameTextX - imgStampWidth / 2 - 5;
        const stampY = nameTextY - imgStampHeight - 5;
        if (pathDau && !this.cauHinh.isDau) {
          doc.addImage(pathDau, "JPEG", stampX, stampY, imgStampWidth, imgStampHeight);
        }
        if (pathChuKyHieuTruong && !this.cauHinh.isAnAnhHieuTruong) {
          doc.addImage(
              pathChuKyHieuTruong,
              "JPEG",
              stampImageX + 4,
              stampY + imgStampHeight / 2 - stampImageHeight / 2 + 2,
              stampImageWidth,
              stampImageHeight
          );
        }
      }

      // // Ghi số trang hiện tại trên footer
      // const totalPages = doc.internal.getNumberOfPages();
      // doc.setFontSize(10);
      // doc.text(`Page ${pageNumber} of ${totalPages}`, 10, doc.internal.pageSize.height - 10);
    },
    displayPDF(pdfDataUrl) {
      console.log("displayPDF");
      console.log(pdfDataUrl);
      this.urlPdf = pdfDataUrl;
      // window.open(this.urlPdf, '_blank', 'noopener,noreferrer');
      return;
      // Hiển thị tệp PDF trong một thẻ iframe
      const iframe = document.createElement("iframe");
      iframe.src = pdfDataUrl;
      iframe.width = "100%";
      iframe.height = "600px";
      // Xóa nội dung cũ trong div
      const pdfViewer = document.getElementById("pdfViewer");
      pdfViewer.innerHTML = "";
      pdfViewer.appendChild(iframe);
    },
  },
};
</script>
<style></style>
