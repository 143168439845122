<template>
    <el-dialog
              :close-on-press-escape="false"
              :close-on-click-modal="false" title="Thông tin học bạ" append-to-body class="info-hocba-fullview" style="margin-top:0 !important;"
        :before-close="closeDialogCreate" :visible.sync="show" center>
        <div class="text-center">
            <div v-for="(hocba,index) in danh_sach_chi_tiet_hoc_ba" :key="index">
                <div v-if="hocba.loaiHocBa==1">
                    <div class="layout-a4 trangBia">
                        <div class="a4-body ">
                            <div class="bodyTrangBia">
                                <div class="nhanHocBa">
                                    <div class="noiDungNhan">
                                        <h6><b>Họ và tên học sinh:</b><span class="hocba-relative"><span
                                                    class="hocba-absolute">{{hocba.hoten}}</span>..............................................................................................................................</span>
                                        </h6>
                                        <h6><b>Trường:</b><span class="hocba-relative"><span
                                                    class="hocba-absolute">{{hocba.tenTruong}}</span>.................................................................................................................................................</span>
                                        </h6>
                                        <h6><b>Xã (Phường, Thị trấn):</b><span class="hocba-relative"><span
                                                    class="hocba-absolute">{{hocba.tenXa}}</span>........................................................................................................................</span>
                                        </h6>
                                        <h6><b>Huyện (Thành phố, Quận, Thị xã):</b><span class="hocba-relative"><span
                                                    class="hocba-absolute">{{hocba.tenHuyen}}</span>....................................................................................................</span>
                                        </h6>
                                        <h6><b>Tỉnh (Thành phố):</b><span class="hocba-relative"><span
                                                    class="hocba-absolute">{{hocba.tenTinh}}</span>................................................................................................................................</span>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Mặt bìa -->
                    <!-- Mặt 1 -->
                    <div class="layout-a4 trangHuongDan">
                        <div class="a4-body" style="margin:6mm 10mm">
                            <div class="tieuDeHocBa ">
                                <div class="huongDanGhiHocBa tieuDe">
                                    <h2>HƯỚNG DẪN GHI HỌC BẠ</h2>
                                </div>
                            </div>
                            <div class="noiDungHuongDan">
                                <p> Học bạ dùng để ghi kết quả tổng hợp đánh giá cuối năm học của học sinh. Khi ghi Học
                                    bạ,
                                    giáo viên cần nghiên cứu kĩ Thông tư số 27/2020/TT-BGDĐT ngày 04 tháng 09 năm 2020
                                    của
                                    Bộ
                                    trưởng Bộ Giáo dục và Đào tạo về việc ban hành quy định đánh giá học sinh tiểu học.
                                </p>
                                <br>
                                <p class="red">1.Trang 1, thông tin ghi theo giấy khai sinh của học sinh.</p>
                                <p class="red">2. Mục "1. Các môn học và hoạt động giáo dục"</p>
                                <ul>
                                    <li>
                                        <p>
                                            Trong cột "Mức đạt được": Ghi kí hiệu T nếu học sinh đạt mức "Hoàn thành
                                            tốt"; H
                                            nếu
                                            học sinh
                                            đạt mức "Hoàn thành" hoặc C nếu học sinh ở mức "Chưa hoàn thành".
                                        </p>
                                    </li>
                                    <li>
                                        <p> Trong cột "Điểm KTĐK" đối với các môn học có Bài kiểm tra định kì: ghi điểm
                                            số
                                            của
                                            bài
                                            kiểm
                                            tra cuối năm học; đối với học sinh được kiểm tra lại, ghi điểm số của bài
                                            kiểm
                                            tra
                                            lần
                                            cuối.</p>
                                    </li>
                                    <li>
                                        <p>
                                            Trong cột "Nhận xét": Ghi những điểm nổi bật về sự tiến bộ, năng khiếu, hứng
                                            thú
                                            học
                                            tập đối với
                                            các môn học, hoạt động giáo dục của học sinh; nội dung, kĩ năng chưa hoàn
                                            thành
                                            trong
                                            từng môn
                                            học, hoạt động giáo dục cần được khắc phục, giúp đỡ (nếu có).
                                        </p>
                                    </li>
                                </ul>
                                <!--  -->
                                <p class="red">3. Mục "2. Những phẩm chất chủ yếu" và mục "3. Những năng lực cốt lõi"
                                </p>
                                <ul>
                                    <li>
                                        <p>
                                            Trong cột "Mức đạt được" tương ứng với từng nội dung đánh giá về phẩm chất,
                                            năng
                                            lực:
                                            ghi kí
                                            hiệu T nếu học sinh đạt mức “Tốt”, Đ nếu học sinh đạt mức “Đạt” hoặc C nếu
                                            học
                                            sinh
                                            ở
                                            mức “Cần
                                            cố gắng”.
                                        </p>
                                    </li>
                                    <li>
                                        <p> Trong cột "Nhận xét" tương ứng với nội dung đánh giá về phẩm chất: ghi các
                                            biểu
                                            hiện, sự
                                            tiến
                                            bộ, ưu điểm, hạn chế hoặc khuyến nghị (nếu có) về sự hình thành và phát
                                            triển
                                            một số
                                            phẩm chất
                                            chủ yếu của học sinh. Ví dụ: Đi học đầy đủ, đúng giờ, mạnh dạn trình bày ý
                                            kiến
                                            cá
                                            nhân;
                                            biết giữ
                                            lời hứa; tôn trọng và biết giúp đỡ mọi người;..- Trong cột "Nhận xét" tương
                                            ứng
                                            với
                                            nội
                                            dung đánh giá về phẩm chất: ghi các biểu hiện, sự tiến
                                            bộ, ưu điểm, hạn chế hoặc khuyến nghị (nếu có) về sự hình thành và phát
                                            triển
                                            một số
                                            phẩm chất
                                            chủ yếu của học sinh. Ví dụ: Đi học đầy đủ, đúng giờ, mạnh dạn trình bày ý
                                            kiến
                                            cá
                                            nhân;
                                            biết giữ
                                            lời hứa; tôn trọng và biết giúp đỡ mọi người;..</p>
                                    </li>
                                    <li>
                                        <p>
                                            Trong cột "Nhận xét" tương ứng với nội dung đánh giá về năng lực: ghi các
                                            biểu
                                            hiện,
                                            sự
                                            tiến bộ,
                                            ưu điểm, hạn chế hoặc khuyến nghị (nếu có) về sự hình thành và phát triển
                                            một số
                                            năng
                                            lực chung,
                                            năng lực đặc thù của học sinh.
                                        </p>
                                    </li>
                                </ul>
                                <p>Ví dụ: Biết vệ sinh thân thể, ăn mặc gọn gàng; chủ động, phối hợp trong học tập; có
                                    khả
                                    năng
                                    tự
                                    học; sử dụng ngôn ngữ lưu loát trong cuộc sống và học tập, biết tư duy, lập luận và
                                    giải
                                    quyết
                                    được một số vấn đề toán học quen thuộc;...
                                </p>
                                <!--  -->
                                <p class="red">4. Mục "4. Đánh giá kết quả giáo dục"</p>
                                <p>Ghi một trong bốn mức: “Hoàn thành xuất sắc”, “Hoàn thành tốt”, “Hoàn thành hoặc
                                    “Chưa
                                    hoàn
                                    thành”.</p> <!--  -->
                                <p class="red">5. Mục "5. Khen thưởng":</p>
                                <p>Ghi những thành tích mà học sinh được khen thưởng trong năm học.</p>
                                <p>Ví dụ: Đạt danh hiệu Học sinh Xuất sắc; Đạt danh hiệu Học sinh Tiêu biểu hoành thành
                                    tốt
                                    trong
                                    học tập và rèn luyện; Đạt giải Nhì hội giao lưu An toàn giao thông cấp huyện;</p>
                                <p class="red">6. Mục "6. Hoàn thành chương trình lớp học/chương trình tiểu học".</p>
                                <p>Ghi Hoàn thành chương trình lớp....../chương trình tiểu học hoặc Chưa hoàn thành
                                    chương
                                    trình
                                    lớp...../chương trình tiểu học; Được lên lớp hoặc Chưa được lên lớp.</p>
                                <p>Ví dụ:
                                    - Hoàn thành chương trình lớp 2; Được lên lớp 3; Hoàn thành chương trình tiểu học.
                                </p>
                            </div>
                            <div class="phanCuoiHuongDan">
                                <br>
                                <p style="font-size: 14px;">Học bạ được nhà trường bảo quản và trả lại cho học sinh khi
                                    học
                                    sinh
                                    chuyển trường, học xong chương trình tiểu học</p>
                            </div>
                        </div>
                    </div>
                    <!-- Hết mặt 1 -->
                    <!-- Mặt trang 2 -->
                    <div class="layout-a4 hinhNenVang2">
                        <div class="a4-body ">
                            <div class="tieuDeHocBa">
                                <div class="chuHocBa tieuDe">
                                    <h2>HỌC BẠ</h2>
                                </div>
                            </div>
                            <div class="thongTinCoBanHocSinh">
                                <div style="text-align: left;">
                                    <span><span>Họ và tên học sinh:</span>
                                        <span class="hocba-relative"><span
                                                class="hocba-absolute">{{hocba.hoten}}</span>....................................................................................................................................</span></span>
                                    <span>Giới tính:<span class="hocba-relative">
                                            <span
                                                class="hocba-absolute">{{getGioiTinh(hocba.gioiTinh)}}</span>.................................</span></span>
                                    <div style="padding-top:5mm;">
                                        <span>Ngày, tháng, năm sinh:
                                            <span class="hocba-relative"> <span
                                                    class="hocba-absolute">{{hocba.ngaySinh}}</span>.......................................................................................</span>
                                            Dân tộc: <span class="hocba-relative"><span
                                                    class="hocba-absolute">{{hocba.tenDanToc}}</span>.......................</span>
                                            Quốc tịch <span class="hocba-relative"><span
                                                    class="hocba-absolute">{{hocba.tenQuocTich}}</span>.................................</span></span>
                                    </div>
                                    <div style="padding-top:4mm;">
                                        <span><span></span>Nơi
                                            sinh: <span class="hocba-relative"> <span
                                                    class="hocba-absolute">{{hocba.noiSinh}}</span>
                                                .......................................................................................................................................................................................................</span></span>
                                    </div>
                                    <div style="padding-top:4mm;">
                                        <span><span></span>Quê
                                            quán: <span class="hocba-relative"> <span
                                                    class="hocba-absolute">{{hocba.queQuan}}</span>
                                                ......................................................................................................................................................................................................</span></span>
                                    </div>
                                    <div style="padding-top:4mm;">
                                        <span><span></span>Nơi ở hiện
                                            nay: <span class="hocba-relative"> <span
                                                    class="hocba-absolute">{{hocba.noiOHienNay}}</span>..............................................................................................................................................................................................</span></span>
                                    </div>
                                    <div style="padding-top:4mm;">
                                        <span><span></span>Họ và tên
                                            cha: <span class="hocba-relative"> <span
                                                    class="hocba-absolute">{{hocba.hoTenCha}}</span>................................................................................................................................................................................................</span></span>
                                    </div>
                                    <div style="padding-top:4mm;">
                                        <span><span></span>Họ và tên
                                            mẹ: <span class="hocba-relative"> <span
                                                    class="hocba-absolute">{{hocba.hoTenMe}}</span>.................................................................................................................................................................................................</span></span>
                                    </div>
                                    <div style="padding-top:4mm;">
                                        <span><span></span>Người giám hộ (nếu có): <span class="hocba-relative"> <span
                                                    class="hocba-absolute">{{hocba.nguoiGiamHo}}</span>................................................................................................................................................................................</span></span>
                                    </div>
                                </div>
                                <div style="padding-top:15mm;display: flex">
                                    <div class="width50"></div>
                                    <div class="width50 hieuTruong">
                                        <div class="xacNhan">
                                            <i v-if="hocba.ngayKyBia">{{hocba.ngayKyBia}}</i>
                                            <i v-else>..................., ngày....... tháng....... năm 20......</i>
                                            <p><b>HIỆU TRƯỞNG</b></p>
                                            <i>(Kí, ghi rõ họ tên và đóng dấu)</i>
                                        </div>
                                        <div class="chuKyHieuTruong hocba-relative">
                                            <!-- <img style="position:absolute;left:25%;top:0;"
                                            src="https://lh3.googleusercontent.com/proxy/0Pn5ZKYBeH2HsgczaDCMEgRHFUJCDU9usFNAo3DaoWVSh3f656kU-P56j0wI2x1FAKKJMyoZ1sdAYRmSoW3ocrS-L9bZ6BZ0LmjGkW7MyOBSqCPOZPzXD0pb7lphg6io4Z8"
                                            alt="">
                                        <img style="position:absolute;bottom:20px;right:25%; width:30%;height:30%"
                                            src="https://files.vfo.vn/2013/T03/img/DDBL-60517-5d4dffc07f2d1ab864eb463180f89d91-41046761-chukydep3.png"
                                            alt=""> -->
                                            <img style="position:absolute;left:25%;top:0;" :src="hocba.pathDau" alt="">
                                            <img style="position:absolute;bottom:20px;right:25%; width:30%;height:30%"
                                                :src="hocba.pathChuKyHieuTruong" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="">
                                <div class="quaTrinhHocTap tieuDe">
                                    <h2>QUÁ TRÌNH HỌC TẬP</h2>
                                </div>
                                <div style="padding-top:10mm">
                                    <table style="width:100%">
                                        <tr class="text-center">
                                            <th style="width: 30mm;">
                                                <p><b>Năm học</b></p>
                                            </th>
                                            <th style="width: 15mm;">
                                                <p><b>Lớp</b></p>
                                            </th>
                                            <th style="width: 60mm;">
                                                <p><b>Tên trường</b></p>
                                            </th>
                                            <th style="width: 20mm;">
                                                <p><b>Số đăng bộ</b></p>
                                            </th>
                                            <th style="width: 30mm;">
                                                <p><b>Ngày nhập học/ <br> chuyển đến</b></p>
                                            </th>
                                        </tr>
                                        <tr v-for="(namhoc,index) in hocba.quaTrinhHocTaps" :key="index"
                                            class="moiHang">
                                            <td>
                                                <p>{{namhoc.namHoc}}</p>
                                            </td>
                                            <td>
                                                <p>{{namhoc.tenLop}}</p>
                                            </td>
                                            <td>
                                                <p>{{namhoc.tenTruong}}</p>
                                            </td>
                                            <td>
                                                <p>{{namhoc.soDangBo}}</p>
                                            </td>
                                            <td>
                                                <p>{{namhoc.ngayNhapHoc}}</p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Hết mặt trang 2 -->
                    <!-- Mặt trang 3 -->
                    <div class="layout-a4 hinhNenXanh">
                        <div class="a4-body">
                            <div class="thongTinHocSinh">
                                <div style="margin: 8mm;text-align: left;">
                                    <span><span><b>Họ và tên học sinh:</b>
                                            <span class="hocba-relative">
                                                <span class="hocba-absolute">{{hocba.hoten}}</span>
                                                ....................................................................................................................</span>
                                        </span></span>
                                    <span><b>Lớp: </b><span class="hocba-relative"> <span
                                                class="hocba-absolute">{{hocba.tenLop}}</span>
                                            ........................................</span></span>
                                    <div style="padding-top:5mm;">
                                        <span>Chiều cao:
                                            <span class="hocba-relative"> <span
                                                    class="hocba-absolute">{{hocba.chieuCao}}</span>
                                                .................................................................................................</span>
                                            Cân
                                            nặng: <span class="hocba-relative"> <span
                                                    class="hocba-absolute">{{hocba.canNang}}</span>
                                                ...................................................................</span>
                                        </span>
                                    </div>
                                    <div style="padding-top:2mm;">
                                        <span>Số ngày nghỉ có phép:
                                            <span class="hocba-relative"><span
                                                    class="hocba-absolute">{{hocba.soNgayNghiCoPhep}}</span>..............................................................................</span>
                                            Số ngày nghỉ
                                            không
                                            phép: <span class="hocba-relative"> <span
                                                    class="hocba-absolute">{{hocba.soNgayNghiKhongPhep}}</span>
                                                ..........................................</span> </span>
                                    </div>
                                </div>
                                <div>
                                </div>
                            </div>
                            <div class="monHocHoatDongGiaoDuc">
                                <div style="text-align: left;">
                                    <h6><b class="red">1.Các môn học và hoạt động giáo dục</b></h6>
                                    <div style="margin-top:5mm;">
                                        <table style="width:100%">
                                            <tr class="text-center">
                                                <th style="width: 42mm;">
                                                    <p><b>Môn học và <br> hoạt động giáo dục</b></p>
                                                </th>
                                                <th style="width: 20mm;">
                                                    <p><b>Mức đạt được</b></p>
                                                </th>
                                                <th style="width: 20mm;">
                                                    <p><b>Điểm KTĐK</b></p>
                                                </th>
                                                <th>
                                                    <p><b>Nhận xét</b></p>
                                                </th>
                                            </tr>
                                            <tr class="moiHang">
                                                <td>
                                                    <p>Tiếng Việt</p>
                                                </td>
                                                <td>
                                                    <p>{{hocba.tvDgtx}}</p>
                                                </td>
                                                <td>
                                                    <p>{{hocba.tvDiem}}</p>
                                                </td>
                                                <td rowspan="13">
                                                    <div style="padding:5mm 8mm">
                                                        <p style="text-align:left" v-html="hocba.ghiChu"></p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="moiHang">
                                                <td>
                                                    <p>Toán</p>
                                                </td>
                                                <td>
                                                    <p>{{hocba.toDgtx}}</p>
                                                </td>
                                                <td>
                                                    <p>{{hocba.toDiem}}</p>
                                                </td>
                                            </tr>
                                            <tr class="moiHang">
                                                <td>
                                                    <p>Ngoại ngữ 1</p>
                                                </td>
                                                <td>
                                                    <p>{{hocba.nnDgtx}}</p>
                                                </td>
                                                <td>
                                                    <p>{{hocba.nnDiem}}</p>
                                                </td>
                                            </tr>
                                            <tr class="moiHang">
                                                <td>
                                                    <p>Lịch sử và Địa lí</p>
                                                </td>
                                                <td>
                                                    <p>{{hocba.ldDgtx}}</p>
                                                </td>
                                                <td>
                                                    <p>{{hocba.ldDiem}}</p>
                                                </td>
                                            </tr>
                                            <tr class="moiHang">
                                                <td>
                                                    <p>Khoa học</p>
                                                </td>
                                                <td>
                                                    <p>{{hocba.khDgtx}}</p>
                                                </td>
                                                <td>
                                                    <p>{{hocba.khDiem}}</p>
                                                </td>
                                            </tr>
                                            <tr class="moiHang">
                                                <td>
                                                    <p>Tin học và Công nghệ</p>
                                                </td>
                                                <td>
                                                    <p>{{hocba.thDgtx}}</p>
                                                </td>
                                                <td>
                                                    <p>{{hocba.thDiem}}</p>
                                                </td>
                                            </tr>
                                            <tr class="moiHang">
                                                <td>
                                                    <p>Đạo đức</p>
                                                </td>
                                                <td>
                                                    <p>{{hocba.ddDgtx}}</p>
                                                </td>
                                                <td rowspan="7"></td>
                                            </tr>
                                            <tr class="moiHang">
                                                <td>
                                                    <p>Tự nhiên và Xã hội</p>
                                                </td>
                                                <td>
                                                    <p>{{hocba.txDgtx}}</p>
                                                </td>
                                            </tr>
                                            <tr class="moiHang">
                                                <td>
                                                    <p>Giáo dục thể chất</p>
                                                </td>
                                                <td>
                                                    <p>{{hocba.tdDgtx}}</p>
                                                </td>
                                            </tr>
                                            <tr class="moiHang">
                                                <td>
                                                    <p>Nghệ thuật (Âm nhạc)</p>
                                                </td>
                                                <td>
                                                    <p>{{hocba.anDgtx}}</p>
                                                </td>
                                            </tr>
                                            <tr class="moiHang">
                                                <td>
                                                    <p>Nghệ thuật (Mĩ thuật)</p>
                                                </td>
                                                <td>
                                                    <p>{{hocba.mtDgtx}}</p>
                                                </td>
                                            </tr>
                                            <tr class="moiHang">
                                                <td>
                                                    <p>Hoạt động trải nghiệm</p>
                                                </td>
                                                <td>
                                                    <p>{{hocba.hdtnDgtx}}</p>
                                                </td>
                                            </tr>
                                            <tr class="moiHang">
                                                <td>
                                                    <p>Tiếng dân tộc</p>
                                                </td>
                                                <td>
                                                    <p>{{hocba.dtDgtx}}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="phamChatChuYeu">
                                <div style="text-align: left;">
                                    <h6><b class="red">2.Những phẩm chất chủ yếu</b></h6>
                                    <div style="margin-top:5mm;">
                                        <table style="width:100%">
                                            <tr class="text-center" style="height:8mm">
                                                <th style="width: 26mm;">
                                                    <p><b>Phẩm chất</b></p>
                                                </th>
                                                <th style="width: 30mm;">
                                                    <p><b>Mức đạt được</b></p>
                                                </th>
                                                <th>
                                                    <p><b>Nhận xét</b></p>
                                                </th>
                                            </tr>
                                            <tr class="moiHang">
                                                <td>
                                                    <p>Yêu nước</p>
                                                </td>
                                                <td>
                                                    <p>{{hocba.pcYeuNuoc}}</p>
                                                </td>
                                                <td rowspan="5">
                                                </td>
                                            </tr>
                                            <tr class="moiHang">
                                                <td>
                                                    <p>Nhân ái</p>
                                                </td>
                                                <td>
                                                    <p>{{hocba.pcNhanAi}}</p>
                                                </td>
                                            </tr>
                                            <tr class="moiHang">
                                                <td>
                                                    <p>Chăm chỉ</p>
                                                </td>
                                                <td>
                                                    <p>{{hocba.pcChamChi}}</p>
                                                </td>
                                            </tr>
                                            <tr class="moiHang">
                                                <td>
                                                    <p>Trung thực</p>
                                                </td>
                                                <td>
                                                    <p>{{hocba.pcTrungThuc}}</p>
                                                </td>
                                            </tr>
                                            <tr class="moiHang">
                                                <td>
                                                    <p>Trách nhiệm</p>
                                                </td>
                                                <td>
                                                    <p>{{hocba.pcTrachNhiem}}</p>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Hết mặt trang 3 -->
                    <!-- Sang mặt trang 4 -->
                    <div class="layout-a4 hinhNenXanh">
                        <div class="a4-body">
                            <div class="thongTinTruong">
                                <div style="margin: 6mm;text-align: left;">
                                    <span><span><b>Trường:</b></span>
                                        <span class="hocba-relative"><span
                                                class="hocba-absolute">{{hocba.tenTruong}}</span>
                                            .........................................................................................................................</span></span>
                                    <span><b>Năm học: </b><span class="hocba-relative"> <span
                                                class="hocba-absolute">{{hocba.namHoc}} - {{hocba.namHoc+1}}</span>
                                            ...............................................</span></span>
                                </div>
                                <div>
                                </div>
                            </div>
                            <div class="nangLucCotLoi">
                                <div style="text-align: left;">
                                    <h6 style="margin-bottom:4px"><b class="red">3.Những năng lực cốt lõi</b></h6>
                                    <p class="red"><i class="red">3.1. Những năng lực chung</i></p>
                                    <div style="margin-top:3mm;">
                                        <table style="width:100%">
                                            <tr class="text-center" style="height:8mm">
                                                <th style="width: 40mm;">
                                                    <p><b>Năng lực</b></p>
                                                </th>
                                                <th style="width: 30mm;">
                                                    <p><b>Mức đạt được</b></p>
                                                </th>
                                                <th>
                                                    <p><b>Nhận xét</b></p>
                                                </th>
                                            </tr>
                                            <tr class="moiHang">
                                                <td>
                                                    <p>Tự chủ và tự học</p>
                                                </td>
                                                <td>
                                                    <p>{{hocba.nlTuChuTuHoc}}</p>
                                                </td>
                                                <td rowspan="3">
                                                    <p>{{hocba.nhanXet}}</p>
                                                </td>
                                            </tr>
                                            <tr class="moiHang">
                                                <td>
                                                    <p>Giao tiếp và hợp tác</p>
                                                </td>
                                                <td>
                                                    <p>{{hocba.nlGiaoTiepHopTac}}</p>
                                                </td>
                                            </tr>
                                            <tr class="moiHang">
                                                <td>
                                                    <p>Giải quyết vấn đề <br> và sáng tạo</p>
                                                </td>
                                                <td>
                                                    <p>{{hocba.nlGquyetVdeSangTao}}</p>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <br>
                                    <p class="red"><i class="red">3.2. Những năng lực đặc thù</i></p>
                                    <div style="margin-top:3mm;">
                                        <table style="width:100%">
                                            <tr class="text-center" style="height:8mm">
                                                <th style="width: 25mm;">
                                                    <p><b>Năng lực</b></p>
                                                </th>
                                                <th style="width: 30mm;">
                                                    <p><b>Mức đạt được</b></p>
                                                </th>
                                                <th>
                                                    <p><b>Nhận xét</b></p>
                                                </th>
                                            </tr>
                                            <tr class="moiHang">
                                                <td>
                                                    <p>Ngôn ngữ</p>
                                                </td>
                                                <td>
                                                    <p>{{hocba.nlNgonNgu}}</p>
                                                </td>
                                                <td rowspan="7">
                                                    <p>{{hocba.nhanXet}}</p>
                                                </td>
                                            </tr>
                                            <tr class="moiHang">
                                                <td>
                                                    <p>Tính toán</p>
                                                </td>
                                                <td>
                                                    <p>{{hocba.nlTinhToan}}</p>
                                                </td>
                                            </tr>
                                            <tr class="moiHang">
                                                <td>
                                                    <p>Khoa học</p>
                                                </td>
                                                <td>
                                                    <p>{{hocba.nlKhoaHoc}}</p>
                                                </td>
                                            </tr>
                                            <tr class="moiHang">
                                                <td>
                                                    <p>Công nghệ</p>
                                                </td>
                                                <td>
                                                    <p>{{hocba.nlCongNghe}}</p>
                                                </td>
                                            </tr>
                                            <tr class="moiHang">
                                                <td>
                                                    <p>Tin học</p>
                                                </td>
                                                <td>
                                                    <p>{{hocba.nlTinHoc}}</p>
                                                </td>
                                            </tr>
                                            <tr class="moiHang">
                                                <td>
                                                    <p>Thẩm mĩ</p>
                                                </td>
                                                <td>
                                                    <p>{{hocba.nlThamMi}}</p>
                                                </td>
                                            </tr>
                                            <tr class="moiHang">
                                                <td>
                                                    <p>Thể chất</p>
                                                </td>
                                                <td>
                                                    <p>{{hocba.nlTheChat}}</p>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="danhGiaKetQuaGiaoDuc" style="padding-top:5mm">
                                <div style="text-align: left;">
                                    <h6 style="margin-bottom:4px"><b class="red">4.Đánh giá kết quả giáo
                                            dục:</b><span class="hocba-relative"><span
                                                class="hocba-absolute">{{hocba.danhGiaKetQuaGiaoDuc}}</span>
                                            ...........................................................................................................................................................................</span>
                                    </h6>
                                </div>
                            </div>
                            <div class="khenThuong" style="padding-top:5mm">
                                <div style="text-align: left;">
                                    <h6 style="margin-bottom:6px"><b class="red">5.Khen
                                            thưởng:</b> <span class="hocba-relative"> <span
                                                class="hocba-absolute">{{hocba.khenThuong}}</span>
                                            ...............................................................................................................................................................................................
                                        </span>
                                    </h6>
                                </div>
                            </div>
                            <div class="hoanThanhChuongTrinhLopHoc" style="padding-top:5mm">
                                <div style="text-align: left;">
                                    <h6 style="margin-bottom:6px"><b class="red">6.Hoàn thành chương trình lớp
                                            học/chương
                                            trình
                                            tiểu
                                            học:</b> <span class="hocba-relative"> <span
                                                class="hocba-absolute">{{hocba.hoanThanhChuongTrinh}}</span>
                                            .......................................................................................................................</span>
                                    </h6>
                                </div>
                            </div>
                            <div class="ngayThang" style="padding-top:6mm">
                                <div style="text-align:right">
                                    <i v-if="hocba.thoiGianHieuTruongKy">{{hocba.thoiGianHieuTruongKy}}</i>
                                    <i v-else>..................., ngày....... tháng....... năm 20......</i>
                                </div>
                            </div>
                            <div class="chuKy" style="">
                                <div class="width50 hieuTruong">
                                    <div class="xacNhan">
                                        <p><b>Xác nhận của Hiệu trưởng</b></p>
                                        <i>(Kí, ghi rõ họ tên và đóng dấu)</i>
                                    </div>
                                    <div class="chuKyHieuTruong hocba-relative">
                                        <!-- <img style="position:absolute;left:25%;top:0;"
                                        src="https://lh3.googleusercontent.com/proxy/0Pn5ZKYBeH2HsgczaDCMEgRHFUJCDU9usFNAo3DaoWVSh3f656kU-P56j0wI2x1FAKKJMyoZ1sdAYRmSoW3ocrS-L9bZ6BZ0LmjGkW7MyOBSqCPOZPzXD0pb7lphg6io4Z8"
                                        alt="">
                                    <img style="position:absolute;bottom:20px;right:25%; width:30%;height:30%"
                                        src="https://files.vfo.vn/2013/T03/img/DDBL-60517-5d4dffc07f2d1ab864eb463180f89d91-41046761-chukydep3.png"
                                        alt=""> -->
                                        <img style="position:absolute;left:25%;top:0;" :src="hocba.pathDau" alt="">
                                        <img style="position:absolute;bottom:20px;right:25%; width:30%;height:30%"
                                            :src="hocba.pathChuKyHieuTruong" alt="">
                                    </div>
                                </div>
                                <div class="width50 giaoVienChuNhiem">
                                    <div>
                                        <p><b>Giáo viên chủ nhiệm</b></p>
                                        <i>(Kí và ghi rõ họ tên)</i>
                                    </div>
                                    <div class="chuKyHieuTruong hocba-relative">
                                        <img style="position:absolute;top:20px;left:35%; width:30%;height:30%"
                                            :src="hocba.pathChuKyGVCN" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="text-center">
                        <div class="layout-a4 an ">
                            <div class="a4-body c2-trangBia center">
                                <div class="c2-tieuDe">
                                    <h2>SỞ GIÁO DỤC VÀ ĐÀO TẠO</h2>
                                </div>
                                <div class="c2-nhanHocBa">
                                    <div class="c2-chuHocBa">
                                        <span style="font-size:90px">HỌC BẠ</span>
                                    </div>
                                    <div class="c2-tenCapHocBa">
                                        <span style="font-size:23px">TRUNG HỌC CƠ SỞ</span>
                                    </div>
                                </div>
                                <div class="c2-tenHocSinh">
                                    <h3><b>Họ và tên học sinh</b></h3>
                                    <h3><b class="hocba-relative"><span
                                                class="hocba-absolute">{{hocba.hoten}}</span>...................................................</b>
                                    </h3>
                                </div>
                                <div class="c2-soHocBa">
                                    <h3><b>Số: {{hocba.maSoHocBa}}</b></h3>
                                </div>
                            </div>
                        </div>
                        <!--  -->
                        <div class="layout-a4 an c2-thongTinChung">
                            <div class="a4-body ">
                                <div class="c2-quocHieuTieuNgu center">
                                    <p><b>CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</b></p>
                                    <p><b>Độc lập - Tự do - Hạnh phúc</b></p>
                                </div>
                                <div class="center" style="padding-top:15mm">
                                    <span><b style="font-size: 35px;">HỌC BẠ</b></span>
                                    <br>
                                    <span><b style="font-size: 14px;">TRUNG HỌC CƠ SỞ</b></span>
                                </div>
                                <div class="c2-noiDungThongTin left">
                                    <p>Họ và tên: <span class="hocba-relative"> <span
                                                class="hocba-absolute">{{hocba.hoten}}</span>.................................................................................................................................................</span>
                                        Giới tính: <span class="hocba-relative"><span
                                                class="hocba-absolute">{{getGioiTinh(hocba.gioiTinh)}}</span>.....................................</span>
                                    </p>
                                    <p>Nơi sinh: <span class="hocba-relative"> <span
                                                class="hocba-absolute">{{hocba.noiSinh}}</span>.........................................................................................................................................................................................................</span>
                                    </p>
                                    <p>Dân tộc: <span class="hocba-relative"> <span
                                                class="hocba-absolute">{{hocba.tenDanToc}}</span>..........................................................................................................................................................................................................</span>
                                    </p>
                                    <p>Con liệt sĩ, con thương binh (bệnh binh, người được hưởng chế độ như thương binh,
                                        gia
                                        đình có
                                        công với cách mạng).............................</p>
                                    <p class="hocba-relative"> <span
                                            class="hocba-absolute">{{hocba.tenDtuongCsach}}</span>.........................................................................................................................................................................................................................
                                    </p>
                                    <p>Chỗ ở hiện nay: <span class="hocba-relative"> <span
                                                class="hocba-absolute">{{hocba.noiOHienNay}}</span>..............................................................................................................................................................................................</span>
                                    </p>
                                    <p>Họ và tên cha: <span class="hocba-relative"> <span
                                                class="hocba-absolute">{{hocba.hoTenCha}}</span>.....................................................................................................................</span>
                                        Nghề nghiệp: <span class="hocba-relative"><span
                                                class="hocba-absolute">{{hocba.ngheNghiepCha}}</span>...................................................</span>
                                    </p>
                                    <p>Họ và tên mẹ: <span class="hocba-relative"> <span
                                                class="hocba-absolute">{{hocba.hoTenMe}}</span>.....................................................................................................................</span>
                                        Nghề nghiệp: <span class="hocba-relative"><span
                                                class="hocba-absolute">{{hocba.ngheNghiepMe}}</span>...................................................</span>
                                    </p>
                                    <p>Họ và tên người giám hộ: <span class="hocba-relative"> <span
                                                class="hocba-absolute">{{hocba.nguoiGiamHo}}</span>...................................................................................................</span>
                                        Nghề
                                        nghiệp: <span class="hocba-relative"><span
                                                class="hocba-absolute">{{hocba.ngheNghiepNguoiGiamHo}}</span>...................................................</span>
                                    </p>
                                </div>
                                <div class="c2-chuKyHieuTruong" style="padding-top:15mm;display: flex">
                                    <div class="width50"></div>
                                    <div class="width50 hieuTruong hocba-relative center">
                                        <div class="xacNhan">
                                            <p><b>HIỆU TRƯỞNG</b></p>
                                            <i style="font-size: 13px;">(Kí, ghi rõ họ tên và đóng dấu)</i>
                                        </div>
                                        <div class="c2-chuKyHieuTruong">
                                            <img style="position:absolute;height:100%; width:auto; left:35%;top:25%;"
                                                :src="hocba.pathDau" alt="">
                                            <img style="position:absolute;bottom:10px;right:25%; width:30%;height:30%"
                                                :src="hocba.pathChuKyHieuTruong" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="c2-quaTrinhHocTap">
                                    <div class="center">
                                        <span><b>QUÁ TRÌNH HỌC TẬP</b></span>
                                    </div>
                                    <table style="width:100%;margin-top:5mm">
                                        <tr class="text-center">
                                            <th style="width: 30mm;">
                                                <p><b>Năm học</b></p>
                                            </th>
                                            <th style="width: 15mm;">
                                                <p><b>Lớp</b></p>
                                            </th>
                                            <th style="width: 60mm;">
                                                <p><b>Tên trường</b></p>
                                            </th>
                                            <th style="width: 20mm;">
                                                <p><b>Số đăng bộ</b></p>
                                            </th>
                                            <th style="width: 30mm;">
                                                <p><b>Ngày nhập học/ <br> chuyển đến</b></p>
                                            </th>
                                        </tr>
                                        <tr v-for="item in hocba.quaTrinhHocTaps" class="moiHang">
                                            <td>
                                                <p>{{item.namHoc}}</p>
                                            </td>
                                            <td>
                                                <p>{{item.tenLop}}</p>
                                            </td>
                                            <td>
                                                <p>{{item.tenTruong}}</p>
                                            </td>
                                            <td>
                                                <p>{{item.soDangBo}}</p>
                                            </td>
                                            <td>
                                                <p>{{item.ngayNhapHoc}}</p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <!--  -->
                        <div class="layout-a4 c2-thongTinChung">
                            <div class="a4-body ">
                                <div class="left">
                                    <p><b>Họ và tên:</b> <span class="hocba-relative"> <span
                                                class="hocba-absolute">{{hocba.hoten}}</span>.........................................................................................<b>Lớp:</b><span
                                                class="hocba-relative"> <span
                                                    class="hocba-absolute">{{hocba.tenLop}}</span>...............................</span></span>
                                        <b>Năm học:</b> <span class="hocba-relative"><span
                                                class="hocba-absolute">{{hocba.namHoc}} -
                                                {{hocba.namHoc+1}}</span>................................................</span>
                                    </p>
                                </div>
                                <div class="c2-diemQuaTrinhHocTap" style="padding-top:5mm">
                                    <table style="width:100%;margin-top:5mm">
                                        <tr class="text-center">
                                            <th rowspan="2" style="width:30mm">
                                                <p><b>Môn học/Hoạt động GD</b></p>
                                            </th>
                                            <th colspan="3" style="width:140mm">
                                                <p><b>Điểm trung bình hoặc xếp
                                                        loại các môn</b></p>
                                            </th>
                                            <th rowspan="2">
                                                <p><b>Điểm hoặc
                                                        xếp loại
                                                        sau KT lại
                                                        (nếu có)</b></p>
                                            </th>
                                            <th rowspan="2">
                                                <p><b>Giáo viên bộ môn ký xác nhận ĐTB
                                                        môn học hoặc xếp loại và sửa chữa (nếu có), (Ký và ghi rõ họ
                                                        tên)</b></p>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th>
                                                <p><b>H Kỳ I</b></p>
                                            </th>
                                            <th>
                                                <p><b>H Kỳ II</b></p>
                                            </th>
                                            <th>
                                                <p><b>CN</b></p>
                                            </th>
                                        </tr>
                                        <tr v-for="kqht in hocba.ketQuaHocTaps" class="center moiHang">
                                            <td>
                                                <p>{{kqht.tenMonHoc}}</p>
                                            </td>
                                            <td>
                                                <p>{{kqht.diemHocKyI}}</p>
                                            </td>
                                            <td>
                                                <p>{{kqht.diemHocKyII}}</p>
                                            </td>
                                            <td>
                                                <p>{{kqht.diemCaNam}}</p>
                                            </td>
                                            <td>
                                                <p>{{kqht.diemThiLai}}</p>
                                            </td>
                                            <td>
                                                <p><img :src="kqht.pathChuKyGV" alt="Chữ ký"> - {{kqht.tenGiaoVien}}</p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="left" style="padding-top:5mm">
                                    <p><b>Trong bảng này có sửa chữa:</b> <span class="hocba-relative"> <span
                                                class="hocba-absolute"></span>..............chỗ, thuộc các môn:
                                            <span>.........................................................................................................................</span>
                                        </span> </p>
                                </div>
                                <div class="c2-chuKy chuKy" style="padding-top:10mm">
                                    <div class="width50 giaoVienChuNhiem">
                                        <div>
                                            <p><b>Xác nhận của giáo viên chủ nhiệm</b></p>
                                            <i>(Kí và ghi rõ họ tên)</i>
                                        </div>
                                        <div class="chuKyHieuTruong hocba-relative">
                                            <img style="position:absolute;top:20px;left:35%; width:30%;height:30%"
                                                :src="hocba.pathChuKyGVCN" alt="">
                                        </div>
                                    </div>
                                    <div class="width50 hieuTruong">
                                        <div class="xacNhan">
                                            <p><b>Xác nhận của Hiệu trưởng</b></p>
                                            <i>(Ký, ghi rõ họ tên và đóng dấu)</i>
                                        </div>
                                        <div class="chuKyHieuTruong hocba-relative">
                                            <!-- <img style="position:absolute;left:25%;top:0;"
                                        src="https://lh3.googleusercontent.com/proxy/0Pn5ZKYBeH2HsgczaDCMEgRHFUJCDU9usFNAo3DaoWVSh3f656kU-P56j0wI2x1FAKKJMyoZ1sdAYRmSoW3ocrS-L9bZ6BZ0LmjGkW7MyOBSqCPOZPzXD0pb7lphg6io4Z8"
                                        alt="">
                                    <img style="position:absolute;bottom:20px;right:25%; width:30%;height:30%"
                                        src="https://files.vfo.vn/2013/T03/img/DDBL-60517-5d4dffc07f2d1ab864eb463180f89d91-41046761-chukydep3.png"
                                        alt=""> -->
                                            <img style="position:absolute;left:25%;top:0;" :src="hocba.pathDau" alt="">
                                            <img style="position:absolute;bottom:20px;right:25%; width:30%;height:30%"
                                                :src="hocba.pathChuKyHieuTruong" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--  -->
                        <div class="layout-a4 c2-thongTinChung">
                            <div class="a4-body ">
                                <div class="left">
                                    <p><b>Trường:</b> <span class="hocba-relative"> <span
                                                class="hocba-absolute">{{hocba.tenTruong}}</span>................................................................................<b>Huyện:</b><span
                                                class="hocba-relative"> <span
                                                    class="hocba-absolute">{{hocba.tenHuyen}}</span>...................................................</span></span>
                                        <b>Tỉnh:</b> <span class="hocba-relative"><span
                                                class="hocba-absolute">{{hocba.tenTinh}}</span>................................................</span>
                                    </p>
                                </div>
                                <div class="c2-diemQuaTrinhHocTap" style="padding-top:5mm">
                                    <table style="width:100%;margin-top:5mm">
                                        <tr class="text-center">
                                            <th rowspan="2" style="width:30mm">
                                                <p><b>Học kỳ</b></p>
                                            </th>
                                            <th colspan="2">
                                                <p><b>Kết quả xếp loại</b></p>
                                            </th>
                                            <th rowspan="2">
                                                <p><b>TS buổi nghỉ học cả năm</b></p>
                                            </th>
                                            <th colspan="2">
                                                <p><b>Xếp loại sau KT lại các môn học hoặc rèn luyện thêm về HK</b></p>
                                            </th>
                                            <th rowspan="5" class="left">
                                                <p>
                                                    <b>Được lên lớp thẳng</b>
                                                    <span style="font-weight:100" class="hocba-relative"> <span
                                                            class="hocba-absolute">{{hocba.lenThangLop}}</span>..................................................................</span>
                                                </p>
                                                <p>
                                                    <b>Được lên lớp sau khi KT lại <br>
                                                        môn học hoặc rèn luyện
                                                        thêm về HK:
                                                    </b>
                                                    <span style="font-weight:100" class="hocba-relative"> <span
                                                            class="hocba-absolute">{{hocba.lenLopSauThiLai}}</span>..................................................................</span>
                                                </p>
                                                <p>
                                                    <b>Không được lên lớp:</b>
                                                    <span style="font-weight:100" class="hocba-relative"> <span
                                                            class="hocba-absolute">{{hocba.khongDuocLenLop}}</span>..................................................................</span>
                                                </p>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th>
                                                <p><b>HK</b></p>
                                            </th>
                                            <th>
                                                <p><b>HL</b></p>
                                            </th>
                                            <th>
                                                <p><b>HK</b></p>
                                            </th>
                                            <th>
                                                <p><b>HL</b></p>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th>
                                                <p><b>Học kỳ I</b></p>
                                            </th>
                                            <th>
                                                <p><b>{{hocba.hanhKiemHKI}}</b></p>
                                            </th>
                                            <th>
                                                <p><b>{{hocba.hocLucHKI}}</b></p>
                                            </th>
                                            <th rowspan="3">
                                                <p><b>{{hocba.soNgayNghiCoPhep + hocba.soNgayNghiKhongPhep}}</b></p>
                                            </th>
                                            <th rowspan="3">
                                                <p><b>0</b></p>
                                            </th>
                                            <th rowspan="3">
                                                <p><b>0</b></p>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th>
                                                <p><b>Học kỳ II</b></p>
                                            </th>
                                            <th>
                                                <p><b>{{hocba.hanhKiemHKII}}</b></p>
                                            </th>
                                            <th>
                                                <p><b>{{hocba.hocLucHKII}}</b></p>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th>
                                                <p><b>Cả năm</b></p>
                                            </th>
                                            <th>
                                                <p><b>{{hocba.hanhKiemCaNam}}</b></p>
                                            </th>
                                            <th>
                                                <p><b>{{hocba.hocLucCN}}</b></p>
                                            </th>
                                        </tr>
                                    </table>
                                </div>
                                <div class="left" style="padding-top:10mm; border:1px solid black; height:25%">
                                    <p><b> - Có chứng chỉ nghề phổ thông:</b> <span class="hocba-relative"> <span
                                                class="hocba-absolute">{{hocba.chungChiNghe}}</span>...........................................................................................................</span>
                                        <b>Loại:</b> <span class="hocba-relative"><span
                                                class="hocba-absolute">{{hocba.loaiChungChi}}</span>.............................................</span>
                                    </p>
                                    <p style="padding-top:10mm"><b> - Được giải thưởng trong các kỳ thi từ cấp huyện trở
                                            lên:</b> <span class="hocba-relative"> <span
                                                class="hocba-absolute"></span>.........................................................................................................................</span>
                                    </p>
                                    <p style="padding-top:10mm"><b> - Khen thưởng đặc biệt khác:</b> <span
                                            class="hocba-relative"> <span
                                                class="hocba-absolute">{{hocba.khenThuong}}</span>.......................................................................................................................................................................</span>
                                    </p>
                                </div>
                                <div class="left" style="padding-top:10mm; border:1px solid black; height:28%">
                                    <div class="center giaoVienChuNhiem">
                                        <div>
                                            <p><b style=""> NHẬN XÉT CỦA GIÁO VIÊN CHỦ NHIỆM</b></p>
                                            <i>(Kí và ghi rõ họ tên)</i>
                                        </div>
                                        <div class="chuKyHieuTruong hocba-relative">
                                            <img style="position:absolute;top:20px;left:35%; width:30%;height:30%"
                                                :src="hocba.pathChuKyGVCN" alt="">
                                        </div>
                                        <p>{{hocba.tenGVCN}}</p>
                                    </div>
                                </div>
                                <div class="left" style="padding-top:10mm; border:1px solid black; height:30%">
                                    <div class="center giaoVienChuNhiem">
                                        <div>
                                            <p><b style=""> PHÊ DUYỆT CỦA HIỆU TRƯỞNG</b></p>
                                        </div>
                                        <div class="width:50">
                                            <div class="xacNhan" style="width:41%; float:right">
                                                <p><b>HIỆU TRƯỞNG</b></p>
                                                <i>(Kí, ghi rõ họ tên và đóng dấu)</i>
                                            </div>
                                            <div class="chuKyHieuTruong hocba-relative">
                                                <!-- <img style="position:absolute;right:10%;top:40px;"
                                                src="https://lh3.googleusercontent.com/proxy/0Pn5ZKYBeH2HsgczaDCMEgRHFUJCDU9usFNAo3DaoWVSh3f656kU-P56j0wI2x1FAKKJMyoZ1sdAYRmSoW3ocrS-L9bZ6BZ0LmjGkW7MyOBSqCPOZPzXD0pb7lphg6io4Z8"
                                                alt="">
                                            <img style="position:absolute;bottom:-40px;right:5%; width:15%;height:auto"
                                                src="https://files.vfo.vn/2013/T03/img/DDBL-60517-5d4dffc07f2d1ab864eb463180f89d91-41046761-chukydep3.png"
                                                alt=""> -->
                                                <img style="position:absolute;right:10%;top:40px;" :src="hocba.pathDau"
                                                    alt="">
                                                <img style="position:absolute;bottom:-40px;right:5%; width:15%;height:auto"
                                                    :src="hocba.pathChuKyHieuTruong" alt="">
                                                <p style="    position: absolute;
    right: 12%;
    bottom: -40px;">{{hocba.tenHieuTruong}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </el-dialog>
</template>
<script>
    import {
        mapActions,
        mapState
    } from "vuex";
    export default {
        name: "ThemHocBa",
        props: ['show', 'id_hoc_ba'],
        computed: {
            ...mapState('hocba', [
                "thongTinHocBa"
            ])
        },
        data: () => {
            return {
                danh_sach_chi_tiet_hoc_ba: [],
            }
        },
        watch: {
            thongTinHocBa(val) {
                console.log("Thông tin học bạ:")
                console.log(val)
                if (val) {
                    this.danh_sach_chi_tiet_hoc_ba = val.list;
                    console.log("danh_sach_chi_tiet_hoc_ba")
                    console.log(this.danh_sach_chi_tiet_hoc_ba)
                }
            },
            show(val) {
                console.log("Show thay đổi" + val);
                if (val) {
                    console.log("Lấy thông tin học bạ");
                    let idHocBa = this.id_hoc_ba;
                    this.getThongTinHocBa({
                        // id: '5ff68cd9c1f019217cb95c00',
                        id: idHocBa,
                    });
                }
            }
        },
        methods: {
            ...mapActions("hocba", [
                "getThongTinHocBa",
                "getListHocBa"
            ]),
            closeDialogCreate() {
                this.$emit('close')
            },
            getGioiTinh(e) {
                if (e == 1) {
                    return "Nam"
                } else if (e == 0) {
                    return "Nữ"
                } else {
                    return ""
                }
            }
        },
        mounted() {}
    }
</script>
<style scoped="scoped">
    .layout-a4 {
        width: 210mm;
        height: 297mm;
        border: 1px solid white;
        margin: 0 auto;
    }
    .a4-body {
        width: 190mm;
        height: 271mm;
        margin: 10mm;
    }
    .thongTinHocSinh {
        width: 100%;
        height: 35mm;
        border: 1px solid #EDE6E6;
        border-radius: 10px;
        background-color: #EDE6E6;
    }
    .thongTinTruong {
        width: 100%;
        height: 15mm;
        border: 1px solid #EDE6E6;
        border-radius: 10px;
        background-color: #EDE6E6;
    }
    .monHocHoatDongGiaoDuc {
        width: 100%;
        height: 155mm;
        margin-top: 7mm;
    }
    .phamChatChuYeu {
        width: 100%;
        height: 70mm;
        margin-top: 5mm;
    }
    .text-center {
        text-align: center;
    }
    p,
    span,
    b,
    i {
        font-size: 13px;
        padding: 0;
        margin: 0;
        font-family: "Times New Roman", Times, serif;
        color: black
    }
    h2,
    h6 {
        margin: 0;
        padding: 0;
    }
    table,
    th,
    td {
        border: 1px solid black;
        border-collapse: collapse;
    }
    .moiHang {
        border: 1px solid blueviolet;
        height: 10mm;
    }
    .moiHang p {
        text-align: center;
    }
    .nangLucCotLoi {
        height: 150mm;
        padding-top: 5mm
    }
    .width50 {
        width: 50%;
    }
    .chuKy {
        padding-top: 4mm;
        display: flex
    }
    .tieuDe {
        background: #F7F6A3;
        color: #E11111;
    }
    /* trang 3 */
    .chuHocBa {
        color: #da1d1d;
        width: 40mm;
        margin: 0 auto;
        border-radius: 15px;
        padding: 6px;
        box-shadow: 1px 1px 1px 2px #AAA;
        /* background-color: yellowgreen; */
    }
    .huongDanGhiHocBa {
        color: #da1d1d;
        width: 110mm;
        margin: 0 auto;
        border-radius: 15px;
        padding: 6px;
        box-shadow: 1px 1px 1px 2px #AAA;
        /* background-color: yellowgreen; */
    }
    .quaTrinhHocTap {
        color: #da1d1d;
        width: 100mm;
        margin: 0 auto;
        border-radius: 15px;
        padding: 6px;
        box-shadow: 1px 1px 1px 2px #AAA;
        /* background-color: yellowgreen; */
    }
    .thongTinCoBanHocSinh {
        width: 100%;
        height: 170mm;
        padding-top: 8mm;
    }
    .trangBia {
        background-image: url(https://cdnhocbadientu.csdl.edu.vn/background/bia_tieuhoc_mau_do.png);
        background-repeat: no-repeat;
        background-size: contain;
    }
    .trangHuongDan {
        background-image: url(https://cdnhocbadientu.csdl.edu.vn/background/nen_vang_1.png);
        background-repeat: no-repeat;
        background-size: contain;
    }
    .hinhNenVang2 {
        background-image: url(https://cdnhocbadientu.csdl.edu.vn/background/nen_vang_2.png);
        background-repeat: no-repeat;
        background-size: contain;
    }
    .hinhNenXanh {
        background-image: url(https://cdnhocbadientu.csdl.edu.vn/background/nen_xanh_la_2.png);
        background-repeat: no-repeat;
        background-size: contain;
    }
    .bodyTrangBia {
        width: 100%;
        height: 100%;
        position: relative;
    }
    .nhanHocBa {
        width: 80%;
        height: 22%;
        position: absolute;
        right: 10%;
        bottom: 10%;
        background-color: #F7F6A3;
        opacity: 0.75;
        border-radius: 15px;
        text-align: left
    }
    .noiDungNhan {
        margin: 7mm;
        color: black
    }
    .noiDungNhan>h6 {
        margin: 5mm 0;
    }
    .noiDungHuongDan {
        margin: 8mm;
        text-align: left;
    }
    .red {
        color: #da1d1d;
    }
    .hocba-relative {
        position: relative;
    }
    .hocba-absolute {
        position: absolute;
        left: 5mm;
    }
    .chuKyHieuTruong {
        height: 45mm;
    }
    .chuKyHieuTruong>img {
        height: 100%;
        width: auto;
    }
    /* Cấp 2 */
    .an {
        /* display: none; */
    }
    .layout-a4 {
        width: 210mm;
        height: 297mm;
        border: 1px solid black;
        margin: 0 auto;
    }
    .a4-body {
        width: 190mm;
        height: 271mm;
        margin: 10mm;
        /* border: 1px solid red; */
        position: relative;
    }
    .center {
        text-align: center;
    }
    .left {
        text-align: left;
    }
    .width50 {
        width: 50%;
    }
    .c2-trangBia {
        border-style: double;
    }
    .c2-chuHocBa {
        padding-top: 60mm;
        font-size: 90px;
        font-weight: bold;
    }
    .c2-tenCapHocBa {
        padding-top: 0mm;
        font-size: 23px;
        font-weight: bold;
    }
    .c2-tenHocSinh {
        padding-top: 60mm;
    }
    .c2-soHocBa {
        position: absolute;
        bottom: 0;
        right: 33%;
    }
    .hocba-relative {
        position: relative;
    }
    .hocba-absolute {
        position: absolute;
        left: 5mm;
        bottom: 2px
    }
    .c2-noiDungThongTin {
        padding-top: 10mm;
    }
    .c2-noiDungThongTin>p {
        padding-top: 4mm;
        font-size: 13px;
    }
    .c2-chuKyHieuTruong {
        height: 45mm
    }
    table,
    th,
    td {
        border: 1px solid black;
        border-collapse: collapse;
    }
    table {
        font-size: 13px;
    }
    .c2-quaTrinhHocTap {
        padding-top: 15mm;
    }
</style>
