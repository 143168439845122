var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 24 } },
        [
          _c(
            "el-col",
            { staticClass: "reset", attrs: { span: 24 } },
            [
              _c("small", { staticStyle: { "padding-right": "5px" } }, [
                _vm._v("Hiển thị "),
              ]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticStyle: { width: "100px !important" },
                  attrs: { size: "mini", placeholder: "Chọn" },
                  on: { change: _vm.onSizeChange },
                  model: {
                    value: _vm.soLuong,
                    callback: function ($$v) {
                      _vm.soLuong = $$v
                    },
                    expression: "soLuong",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c("small", { staticStyle: { "padding-left": "5px" } }, [
                _vm._v("bản ghi/trang."),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }