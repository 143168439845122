<template>
    <div>
<!--        <div class="bieudo" ref="bieudokhongxepchong"></div>-->
        <apexchart type="bar" height="220" :options="chartOptions" :series="series" ref="bieudokhongxepchong"></apexchart>
    </div>
</template>
<style>
</style>
<script>
import ApexCharts from 'vue-apexcharts'
// import ApexCharts from 'apexcharts'


export default {
    props: ['dulieu'],
    data() {
        return {
            chartOptions : {
                colors: ['#00a65a', '#FFA200', '#60BFFF', '#90ED7D', '#014839', '#FB4444'],
                chart: {
                    type: "bar",
                    width: '100%',
                    height: '290',
                    stacked: false,
                    toolbar: {
                        show: false
                    },
                    events: {
                        mounted: (chartContext, config) => {
                            console.log("mounted", chartContext, config, config.globals.yRange);
                            // setTimeout(() => {
                            //   addAnnotations(config);
                            // });
                        },
                        updated: (chartContext, config) => {
                            // setTimeout(() => {
                            //   addAnnotations(config);
                            // });
                        }
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val, opts) {
                        return val.toLocaleString();
                    },
                    style: {
                        fontSize: '12px',
                        fontWeight: 'bold',
                    },
                },
                noData: {
                    text: "Biểu đồ chưa có dữ liệu",
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '14px',
                        fontFamily: undefined
                    }
                },
                plotOptions: {
                    bar: {
                        columnWidth: '60%',
                        horizontal: false,
                        dataLabels: {
                            maxItems: 2
                        }
                    }
                },
                xaxis: {
                    categories: [],
                    axisTicks: {
                        show: true
                    },
                    axisBorder: {
                        show: true
                    },
                    labels: {
                        hideOverlappingLabels: false,
                    }
                },
                yaxis: {
                    axisTicks: {
                        show: true
                    },
                    axisBorder: {
                        show: true
                    },
                    labels: {
                        hideOverlappingLabels: true,
                    }
                },
                fill: {
                    opacity: 1
                },
                legend: {
                    position: "top",
                    horizontalAlign: "center"
                },
                grid: {
                    padding: {
                        left: 13.5,
                        right: 0
                    },
                    xaxis: {
                        lines: {
                            show: false
                        }
                    },
                }
            },
            series: [{
                data: []
            }],
        }
    },
    mounted() {
        if (this.dulieu){
            this.bindData(this.dulieu)
        }

    },
    components: {
        apexchart: ApexCharts,

    },
    watch: {

        dulieu(newVal) {
            if (newVal) {
                this.bindData(newVal)

            }
        },
    },
    methods: {
        bindData(e) {
            let series = e.du_lieu;
            let categories = e.list_lop;
            // this.chartOptions.xaxis.categories = categories;
            this.chartOptions = {...this.chartOptions, ...{
                    xaxis: {
                        categories: categories
                    }
                }}
            let element = this.$refs.bieudokhongxepchong;
            this.series = series;

            // this.generateChart(series, categories, element)
        },
        generateChart(series, categories, element) {
            let chart;
            const options = {
                series,
                colors: ['#00a65a', '#FFA200', '#60BFFF', '#90ED7D', '#014839', '#FB4444'],
                chart: {
                    type: "bar",
                    width: '100%',
                    height: '300',
                    stacked: false,
                    toolbar: {
                        show: false
                    },
                    events: {
                        mounted: (chartContext, config) => {
                            console.log("mounted", chartContext, config, config.globals.yRange);
                            // setTimeout(() => {
                            //   addAnnotations(config);
                            // });
                        },
                        updated: (chartContext, config) => {
                            // setTimeout(() => {
                            //   addAnnotations(config);
                            // });
                        }
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val, opts) {
                        return val.toLocaleString();
                    },
                    style: {
                        fontSize: '12px',
                        fontWeight: 'bold',
                    },
                },
                noData: {
                    text: "Biểu đồ chưa có dữ liệu",
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '14px',
                        fontFamily: undefined
                    }
                },
                plotOptions: {
                    bar: {
                        columnWidth: '60%',
                        horizontal: false,
                        dataLabels: {
                            maxItems: 2
                        }
                    }
                },
                xaxis: {
                    categories,
                    axisTicks: {
                        show: true
                    },
                    axisBorder: {
                        show: true
                    },
                    labels: {
                        hideOverlappingLabels: false,
                    }
                },
                yaxis: {
                    axisTicks: {
                        show: true
                    },
                    axisBorder: {
                        show: true
                    },
                    labels: {
                        hideOverlappingLabels: true,
                    }
                },
                fill: {
                    opacity: 1
                },
                legend: {
                    position: "top",
                    horizontalAlign: "center"
                },
                grid: {
                    padding: {
                        left: 13.5,
                        right: 0
                    },
                    xaxis: {
                        lines: {
                            show: false
                        }
                    },
                }
            };
            const addAnnotations = (config) => {
                var seriesTotals = config.globals.stackedSeriesTotals;
                if (seriesTotals.length == 0) {
                    seriesTotals = [0, 0, 0]
                }
                const isHorizontal = options.plotOptions.bar.horizontal;
                chart.clearAnnotations();

                try {
                    categories.forEach((category, index) => {
                        chart.addPointAnnotation({
                                y: isHorizontal ?
                                    calcHorizontalY(config, index) : seriesTotals[index],
                                x: isHorizontal ? 0 : category,
                                label: {
                                    text: `${seriesTotals[index].toLocaleString()}`,
                                }
                            },
                            false
                        );

                        if (isHorizontal) {
                            adjustPointAnnotationXCoord(config, index);
                        }
                    });
                } catch (error) {
                    console.log(`Add point annotation error: ${error.message}`);
                }
            };

            const calcHorizontalY = (config, index) => {
                const catLength = categories.length;
                const yRange = config.globals.yRange[0];
                const minY = config.globals.minY;
                const halfBarHeight = yRange / catLength / 2;

                return minY + halfBarHeight + 2 * halfBarHeight * (catLength - 1 - index);
            };

            const adjustPointAnnotationXCoord = (config, index) => {
                const gridWidth = config.globals.gridWidth;
                const seriesTotal = config.globals.stackedSeriesTotals[index];
                const minY = Math.abs(config.globals.minY);
                const yRange = config.globals.yRange[0];
                const xOffset = (gridWidth * (seriesTotal + minY)) / yRange;
                const chartId = config.globals.dom.baseEl.id;

                const circle = document.querySelector(
                        `#${chartId} .apexcharts-point-annotations circle:nth-of-type(${index + 1})`
                );
                const labelField = document.querySelector(
                        `#${chartId} .apexcharts-point-annotations rect:nth-of-type(${index + 1}`
                );
                const labelFieldXCoord = parseFloat(labelField.getAttribute("x"));
                const text = document.querySelector(
                        `#${chartId} .apexcharts-point-annotations text:nth-of-type(${index + 1}`
                );

                labelField.setAttribute("x", labelFieldXCoord + xOffset);
                text.setAttribute("x", xOffset);
                circle.setAttribute("cx", xOffset);
            };

            chart = new ApexCharts(element, options);
            console.log('render 111111111111111');
            chart.render();
            // chart.updateOptions([options])
        }
    },
}

</script>
<style scoped="scoped">
.apexcharts-canvas {
    margin: 0 auto !important;
}

</style>
