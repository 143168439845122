export default {
    ROLE : [
        {
            VALUE : 1,
            NAME : 'GVBM'
        },
        {
            VALUE : 2,
            NAME : 'GVCN'
        },
        {
            VALUE : 3,
            NAME : 'Hiệu trưởng'
        },
        {
            VALUE : 4,
            NAME : 'Cán bộ'
        },
        {
            VALUE : 5,
            NAME : 'Học sinh'
        },
        {
            VALUE : 5,
            NAME : 'Học sinh'
        },
        {
            VALUE : 6,
            NAME : 'PGD'
        },
        {
            VALUE : 7,
            NAME : 'Sở GD'
        },
    ],
    TYPE_KY :[
        {
            VALUE : 1,
            NAME : 'Ký OTP'
        },
        {
            VALUE : 2,
            NAME : 'Ký bằng sim CA'
        },
        {
            VALUE : 3,
            NAME : 'USB Token Viettel'
        },
        {
            VALUE : 4,
            NAME : 'USB Token vinaphone'
        },
        {
            VALUE : 5,
            NAME : 'USB Token bkav'
        }
    ],
    DONG_BO_ADMIN : [
        {
            VALUE : 8,
            NAME : 'Phòng'
        },
        {
            VALUE : 9,
            NAME : 'Trường'
        },
        {
            VALUE : 1,
            NAME : 'Giáo viên'
        },
        {
            VALUE : 2,
            NAME : 'Lớp học'
        },
        {
            VALUE : 3,
            NAME : 'Học sinh'
        }
        ,
        {
            VALUE : 4,
            NAME : 'Học bạ',

        },
        // {
        //     VALUE : 5,
        //     NAME : 'Học bạ cấp 2'
        // },
        // {
        //     VALUE : 6,
        //     NAME : 'Học bạ cấp 3'
        // },
        // {
        //     VALUE : 7,
        //     NAME : 'GV+HS+LH+HB'
        // }
    ],
    DONG_BO_GIAO_VIEN : [

        {
            VALUE : 2,
            NAME : 'Lớp học'
        },
        {
            VALUE : 3,
            NAME : 'Học sinh'
        },
        {
            VALUE : 4,
            NAME : 'Học bạ',

        }
    ],
    DONG_BO_SO : [
        // {
        //     VALUE : 8,
        //     NAME : 'Phòng'
        // },
        {
            VALUE : 9,
            NAME : 'Trường'
        },
        {
            VALUE : 1,
            NAME : 'Giáo viên'
        },
        {
            VALUE : 2,
            NAME : 'Lớp học'
        },
        {
            VALUE : 3,
            NAME : 'Học sinh'
        }
        ,
        {
            VALUE : 4,
            NAME : 'Học bạ',

        },
        // {
        //     VALUE : 5,
        //     NAME : 'Học bạ cấp 2'
        // },
        // {
        //     VALUE : 6,
        //     NAME : 'Học bạ cấp 3'
        // },
        // {
        //     VALUE : 7,
        //     NAME : 'GV+HS+LH+HB'
        // }
    ],
    DONG_BO_PHONG : [
        {
            VALUE : 9,
            NAME : 'Trường'
        },
        {
            VALUE : 1,
            NAME : 'Giáo viên'
        },
        {
            VALUE : 2,
            NAME : 'Lớp học'
        },
        {
            VALUE : 3,
            NAME : 'Học sinh'
        }
        ,
        {
            VALUE : 4,
            NAME : 'Học bạ',

        },
        // {
        //     VALUE : 5,
        //     NAME : 'Học bạ cấp 2'
        // },
        // {
        //     VALUE : 6,
        //     NAME : 'Học bạ cấp 3'
        // },
        // {
        //     VALUE : 7,
        //     NAME : 'GV+HS+LH+HB'
        // }
    ],
    DONG_BO_TRUONG : [
        {
            VALUE : 9,
            NAME : 'Trường học'
        },
        {
            VALUE : 2,
            NAME : 'Lớp học'
        },
        {
            VALUE : 1,
            NAME : 'Giáo viên'
        },
        {
            VALUE : 3,
            NAME : 'Học sinh'
        }
        ,
        {
            VALUE : 4,
            NAME : 'Học bạ',

        },
        // {
        //     VALUE : 7,
        //     NAME : 'GV+HS+LH+HB'
        // }
    ],

    DONG_BO_TRUONG_HCM : [
        {
            VALUE : 9,
            NAME : 'Trường học'
        },
        {
            VALUE : 1,
            NAME : 'Giáo viên'
        },
    ],

}
