<script>
import { Pie } from "vue-chartjs";


export default {
    extends: Pie,
    name: "PieChart",
    props : [
        'title',
        'dataPie'
    ],
    components : {
        Pie
    },
    computed: {
        chartData: function() {
            return this.dataPie;
        }
    },

    mounted() {
        this.renderPieChart()
    },
    methods : {
        renderPieChart(){
            this.renderChart({
                labels: ['Không sửa chữa','Có sửa chữa'],
                datasets: [
                    {
                        backgroundColor: ['#dd4b39','#00a65a'],
                        data: this.chartData
                    }
                ]
            }, {
                title: {
                    display: true,
                    text: this.title,
                    fontSize : 14,
                    fontFamily : 'Roboto',
                },
                responsive: true,
                maintainAspectRatio: false
            })
        }
    },
    watch: {
        dataPie: function(val) {

            //this.renderChart(this.data, this.options);
            this.renderPieChart();
        }
    }
}
</script>

<style scoped>

</style>
