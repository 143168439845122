import api from "../api";
import {sprintf} from "sprintf-js";
import restApi from "../_helpers/rest_api";

export const untilService = {
    getProvince,
    getListTruong
};

function getProvince(){
    let uri = api.truong.getTinh;
    let params = {};
    return restApi.get(uri,params);
}

function getListTruong(provinceId,capHoc){
    let uri = api.truong.listTruong;
    let params = {
        maTinh : provinceId,
        capHoc : capHoc
    };
    return restApi.get(uri,params);
}
