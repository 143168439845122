var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page page-request" }, [
    _vm.infoHocSinh
      ? _c(
          "div",
          { staticClass: "page__content infoAccount" },
          [
            _c(
              "el-row",
              { staticClass: "dts-content", attrs: { gutter: 10 } },
              [
                _c("el-col", { attrs: { lg: 5, md: 4, sm: 3 } }),
                _vm._v(" "),
                _c(
                  "el-col",
                  {
                    staticClass: "bg-smoke text-center",
                    attrs: { lg: 14, md: 16, sm: 18, xs: 24 },
                  },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 24 } },
                      [
                        _c("el-col", { staticClass: "text-center" }, [
                          _c("div", { staticClass: "grid-content " }, [
                            _c("div", { staticClass: "demo-basic--circle" }, [
                              _c(
                                "div",
                                { staticClass: "text-center" },
                                [
                                  _c(
                                    "el-image",
                                    {
                                      staticClass:
                                        "rounded-circle image-circle-info",
                                      attrs: {
                                        src: _vm.infoHocSinh.anhTuQLNT
                                          ? _vm.infoHocSinh.anhTuQLNT
                                          : _vm.infoHocSinh.anhTuHocBaDienTu,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "image-slot",
                                          attrs: { slot: "error" },
                                          slot: "error",
                                        },
                                        [
                                          _c("el-image", {
                                            staticClass:
                                              "rounded-circle image-circle-info",
                                            attrs: {
                                              src: "/images/default-avatar.png",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("h3", [_vm._v(_vm._s(_vm.infoHocSinh.tenHS))]),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      { attrs: { gutter: 24 } },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "text-left",
                            attrs: { sm: 12, xs: 24 },
                          },
                          [
                            _c("el-card", { attrs: { shadow: "always" } }, [
                              _c("div", { staticClass: "iconInfo" }, [
                                _c("i", { staticClass: "el-icon-user" }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "textInfo" }, [
                                _c("span", [_vm._v("Mã học sinh")]),
                                _vm._v(" "),
                                _c("p", [_vm._v(_vm._s(_vm.infoHocSinh.maHS))]),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            staticClass: "text-left",
                            attrs: { sm: 12, xs: 24 },
                          },
                          [
                            _c("el-card", { attrs: { shadow: "always" } }, [
                              _c("div", { staticClass: "iconInfo" }, [
                                _c("i", { staticClass: "el-icon-date" }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "textInfo" }, [
                                _c("span", [_vm._v("Ngày sinh")]),
                                _vm._v(" "),
                                _vm.infoHocSinh.ngaySinh
                                  ? _c("p", [
                                      _vm._v(_vm._s(_vm.infoHocSinh.ngaySinh)),
                                    ])
                                  : _c("p", [_vm._v("Chưa cập nhật")]),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            staticClass: "text-left",
                            attrs: { sm: 12, xs: 24 },
                          },
                          [
                            _c("el-card", { attrs: { shadow: "always" } }, [
                              _c("div", { staticClass: "iconInfo" }, [
                                _c("i", {
                                  staticClass: "el-icon-add-location",
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "textInfo" }, [
                                _c("span", [_vm._v("Nơi sinh")]),
                                _vm._v(" "),
                                _vm.infoHocSinh.noiSinh
                                  ? _c("p", [
                                      _vm._v(_vm._s(_vm.infoHocSinh.noiSinh)),
                                    ])
                                  : _c("p", [_vm._v("Chưa cập nhật")]),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            staticClass: "text-left",
                            attrs: { sm: 12, xs: 24 },
                          },
                          [
                            _c("el-card", { attrs: { shadow: "always" } }, [
                              _c("div", { staticClass: "iconInfo" }, [
                                _c("i", {
                                  staticClass: "el-icon-location-outline",
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "textInfo" }, [
                                _c("span", [_vm._v("Nơi ở hiện nay")]),
                                _vm._v(" "),
                                _vm.infoHocSinh.noiOHienNay
                                  ? _c("p", [
                                      _vm._v(
                                        _vm._s(_vm.infoHocSinh.noiOHienNay)
                                      ),
                                    ])
                                  : _c("p", [_vm._v("Chưa cập nhật")]),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            staticClass: "text-left",
                            attrs: { sm: 12, xs: 24 },
                          },
                          [
                            _c("el-card", { attrs: { shadow: "always" } }, [
                              _c("div", { staticClass: "iconInfo" }, [
                                _c("i", {
                                  staticClass: "el-icon-map-location",
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "textInfo" }, [
                                _c("span", [_vm._v("Quê quán")]),
                                _vm._v(" "),
                                _vm.infoHocSinh.queQuan
                                  ? _c("p", [
                                      _vm._v(_vm._s(_vm.infoHocSinh.queQuan)),
                                    ])
                                  : _c("p", [_vm._v("Chưa cập nhật")]),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            staticClass: "text-left",
                            attrs: { sm: 12, xs: 24 },
                          },
                          [
                            _c("el-card", { attrs: { shadow: "always" } }, [
                              _c("div", { staticClass: "iconInfo" }, [
                                _c("i", { staticClass: "el-icon-price-tag" }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "textInfo" }, [
                                _c("span", [_vm._v("Dân tộc")]),
                                _vm._v(" "),
                                _vm.infoHocSinh.tenDanToc
                                  ? _c("p", [
                                      _vm._v(_vm._s(_vm.infoHocSinh.tenDanToc)),
                                    ])
                                  : _c("p", [_vm._v("Chưa cập nhật")]),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            staticClass: "text-left",
                            attrs: { sm: 12, xs: 24 },
                          },
                          [
                            _c("el-card", { attrs: { shadow: "always" } }, [
                              _c("div", { staticClass: "iconInfo" }, [
                                _c("i", { staticClass: "el-icon-s-custom" }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "textInfo" }, [
                                _c("span", [_vm._v("Họ tên bố")]),
                                _vm._v(" "),
                                _vm.infoHocSinh.hoTenCha
                                  ? _c("p", [
                                      _vm._v(_vm._s(_vm.infoHocSinh.hoTenCha)),
                                    ])
                                  : _c("p", [_vm._v(" ")]),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            staticClass: "text-left",
                            attrs: { sm: 12, xs: 24 },
                          },
                          [
                            _c("el-card", { attrs: { shadow: "always" } }, [
                              _c("div", { staticClass: "iconInfo" }, [
                                _c("i", { staticClass: "el-icon-s-check" }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "textInfo" }, [
                                _c("span", [_vm._v("Họ tên mẹ")]),
                                _vm._v(" "),
                                _vm.infoHocSinh.hoTenMe
                                  ? _c("p", [
                                      _vm._v(_vm._s(_vm.infoHocSinh.hoTenMe)),
                                    ])
                                  : _c("p", [_vm._v(" ")]),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            staticClass: "text-left",
                            attrs: { sm: 12, xs: 24 },
                          },
                          [
                            _c("el-card", { attrs: { shadow: "always" } }, [
                              _c("div", { staticClass: "iconInfo" }, [
                                _c("i", { staticClass: "el-icon-school" }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "textInfo" }, [
                                _c("span", [_vm._v("Nghề nghiệp bố")]),
                                _vm._v(" "),
                                _vm.infoHocSinh.ngheNghiepCha
                                  ? _c("p", [
                                      _vm._v(
                                        _vm._s(_vm.infoHocSinh.ngheNghiepCha)
                                      ),
                                    ])
                                  : _c("p", [_vm._v(" ")]),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            staticClass: "text-left",
                            attrs: { sm: 12, xs: 24 },
                          },
                          [
                            _c("el-card", { attrs: { shadow: "always" } }, [
                              _c("div", { staticClass: "iconInfo" }, [
                                _c("i", { staticClass: "el-icon-school" }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "textInfo" }, [
                                _c("span", [_vm._v("Nghề nghiệp mẹ")]),
                                _vm._v(" "),
                                _vm.infoHocSinh.ngheNghiepMe
                                  ? _c("p", [
                                      _vm._v(
                                        _vm._s(_vm.infoHocSinh.ngheNghiepMe)
                                      ),
                                    ])
                                  : _c("p", [_vm._v(" ")]),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            staticClass: "text-left",
                            attrs: { sm: 12, xs: 24 },
                          },
                          [
                            _c("el-card", { attrs: { shadow: "always" } }, [
                              _c("div", { staticClass: "iconInfo" }, [
                                _c("i", {
                                  staticClass: "el-icon-mobile-phone",
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "textInfo" }, [
                                _c("span", [_vm._v("Số điện thoại bố")]),
                                _vm._v(" "),
                                _vm.infoHocSinh.sdtBo
                                  ? _c("p", [
                                      _vm._v(_vm._s(_vm.infoHocSinh.sdtBo)),
                                    ])
                                  : _c("p", [_vm._v("Chưa cập nhật")]),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            staticClass: "text-left",
                            attrs: { sm: 12, xs: 24 },
                          },
                          [
                            _c("el-card", { attrs: { shadow: "always" } }, [
                              _c("div", { staticClass: "iconInfo" }, [
                                _c("i", {
                                  staticClass: "el-icon-mobile-phone",
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "textInfo" }, [
                                _c("span", [_vm._v("Số điện thoại mẹ")]),
                                _vm._v(" "),
                                _vm.infoHocSinh.sdtMe
                                  ? _c("p", [
                                      _vm._v(_vm._s(_vm.infoHocSinh.sdtMe)),
                                    ])
                                  : _c("p", [_vm._v("Chưa cập nhật")]),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          {
                            staticClass: "text-left",
                            attrs: { sm: 12, xs: 24 },
                          },
                          [
                            _c("el-card", { attrs: { shadow: "always" } }, [
                              _c("div", { staticClass: "iconInfo" }, [
                                _c("i", { staticClass: "el-icon-s-grid" }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "textInfo" }, [
                                _c("span", [_vm._v("Đối tượng chính sách")]),
                                _vm._v(" "),
                                _vm.infoHocSinh.tenDtuongCsach
                                  ? _c("p", [
                                      _vm._v(
                                        _vm._s(_vm.infoHocSinh.tenDtuongCsach)
                                      ),
                                    ])
                                  : _c("p", [_vm._v(" ")]),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }