import api from "../api";
import {sprintf} from "sprintf-js";
import restApi from "../_helpers/rest_api";

export const reportService = {
    getReport,
    getReportPhong,
    getReportKyLai,
    getReportKyLaiPhong
};

function getReport(data) {
    let uri = api.report.getReport;
    return restApi.get(uri, data);
}

function getReportPhong(data) {
    let uri = api.report.getReportPhong;
    return restApi.get(uri, data);
}

function getReportKyLai(data) {
    let uri = api.report.getReportKyLai;
    return restApi.get(uri, data);
}

function getReportKyLaiPhong(data) {
    let uri = api.report.getReportKyLaiPhong;
    return restApi.get(uri, data);
}
