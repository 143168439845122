import Vue from 'vue';
import Vuex from 'vuex';
import { account } from './account.module';
import { alert } from './alert.module';
import {setting} from "./setting.module";
// import {userSystem} from "./userSystem.module";
// import {roleSystem} from "./roleSystem.module";
// import {navbar} from "./navbar.module";
// import {modal} from "./modal.module";
import {hocsinh} from "./hocsinh.module";
import {until} from "./until.module";
import {teacher} from "./giaovien.module";
import {hocba} from "./hocba.module";
import {school} from "./school.module";
import {sso} from "./sso.module";
import {test} from "./test.module";
import {configKyModule} from "./configKy.module";
import {reportKy} from "./report.module";


Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        account,
        alert,
        setting,
        // modal,
        hocsinh,
        teacher,
        until,
        hocba,
        sso,
        school,
        test,
        configKyModule,
        reportKy,

    }
});
