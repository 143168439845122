<template>
  <div v-loading="loading" :element-loading-text="text_load" element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)">
    <div>
      <div class="d-lg-flex">
        <!-- <el-select class="mb-2" disabled v-model="type" size="small" placeholder="Nguồn dữ liệu"
                   @change="getRowHeader">
          <el-option value="" label="--Nguồn dữ liệu--"></el-option>
          <el-option v-for="(item,index) in source" :value="item.type" :label="item.label" :key="item.type">
          </el-option>
        </el-select> -->
        <el-select v-if="requireLstHocKy" style="width: 120px" class="mb-2" v-model="hocKy" size="small"
                   placeholder="Học kỳ">
          <el-option value="" label="--Học kỳ--"></el-option>
          <el-option v-for="(item,index) in lstHocKy" :value="item.key" :label="item.label"
                     :key="item.type"></el-option>
        </el-select>
        <ESelect class="" size="small" style="width:120px" collapseTags v-model="khoiHoc"
                 @change="khoiHocChange" placeholder="Chọn" filterable :data="optionGrade"
                 :fields="['label','value']"/>
        <ESelect v-if="!hideClass" class="ml-2" size="small" style="width:120px" collapseTags v-model="lopHoc"
                 placeholder="Chọn" filterable :data="danh_sach_lop_hoc" :fields="['tenLop','maLop']"/>
        <div class="ml-auto" v-if="!disabledDownload">
          <el-link class="mb-2 mt-2" size="small" @click.prevent="dowloadFile"><i>Tải về file mẫu</i>
          </el-link>
        </div>
      </div>
      <div class="d-block d-md-flex align-items-md-center">
        <div>
          <el-upload :on-change="uploadFile" accept=".xls,.xlsx" class="upload-excell" drag :limit="1"
                     ref="uploadExcel" action="/" :auto-upload="false">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text"><em>Chọn file upload</em></div>
          </el-upload>
        </div>
        <div class="mt-3 mt-md-0 ml-md-2">
          <el-button style="" size="small" type="success" @click="submitUpload">Nhập dữ liệu
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  mapActions,
  mapState
} from "vuex";
import ESelect from "../Ui/ESelect";
import XLSX from 'xlsx';
import LZString from 'lz-string';
import api from "../../_helpers/api";
import constant from '../../_helpers/constant_api';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

export default {
  name: "ImportNhanXet",
  components: {
    ESelect,
  },
  props: [
    'isGdcd',
    'year',
    'capHoc',
    'path',
    'source',
    'uriDowload',
    'nameFile',
    'disabledDownload',
    'requireKhoiHoc',
    'requireImportType',
    'requireLstHocKy',
    'hideClass',
    'fileNameError',
    'importType'
  ],
  data() {
    return {
      infoRole: {
        isGVCN: false,
      },
      optionGrade: [],
      khoiCap1: [{
        value: 1,
        label: 'Khối 1'
      },
        {
          value: 2,
          label: 'Khối 2'
        },
        {
          value: 3,
          label: 'Khối 3'
        },
        {
          value: 4,
          label: 'Khối 4'
        },
        {
          value: 5,
          label: 'Khối 5'
        },
      ],
      khoiCap2: [{
        value: 6,
        label: 'Khối 6'
      },
        {
          value: 7,
          label: 'Khối 7'
        },
        {
          value: 8,
          label: 'Khối 8'
        },
        {
          value: 9,
          label: 'Khối 9'
        },
      ],
      khoiCap3: [{
        value: 10,
        label: 'Khối 10'
      },
        {
          value: 11,
          label: 'Khối 11'
        },
        {
          value: 12,
          label: 'Khối 12'
        },
      ],
      lstHocKy: [
        {key: 1, label: 'Học kỳ I'},
        {key: 2, label: 'Học kỳ II'},
        {key: 3, label: 'Cả năm'}
      ],
      danh_sach_lop_hoc: [],
      hocKy: '',
      khoiHoc: '',
      lopHoc: '',
      text_load: '',
      row_header: 0,
      type: '',
      loiDocFile: false,
      loading: false,
      base64: '',
      listRows: [],
      workbook: '',
      worksheet: '',
      listRowsAll: [],
    }
  },
  computed: {
    ...mapState('account', [
      'list_nam_hoc',
      'user'
    ]),
  },
  watch: {
    year(val) {
      this.onChangeNamHoc();
    },
    capHoc(val) {
      this.khoiHoc = '';
      this.lopHoc = '';
      if (val) {
        this.getKhoiHoc();
      }
    }
  },
  methods: {
    onChangeNamHoc() {
      this.lopHoc = '';
      this.khoiHoc = '';
      this.danh_sach_lop_hoc = []
    },
    getKhoiHoc() {
      this.optionGrade = [];
      if (this.capHoc == 1) {
        this.optionGrade = this.khoiCap1;
      } else if (this.capHoc == 2) {
        this.optionGrade = this.khoiCap2;
      } else if (this.capHoc == 3) {
        this.optionGrade = this.khoiCap3;
      } else if (this.capHoc == 12) {
        this.optionGrade = this.khoiCap1.concat(this.khoiCap2)
      } else if (this.capHoc == 23) {
        this.optionGrade = this.khoiCap3.concat(this.khoiCap3)
      } else {
        this.optionGrade = this.khoiCap1.concat(this.khoiCap2, this.khoiCap3)
      }
    },
    khoiHocChange() {
      this.lopHoc = '';
      this.danh_sach_lop_hoc = [];
      this.getlistLopHoc();
      // if (this.khoiHoc) {
      //   this.getRole();
      // }
    },
    getRole() {
      console.log('get Role')
      let url = constant.giaovien.getRole;
      let params = {
        namHoc: this.year,
      }
      this.infoRole = {
        isGVCN: false
      }
      api.get(url, params).then(
          data => {
            console.log('get role')
            console.log(data)
            if (data.data.code == 200) {
              this.infoRole = data.data.data;
            }
            this.getlistLopHoc();
          }
      ).catch((e) => {
      })
    },
    getlistLopHoc() {
      try {
        this.loading = true;
        let params = {
          start: 0,
          limit: 99,
          khoiHoc: this.khoiHoc,
          namHoc: this.year,
        }
        let url = ''
        if (this.isGdcd) {
          url = constant.giaovien.listLopNxGdcd;
          params.isGVCN = false
        } else {
          url = constant.giaovien.listLop;
        }
        api.get(url, params).then(
            response => {
              if (response.data.code == 200) {
                this.danh_sach_lop_hoc = response.data.data.list;
              } else {
              }
              this.loading = false;
            }
        ).catch((e) => {
          this.loading = false;
          // this.thongBao('success', 'Hệ thống đang cập nhật dữ liệu. Vui lòng chờ trong ít phút.');
        })
      } catch (e) {
        this.thongBao('error', 'Vui lòng thử lại sau ít phút.')
      }
    },
    getRowHeader() {
      if (this.type) {
        let source = this.source.find(item => {
          return item.type === this.type;
        })
        if (source) {
          this.row_header = source.rowHeader;
        } else {
          this.row_header = 1;
        }
      }
    },
    createBaoLoiBak(data) {
      let errors = JSON.parse(LZString.decompressFromBase64(data));
      let wb = new ExcelJS.Workbook();
      console.log("createBaoLoi")
      console.log(errors)
      errors.forEach((ws) => {
        let sheet = wb.addWorksheet(ws.sheetName);
        let r;
        let dataSheet = this.listRowsAll.find(item => item.sheetName == ws.sheetName)
        this.worksheet = this.workbook.Sheets[ws.sheetName];
        dataSheet.data.forEach((row, index) => {
          if (index < this.row_header) {
            r = sheet.addRow(row);
            r.font = {
              name: 'Times New Roman',
              size: 12,
              bold: true
            }
          } else {
            ws.data.forEach((rowError) => {
              console.log(ws)
              if (rowError) {
                if ((index + this.row_header + 1) == (rowError.row + this
                    .row_header + 1)) {
                  r = sheet.addRow(row);
                  r.font = {
                    name: 'Times New Roman',
                    size: 12,
                    bold: false
                  }
                  rowError.cells.forEach((cellError) => {
                    try {
                      var rowCell = r.getCell(cellError.cell);
                      rowCell.note = cellError.cmt || "";
                      rowCell.fill = {
                        type: 'pattern',
                        pattern: "solid",
                        fgColor: {
                          argb: 'FFFFFF00'
                        },
                        bgColor: {
                          argb: 'FF0000FF'
                        }
                      }
                    } catch (error) {
                      console.log(error);
                    }
                  });
                }
              }
            });
          }
          if (this.worksheet['!rows'] && this.worksheet['!rows'][index])
            r.height = this.worksheet['!rows'][index].hpx;
          r.alignment = {
            horizontal: "center",
            vertical: "middle",
            wrapText: true
          };
          r.eachCell((cell) => {
            if (index < 1) {
              cell.fill = {
                bgColor: {
                  indexed: 64
                },
                fgColor: {
                  theme: 0,
                  tint: -0.0499893185216834
                },
                pattern: "solid",
                type: "pattern"
              };
            }
            cell.border = {
              top: {
                style: 'thin'
              },
              left: {
                style: 'thin'
              },
              bottom: {
                style: 'thin'
              },
              right: {
                style: 'thin'
              }
            }
          });
        });
        let merges = [];
        if (this.worksheet['!merges'])
          this.worksheet['!merges'].forEach((range) => {
            var cell_range = XLSX.utils.encode_cell({
              c: range.s.c,
              r: range.s.r
            }) + ':' + XLSX.utils.encode_cell({
              c: range.e.c,
              r: range.e.r
            });
            sheet.mergeCells(cell_range);
            merges.push(cell_range);
          });
        // console.log(merges);
        sheet.columns.forEach((col, index) => {
          if (this.worksheet['!cols'] && this.worksheet['!cols'][index])
            col.width = this.worksheet['!cols'][index].width;
        });
      })
      this.$refs.uploadExcel.clearFiles();
      var that = this;
      wb.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = that.tenFileLoi() + '.xlsx';
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    },

    createBaoLoi(data) {
      let errors = JSON.parse(LZString.decompressFromBase64(data));
      console.log('createBaoLoi')
      console.log(errors)
      console.error('row_header')
      console.error(this.row_header)
      let wb = new ExcelJS.Workbook();
      let sheet = wb.addWorksheet(this.workbook.SheetNames[0]);
      let r;
      console.error('listRows:')
      console.log(this.listRows)
      this.listRows.forEach((row, index) => {
        if (index < this.row_header) {
          r = sheet.addRow(row);
          r.font = {
            name: 'Times New Roman',
            size: 12,
            bold: true
          }
        } else {
          errors.forEach((rowError) => {
            if (rowError) {
              if ((index + this.row_header + 1) == (rowError.row + this.row_header +
                  1)) {
                r = sheet.addRow(row);
                r.font = {
                  name: 'Times New Roman',
                  size: 12,
                  bold: false
                }
                rowError.cells.forEach((cellError) => {
                  try {
                    var rowCell = r.getCell(cellError.cell);
                    rowCell.note = cellError.cmt || "";
                    rowCell.fill = {
                      type: 'pattern',
                      pattern: "solid",
                      fgColor: {
                        argb: 'FFFFFF00'
                      },
                      bgColor: {
                        argb: 'FF0000FF'
                      }
                    }
                  } catch (error) {
                    console.log(error);
                    //error
                  }
                });
              }
            }
          });
        }
        if (this.worksheet['!rows'] && this.worksheet['!rows'][index])
          r.height = this.worksheet['!rows'][index] ? this.worksheet['!rows'][index].hpx : 6;
        r.alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true
        };
        r.eachCell((cell) => {
          if (index < 1) {
            cell.fill = {
              bgColor: {
                indexed: 64
              },
              fgColor: {
                theme: 0,
                tint: -0.0499893185216834
              },
              pattern: "solid",
              type: "pattern"
            };
          }
          cell.border = {
            top: {
              style: 'thin'
            },
            left: {
              style: 'thin'
            },
            bottom: {
              style: 'thin'
            },
            right: {
              style: 'thin'
            }
          }
        });
      });
      let merges = [];
      if (this.worksheet['!merges'])
        this.worksheet['!merges'].forEach((range) => {
          var cell_range = XLSX.utils.encode_cell({
            c: range.s.c,
            r: range.s.r
          }) + ':' + XLSX.utils.encode_cell({
            c: range.e.c,
            r: range.e.r
          });
          sheet.mergeCells(cell_range);
          merges.push(cell_range);
        });
      sheet.columns.forEach((col, index) => {
        if (this.worksheet['!cols'] && this.worksheet['!cols'][index])
          col.width = this.worksheet['!cols'][index].width;
      });
      var that = this;
      this.$refs.uploadExcel.clearFiles();
      wb.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = that.tenFileLoi() + '.xlsx';
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    },
    tenFileLoi() {
      console.log('tenFileLoi:')
      let result = '';
      let tenFile = 'file_loi_import';
      if (this.fileNameError) {
        tenFile = this.fileNameError;
      }
      tenFile = tenFile + '_ma_truong_' + this.user.maTruong;
      if (this.hocKy) {
        tenFile = tenFile + '_hoc_ky_' + this.hocKy
      }
      if (this.lopHoc) {
        tenFile = tenFile + '_lop_hoc_' + this.lopHoc
      }
      result = tenFile + '_' + this.getFullDate();
      return result
    },
    getFullDate() {
      let today = new Date();
      let yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();
      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;
      let formattedToday = dd + '-' + mm + '-' + yyyy;
      return formattedToday
    },
    uploadFile(file, fileList) {
      try {
        this.text_load = 'Đang đọc dữ liệu....';
        let allowedExtensions = /(\.xlsx|\.xls)$/i;
        if (!allowedExtensions.exec(file.name)) {
          this.$alert('Vui lòng chỉ tải lên file định dạng excel', 'Thông báo', {
            confirmButtonText: 'Đóng',
            type: 'error',
            callback: action => {
              this.$refs.uploadExcel.clearFiles();
            }
          });
          return false;
        }
        this.loading = true;
        console.log("1")
        let reader = new FileReader();
        console.log("2")
        reader.readAsArrayBuffer(file.raw);
        console.log("3")
        reader.onloadstart = (e) => {
        }
        reader.onload = (e) => {
          console.log("4")
          let data = new Uint8Array(e.target.result);
          console.log("Data build")
          console.log(data)
          this.builData(data);
        }
      } catch (e) {
        console.log(e)
        this.thongBao('error', 'Vui lòng thử lại sau ít phút')
      }
    },
    builData(data) {
      console.log('builData')
      console.log(data)
      this.listRowsAll = [];
      this.workbook = XLSX.read(data, {
        type: 'array',
        cellDates: true,
        cellStyles: true
      });
      let params = [];
      this.workbook.SheetNames.forEach((sheet, index) => {
        let paramSheet = [];
        this.worksheet = this.workbook.Sheets[sheet];
        console.log("worksheet")
        console.log(this.worksheet)
        console.log(sheet)
        this.loiDocFile = false;
        try {
          this.listRows = this.sheet2Arr(this.worksheet, this.workbook);
        } catch (e) {
          let msg = 'Vui lòng kiểm tra lại dữ liệu file tải lên ở sheet: ' + sheet;
          this.thongBao('error', msg)
          this.loading = false;
          this.$refs.uploadExcel.clearFiles();
          this.base64 = '';
          this.loiDocFile = true;
          return;
        }
        console.log("listRows")
        console.log(this.listRows)
        this.listRows.forEach((row, index) => {
          let p = {};
          row.forEach((value, index) => {
            p[index + 1] = value.toString();
          });
          paramSheet.push(p);
        });
        params.push({
          sheetName: sheet,
          data: paramSheet
        })
        this.listRowsAll.push({
          sheetName: sheet,
          data: this.listRows
        });
      });
      console.log('listRowsAll')
      console.log(this.listRowsAll)
      console.error('data')
      console.error(params)
      if (!this.loiDocFile) {
        this.base64 = LZString.compressToBase64(JSON.stringify(params))
      } else {
        this.base64 = ''
      }
      this.loading = false;
    },
    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t
      }
      if (type == "success") {
        cl = "dts-noty-success"
      }
      if (type == "warning") {
        cl = "dts-noty-warning"
      }
      if (type == "error") {
        cl = "dts-noty-error"
      }
      if (type == "info") {
        cl = "dts-noty-info"
      }
      document.querySelector('.overlay').style.display = 'block';
      this.$message({
        onClose: () => {
          document.querySelector('.overlay').style.display = 'none';
        },
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 5000
      });
    },
    sheet2Arr(sheet, workbook) {
      let result = [];
      let row;
      let rowNum;
      let colNum;
      let range = XLSX.utils.decode_range(sheet['!ref']) ? XLSX.utils.decode_range(sheet['!ref']) : 0;
      for (rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
        row = [];
        for (colNum = range.s.c; colNum <= range.e.c; colNum++) {
          var nextCell = sheet[
              XLSX.utils.encode_cell({
                r: rowNum,
                c: colNum
              })
              ];
          if (typeof nextCell === 'undefined') {
            row.push('');
          } else {
            if (nextCell.v instanceof Date) {
              let dateMode = workbook.Workbook.WBProps.date1904;
              let result = XLSX.SSF.format('DD/MM/YYYY', nextCell.v, {
                date1904: dateMode
              });
              row.push(result);
            } else {
              if (typeof nextCell.v === 'undefined') {
                row.push('');
              } else {
                row.push(nextCell.v);
              }
            }
          }
        }
        result.push(row);
      }
      return result;
    },
    submitUpload() {
      console.error('requireKhoiHoc')
      console.error(this.requireKhoiHoc)
      if (!this.type) {
        this.$alert('Vui lòng chọn nguồn', 'Thông báo', {
          confirmButtonText: 'Đóng',
          type: 'warning',
          callback: action => {
          }
        });
        return;
      }
      if (!this.year) {
        this.$alert('Vui lòng chọn năm học', 'Thông báo', {
          confirmButtonText: 'Đóng',
          type: 'warning',
          callback: action => {
          }
        });
        return;
      }
      if (!this.importType && this.requireImportType) {
        this.$alert('Vui lòng chọn phương thức nhập lên', 'Thông báo', {
          confirmButtonText: 'Đóng',
          type: 'warning',
          callback: action => {
          }
        });
        return;
      }
      if (this.requireKhoiHoc && !this.khoiHoc) {
        this.thongBao('error', 'Vui lòng chọn khối học')
        return;
      }
      if (!this.hideClass && !this.lopHoc) {
        this.$alert('Vui lòng chọn lớp học', 'Thông báo', {
          confirmButtonText: 'Đóng',
          type: 'warning',
          callback: action => {
          }
        });
        return;
      }
      if (!this.base64) {
        this.$alert('Vui lòng thêm file', 'Thông báo', {
          confirmButtonText: 'Đóng',
          type: 'warning',
          callback: action => {
          }
        });
        return;
      }
      this.text_load = 'Đang tải dữ liệu....'
      this.loading = true;
      let params = {
        namHoc: this.year,
        type: this.type,
        data: this.base64,
        maLop: this.lopHoc,
        qlnt: this.user.qlnt,
        importType: this.importType
      }
      if (!this.hideClass) {
        params.maLop = this.lopHoc
      }
      if (this.requireKhoiHoc) {
        params.khoiHoc = this.khoiHoc
      }
      if (this.requireLstHocKy) {
        params.hocKy = this.hocKy
      }
      api.post('/hocbadientu-sync-service/api/v2/import/data/import', params).then(data => {
        if (data.data.code == 200) {
          this.$alert('Nhập dữ liệu thành công', 'Thông báo', {
            confirmButtonText: 'Đóng',
            type: 'success',
            center: true,
            callback: action => {
              this.$refs.uploadExcel.clearFiles();
              this.base64 = '';
            }
          });
        } else if (data.data.code == 313) {
          this.$confirm(data.data.msg, 'Lỗi nhập dữ liệu', {
            confirmButtonText: 'Tải về báo lỗi',
            cancelButtonText: 'Hủy',
            center: true,
            type: 'warning'
          }).then(() => {
            this.createBaoLoi(data.data.data);
          }).catch(() => {
            this.$refs.uploadExcel.clearFiles();
          });
        } else {
          this.$alert(data.data.msg, 'Thông báo', {
            confirmButtonText: 'Đóng',
            center: true,
            type: 'error',
            callback: action => {
              this.$refs.uploadExcel.clearFiles();
            }
          });
        }
        this.loading = false;
      }).catch((e) => {
        this.loading = false;
        // this.thongBao('success', 'Hệ thống đang cập nhật dữ liệu. Vui lòng chờ trong ít phút.');
      })
    },
    dowloadFile() {
      if (!this.year) {
        this.$alert('Vui lòng chọn năm học', 'Thông báo', {
          confirmButtonText: 'Đóng',
          type: 'warning',
          callback: action => {
          }
        });
        return;
      }
      if (!this.type) {
        this.$alert('Vui lòng chọn nguồn', 'Thông báo', {
          confirmButtonText: 'Đóng',
          type: 'warning',
          callback: action => {
          }
        });
        return;
      }
      if (!this.khoiHoc) {
        this.$alert('Vui lòng chọn khối học', 'Thông báo', {
          confirmButtonText: 'Đóng',
          type: 'warning',
          callback: action => {
          }
        });
        return;
      }
      if (!this.lopHoc && !this.hideClass) {
        this.$alert('Vui lòng chọn lớp học', 'Thông báo', {
          confirmButtonText: 'Đóng',
          type: 'warning',
          callback: action => {
          }
        });
        return;
      }
      let params = {
        hocKy: this.hocKy,
        namHoc: this.year,
        khoiHoc: this.khoiHoc,
        type: this.type,
        maLop: this.lopHoc,
        qlnt: this.type,
      }
      let uri = this.uriDowload;
      api.post(uri, params).then((data) => {
        console.log("Trả về:")
        console.log(data)
        if (data.data.code == 200) {
          window.location.href = data.data.data;
          // let dataSheet = JSON.parse(LZString.decompressFromBase64(data.data.data));
          // console.log(dataSheet)
          // let wb = new ExcelJS.Workbook();
          // dataSheet.forEach(item => {
          //   let sheet = wb.addWorksheet(item.sheetName);
          //   let r;
          //   let dataBuild = item.data;
          //   dataBuild.forEach((row, index) => {
          //     if (index < this.row_header) {
          //       r = sheet.addRow(row);
          //       r.font = {
          //         name: 'Times New Roman',
          //         size: 12,
          //         bold: true
          //       }
          //     } else {
          //       r = sheet.addRow(row);
          //       r.font = {
          //         name: 'Times New Roman',
          //         size: 12,
          //         bold: false
          //       }
          //     }
          //     if (sheet['!rows'] && sheet['!rows'][index])
          //       r.height = sheet['!rows'][index].hpx;
          //     r.alignment = {
          //       horizontal: "center",
          //       vertical: "middle",
          //       wrapText: true
          //     };
          //     r.eachCell((cell) => {
          //       if (index < 1) {
          //         cell.fill = {
          //           bgColor: {
          //             indexed: 64
          //           },
          //           fgColor: {
          //             theme: 0,
          //             tint: -0.0499893185216834
          //           },
          //           pattern: "solid",
          //           type: "pattern"
          //         };
          //       }
          //       cell.border = {
          //         top: {
          //           style: 'thin'
          //         },
          //         left: {
          //           style: 'thin'
          //         },
          //         bottom: {
          //           style: 'thin'
          //         },
          //         right: {
          //           style: 'thin'
          //         }
          //       }
          //     });
          //
          //   })
          //
          //   if (sheet.columns) {
          //     console.log(sheet.columns)
          //     sheet.columns.forEach((col, index) => {
          //       let dataMax = 0;
          //       const column = col;
          //       console.log(column)
          //       for (let j = 1; j < column.values.length; j += 1) {
          //         const columnLength = column.values[j].length;
          //         if (columnLength > dataMax) {
          //           dataMax = columnLength;
          //         }
          //       }
          //       column.width = dataMax < 6 ? 6 : (dataMax + 1);
          //     });
          //   }
          // })
          //
          // let fileName = this.nameFile;
          // let tenLop = this.getTenLop(this.lopHoc);
          // let khoiHoc = this.khoiHoc;
          // wb.xlsx.writeBuffer().then(function (data) {
          //   const blob = new Blob([data], {
          //     type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          //   });
          //   const url = window.URL.createObjectURL(blob);
          //   const anchor = document.createElement('a');
          //   anchor.href = url;
          //   anchor.download = fileName + (tenLop ? '-lop-' + tenLop : '-khoi-' +
          //       khoiHoc) + '.xlsx';
          //   anchor.click();
          //   window.URL.revokeObjectURL(url);
          // });
        } else {
          this.$message({
            customClass: 'dts-noty-error',
            showClose: true,
            message: data.data.msg,
            type: 'error',
            duration: 3000
          });
        }
      }).catch((e) => {
        console.log(e)
        this.$message({
          customClass: 'dts-noty-error',
          showClose: true,
          message: 'Lỗi kết nối',
          type: 'error',
          duration: 3000
        });
      })
    },
    getTenLop(malop) {
      let lop = this.danh_sach_lop_hoc.find(item => {
        return item.maLop == malop;
      })
      if (lop) {
        return lop.tenLop;
      }
      return ''
    },
  },
  mounted() {
    this.getKhoiHoc();
    if (this.user) {
      this.type = this.user.qlnt;
      this.getRowHeader();
    }
  }
}
</script>
<style scoped>
.el-link {
  font-weight: 400;
  font-size: 13px;
}
</style>