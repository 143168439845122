<template>

</template>

<script>
import {mapActions} from 'vuex';
export default {
    name: "Clear",
    methods : {
        ...mapActions('account',[
            "clearData"
        ])
    },
    mounted() {
        this.clearData();
    }
}
</script>

<style scoped>

</style>
