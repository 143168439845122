var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "page page-file file detailHocBa",
      attrs: { id: "detailHocBa" },
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "box-upload-file" },
        [
          _c(
            "el-form",
            {
              ref: "searchForm",
              attrs: {
                rules: _vm.rulesSearch,
                model: _vm.searchForm,
                "hide-required-asterisk": true,
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "mt-2 mb-2",
                      attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          attrs: { prop: "year", label: "Năm học" },
                        },
                        [
                          _c("eselect", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              collapseTags: "",
                              placeholder: "Chọn",
                              filterable: "",
                              data: _vm.list_nam_hoc,
                              fields: ["name", "value"],
                            },
                            on: { change: _vm.onYearChange },
                            model: {
                              value: _vm.searchForm.year,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "year", $$v)
                              },
                              expression: "searchForm.year",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "mt-2 mb-2",
                      attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          attrs: { prop: "education", label: "Cấp học" },
                        },
                        [
                          _c("eselect", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              disabled: _vm.list_cap_hoc.length == 1,
                              placeholder: "Chọn",
                              filterable: "",
                              data: _vm.list_cap_hoc,
                              fields: ["name", "value"],
                            },
                            on: { change: _vm.capHocChange },
                            model: {
                              value: _vm.searchForm.education,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "education", $$v)
                              },
                              expression: "searchForm.education",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "mt-2 mb-2",
                      attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          attrs: { prop: "grade", label: "Khối học" },
                        },
                        [
                          _c("eselect", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              collapseTags: "",
                              placeholder: "Chọn",
                              filterable: "",
                              data: _vm.optionGrade,
                              fields: ["label", "value"],
                            },
                            on: { change: _vm.khoiHocChange },
                            model: {
                              value: _vm.searchForm.grade,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "grade", $$v)
                              },
                              expression: "searchForm.grade",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "mt-2 mb-2",
                      attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          attrs: { prop: "class", label: "Lớp học" },
                        },
                        [
                          _c("eselect", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              collapseTags: "",
                              placeholder: "Chọn",
                              filterable: "",
                              data: _vm.danh_sach_lop_hoc,
                              fields: ["tenLop", "maLop"],
                            },
                            model: {
                              value: _vm.searchForm.class,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "class", $$v)
                              },
                              expression: "searchForm.class",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "mt-2 mb-2",
                      attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          attrs: { label: "Tên học sinh" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "Nhập" },
                            model: {
                              value: _vm.searchForm.student,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "student", $$v)
                              },
                              expression: "searchForm.student",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "mt-2 mb-2",
                      attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          attrs: { label: "Mã học sinh" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "Nhập" },
                            model: {
                              value: _vm.searchForm.maHS,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "maHS", $$v)
                              },
                              expression: "searchForm.maHS",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "text-left mt-2 mb-2",
                      attrs: { xs: 24, sm: 24, md: 6, lg: 24, xl: 3 },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "d-inline-block text-left" },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                color: "transparent",
                                "margin-bottom": "-1px",
                              },
                            },
                            [_vm._v("Tìm kiếm")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.submitSearch("searchForm")
                                },
                              },
                            },
                            [_vm._v("Tìm kiếm")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 24 } },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c(
              "div",
              { staticClass: "list-btn-table" },
              [
                _c(
                  "el-badge",
                  {
                    staticClass: "item",
                    attrs: {
                      hidden: _vm.listHocBaCheck.length == 0,
                      value: _vm.listHocBaCheck.length,
                      type: "primary",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled:
                            !_vm.listHocBaCheck ||
                            _vm.listHocBaCheck.length == 0,
                          size: "small",
                          type: "primary",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.showThongTinKy()
                          },
                        },
                      },
                      [_vm._v(" Đóng dấu\n            ")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: "Đăng nhập VNPT SmartCA",
                      placement: "left",
                    },
                  },
                  [
                    _vm.kieuKyGiaoVien == 6 && !_vm.tokenSmartCa
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: { click: _vm.loginSmartCA },
                          },
                          [
                            _vm._v(
                              "Đăng\n              nhập VNPT SmartCA\n            "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: "Thay đổi tài khoản VGCA",
                      placement: "left",
                    },
                  },
                  [
                    _vm.kieuKyGiaoVien == 7
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { padding: "8px 15px" },
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                _vm.show_check_account_vgca = true
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n              Tài khoản VGCA\n            "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.kieuKyGiaoVien
                  ? _c(
                      "div",
                      { staticClass: "cursor-pointer" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: "Thay đổi kiểu ký",
                              placement: "top",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  padding: "6px",
                                  "margin-left": "10px",
                                },
                                attrs: {
                                  type: "primary",
                                  disabled: _vm.countTypeSign == 1,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    _vm.showChonKieuKy = true
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "30px",
                                    height: "20px",
                                  },
                                  attrs: {
                                    src: _vm.getPathImgByType(
                                      _vm.kieuKyGiaoVien
                                    ),
                                    alt: "",
                                  },
                                }),
                                _vm._v(
                                  "\n                Kiểu ký\n              "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "page__content" }, [
        _c(
          "div",
          { staticClass: "listNew mb-5" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                ref: "listFile",
                attrs: {
                  border: "",
                  fit: "",
                  stripe: "",
                  lazy: true,
                  data: _vm.listHocBa ? _vm.listHocBa.list : [],
                  width: "100%",
                },
                on: { "selection-change": _vm.handleSelectionChange },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    width: "100",
                    align: "center",
                    "header-align": "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-checkbox",
                            {
                              attrs: {
                                disabled:
                                  _vm.khongChoChonTatCa ||
                                  !_vm.listHocBa ||
                                  !_vm.listHocBa.list,
                              },
                              on: { change: _vm.chonToanBoDongDauKqht },
                              model: {
                                value: _vm.checkAllDauKqht,
                                callback: function ($$v) {
                                  _vm.checkAllDauKqht = $$v
                                },
                                expression: "checkAllDauKqht",
                              },
                            },
                            [_vm._v("Chọn\n              ")]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-checkbox", {
                            attrs: { disabled: scope.row.sign },
                            on: {
                              change: function ($event) {
                                return _vm.mapListHocBaCheck(scope.row)
                              },
                            },
                            model: {
                              value: scope.row.checkKy,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "checkKy", $$v)
                              },
                              expression: "scope.row.checkKy",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  staticStyle: { width: "50px" },
                  attrs: {
                    label: "STT",
                    width: "50",
                    type: "index",
                    "class-name": "text-center",
                    index: _vm.indexMethod,
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.user.maSo == 79 ? "Mã CCCD" : "Mã học sinh",
                    "min-width": "120",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.user.maSo == 79
                            ? _c("div", [
                                _vm._v(_vm._s(_vm.cccdHocSinh[scope.row.maHS])),
                              ])
                            : _c("div", [_vm._v(_vm._s(scope.row.maHS))]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "Tên học sinh",
                    "header-align": "center",
                    align: "left",
                    property: "tenHS",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  staticClass: "text-center",
                  staticStyle: { "text-align": "center" },
                  attrs: {
                    label: "Lớp",
                    property: "tenLop",
                    align: "center",
                    width: "70",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    align: "left",
                    "header-align": "center",
                    label: "Trạng thái",
                    property: "moTaTrangThaiHocBa",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { width: "120", align: "center", label: "Đóng dấu" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.sign == false
                            ? _c(
                                "div",
                                [
                                  _c("el-button", {
                                    attrs: {
                                      size: "mini",
                                      type: "primary",
                                      icon: "el-icon-s-check",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.kyMotHocSinh(scope.row, 1)
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        placement: "top",
                                        content: "Đóng dấu",
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          type: "info",
                                          disabled: true,
                                          size: "mini",
                                          icon: "el-icon-s-check",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.listHocBa && _vm.listHocBa.total
              ? _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-between",
                  },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c("chonSoLuong", {
                          on: {
                            chonXongSoLuong: function ($event) {
                              return _vm.ChonSoLuongBanGhi($event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { staticClass: "text-right", attrs: { span: 16 } },
                      [
                        _c("small", { staticClass: "pd-r-10" }, [
                          _c("i", [
                            _vm._v(
                              "Danh sách có " +
                                _vm._s(_vm.listHocBa.total) +
                                " học sinh "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm.listHocBa
                          ? _c("pagination", {
                              attrs: {
                                "page-count": _vm.getTotalPage(
                                  _vm.listHocBa.total
                                ),
                                "initial-page": _vm.paginate.page,
                                "click-handler": _vm.getHocBa,
                                "prev-text": "Trước",
                                "next-text": "Sau",
                                "container-class": "el-pager",
                                pageClass: "number",
                              },
                              model: {
                                value: _vm.paginate.page,
                                callback: function ($$v) {
                                  _vm.$set(_vm.paginate, "page", $$v)
                                },
                                expression: "paginate.page",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("form-otp", {
        attrs: {
          type: _vm.typeKy,
          duLieu: _vm.duLieuHocBaCanKy,
          "list-hoc-ba": _vm.hocbaDatas,
          "show-dialog-otp": _vm.showDialogOtp,
        },
        on: {
          closeDialogOtp: function ($event) {
            return _vm.closeDialogOtp()
          },
          success: function ($event) {
            return _vm.success()
          },
        },
      }),
      _vm._v(" "),
      _vm.kySoStatus
        ? _c("dialog-alert", {
            attrs: {
              title: "Thông báo",
              message: _vm.kySoStatus.message,
              "show-dialog": _vm.showDialogAlert,
            },
            on: {
              closeDialog: function ($event) {
                return _vm.closeDialogAlert()
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.kyUsbStatus
        ? _c("dialog-alert", {
            attrs: {
              title: "Thông báo",
              message: _vm.kyUsbStatus.message,
              "show-dialog": _vm.showDialogAlertUsb,
            },
            on: {
              closeDialog: function ($event) {
                return _vm.closeDialogAlert()
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            title: "Đăng nhập hệ thống VGCA",
            width: "50%",
            center: "",
            "custom-class": "thongTinKyTheoMonHoc",
            "append-to-body": "",
            "show-close": false,
            "show-close": "",
            visible: _vm.show_check_account_vgca,
          },
          on: {
            "update:visible": function ($event) {
              _vm.show_check_account_vgca = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-form",
                {
                  ref: "vgcaForm",
                  attrs: {
                    rules: _vm.ruleVgca,
                    model: _vm.vgcaForm,
                    "hide-required-asterisk": true,
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "d-inline-block",
                              attrs: { prop: "username", label: "Tài khoản" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  clearable: "",
                                  placeholder: "Nhập...",
                                },
                                model: {
                                  value: _vm.vgcaForm.username,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.vgcaForm, "username", $$v)
                                  },
                                  expression: "vgcaForm.username",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "d-inline-block",
                              attrs: { prop: "password", label: "Mật khẩu" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "password",
                                  "show-password": "",
                                  clearable: "",
                                  placeholder: "Nhập",
                                },
                                model: {
                                  value: _vm.vgcaForm.password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.vgcaForm, "password", $$v)
                                  },
                                  expression: "vgcaForm.password",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.show_check_account_vgca = false
                    },
                  },
                },
                [_vm._v("Hủy")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "success" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("vgcaForm")
                    },
                  },
                },
                [_vm._v("Đăng nhập")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            title: "Chọn chứng chỉ VGCA",
            width: "50%",
            center: "",
            "custom-class": "thongTinKyTheoMonHoc",
            "append-to-body": "",
            "show-close": false,
            "show-close": "",
            visible: _vm.show_slect_cer_vgca,
          },
          on: {
            "update:visible": function ($event) {
              _vm.show_slect_cer_vgca = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-form",
                {
                  ref: "vgcaForm",
                  attrs: {
                    rules: _vm.ruleVgca,
                    model: _vm.vgcaForm,
                    "hide-required-asterisk": true,
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        _vm._l(_vm.list_cer_vgca, function (type, i) {
                          return _c(
                            "el-radio",
                            {
                              key: i,
                              staticClass: "checkTypeSign",
                              attrs: { border: "", label: type },
                              model: {
                                value: _vm.cerVgca,
                                callback: function ($$v) {
                                  _vm.cerVgca = $$v
                                },
                                expression: "cerVgca",
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(type.credentialId) +
                                  "\n              "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.show_slect_cer_vgca = false
                    },
                  },
                },
                [_vm._v("Hủy")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    type: "primary",
                    disabled: !_vm.cerVgca,
                  },
                  on: { click: _vm.kyHocBaListVGCA },
                },
                [_vm._v("Đóng dấu")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            title: "Chi tiết",
            width: "60%",
            center: "",
            "custom-class": "thongTinKyTheoMonHoc",
            visible: _vm.hien_thi_thong_tin_ky,
          },
          on: {
            "update:visible": function ($event) {
              _vm.hien_thi_thong_tin_ky = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              attrs: {
                "max-height": _vm.maxHeight,
                "row-class-name": _vm.tableRowClassName,
                data: _vm.list_trang_thai_ky_theo_mon,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "STT",
                  width: "50",
                  align: "center",
                  type: "index",
                  "class-name": "text-center",
                  index: _vm.indexMethod,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "tenMonHoc",
                  label: "Môn học",
                  width: "110",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "tenGiaoVien", label: "Giáo viên" },
              }),
              _vm._v(" "),
              _c(
                "el-table-column",
                { attrs: { label: "GVCN" } },
                [[_c("p", [_vm._v(_vm._s(_vm.tenGiaoVienChuNhiem))])]],
                2
              ),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", label: "Trạng thái", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.trangThaiXacNhan == 1
                          ? _c("div", [_vm._v("Đã ký")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.trangThaiXacNhan == 0
                          ? _c("div", [_vm._v("Chưa ký")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            title: "Chọn kiểu ký",
            width: "40%",
            center: "",
            "custom-class": "thongTinKyTheoMonHoc",
            "append-to-body": "",
            "show-close": true,
            visible: _vm.showChonKieuKy,
          },
          on: {
            close: _vm.handleCloseChonKieuKy,
            "update:visible": function ($event) {
              _vm.showChonKieuKy = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { staticClass: "text-center", attrs: { span: 24 } },
                _vm._l(_vm.listKieuKy, function (type, i) {
                  return _c(
                    "el-radio",
                    {
                      key: i,
                      staticClass: "checkTypeSign",
                      attrs: { label: type.value },
                      on: {
                        change: function ($event) {
                          return _vm.chonKieuKy()
                        },
                      },
                      model: {
                        value: _vm.kieuKyGiaoVien,
                        callback: function ($$v) {
                          _vm.kieuKyGiaoVien = $$v
                        },
                        expression: "kieuKyGiaoVien",
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "90px", height: "70px" },
                        attrs: { src: _vm.getPathImg(type), alt: "Ảnh" },
                      }),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            title: "Chọn chứng thư số",
            width: "50%",
            center: "",
            "custom-class": "thongTinKyTheoMonHoc",
            "append-to-body": "",
            "show-close": false,
            "show-close": "",
            visible: _vm.hien_thi_chon_chung_thu_so,
          },
          on: {
            "update:visible": function ($event) {
              _vm.hien_thi_chon_chung_thu_so = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-table",
                { attrs: { border: "", data: _vm.danh_sach_chung_thu_so } },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "STT",
                      width: "50",
                      align: "center",
                      type: "index",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { "header-align": "center", label: "Số serial" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n              " +
                                _vm._s(scope.row.serialNumberDecimal) +
                                "\n            "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      property: "status",
                      label: "Trạng thái",
                      align: "center",
                      width: "150",
                      "header-align": "center",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      property: "validTo",
                      "header-align": "center",
                      align: "center",
                      label: "Ngày hết hiệu lực",
                      width: "220",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "header-align": "center",
                      label: "Hành động",
                      width: "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.setChungThuSo(scope.row)
                                  },
                                },
                              },
                              [_vm._v("Sử dụng")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            top: "5vh",
            title: "Xác nhận ký tài liệu",
            width: "40%",
            "append-to-body": "",
            "show-close": false,
            center: "",
            "custom-class": "hienThiThoiGianCho",
            visible: _vm.hien_thi_thoi_gian_doi_ky,
          },
          on: {
            "update:visible": function ($event) {
              _vm.hien_thi_thoi_gian_doi_ky = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c("p", [
                  _vm._v("Yêu cầu ký số đã được gửi về thiết bị di động."),
                ]),
                _vm._v(" "),
                _c("p", { staticStyle: { "white-space": "nowrap" } }, [
                  _vm._v("Mở\n            "),
                  _vm.kieuKyGiaoVien != 2
                    ? _c("span", [_vm._v("ứng dụng")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.kieuKyGiaoVien == 2
                    ? _c("span", [
                        _vm._v("thiết bị di động "),
                        _c("b", [_vm._v("nhập mã PIN")]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.kieuKyGiaoVien == 5
                    ? _c("span", [_vm._v("MySign")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.kieuKyGiaoVien == 6
                    ? _c("span", [_vm._v("VNPT SmartCA")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.kieuKyGiaoVien == 7
                    ? _c("span", [_vm._v("VGCA")])
                    : _vm._e(),
                  _vm._v("\n            và nhấn "),
                  _c("b", [_vm._v("Xác nhận")]),
                  _vm._v(" để ký tài liệu."),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { xs: 24, sm: 12 } }, [
                _c("div", { staticClass: "bg-purple-light pt-3" }, [
                  _c("p", [
                    _c("b", { staticClass: "pd-r-14" }, [_vm._v("Ứng dụng: ")]),
                    _vm._v(" "),
                    _vm.kieuKyGiaoVien == 2
                      ? _c("span", [_vm._v("Viettel CA")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.kieuKyGiaoVien == 5
                      ? _c("span", [_vm._v("MySign")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.kieuKyGiaoVien == 6
                      ? _c("span", [_vm._v("VNPT SmartCA")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.kieuKyGiaoVien == 7
                      ? _c("span", [_vm._v("VGCA")])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticStyle: { "white-space": "nowrap" } }, [
                    _c("b", { staticClass: "pd-r-10" }, [
                      _vm._v("Trạng thái: "),
                    ]),
                    _vm._v(" Chờ xác nhận"),
                  ]),
                ]),
                _vm._v(" "),
                _vm.kieuKyGiaoVien != 2
                  ? _c("div", { staticClass: "text-center pt-3" }, [
                      _c("p", [_vm._v("Thời gian còn lại")]),
                      _vm._v(" "),
                      _c("h4", { staticStyle: { color: "#00A65A" } }, [
                        _vm._v(_vm._s(_vm.getMinutes(_vm.timeLeft))),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.kieuKyGiaoVien != 2
                  ? _c("div", [_c("p", [_vm._v("Cài đặt ứng dụng tại")])])
                  : _vm._e(),
                _vm._v(" "),
                _vm.kieuKyGiaoVien != 2
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 24 } },
                          [
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c("img", {
                                attrs: {
                                  src: "/images/ch_android.png",
                                  alt: "",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.getAppAndroid()
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                attrs: {
                                  src: "/images/appstore_ios.png",
                                  alt: "",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.getAppIos()
                                  },
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("el-col", { attrs: { span: 12 } }, [
                              _vm.kieuKyGiaoVien == 5
                                ? _c("img", {
                                    staticStyle: { width: "80% !important" },
                                    attrs: {
                                      src: "/images/qr_code_viettel.png",
                                      alt: "Ảnh qr",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.kieuKyGiaoVien == 6
                                ? _c("img", {
                                    staticStyle: { width: "80% !important" },
                                    attrs: {
                                      src: "/images/qr_code_vnpt.png",
                                      alt: "Ảnh qr",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.kieuKyGiaoVien == 7
                                ? _c("img", {
                                    staticStyle: { width: "80% !important" },
                                    attrs: {
                                      src: "/images/qr_code_vnpt.png",
                                      alt: "Ảnh qr",
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c("div", { staticClass: "text-center" }, [
                      _c("img", {
                        attrs: { src: "/images/loading1.gif", alt: "" },
                      }),
                    ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                {
                  staticClass: "hidden-xs-only hide-in-mobile",
                  attrs: { sm: 12 },
                },
                [
                  _c("img", {
                    attrs: { src: "/images/mobile_ca.png", alt: "ảnh" },
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.kieuKyGiaoVien == 4 && _vm.thuc_hien_ky_list_usb_all_edoc
        ? _c("KyListHocBaUsbAllV2Edoc", {
            attrs: {
              typeKy: _vm.duLieuKyUsb,
              dulieu: _vm.duLieuKyHocBaListUsbAll,
              thuchienky: _vm.thuc_hien_ky_list_usb_all_edoc,
            },
            on: {
              load: _vm.openLoading,
              close: function ($event) {
                return _vm.kyHocBaUsbXong()
              },
              error: _vm.closeLoading,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("ThongTinHocBa", {
        attrs: { show: _vm.ThongTinHocBa, id_hoc_ba: _vm.idHocBa },
        on: {
          close: function ($event) {
            _vm.ThongTinHocBa = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "page__title d-flex align-items-center justify-content-between",
      },
      [_c("h1", { staticClass: "title" }, [_vm._v("Đóng dấu kết quả học tập")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }