import {hocbaService} from "../_services";

const state = {
    detailHocBaPdf: "",
    trangThaiDetailHocBaPdf: null,
    trangThaiDiemTongKetMon: null,
    trangThaiBieuDoTrangThaiHocBaTheoLop: null,
    trangThaiBieuDoTrangThaiHocBaTongQuan: null,
    trangThaiBieuDoTrangThaiHocBaTongQuanHieuTruong: null,
    trangThaiBieuDoHocBaHocSinhHieuTruong: null,
    trangThaiBieuDoHocBaHocSinhGvcn: null,
    trangThaiBieuDoTrangThaiHocBaTheoMonHoc: null,
    diemTongKetMon: null,
    bieuDoTrangThaiHocBaTongQuan: null,
    bieuDoTrangThaiHocBaTongQuanHieuTruong: null,
    bieuDoTrangThaiHocBaTheoLop: null,
    bieuDoTrangThaiHocBaTheoGiaoVien: null,
    bieuDoTrangThaiHocBaTheoMonHoc: null,
    chiTietBieuDoTrangThaiTheoLopMon: null,
    bieuDoHocBaHocSinhHieuTruong: null,
    chiTietTrangThaiHocBaTheoLop: null,
    chiTietTrangThaiHocBaTheoGVCN: null,
    fullScreenLoading: null,
    bieuDoHocBaHocSinhGvcn: null,
    detailHocBa: null,
    thongTinHocBa: null,
    fileDetail: '',
    fileStatus: '',
    ketQuaHocTapTemp: null,
    createStatus: null,
    updateStatus: null,
    detailHs: null,
    listHocBa: null,
};

const actions = {
    setDataHocSinh({commit}, data) {
        commit('setDataHocSinh', data)
    },

    getDetail({commit}, data) {
        commit('getDetail', null);
        hocbaService.getDetailHocBa(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('getDetail', response.data.data)
                } else {
                    commit('getDetail', null)
                }
            }
        ).catch(function (e) {

        })
    },
    getDetailPdf({
                     commit
                 }, data) {

        commit('setTrangThaiDetailHocBaPdf', {stt:2,other:null})
        hocbaService.getDetailHocBaPdf(data).then(
            response => {
                console.log("response getDetailHocBaPdf ");
                console.log(response)
                if (response.data && response.data.code == 200) {
                    commit('getDetailPdf', response.data.data)
                    commit('setTrangThaiDetailHocBaPdf', 1)
                    if(response.data.other!=1){
                        commit('setTrangThaiDetailHocBaPdf', {stt:1,other:2})
                    }else{
                        commit('setTrangThaiDetailHocBaPdf', {stt:1,other:1})
                    }
                } else {
                    commit('getDetailPdf', null)
                    commit('setTrangThaiDetailHocBaPdf', {stt:0,other:2})
                }
            }
        ).catch(function (e) {

        })
    },
    getFile({commit}, data) {
        commit('getFile', {status: 0, data: ''})
        hocbaService.getDetailHocBaPdfPost(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('getFile', {status: 2, data: response.data.data})
                } else {
                    commit('getFile', {status: 1, data: response.data.msg})
                }
            }
        ).catch(function (e) {
            commit('getFile', {status: 1, data: 'Lỗi hệ thống'})
        })
    },
    saveTempKq({commit}, data) {
        commit('saveTempKq', data)
    },
    createHocBaTieuHoc({commit}, data) {
        commit('creatHocBa', null);
        hocbaService.createHocBaTieuHoc(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('creatHocBa', {status: "success", message: 'Tạo học bạ thành công'})
                } else {
                    commit('creatHocBa', {status: 'error', message: 'Lỗi hệ thống'});
                }
            }
        ).catch(function (e) {
            commit('creatHocBa', {status: 'error', message: 'Lỗi hệ thống.' + response.data.msg});
        })
    },
    createHocBaTrungHoc({commit}, data) {
        commit('creatHocBa', null);
        hocbaService.createHocBaTrungHoc(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('creatHocBa', {status: "success", message: 'Tạo học bạ thành công'})
                } else {
                    commit('creatHocBa', {
                        status: 'error',
                        message: 'Tạo học bạ không thành công.' + response.data.msg
                    });
                }
            }
        ).catch(function (e) {
            commit('creatHocBa', {status: 'error', message: 'Lỗi hệ thống'});
        })
    },

    updateHocBaTrungHoc({commit}, data) {
        commit('updateHocBa', null);
        hocbaService.updateHocBaTrungHoc(data).then(
            response => {
                if (response.data && response.data.code == 200) {
                    commit('updateHocBa', {status: "success", message: 'Cập nhật học bạ thành công'})
                } else {
                    commit('updateHocBa', {
                        status: 'error',
                        message: 'Cập nhật học bạ không thành công.' + response.data.msg
                    });
                }
            }
        ).catch(function (e) {
            commit('updateHocBa', {status: 'error', message: 'Lỗi hệ thống'});
        })
    },

    getListHocBa({commit}, data) {
        commit('getListHocBa', null);
        hocbaService.getListHocBa(data).then(
            response => {

                if (response.data && response.data.code == 200) {

                    commit('getListHocBa', response.data.data);
                } else {
                    commit('getListHocBa', null);
                }
            }
        ).catch(function (e) {
            commit('getListHocBa', null);
        })
    },
    getThongTinHocBa({commit}, data) {
        commit('getThongTinHocBa', null);
        hocbaService.getThongTinHocBa(data).then(
            response => {

                if (response.data && response.data.code == 200) {

                    commit('getThongTinHocBa', response.data.data);
                } else {
                    commit('getThongTinHocBa', null);
                }
            }
        ).catch(function (e) {
            commit('getThongTinHocBa', null);
        })
    },
    layDiemTongKetMon({commit}, data) {
        commit('layDiemTongKetMon', null);
        commit('setTrangThaiDiemTongKetMon', 2);
        hocbaService.layDiemTongKetMon(data).then(
            response => {

                if (response.data && response.data.code == 200 && response.data.data) {
                    commit('layDiemTongKetMon', response.data.data);
                    commit('setTrangThaiDiemTongKetMon', 1);
                } else {
                    commit('layDiemTongKetMon', null);
                    commit('setTrangThaiDiemTongKetMon', 0);
                }
            }
        ).catch(function (e) {
            commit('layDiemTongKetMon', null);
            commit('setTrangThaiDiemTongKetMon', 0);
        })
    },
    layBieuDoTrangThaiHocBaTheoLop({commit}, data) {
        commit('layBieuDoTrangThaiHocBaTheoLop', null);
        commit('setTrangThaiBieuDoTrangThaiHocBaTheoLop', 2);
        hocbaService.layBieuDoTrangThaiHocBaTheoLop(data).then(
            response => {

                if (response.data && response.data.code == 200 && response.data.data) {
                    commit('layBieuDoTrangThaiHocBaTheoLop', response.data.data);
                    commit('setTrangThaiBieuDoTrangThaiHocBaTheoLop', 1);
                } else {
                    commit('layBieuDoTrangThaiHocBaTheoLop', null);
                    commit('setTrangThaiBieuDoTrangThaiHocBaTheoLop', 0);
                }
            }
        ).catch(function (e) {
            commit('layBieuDoTrangThaiHocBaTheoLop', null);
            commit('setTrangThaiBieuDoTrangThaiHocBaTheoLop', 0);
        })
    },
    layBieuDoTrangThaiHocBaTheoGiaoVien({commit}, data) {
        console.log("layBieuDoTrangThaiHocBaTheoGiaoVien")
        commit('layBieuDoTrangThaiHocBaTheoGiaoVien', null);
        commit('setTrangThaiBieuDoTrangThaiHocBaTheoGiaoVien', 2);
        hocbaService.layBieuDoTrangThaiHocBaTheoGiaoVien(data).then(
            response => {

                if (response.data && response.data.code == 200 && response.data.data) {
                    commit('layBieuDoTrangThaiHocBaTheoGiaoVien', response.data.data);
                    commit('setTrangThaiBieuDoTrangThaiHocBaTheoGiaoVien', 1);
                } else {
                    commit('layBieuDoTrangThaiHocBaTheoGiaoVien', null);
                    commit('setTrangThaiBieuDoTrangThaiHocBaTheoGiaoVien', 0);
                }
            }
        ).catch(function (e) {
            commit('layBieuDoTrangThaiHocBaTheoGiaoVien', null);
            commit('setTrangThaiBieuDoTrangThaiHocBaTheoGiaoVien', 0);
        })
    },
    layBieuDoTrangThaiHocBaTheoMonHoc({commit}, data) {
        // commit('layBieuDoTrangThaiHocBaTheoMonHoc', null);
        commit('setTrangThaiBieuDoTrangThaiHocBaTheoMonHoc', 2);
        hocbaService.layBieuDoTrangThaiHocBaTheoMonHoc(data).then(
            response => {
                if (response.data && response.data.code == 200 && response.data.data) {
                    commit('layBieuDoTrangThaiHocBaTheoMonHoc', response.data.data);
                    commit('setTrangThaiBieuDoTrangThaiHocBaTheoMonHoc', 1);
                } else {
                    commit('layBieuDoTrangThaiHocBaTheoMonHoc', null);
                    commit('setTrangThaiBieuDoTrangThaiHocBaTheoMonHoc', 0);
                }
            }
        ).catch(function (e) {
            commit('layBieuDoTrangThaiHocBaTheoMonHoc', null);
            commit('setTrangThaiBieuDoTrangThaiHocBaTheoMonHoc', 0);
        })
    },
    layChiTietBieuDoTrangThaiTheoLopMon({commit}, data) {
        commit('setFullScreenLoading', 2);
        hocbaService.layChiTietBieuDoTrangThaiTheoLopMon(data).then(
            response => {
                if (response.data && response.data.code == 200 && response.data.data) {
                    commit('layBieuDoTrangThaiHocBaTheoLopMonHoc', response.data.data);
                    commit('setFullScreenLoading', 1);
                } else {
                    commit('layBieuDoTrangThaiHocBaTheoLopMonHoc', null);
                    commit('setFullScreenLoading', 0);
                }
            }
        ).catch(function (e) {
            commit('layBieuDoTrangThaiHocBaTheoLopMonHoc', null);
            commit('setFullScreenLoading', 0);
        })
    },
    layBieuDoTrangThaiHocBaTongQuan({commit}, data) {
        commit('layBieuDoTrangThaiHocBaTongQuan', null);
        commit('setTrangThaiBieuDoTrangThaiHocBaTongQuan', 2);
        hocbaService.layBieuDoTrangThaiHocBaTongQuan(data).then(
            response => {

                if (response.data && response.data.code == 200 && response.data.data) {
                    commit('layBieuDoTrangThaiHocBaTongQuan', response.data.data);
                    commit('setTrangThaiBieuDoTrangThaiHocBaTongQuan', 1);
                } else {
                    commit('layBieuDoTrangThaiHocBaTongQuan', null);
                    commit('setTrangThaiBieuDoTrangThaiHocBaTongQuan', 0);
                }
            }
        ).catch(function (e) {
            commit('layBieuDoTrangThaiHocBaTongQuan', null);
            commit('setTrangThaiBieuDoTrangThaiHocBaTongQuan', 0);
        })
    },
    layBieuDoTrangThaiHocBaTongQuanHieuTruong({commit}, data) {
        commit('layBieuDoTrangThaiHocBaTongQuanHieuTruong', null);
        commit('setTrangThaiBieuDoTrangThaiHocBaTongQuanHieuTruong', 2);
        hocbaService.layBieuDoTrangThaiHocBaTongQuanHieuTruong(data).then(
            response => {
                if (response.data && response.data.code == 200 && response.data.data) {
                    commit('layBieuDoTrangThaiHocBaTongQuanHieuTruong', response.data.data);
                    commit('setTrangThaiBieuDoTrangThaiHocBaTongQuanHieuTruong', 1);
                } else {
                    commit('layBieuDoTrangThaiHocBaTongQuanHieuTruong', null);
                    commit('setTrangThaiBieuDoTrangThaiHocBaTongQuanHieuTruong', 0);
                }
            }
        ).catch(function (e) {
            commit('layBieuDoTrangThaiHocBaTongQuanHieuTruong', null);
            commit('setTrangThaiBieuDoTrangThaiHocBaTongQuanHieuTruong', 0);
        })
    },
    layBieuDoHocBaHocSinhHieuTruong({commit}, data) {
        commit('layBieuDoHocBaHocSinhHieuTruong', null);
        commit('setTrangThaiBieuDoHocBaHocSinhHieutruong', 2);
        hocbaService.layBieuDoHocBaHocSinhHieuTruong(data).then(
            response => {

                if (response.data && response.data.code == 200 && response.data.data) {
                    commit('layBieuDoHocBaHocSinhHieuTruong', response.data.data);
                    commit('setTrangThaiBieuDoHocBaHocSinhHieutruong', 1);
                } else {
                    commit('layBieuDoHocBaHocSinhHieuTruong', null);
                    commit('setTrangThaiBieuDoHocBaHocSinhHieutruong', 0);
                }
            }
        ).catch(function (e) {
            commit('layBieuDoHocBaHocSinhHieuTruong', null);
            commit('setTrangThaiBieuDoHocBaHocSinhHieutruong', 0);
        })
    },
    layBieuDoHocBaHocSinhGvcn({commit}, data) {
        commit('layBieuDoHocBaHocSinhGvcn', null);
        commit('setTrangThaiBieuDoHocBaHocSinhGvcn', 2);
        hocbaService.layBieuDoHocBaHocSinhGvcn(data).then(
            response => {

                if (response.data && response.data.code == 200 && response.data.data) {
                    commit('layBieuDoHocBaHocSinhGvcn', response.data.data);
                    commit('setTrangThaiBieuDoHocBaHocSinhGvcn', 1);
                } else {
                    commit('layBieuDoHocBaHocSinhGvcn', null);
                    commit('setTrangThaiBieuDoHocBaHocSinhGvcn', 0);
                }
            }
        ).catch(function (e) {
            commit('layBieuDoHocBaHocSinhGvcn', null);
            commit('setTrangThaiBieuDoHocBaHocSinhGvcn', 0);
        })
    },
    layChiTietTrangThaiHocBaTheoLop({commit}, data) {
        console.log("1")
        commit('layChiTietTrangThaiHocBaTheoLop', null);
        commit('setFullScreenLoading', 2);
        console.log("2")
        hocbaService.layChiTietTrangThaiHocBaTheoLop(data).then(
            response => {
                console.log("3")
                if (response.data && response.data.code == 200 && response.data.data) {
                    commit('layChiTietTrangThaiHocBaTheoLop', response.data.data);
                    commit('setFullScreenLoading', 1);
                } else {
                    commit('layChiTietTrangThaiHocBaTheoLop', null);
                    commit('setFullScreenLoading', 0);
                }
            }
        ).catch(function (e) {
            commit('layChiTietTrangThaiHocBaTheoLop', null);
            commit('setFullScreenLoading', 0);
        })
    },
    layChiTietTrangThaiHocBaTheoGVCN({commit}, data) {
        commit('layChiTietTrangThaiHocBaTheoGVCN', null);
        commit('setFullScreenLoading', 2);
        hocbaService.layChiTietTrangThaiHocBaTheoGVCN(data).then(
            response => {
                if (response.data && response.data.code == 200 && response.data.data) {
                    commit('layChiTietTrangThaiHocBaTheoGVCN', response.data.data);
                    commit('setFullScreenLoading', 1);
                } else {
                    commit('layChiTietTrangThaiHocBaTheoGVCN', null);
                    commit('setFullScreenLoading', 0);
                }
            }
        ).catch(function (e) {
            commit('layChiTietTrangThaiHocBaTheoGVCN', null);
            commit('setFullScreenLoading', 0);
        })
    },
};

const mutations = {
    getDetail(state, data) {
        state.detailHocBa = data;
    },
    getDetailPdf(state, data) {
        if (data) {
            state.detailHocBaPdf = data;
        } else {
            state.detailHocBaPdf = "data";
        }

    },
    setTrangThaiDetailHocBaPdf(state, data){

        state.trangThaiDetailHocBaPdf = data;
    },
    getFile(state, data) {
        state.fileStatus = data.status;
        state.fileDetail = data.data;
    },
    saveTempKq(state, data) {
        state.ketQuaHocTapTemp = data;
    },
    creatHocBa(state, data) {
        state.createStatus = data;
    },
    updateHocBa(state, data) {
        state.updateStatus = data;
    },

    setDataHocSinh(state, data) {
        state.detailHs = data;
    },

    getListHocBa(state, data) {
        state.listHocBa = data;
    },
    getThongTinHocBa(state, data) {
        state.thongTinHocBa = data
    },
    layDiemTongKetMon(state, data) {
        state.diemTongKetMon = data
    },
    layBieuDoTrangThaiHocBaTheoLop(state, data) {
        state.bieuDoTrangThaiHocBaTheoLop = data
    },
    layBieuDoTrangThaiHocBaTheoGiaoVien(state, data) {
        state.bieuDoTrangThaiHocBaTheoGiaoVien = data
    },
    layBieuDoTrangThaiHocBaTheoMonHoc(state, data) {
        state.bieuDoTrangThaiHocBaTheoMonHoc = data
    },
    layBieuDoTrangThaiHocBaTheoLopMonHoc(state,data){
        
        state.chiTietBieuDoTrangThaiTheoLopMon = data
    },
    setTrangThaiBieuDoTrangThaiHocBaTheoLop(state, data) {
        state.trangThaiBieuDoTrangThaiHocBaTheoLop = data
    },
    setTrangThaiBieuDoTrangThaiHocBaTheoGiaoVien(state, data) {
        state.trangThaiBieuDoTrangThaiHocBaTheoGiaoVien = data
    },
    setTrangThaiBieuDoTrangThaiHocBaTheoMonHoc(state, data) {
        state.trangThaiBieuDoTrangThaiHocBaTheoMonHoc = data
    },
    layBieuDoTrangThaiHocBaTongQuan(state, data) {
        state.bieuDoTrangThaiHocBaTongQuan = data
    },
    layBieuDoTrangThaiHocBaTongQuanHieuTruong(state, data) {
        state.bieuDoTrangThaiHocBaTongQuanHieuTruong = data
    },
    layBieuDoHocBaHocSinhHieuTruong(state, data) {
        state.bieuDoHocBaHocSinhHieuTruong = data
    },
    layBieuDoHocBaHocSinhGvcn(state, data) {
        state.bieuDoHocBaHocSinhGvcn = data
    },
    layChiTietTrangThaiHocBaTheoLop(state, data) {
        state.chiTietTrangThaiHocBaTheoLop = data
    },
    
    layChiTietTrangThaiHocBaTheoGVCN(state, data) {
        state.chiTietTrangThaiHocBaTheoGVCN = data
    },
    
    setFullScreenLoading(state, data) {
        state.fullScreenLoading = data
    },
    
    setTrangThaiBieuDoTrangThaiHocBaTongQuan(state, data) {
        state.trangThaiBieuDoTrangThaiHocBaTongQuan = data
    },
    setTrangThaiBieuDoTrangThaiHocBaTongQuanHieuTruong(state, data) {
        state.trangThaiBieuDoTrangThaiHocBaTongQuanHieuTruong = data
    },
    setTrangThaiBieuDoHocBaHocSinhHieutruong(state, data) {
        state.trangThaiBieuDoHocBaHocSinhHieuTruong = data
    },
    setTrangThaiBieuDoHocBaHocSinhGvcn(state, data) {
        state.trangThaiBieuDoHocBaHocSinhGvcn = data
    },
    setTrangThaiDiemTongKetMon(state, data) {
        state.trangThaiDiemTongKetMon = data
    }
};

export const hocba = {
    namespaced: true,
    state,
    actions,
    mutations
};
