<template>
<div></div>
</template>

<script>
    import api from '../_helpers/api';
    import constant_api from '../_helpers/constant_api';
    export default {
        name: "Login",
        metaInfo: {
            title: 'Học bạ số',
            titleTemplate: '%s | Đăng nhập',
            meta: [{
                vmid: 'description',
                name: 'description',
                content: ''
            }]
        },
        computed: {
        },
        methods: {

            objectToQueryString(obj) {
                let str = [];
                for (let p in obj)
                    if (obj.hasOwnProperty(p)) {
                        str.push(p + "=" + obj[p]);
                    }
                return str.join("&");
            },
            generateString(length) {
                let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let result = '';
                const charactersLength = characters.length;
                for (let i = 0; i < length; i++) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }

                return result;
            },

            linkToSSO() {
                let url = constant_api.login.getCodeSSO;
                console.log('url:' + url)
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                api.get(url, {}).then(
                    response => {
                        console.log("Res:")
                        console.log(response)
                        if (response.data.code == 200) {
                            loading.close();
                            let code_challenge = response.data.data.code_challenge;
                            let code_verifyer = response.data.data.code_verifier;
                            localStorage.setItem('code_verifyer', code_verifyer)
                            let params = {
                                response_type: 'code',
                                redirect_uri: 'https://hocba.edu.vn',
                                client_id: 'hocbadientu_client',
                                state: this.generateString(5),
                                scope: 'openid profile offline_access esmartup',
                                code_challenge: code_challenge,
                                code_challenge_method: 'S256',
                            };
                            console.log(params)
                            let query = this.objectToQueryString(params);
                            // let urlLogin = 'https://id.smartup.edu.vn/connect/authorize?' + query;
                            let urlLogin = 'https://id.nentanggiaoduc.edu.vn/connect/authorize?' + query;
                            console.log("urlLogin")
                            console.log(urlLogin)
                            loading.close();
                            window.location.href = urlLogin;
                        } else {
                            this.thongBao('error', response.data.msg)
                            loading.close();
                        }
                    }
                )
            }
        },
        data() {
            return {
            }
        },
        mounted() {
            console.error("redirecttosso")
            window.localStorage.removeItem('uriConnectWebsocket');
            window.localStorage.removeItem('messInfoOtpCode');
            window.localStorage.removeItem('OtpCode');
            this.linkToSSO();
        }
    }
</script>

<style scoped>

</style>