<template>
    <div class="page page-file file" v-loading.fullscreen.lock="fullscreenLoading">
        <component :is="view" @close="closeDialog"></component>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import dialog from "../dialog";
import TrungHoc from "./AddHocBa/TrungHoc";
import TieuHoc from "./AddHocBa/TieuHoc";

export default {
    name: "ThemHocBa",
    props : [

    ],
    computed: {
        ...mapState('account', [
            'user'
        ]),
        ...mapState('hocba', [
            "detailHs"
        ])
    },
    data : () => {
        return {
            view : TrungHoc,
            fullscreenLoading : false
        }
    },
    methods : {
        closeDialog(){
            this.$emit('close');
        },
        checkView(){
            if (this.detailHs.capHoc == 1){
                this.view = TieuHoc;
            }
            else this.view = TrungHoc;
        }
    },
    mounted() {
        this.checkView();
    }

}
</script>

<style scoped>

</style>
