var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "page__content",
    },
    [
      _c(
        "el-alert",
        {
          staticClass: "mb-2",
          attrs: {
            title: "Hướng dẫn",
            closable: false,
            "show-icon": "",
            type: "info",
          },
        },
        [
          _c("div", [
            _vm._v("\n            Bước 1: Chọn lớp muốn thêm học bạ"),
            _c("br"),
            _vm._v(
              "\n            Bước 2: Chọn Hình thức thêm, mặc định hệ thống hiển thị là “Upload file”. Nếu muốn nhập tay thì lựa chọn hình thức là “Nhập thông tin”"
            ),
            _c("br"),
            _vm._v(
              "\n            Bước 3: Nếu hình thức là upload file thì chọn File upload, người dùng scan kết quả học tập của năm học đó rồi upload file lên hệ thống, Trang bìa không phải scan. Nếu hình thức là Nhập thông tin thì điền các thông tin của học bạ."
            ),
            _c("br"),
            _vm._v("\n            Bước 4: Nhấn tạo học bạ"),
            _c("br"),
            _vm._v(
              "\n            Bước 5: Lặp lại từ bước 1 nếu muốn tạo tiếp học bạ hoặc nhấn button Đóng để kết thúc việc thêm mới.\n        "
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.listLopThieu.length
        ? _c(
            "el-alert",
            {
              attrs: {
                title: "Thiếu học bạ",
                type: "warning",
                closable: false,
                "show-icon": "",
              },
            },
            [
              _c(
                "ul",
                _vm._l(_vm.listLopThieu, function (item) {
                  return _c("li", { key: item.maLop }, [
                    _c("div", [
                      _vm._v(
                        " Năm học: " +
                          _vm._s(item.namHoc) +
                          ". Lớp : " +
                          _vm._s(item.tenLop)
                      ),
                    ]),
                  ])
                }),
                0
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("h5", [_vm._v("A. Thông tin học sinh")]),
      _vm._v(" "),
      _c("el-form", [
        _c(
          "div",
          [
            _c(
              "el-form-item",
              {
                staticClass: "d-inline-block",
                staticStyle: { width: "220px" },
                attrs: { prop: "maHS" },
              },
              [
                _c("div", [_vm._v("Mã học sinh:")]),
                _vm._v(" "),
                _c("el-input", {
                  attrs: { disabled: _vm.checkDisable(), placeholder: "Nhập" },
                  model: {
                    value: (_vm.formHocBa.maHS = _vm.getValue("maHS")),
                    callback: function ($$v) {
                      _vm.$set(_vm.formHocBa, "maHS = getValue('maHS')", $$v)
                    },
                    expression: "formHocBa.maHS = getValue('maHS')",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                staticClass: "d-inline-block",
                staticStyle: { width: "220px" },
                attrs: { prop: "hoten" },
              },
              [
                _c("div", [_vm._v("Họ tên học sinh:")]),
                _vm._v(" "),
                _c("el-input", {
                  attrs: { disabled: _vm.checkDisable(), placeholder: "Nhập" },
                  model: {
                    value: (_vm.formHocBa.hoten = _vm.getValue("tenHS")),
                    callback: function ($$v) {
                      _vm.$set(_vm.formHocBa, "hoten = getValue('tenHS')", $$v)
                    },
                    expression: "formHocBa.hoten = getValue('tenHS')",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                staticClass: "d-inline-block",
                staticStyle: { width: "120px" },
              },
              [
                _c("div", [_vm._v("Giới tính:")]),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    attrs: {
                      disabled: _vm.checkDisable(),
                      placeholder: "Giới tính",
                    },
                    model: {
                      value: (_vm.formHocBa.gioiTinh =
                        _vm.getValue("gioiTinh")),
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formHocBa,
                          "gioiTinh = getValue('gioiTinh')",
                          $$v
                        )
                      },
                      expression: "formHocBa.gioiTinh = getValue('gioiTinh')",
                    },
                  },
                  _vm._l(_vm.gioiTinh, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "d-inline-block", attrs: { prop: "year" } },
              [
                _c("div", [_vm._v("Ngày sinh:")]),
                _vm._v(" "),
                _c("el-date-picker", {
                  staticStyle: { width: "150px" },
                  attrs: {
                    format: "dd/MM/yyyy",
                    type: "date",
                    placeholder: "Ngày sinh",
                    "value-format": "dd/MM/yyyy",
                  },
                  model: {
                    value: (_vm.formHocBa.ngaySinh = _vm.getValue("ngaySinh")),
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formHocBa,
                        "ngaySinh = getValue('ngaySinh')",
                        $$v
                      )
                    },
                    expression: "formHocBa.ngaySinh = getValue('ngaySinh')",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-form-item",
              { staticClass: "d-inline-block", attrs: { prop: "namHoc" } },
              [
                _c("div", [_vm._v("Năm học:")]),
                _vm._v(" "),
                _c("el-date-picker", {
                  staticStyle: { width: "150px" },
                  attrs: {
                    disabled: "",
                    prop: "namHoc",
                    type: "year",
                    placeholder: "Năm học",
                    "value-format": "yyyy",
                  },
                  model: {
                    value: _vm.formHocBa.namHoc,
                    callback: function ($$v) {
                      _vm.$set(_vm.formHocBa, "namHoc", $$v)
                    },
                    expression: "formHocBa.namHoc",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                staticClass: "d-inline-block",
                staticStyle: { width: "130px" },
                attrs: { prop: "maLop" },
              },
              [
                _c("div", [_vm._v("Lớp:")]),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "Chọn lớp" },
                    on: { change: _vm.lopChange },
                    model: {
                      value: _vm.formHocBa.maLop,
                      callback: function ($$v) {
                        _vm.$set(_vm.formHocBa, "maLop", $$v)
                      },
                      expression: "formHocBa.maLop",
                    },
                  },
                  _vm._l(_vm.listLopThieu, function (item) {
                    return _c("el-option", {
                      key: item.maLop,
                      attrs: { label: item.tenLop, value: item.maLop },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "h4",
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.showManual,
                callback: function ($$v) {
                  _vm.showManual = $$v
                },
                expression: "showManual",
              },
            },
            [
              _c("el-radio", { attrs: { label: 1 } }, [_vm._v("Upload file")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 2 } }, [
                _vm._v("Nhập thông tin"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "formHocBa",
          attrs: {
            rules: _vm.rulesHocBa,
            model: _vm.formHocBa,
            "hide-required-asterisk": true,
          },
        },
        [
          !_vm.checkDisable()
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "d-inline-block",
                      staticStyle: { width: "400px" },
                    },
                    [
                      _c("div", [_vm._v("Nơi sinh:")]),
                      _vm._v(" "),
                      _c("el-input", {
                        attrs: { type: "textarea", placeholder: "Nơi sinh" },
                        model: {
                          value: (_vm.formHocBa.noiSinh =
                            _vm.getValue("noiSinh")),
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formHocBa,
                              "noiSinh = getValue('noiSinh')",
                              $$v
                            )
                          },
                          expression: "formHocBa.noiSinh = getValue('noiSinh')",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "d-inline-block",
                      staticStyle: { width: "400px" },
                    },
                    [
                      _c("div", [_vm._v("Nơi ở hiện tại:")]),
                      _vm._v(" "),
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "Nơi ở hiện tại",
                        },
                        model: {
                          value: (_vm.formHocBa.noiOHienNay =
                            _vm.getValue("noiOHienNay")),
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formHocBa,
                              "noiOHienNay = getValue('noiOHienNay')",
                              $$v
                            )
                          },
                          expression:
                            "formHocBa.noiOHienNay = getValue('noiOHienNay')",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.checkDisable()
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "d-inline-block",
                      staticStyle: { width: "250px" },
                    },
                    [
                      _c("div", [_vm._v("Họ tên bố:")]),
                      _vm._v(" "),
                      _c("el-input", {
                        attrs: { placeholder: "Họ tên bố" },
                        model: {
                          value: (_vm.formHocBa.hoTenCha =
                            _vm.getValue("hoTenCha")),
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formHocBa,
                              "hoTenCha = getValue('hoTenCha')",
                              $$v
                            )
                          },
                          expression:
                            "formHocBa.hoTenCha = getValue('hoTenCha')",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "d-inline-block",
                      staticStyle: { width: "250px" },
                    },
                    [
                      _c("div", [_vm._v("Nghề nghiệp bố:")]),
                      _vm._v(" "),
                      _c("el-input", {
                        attrs: { placeholder: "Nghề nghiệp bố" },
                        model: {
                          value: (_vm.formHocBa.ngheNghiepCha =
                            _vm.getValue("ngheNghiepCha")),
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formHocBa,
                              "ngheNghiepCha = getValue('ngheNghiepCha')",
                              $$v
                            )
                          },
                          expression:
                            "formHocBa.ngheNghiepCha = getValue('ngheNghiepCha')",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.checkDisable()
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "d-inline-block",
                      staticStyle: { width: "250px" },
                    },
                    [
                      _c("div", [_vm._v("Họ tên mẹ:")]),
                      _vm._v(" "),
                      _c("el-input", {
                        attrs: { placeholder: "Họ tên mẹ" },
                        model: {
                          value: (_vm.formHocBa.hoTenMe =
                            _vm.getValue("hoTenMe")),
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formHocBa,
                              "hoTenMe = getValue('hoTenMe')",
                              $$v
                            )
                          },
                          expression: "formHocBa.hoTenMe = getValue('hoTenMe')",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "d-inline-block",
                      staticStyle: { width: "250px" },
                    },
                    [
                      _c("div", [_vm._v("Nghề nghiệp Mẹ:")]),
                      _vm._v(" "),
                      _c("el-input", {
                        attrs: { placeholder: "Nghề nghiệp mẹ" },
                        model: {
                          value: (_vm.formHocBa.ngheNghiepMe =
                            _vm.getValue("ngheNghiepMe")),
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formHocBa,
                              "ngheNghiepMe = getValue('ngheNghiepMe')",
                              $$v
                            )
                          },
                          expression:
                            "formHocBa.ngheNghiepMe = getValue('ngheNghiepMe')",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showManual == 1
            ? [
                _c("h5", [_vm._v("B. Tải lên file học bạ")]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("UploadImageBase64", {
                      staticClass: "hocba__upload mb-1",
                      staticStyle: { width: "100px", cursor: "pointer" },
                      attrs: {
                        "default-preview": _vm.urlFile,
                        "image-class": "v1-image",
                        "input-class": "v1-image",
                        "max-size": _vm.customImageMaxSize,
                        placeholder: "Tải lên học bạ",
                      },
                      on: {
                        "size-exceeded": _vm.onSizeExceeded,
                        load: _vm.onLoad,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "showButton",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "hocba__uploadfile" },
                                  [
                                    _c("i", { staticClass: "el-icon-upload" }),
                                    _vm._v(" Tải lên file học bạ"),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3113980298
                      ),
                    }),
                  ],
                  1
                ),
              ]
            : [
                _c("h5", [_vm._v("B. Kết quả học tập")]),
                _vm._v(" "),
                _vm._l(_vm.monHoc, function (item, index) {
                  return _c("div", [
                    _c("div", [
                      _c("strong", [
                        _vm._v(_vm._s(index + 1) + ". " + _vm._s(item.monHoc)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "d-inline-block",
                            staticStyle: { width: "120px" },
                          },
                          [
                            _c(
                              "label",
                              [
                                _vm._v(
                                  "Điểm TB HK I:\n                            "
                                ),
                                _c("el-input", {
                                  attrs: { placeholder: "Điểm HK I" },
                                  model: {
                                    value: item.diemHocKyI,
                                    callback: function ($$v) {
                                      _vm.$set(item, "diemHocKyI", $$v)
                                    },
                                    expression: "item.diemHocKyI",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "d-inline-block",
                            staticStyle: { width: "120px" },
                          },
                          [
                            _c(
                              "label",
                              [
                                _vm._v(
                                  "Điểm TB HK II:\n                            "
                                ),
                                _c("el-input", {
                                  attrs: { placeholder: "Điểm HK II" },
                                  model: {
                                    value: item.diemHocKyII,
                                    callback: function ($$v) {
                                      _vm.$set(item, "diemHocKyII", $$v)
                                    },
                                    expression: "item.diemHocKyII",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "d-inline-block",
                            staticStyle: { width: "120px" },
                          },
                          [
                            _c(
                              "label",
                              [
                                _vm._v(
                                  "Điểm TB cả năm:\n                            "
                                ),
                                _c("el-input", {
                                  attrs: { placeholder: "Điểm cả năm" },
                                  model: {
                                    value: item.diemCaNam,
                                    callback: function ($$v) {
                                      _vm.$set(item, "diemCaNam", $$v)
                                    },
                                    expression: "item.diemCaNam",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("el-form-item", { staticClass: "d-inline-block" }, [
                          _c(
                            "label",
                            [
                              _vm._v(
                                "Điểm KT lại hoặc xếp loại sau KT lại:\n                            "
                              ),
                              _c("el-input", {
                                attrs: { placeholder: "Điểm KT lại" },
                                model: {
                                  value: item.diemThiLai,
                                  callback: function ($$v) {
                                    _vm.$set(item, "diemThiLai", $$v)
                                  },
                                  expression: "item.diemThiLai",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ])
                }),
                _vm._v(" "),
                _c("div", [
                  _c("h5", [_vm._v("C. Tổng kết cả năm")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          staticStyle: { width: "120px" },
                        },
                        [
                          _c(
                            "label",
                            [
                              _vm._v(
                                "Điểm TB HK I:\n                            "
                              ),
                              _c("el-input", {
                                attrs: { placeholder: "Điểm TB HK I" },
                                model: {
                                  value: _vm.formHocBa.diemTBHKI,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formHocBa, "diemTBHKI", $$v)
                                  },
                                  expression: "formHocBa.diemTBHKI",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          staticStyle: { width: "120px" },
                        },
                        [
                          _c(
                            "label",
                            [
                              _vm._v(
                                "Điểm TB HK II:\n                            "
                              ),
                              _c("el-input", {
                                attrs: { placeholder: "Điểm TB HK II" },
                                model: {
                                  value: _vm.formHocBa.diemTBHKII,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formHocBa, "diemTBHKII", $$v)
                                  },
                                  expression: "formHocBa.diemTBHKII",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          staticStyle: { width: "120px" },
                        },
                        [
                          _c(
                            "label",
                            [
                              _vm._v(
                                "Điểm TB cả năm:\n                            "
                              ),
                              _c("el-input", {
                                attrs: { placeholder: "Điểm TB cả năm" },
                                model: {
                                  value: _vm.formHocBa.diemTBCaNam,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formHocBa, "diemTBCaNam", $$v)
                                  },
                                  expression: "formHocBa.diemTBCaNam",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("el-form-item", { staticClass: "d-inline-block" }, [
                        _c(
                          "label",
                          [
                            _vm._v(
                              "Điểm trung bình sau KT lại:\n                            "
                            ),
                            _c("el-input", {
                              attrs: { placeholder: "Điểm TB sau thi lại" },
                              model: {
                                value: _vm.formHocBa.diemThiLai,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formHocBa, "diemThiLai", $$v)
                                },
                                expression: "formHocBa.diemThiLai",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          staticStyle: { width: "120px" },
                        },
                        [
                          _c(
                            "label",
                            [
                              _vm._v(
                                "Học lực HK I:\n                            "
                              ),
                              _c("el-input", {
                                attrs: { placeholder: "Học lực HK I" },
                                model: {
                                  value: _vm.formHocBa.hocLucHKI,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formHocBa, "hocLucHKI", $$v)
                                  },
                                  expression: "formHocBa.hocLucHKI",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          staticStyle: { width: "120px" },
                        },
                        [
                          _c(
                            "label",
                            [
                              _vm._v(
                                "Học lực HK II:\n                            "
                              ),
                              _c("el-input", {
                                attrs: { placeholder: "Học lực HK II" },
                                model: {
                                  value: _vm.formHocBa.hocLucHKII,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formHocBa, "hocLucHKII", $$v)
                                  },
                                  expression: "formHocBa.hocLucHKII",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          staticStyle: { width: "120px" },
                        },
                        [
                          _c(
                            "label",
                            [
                              _vm._v(
                                "Học lực cả năm:\n                            "
                              ),
                              _c("el-input", {
                                attrs: { placeholder: "Học lưc cả năm" },
                                model: {
                                  value: _vm.formHocBa.hocLucCN,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formHocBa, "hocLucCN", $$v)
                                  },
                                  expression: "formHocBa.hocLucCN",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("el-form-item", { staticClass: "d-inline-block" }, [
                        _c(
                          "label",
                          [
                            _vm._v(
                              "Học lực sau KT lại:\n                            "
                            ),
                            _c("el-input", {
                              attrs: { placeholder: "Học lực sau thi lại" },
                              model: {
                                value: _vm.formHocBa.hocLucThiLai,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formHocBa, "hocLucThiLai", $$v)
                                },
                                expression: "formHocBa.hocLucThiLai",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          staticStyle: { width: "120px" },
                        },
                        [
                          _c(
                            "label",
                            [
                              _vm._v(
                                "Hạnh kiểm HK I:\n                            "
                              ),
                              _c("el-input", {
                                attrs: { placeholder: "Hạnh kiểm" },
                                model: {
                                  value: _vm.formHocBa.hanhKiemHKI,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formHocBa, "hanhKiemHKI", $$v)
                                  },
                                  expression: "formHocBa.hanhKiemHKI",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          staticStyle: { width: "120px" },
                        },
                        [
                          _c(
                            "label",
                            [
                              _vm._v(
                                "Hạnh kiểm HK II:\n                            "
                              ),
                              _c("el-input", {
                                attrs: { placeholder: "Hạnh kiểm HK II" },
                                model: {
                                  value: _vm.formHocBa.hanhKiemHKII,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formHocBa, "hanhKiemHKII", $$v)
                                  },
                                  expression: "formHocBa.hanhKiemHKII",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          staticStyle: { width: "120px" },
                        },
                        [
                          _c(
                            "label",
                            [
                              _vm._v(
                                "Hạnh kiểm cả năm:\n                            "
                              ),
                              _c("el-input", {
                                attrs: { placeholder: "Hạnh kiểm cả năm" },
                                model: {
                                  value: _vm.formHocBa.hanhKiemCaNam,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formHocBa,
                                      "hanhKiemCaNam",
                                      $$v
                                    )
                                  },
                                  expression: "formHocBa.hanhKiemCaNam",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("el-form-item", { staticClass: "d-inline-block" }, [
                        _c(
                          "label",
                          [
                            _vm._v(
                              "Hạnh kiểm sau KT lại:\n                            "
                            ),
                            _c("el-input", {
                              attrs: { placeholder: "Hạnh kiểm sau thi lại" },
                              model: {
                                value: _vm.formHocBa.hanhKiemThiLai,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formHocBa, "hanhKiemThiLai", $$v)
                                },
                                expression: "formHocBa.hanhKiemThiLai",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ]),
              ],
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "button mb-5" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.taoHocBa("formHocBa")
                    },
                  },
                },
                [_vm._v("Tạo học bạ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "info" }, on: { click: _vm.closeClick } },
                [_vm._v("Đóng")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("dialog-alert", {
        attrs: {
          title: "Thông báo",
          message: _vm.messageAlert,
          "show-dialog": _vm.showAlert,
        },
        on: {
          closeDialog: function ($event) {
            _vm.showAlert = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }