<template>
  <div id="detailHocBa" class="page page-file file detailHocBa">
    <div class="page__title d-flex align-items-center justify-content-between" style="padding-top: 20px">
      <h1 class="title">Thời gian năm học</h1>
      <div style="margin-bottom : 20px">

      <el-button  type="primary" size="mini" @click.prevent="addNamHocClick">
        Thêm mới
      </el-button>
      </div>
    </div>

    <el-table
        :data="lstNamHoc.list"
        border
        width="100%"
        v-loading="loading"
        element-loading-text="Loading..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-table-column
          header-align="center"
          align="center"
          label="STT"
          type="index"
          :index="indexMethod"
      >
      </el-table-column>
      <el-table-column header-align="center" align="center" label="Năm học" width="180">
        <template slot-scope="scope">
          {{ scope.row.namHoc }} - {{ scope.row.namHoc + 1 }}
        </template>
      </el-table-column>
      <el-table-column
          header-align="center"
          align="center"
          label="Ngày ký lý lịch học sinh"
      >
        <template slot-scope="scope">
          {{ new Date(scope.row.ngayBatDau) | moment("DD/MM/YYYY") }}
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          header-align="center"
          label="Ngày ký xác nhận hiệu trưởng"
      >
        <template slot-scope="scope">
          {{ new Date(scope.row.ngayKetThuc) | moment("DD/MM/YYYY") }}
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          header-align="center"
          label="Ngày ký học bạ thi lại"
      >
        <template slot-scope="scope">
          {{ new Date(scope.row.ngayKetThucThiLai) | moment("DD/MM/YYYY") }}
        </template>
      </el-table-column>
      <el-table-column
          header-align="center"
          align="center"
          property="tenDiaDanh"
          label="Tên địa danh ký"
      >
      </el-table-column>
      <el-table-column
          header-align="center"
          align="center"
          property="capQuanLySo"
          label="Cấp quản lý ghi trang bìa"
      >
      </el-table-column>
      <el-table-column align="center" header-align="center" label="Hành động">
        <template slot-scope="scope">
          <div class="">
            <el-tooltip
                class="item"
                effect="dark"
                content="Chỉnh sửa năm học"
                placement="top"
            >
              <el-button
                  @click.prevent="editNamHocClick(scope.row)"
                  size="mini"
                  icon="el-icon-edit-outline"
                  type="primary"
              ></el-button>
            </el-tooltip>
            <el-tooltip
                slot="reference"
                class="item"
                effect="dark"
                content="Xóa năm học"
                placement="top"
                v-if="!scope.row.status"
            >
              <el-button
                  size="mini"
                  @click.prevent="confirmDel(scope.row.id, scope.row.namHoc)"
                  type="danger"
                  icon="el-icon-delete"
              ></el-button>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div v-if="false" class="text-right mt-2">
      <pagination
          v-if="lstNamHoc && lstNamHoc.total"
          :value="paginate.current"
          :page-count="getTotalPage(lstNamHoc.total)"
          :click-handler="getListNamHoc"
          :prev-text="'Trước'"
          :next-text="'Sau'"
          :container-class="'el-pager'"
          :pageClass="'number'"
      >
      </pagination>
    </div>
    <el-dialog
        title="Thêm mới năm học"
        width="80%"
        center
        :visible.sync="showPopUpAdd"
        :before-close="closeDialogFormCreate"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        custom-class="thongTinCauHinhNamHoc"
        class="uploadSignature"
    >
      <el-form ref="addNamHoc" :model="createData" :inline="true" :rules="rules">
        <el-row :gutter="24">
          <el-col :lg="6" :sm="12" :xs="24">
            <el-form-item prop="namHoc">
              <label>Năm học</label>
              <SelectNamHoc style="display: block" v-model="createData.namHoc"/>
            </el-form-item>
          </el-col>
          <el-col :lg="6" :sm="12" :xs="24">
            <el-form-item prop="startDate">
              <label>Ngày ký lý lịch học sinh <sup style="color: #DA1D1D">*</sup></label>
              <el-date-picker
                  style="display: block"
                  value-format="timestamp"
                  format="dd/MM/yyyy"
                  v-model="createData.startDate"
                  type="date"
                  placeholder="dd/mm/yyyy"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :lg="6" :sm="12" :xs="24">
            <el-form-item prop="endDate">
              <label>Ngày ký xác nhận hiệu trưởng <sup style="color: #DA1D1D">*</sup></label>
              <el-date-picker
                  style="display: block"
                  format="dd/MM/yyyy"
                  v-model="createData.endDate"
                  value-format="timestamp"
                  type="date"
                  placeholder="dd/mm/yyyy"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :lg="6" :sm="12" :xs="24">
            <el-form-item prop="ngayKetThucThiLai">
              <label>Ngày ký học bạ thi lại <sup style="color: #DA1D1D">*</sup></label>
              <el-date-picker
                  style="display: block"
                  format="dd/MM/yyyy"
                  v-model="createData.ngayKetThucThiLai"
                  value-format="timestamp"
                  type="date"
                  placeholder="dd/mm/yyyy"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :lg="6" :sm="12" :xs="24">
            <el-form-item prop="capQuanLySo">
              <label
              >Cấp quản lý ghi trang bìa <sup style="color: #DA1D1D">*</sup>
                <el-tooltip placement="top">
                  <div slot="content">
                    Ghi rõ: BỘ GIÁO DỤC VÀ ĐÀO TẠO hoặc SỞ GIÁO DỤC VÀ ĐÀO TẠO <br/>(Ví
                    dụ: Sở Giáo dục và Đào tạo Thái Nguyên)
                  </div>
                  <small><i style="color: blue">Hướng dẫn</i></small>
                </el-tooltip>
              </label>

              <el-input
                  @change="handleInputChange('capQuanLySo')"
                  clearable
                  placeholder="Nhập"
                  type="text"
                  v-model="createData.capQuanLySo"
              ></el-input>
              <!--                            <el-input rows="1" placeholder="Nhập" type="textarea" v-model="createData.capQuanLySo" style="width: 100%"></el-input>-->

              <!--                            <textarea v-model="createData.capQuanLySo" class="form-control" style="height: 40px"-->
              <!--                                      rows="1" placeholder="Nhập nội dung">{{createData.capQuanLySo}}</textarea>-->
            </el-form-item>
          </el-col>
          <el-col :lg="6" :sm="12" :xs="24">
            <el-form-item prop="tenDiaDanh">
              <label>Tên địa danh ký <sup style="color: #DA1D1D">*</sup></label>
              <el-input
                  @change="handleInputChange('tenDiaDanh')"
                  clearable
                  placeholder="Nhập"
                  type="text"
                  v-model="createData.tenDiaDanh"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="closeDialogFormCreate">Hủy</el-button>
        <el-button
            :disabled="!confirm_createData"
            size="mini"
            type="primary"
            @click.prevent="submitAddNamHoc('addNamHoc')"
        >Lưu</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
        @close="closeDialogFormUpdate"
        :title="'Chỉnh sửa năm học ' + editData.namHoc + '-' + (editData.namHoc + 1)"
        width="80%"
        center
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        :visible="showPopUpEdit"
        custom-class="thongTinCauHinhNamHoc"
        class="uploadSignature"
    >
      <el-form ref="editNamHoc" :model="editData" :inline="true" :rules="rules">
        <el-row :gutter="24">
          <el-col :lg="6" :sm="12" :xs="24">
            <el-form-item prop="namHoc">
              <label>Năm học</label>
              <SelectNamHoc disabled style="display: block" v-model="editData.namHoc"/>
            </el-form-item>
          </el-col>
          <el-col :lg="6" :sm="12" :xs="24">
            <el-form-item prop="startDate">
              <label>Ngày ký lý lịch học sinh <sup style="color: #DA1D1D">*</sup></label>
              <el-date-picker
                  style="display: block"
                  value-format="timestamp"
                  format="dd/MM/yyyy"
                  v-model="editData.startDate"
                  type="date"
                  placeholder="dd/mm/yyyy"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :lg="6" :sm="12" :xs="24">
            <el-form-item prop="endDate">
              <label>Ngày ký xác nhận hiệu trưởng <sup style="color: #DA1D1D">*</sup></label>
              <el-date-picker
                  style="display: block"
                  format="dd/MM/yyyy"
                  v-model="editData.endDate"
                  value-format="timestamp"
                  type="date"
                  placeholder="dd/mm/yyyy"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :lg="6" :sm="12" :xs="24">
            <el-form-item prop="ngayKetThucThiLai">
              <label>Ngày ký học bạ thi lại <sup style="color: #DA1D1D">*</sup></label>
              <el-date-picker
                  style="display: block"
                  format="dd/MM/yyyy"
                  v-model="editData.ngayKetThucThiLai"
                  value-format="timestamp"
                  type="date"
                  placeholder="dd/mm/yyyy"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :lg="6" :sm="12" :xs="24">
            <el-form-item prop="capQuanLySo">
              <label
              >Cấp quản lý ghi trang bìa <sup style="color: #DA1D1D">*</sup>

                <el-tooltip placement="top">
                  <div slot="content">
                    Ghi rõ: BỘ GIÁO DỤC VÀ ĐÀO TẠO hoặc SỞ GIÁO DỤC VÀ ĐÀO TẠO <br/>
                    (Ví dụ: Sở Giáo dục và Đào tạo Thái Nguyên)
                  </div>
                  <small><i style="color: blue">Hướng dẫn</i></small>
                </el-tooltip>
              </label>

              <el-input
                  @change="handleInputChange('capQuanLySo')"
                  clearable
                  placeholder="Nhập"
                  type="text"
                  v-model="editData.capQuanLySo"
              ></el-input>
              <!--                            <el-input placeholder="Nhập nội dung"  rows="1" type="textarea" v-model="editData.capQuanLySo" style="width: 100%"></el-input>-->
              <!--                            <textarea v-model="editData.capQuanLySo" class="form-control" style="height: 40px" rows="1"-->
              <!--                                      placeholder="Nhập nội dung">{{editData.capQuanLySo}}</textarea>-->
            </el-form-item>
          </el-col>
          <el-col :lg="6" :sm="12" :xs="24">
            <el-form-item prop="tenDiaDanh">
              <label>Tên địa danh ký <sup style="color: #DA1D1D">*</sup></label>
              <el-input
                  @change="handleInputChange('tenDiaDanh')"
                  clearable
                  placeholder="Nhập"
                  type="text"
                  v-model="editData.tenDiaDanh"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="closeDialogFormUpdate">Hủy</el-button>
        <el-button
            :disabled="!confirm_editData"
            size="mini"
            type="primary"
            @click.prevent="submitEditNamHoc('editNamHoc')"
        >Lưu</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "../_helpers/api";
import uri from "../api";
import constant from "../_helpers/constant_api";
import Pagination from "../components/Pagination";
import SelectNamHoc from "../components/SelectNamHoc";
import {mapActions, mapState} from "vuex";
import util from "../util";

export default {
  name: "QuanLyNamHoc",
  metaInfo: {
    title: "Thời gian năm học",
  },
  components: {
    Pagination,
    SelectNamHoc,
  },

  computed: {
    ...mapState("account", ["user", "list_nam_hoc", "nam_hoc_hien_tai", "list_cap_hoc"]),
  },
  watch: {
    createData: {
      handler(val) {
        const {namHoc, ...fieldsToCheck} = val;

        const isNamHocChanged = this.createData.namHoc !== this.nam_hoc_hien_tai;

        const isOtherFieldsChanged = Object.values(fieldsToCheck).some(
            (value) => value !== ""
        );

        this.confirm_createData = isNamHocChanged || isOtherFieldsChanged;
      },
      deep: true,
    },

    editData: {
      handler(val) {
        this.confirm_editData = util.hasChangesToConfirmBeforeClose(
            val,
            this.original_editData
        );
      },
      deep: true,
    },

    showPopUpEdit(val) {
      if (val) {
        this.editData.startDate = this.editItem.ngayBatDau;
        this.editData.endDate = this.editItem.ngayKetThuc;
        this.editData.namHoc = this.editItem.namHoc;
        this.editData.tenDiaDanh = JSON.parse(JSON.stringify(this.editItem.tenDiaDanh));
        this.editData.capQuanLySo = this.editItem.capQuanLySo;
        this.editData.ngayKetThucThiLai = this.editItem.ngayKetThucThiLai;

        this.original_editData.startDate = this.editItem.ngayBatDau;
        this.original_editData.endDate = this.editItem.ngayKetThuc;
        this.original_editData.namHoc = this.editItem.namHoc;
        this.original_editData.tenDiaDanh = JSON.parse(
            JSON.stringify(this.editItem.tenDiaDanh)
        );
        this.original_editData.capQuanLySo = this.editItem.capQuanLySo;
        this.original_editData.ngayKetThucThiLai = this.editItem.ngayKetThucThiLai;
      } else {
        this.confirm_editData = false;
      }
    },
  },
  data() {
    return {
      lstNamHoc: [],
      paginate: {
        start: 0,
        limit: 100,
        current: 1,
      },
      createData: {
        namHoc: "",
        capQuanLySo: "",
        ngayKetThucThiLai: "",
        startDate: "",
        endDate: "",
        tenDiaDanh: "",
      },
      original_createData: {
        namHoc: "",
        capQuanLySo: "",
        ngayKetThucThiLai: "",
        startDate: "",
        endDate: "",
        tenDiaDanh: "",
      },
      confirm_createData: false,

      editData: {
        capQuanLySo: "",
        ngayKetThucThiLai: "",
        namHoc: "",
        startDate: "",
        endDate: "",
        tenDiaDanh: "",
      },

      original_editData: {
        capQuanLySo: "",
        ngayKetThucThiLai: "",
        namHoc: "",
        startDate: "",
        endDate: "",
        tenDiaDanh: "",
      },
      confirm_editData: false,

      rules: {
        namHoc: [{required: true, message: "Vui lòng chọn Năm học", trigger: "change"}],
        startDate: [
          {
            required: true,
            message: "Vui lòng chọn Ngày ký lịch học sinh",
            trigger: ["blur", "change"],
          },
        ],
        endDate: [
          {
            required: true,
            message: "Vui lòng chọn Ngày ký xác nhận hiệu trưởng",
            trigger: ["blur", "change"],
          },
        ],
        ngayKetThucThiLai: [
          {
            required: true,
            message: "Vui lòng chọn Ngày ký học bạ thi lại",
            trigger: ["blur", "change"],
          },
        ],
        capQuanLySo: [
          {
            required: true,
            message: "Vui lòng nhập Cấp quản lý ghi trang bìa",
            trigger: ["blur", "change"],
          },
        ],
        tenDiaDanh: [
          {
            required: true,
            message: "Vui lòng nhập Tên địa danh ký",
            trigger: ["blur", "change"],
          },
          {
            max: 50,
            message: "Tên địa danh ký không được vượt quá 50 ký tự",
            trigger: ["blur", "change"],
          },
        ],
      },
      showPopUpAdd: false,
      showPopUpEdit: false,
      loading: false,
      editItem: null,
    };
  },
  methods: {
    confirmDel(id, year) {
      this.$confirm(
          "Xác nhận xoá thông tin năm học " + year + "-" + (year + 1) + "?",
          "Thông báo",
          {
            confirmButtonText: "Xoá năm học",
            cancelButtonText: "Hủy",
            closeOnClickModal: false,
            confirmButtonClass: "el-button--danger",
            closeOnPressEscape: true,
          }
      )
          .then((_) => {
            this.onConfirm(id);
          })
          .catch((_) => {
          });
      document.addEventListener("keydown", this.preventEnter, true);
    },
    preventEnter(event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    },
    editNamHocClick(item) {
      this.editItem = item;
      this.showPopUpEdit = true;
    },
    onConfirm(id) {
      this.loading = true;
      let url = uri.school.deleteNamHoc;
      api.post(url, {id: id}).then((response) => {
        console.log("Res:");
        console.log(response);
        if (response.data.code == 200) {
          this.getListNamHoc();
          this.thongBao("success", "Xoá năm học thành công");
        } else {
          this.thongBao("error", "Xoá năm học thất bại");
        }
      });
    },

    indexMethod(index) {
      return this.paginate.start + index + 1;
    },
    addNamHocClick() {
      this.showPopUpAdd = true;
    },

    editNamHoc() {
      let url = uri.school.updateNamHoc;
      let params = {
        id: this.editItem.id,
        namHoc: this.editData.namHoc,
        ngayBatDau: this.editData.startDate,
        ngayKetThuc: this.editData.endDate,
        tenDiaDanh: this.editData.tenDiaDanh,
        capQuanLySo: this.editData.capQuanLySo,
        ngayKetThucThiLai: this.editData.ngayKetThucThiLai,
      };
      api.post(url, params).then((response) => {
        this.loading = false;
        console.log("Res:");
        console.log(response);
        if (response.data.code == 200) {
          this.showPopUpEdit = false;
          this.resetFormUpdate();

          this.getListNamHoc();
          this.thongBao("success", "Chỉnh sửa năm học thành công");
        } else {
          this.thongBao("error", response.data.msg);
        }
      });
    },
    handleInputChange(field) {
      this.createData[field] = this.createData[field]?.trim();
      this.editData[field] = this.editData[field]?.trim();
    },
    submitAddNamHoc(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;

          let url = uri.school.addNamHoc;
          let params = {
            namHoc: this.createData.namHoc,
            ngayBatDau: this.createData.startDate,
            ngayKetThuc: this.createData.endDate,
            tenDiaDanh: this.createData.tenDiaDanh,
            capQuanLySo: this.createData.capQuanLySo,
            ngayKetThucThiLai: this.createData.ngayKetThucThiLai,
          };

          api.post(url, params).then((response) => {
            this.loading = false;
            console.log("Res:");
            console.log(response);

            if (response.data.code == 200) {
              this.showPopUpAdd = false;
              this.thongBao("success", "Thêm năm học thành công");

              this.resetFormCreate();

              this.getListNamHoc();
            } else {
              this.thongBao("error", response.data.msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t;
      }
      if (type == "success") {
        cl = "dts-noty-success";
      }
      if (type == "warning") {
        cl = "dts-noty-warning";
      }
      if (type == "error") {
        cl = "dts-noty-error";
      }
      if (type == "info") {
        cl = "dts-noty-info";
      }
      document.querySelector('.overlay').style.display = 'block';
      this.$message({
        onClose: () => {
          document.querySelector('.overlay').style.display = 'none';
        },
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000,
      });
    },

    resetFormUpdate() {
      this.$refs.editNamHoc.resetFields();
      this.confirm_editData = false;
    },

    closeDialogFormUpdate() {
      console.log('closeDialogFormUpdate')
      if (this.confirm_editData) {
        this.$confirm(
            "Các thông tin vừa nhập sẽ bị mất khi đóng trang. Thầy cô có muốn tiếp tục chỉnh sửa, kiểm tra và lưu lại thông tin đã nhập?",
            "Thông báo",
            {
              closeOnClickModal: false,
              confirmButtonText: "Rời khỏi trang",
              cancelButtonText: "Tiếp tục chỉnh sửa",
              confirmButtonClass: "el-button--danger",
            }
        )
            .then(() => {
              console.log('case 1')
              this.resetFormUpdate();
              this.showPopUpEdit = false;
            })
            .catch(() => {
              console.log('case 2')
              // this.resetFormUpdate();
            });
      } else {
        console.log('case 3')
        // this.resetFormUpdate();
        this.showPopUpEdit = false;
      }
    },

    closeDialogFormCreate() {
      if (this.confirm_createData) {
        this.$confirm(
            "Các thông tin vừa nhập sẽ bị mất khi đóng trang. Thầy cô có muốn tiếp tục chỉnh sửa, kiểm tra và lưu lại thông tin đã nhập?",
            "Thông báo",
            {
              closeOnClickModal: false,
              confirmButtonText: "Rời khỏi trang",
              cancelButtonText: "Tiếp tục thêm mới",
              confirmButtonClass: "el-button--danger",
            }
        ).then(() => {
          this.resetFormCreate();
          this.showPopUpAdd = false;
        });
      } else {
        // this.resetFormCreate();
        this.showPopUpAdd = false;
      }
    },
    resetFormCreate() {
      this.createData = {
        namHoc: this.nam_hoc_hien_tai,
        startDate: "",
        endDate: "",
        ngayKetThucThiLai: "",
        capQuanLySo: "",
        tenDiaDanh: "",
      };
      this.$refs.addNamHoc.resetFields();
      this.confirm_createData = false;
    },

    submitEditNamHoc(formName) {
      console.log('submitEditNamHoc')
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm("Xác nhận chỉnh sửa thông tin năm học?", "Thông báo", {
            confirmButtonText: "Lưu",
            cancelButtonText: "Hủy",
            closeOnClickModal: false,
            confirmButtonClass: "el-button--primary",
          })
              .then((_) => {
                this.editNamHoc();
              })
              .catch((_) => {
              });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getTotalPage(number) {
      return Math.ceil(number / this.paginate.limit);
    },

    getListNamHoc(select) {
      if (select) {
        let start =
            (select - 1) * this.paginate.limit ? (select - 1) * this.paginate.limit : 0;
        this.paginate.start = start;
        this.paginate.current = select;
      }

      let params = {
        start: this.paginate.start,
        limit: this.paginate.limit,
      };
      let url = uri.school.getListNamHoc;
      api.get(url, params).then((response) => {
        console.log("Res:");
        console.log(response);
        if (response.data.code == 200) {
          this.lstNamHoc = response.data.data;
        } else {
          this.lstNamHoc = [];
        }
        this.loading = false;
      });
    },
    getFullYear() {
      let d = new Date();
      return d.getFullYear();
    },
    getCurrentMonth() {
      let d = new Date();
      return d.getMonth();
    },
  },
  mounted() {
    this.createData.namHoc = this.nam_hoc_hien_tai;
    this.original_createData.namHoc = this.nam_hoc_hien_tai;
    this.getListNamHoc(1);
  },
};
</script>

<style scoped></style>
