import {LoginSsoService} from "../_services";
import {router} from "../_router";
import el from "element-ui/src/locale/lang/el";
import util from "../util";

const state = {
    resuleDetect: null,
    statusLogin: null
};

function ranDomString(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

function objectToQueryString(obj) {
    let str = [];
    for (let p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(p + "=" + obj[p]);
        }
    return str.join("&");
}

const actions = {
    loginWithCode({commit, dispatch}, code) {
        LoginSsoService.detectCodeV2(code).then(
            response => {
                console.error('THông tin dêtctCode')
                console.error(response.data)
                console.error('***********')
                if (response.data.code == 200 && response.data.data.access_token != null) {
                    console.log(JSON.stringify("response.access_token"));
                    console.log(JSON.stringify(response));
                    let access_token = response.data.data.access_token;
                    localStorage.setItem('id_token', response.data.data.id_token);
                    console.log("access token mới");
                    console.log(access_token)
                    LoginSsoService.getSsoInfoV2(access_token).then(
                        response => {
                            console.log("đăng nhập trả về:");
                            console.log(response);
                            if (response.data && response.data.code == 200) {
                                localStorage.setItem('userSso', true);
                                localStorage.setItem('user', JSON.stringify(response.data.data));
                                // localStorage.setItem('token', response.data.data.token);
                                util.setCookie('token', response.data.data.token, 60)
                                sessionStorage.setItem("token", response.data.data.token);
                                let token1   = sessionStorage.getItem("token");
                                console.log('token session:'+token1);
                                commit('account/loginSuccess', response.data.data, {root: true});
                                dispatch('alert/clear', null, {root: true});
                                if(true){
                                    window.location.href = '/thong-tin-tai-khoan';
                                    // router.push({
                                    //     name: 'ThongTinTaiKhoan'
                                    // });
                                }
                                else if (response.data.data.role == 5) {
                                    router.push({name: 'DanhSachHocBa'});
                                } else if (response.data.data.role == 8) {
                                    router.push({name: 'CauHinhNguoiDung'});
                                } else if (response.data.data.role == 7 || response.data.data.role == 6 || response.data.data.role == 4) {
                                    router.push({
                                        name: 'ThongKeBaoCao'
                                    });
                                } else {
                                    router.push({name: 'DashBoard'});
                                }
                            } else {
                                console.error('Case: 123')
                                dispatch('alert/error', response.data.msg ? response.data.msg : 'Đang bảo trì hệ thống', {root: true});
                                let domain = window.location.protocol + '//' + window.location.hostname;
                                let id_token = localStorage.getItem('id_token')
                                let state = ranDomString(5)
                                let post_logout_redirect_uri = domain + '/login'
                                let config = {
                                    id_token_hint: id_token,
                                    state: state,
                                    post_logout_redirect_uri: post_logout_redirect_uri,
                                }
                                let base_url = 'https://id.nentanggiaoduc.edu.vn/connect/endsession?'
                                let url_logout = base_url + objectToQueryString(config);
                                console.error('url_logout')
                                console.error(url_logout)
                                setTimeout(() => {
                                    window.location.href = url_logout
                                }, 5000)
                            }
                        }
                    )
                } else {
                    alert("Không tìm thấy thông tin người dùng. Vui lòng thử lại.")
                    console.error("Không tìm thấy thông tin người dùng. Vui lòng thử lại.")
                    window.location.href = "/logout";
                }
            }
        ).catch(function (e) {
            commit('checkCode', null)
        })
    },


    loginWithSMAS({commit, dispatch}, code) {
        console.log("loginWithSMAS" + code)
        LoginSsoService.getSsoInfoSmas(code).then(
            response => {
                console.log("đăng nhập trả về:");
                console.log(response);
                if (response.data && response.data.code == 200) {
                    localStorage.setItem('user', JSON.stringify(response.data.data));
                    // localStorage.setItem('token', response.data.data.token);
                    util.setCookie('token', response.data.data.token, 60)
                    commit('account/loginSuccess', response.data.data, {root: true});
                    dispatch('alert/clear', null, {root: true});
                    if (response.data.data.role == 5) {
                        router.push({name: 'DanhSachHocBa'});
                    } else if (response.data.data.role == 8) {
                        router.push({name: 'CauHinhNguoiDung'});
                    } else if (response.data.data.role == 7 || response.data.data.role == 6 || response.data.data.role == 4) {
                        router.push({
                            name: 'ThongKeBaoCao'
                        });
                    } else {
                        router.push({name: 'DashBoard'});
                    }
                } else {
                    commit('account/loginFailure', null, {root: true});
                    dispatch('alert/error', response.data.msg ? response.data.msg : 'Đang bảo trì hệ thống', {root: true});
                }
            }
        )
    },
    loginWithK12({commit, dispatch}, code) {
        console.log("loginWithK12" + code)
        LoginSsoService.getInfoK12(code).then(
            response => {
                console.log("đăng nhập k12 trả về:");
                console.log(response);
                if (response.data && response.data.code == 200) {
                    localStorage.setItem('user', JSON.stringify(response.data.data));
                    // localStorage.setItem('token', response.data.data.token);
                    util.setCookie('token', response.data.data.token, 60)
                    commit('account/loginSuccess', response.data.data, {root: true});
                    dispatch('alert/clear', null, {root: true});
                    if (response.data.data.role == 5) {
                        router.push({name: 'DanhSachHocBa'});
                    } else if (response.data.data.role == 8) {
                        router.push({name: 'CauHinhNguoiDung'});
                    } else if (response.data.data.role == 7 || response.data.data.role == 6 || response.data.data.role == 4) {
                        router.push({
                            name: 'ThongKeBaoCao'
                        });
                    } else {
                        router.push({name: 'DashBoard'});
                    }
                } else {
                    commit('account/loginFailure', null, {root: true});
                    dispatch('alert/error', response.data.msg ? response.data.msg : 'Đang bảo trì hệ thống', {root: true});
                }
            }
        )
    },

    loginWithHcm({commit, dispatch}, code) {
        console.log("loginWithHcm" + code)
        LoginSsoService.getInfoHcm(code).then(
            response => {
                console.log("đăng nhập HCM trả về:");
                console.log(response);
                if (response.data && response.data.code == 200) {
                    localStorage.setItem('user', JSON.stringify(response.data.data));
                    // localStorage.setItem('token', response.data.data.token);
                    util.setCookie('token', response.data.data.token, 60)
                    commit('account/loginSuccess', response.data.data, {root: true});
                    dispatch('alert/clear', null, {root: true});
                    if (response.data.data.role == 5) {
                        router.push({name: 'DanhSachHocBa'});
                    } else if (response.data.data.role == 8) {
                        router.push({name: 'CauHinhNguoiDung'});
                    } else if (response.data.data.role == 7 || response.data.data.role == 6 || response.data.data.role == 4) {
                        router.push({
                            name: 'ThongKeBaoCao'
                        });
                    } else {
                        router.push({name: 'DashBoard'});
                    }
                } else {
                    commit('account/loginFailure', null, {root: true});
                    dispatch('alert/error', response.data.msg ? response.data.msg : 'Đang bảo trì hệ thống', {root: true});
                }
            }
        )
    }
};
const mutations = {
    checkCode(state, data) {
        state.resuleDetect = data;
    }
};
export const sso = {
    namespaced: true,
    state,
    actions,
    mutations
};