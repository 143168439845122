<template>
    <div class="page page-file file" v-loading.fullscreen.lock="fullscreenLoading">
        <div>
            <div class="file--title">
                <h3 class="title">Sửa học bạ</h3>
            </div>
        </div>
        <component :is="view" @close="closeEdit"></component>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import dialog from "../dialog";
import TrungHoc from "./SuaHocBa/TrungHoc";
import TieuHoc from "./SuaHocBa/TieuHoc";

export default {
    name: "SuaHocBa",
    computed: {
        ...mapState('account', [
            'user'
        ]),
        ...mapState('hocba', [
            "detailHs"
        ])
    },
    data : () => {
        return {
            view : TrungHoc,
            fullscreenLoading : false
        }
    },
    methods : {
        closeEdit(){
            this.$emit('close');
        },
        checkView(){
            if (this.detailHs.loaiHocBa == 1){
                this.view = TieuHoc;
            }
            else this.view = TrungHoc;
        }
    },
    mounted() {
        this.checkView();
    },
    watch : {
        detailHs(val){
            if (this.detailHs.loaiHocBa == 1){
                this.view = TieuHoc;
            }
            else this.view = TrungHoc;
        }
    }

}
</script>

<style scoped>

</style>
