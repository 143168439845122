var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { ref: "pdfViewer" }, [
      _c("iframe", {
        staticStyle: { width: "100%", height: "100vh", "min-height": "600px" },
        attrs: { src: _vm.urlPdf },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }