var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    directives: [
      {
        name: "loading",
        rawName: "v-loading.fullscreen.lock",
        value: _vm.loading,
        expression: "loading",
        modifiers: { fullscreen: true, lock: true },
      },
    ],
    attrs: {
      "element-loading-text": _vm.loadingText,
      "element-loading-spinner": "el-icon-loading",
      "element-loading-background": "rgba(0, 0, 0, 0.8)",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }