<template>
    <div class="page__content" v-loading.fullscreen.lock="fullscreenLoading">
        <el-form :rules="rulesHocBa" ref="formHocBa" :model="formHocBa" :hide-required-asterisk="true">
            <div>
                <h5>A. Thông tin học sinh</h5>
                <div>
                    <el-form-item style="width: 100px" class="d-inline-block" prop="education">
                        <label>Cấp học:</label>
                        <el-select disabled v-model="formHocBa.capHoc = this.user.capHoc" placeholder="Cấp học">
                            <el-option v-for="item in optionEducation" :label="item.label" :key="item.value"
                                       :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item class="d-inline-block" prop="namHoc">
                        <div>Năm học:</div>
                        <!--                        <el-date-picker prop="namHoc" style="width: 150px" v-model="formHocBa.namHoc" type="year" placeholder="Năm học" value-format="yyyy">-->
                        <!--                        </el-date-picker>-->
                        <SelectNamHoc v-model="formHocBa.namHoc"/>
                    </el-form-item>
                    <el-form-item style="width: 120px" class="d-inline-block" prop="khoiHoc">
                        <div>Chọn khối:</div>
                        <el-select @change="khoiHocChange" v-model="formHocBa.khoiHoc" placeholder="Chọn khối">
                            <el-option v-for="item in optionGrade" :label="item.label" :key="item.value"
                                       :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item style="width: 130px" class="d-inline-block" prop="maLop">
                        <div>Lớp:</div>
                        <el-select v-model="formHocBa.maLop" placeholder="Chọn lớp">
                            <el-option v-for="item in listLop.list" :label="item.tenLop" :key="item.maLop"
                                       :value="item.maLop"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div>
                    <el-form-item style="width: 220px" class="d-inline-block" prop="hoten">
                        <div>Mã học sinh:</div>
                        <el-input v-model="formHocBa.maHS" placeholder="Nhập"></el-input>
                    </el-form-item>
                    <el-form-item style="width: 220px" class="d-inline-block" prop="hoten">
                        <div>Họ tên học sinh:</div>
                        <el-input v-model="formHocBa.hoten" placeholder="Nhập"></el-input>
                    </el-form-item>
                    <el-form-item style="width: 120px" class="d-inline-block">
                        <div>Giới tính:</div>
                        <el-select v-model="formHocBa.gioiTinh" placeholder="Giới tính">
                            <el-option v-for="item in gioiTinh" :label="item.label" :key="item.value"
                                       :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item class="d-inline-block" prop="year">
                        <div>Ngày sinh:</div>
                        <el-date-picker style="width: 150px" v-model="formHocBa.ngaySinh" format="dd/MM/yyyy"
                                        type="date" placeholder="Ngày sinh" value-format="dd/MM/yyyy">
                        </el-date-picker>
                    </el-form-item>
                </div>
                <div>
                    <el-form-item style="width: 400px" class="d-inline-block">
                        <div>Nơi sinh:</div>
                        <el-input type="textarea" v-model="formHocBa.noiSinh" placeholder="Nơi sinh"></el-input>
                    </el-form-item>
                    <el-form-item style="width: 400px" class="d-inline-block">
                        <div>Nơi ở hiện tại:</div>
                        <el-input type="textarea" v-model="formHocBa.noiOHienNay"
                                  placeholder="Nơi ở hiện tại"></el-input>
                    </el-form-item>
                </div>
                <div>
                    <el-form-item style="width: 250px" class="d-inline-block">
                        <div>Họ tên bố:</div>
                        <el-input v-model="formHocBa.hoTenCha" placeholder="Họ tên bố"></el-input>
                    </el-form-item>
                    <el-form-item style="width: 250px" class="d-inline-block">
                        <div>Nghề nghiệp bố:</div>
                        <el-input v-model="formHocBa.ngheNghiepCha" placeholder="Nghề nghiệp bố"></el-input>
                    </el-form-item>
                </div>
                <div>
                    <el-form-item style="width: 250px" class="d-inline-block">
                        <div>Họ tên mẹ:</div>
                        <el-input v-model="formHocBa.hoTenMe" placeholder="Họ tên mẹ"></el-input>
                    </el-form-item>
                    ,
                    <el-form-item style="width: 250px" class="d-inline-block">
                        <div>Nghề nghiệp Mẹ:</div>
                        <el-input v-model="formHocBa.ngheNghiepMe" placeholder="Nghề nghiệp mẹ"></el-input>
                    </el-form-item>
                </div>
            </div>
            <div>
                <h5>B. Các môn học và hoạt động giáo dục</h5>
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3" v-for="(item,index) in monHoc">
                        <div><strong>{{index+1}}. {{item.monHoc}}</strong></div>
                        <div class="d-flex">
                            <el-form-item style="width: 160px" class="mr-1">
                                <label>Mức đạt:
                                    <el-select v-model="item.mucDat" placeholder="Mức đạt">
                                        <el-option v-for="item in mucDatList" :label="item.label" :key="item.value"
                                                   :value="item.value"></el-option>
                                    </el-select>
                                </label>
                            </el-form-item>
                            <el-form-item style="width: 160px" class="d-inline-block mr-1">
                                <label>Điểm kiểm tra định kỳ:
                                    <el-input v-model="item.diemKTDK" placeholder="Điểm KTĐK">
                                    </el-input>
                                </label>
                            </el-form-item>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <label>Nhận xét các môn học và hoạt động giáo dục:
                            <el-input rows="3" v-model="formHocBa.danhGiaMonHoc" type="textarea"></el-input>
                        </label>
                    </div>
                </div>
            </div>
            <div class="mt-3">
                <h5>C. Những phẩm chất chủ yếu</h5>
                <div class="row">
                    <div v-for="item in phamChat" class="col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-2">
                        <div>
                            <strong style="width: 110px;display: inline-block">{{item.ten}} : </strong>
                            <el-select size="small" v-model="item.mucDat" placeholder="Mức đạt">
                                <el-option v-for="item in mucDatList" :label="item.label" :key="item.value"
                                           :value="item.value"></el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <label>Nhận xét các phẩm chất:
                            <el-input rows="3" v-model="formHocBa.danhGiaPhamChat" type="textarea"></el-input>
                        </label>
                    </div>
                </div>
            </div>
            <div class="mt-3">
                <h5>D. Năng lực cốt lõi</h5>
                <div class="row">
                    <div class="col-md-6">
                        <h6> 1.Những năng lực chung </h6>
                        <div class="row">
                            <div v-for="item in nangLucChung" class="col-sm-12 mb-3">
                                <div>
                                    <strong style="width: 250px;display: inline-block">{{item.ten}} : </strong>
                                    <el-select size="small" v-model="item.mucDat" placeholder="Mức đạt">
                                        <el-option v-for="item in mucDatList" :label="item.label" :key="item.value"
                                                   :value="item.value"></el-option>
                                    </el-select>
                                </div>
                            </div>
                            <div class="col-md-12 mb-4">
                                <label>Nhận xét về năng lực chung:
                                    <el-input rows="3" v-model="formHocBa.danhGiaNangLucChung"
                                              type="textarea"></el-input>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <h6> 2.Những năng lực đặc thù </h6>
                        <div class="row">
                            <div v-for="item in nangLucDacThu" class="col-sm-12 mb-3">
                                <div>
                                    <strong style="width: 110px;display: inline-block">{{item.ten}} : </strong>
                                    <el-select size="small" v-model="item.mucDat" placeholder="Mức đạt">
                                        <el-option v-for="item in mucDatList" :label="item.label" :key="item.value"
                                                   :value="item.value"></el-option>
                                    </el-select>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <label>Nhận xét về năng lực đặc thù:
                                    <el-input rows="3" v-model="formHocBa.danhGiaNangLucDacThu"
                                              type="textarea"></el-input>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-3">
                <h5>E. Đánh giá chung</h5>
                <div>
                    <label>Đánh giá kết quả giảo dục:
                        <el-input rows="3" v-model="formHocBa.danhGiaKqGd" type="textarea"></el-input>
                    </label>
                </div>
                <div>
                    <label>Khen Thưởng:
                        <el-input rows="3" v-model="formHocBa.khenThuong" type="textarea"></el-input>
                    </label>
                </div>
                <div>
                    <label>Hoàn thành chương trình:
                        <el-input rows="3" v-model="formHocBa.hoanThanhChuongTrinh" type="textarea"></el-input>
                    </label>
                </div>
            </div>
            <div class="button mb-5 mt-4">
                <el-button type="primary" @click="taoHocBa('formHocBa')">Tạo học bạ</el-button>
            </div>
        </el-form>


    </div>
</template>

<script>
    import {mapActions, mapState} from "vuex";
    import dialog from "../../dialog";
    import SelectNamHoc from "../../components/SelectNamHoc";

    export default {
        name: "TieuHoc",
        computed: {
            ...mapState('teacher', [
                "listLop",

            ]),
            ...mapState('account', [
                'user'
            ]),
            ...mapState('hocba', [
                "createStatus"
            ])
        },
        components: {
            SelectNamHoc
        },
        data: () => {
            return {
                mucDatList: [
                    {value: '', label: 'Chọn xếp loại'},
                    {value: 'T', label: 'Tốt'},
                    {value: 'Đ', label: 'Đạt'},
                    {value: 'C', label: 'Cần cố gắng'},
                ],
                gioiTinh: [
                    {value: -1, label: 'Giới tính'},
                    {value: 0, label: 'Nữ'},
                    {value: 1, label: 'Nam'}
                ],
                fullscreenLoading: false,
                formHocBa: {
                    capHoc: '',
                    khoiHoc: '',
                    namHoc: '',
                    maLop: '',
                    maHS: '',
                    hoTenMe: '',
                    ngheNghiepCha: "",
                    ngheNghiepMe: "",
                    ngheNghiepNguoiGiamHo: "",
                    hoTenCha: '',
                    gioiTinh: '',
                    hoten: '',
                    ngaySinh: "",
                    noiOHienNay: "",
                    noiSinh: "",
                    khenThuong: '',
                    nangLucs: [],
                    nangLucDacThus: [],
                    phamChatChuYeus: [],
                    hoanThanhChuongTrinh: '',
                    danhGiaKqGd: '',
                    danhGiaKetQua: '',
                    danhGiaMonHoc: '',
                    danhGiaPhamChat: '',
                    danhGiaNangLucChung: '',
                    danhGiaNangLucDacThu: '',
                    quaTrinhHocTaps: []

                },
                monHoc: [
                    {
                        mucDat: '',
                        nhanXet: '',
                        maMonHoc: "TIENGVIET",
                        monHoc: "Tiếng Việt",
                        diemKTDK: ''
                    },
                    {
                        mucDat: '',
                        nhanXet: '',
                        maMonHoc: "TOAN",
                        monHoc: "Toán",
                        diemKTDK: ''
                    },
                    {
                        mucDat: '',
                        nhanXet: '',
                        maMonHoc: "NGOAINGU",
                        monHoc: "Ngoại ngữ",
                        diemKTDK: ''
                    },
                    {
                        mucDat: '',
                        nhanXet: '',
                        maMonHoc: "LSDL",
                        monHoc: "Lịch sử & Địa lý",
                        diemKTDK: ''
                    },
                    {
                        mucDat: '',
                        nhanXet: '',
                        maMonHoc: "KHOAHOC",
                        monHoc: "Khoa học",
                        diemKTDK: ''
                    },
                    {
                        mucDat: '',
                        nhanXet: '',
                        maMonHoc: "THCN",
                        monHoc: "Tin học và Công nghệ",
                        diemKTDK: ''
                    },
                    {
                        mucDat: '',
                        nhanXet: '',
                        maMonHoc: "DAODUC",
                        monHoc: "Đạo Đức",
                        diemKTDK: ''
                    },
                    {
                        mucDat: '',
                        nhanXet: '',
                        maMonHoc: "TNXH",
                        monHoc: "Tự nhiên và Xã hội",
                        diemKTDK: ''
                    },
                    {
                        mucDat: '',
                        nhanXet: '',
                        maMonHoc: "GDTC",
                        monHoc: "Giáo dục thể chất",
                        diemKTDK: ''
                    },
                    {
                        mucDat: '',
                        nhanXet: '',
                        maMonHoc: "AMNHAC",
                        monHoc: "Âm nhạc",
                        diemKTDK: ''
                    },
                    {
                        mucDat: '',
                        nhanXet: '',
                        maMonHoc: "MYTHUAT",
                        monHoc: 'Mỹ thuật',
                        diemKTDK: ''
                    },
                    {
                        mucDat: '',
                        nhanXet: '',
                        maMonHoc: "HDTN",
                        monHoc: 'Hoạt động trải nghiệm',
                        diemKTDK: ''
                    },
                    {
                        mucDat: '',
                        nhanXet: '',
                        maMonHoc: "TIENGDT",
                        monHoc: 'Tiếng dân tộc',
                        diemKTDK: ''
                    }
                ],

                phamChat: {
                    yeuNuoc: {
                        mucDat: '',
                        ten: "Yêu nước",
                    },
                    nhanAi: {
                        mucDat: '',
                        ten: "Nhân ái",
                    },
                    chamChi: {
                        mucDat: '',
                        ten: "Chăm chỉ",
                    },
                    trungThuc: {
                        mucDat: '',
                        ten: "Trung thực",
                    },
                    trachNhiem: {
                        mucDat: '',
                        ten: "Trách nhiệm",
                    }
                },
                nangLucChung: {
                    tuChu: {
                        mucDat: '',
                        ten: "Tự chủ và tự học",
                    },
                    giaoTiep: {
                        mucDat: '',
                        ten: "Giao tiếp và hợp tác",
                    },
                    sangTao: {
                        mucDat: '',
                        ten: "Giải quyết vấn đề và sáng tạo",
                    },
                },
                nangLucDacThu: {
                    ngonNgu: {
                        mucDat: '',
                        ten: "Ngôn ngữ",
                    },
                    tinhToan: {
                        mucDat: '',
                        ten: "Tính toán",
                    },
                    congNghe: {
                        mucDat: '',
                        ten: "Công nghệ",
                    },
                    tinHoc: {
                        mucDat: '',
                        ten: "tinHoc",
                    },
                    thamMi: {
                        mucDat: '',
                        ten: "Thẩm mĩ",
                    },
                    theChat: {
                        mucDat: '',
                        ten: "Thể chất",
                    }
                },
                rulesHocBa: {
                    khoiHoc: [{
                        required: true,
                        message: 'Chưa chọn khối học',
                        trigger: 'change'
                    }],
                    maLop: [{
                        required: true,
                        message: 'Chưa chọn lớp học',
                        trigger: 'change'
                    }],
                    hoten: [{
                        required: true,
                        message: 'Chưa thêm tên học sinh',
                        trigger: 'change'
                    }],
                    namHoc: [{
                        required: true,
                        message: 'Chưa chọn năm học',
                        trigger: 'change'
                    }]
                },
                optionEducation: [
                    {
                        value: 1,
                        label: 'Cấp 1'
                    },
                    {
                        value: 2,
                        label: 'Cấp 2'
                    },
                    {
                        value: 3,
                        label: 'Cấp 3'
                    },
                ],
                optionGrade: [],
                khoiCap1: [{
                    value: 1,
                    label: 'Khối 1'
                },
                    {
                        value: 2,
                        label: 'Khối 2'
                    },
                    {
                        value: 3,
                        label: 'Khối 3'
                    },
                    {
                        value: 4,
                        label: 'Khối 4'
                    },
                    {
                        value: 5,
                        label: 'Khối 5'
                    },
                ],
                khoiCap2: [{
                    value: 6,
                    label: 'Khối 6'
                },
                    {
                        value: 7,
                        label: 'Khối 7'
                    },
                    {
                        value: 8,
                        label: 'Khối 8'
                    },
                    {
                        value: 9,
                        label: 'Khối 9'
                    },
                ],
                khoiCap3: [{
                    value: 10,
                    label: 'Khối 10'
                },
                    {
                        value: 11,
                        label: 'Khối 11'
                    },
                    {
                        value: 12,
                        label: 'Khối 12'
                    },

                ],
            }
        },
        methods: {
            ...mapActions('teacher', [
                "getListLop",
                "clear",
            ]),
            ...mapActions('hocba', [
                "createHocBaTieuHoc",
                "createHocBaTrungHoc",
            ]),
            getKhoiHoc() {
                if (this.user.capHoc == 1) {
                    this.optionGrade = this.khoiCap1;
                } else if (this.user.capHoc == 2) {
                    this.optionGrade = this.khoiCap2;
                } else if (this.user.capHoc == 3) {
                    this.optionGrade = this.khoiCap3;
                } else {
                    this.optionGrade = this.khoiCap1.concat(this.khoiCap2, this.khoiCap3)
                }
            },
            khoiHocChange() {
                this.formHocBa.lop = '';
                this.getlistLopHoc();
            },
            getlistLopHoc() {
                this.getListLop({
                    start: 0,
                    limit: 999,
                    khoiHoc: this.formHocBa.khoiHoc,
                    // namHoc : this.searchForm.year,
                    // lopHoc : this.searchForm.class
                })
            },
            taoHocBa(formName) {
                this.fullscreenLoading = true;
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let param = this.formHocBa;
                        let namHoc = this.formHocBa.namHoc;
                        param.namHoc = namHoc;
                        param.nangLucDacThus = Object.values(this.nangLucDacThu);
                        param.nangLucs = Object.values(this.nangLucChung);
                        param.phamChatChuYeus = Object.values(this.phamChat);
                        if (this.formHocBa.capHoc == 1) {
                            this.createHocBaTieuHoc(param);
                        } else {
                            this.createHocBaTrungHoc(param);
                        }
                    } else {
                        this.fullscreenLoading = false;
                        dialog.showDialog('Thông báo', 'Vui lòng kiểm tra lại các trường thông tin');
                        console.log('error submit!!');
                        return false;
                    }
                });
            }
        },
        mounted() {
            this.getKhoiHoc();
        },
        watch: {
            createStatus(val) {
                if (val) {
                    this.fullscreenLoading = false;
                    dialog.showDialog('Thong báo', val.message, () => {
                        location.reload();
                    });

                }
            }
        }
    }
</script>

<style scoped>

</style>
