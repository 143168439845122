var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vue-base64-file-upload",
      staticStyle: { width: "fit-content", display: "inline-block" },
    },
    [
      _c("img", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.previewImage && !_vm.disablePreview,
            expression: "previewImage && !disablePreview",
          },
        ],
        class: _vm.imageClass,
        attrs: { src: _vm.previewImage },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "vue-base64-file-upload-wrapper",
          style: _vm.wrapperStyles,
        },
        [
          _c("input", {
            ref: "file",
            style: _vm.fileInputStyles,
            attrs: { type: "file", accept: _vm.accept },
            on: { change: _vm.onChange },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              on: {
                click: function ($event) {
                  return _vm.$refs.file.click()
                },
              },
            },
            [
              _vm._t("showButton", [
                _c("input", {
                  class: _vm.inputClass,
                  style: _vm.textInputStyles,
                  attrs: {
                    type: "text",
                    placeholder: _vm.placeholder,
                    disabled: "",
                  },
                  domProps: {
                    value: _vm.fileName || (_vm.file && _vm.file.name),
                  },
                }),
              ]),
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }